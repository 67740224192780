<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="title">Konkursy</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker v-model="dateRange" type="daterange" align="center" unlink-panels range-separator="-"
          start-placeholder="Data początkowa" end-placeholder="Data końcowa" :picker-options="pickerOptions" :clearable="false">
        </el-date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body row">
              <div class="col-md-3 col-sm-12">
                <fg-input :error="getError('project_id')" data-vv-as="Projekt">
                  <el-select id="leftSelect" v-on:visible-change="getLeftSelectWidth()" v-model="project_id"
                    name="project_id" data-vv-as="Projekt" filterable>
                    <el-option
                      :style="leftSelectWidth !== null ? 'width: ' + leftSelectWidth + 'px' : 'width: 300px'"
                      v-for="project in projects"
                      :label="'[' + project.project_id + '] ' + project.project_name"
                      :value="project.project_id"
                      :key="project.project_id"
                    ></el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-md-6  col-sm-12" align="center">
                <el-tooltip
                  class="item"
                  effect="light"
                  content="Komplet danych osobowych"
                  placement="top"
                  :open-delay="500"
                >
                  <p-checkbox inline v-model="checkboxes.complete_profile">Kompletność danych</p-checkbox>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="light"
                  content="Udzielenie odpowiedzi na wszystkie pytania CO-REGISTRATION i PROFILE"
                  placement="top"
                  :open-delay="500"
                >
                  <p-checkbox inline v-model="checkboxes.complete_answers">Kompletność odpowiedzi</p-checkbox>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="light"
                  content="Udzielenie odpowiedzi na pytanie DOUBLEOPT-IN"
                  placement="top"
                  :open-delay="500"
                >
                  <p-checkbox inline v-model="checkboxes.dopt">Doubleopt-in</p-checkbox>
                </el-tooltip>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="pull-right">
                  <fg-input class="input-sm"
                            placeholder="Szukaj"
                            v-model="searchQuery"
                            addon-right-icon="nc-icon nc-zoom-split">
                  </fg-input>
                </div>
              </div>
            </div>
            <div class="card-body row">
              <el-table :data="queriedData">
                <el-table-column type="expand">
                  <template v-slot="props">
                    <p>Data urodzenia: {{ props.row.DateOfBirdth }}</p>
                    <p>Adres: {{ props.row.Street }} {{ props.row.HouseNumber }}<span v-if="props.row.ApartmentNumber !== null">/{{ props.row.ApartmentNumber }}</span> {{ props.row.PostalCode}}, {{ props.row.City }}</p>
                    <p>Email: {{ props.row.Email }}</p>
                    <p>Telefon: {{ props.row.Phone }}</p>
                    <p>Pytanie: {{ props.row.Pytanie }}</p>
                    <p>Odpowiedź: {{ props.row.Odpowiedz }}</p>
                    <p v-if="props.row.PriceValue !== null && props.row.PriceValue !== ''">Wartość: {{ props.row.PriceValue }}</p>
                  </template>
                </el-table-column>
                <el-table-column label="ID" property="UserDataID" min-width="120" align="center"></el-table-column>
                <el-table-column label="Imię" property="Name" min-width="120" align="center"></el-table-column>
                <el-table-column label="Nazwisko" property="Surname" min-width="120" align="center"></el-table-column>
                <el-table-column label="Nagroda" property="Nagroda" min-width="120" align="center">
                  <template v-slot="props">
                    <div style="word-break: keep-all;">
                      {{ props.row.Nagroda }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Data" property="AddTime" min-width="120" align="center">
                  <template v-slot="props">
                    <div style="word-break: keep-all;">
                      {{ props.row.AddTime }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="card-body row">
              <div class="col-sm-12 col-md-3 pagination-info">
                <p v-if="ifShow" class="category">Pokazuje od {{from + 1}} do {{to}} z {{total}} pozycji</p>
              </div>
              <div align="center" class="col-sm-12 col-md-3 text-md-right">
                <p-button type="success" @click.prevent="loadData(false, true)">Wyświetl</p-button>
              </div>
              <div class="col-sm-12 col-md-3 text-md-left text-center" style="padding-left: 2px;">
                <p-button type="success" @click.prevent="exportQuiz()">Eksportuj</p-button>
                <!-- <downloadExcel
                  :data   = "tableData"
                  :fields = "json_fields"
                  worksheet = "Worksheet"
                  :title="fileTitle"
                  :footer="fileFooter"
                  :name    = "fileName + '.xls'"
                  :before-generate = "loadData">
                  <p-button type="default" link>Eksportuj</p-button>
                </downloadExcel> -->
              </div>
              <div class="col-sm-12 col-md-3">
                <p-pagination class="pull-right"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total="pagination.total"
                              v-if="ifShow">
                </p-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment"; // time manipulation library

import { DatePicker } from "element-ui";
import BackToTop from 'vue-backtotop'

// ui components
import { Table, TableColumn, Select, Option, Switch, Tooltip } from "element-ui";
import { Tabs, TabPane, Card } from 'src/components/UIComponents'
import PPagination from 'src/components/UIComponents/Pagination.vue';

// vuex actions
import { LOAD_QUIZ, QUIZ_INVALID } from "src/store/actions/raports/quiz";
import { LOAD_EXPORT_QUIZ, EXPORT_QUIZ_INVALID } from "src/store/actions/raports/export_quiz";
import { LOAD_PROJECTS } from "src/store/actions/questions/projects";

// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

import * as priv from 'src/privileges.js';

Vue.use(Table);
Vue.use(TableColumn);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [Tooltip.name]: Tooltip,
    [DatePicker.name]: DatePicker,
    PPagination,
    TabPane,
    Tabs,
    Card,
    BackToTop
  },
  data() {
    return {
      project_id: null,
      project_name: '',
      leftSelectWidth: null,
      dateRange: [utils.today(), utils.today()],
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      checkboxes: {
        complete_profile: false,
        complete_answers: false,
        dopt: false
      },
      searchQuery: '',
      propsToSearch: ['UserDataID', 'Name', 'Surname', 'Email', 'AddTime', 'Nagroda'],
      loading: true,
      pickerOptions: {
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      ifShow: false
    }
  },
  computed: {
    projects() { // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjects);
      let uniqueProjects = {}

      for (let i in projects) { // wybranie unikalnych projektów
        uniqueProjects[projects[i].project_id] = {project_id: projects[i].project_id, project_name:projects[i].project_name }
      }

      let result = Object.values(uniqueProjects).sort((a,b) => (a.project_id > b.project_id) ? 1 : -1); // sortowanie projektów po nazwie

      return result;
    },
    quiz() { // pobranie listy danych konkursów
      return Object.values(this.$store.getters.getQuiz);
    },
    tableData() { // dane do tabeli
      if (this.$store.state.quiz.loading_status === 'success') {
        const quiz = JSON.parse(JSON.stringify(this.quiz));
        let data = [];
        let postalNumber = '';

        for(let i in quiz) { // formatowanie kodu pocztowego
          data[i] = quiz[i];
          let number = quiz[i].PostCode;
          if (number !== null) {
            data[i].PostalCode = this.padToFive(number);
            data[i].PostalCode = data[i].PostalCode.substr(0, 2) + '-' + data[i].PostalCode.substr(2);
          } else data[i].PostalCode = number;
        }

        return data
      } return []
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    queriedData () {
      if(!this.ifShow) return [];
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.tableData.slice(this.from, this.to)
      }
      let result = this.tableData
        .filter((row) => {
          let isIncluded = false
          for (let key of this.propsToSearch) {
            if(row[key] !== null) {
              let rowValue = row[key].toString();
              let rowValueLowerCase = row[key].toString().toLowerCase();
              if ((rowValue.includes && rowValue.includes(this.searchQuery)) || rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
          }
          return isIncluded
        })

      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    },
  },
  methods: {
    async loadData(init, ifShow) { // wczytanie danych
      this.loading = true;
      this.ifShow = ifShow;
      if (init) {
        this.$store.dispatch(LOAD_PROJECTS, {}).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });
        this.loading = false;
        return;
      }
      // sprawdzenie czy został wybrany projekt i przedział czasowy
      if (this.dateRange === null) {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning"
        });
      } else if (this.project_id === null) {
        eventBus.$emit("notify", {
          message: "Należy wybrać projekt!",
          type: "warning"
        });
      }
      this.$store.commit(QUIZ_INVALID);

      this.$store.dispatch(LOAD_PROJECTS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      if(this.project_id !== null && this.dateRange !== null) {
        const filters = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
          project_id: this.project_id,
          complete_profile: this.checkboxes.complete_profile,
          complete_answers: this.checkboxes.complete_answers,
          dopt: this.checkboxes.dopt
        }

        await this.$store.dispatch(LOAD_QUIZ, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });
      }
      this.loading = false;
    },
    async exportQuiz() {
      if (this.dateRange === null) {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning"
        });
      } else if (this.project_id === null) {
        eventBus.$emit("notify", {
          message: "Należy wybrać projekt!",
          type: "warning"
        });
      }
      this.loading = true;

      this.$store.commit(EXPORT_QUIZ_INVALID);

      if(this.project_id !== null && this.dateRange !== null) {
        const filters = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
          project_id: this.project_id,
          complete_profile: this.checkboxes.complete_profile,
          complete_answers: this.checkboxes.complete_answers,
          dopt: this.checkboxes.dopt
        }

        await this.$store.dispatch(LOAD_EXPORT_QUIZ, filters)
          .catch(error => {
            eventBus.$emit("notify", {
              message: "Błąd wczytywania danych!",
              type: "warning"
            });
          });

        const tmpName = this.$store.getters.getExportQuiz.tmpname;
        const fileName = this.$store.getters.getExportQuiz.name;
        const ext = this.$store.getters.getExportQuiz.ext;
        // console.log(tmpName, fileName);
        if(tmpName && fileName) {
          this.downloadFile(tmpName, fileName, ext);
        } else {
          eventBus.$emit("notify", {
            message: "Błąd pobierania pliku!",
            type: "warning"
          });
        }
      }
      this.loading = false;
    },
    downloadFile(tmpName, fileName, ext) {
      const basePath = "https://api-utils4.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = `${basePath}?token=${userToken}&tmpname=${tmpName}&name=${fileName}&ext=${ext}`;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getLeftSelectWidth() { // sprawdzenie szerokości selecta
      this.leftSelectWidth = document.getElementById('leftSelect').offsetWidth;
    },
    padToFive(number) { // funkcja dodające 0 przed kodem pocztowym
      if (number <= 99999) { number = ("0000" + number).slice(-5); }
      return number;
    }
  },
  created() {
    this.loadData(true, false);
    eventBus.$on("forceReload", () => {
      this.loadData(false, false);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style scoped>
</style>
