import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_USER_SCOPES,
  USER_SCOPES_LOADING,
  USER_SCOPES_LOADING_SUCCESS,
  USER_SCOPES_LOADING_ERROR,
  USER_SCOPES_INVALID,
  SAVE_USER_SCOPE,
  USER_SCOPE_SAVING,
  USER_SCOPE_SAVING_SUCCESS,
  USER_SCOPE_SAVING_ERROR
} from 'src/store/actions/admin/user_scopes'

const storeObjectConfig = {
  objectName: "UserScopes",
  getterName: "getUserScopes",
  loadActionName: LOAD_USER_SCOPES,
  loadingMutationName: USER_SCOPES_LOADING,
  loadingSuccessMutationName: USER_SCOPES_LOADING_SUCCESS,
  loadingErrorMutationName: USER_SCOPES_LOADING_ERROR,
  invalidMutationName: USER_SCOPES_INVALID,
  saveActionName: SAVE_USER_SCOPE,
  savingMutationName: USER_SCOPE_SAVING,
  savingSuccessMutationName: USER_SCOPE_SAVING_SUCCESS,
  savingErrorMutationName: USER_SCOPE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/userScopes",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/userScopes",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
