<template>
  <div>
    <div class="row">
      <div class="col-md-11">
        <div class="form-group">
          <label>Telefon</label>
          <fg-input :error="getError('phone')" data-vv-as="Telefon">
            <el-select
              v-model="model.phone"
              name="phone"
              data-vv-as="Telefon"
              v-validate="modelValidations.phone"
              filterable
              allow-create
              clearable
              @input="handlePhoneChange"
            >
              <el-option v-for="record in phone" :label="record" :value="record" :key="record"></el-option>
            </el-select>
          </fg-input>
        </div>
      </div>

      <div class="col-sm-1 helper">
        <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
          <div slot="content">Aby dodać ręcznie numer należy wpisać go w danym polu i wybrać kliknięciem</div>
          <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip } from "element-ui";

// event bus
import { eventBus } from "src/main";

import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },
  props: ["phone"],
  data() {
    return {
      model: {
        phone: "",
      },
      modelValidations: {
        phone: {
          numeric: true,
          min: 11,
          max: 11,
        },
      },
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    handlePhoneChange() {
      eventBus.$emit("PhoneForm_PhoneChanged", {
        phone: this.model.phone,
      });
    },
  },
  async created() {
    if (this.phone.length > 0) {
      this.model.phone = this.phone[0];
      this.handlePhoneChange();
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.row:last-child {
  justify-content: center;
}
.helper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
</style>
