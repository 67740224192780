<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Raport</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker v-model="dateRange" type="daterange" align="center" unlink-panels range-separator="-"
          start-placeholder="Data początkowa" end-placeholder="Data końcowa" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr v-if="this.HLR.sum !== '0'">
              <td>Liczba zdarzeń HLR: </td>
              <td align="right">{{ this.HLR.sum }}</td>
            </tr>
            <tr v-if="this.mailing_stats.sum !== '0'">
              <td>Liczba zdarzeń mailing:</td>
              <td align="right">{{ this.mailing_stats.sum }}</td>
            </tr>
          </tbody>
        </table>

        <div v-if="parseInt(HLR.sum) > 0 || parseInt(mailing_stats.sum) > 0">
          <el-collapse>
            <el-collapse-item>
              <template slot="title">
                <strong>Szczegóły</strong>
              </template>
              <el-table :data="queriedData" style="width: 100%">
                <el-table-column label="Data" min-width="100" property="date"></el-table-column>
                <el-table-column label="HLR" min-width="100" property="HLR"></el-table-column>
                <el-table-column label="Mailing" min-width="100" property="mailing"></el-table-column>
              </el-table>
              <div class="card-body row">
                <div class="pagination-info col-sm-6">
                  <p class="category">Pokazuje od {{from + 1}} do {{to}} z {{total}} pozycji</p>
                </div>
                <div class="col-sm-6">
                  <p-pagination class="pull-right"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="pagination.total">
                  </p-pagination>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-center">
          <p-button type="success" @click.prevent="loadStats()">Pokaż</p-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from "vue";
  import moment from "moment"; // time manipulation library
  import utils from "src/utils";

  // event bus
  import { eventBus } from "src/main";

  import { DatePicker, Collapse, CollapseItem } from "element-ui";
  import PPagination from 'src/components/UIComponents/Pagination.vue';

  import { LOAD_HLR, HLR_INVALID } from "src/store/actions/raports/hlr_raport";
  import { LOAD_MAILING_RAPORT, MAILING_RAPORT_INVALID } from "src/store/actions/raports/mailing_raport";

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      [CollapseItem.name]: CollapseItem,
      [Collapse.name]: Collapse,
      PPagination,
    },
    data() {
      return {
        loading: false,
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        dateRange: [utils.today(), utils.today()],
        pickerOptions: {
          shortcuts: [
            {
              text: "Dzisiaj",
              onClick(picker) {
                const end = utils.today();
                const start = utils.today();
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Ostatni tydzień",
              onClick(picker) {
                const end = utils.today();
                const start = utils.today();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Ostatni miesiąc",
              onClick(picker) {
                const end = utils.today();
                const start = utils.today();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Ostatnie 3 miesiące",
              onClick(picker) {
                const end = utils.today();
                const start = utils.today();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Poprzedni miesiąc",
              onClick(picker) {
                const end = utils.today();
                const start = utils.today();
                start.setDate(1);
                start.setMonth(start.getMonth() - 1);
                end.setDate(0);
                picker.$emit("pick", [start, end]);
              }
            }
          ]
        }
      }
    },
    methods: {
      async loadStats() {
        this.loading = true;
        const filters = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
          details: true
        };

        this.$store.commit(HLR_INVALID);
        this.$store.commit(MAILING_RAPORT_INVALID);

        this.$store.dispatch(LOAD_MAILING_RAPORT, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });

        await this.$store.dispatch(LOAD_HLR, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });
        this.loading = false;
      },
    },
    computed: {
      HLR() {
        let result = {};
        const hlr_getter = this.$store.getters.getHLR;
        if(this.$store.state.hlr_raport.loading_status === 'success'){
          result = JSON.parse(JSON.stringify(hlr_getter));
          result.sum = result.sum.toLocaleString();
        } else {
          result = {details: [], sum: '0'};
        }
        return result;
      },
      mailing_stats() {
        let result = {};
        const mailing_getter = this.$store.getters.getMailingRaport;
        if(this.$store.state.mailing_raport.loading_status === 'success'){
          result = JSON.parse(JSON.stringify(mailing_getter));
          result.sum = result.sum.toLocaleString();
        } else {
          result = {details: [], sum: '0'};
        }
        return result;
      },
      tableData() {
        let tableData = [];
        if(this.$store.state.hlr_raport.loading_status === 'success') {
          let data = {};
          let HLR_days = Object.keys(this.HLR.details);
          for(let i in HLR_days) {
            let day = HLR_days[i];
            data[day] = {
              date: day,
              HLR: 0,
              mailing: 0
            };
            data[day].HLR = this.HLR.details[day];
            if(this.mailing_stats.details[day]) {
              data[day].mailing = this.mailing_stats.details[day];
            }
          }
          let mailing_days = Object.keys(this.mailing_stats.details);
          for(let j in mailing_days) {
            let day = mailing_days[j];
            if(!data[day]) {
              data[day] = {
                date: day,
                HLR: 0,
                mailing: 0
              }
              data[day].mailing = this.mailing_stats.details[day];
            }
          }
          tableData = Object.values(data);
        }
        return tableData
      },
      pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    queriedData () { // paginacja danych
      this.pagination.total = this.tableData.length
      return this.pagedData;
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    },
    }
  }
</script>
<style scoped>
</style>
