<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Wyniki testów</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="-"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd"
        ></el-date-picker>
      </div>
    </div>

    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4">
            <label for="project">Projekt</label>
            <el-select
              id="testResultsProjectSelect"
              v-on:visible-change="getUpperLeftSelectWidth()"
              v-model="projectsArray"
              name="project"
              data-vv-as="Projekt"
              style="width: 100%"
              multiple
              filterable
              clearable
            >
              <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                <el-option
                  :style="upperLeftSelectWidth !== null ? 'width: ' + upperLeftSelectWidth + 'px' : 'width: 300px'"
                  :id="'testResultsProjectSelectOption_' + item.project_id"
                  v-for="item in group.options"
                  :key="item.project_id"
                  :label="item.project_name + ' [' + item.project_id + ']'"
                  :value="item.project_id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </div>
          <div class="col-sm-2">
            <label for="project">Status</label>
            <el-select id="testResultsStatusSelect" v-model="activeFilter" name="status" data-vv-as="Status" style="width: 100%" filterable clearable>
              <el-option v-for="status in filterTypes" :key="status.value" :label="status.text" :value="status.value"> </el-option>
            </el-select>
          </div>
          <div class="col-sm-2">
            <label for="project">Scenariusz</label>
            <el-select
              id="testResultsScenarioTypeSelect"
              v-model="scenarioTypeFilter"
              name="scenariusz"
              data-vv-as="Scenariusz"
              style="width: 100%"
              filterable
              clearable
            >
              <el-option v-for="scenario in scenarioTypes" :key="scenario.value" :label="scenario.text" :value="scenario.value"> </el-option>
            </el-select>
          </div>
          <div class="col right-bottom">
            <p-button type="success" @click="loadData">Pokaż</p-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body row">
        <el-table :data="tableData" style="width: 100%" :header-cell-style="headerStyle" @sort-change="sortChange">
          <el-table-column label="ID" property="id" width="100" align="right" sortable="custom"></el-table-column>
          <el-table-column label="UDID" property="userdata_id" width="120" align="right" sortable="custom"></el-table-column>
          <el-table-column label="Projekt" property="project_id" align="left" min-width="150" sortable="custom">
            <template v-slot="props">
              <div>{{ "[" + props.row.project_id + "] " + projects[props.row.project_id].project_name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Wersja" property="version" width="150" align="right" sortable="custom"></el-table-column>
          <el-table-column label="Scenariusz" property="scenario_type" width="130" align="right" sortable="custom"></el-table-column>
          <el-table-column label="Moduł 1" property="start_module" width="120" align="right" sortable="custom"></el-table-column>
          <el-table-column label="Moduł 2" property="module" width="120" align="right" sortable="custom"></el-table-column>
          <el-table-column label="Data" property="start_time" align="right" width="180" sortable="custom"></el-table-column>
          <el-table-column label="Trwanie" property="duration" align="right" width="120" sortable="custom"></el-table-column>
          <el-table-column label="Status" property="status" align="right" width="120" sortable="custom">
            <template v-slot="props">
              <el-tag class="test-results__status-tag" :type="props.row.status === 'SUCCESS' ? 'success' : 'danger'" size="small">{{
                props.row.status === "SUCCESS" ? "SUKCES" : "BŁĄD"
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" width="80">
            <template v-slot="props">
              <p-button type="info" size="sm" icon @click="handleDetails(props.row)">
                <i class="fa fa-info" aria-hidden="true"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="block center">
      <el-pagination
        v-on:current-change="changePage($event)"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="maxPage * pageLimit"
        :page-size="pageLimit"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

// event bus
import { eventBus } from "src/main";

import { DatePicker, Select, Option, OptionGroup, Tag, Pagination } from "element-ui";

import { LOAD_TESTS_RESULTS, TESTS_RESULTS_INVALID } from "src/store/actions/tests/tests_results";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_TESTS_PROJECTS, TESTS_PROJECTS_INVALID } from "../../../store/actions/tests/tests_projects";

import TestsResultsModal from "src/components/Utils4/tests/TestsResultsModal.vue";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tag.name]: Tag,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      loading: false,
      page: 1,
      maxPage: 1,
      pageLimit: 20,
      sortOrder: "DESC",
      sortProp: "id",
      projectsArray: [],
      dateRange: ["", ""],
      upperLeftSelectWidth: null,
      filterTypes: [
        { text: "SUKCES", value: "SUCCESS" },
        { text: "BŁĄD", value: "FAILED" },
      ],
      scenarioTypes: [
        { text: "POZYTYWNY", value: "POSITIVE" },
        { text: "NEGATYWNY", value: "NEGATIVE" },
      ],
      activeFilter: "",
      scenarioTypeFilter: "",
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    async initLoad(force) {
      this.loading = true;

      this.$store.commit(TESTS_RESULTS_INVALID);

      if (force) {
        this.$store.commit(TESTS_PROJECTS_INVALID);
        this.$store.commit(PROJECT_DICT_INVALID);
      }

      this.$store.dispatch(LOAD_TESTS_PROJECTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.loadData();
      this.loading = false;
    },
    async loadData() {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        page: this.page,
        pageLimit: this.pageLimit,
        statusFilter: this.activeFilter,
        scenarioType: this.scenarioTypeFilter,
        project_list: this.projectsArray,
        sortOrder: this.sortOrder,
        sortProp: this.sortProp,
        status: this.activeFilter,
      };

      this.$store.commit(TESTS_RESULTS_INVALID);

      await this.$store
        .dispatch(LOAD_TESTS_RESULTS, filters)
        .then(() => {
          if (this.testsResults.allCnt) {
            this.maxPage = Math.ceil(this.testsResults.allCnt / this.pageLimit);
          } else {
            this.maxPage = 1;
          }
        })
        .catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

      this.loading = false;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    async changePage(currentPage) {
      if (currentPage) {
        this.page = currentPage;
      } else {
        this.page = 1;
        this.maxPage = 1;
      }

      await this.loadData(false);
      window.scrollTo(0, 0);
    },
    sortChange(obj) {
      console.log(obj);
      this.sortOrder = obj.order === "ascending" ? "ASC" : "DESC";
      this.sortProp = obj.prop;
      this.changePage();
    },
    getUpperLeftSelectWidth() {
      this.upperLeftSelectWidth = document.getElementById("testResultsProjectSelect").offsetWidth;
    },
    headerStyle(obj) {
      return "font-size: 12px;";
    },
    handleDetails(row) {
      // wyświetlenie modala szczegółów
      this.$modal.show(
        TestsResultsModal,
        {
          row: row,
          projects: this.projects,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(TestsResultsModal);
              },
            },
          ],
        },
        {
          name: "TestsResultsModal",
          draggable: false,
          scrollable: true,
          width: "60%",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
  },
  computed: {
    testsProjects() {
      return this.$store.getters.getTestsProjects;
    },
    projects() {
      return this.$store.getters.getProjectDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      const projects = Object.values(this.$store.getters.getProjectDict);
      let testProjects = this.testsProjects;

      testProjects = _.isArray(testProjects) ? testProjects : [];

      let projects_filtered = projects.filter((x) => testProjects.includes(x.project_id));
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects_filtered.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    testsResults() {
      return this.$store.getters.getTestsResults;
    },
    tableData() {
      if (this.$store.state.tests_results.loading_status === "success") {
        let getter = Object.values(this.testsResults.results);
        return getter;
      }
      return [];
    },
  },
  mounted() {},
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
      this.initLoad(true);
    });
    const today = moment(utils.today()).format("YYYY-MM-DD");
    this.dateRange[0] = today;
    this.dateRange[1] = today;

    this.initLoad(false);
  },
  beforeDestroy: function () {
    this.$store.commit(TESTS_RESULTS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.center {
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
}

.right-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.leftSwitch {
  margin-right: 20px;
}

.leftSwitch::after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid rgb(179, 177, 177);
  top: 0;
  left: 152px;
}
</style>

<style>
.mailing-efficiency__hide-expand :first-child {
  visibility: hidden !important;
}

.test-results__status-tag {
  color: white !important;
}
</style>
