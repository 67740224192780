import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_CREATIONS_EFFICIENCY_EXPORT,
  CREATIONS_EFFICIENCY_EXPORT_LOADING,
  CREATIONS_EFFICIENCY_EXPORT_LOADING_SUCCESS,
  CREATIONS_EFFICIENCY_EXPORT_LOADING_ERROR,
  CREATIONS_EFFICIENCY_EXPORT_INVALID
} from 'src/store/actions/secure/creations_efficiency_export'

const storeObjectConfig = {
  objectName: "CreationsEfficiency",
  getterName: "getCreationsEfficiencyExport",
  loadActionName: LOAD_CREATIONS_EFFICIENCY_EXPORT,
  loadingMutationName: CREATIONS_EFFICIENCY_EXPORT_LOADING,
  loadingSuccessMutationName: CREATIONS_EFFICIENCY_EXPORT_LOADING_SUCCESS,
  loadingErrorMutationName: CREATIONS_EFFICIENCY_EXPORT_LOADING_ERROR,
  invalidMutationName: CREATIONS_EFFICIENCY_EXPORT_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/creationsEfficiency/export",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
