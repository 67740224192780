<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-10">
        <h5 class="title">Historia numeru</h5>
      </div>
      <div class="col-sm-2">
        <router-link
          :to="{
            name: 'Reklamacje',
            params: {
              company: $route.params.company,
              mailbox: $route.params.mailbox,
              complaintID: $route.params.complaintID,
            },
          }"
        >
          <p-button type="default" link v-if="complaintCheck"> Powrót do reklamacji </p-button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <label>Wpisz numer telefonu</label>
              <fg-input :error="getError('Numer telefonu')" data-vv-as="Numer telefonu">
                <el-input
                  type="tel"
                  name="Numer telefonu"
                  clearable
                  placeholder="Numer telefonu"
                  v-model="model.phone"
                  :error="getError('Numer telefonu')"
                >
                </el-input>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div><label>Wybierz przedział</label></div>
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                align="center"
                unlink-panels
                range-separator="-"
                start-placeholder="Data początkowa"
                end-placeholder="Data końcowa"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <p-button type="success" size="sm" @click="loadData">Sprawdź</p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="this.$store.state.phone_history.loading_status === 'success'">
      <div class="card-body">
        <div class="row">
          <el-table
            :cell-style="colStyle"
            :header-cell-style="headerStyle"
            :data="phoneHistory"
            style="width: 100%"
            :default-sort="{ prop: 'eventTime', order: 'descending' }"
          >
            <el-table-column type="expand">
              <template v-slot="props">
                <div class="row expand">
                  <div class="col-sm-12">
                    <p><b>ID:</b> {{ props.row.ID }}</p>
                    <p><b>ID Akcji:</b> {{ props.row.IdAction }}</p>
                    <p><b>Operator:</b> {{ props.row.ShortOperator }}</p>
                    <p><b>Źródło:</b> {{ props.row.Source }}</p>
                    <p><b>Typ:</b> {{ props.row.SmsType }}</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Typ" property="Direction" width="50"></el-table-column>
            <el-table-column label="Data" property="AddTime" width="150"></el-table-column>
            <el-table-column label="Numer" property="Msisdn" width="110"> </el-table-column>
            <el-table-column label="Sms" property="SmsPremiumNumber" width="70"> </el-table-column>
            <el-table-column label="Treść" property="Message" min-width="200"> </el-table-column>
            <el-table-column label="Spam" property="IsSpam" width="60">
              <template v-slot="props">
                {{ props.row.IsSpam ? "Tak" : "Nie" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Select, Option, Input, Tooltip, Checkbox, DatePicker } from "element-ui";
import moment from "moment"; // time manipulation library

// vuex actions
import { LOAD_PHONE_HISTORY, PHONE_HISTORY_INVALID } from "src/store/actions/complaints/phone_history";

import ConsentsComponent from "src/components/Utils4/complaints/views/ConsentsComponent.vue";

// event bus
import { eventBus } from "src/main";

import utils from "src/utils";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Tooltip.name]: Tooltip,
    [Checkbox.name]: Checkbox,
    [DatePicker.name]: DatePicker,
    appConsentsComponent: ConsentsComponent,
  },
  data() {
    return {
      loading: false,
      model: {
        phone: "",
      },
      modelValidations: {
        phone: {
          regex: "^(48)?[0-9]{9}$",
          max: 11,
        },
      },
      dateRange: [utils.today().getTime() - 3600 * 1000 * 24 * 30, utils.today()],
      pickerOptions: {
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      complaintCheck: false,
    };
  },
  computed: {
    phoneHistory() {
      return this.$store.getters.getPhoneHistory;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validatePhone(phone) {
      if (!phone) {
        return false;
      }
      let validatedPhone = phone.replace(/[^0-9]/g, "");
      validatedPhone = validatedPhone.length == 9 ? "48" + validatedPhone : validatedPhone;
      if (validatedPhone.length != 11) {
        return false;
      } else {
        this.model.phone = validatedPhone;
        return validatedPhone;
      }
    },
    async loadData(force) {
      if (this.model.phone.length < 1) {
        eventBus.$emit("notify", {
          message: "Należy podać dane do wyszukania",
          type: "warning",
        });
        return;
      }
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      let phone = "";
      if (this.model.phone.length > 0) {
        phone = this.validatePhone(this.model.phone);
      }

      if (phone !== "" && !phone) {
        eventBus.$emit("notify", {
          message: "Nieprawidłowy numer telefonu",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const filters = {
        phone: phone,
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
      };

      if (force) {
        this.$store.commit(PHONE_HISTORY_INVALID);
      }

      await this.$store.dispatch(LOAD_PHONE_HISTORY, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    colStyle() {
      return "font-size: 12px;";
    },
    headerStyle() {
      return "font-size: 12px;";
    },
  },
  created() {
    let params = this.$route.params;
    if (params.phone) {
      this.model.phone = params.phone;
      this.complaintCheck = true;
    }
    if (this.complaintCheck) {
      this.loadData(true);
    }
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy() {
    this.$store.commit(PHONE_HISTORY_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style scoped>
.center {
  justify-content: center;
  align-content: center;
  display: flex;
}

.expand {
  margin: 0;
  font-size: 12px;
}
</style>
