import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_SCOPE_TYPES,
  SCOPE_TYPES_LOADING,
  SCOPE_TYPES_LOADING_SUCCESS,
  SCOPE_TYPES_LOADING_ERROR,
  SCOPE_TYPES_INVALID,
  SAVE_SCOPE_TYPE,
  SCOPE_TYPE_SAVING,
  SCOPE_TYPE_SAVING_SUCCESS,
  SCOPE_TYPE_SAVING_ERROR
} from 'src/store/actions/admin/scope_types'

const storeObjectConfig = {
  objectName: "ScopeTypes",
  getterName: "getScopeTypes",
  loadActionName: LOAD_SCOPE_TYPES,
  loadingMutationName: SCOPE_TYPES_LOADING,
  loadingSuccessMutationName: SCOPE_TYPES_LOADING_SUCCESS,
  loadingErrorMutationName: SCOPE_TYPES_LOADING_ERROR,
  invalidMutationName: SCOPE_TYPES_INVALID,
  saveActionName: SAVE_SCOPE_TYPE,
  savingMutationName: SCOPE_TYPE_SAVING,
  savingSuccessMutationName: SCOPE_TYPE_SAVING_SUCCESS,
  savingErrorMutationName: SCOPE_TYPE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/scopeTypes",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/scopeTypes",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
