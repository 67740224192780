<template>
  <prism-editor
    class="my-editor"
    :style="`max-height: ${selectedHeight}px`"
    v-model="code"
    v-on:input="$emit('input', code)"
    :highlight="highlighter"
  ></prism-editor>
</template>

<script>
// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism-okaidia.css"; // import syntax highlighting styles

export default {
  props: ["value", "mode", "height"],
  components: {
    PrismEditor,
  },
  data: () => ({ code: "", selectedLanguage: null, selectedHeight: "400" }),
  methods: {
    highlighter(code) {
      return highlight(code, languages[this.selectedLanguage]); // languages.<insert language> to return html with markup
    },
  },
  created() {
    this.code = this.value;
    this.selectedLanguage = this.mode === "js" ? "js" : "markup";
  },
};
</script>

<style>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  border-radius: 5px;
  height: auto;
  margin-bottom: 10px;
}

.prism-editor__editor {
  color: #ccc;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
