<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Efektywność Klientów</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="-"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd"
          :default-value="defaultDate"
        ></el-date-picker>
      </div>
    </div>


    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <label for="project">Projekt</label>
            <el-select v-model="projects" multiple style="width: 100%" placeholder="Projekt">
              <el-option v-for="project in customersProjects" :key="project.id" :label="project.name"
                         :value="project.id"></el-option>
            </el-select>
          </div>
          <div class="col-sm-6">
            <label for="klient">Klient</label>
            <el-select v-model="customers" multiple style="width: 100%" placeholder="Klient">
              <el-option v-for="customer in customersList" :key="customer.cId" :label="customer.cName"
                         :value="customer.cId"></el-option>
            </el-select>
          </div>
          <div class="col-sm-6">
            <label for="source">Źródło</label>
            <el-select v-model="sources" multiple style="width: 100%" placeholder="Źródło">
              <el-option v-for="source in customersSources" :key="source" :label="source" :value="source"></el-option>
            </el-select>
          </div>
          <div class="col-sm-6 d-flex mt-4 mt-sm-0">
            <p-button type="success" @click="clearFilter" class="mr-auto mt-auto mb-0">Wyczyść</p-button>
            <p-button type="success" @click="loadData('show')" class=" mt-auto mb-0">Pokaż</p-button>
            <p-button type="success" @click="loadData('excel')" class=" mt-auto mb-0">Excel</p-button>
          </div>
        </div>
      </div>
      <hr/>
      <div class="card-footer" style="display: flex; justify-content: space-between">
        <h6>
          <span>Przedział dat: </span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <template>
          <el-table
            :data="getCustomerSale"
            style="width: 100%" :default-sort="{ prop: 'Klient', order: 'ascending' }">
            <el-table-column type="expand">
              <template v-slot="scope">
                <el-table
                  :data="getBaners(scope.row.baners)"
                  style="width: 100%" :default-sort="{ prop: 'sold', order: 'descending' }">
                  <el-table-column
                    prop="BannerID"
                    width="80"
                    label="id" sortable>
                  </el-table-column>
                  <el-table-column
                    prop="BannerName"
                    width="250"
                    label="Name" sortable>
                  </el-table-column>
                  <el-table-column
                    prop="pidName"
                    width="200"
                    label="Projekt" sortable>
                  </el-table-column>
                  <el-table-column
                    prop="source"
                    label="Źródło"
                    width="120" sortable>
                  </el-table-column>
                  <el-table-column
                    prop="listName"
                    width="200" sortable>
                    <template slot="header">
                      <el-tooltip content="Nazwa listy mailingowej" placement="top" effect="light"
                                  class="d-inline-flex">
                        <div>M. list</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="sold"
                    width="120" sortable>
                    <template slot="header">
                      <el-tooltip content="Ilość sprzedanych leadów" placement="top" effect="light"
                                  class="d-inline-flex">
                        <div>Sold</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="sendMailSoldPercent"
                    width="110" sortable>
                    <template slot="header">
                      <el-tooltip content="Procent sprzedanych maili do wysłanych" placement="top" effect="light"
                                  class="d-inline-flex">
                        <div>S/Send</div>
                      </el-tooltip>
                    </template>
                    <template v-slot="props">
                      <div class="progress" style="height: 15px">
                        <div class="progress-bar bg-success"
                             :style="'width: ' + props.row.sendMailSoldPercent + ';'"
                             role="progressbar"
                             aria-valuemin="0"
                             aria-valuemax="100">
                          <span class="percentageValue">{{ props.row.sendMailSoldPercent }}</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="display"
                    width="100" sortable>
                    <template slot="header">
                      <el-tooltip content="Ilość wyświetleń" placement="top" effect="light" class="d-inline-flex">
                        <div>DIS</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="displayMailSoldPercent"
                    width="100" sortable>
                    <template slot="header">
                      <el-tooltip content="Procent sprzedanych do wyświetleń" placement="top" effect="light"
                                  class="d-inline-flex">
                        <div>S/DIS</div>
                      </el-tooltip>
                    </template>
                    <template v-slot="props">
                      <div class="progress" style="height: 15px">
                        <div class="progress-bar bg-success"
                             :style="'width: ' + props.row.displayMailSoldPercent + ';'"
                             role="progressbar"
                             aria-valuemin="0"
                             aria-valuemax="100">
                          <span class="percentageValue">{{ props.row.displayMailSoldPercent }}</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="mail_sent"
                    width="120" sortable>
                    <template slot="header">
                      <el-tooltip content="Ilość wysłanych maili" placement="top" effect="light" class="d-inline-flex">
                        <div>M Send</div>
                      </el-tooltip>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="click"
                    width="100"
                    label="Click" sortable>
                  </el-table-column>
                  <el-table-column
                    prop="clickMailSoldPercent" sortable width="110">
                    <template slot="header">
                      <el-tooltip content="Procent sprzedanych do klik" placement="top" effect="light"
                                  class="d-inline-flex">
                        <div>S/CLICK</div>
                      </el-tooltip>
                    </template>
                    <template v-slot="props">
                      <div class="progress" style="height: 15px">
                        <div class="progress-bar bg-success"
                             :style="'width: ' + props.row.clickMailSoldPercent + ';'"
                             role="progressbar"
                             aria-valuemin="0"
                             aria-valuemax="100">
                          <span class="percentageValue">{{ props.row.clickMailSoldPercent }}</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <!--el-table-column
                      label="Sprzedaz"
                      prop="soldPrecent">
                  </el-table-column-->
                  <el-table-column
                    label="Przy"
                    prop="income"
                    width="100"
                    :formatter="formatCurrency" sortable>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column
              label="ID"
              prop="id"
              width="100" sortable>
            </el-table-column>
            <el-table-column
              label="Klient"
              prop="Klient" sortable>
            </el-table-column>
            <el-table-column
              label="HOT/WARM"
              prop="type"
              width="150" sortable>
            </el-table-column>
            <el-table-column
              label="Sprzedano"
              prop="sum.sold" sortable>
            </el-table-column>
            <el-table-column
              label="Sprzedaz"
              prop="sum.soldPrecent" sortable>
              <template v-slot="props">
                <div class="progress" style="height: 15px">
                  <div class="progress-bar bg-success"
                       :style="'width: ' + props.row.sum.soldPrecent + ';'"
                       role="progressbar"
                       aria-valuemin="0"
                       aria-valuemax="100">
                    <span class="percentageValue">{{ props.row.sum.soldPrecent }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Przych"
              prop="sum.income"
              :formatter="formatCurrency" sortable>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import {
  LOAD_CUSTOMER_EFFICIENCY,
  INVALID_CUSTOMER_EFFICIENCY,
  GET_CUSTOMER_EFFICIENCY
} from "@/store/actions/sales/customer_efficiency";

import {LOAD_CUSTOMER_EFFICIENCY_EXCEL_DISPLAY,CUSTOMER_EFFICIENCY_DISPLAY_INVALID} from "@/store/actions/sales/customer_efficiency_excel";


import {eventBus} from "@/main";
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import {DatePicker, Option, Select, Tooltip} from "element-ui";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip
  },
  computed: {
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    date() {
      let result = [];
      if (this.pickrDate) {
        const newDate = this.pickrDate.split(" do ");
        if (newDate[0]) result.push(newDate[0]);
        if (newDate[1]) result.push(newDate[1]);
      }
      return result;
    },
    customersProjects() {
      let result = [];
      if (this.$store.state.customer_efficiency.loading_status === "success") {
        result = Object.values(this.$store.getters[GET_CUSTOMER_EFFICIENCY].customersProjects);
        result.sort(function (a, b) {

          //compare two values
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;

        })
      }
      return result
    },
    customersSources() {
      let result = [];
      if (this.$store.state.customer_efficiency.loading_status === "success") {
        result = Object.values(this.$store.getters[GET_CUSTOMER_EFFICIENCY].customersSources);
      }
      return result
    },
    customersSales() {
      let result = []
      if (this.$store.state.customer_efficiency.loading_status === "success") {
        result = Object.values(this.$store.getters[GET_CUSTOMER_EFFICIENCY].customersSales);
      }
      return result;
    },

    customerSale: {
      get() {
        if (this.customers.length === 0) {
          this.customerSalePresent = this.customersSales;
        }
        return this.customerSalePresent;
      },
      set(newValue) {
        this.customerSalePresent = newValue;
      }
    },
    getCustomerSale() {
      return this.customerSale;
    },
    customersList() {
      let result = [];
      if (this.$store.state.customer_efficiency.loading_status === "success") {
        result = Object.values(this.$store.getters[GET_CUSTOMER_EFFICIENCY].customersList);
        result.sort(function (a, b) {

          //compare two values
          if (a.cName.toLowerCase() < b.cName.toLowerCase()) return -1;
          if (a.cName.toLowerCase() > b.cName.toLowerCase()) return 1;
          return 0;

        })
      }
      return result
    },
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
  },

  data() {
    return {
      loading: false,
      pickrDate: null,
      customers: [],
      sources: [],
      projects: [],
      customerSalePresent: [],
      dateRange: ["", ""],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    customers() {
      if (this.customers.length > 0) {
        let sales = [], result = this.customersSales;
        for (let key in result) {
          let customerId = result[key].id;
          if (this.customers.includes(customerId)) {
            sales.push(result[key]);
          }
        }
        this.customerSale = sales;
      }
    }
  },
  methods: {
    clearFilter() {
      this.projects = [];
      this.customers = [];
      this.sources = [];
    },
    getBaners(banerObjects) {
      let result = [];
      if (Object.keys(banerObjects).length > 0) {
        result = Object.values(banerObjects);
        if (this.projects.length > 0) {
          for (let key in result) {
            let pid = result[key].ProjectId;

            if (!this.projects.includes(pid)) {
              delete result[key];
            }
          }
        }

        if (this.sources.length > 0) {
          for (let key in result) {
            let source = result[key].source;

            if (!this.sources.includes(source)) {
              delete result[key];
            }
          }
        }
      }
      return result;
    },
    async loadData(mode) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      this.clearFilter();
      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
      };

      this.$store.commit(mode === "excel" ? CUSTOMER_EFFICIENCY_DISPLAY_INVALID : INVALID_CUSTOMER_EFFICIENCY);

      await this.$store
        .dispatch(mode === "excel" ? LOAD_CUSTOMER_EFFICIENCY_EXCEL_DISPLAY : LOAD_CUSTOMER_EFFICIENCY, filters)
        .then(() => {

          let message = 'Wczytano dane';
          eventBus.$emit("notify", {
            message: message,
            type: "warning",
          });
        })
        .catch((error) => {
          let isJson;
          let msg = "";
          let err_msg;
          try {
            isJson = true;
            err_msg = JSON.parse(error.response.data.description);
          } catch (e) {
            isJson = false
          }
          if (isJson === true) {
            if ("missing" in err_msg) {
              msg = "Brak parametru: " + err_msg.missing;
            }
          } else {
            msg = error.response.data.description;
          }
          eventBus.$emit("notify", {
            message: msg,
            type: "warning",
          });
        });
      if (mode === "excel") {
        const tmpName = this.$store.getters.getCustomerEfficiencyExel.tmpname;
        const fileName = this.$store.getters.getCustomerEfficiencyExel.name;

        //console.log(tmpName,fileName);
        this.downloadFile(tmpName, fileName);
      }
      this.loading = false;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-contract.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    formatCurrency(row, column, cellValue) {
      return this.$options.filters.formatCurrency(cellValue);
    }
  },
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    this.$store.commit(INVALID_CUSTOMER_EFFICIENCY);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.percentageValue {
  color: #383838;
  font-weight: bold;
}
</style>
