import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_QUESTIONS,
  QUESTIONS_LOADING,
  QUESTIONS_LOADING_SUCCESS,
  QUESTIONS_LOADING_ERROR,
  QUESTIONS_INVALID,
  SAVE_QUESTION,
  QUESTION_SAVING,
  QUESTION_SAVING_SUCCESS,
  QUESTION_SAVING_ERROR
} from 'src/store/actions/questions/questions'

const storeObjectConfig = {
  objectName: "Questions",
  getterName: "getQuestions",
  loadActionName: LOAD_QUESTIONS,
  loadingMutationName: QUESTIONS_LOADING,
  loadingSuccessMutationName: QUESTIONS_LOADING_SUCCESS,
  loadingErrorMutationName: QUESTIONS_LOADING_ERROR,
  invalidMutationName: QUESTIONS_INVALID,
  saveActionName: SAVE_QUESTION,
  savingMutationName: QUESTION_SAVING,
  savingSuccessMutationName: QUESTION_SAVING_SUCCESS,
  savingErrorMutationName: QUESTION_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/questions",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/questions/questions",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
