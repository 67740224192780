<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 v-if="!editMode" class="card-title">Nowy endpoint</h4>
        <h4 v-if="editMode" class="card-title">Edycja endpointu</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Nazwa endpointu</label>
          <fg-input
            type="text"
            name="endpoint_name"
            data-vv-as="Nazwa"
            v-validate="modelValidations.endpoint_name"
            :error="getError('endpoint_name')"
            v-model="model.endpoint_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Opis</label>
          <fg-input
            type="text"
            name="endpoint_description"
            data-vv-as="Opis"
            v-validate="modelValidations.endpoint_description"
            :error="getError('endpoint_description')"
            v-model="model.endpoint_description"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Aktywny</label>
          <fg-input>
            <el-switch
              v-model="model.endpoint_is_active"
              active-value="true"
              inactive-value="false"
            ></el-switch>
          </fg-input>
        </div>
      </div>
      <div class="card-footer text-center">
        <router-link to="/admin/endpoints">
          <p-button type="default" link style="margin-right: 5vw">Anuluj</p-button>
        </router-link>
        <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Select, Option, Switch } from "element-ui";

import {
  SAVE_ENDPOINT,
  ENDPOINTS_INVALID
} from "src/store/actions/admin/endpoints";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  data() {
    return {
      model: {
        endpoint_id: null,
        endpoint_name: "",
        endpoint_description: "",
        endpoint_is_active: "true"
      },
      modelValidations: {
        endpoint_id: {
          numeric: true,
          min: 1
        },
        endpoint_name: {
          required: true,
          regex: regexp.URL_REGEXP,
          min: 3,
          max: 32
        },
        endpoint_description: {
          // regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .-]+)$",
          regex: regexp.TEXT_REGEXP,
          max: 128
        }
      },
      editMode: true
    };
  },
  computed: {
    endpoint_id() {
      return this.$route.params.id;
    }
  },
  methods: {
    loadData() {},
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();

      if (isValid) {
        await this.$store.dispatch(SAVE_ENDPOINT, this.model).catch(error => {
          let message = "";
          console.log(error.response);
          switch (error.response.data.code) {
            case 409:
              message = "Nazwy muszą być unikalne!";
              break;
            default:
              message = "Błąd zapisu danych!";
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning"
          });
        });

        if (this.$store.getters.getEndpointsModifiedRecords === true) {
          eventBus.$emit("notify", {
            message:
              "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
            type: "success"
          });

          this.$store.commit(ENDPOINTS_INVALID); // oznacz nieaktualność danych
          this.$router.push({ name: "Endpoint" }); // przekieruj do listy
        }
      }
    }
  },
  created() {
    this.loadData();
    if (!!this.endpoint_id) {
      let endpoint = this.$store.getters.getEndpoints[this.endpoint_id];
      this.model = Object.assign(this.model, endpoint);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  }
};
</script>
<style>
</style>


