import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_GROUP_PROJECT_NAME,
  GROUP_PROJECT_NAME_LOADING,
  GROUP_PROJECT_NAME_LOADING_SUCCESS,
  GROUP_PROJECT_NAME_LOADING_ERROR,
  GROUP_PROJECT_NAME_INVALID,
  SAVE_GROUP_PROJECT_NAME,
  GROUP_PROJECT_NAME_SAVING,
  GROUP_PROJECT_NAME_SAVING_SUCCESS,
  GROUP_PROJECT_NAME_SAVING_ERROR
} from 'src/store/actions/stats/group_project_name'

const storeObjectConfig = {
  objectName: "DisplayStats",
  getterName: "getGroupProjectName",
  loadActionName: LOAD_GROUP_PROJECT_NAME,
  loadingMutationName: GROUP_PROJECT_NAME_LOADING,
  loadingSuccessMutationName: GROUP_PROJECT_NAME_LOADING_SUCCESS,
  loadingErrorMutationName: GROUP_PROJECT_NAME_LOADING_ERROR,
  invalidMutationName: GROUP_PROJECT_NAME_INVALID,
  saveActionName: SAVE_GROUP_PROJECT_NAME,
  savingMutationName: GROUP_PROJECT_NAME_SAVING,
  savingSuccessMutationName: GROUP_PROJECT_NAME_SAVING_SUCCESS,
  savingErrorMutationName: GROUP_PROJECT_NAME_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/stats/groupProjectName",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
