export const LOAD_FILTER_EXCEPTION = "LOAD_FILTER_EXCEPTION";
export const FILTER_EXCEPTION_LOADING = "FILTER_EXCEPTION_LOADING";
export const FILTER_EXCEPTION_LOADING_SUCCESS = "FILTER_EXCEPTION_LOADING_SUCCESS";
export const FILTER_EXCEPTION_LOADING_ERROR = "FILTER_EXCEPTION_LOADING_ERROR";
export const FILTER_EXCEPTION_INVALID = "FILTER_EXCEPTION_INVALID";

export const SAVE_FILTER_EXCEPTION = "SAVE_FILTER_EXCEPTION";
export const FILTER_EXCEPTION_SAVING = "FILTER_EXCEPTION_SAVING";
export const FILTER_EXCEPTION_SAVING_SUCCESS = "FILTER_EXCEPTION_SAVING_SUCCESS";
export const FILTER_EXCEPTION_SAVING_ERROR = "FILTER_EXCEPTION_SAVING_ERROR";

export const DELETE_FILTER_EXCEPTION = "DELETE_FILTER_EXCEPTION";
export const FILTER_EXCEPTION_DELETING = "FILTER_EXCEPTION_DELETING";
export const FILTER_EXCEPTION_DELETING_SUCCESS = "FILTER_EXCEPTION_DELETING_SUCCESS";
export const FILTER_EXCEPTION_DELETING_ERROR = "FILTER_EXCEPTION_DELETING_ERROR";
