<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 v-if="!editMode" class="card-title">Nowy partner</h4>
        <h4 v-if="editMode" class="card-title">Edycja partnera</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Nazwa Partnera</label>
          <fg-input
            type="text"
            name="partner_name"
            data-vv-as="Nazwa partnera"
            v-validate="modelValidations.partner_name"
            :error="getError('partner_name')"
            v-model="model.partner_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Opis partnera</label>
          <fg-input
            type="text"
            name="description"
            data-vv-as="Opis partnera"
            v-validate="modelValidations.description"
            :error="getError('description')"
            v-model="model.description"
          ></fg-input>
        </div>

        <hr />
        <h6>Dane szczegółowe</h6>

        <div class="form-group">
          <label>Pełna nazwa</label>
          <fg-input
            type="text"
            name="company_name"
            data-vv-as="Pełna nazwa"
            v-validate="dataModelValidations.company_name"
            :error="getError('company_name')"
            v-model="dataModel.company_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Ulica</label>
          <fg-input
            type="text"
            name="street"
            data-vv-as="Ulica"
            v-validate="dataModelValidations.street"
            :error="getError('street')"
            v-model="dataModel.street"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Kod pocztowy</label>
          <fg-input
            type="text"
            name="postcode"
            data-vv-as="Kod pocztowy"
            v-validate="dataModelValidations.postcode"
            :error="getError('postcode')"
            v-model="dataModel.postcode"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Miasto</label>
          <fg-input
            type="text"
            name="city"
            data-vv-as="Miasto"
            v-validate="dataModelValidations.city"
            :error="getError('city')"
            v-model="dataModel.city"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>NIP</label>
          <fg-input
            type="text"
            name="nip"
            data-vv-as="NIP"
            v-validate="dataModelValidations.nip"
            :error="getError('nip')"
            v-model="dataModel.nip"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>REGON</label>
          <fg-input
            type="text"
            name="regon"
            data-vv-as="REGON"
            v-validate="dataModelValidations.regon"
            :error="getError('regon')"
            v-model="dataModel.regon"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>KRS</label>
          <fg-input
            type="text"
            name="krs"
            data-vv-as="KRS"
            v-validate="dataModelValidations.krs"
            :error="getError('krs')"
            v-model="dataModel.krs"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Aktywny</label>
          <fg-input>
            <el-switch v-model="model.active" active-value="true" inactive-value="false"></el-switch>
          </fg-input>
        </div>
      </div>
      <div class="card-footer text-center">
        <router-link to="/admin/partners">
          <p-button type="default" link style="margin-right: 5vw">
            Anuluj
          </p-button>
        </router-link>
        <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Select, Option, Switch } from "element-ui";

import { SAVE_PARTNER, PARTNERS_INVALID } from "src/store/actions/admin/partners";
import { LOAD_PARTNER_DATA, PARTNER_DATA_INVALID, SAVE_PARTNER_DATA } from "src/store/actions/admin/partner_data";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
  },
  data() {
    return {
      model: {
        partner_id: null,
        partner_name: "",
        description: "",
        active: "true",
      },
      dataModel: {
        id: null,
        partner_id: null,
        parent_partner_id: null,
        name: "",
        company_name: "",
        street: "",
        postcode: "",
        city: "",
        nip: "",
        regon: "",
        krs: "",
        active: "true",
      },
      modelValidations: {
        partner_id: {
          numeric: true,
          min: 1,
        },
        partner_name: {
          required: true,
          regex: regexp.NAME_REGEXP,
          min: 3,
          max: 32,
        },
        description: {
          regex: regexp.TEXT_REGEXP,
          max: 128,
        },
        active: {
          required: true,
          included: ["true", "false"],
        },
      },
      dataModelValidations: {
        id: {
          numeric: true,
          min: 1,
        },
        partner_id: {
          numeric: true,
          min: 1,
        },
        parent_partner_id: {
          numeric: true,
          min: 1,
        },
        name: {
          required: true,
          regex: regexp.NAME_REGEXP,
          min: 2,
          max: 32,
        },
        company_name: {
          regex: regexp.TEXT_REGEXP,
          min: 2,
          max: 128,
        },
        street: {
          regex: regexp.TEXT_REGEXP,
          min: 2,
          max: 64,
        },
        postcode: {
          regex: "^[-0-9]+$",
          min: 2,
          max: 6,
        },
        city: {
          regex: regexp.TEXT_REGEXP,
          min: 2,
          max: 32,
        },
        nip: {
          regex: "^[0-9]+$",
          min: 2,
          max: 10,
        },
        regon: {
          regex: "^[0-9]+$",
          min: 2,
          max: 14,
        },
        krs: {
          regex: "^[0-9]+$",
          min: 2,
          max: 10,
        },
      },
      editMode: true,
    };
  },
  computed: {
    partner_id() {
      return this.$route.params.id;
    },
    partner_data() {
      return this.$store.getters.getPartnerData[this.partner_id];
    },
  },
  methods: {
    async loadData(force) {
      const filters = {};

      if (force) {
        this.$store.commit(PARTNER_DATA_INVALID);
      }

      await this.$store.dispatch(LOAD_PARTNER_DATA, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.initData();
    },
    initData() {
      if (!!this.partner_id) {
        let partner = this.$store.getters.getPartners[this.partner_id];
        let partnerData = this.$store.getters.getPartnerData[this.partner_id];
        this.model = Object.assign(this.model, partner);
        this.dataModel = Object.assign(this.dataModel, partnerData);
        this.dataModel.name = this.model.partner_name;

        if (partnerData && partnerData.krs) {
          let krs = partnerData.krs.toString();
          while (krs.length < 10) {
            krs = "0" + krs;
          }

          this.dataModel.krs = krs;
        }

        this.editMode = true;
      } else {
        this.editMode = false;
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();

      if (isValid) {
        this.dataModel.name = this.model.partner_name;
        await this.$store.dispatch(SAVE_PARTNER, this.model).catch(error => {
          let message = "";
          console.log(error.response);
          switch (error.response.data.code) {
            case 409:
              message = "Nazwy muszą być unikalne!";
              break;
            default:
              message = "Błąd zapisu danych!";
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning",
          });
        });

        let insertedID = null;

        if (this.$store.getters.getPartnersModifiedRecords.affected_rows > 0) {
          insertedID = this.$store.state.partners.saving_result.returning_id;
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
            type: "success",
          });

          if (insertedID) {
            this.dataModel.partner_id = insertedID;
          }

          await this.$store.dispatch(SAVE_PARTNER_DATA, this.dataModel).catch(error => {
            let message = "";
            console.log(error.response);
            switch (error.response.data.code) {
              case 409:
                message = "Nazwy muszą być unikalne!";
                break;
              default:
                message = "Błąd zapisu danych!";
            }
            eventBus.$emit("notify", {
              message: message,
              type: "warning",
            });
          });
          if (this.$store.getters.getPartnerDataModifiedRecords === true) {
            eventBus.$emit("notify", {
              message: "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
              type: "success",
            });
          }

          this.$store.commit(PARTNERS_INVALID); // oznacz nieaktualność danych
          this.$store.commit(PARTNER_DATA_INVALID); // oznacz nieaktualność danych
          this.$router.push({ name: "Partnerzy" }); // przekieruj do listy
        }
      }
    },
  },
  async created() {
    await this.loadData();
    this.initData();
  },
};
</script>
<style></style>
