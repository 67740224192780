// https://github.com/sqreen/vue-authentication-example/tree/master/src
// https://blog.sqreen.io/authentication-best-practices-vue/

import Vue from "vue";
import Vuex from "vuex";

// Admin
import profile from "./modules/admin/profile";
import auth from "./modules/auth";
import common from "./modules/common";
import partners from "./modules/admin/partners";
import users from "./modules/admin/users";
import users_import from "./modules/admin/users_import";
import users_list from "./modules/admin/users_list";
import systems from "./modules/admin/systems";
import roles from "./modules/admin/roles";
import permissions from "./modules/admin/permissions";
import user_roles from "./modules/admin/user_roles";
import role_privs from "./modules/admin/role_privs";
import endpoints from "./modules/admin/endpoints";
import priv_types from "./modules/admin/priv_types";
import scopes from "./modules/admin/scopes";
import scope_types from "./modules/admin/scope_types";
import user_scopes from "./modules/admin/user_scopes";
import password from "./modules/admin/password";
import user_password from "./modules/admin/user_password";
import databases from "./modules/admin/databases";
import profile_prefs from "./modules/admin/profile_prefs";
import dashboard_stats from "./modules/stats/dashboard_stats";
import partner_data from "./modules/admin/partner_data";
import system_params from "./modules/admin/system_params";

// Questions
import questions from "./modules/questions/questions";
import question_list from "./modules/questions/question_list";
import pause_question from "./modules/questions/pause_question";
import project_questions from "./modules/questions/project_questions";
import question_projects from "./modules/questions/question_projects";
import question_projects_list from "./modules/questions/question_projects_list";
import projects from "./modules/questions/projects";
import projects_all from "./modules/questions/projects_all";
import project_configs from "./modules/questions/project_configs";
import question_configs from "./modules/questions/question_configs";
import question_order from "./modules/questions/question_order";
import subquestion_parents from "./modules/questions/subquestion_parents";
import answers from "./modules/questions/answers";
import project_limits from "./modules/questions/project_limits";
import clone_question from "./modules/questions/clone_question";
import project_dict from "./modules/questions/project_dict";
import groups from "./modules/questions/groups";
import question_group from "./modules/questions/question_group";
import pause_answer from "./modules/questions/pause_answer";

// Questions forms
import question_data from "./modules/questionForm/question_data";
import criterias from "./modules/questionForm/criterias";
import question_criteria from "./modules/questionForm/question_criteria";
import clients from "./modules/questionForm/clients";
import answer_client from "./modules/questionForm/answer_client";
import answer_stats from "./modules/questionForm/answer_stats";
import answer_rules from "./modules/questionForm/answer_rules";
import question_templates from "./modules/questionForm/question_templates";
import question_template_field_types from "./modules/questionForm/question_template_field_types";
import answer_field_types from "./modules/questionForm/answer_field_types";
import categorys from "./modules/questionForm/categorys";
import tags from "./modules/questionForm/tags";
import answer_categorys from "./modules/questionForm/answer_categorys";
import answer_tags from "./modules/questionForm/answer_tags";
import postcodes from "./modules/questionForm/postcodes";
import postcodes_count from "./modules/questionForm/postcodes_count";
import prizes from "./modules/questions/prizes";

// Consents
import consents from "./modules/consents/consents";
import consents_dict from "./modules/consents/consents_dict";
import consents_partners from "./modules/consents/consents_partners";
import consents_partners_dict from "./modules/consents/consents_partners_dict";
import consents_owners from "./modules/consents/consents_owners";

// Filter - records
import filter_analyser from "./modules/filter/filter_analyser";
import filter_analyse_details from "./modules/filter/filter_analyse_details";
import filter_analyse_data from "./modules/filter/filter_analyse_data";
import filter_panel_validator from "./modules/filter/filter_panel_validator";
import filter_config from "./modules/filter/filter_config";
import filter_consistency_raport from "./modules/filter/filter_consistency_raport";
import filter_exception from "./modules/filter/filter_exception";

// Raports
import hlr_raport from "./modules/raports/hlr_raport";
import mailing_raport from "./modules/raports/mailing_raport";
import settlements_partners from "./modules/raports/settlements_partners";
import settlements from "./modules/raports/settlements";
import quiz from "./modules/raports/quiz";
import export_quiz from "./modules/raports/export_quiz";
import events_raport from "./modules/raports/events_raport";
import events_raport_dashboard from "./modules/raports/events_raport_dashboard";

// Secure - sales
import display_costs from "./modules/secure/display_costs";
import display_costs_upload from "./modules/secure/display_costs_upload";
import client_rates from "./modules/secure/client_rates";
import unique_clients from "./modules/secure/unique_clients";
import display_efficiency from "./modules/secure/display_efficiency";
import mailing_efficiency from "./modules/secure/mailing_efficiency";
import mailing_efficiency_const from "@/store/modules/secure/mailing_efficiency_const";
import mailing_efficiency_const_excel from "@/store/modules/secure/mailing_efficiency_const_excel";
import sms_efficiency from "./modules/secure/sms_efficiency";
import status_production_raport from "./modules/secure/statusRaport/status_production_raport";
import status_sales_raport from "./modules/secure/statusRaport/status_sales_raport";
import status_sales_details from "./modules/secure/statusRaport/status_sales_details";
import status_raport_chart_data from "./modules/secure/statusRaport/status_raport_chart_data";
import upselling_income from "./modules/secure/upselling_income";
import upselling_income_schema from "./modules/secure/upselling_income_schema";
import sales_raport from "./modules/secure/sales_raport";
import sales_raport_excel from "./modules/secure/sales_raport_excel";
import creations_efficiency from "./modules/secure/creations_efficiency";
import creations_efficiency_export from "./modules/secure/creations_efficiency_export";
import questions_efficiency_projects from "./modules/secure/questions_efficiency_projects";
import questions_efficiency_questions from "./modules/secure/questions_efficiency_questions";
import banner_sales from "./modules/secure/banner_sales";
import mailing_costs from "./modules/secure/mailing_costs";
import display_efficiency_exel from "@/store/modules/secure/display_efficiency_exel";

// Sales
import sales_limits from "./modules/sales/sales_limits";
import limits_unique_clients from "./modules/sales/limits_unique_clients";
import user_from_complaints from "./modules/sales/user_from_complaints";
import user_from_complaints_list from "./modules/sales/user_from_complaints_list";
import deduplication from "./modules/sales/deduplication";
import deduplication_clients from "./modules/sales/deduplication_clients";
import deduplication_active_clients from "./modules/sales/deduplication_active_clients";
import customer_efficiency from "./modules/sales/customer_efficiency";
import customer_efficiency_excel from "@/store/modules/sales/customer_efficiency_excel";
import sms_efficiency_excel from "@/store/modules/secure/sms_efficiency_excel";
import mailing_efficiency_excel from "@/store/modules/secure/mailing_efficiency_excel";
import questions_efficiency_excel from "@/store/modules/secure/questions_efficiency_excel";
import baners_efficiency from "@/store/modules/sales/baners_efficiency";


// Complaints
import complaints from "./modules/complaints/complaints";
import requests from "./modules/complaints/requests";
import user_data from "./modules/complaints/user_data";
import response_templates from "./modules/complaints/response_templates";
import mailboxes from "./modules/complaints/mailboxes";
import user_history from "./modules/complaints/user_history";
import responses from "./modules/complaints/responses";
import user_group_list from "./modules/complaints/user_group_list";
import user_sales from "./modules/complaints/user_sales";
import user_mailings from "./modules/complaints/user_mailings";
import complaints_dashboard from "./modules/complaints/complaints_dashboard";
import response_files from "./modules/complaints/response_files";
import user_consents from "./modules/complaints/user_consents";
import complaints_user_dashboard from "./modules/complaints/complaints_user_dashboard";
import complaints_history from "./modules/complaints/complaints_history";
import user_data_counter from "./modules/complaints/user_data_counter";
import macro from "./modules/complaints/macro";
import user_group from "./modules/complaints/user_group";
import mailbox_templates from "./modules/complaints/mailbox_templates";
import company from "./modules/complaints/company";
import response_types from "./modules/complaints/response_types";
import phone_history from "./modules/complaints/phone_history";

// Complaints - actions
import set_spam from "./modules/complaints/actions/set_spam";
import set_response from "./modules/complaints/actions/set_response";
import set_forward from "./modules/complaints/actions/set_forward";
import set_status from "./modules/complaints/actions/set_status";
import set_close from "./modules/complaints/actions/set_close";
import set_api_call from "./modules/complaints/actions/set_api_call";
import blacklist_lead from "./modules/complaints/actions/blacklist_lead";
import upload_complaint_flow_files from "./modules/complaints/actions/upload_complaint_flow_files";
import create_complaint from "./modules/complaints/actions/create_complaint";

// Blacklist
import blacklist_check from "./modules/blacklist/blacklist_check";
import blacklist_history from "./modules/blacklist/blacklist_history";
import blacklist_remove from "./modules/blacklist/blacklist_remove";
import blacklist_add from "./modules/blacklist/blacklist_add";
import blacklist_msisdn_criteria from "./modules/blacklist/blacklist_msisdn_criteria";
import blacklist_email_criteria from "./modules/blacklist/blacklist_email_criteria";
import blacklist_msisdn from "./modules/blacklist/blacklist_msisdn";
import blacklist_email from "./modules/blacklist/blacklist_email";

// Blacklist dictionaries
import blacklist_owner from "./modules/complaints/dict/blacklist_owner";
import blacklist_project from "./modules/complaints/dict/blacklist_project";

// Stats
import display_stats from "./modules/stats/display_stats";
import question_answer_stats from "./modules/stats/question_answer_stats";
import display_stats_details from "./modules/stats/display_stats_details";
import question_answer_stats_details from "./modules/stats/question_answer_stats_details";
import media_house from "./modules/stats/media_house";
import banner_config from "./modules/stats/banner_config";
import display_stats_chart from "./modules/stats/display_stats_chart";
import group_project_name from "./modules/stats/group_project_name";
import mailing_stats from "./modules/stats/mailing_stats";
import mailing_stats_chart_data from "./modules/stats/mailing_stats_chart_data";
import dashboard_mailing_stats from "./modules/stats/dashboard_mailing_stats";
import export_display_stats from "./modules/stats/export_display_stats";
import questions_efficiency_dashboard from "./modules/stats/questions_efficiency_dashboard";
import dashboard_sales_stats from "./modules/stats/dashboard_sales_stats";
import conversion_stats from "./modules/stats/conversion_stats";
import question_api_stats from "./modules/stats/question_api_stats";
import lead_stats from "./modules/stats/lead_stats";
import question_activity from "./modules/stats/question_activity";
import mailing_stats_demography from "./modules/stats/mailing_stats_demography";
import mailing_stats_hour from "./modules/stats/mailing_stats_hour";
import mailing_stats_efficiency from "./modules/stats/mailing_stats_efficiency";
import export_mailing_stats from "./modules/stats/export_mailing_stats";
import question_answer_custom from "./modules/stats/question_answer_custom";
import demography_stats from "./modules/stats/demography_stats";
import baners_stats from "@/store/modules/stats/baners_stats";

//Mailings
import mailings from "./modules/mailings/work_campaigns";
import mailer_lists from "@/store/modules/mailings/mailer_lists";

// Tests
import tests_results from "./modules/tests/tests_results";
import tests_dashboard from "./modules/tests/tests_dashboard";
import tests_errors from "./modules/tests/tests_errors";
import tests_requests from "./modules/tests/tests_requests";
import hotjar_config from "./modules/tests/hotjar_config";
import tests_projects from "./modules/tests/tests_projects";

// Mailings
import pbr from "./modules/mailings/pbr";
import mailingsDashboardSends from "@/store/modules/mailings/mailingsDashboardSends";

// api-ip actions
import unsubscribe_lead from "./modules/blacklist/unsubscribe_lead";
import send_email from "./modules/api-ip/send_email";

// Other
import notifications from "./modules/notifications";
import echo from "./modules/echo";


//Korejestracja
import ko_sends from "@/store/modules/korejestracja/ko_sends";
import ko_questions from "@/store/modules/korejestracja/ko_questions";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    profile,
    auth,
    common,
    partners,
    users,
    users_import,
    systems,
    roles,
    permissions,
    user_roles,
    role_privs,
    endpoints,
    priv_types,
    scopes,
    scope_types,
    user_scopes,
    password,
    user_password,
    hlr_raport,
    mailing_raport,
    databases,
    questions,
    question_list,
    pause_question,
    projects,
    project_configs,
    project_questions,
    question_projects,
    question_projects_list,
    question_configs,
    question_order,
    subquestion_parents,
    settlements_partners,
    settlements,
    quiz,
    answers,
    question_data,
    groups,
    question_group,
    criterias,
    question_criteria,
    clients,
    answer_client,
    answer_stats,
    answer_rules,
    question_templates,
    question_template_field_types,
    answer_field_types,
    categorys,
    tags,
    echo,
    answer_categorys,
    answer_tags,
    postcodes,
    project_limits,
    postcodes_count,
    notifications,
    display_stats,
    question_answer_stats,
    media_house,
    banner_config,
    display_stats_chart,
    profile_prefs,
    dashboard_stats,
    group_project_name,
    prizes,
    partner_data,
    display_costs,
    display_costs_upload,
    complaints,
    requests,
    client_rates,
    unique_clients,
    display_efficiency,
    mailing_efficiency,
    sms_efficiency,
    status_production_raport,
    status_sales_raport,
    status_sales_details,
    status_raport_chart_data,
    upselling_income,
    sales_raport,
    sales_raport_excel,
    user_data,
    clone_question,
    mailing_stats,
    mailing_stats_chart_data,
    project_dict,
    dashboard_mailing_stats,
    export_display_stats,
    creations_efficiency,
    creations_efficiency_export,
    questions_efficiency_projects,
    questions_efficiency_questions,
    consents,
    consents_dict,
    users_list,
    projects_all,
    consents_partners_dict,
    consents_partners,
    filter_analyser,
    filter_analyse_details,
    filter_analyse_data,
    filter_panel_validator,
    filter_config,
    consents_owners,
    questions_efficiency_dashboard,
    upselling_income_schema,
    dashboard_sales_stats,
    export_quiz,
    sales_limits,
    limits_unique_clients,
    user_from_complaints,
    user_from_complaints_list,
    customer_efficiency,
    display_stats_details,
    question_answer_stats_details,
    banner_sales,
    tests_results,
    tests_dashboard,
    tests_errors,
    tests_requests,
    response_templates,
    mailboxes,
    user_history,
    responses,
    user_group_list,
    set_spam,
    set_response,
    set_forward,
    set_status,
    set_close,
    hotjar_config,
    user_sales,
    user_mailings,
    complaints_dashboard,
    conversion_stats,
    response_files,
    unsubscribe_lead,
    blacklist_lead,
    user_consents,
    set_api_call,
    send_email,
    upload_complaint_flow_files,
    create_complaint,
    complaints_user_dashboard,
    complaints_history,
    user_data_counter,
    macro,
    user_group,
    system_params,
    mailbox_templates,
    mailing_costs,
    company,
    response_types,
    question_api_stats,
    filter_consistency_raport,
    filter_exception,
    blacklist_check,
    blacklist_history,
    blacklist_remove,
    blacklist_add,
    lead_stats,
    question_activity,
    events_raport,
    events_raport_dashboard,
    tests_projects,
    mailings,
    blacklist_owner,
    blacklist_project,
    pbr,
    mailing_stats_demography,
    mailing_stats_hour,
    mailing_stats_efficiency,
    export_mailing_stats,
    phone_history,
    question_answer_custom,
    pause_answer,
    demography_stats,
    deduplication,
    deduplication_clients,
    deduplication_active_clients,
    blacklist_msisdn_criteria,
    blacklist_msisdn,
    blacklist_email_criteria,
    blacklist_email,
    display_efficiency_exel,
    customer_efficiency_excel,
    sms_efficiency_excel,
    mailing_efficiency_excel,
    questions_efficiency_excel,
    baners_stats,
    ko_questions,
    ko_sends,
    mailingsDashboardSends,
    baners_efficiency,
    mailer_lists,
    mailing_efficiency_const,
    mailing_efficiency_const_excel
  },
  strict: debug,
});
