<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <h5 class="title">Edycja zgód</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8 col-md-4">
        <label>Projekt</label>
        <fg-input :error="getError('project_id')" data-vv-as="Projekt">
          <el-select
            id="ConsentsFormProjectSelect"
            v-on:visible-change="getUpperLeftSelectWidth()"
            v-model="project_id"
            name="project_id"
            data-vv-as="Projekt"
            filterable
            v-on:input="loadData()"
          >
            <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
              <el-option
                :style="upperLeftSelectWidth !== null ? 'width: ' + upperLeftSelectWidth + 'px' : 'width: 300px'"
                :id="'ConsentsFormProjectSelectOption_' + item.project_id"
                v-for="item in group.options"
                :key="item.project_id"
                :label="item.project_name + ' [' + item.project_id + ']'"
                :value="item.project_id"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </fg-input>
      </div>
      <div class="col-sm-4 col-md-2">
        <label>Wersja</label>
        <fg-input :error="getError('version')" data-vv-as="Wersja">
          <el-select
            id="ConsentsFormVersionSelect"
            v-on:visible-change="getUpperRightSelectWidth()"
            v-model="version"
            name="version"
            data-vv-as="Wersja"
            v-on:input="versionChanged(version)"
            filterable
            clearable
          >
            <el-option
              :style="upperRightSelectWidth !== null ? 'width: ' + upperRightSelectWidth + 'px' : 'width: 300px'"
              :id="'ConsentsFormVersionSelectOption_' + version.module"
              v-for="version in activeProjectModules"
              :label="version.module"
              :value="version.module"
              :key="version.module"
            ></el-option>
          </el-select>
        </fg-input>
      </div>
      <div class="col-sm-12 col-md-6 text-md-right right-bottom" v-if="project_id && editPermitted">
        <p-button style="margin-right: 5px;" type="warning" @click.prevent="cancel()">Anuluj</p-button>
        <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="tableData" style="width: 100%; font-size: 12px;" :row-style="rowStyle" :key="tableKey" :highlight-current-row="false">
              <el-table-column label="Typ" width="70" align="left" property="tresc_zgody">
                <template v-slot="props">
                  {{ props.row.typ_zgody }}
                </template>
              </el-table-column>
              <el-table-column label="ID" width="70" align="left" property="zgody_slownik_id"></el-table-column>
              <el-table-column label="Admin" width="120" align="left" property="owners">
                <template v-slot="props">
                  <span :style="!checkMainOwner(props.row.owners) ? 'font: bold; color: #dc3545;' : ''">{{ getOwners(props.row.owners) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Treść" min-width="380" align="left" property="tresc_zgody">
                <template v-slot="props">
                  <div class="row" style="cursor: pointer;" @click="showModal(props.row)">
                    <div class="col-sm-11">
                      <div style="word-break: keep-all;">
                        {{ props.row.tresc_zgody }}
                      </div>
                    </div>
                    <div class="col-sm-1 center">
                      <i class="fa fa-edit fa-2x" aria-hidden="true"></i>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Wymagane" width="130" align="center" property="requaier">
                <template v-slot="props">
                  <el-switch v-model="props.row.requaier" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="Pozycja" width="120" align="center" property="position">
                <template v-slot="props">
                  <div class="row">
                    <div class="col">
                      <el-select v-model="props.row.position" placeholder="Pozycja" style="width: 60px;">
                        <el-option v-for="position in ['1', '2', '3', '4', '5', '6', '7', '8']" :key="position" :label="position" :value="position">
                        </el-option>
                      </el-select>
                    </div>
                    <div style="display: flex;">
                      <el-tooltip
                        class="item"
                        effect="light"
                        :content="props.row.position === null ? 'Pozycja zgody jest pusta' : 'Pozycje zgód nakładają się'"
                        placement="top"
                        :open-delay="200"
                      >
                        <div class="positionBadge">
                          <el-badge
                            value="!"
                            class="item"
                            type="danger"
                            v-if="props.row.tresc_zgody !== null && consentsPositionObject[props.row.position] > 1"
                          ></el-badge>
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Dodał" min-width="100" align="center" property="user_id">
                <template v-slot="props">
                  <div style="word-break: keep-all;">{{ getUserName(props.row.user_id) }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="80">
                <template slot="header">
                  <!-- <p-button type="success" icon round @click="showModal('add', null)">
                    <i class="fa fa-plus"></i>
                  </p-button> -->
                </template>
                <template v-slot="props">
                  <p-button type="danger" size="sm" icon :disabled="props.row.zgody_slownik_id === null" @click="handleDelete(props.row)">
                    <i class="fa fa-trash"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
// ui components
import { Select, Option, OptionGroup, Input, Tag, Radio, Tooltip, Badge, Switch } from "element-ui";
import BackToTop from "vue-backtotop";
import Swal from "sweetalert2";

// vuex actions
import { LOAD_USERS_LIST, USERS_LIST_INVALID } from "src/store/actions/admin/users_list";
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_CONSENTS, CONSENTS_INVALID, SAVE_CONSENT } from "src/store/actions/consents/consents";

import ConsentsFormModal from "./../modals/ConsentsFormModal.vue";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Input.name]: Input,
    [Tag.name]: Tag,
    [Radio.name]: Radio,
    [Tooltip.name]: Tooltip,
    [Badge.name]: Badge,
    [Switch.name]: Switch,
    BackToTop,
  },
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      project_id: null,
      version: "",
      site_id: null,
      upperLeftSelectWidth: null,
      upperRightSelectWidth: null,
      tableKey: 0,
      defaultPosition: {
        1: 2,
        2: 3,
        3: 4,
        4: 5,
        5: 8,
        6: 6,
        7: 1,
        8: 7,
      },
      initKey: false,
      compareTableData: [],
    };
  },
  computed: {
    usersList() {
      return this.$store.getters.getUsersList;
    },
    projects() {
      return this.$store.getters.getProjectDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map(x => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    activeProjectModules() {
      // wszystkie moduły aktywnego projektu
      let projects = this.$store.getters.getProjectsAll;
      let filteredData = {};

      for (let [key, project] of Object.entries(projects)) {
        // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
        if (project.project_id === this.project_id) {
          filteredData[project.module] = Object.assign({}, project);
        }
      }

      let sourceData = [];

      for (let ver of Object.keys(filteredData)) {
        // iteracja po nazwach modułów danego projektu
        let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie liczby
        filteredData[ver].number = res;
      }

      sourceData = Object.values(filteredData);
      sourceData.sort((a, b) => a.number - b.number);
      let result = {};
      for (let i in sourceData) {
        result[sourceData[i].module] = sourceData[i];
      }

      return result;
    },
    consents() {
      return this.$store.getters.getConsents;
    },
    tableData() {
      if (this.project_id === null) {
        this.compareTableData = [];
        return [];
      }

      const emptyConsentBlueprint = {
        id: null,
        active: 1,
        kind: 1,
        position: null,
        project_id: this.project_id,
        site_id: this.site_id,
        requaier: false,
        zgody_slownik_id: null,
        user_id: null,
        typ_zgody: null,
        tresc_zgody: null,
      };

      let consentObj = {};

      for (let i = 1; i <= 8; i++) {
        // if(i !== 5) {
        consentObj[i] = JSON.parse(JSON.stringify(emptyConsentBlueprint));
        consentObj[i].typ_zgody = i;
        consentObj[i].position = this.defaultPosition[i];
        // }
      }

      let consents = Object.values(JSON.parse(JSON.stringify(this.consents)));
      let filteredConsents = consents.filter(
        consent =>
          (this.version === "" && consent.site_id === null && consent.kind === 1) ||
          (this.version !== null && this.version === consent.short_name && consent.kind === 1),
      );
      filteredConsents.forEach(consent => {
        consent.requaier = consent.requaier ? true : false;
        consentObj[consent.typ_zgody] = consent;
      });

      if (!this.initKey && this.$store.state.consents.loading_status === "success") {
        this.compareTableData = Object.values(JSON.parse(JSON.stringify(consentObj)));
        this.initKey = true;
      }

      // this.consentsAdminObjectMethod(Object.values(consentObj));
      return Object.values(consentObj);
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_CONSENTS);
    },
    consentsPositionObject() {
      const data = this.tableData;
      let obj = {};

      data.forEach(row => {
        if (!obj[row.position]) {
          obj[row.position] = 0;
        }
        obj[row.position] += 1;
      });
      return obj;
    },
    consentsAdminObject() {
      const data = this.tableData;
      let obj = {};
      let mainAdmin = "";
      let counter = 0;

      data.forEach(row => {
        if (row.owners) {
          row.owners.forEach(owner => {
            if (!obj[owner.name]) {
              obj[owner.name] = {};
            }
            obj[owner.name][row.typ_zgody] = 1;
          });
        }
      });

      for (let admin in obj) {
        if (admin !== "undefined") {
          let adminLength = Object.values(obj[admin]).length;
          if (adminLength > counter) {
            mainAdmin = admin;
          }
          counter = adminLength;
        }
      }

      return mainAdmin;
    },
    project_owner() {
      if (this.project_id) {
        return this.projects[this.project_id].owner;
      } else return null;
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(PROJECTS_ALL_INVALID);
        this.$store.commit(USERS_LIST_INVALID);
      }
      this.$store.commit(CONSENTS_INVALID);
      this.version = "";
      this.site_id = null;
      this.initKey = false;

      this.$store.dispatch(LOAD_USERS_LIST, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_CONSENTS, { project_id: this.project_id }).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getUserName(user_id) {
      if (this.usersList[user_id]) {
        return this.usersList[user_id].user_name;
      } else {
        return user_id;
      }
    },
    rowStyle({ row, rowIndex }) {
      if (this.$store.state.consents.loading_status === "success" && JSON.stringify(row) !== JSON.stringify(this.compareTableData[rowIndex])) {
        return "background-color: #ffe9a8; height: 80px;";
      }
      return "height: 80px;";
    },
    async saveData() {
      const model = [];
      for (let i in this.tableData) {
        if (JSON.stringify(this.tableData[i]) !== JSON.stringify(this.compareTableData[i])) {
          let row = JSON.parse(JSON.stringify(this.tableData[i]));
          delete row.tresc_zgody;
          model.push(row);
        }
      }
      if (model.length === 0) return;
      model.forEach(el => {
        el.requaier = el.requaier ? 1 : 0;
      });
      await this.$store.dispatch(SAVE_CONSENT, { model: model }).catch(error => {
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getConsentsModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Zgody zostały zmodyfikowane",
          type: "success",
        });

        this.$store.commit(CONSENTS_INVALID); // oznacz nieaktualność danych
        this.loadData();
      }
    },
    cancel() {
      eventBus.$emit("consentsTabChanged");
    },
    showModal(row) {
      // wyświetlenie modala edycji zgody
      let mode = row.id === null ? "add" : "edit";
      this.$modal.show(
        ConsentsFormModal,
        {
          mode: mode,
          row: row,
          project_name: this.projects[this.project_id].project_name,
          version: this.version,
          project_owner: this.project_owner,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(ConsentsFormModal);
              },
            },
          ],
        },
        {
          name: "ConsentsFormModal",
          draggable: false,
          scrollable: true,
          width: "50%",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    handleDelete(row) {
      let varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      let text = row.site_id === null ? "dla poziomu projektu" : 'dla poziomu wersji "' + row.short_name + '"';
      swalWithBootstrapButtons
        .fire({
          title: `Czy chcesz usunąć zgodę ${row.typ_zgody} dla projektu "${varThis.projects[row.project_id].project_name}"?`,
          text: `Zostanie usunięta zgoda ${text}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then(res => {
          if (res.isConfirmed) {
            varThis.deleteData(row);
          }
        })
        .catch(() => {
          return;
        });
    },
    async deleteData(data) {
      this.tableData.forEach(row => {
        if (data.id === row.id) {
          row.zgody_slownik_id = null;
          row.tresc_zgody = "";
          row.requaier = false;
          row.user_id = null;
          this.tableKey += 1;
        }
      });
    },
    getUpperLeftSelectWidth() {
      this.upperLeftSelectWidth = document.getElementById("ConsentsFormProjectSelect").offsetWidth;
    },
    getUpperRightSelectWidth() {
      this.upperRightSelectWidth = document.getElementById("ConsentsFormVersionSelect").offsetWidth;
    },
    versionChanged(version) {
      this.initKey = false;
      if (this.activeProjectModules[version]) {
        this.site_id = this.activeProjectModules[version].site_id;
      } else {
        this.site_id = null;
      }
    },
    getOwners(owners) {
      if (owners) {
        return owners.map(x => x.name).join(", ");
      } else return "";
    },
    checkMainOwner(owners) {
      if (owners) {
        let ownersArray = owners.map(x => x.name);
        if (ownersArray.includes(this.consentsAdminObject)) {
          return true;
        } else {
          return false;
        }
      } else return true;
    },
  },
  // async created() {
  //   eventBus.$on("forceReload", () => {
  //     this.loadData(true);
  //   });
  // },
  mounted() {
    eventBus.$on("consentsTabChanged", () => {
      this.project_id = null;
      this.version = "";
      this.site_id = null;
      this.initKey = false;
    });
    eventBus.$on("setConsentID", ({ changed, row, consent_id, tresc_zgody, owners }) => {
      if (changed) {
        this.tableData.forEach(x => {
          if (x.typ_zgody === row.typ_zgody) {
            x.zgody_slownik_id = consent_id;
            x.tresc_zgody = tresc_zgody;
            x.owners = owners;
            this.tableKey += 1;
          }
        });
      }
    });
  },
  // beforeDestroy: function () {
  //   eventBus.$off("forceReload");
  // },
};
</script>

<style lang="scss" scoped>
.highlight {
  font-size: 1.2em;
  font-weight: bold;
}

.consentRadio {
  margin-bottom: 0;
}

.consentTextarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.center {
  display: flex;
  justify-content: center;
  align-self: center;
}

.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}

.positionBadge {
  padding: 0px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-self: flex-end;
}
</style>
