<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h5 class="title">Poczta bez reklam</h5>
      </div>
      <div class="col-sm-12 col-md-4 text-md-right">
        <el-select v-model="selectedFilter" size="small" placeholder="Filtruj" @input="loadData()">
          <el-option v-for="filter in filters" :key="filter.value" :label="filter.label" :value="filter.value"></el-option>
        </el-select>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <el-table
          :cell-style="rowStyle"
          :header-cell-style="headerStyle"
          :data="pbr"
          style="width: 100%"
          :default-sort="{ prop: 'id', order: 'descending' }"
        >
          <el-table-column label="ID" property="id" width="80" align="center"></el-table-column>
          <el-table-column label="Telefon" property="phone" width="120" align="center"></el-table-column>
          <el-table-column label="Email" property="newEmail" min-width="120" align="left">
            <template v-slot="props">
              <div style="word-break: keep-all; cursor: pointer" @click="copyElement(props.row.generatedEmail)">{{ props.row.generatedEmail }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Status" property="status" width="120" align="right">
            <template v-slot="props">
              <div>
                {{ getStatus(props.row.status) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Data" property="insertDate" width="160" align="right"></el-table-column>
          <el-table-column label="Zapisz" property="save" width="80" align="center">
            <template v-slot="props">
              <p-button
                v-if="props.row.status === 'NEW'"
                type="success"
                size="sm"
                icon
                @click="handleModal(props.row)"
                :disabled="props.row.passwordSend ? true : false"
              >
                <i class="fa fa-floppy-o"></i>
              </p-button>
              <p-button v-if="props.row.status === 'ACTIVE'" type="success" size="sm" icon disabled>
                <i class="fa fa-floppy-o"></i>
              </p-button>
              <p-button v-if="props.row.status === 'TO_DELETE'" type="danger" size="sm" icon @click="handleDeleteModal(props.row)">
                <i class="fa fa-trash"></i>
              </p-button>
              <p-button v-if="props.row.status === 'DELETED'" type="danger" size="sm" icon disabled>
                <i class="fa fa-trash"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block center">
          <el-pagination
            v-on:current-change="loadData($event)"
            :hide-on-single-page="true"
            background
            layout="prev, pager, next"
            :total="maxPage * pageLimit"
            :page-size="pageLimit"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// event bus
import { eventBus } from "src/main";

import { Select, Option, DatePicker, Pagination } from "element-ui";
import Swal from "sweetalert2";

import { LOAD_PBR, DELETE_PBR, PBR_INVALID } from "src/store/actions/mailings/pbr";

import ModalPBR from "src/components/Utils4/mailings/ModalPBR.vue";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      loading: false,
      page: 1,
      maxPage: 1,
      pageLimit: 20,
      selectedFilter: "NEW",
      filters: [
        {
          label: "Wszystkie",
          value: "",
        },
        {
          label: "Nowy",
          value: "NEW",
        },
        {
          label: "Aktywny",
          value: "ACTIVE",
        },
        {
          label: "Do usunięcia",
          value: "TO_DELETE",
        },
        {
          label: "Usunięty",
          value: "DELETED",
        },
      ],
    };
  },
  methods: {
    async loadData(currentPage) {
      if (currentPage) {
        this.page = currentPage;
      } else {
        this.page = 1;
      }

      this.$store.commit(PBR_INVALID);

      this.loading = true;

      await this.$store.dispatch(LOAD_PBR, { page: currentPage, filter: this.selectedFilter }).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    generatePassword() {
      let length = 10,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      const index1 = Math.floor(Math.random() * length);
      const index2 = Math.floor(Math.random() * length);
      retVal = retVal.substring(0, index1) + "!" + retVal.substring(index1 + 1);
      retVal = retVal.substring(0, index2) + "@" + retVal.substring(index2 + 1);
      return retVal;
    },
    copyElement(element) {
      navigator.clipboard.writeText(element).then(
        function () {
          eventBus.$emit("notify", {
            message: "Skopiowano dane do schowka",
            type: "success",
          });
        },
        function (err) {
          eventBus.$emit("notify", {
            message: "Błąd kopiowania",
            type: "warning",
          });
        },
      );
    },
    handleModal(row) {
      this.$modal.show(
        ModalPBR,
        {
          id: row.id,
          phone: row.phone,
          email: row.newEmail,
          password: row.generatedPassword,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(ModalPBR);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "600px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    rowStyle({ row }) {
      return "font-size: 12px;";
    },
    headerStyle() {
      return "font-size: 12px;";
    },
    getStatus(status) {
      switch (status) {
        case "NEW":
          return "Nowy";
        case "ACTIVE":
          return "Aktywny";
        case "TO_DELETE":
          return "Do usunięcia";
        case "DELETED":
          return "Usunięty";
        default:
          return status;
      }
    },
    handleDeleteModal(row) {
      let varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: `Czy chcesz usunąć maila ${row.newEmail}?`,
          // text: `Zostanie usunięta zgoda ${text}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            varThis.deleteData(row);
          }
        })
        .catch(() => {
          return;
        });
    },
    async deleteData(row) {
      const record = {
        id: row.id,
      };

      await this.$store.dispatch(DELETE_PBR, record).catch((error) => {
        // zapisanie danych do bazy
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getPbrDeletingStatus === "deleted") {
        // wyświetlenie informacji o zapisaniu
        eventBus.$emit("notify", {
          message: "Rekord został usunięty",
          type: "success",
        });

        this.loadData();
      }
    },
  },
  computed: {
    pbr() {
      let data = [];
      if (this.$store.state.pbr.loading_status === "success") {
        data = this.$store.getters.getPbr["mail"];
        data.map((row) => {
          row.generatedPassword = this.generatePassword();
          row.generatedEmail = row.newEmail + "@fajnapoczta.com";
          return row;
        });
        this.maxPage = Math.ceil(this.$store.getters.getPbr.counter / this.pageLimit);
      }
      return data;
    },
  },
  created() {
    this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
    eventBus.$on("pbrSuccess", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.center {
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
}
</style>
