import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_UPSELLING_INCOME,
  UPSELLING_INCOME_LOADING,
  UPSELLING_INCOME_LOADING_SUCCESS,
  UPSELLING_INCOME_LOADING_ERROR,
  UPSELLING_INCOME_INVALID,
  SAVE_UPSELLING_INCOME,
  UPSELLING_INCOME_SAVING,
  UPSELLING_INCOME_SAVING_SUCCESS,
  UPSELLING_INCOME_SAVING_ERROR,
  DELETE_UPSELLING_INCOME,
  UPSELLING_INCOME_DELETING,
  UPSELLING_INCOME_DELETING_SUCCESS,
  UPSELLING_INCOME_DELETING_ERROR
} from 'src/store/actions/secure/upselling_income'

const storeObjectConfig = {
  objectName: "UpsellingIncome",
  getterName: "getUpsellingIncome",
  loadActionName: LOAD_UPSELLING_INCOME,
  loadingMutationName: UPSELLING_INCOME_LOADING,
  loadingSuccessMutationName: UPSELLING_INCOME_LOADING_SUCCESS,
  loadingErrorMutationName: UPSELLING_INCOME_LOADING_ERROR,
  invalidMutationName: UPSELLING_INCOME_INVALID,
  saveActionName: SAVE_UPSELLING_INCOME,
  savingMutationName: UPSELLING_INCOME_SAVING,
  savingSuccessMutationName: UPSELLING_INCOME_SAVING_SUCCESS,
  savingErrorMutationName: UPSELLING_INCOME_SAVING_ERROR,
  deleteActionName: DELETE_UPSELLING_INCOME,
  deletingMutationName: UPSELLING_INCOME_DELETING,
  deletingSuccessMutationName: UPSELLING_INCOME_DELETING_SUCCESS,
  deletingErrorMutationName: UPSELLING_INCOME_DELETING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/upsellingIncome",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/raports/upsellingIncome",
    method: "put" // {'get','post','put'}
  },
  deleteParams: {
    url: "/raports/upsellingIncome",
    method: "delete" // {'delete'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
