import axios from "axios";
import moment from "moment"; // time manipulation library

import uiVersion from "../uiVersion";

/*
example storeObjectConfig:
storeObjectConfig = {
  objectName: "Campaigns",
  getterName: "getCampaigns",
  loadActionName: LOAD_CAMPAIGNS,
  loadingMutationName: CAMPAIGNS_LOADING,
  loadingSuccessMutationName: CAMPAIGNS_LOADING_SUCCESS,
  loaingErrorMutationName: CAMPAIGNS_LOADING_ERROR,
  invalidMutationName: CAMPAIGNS_INVALID,
  saveActionName: SAVE_CAMPAIGN,
  savingMutationName: CAMPAIGN_SAVING,
  savingSuccessMutationName: CAMPAIGN_SAVING_SUCCESS,
  savingErrorMutationName: CAMPAIGN_SAVING_ERROR,
  deleteActionName: DELETE_CAMPAIGN,
  deletingMutationName: CAMPAIGN_DELETING,
  deletingSuccessMutationName: CAMPAIGN_DELETING_SUCCESS,
  deletingErrorMutationName: CAMPAIGN_DELETING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 30, // minutes
  recordType: 'json', // {'json', 'binary', 'plain'},
    binary are not stringified, json records are put in record structure
  loadParams: {
    url: "/c2s/campaigns",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/c2s/campaigns",
    method: "put" // {'get','post','put'}
  },
  deleteParams: {
    url: "/c2s/campaigns",
    method: "delete" // {'delete'}
  }
}
*/

function stateGenerator(storeObjectConfig) {
  return {
    data: {},
    filters: {},
    record: {},
    loading_status: "invalid", // status ostatniej operacji wczytywania z API: {'invalid', 'loading', 'sucess', 'error'}
    saving_status: "saved", // status ostatniej operacji zapisywania z API: {'saved', 'saving', 'error'}
    delete_status: "deleted", //status ostatniej operacji usuwania z API: {'deleted', 'deleting', 'error'}
    deleting_result: "", // wynik operacji usuwania
    saving_result: "", // wynik operacji zapisu
    validPeriod: storeObjectConfig.validPeriod, // minutes
    validTo: "2000-01-01 00:00:00",
  };
}

function gettersGenerator(storeObjectConfig) {
  return {
    [storeObjectConfig.getterName]: state => {
      return state.data;
    },
    [storeObjectConfig.getterName + "LoadingStatus"]: state => filters => {
      // use function to pass an argument to getter
      if (moment(Date.now()).isAfter(state.validTo)) {
        return "invalid";
      } else if (JSON.stringify(filters) !== JSON.stringify(state.filters)) {
        return "invalid";
      } else {
        return state.loading_status;
      }
    },
    [storeObjectConfig.getterName + "SavingStatus"]: state => {
      return state.saving_status;
    },
    [storeObjectConfig.getterName + "DeletingStatus"]: state => {
      return state.delete_status;
    },
    [storeObjectConfig.getterName + "ModifiedRecords"]: state => {
      return state.saving_result || false;
    },
    [storeObjectConfig.getterName + "DeletedRecords"]: state => {
      return state.deleting_result.affected_rows || 0;
    },
  };
}

function actionsGenerator(storeObjectConfig, hookFunctions) {
  return {
    [storeObjectConfig.loadActionName]: ({ commit, dispatch, getters, state }, filters) => {
      return new Promise((resolve, reject) => {
        // check if data update is needed
        // const loading_status = getters[storeObjectConfig.getterName + 'LoadingStatus'](filters);
        const loading_status = state.loading_status;

        if (loading_status === "loading" || loading_status === "success" || loading_status === "error") {
          resolve(loading_status); // loading or success response
        } else {
          // invalid data or error response

          let filterObject = {};

          if (storeObjectConfig.recordType === "plain") {
            filterObject = Object.assign({}, filters); // copy original object
          } else {
            filterObject = { filters: filters };
          }

          let params = {...filterObject,...uiVersion};
          if(storeObjectConfig.loadParams.method === 'get'){
            params = {params:{...filters,...uiVersion}};
          }
          // update processing status
          commit(storeObjectConfig.loadingMutationName, {});

          // call API
          axios[storeObjectConfig.loadParams.method](storeObjectConfig.apiUrl + storeObjectConfig.loadParams.url + "/?token=" + getters.getToken, params)
            .then(resp => {
              // data for Table component need to be an array
              let data = resp.data.data ? resp.data.data : {};

              // update data store and processing status
              commit(storeObjectConfig.loadingSuccessMutationName, {
                data,
                filters,
              });

              if (typeof hookFunctions !== "undefined" && typeof hookFunctions.afterSuccessfulLoad !== "undefined") {
                // call hook function if defined
                hookFunctions.afterSuccessfulLoad({
                  commit,
                  dispatch,
                  getters,
                });
              }

              resolve(true);
            })
            .catch(error => {
              console.log(error);

              if (typeof hookFunctions !== "undefined" && typeof hookFunctions.afterInvalidLoad !== "undefined") {
                // call hook function if defined
                hookFunctions.afterInvalidLoad({
                  commit,
                  dispatch,
                  getters,
                });
              }

              // update processing status
              commit(storeObjectConfig.loadingErrorMutationName);

              reject(error);
            });
        }
      });
    },
    // TODO: what if save request before load processed
    [storeObjectConfig.saveActionName]: ({ commit, dispatch, getters }, data) => {
      return new Promise((resolve, reject) => {
        // check if data update is needed
        const status = getters[storeObjectConfig.getterName + "SavingStatus"];
        if (status === "saving") {
          resolve(status);
        } else {
          if (storeObjectConfig.recordType === "json") {
            var record = Object.assign({}, data); // copy original object
            // update processing status
            commit(storeObjectConfig.savingMutationName, {
              record,
            });
            record = {
              record: record,
              uiVersion,
            };
          } else if (storeObjectConfig.recordType === "plain") {
            var record = Object.assign({}, data); // copy original object
            // update processing status
            commit(storeObjectConfig.savingMutationName, {
              record,
            });
          } else {
            var record = data;
            commit(storeObjectConfig.savingMutationName, {
              record,
            });
          }

          // call API
          let caller;
          if (storeObjectConfig.saveParams.method === "delete") {
            caller = axios.delete(storeObjectConfig.apiUrl + storeObjectConfig.saveParams.url + "/?token=" + getters.getToken, {
              data: record,
              headers: {
                "Content-Type": "application/json; charset=UTF-8",
              },
            });
          } else {
            caller = axios[storeObjectConfig.saveParams.method](
              storeObjectConfig.apiUrl + storeObjectConfig.saveParams.url + "/?token=" + getters.getToken,
              record,
            );
          }
          caller
            .then(resp => {
              // data for Table component need to be an array
              let data = resp.data.data ? resp.data.data : {};

              // update data store and processing status
              commit(storeObjectConfig.savingSuccessMutationName, {
                data,
              });

              resolve(true);
            })
            .catch(error => {
              console.log(error);

              // update processing status
              commit(storeObjectConfig.savingErrorMutationName);

              reject(error);
            });
        }
      });
    },
    [storeObjectConfig.deleteActionName]: ({ commit, dispatch, getters }, record) => {
      return new Promise((resolve, reject) => {
        // check if data update is needed
        const status = getters[storeObjectConfig.getterName + "DeletingStatus"];
        if (status === "deleting") {
          resolve(status);
        } else {
          commit(storeObjectConfig.deletingMutationName, {
            record,
          });

          let data = record;
          data = {
            record: data,
            uiVersion,
          };
          // let record = data;

          // call API
          axios[storeObjectConfig.deleteParams.method](
            storeObjectConfig.apiUrl + storeObjectConfig.deleteParams.url + "/?token=" + getters.getToken,
            {
              data,
            },
          )
            .then(resp => {
              // data for Table component need to be an array
              let data = resp.data.data ? resp.data.data : {};

              // update data store and processing status
              commit(storeObjectConfig.deletingSuccessMutationName, {
                data,
              });

              resolve(true);
            })
            .catch(error => {
              console.log(error);

              // update processing status
              commit(storeObjectConfig.deletingErrorMutationName);

              reject(error);
            });
        }
      });
    },
  };
}

function mutationsGenerator(storeObjectConfig) {
  return {
    [storeObjectConfig.loadingMutationName]: state => {
      state.data = {};
      state.filters = {};
      state.loading_status = "loading";
      state.validTo = "2000-01-01 00:00:00";
    },
    [storeObjectConfig.loadingSuccessMutationName]: (state, payload) => {
      state.data = payload.data;
      state.filters = payload.filters;
      state.loading_status = "success"; // status ostatniej operacji z API
      state.validTo = moment(Date.now())
        .add(state.validPeriod, "m")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    [storeObjectConfig.loadingErrorMutationName]: state => {
      state.loading_status = "error";
    },
    [storeObjectConfig.invalidMutationName]: state => {
      state.loading_status = "invalid";
    },
    [storeObjectConfig.savingMutationName]: (state, payload) => {
      state.record = payload.record;
      state.saving_status = "saving";
    },
    [storeObjectConfig.savingSuccessMutationName]: (state, payload) => {
      state.saving_result = payload.data;
      state.saving_status = "saved";
    },
    [storeObjectConfig.savingErrorMutationName]: state => {
      state.saving_result = {};
      state.saving_status = "error";
    },
    [storeObjectConfig.deletingMutationName]: (state, payload) => {
      state.record = payload.record;
      state.delete_status = "deleting";
    },
    [storeObjectConfig.deletingSuccessMutationName]: (state, payload) => {
      state.deleting_result = payload.data;
      state.delete_status = "deleted";
    },
    [storeObjectConfig.deletingErrorMutationName]: state => {
      state.deleting_result = {};
      state.delete_status = "error";
    },
    [storeObjectConfig.logoutMutationName]: state => {
      Object.assign(state, {
        data: {},
        filters: {},
        record: {},
        loading_status: "invalid",
        saving_status: "saved",
        delete_status: "",
        validPeriod: storeObjectConfig.validPeriod, // minutes
        validTo: "2000-01-01 00:00:00",
      });
    },
  };
}

export default {
  stateGenerator,
  gettersGenerator,
  actionsGenerator,
  mutationsGenerator,
};
