import Vue from "vue";
import store from "./store"; // Vuex
import axios from "axios";
import lodash from "lodash";
// import NProgress from 'nprogress'

import "./pollyfills";
import VueRouter from "vue-router";
import VueNotify from "vue-notifyjs";

import pl from "vee-validate/dist/locale/pl";
import VeeValidate, { Validator } from "vee-validate";

import lang from "element-ui/lib/locale/lang/pl";  
import locale from "element-ui/lib/locale";
import App from "./App.vue";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import SideBar from "./components/UIComponents/SidebarPlugin";
import initProgress from "./progressbar";

// router setup
import routes from "./routes/routes";

import echo from "./echo";

// library imports

import "./assets/sass/paper-dashboard.scss";
import "./assets/sass/element_variables.scss";
import "./assets/sass/demo.scss";

import sidebarLinks from "./sidebarLinks";

import VModal from "vue-js-modal";
import BlockUI from "vue-blockui";

// plugin setup
Vue.use(VueRouter);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(SideBar, {
  sidebarLinks: sidebarLinks,
});

Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
});
Vue.use(BlockUI);
Vue.use(lodash);

Vue.use(VeeValidate, {
  fieldsBagName: "formFields",
});
// Localize takes the locale object as the second argument (optional) and merges it.
Validator.localize("pl", pl);

Vue.use(VueNotify, {
  type: "primary",
  timeout: 5000,
  horizontalAlign: "right",
  verticalAlign: "bottom",
});

locale.use(lang);

Vue.filter("numberFormat", value => (!!value ? value.toLocaleString() : ""));

Vue.filter("formatNumber", value => {
  let decimals = 0;
  let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
  let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

  let n = Math.abs(value)
    .toFixed(decimals)
    .split(".");
  n[0] = n[0]
    .split("")
    .reverse()
    .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
    .reverse()
    .join("");
  let final = (Math.sign(value) < 0 ? "-" : "") + n.join(decpoint);

  return final;
});

Vue.filter("formatCurrency", value => {
  let decimals = 2;
  let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
  let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

  let n = Math.abs(value)
    .toFixed(decimals)
    .split(".");
  n[0] = n[0]
    .split("")
    .reverse()
    .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
    .reverse()
    .join("");
  let final = (Math.sign(value) < 0 ? "-" : "") + n.join(decpoint);

  return final + " zł";
});

// event bus
export const eventBus = new Vue();

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  echo();
  next();
});

initProgress(router);

// axios global configuration
// axios.defaults.baseURL = 'https://api-utils4.mobi-me.pl';
axios.defaults.headers = {
  "Content-Type": "application/json; charset=UTF-8",
};

axios.interceptors.request.use(config => {
  // store.commit('updateSessionTime', 1800);
  eventBus.$emit("restartSessionTime");
  if (config.url.includes("https://api-contract.mobi-me.pl/")) {
    echo();
  }

  // NProgress.start();
  if (process.env.NODE_ENV !== "production") {
    console.log("Request interceptor: ", config);
  }
  return config;
});

axios.interceptors.response.use(
  response => {
    // NProgress.done();
    if (process.env.NODE_ENV !== "production") {
      console.log("Response interceptor: ", response);
    }

    return response;
  },
  error => {
    if (!!error.response && error.response.status === 401) {
      if (error.response.data.request_url.includes("https://api-contract.mobi-me.pl/")) {
        eventBus.$emit("notify", {
          message: "Brak dostępu do zasobu!",
          type: "danger",
        });
        router.push("/dashboard");
      } else {
        eventBus.$emit("logout");
      }
    }

    if (!!error.response && error.response.status === 412) {
      eventBus.$emit("incompatibility");
    }

    return Promise.reject(error);
  },
);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  router,
  store,
});
