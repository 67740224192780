import storeGen from "@/store/storeGenerator";

import { AUTH_LOGOUT } from "@/store/actions/auth";
import {BASE_API} from "@/store/config";
import {
  MAILER_LISTS_GETTER_NAME, MAILER_LISTS_INVALID,
  MAILER_LISTS_LOAD,
  MAILER_LISTS_LOADING, MAILER_LISTS_LOADING_ERROR, MAILER_LISTS_LOADING_SUCCESS,
  MAILER_LISTS_OB_NAME
} from "@/store/actions/mailings/mailer_list";





const storeObjectConfig = {
  objectName: MAILER_LISTS_OB_NAME,
  getterName: MAILER_LISTS_GETTER_NAME,
  loadActionName: MAILER_LISTS_LOAD,
  loadingMutationName: MAILER_LISTS_LOADING,
  loadingSuccessMutationName: MAILER_LISTS_LOADING_SUCCESS,
  loadingErrorMutationName: MAILER_LISTS_LOADING_ERROR,
  invalidMutationName: MAILER_LISTS_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/mailings/lists",
    method: "get", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
