import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_PASSWORDS,
  PASSWORDS_LOADING,
  PASSWORDS_LOADING_SUCCESS,
  PASSWORDS_LOADING_ERROR,
  PASSWORDS_INVALID,
  SAVE_PASSWORD,
  PASSWORD_SAVING,
  PASSWORD_SAVING_SUCCESS,
  PASSWORD_SAVING_ERROR
} from 'src/store/actions/admin/password'

const storeObjectConfig = {
  objectName: "UserPassword",
  getterName: "getPassword",
  loadActionName: LOAD_PASSWORDS,
  loadingMutationName: PASSWORDS_LOADING,
  loadingSuccessMutationName: PASSWORDS_LOADING_SUCCESS,
  loadingErrorMutationName: PASSWORDS_LOADING_ERROR,
  invalidMutationName: PASSWORDS_INVALID,
  saveActionName: SAVE_PASSWORD,
  savingMutationName: PASSWORD_SAVING,
  savingSuccessMutationName: PASSWORD_SAVING_SUCCESS,
  savingErrorMutationName: PASSWORD_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "",
    method: "" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/password",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
