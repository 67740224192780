import storeGen from "../../storeGenerator";

import { AUTH_LOGOUT } from "../../actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_RESPONSE_TYPES,
  RESPONSE_TYPES_LOADING,
  RESPONSE_TYPES_LOADING_SUCCESS,
  RESPONSE_TYPES_LOADING_ERROR,
  RESPONSE_TYPES_INVALID,
} from "../../actions/complaints/response_types";

const storeObjectConfig = {
  objectName: "ResponseTypes",
  getterName: "getResponseTypes",
  loadActionName: LOAD_RESPONSE_TYPES,
  loadingMutationName: RESPONSE_TYPES_LOADING,
  loadingSuccessMutationName: RESPONSE_TYPES_LOADING_SUCCESS,
  loadingErrorMutationName: RESPONSE_TYPES_LOADING_ERROR,
  invalidMutationName: RESPONSE_TYPES_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/complaints/responses/response_types",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
