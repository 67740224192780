import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_QUESTION_PROJECTS_LIST,
  QUESTION_PROJECTS_LIST_LOADING,
  QUESTION_PROJECTS_LIST_LOADING_SUCCESS,
  QUESTION_PROJECTS_LIST_LOADING_ERROR,
  QUESTION_PROJECTS_LIST_INVALID,
  SAVE_QUESTION_PROJECT_LIST,
  QUESTION_PROJECT_LIST_SAVING,
  QUESTION_PROJECT_LIST_SAVING_SUCCESS,
  QUESTION_PROJECT_LIST_SAVING_ERROR
} from 'src/store/actions/questions/question_projects_list'

const storeObjectConfig = {
  objectName: "QuestionProjectsList",
  getterName: "getQuestionProjectsList",
  loadActionName: LOAD_QUESTION_PROJECTS_LIST,
  loadingMutationName: QUESTION_PROJECTS_LIST_LOADING,
  loadingSuccessMutationName: QUESTION_PROJECTS_LIST_LOADING_SUCCESS,
  loadingErrorMutationName: QUESTION_PROJECTS_LIST_LOADING_ERROR,
  invalidMutationName: QUESTION_PROJECTS_LIST_INVALID,
  saveActionName: SAVE_QUESTION_PROJECT_LIST,
  savingMutationName: QUESTION_PROJECT_LIST_SAVING,
  savingSuccessMutationName: QUESTION_PROJECT_LIST_SAVING_SUCCESS,
  savingErrorMutationName: QUESTION_PROJECT_LIST_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/questions/projects",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
