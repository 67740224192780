<template>
  <div class="modal-wrapper" :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row header-row">
      <div class="col-sm-11">
        <div class="row">
          <div class="col-sm-12">
            <h5 class="title">Szczegóły paczki {{ package_id }} dla klienta '{{ client }}' [{{ client_id }}]</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
    </div>

    <div class="row" v-if="loading">
      <div class="col-sm-12">
        <div class="center" style="font-size: 1.3em;">
          Wczytywanie danych
        </div>
      </div>
    </div>
    <div v-if="Object.keys(tableData).length > 0">
      <div class="row" v-for="(tbl, key) in tableData" :key="'table_' + key">
        <div class="col-sm-12">
          <h4 class="tableHeader">{{ tbl.rowDescription }}</h4>
          <table>
            <thead>
              <tr>
                <th></th>
                <th
                  class="columnName"
                  :style="'width: ' + columndWidth + 'px;'"
                  v-for="record in tbl.rows[0].records"
                  :key="'header_' + record.label"
                >
                  {{ record.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in tbl.rows" :key="'row_' + index">
                <td style="width: 50px;">
                  <el-tooltip class="item" effect="light" content="Negacja" placement="top" :open-delay="300">
                    <i style="opacity: 0.7" v-if="row.negationParam" class="fa fa-minus-circle fa-2x" aria-hidden="true"></i>
                  </el-tooltip>
                </td>
                <td class="tdCell" v-for="record in row.records" :key="'column_' + record.label">
                  <div class="tableCell">{{ record.value }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <br />
    <br />
    <p v-if="unsupportedData.length > 0">Nieopracowane warunki: {{ unsupportedData }}</p>
    <div class="row">
      <div class="col-sm-12 center">
        <p-button type="default" link @click="forceJob()">
          Zleć ponownie
        </p-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from "src/components/UIComponents";
import { Select, Option, Tooltip } from "element-ui";

// import {  } from "element-ui";
import { LOAD_FILTER_ANALYSE_DETAILS, FILTER_ANALYSE_DETAILS_INVALID } from "src/store/actions/filter/filter_analyse_details";
// event bus
import { eventBus } from "src/main";

export default {
  components: {
    Card,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      loading: false,
      columndWidth: null,
      columnCount: 0,
    };
  },
  props: ["package_id", "client_id", "client"],
  computed: {
    filterDetailsData() {
      return this.$store.getters.getFilterAnalyseDetails;
    },
    tableData() {
      const details = this.filterDetailsData.details;

      let result = {};
      let options = "";

      for (let i in details) {
        let config = details[i].config;
        let panel = details[i].panel;
        let rowResult = { rowDescription: config.description, records: [] };
        let negationParam = 0;

        let rowDescription = config.description;

        if (typeof config.negation_param !== "undefined" && typeof panel.params[config.negation_param] !== "undefined") {
          negationParam = panel.params[config.negation_param];
        }

        rowResult.negationParam = negationParam;
        let configParams = config.parameters;

        let records = [];

        if (configParams) {
          if (configParams.length > this.columnCount) {
            this.columnCount = configParams.length;
          }

          for (let j in configParams) {
            if (configParams[j].url && configParams[j].url == "true") {
              let filePath = panel.params[configParams[j].name];
              const fileName = filePath.substring(filePath.lastIndexOf("/") + 1);

              rowResult.records.push({ label: "URL", value: fileName });
            } else {
              if (configParams[j].usage === "iterate") {
                let iterateArray = Object.values(panel.params[configParams[j].name]).sort((a, b) => a - b);
                let iterateString = iterateArray.join("\r\n");
                rowResult.records.push({ label: configParams[j].description, value: iterateString });
              } else if (configParams[j].usage === "inline") {
                let inlineArray = Object.values(panel.params[configParams[j].name]).sort((a, b) => a - b);
                let inlineString = inlineArray.join("\r\n");
                rowResult.records.push({ label: configParams[j].description, value: inlineString });
              } else if (configParams[j].usage === "value") {
                let value = panel.params[configParams[j].name].toString();
                rowResult.records.push({ label: configParams[j].description, value: value });
              } else {
                console.error("Invalid param usage: " + configParams[j].usage);
              }
            }
          }

          if (typeof result[rowDescription] == "undefined") {
            result[rowDescription] = {};
            result[rowDescription].rows = [];
            result[rowDescription].rowDescription = rowDescription;
          }
          result[rowDescription].rows.push(rowResult);
        } else {
          options += rowDescription + "\r\n";
        }
      }

      if (options.length > 0) {
        result["Opcje"] = {
          rowDescription: "Opcje",
          rows: [
            {
              negationParam: 0,
              records: [
                {
                  label: "Wybrane",
                  value: options,
                },
              ],
            },
          ],
        };
      }

      return result;
    },
    unsupportedData() {
      const data = this.filterDetailsData.unsupported;

      let result = "";

      if (data) {
        data.map(x => (result = result + x + ", "));

        result = result.slice(0, -2);
      }

      return result;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData(id) {
      this.$store.commit(FILTER_ANALYSE_DETAILS_INVALID);

      await this.$store.dispatch(LOAD_FILTER_ANALYSE_DETAILS, { id: id }).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      // this.tableData();
    },
    handleResize() {
      const screenWidth = window.innerWidth;
      const modalWidth = screenWidth / 2;
      const paddingWidth = 140;
      const columnArea = modalWidth - paddingWidth;

      const columnSize = Math.floor(columnArea / this.columnCount);

      this.columndWidth = columnSize;
    },
    forceJob() {
      this.$emit("close");
      eventBus.$emit("forceFilterJob", { package_id: this.package_id });
    },
  },
  async created() {
    await this.loadData(this.package_id);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="css" scoped>
.tableHeader {
  margin: 30px 0 0;
  padding: 5px 0;
  background-color: #909399;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-size: 1.3em;
}

table {
  word-wrap: break-word;
}

.tableCell {
  display: block;
  max-height: 200px;
  overflow-y: auto;
}

.columnName {
  /* width: 200px; */
  padding: 5px 0;
}

.tdCell {
  border: 1px solid #dbdbdb;
}

td {
  white-space: pre;
  text-align: center;
  vertical-align: middle;
}

th {
  text-align: center;
  vertical-align: middle;
}

.card-title {
  margin: 0 40px;
}

.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}
.header-row {
  padding-top: 15px;
  padding-left: 15px;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-wrapper {
  position: relative;
  padding: 0 20px;
}
</style>
