<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-11">
        <h5 class="title">Pulpit - reklamacje</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card" v-for="company in dashboard" :key="company.id">
          <div class="card-body">
            <div class="corner">
              <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                <div v-if="editPermitted" slot="content">Liczniki reklamacji w formacie 'personalne'/'ogólne'</div>
                <div v-else slot="content">Liczniki personalne reklamacji</div>
                <div slot="content">Po kliknięciu w daną liczbe przenosi do reklamacji z odpowiednimi filtrami</div>
                <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
              </el-tooltip>
            </div>
            <h5>{{ company.name }}</h5>
            <div class="card-body row">
              <el-table :data="company.mailboxes">
                <el-table-column label="" property="icon" width="50">
                  <template v-slot="props">
                    <i :class="getIcon(props.row.type)" aria-hidden="true"></i>
                  </template>
                </el-table-column>
                <el-table-column label="Kontekst" property="email" min-width="150">
                  <template v-slot="props">
                    <span style="cursor: pointer;" @click="goToMailbox(props.row.mailbox_id, company.id, null)">{{ props.row.email }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Nowe" property="counters.new_counter" align="right" width="120">
                  <template v-slot="props">
                    <span style="cursor: pointer;" @click="goToMailbox(props.row.mailbox_id, company.id, 1, false)">{{
                      props.row.counters.personal_new_counter ? props.row.counters.personal_new_counter : 0
                    }}</span>
                    <span v-if="editPermitted">
                      <span> / </span>
                      <span style="cursor: pointer;" @click="goToMailbox(props.row.mailbox_id, company.id, 1, true)">{{
                        props.row.counters.new_counter
                      }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Otwarte" property="counters.active_counter" align="right" width="120">
                  <template v-slot="props">
                    <span style="cursor: pointer;" @click="goToMailbox(props.row.mailbox_id, company.id, 3, false)">{{
                      props.row.counters.personal_active_counter ? props.row.counters.personal_active_counter : 0
                    }}</span>
                    <span v-if="editPermitted">
                      <span> / </span>
                      <span style="cursor: pointer;" @click="goToMailbox(props.row.mailbox_id, company.id, 3, true)">{{
                        props.row.counters.active_counter
                      }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Zawieszone" property="counters.waiting_counter" align="right" width="140">
                  <template v-slot="props">
                    <span style="cursor: pointer;" @click="goToMailbox(props.row.mailbox_id, company.id, 4, false)">{{
                      props.row.counters.personal_waiting_counter ? props.row.counters.personal_waiting_counter : 0
                    }}</span>
                    <span v-if="editPermitted">
                      <span> / </span>
                      <span style="cursor: pointer;" @click="goToMailbox(props.row.mailbox_id, company.id, 4, true)">{{
                        props.row.counters.waiting_counter
                      }}</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column v-if="editPermitted" label="Błędne" property="counters.error_counter" align="right" width="120">
                  <template v-slot="props">
                    <span
                      style="cursor: pointer;"
                      :class="checkCounter(props.row.counters.error_counter) ? 'warning_font' : ''"
                      @click="goToMailbox(props.row.mailbox_id, company.id, 7, true)"
                      >{{ props.row.counters.error_counter }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column v-if="editPermitted" label="Do wysłania" property="counters.tosend_counter" align="right" width="140">
                  <template v-slot="props">
                    <span style="cursor: pointer;" @click="goToMailbox(props.row.mailbox_id, company.id, 5, true)">{{
                      props.row.counters.tosend_counter
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column v-if="editPermitted" label="Procesowane" property="counters.pending_counter" align="right" width="160">
                  <template slot="header">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Zgłoszenia oczekujące na wypisanie/usunięcie przez system"
                      placement="top"
                      :open-delay="500"
                    >
                      <span>Procesowane</span>
                    </el-tooltip>
                  </template>
                  <template v-slot="props">
                    <span
                      style="cursor: pointer;"
                      :class="checkCounter(props.row.counters.pending_counter) ? 'warning_font' : ''"
                      @click="goToMailbox(props.row.mailbox_id, company.id, 999, true)"
                      >{{ props.row.counters.pending_counter }}</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="editPermitted && this.$store.state.complaints_history.loading_status === 'success'">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 @click="showHistory = !showHistory" style="cursor: pointer;">Historia</h5>
            <div class="card-body row" v-if="showHistory">
              <el-table :data="history" :cell-style="cellStyle" :header-cell-style="headerStyle">
                <el-table-column label="" property="icon" width="50">
                  <template v-slot="props">
                    <i :class="getOperationIcon(props.row.operation_type)" aria-hidden="true"></i>
                  </template>
                </el-table-column>
                <el-table-column label="ID" property="complaint_id" width="100">
                  <template v-slot="props">
                    <span class="complaintid_column" @click="goToComplaints(props.row)">{{ props.row.complaint_id }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Kto" property="system_user_id" width="220">
                  <template v-slot="props">
                    <span>{{ getUser(props.row.system_user_id) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Kontekst" property="mailbox_id" width="200">
                  <template v-slot="props">
                    <span style="cursor: pointer;" @click="goToMailbox(props.row.mailbox_id, props.row.company_id, 0, true)">{{
                      getContext(props.row)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Akcja" property="counters.new_counter" align="left" min-width="120">
                  <template v-slot="props">
                    <div style="word-break: keep-all;">
                      {{ getAction(props.row) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Data" property="counters.active_counter" align="left" width="180">
                  <template v-slot="props">
                    <span>{{ props.row.event_time }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// event bus
import { eventBus } from "src/main";
import * as priv from "src/privileges.js";

import { LOAD_COMPLAINTS_DASHBOARD, COMPLAINTS_DASHBOARD_INVALID } from "src/store/actions/complaints/complaints_dashboard";
import { LOAD_COMPLAINTS_HISTORY, COMPLAINTS_HISTORY_INVALID } from "src/store/actions/complaints/complaints_history";
import { LOAD_USERS, USERS_INVALID } from "src/store/actions/admin/users";
import { LOAD_USER_GROUP_LIST, USER_GROUP_LIST_INVALID } from "src/store/actions/complaints/user_group_list";

import { Tooltip } from "element-ui";

export default {
  components: {
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      loading: false,
      showHistory: false,
    };
  },
  computed: {
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_COMPLAINTS);
    },
    dashboard() {
      return this.$store.getters.getComplaintsDashboard;
    },
    history() {
      return this.$store.getters.getComplaintsHistory;
    },
    users() {
      return this.$store.getters.getUsers;
    },
    userGroup() {
      let result = { user: {}, group: {} };
      const data = _.cloneDeep(this.$store.getters.getUserGroupList);
      if (data.user) {
        data.user.forEach(user => {
          result.user[user.system_user_id] = user;
        });
        data.group.forEach(group => {
          result.group[group.group_id] = group;
        });
      }
      return result;
    },
  },
  methods: {
    async loadData(force) {
      const filters = {};
      this.loading = true;

      if (force) {
        this.$store.commit(USERS_INVALID);
        this.$store.commit(USER_GROUP_LIST_INVALID);
      }

      this.$store.commit(COMPLAINTS_DASHBOARD_INVALID);

      if (this.editPermitted) {
        this.$store.commit(COMPLAINTS_HISTORY_INVALID);

        this.$store.dispatch(LOAD_USER_GROUP_LIST, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        this.$store.dispatch(LOAD_USERS, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        this.$store.dispatch(LOAD_COMPLAINTS_HISTORY, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }

      await this.$store.dispatch(LOAD_COMPLAINTS_DASHBOARD, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    goToMailbox(mailbox_id, company_id, status_id, is_full) {
      status_id === 0 ? (status_id = String(status_id)) : (status_id = status_id);
      this.$router.push({
        name: "Reklamacje",
        params: { mailbox: mailbox_id, company: company_id, status: status_id, owner: is_full ? "all" : null },
      });
    },
    getIcon(type) {
      switch (type) {
        case "mail":
          return "fa fa-envelope-o";
        case "manual":
          return "fa fa-pencil-square-o";
        default:
          return "";
      }
    },
    getUser(user_id) {
      if (user_id) {
        return this.users[user_id].user_first_name + " " + this.users[user_id].user_last_name;
      } else {
        return "brak";
      }
    },
    getSender({ from_email, from_msisdn }) {
      let sender = "";
      if (from_msisdn) sender += from_msisdn;
      if (from_email) {
        if (from_msisdn) {
          sender += " / " + from_email;
        } else {
          sender += from_email;
        }
      }
      if (sender === "") sender = "-";
      return sender;
    },
    getAssigned({ current_user_id, current_group_id }) {
      if (current_user_id) {
        return `użytkownika ${this.getUser(current_user_id)}`;
      } else {
        return `grupy ${this.userGroup.group[current_group_id].name}`;
      }
    },
    getAction(row) {
      // 'CREATE', 'CLOSE', 'OPEN', 'COMMENT', 'SUSPEND', 'ASSIGN', 'UNSUBSCRIBE', 'DELETE', 'RESPONSE', 'SPAM'
      switch (row.operation_type) {
        case "CREATE":
          return `Utworzono reklamację dla ${this.getSender(row)}`;
        case "CLOSE":
          return `Zamknięto reklamację`;
        case "OPEN":
          return `Otwarto reklamację ${row.comment ? `z komentarzem: '${row.comment}'` : ""}`;
        case "COMMENT":
          return `Dodano komentarz do reklamacji: '${row.comment}'`;
        case "SUSPEND":
          return `Zawieszono reklamację ${row.comment ? `z komentarzem: '${row.comment}'` : ""}`;
        case "ASSIGN":
          return `Przekazano reklamację do ${this.getAssigned(row)} ${row.comment ? `z komentarzem: '${row.comment}'` : ""}`;
        case "UNSUBSCRIBE":
          return `Wypisano rekordy dla adresu email ${row.comment}`;
        case "DELETE":
          return `Usunięto rekordy dla danych ${row.comment}`;
        case "RESPONSE":
          return `Odpowiedziano na reklamację`;
        case "SPAM":
          return `Oznaczono jako SPAM`;
        default:
          return "Nieznana akcja";
      }
    },
    getOperationIcon(operation_type) {
      switch (operation_type) {
        case "CREATE":
          return "fa fa-plus-square-o";
        case "CLOSE":
          return "fa fa-times";
        case "OPEN":
          return "fa fa-undo";
        case "COMMENT":
          return "fa fa-comment-o";
        case "SUSPEND":
          return "fa fa-clock-o";
        case "ASSIGN":
          return "fa fa-user-o";
        case "UNSUBSCRIBE":
          return "fa fa-ban";
        case "DELETE":
          return "fa fa-ban";
        case "RESPONSE":
          return "fa fa-reply";
        case "SPAM":
          return "fa fa-trash-o";
        default:
          return "";
      }
    },
    cellStyle({ row }) {
      return "font-size: 13px; padding: 2px;";
    },
    headerStyle({ row }) {
      return "font-size: 13px;";
    },
    goToComplaints(row) {
      this.$router.push({
        name: "Reklamacje",
        params: {
          from: "history",
          mailbox: row.mailbox_id,
          company: row.company_id,
          complaintID: row.complaint_id,
        },
      });
    },
    checkCounter(counter) {
      if (counter && counter > 0) return true;
      return false;
    },
    getContext(row) {
      let result = "";
      result = "[" + row.name + "]";
      result += " " + row.mailbox_login.split("@")[0];
      return result;
    },
  },
  created() {
    this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
  },
};
</script>

<style scoped>
.complaintid_column:hover {
  cursor: pointer;
  text-decoration: underline;
}
.warning_font {
  color: red;
  font-weight: bold;
}
.corner {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
