import storeGen from '../../storeGenerator'

import {
    AUTH_LOGOUT
} from '../../actions/auth'

import {
    SECURE_API
} from '../../config'

import { 
    GET_CUSTOMER_EFFICIENCY,
    LOAD_CUSTOMER_EFFICIENCY,
    SAVE_CUSTOMER_EFFICIENCY,
    SUCCESS_CUSTOMER_EFFICIENCY,
    ERROR_CUSTOMER_EFFICIENCY,
    INVALID_CUSTOMER_EFFICIENCY,
    SAVE_FROM_CUSTOMER_EFFICIENCY,
    SAVING_CUSTOMER_EFFICIENCY,
    SAVING_ERROR_CUSTOMER_EFFICIENCY,
    SAVING_SUCCESS_CUSTOMER_EFFICIENCY,
    } from '../../actions/sales/customer_efficiency';

const storeObjectConfig = {
    objectName: "UserFromComplaints",
    getterName: GET_CUSTOMER_EFFICIENCY,
    loadActionName: LOAD_CUSTOMER_EFFICIENCY,
    loadingMutationName: SAVE_CUSTOMER_EFFICIENCY,
    loadingSuccessMutationName: SUCCESS_CUSTOMER_EFFICIENCY,
    loadingErrorMutationName: ERROR_CUSTOMER_EFFICIENCY,
    invalidMutationName: INVALID_CUSTOMER_EFFICIENCY,
    saveActionName: SAVE_FROM_CUSTOMER_EFFICIENCY,
    savingMutationName: SAVING_CUSTOMER_EFFICIENCY,
    savingSuccessMutationName: SAVING_SUCCESS_CUSTOMER_EFFICIENCY,
    savingErrorMutationName: SAVING_ERROR_CUSTOMER_EFFICIENCY,
    logoutMutationName: AUTH_LOGOUT,
    apiUrl: SECURE_API,
    validPeriod: 10, // minutes
    recordType: 'json',
    loadParams: {
        url: "/raports/customerEfficiency",
        method: "post" // {'get','post','put'}
    },
    saveParams: {
        url: "/raports/customerEfficiency",
        method: "post" // {'get','post','put'}
    }
}

const state = storeGen.stateGenerator(storeObjectConfig);// stan stora
const getters = storeGen.gettersGenerator(storeObjectConfig);//pobiera dane ze stora
const actions = storeGen.actionsGenerator(storeObjectConfig);//poberanie dany do stora async
const mutations = storeGen.mutationsGenerator(storeObjectConfig);//zmieniają stan stora  

export default {
    state,
    getters,
    actions,
    mutations
}