<template>
  <div>
    <div class="row header-row">
      <div class="col-sm-11">
        <h5 class="title">Szczegóły testu</h5>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer" aria-hidden="true"></i>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <label>Wynik testu</label>
          <table class="table">
            <tbody>
              <tr>
                <td class="verticalTableHeader">Nazwa</td>
                <td>{{ testConfig.test_name }}</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Typ</td>
                <td>{{ row.version }}</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Rodzaj</td>
                <td>{{ row.scenario_type }}</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Status</td>
                <td>{{ row.status === "SUCCESS" ? "SUKCES" : "BŁĄD" }}</td>
              </tr>
              <tr style="background-color: #fafafa">
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <label>Konfiguracja testu</label>
          <table class="table">
            <tbody>
              <tr>
                <td class="verticalTableHeader">Projekt</td>
                <td>{{ "[" + testConfig.project_id + "] " + projects[testConfig.project_id].project_name }}</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Moduł początkowy</td>
                <td>{{ row.start_module }}</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Moduł końcowy</td>
                <td>{{ row.module }}</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Rekord testowy</td>
                <td>{{ testConfig.test_record }}</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Rekord porównania</td>
                <td>{{ testConfig.parent_record ? testConfig.parent_record : "-" }}</td>
              </tr>
              <tr v-if="testConfig.test_index">
                <td class="verticalTableHeader">Indeks testu</td>
                <td>{{ testConfig.test_index }}</td>
              </tr>
              <tr style="background-color: #fafafa">
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Token</td>
                <td>{{ testConfig.token }}</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">URL</td>
                <td class="wrapRow urlLink" @click="clickToSource(testConfig.url)">{{ testConfig.url }}</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">UserDataID</td>
                <td>{{ testConfig.userDataID }}</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">UserID</td>
                <td>{{ testConfig.userID }}</td>
              </tr>
              <tr style="background-color: #fafafa">
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="testComments.length > 0">
          <label>Dodatkowe informacje</label>
          <table class="table">
            <tbody>
              <tr v-for="(test, index) in testComments" :key="index">
                <td>{{ test }}</td>
              </tr>
              <tr style="background-color: #fafafa">
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="testDuration.length > 0">
          <label>Czas trwania testu</label>
          <table class="table">
            <tbody>
              <tr v-for="(test, index) in testDuration" :key="index">
                <td class="verticalTableHeader" :style="test.type === 'test' ? 'padding-left: 30px;' : ''">{{ test.name }}</td>
                <td>{{ test.duration + "s" }}</td>
              </tr>
              <tr style="background-color: #fafafa">
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="testErrors && testErrors.length > 0">
          <label>Szczegóły błędu</label>
          <table class="table">
            <tbody>
              <tr v-for="(err, index) in testErrors" :key="'err' + index">
                <td class="verticalTableHeader">Opis błędu</td>
                <td>{{ err }}</td>
              </tr>
              <tr style="background-color: #fafafa">
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Zrzut strony</td>
                <td style="cursor: pointer; font-weight: bold" @click="clickToHtml()">Przejdź do strony</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Nagranie testu</td>
                <td style="cursor: pointer; font-weight: bold" @click="clickToVideo()">Przejdź do nagrania</td>
              </tr>
              <tr>
                <td class="verticalTableHeader">Zrzut ekranu</td>
                <td><img :src="'https://utils4.mobi-me.pl/storage/tests/screenshots/' + row.screenshot_url + '.png'" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="testConfig.console_messages">
          <label>Logi przeglądarki</label>
          <table class="table">
            <tbody>
              <tr v-if="testConfig.console_messages.error.length > 0">
                <td class="verticalTableHeader">Error</td>
                <td>{{ JSON.stringify(testConfig.console_messages.error) }}</td>
              </tr>
              <tr v-if="testConfig.console_messages.info.length > 0">
                <td class="verticalTableHeader">Info</td>
                <td>{{ JSON.stringify(testConfig.console_messages.info) }}</td>
              </tr>
              <tr v-if="testConfig.console_messages.log.length > 0">
                <td class="verticalTableHeader">Log</td>
                <td>{{ JSON.stringify(testConfig.console_messages.log) }}</td>
              </tr>
              <tr v-if="testConfig.console_messages.warn.length > 0">
                <td class="verticalTableHeader">Warn</td>
                <td>{{ JSON.stringify(testConfig.console_messages.warn) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

import { Table, TableColumn } from "element-ui";

// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

import * as priv from "src/privileges.js";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {};
  },
  props: ["row", "projects"],
  computed: {
    testConfig() {
      return JSON.parse(this.row.test_config);
    },
    testResult() {
      if (this.row.test_result) {
        return JSON.parse(this.row.test_result).tests[0];
      } else {
        return null;
      }
    },
    testErrors() {
      if (this.testResult) {
        let result = this.testResult;
        return result.errs;
      } else {
        return null;
      }
    },
    testComments() {
      if (this.testConfig && this.testConfig.comments) {
        return this.testConfig.comments;
      } else {
        return [];
      }
    },
    testDuration() {
      if (this.testConfig.test_duration) {
        let data = this.testConfig.test_duration;
        const result = [];
        for (let i in data) {
          result.push({ name: data[i].name, duration: data[i].duration, type: "page" });

          for (let j in data[i].tests) {
            result.push({ name: data[i].tests[j].name, duration: data[i].tests[j].duration, type: "test" });
          }
        }
        return result;
      } else {
        return [];
      }
    },
  },
  methods: {
    headerStyle(obj) {
      return "font-size: 12px;";
    },
    clickToHtml() {
      window.open(`https://utils4.mobi-me.pl/storage/tests/html/${this.row.screenshot_url}.html`, "_blank");
    },
    clickToVideo() {
      window.open(`https://utils4.mobi-me.pl/storage/tests/videos/${this.row.screenshot_url}.mp4`, "_blank");
    },
    clickToSource(url) {
      window.open(url, "_blank");
    },
  },
  created() {},
};
</script>

<style lang="css" scoped>
.card {
  margin: 10px;
}
.header-row {
  padding-top: 15px;
  padding-left: 15px;
}
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
.verticalTableHeader {
  font-weight: bold;
  width: 200px;
}
table {
  word-break: break-all;
}
.urlLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
