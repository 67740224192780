<template>
  <div>
    <div class="row">
      <div class="col-sm-11">
        <h5 class="title">Role</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="tableData" :default-sort = "{prop: 'role_add_time', order: 'ascending'}">
              <el-table-column type="expand">
                <template v-slot="props">
                  <p>Opis: {{ props.row.role_description }}</p>
                  <p>Utworzona: {{ props.row.role_add_time }}</p>
                  <p>Czy aktywny: {{ props.row.role_is_active }}</p>
                </template>
              </el-table-column>
              <el-table-column label="ID" min-width="30" property="role_id" sortable></el-table-column>
              <el-table-column label="System" min-width="100" property="system_name" sortable></el-table-column>
              <el-table-column label="Rola" property="role_name" min-width="160" sortable></el-table-column>
              <el-table-column label="Aktywny" align="center" width="140" sortable>
                <template v-slot="props">
                  <fg-input>
                    <el-switch
                      :value="props.row.role_is_active"
                      active-value="true"
                      inactive-value="false"
                      active-color="#6bd098"
                    ></el-switch>
                  </fg-input>
                </template>
              </el-table-column>
              <el-table-column label="Role" align="center" v-if="editPermitted" width="150">
                <template slot="header">
                  Uprawnienia
                </template>
                <template v-slot="props">
                  <p-button
                    type="info"
                    size="sm"
                    icon
                    @click="handleEditPriv(props.$index, props.row)"
                  >
                    <i class="fa fa-object-group"></i>
                  </p-button>
                </template>
              </el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="80">
                <template slot="header">
                  <router-link to="/admin/role_form">
                    <p-button type="success" icon round>
                      <i class="fa fa-plus"></i>
                    </p-button>
                  </router-link>
                </template>
                <template v-slot="props">
                  <p-button
                    type="success"
                    size="sm"
                    icon
                    @click="handleEdit(props.$index, props.row)"
                  >
                    <i class="fa fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

// ui components
import { Table, TableColumn, Select, Option, Switch } from "element-ui";

// vuex actions
import { LOAD_ROLES, ROLES_INVALID } from "src/store/actions/admin/roles";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';

Vue.use(Table);
Vue.use(TableColumn);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  computed: {
    tableData() {
      // table data needs to by an array
      return Object.values(this.$store.getters.getRoles);
    },
      editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN)
    }
  },
  methods: {
    loadData(force) {
      const filters = {}

      if (force) {
        this.$store.commit(ROLES_INVALID);
      }

      this.$store.dispatch(LOAD_ROLES, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    handleEdit(index, row) {
      this.$router.push("/admin/role_form/" + row.role_id);
    },
    handleEditPriv(index, row) {
      this.$router.push("/admin/role_add_priv/" + row.role_id);
    }
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style>
</style>
