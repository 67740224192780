import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_MEDIA_HOUSE,
  MEDIA_HOUSE_LOADING,
  MEDIA_HOUSE_LOADING_SUCCESS,
  MEDIA_HOUSE_LOADING_ERROR,
  MEDIA_HOUSE_INVALID,
  SAVE_MEDIA_HOUSE,
  MEDIA_HOUSE_SAVING,
  MEDIA_HOUSE_SAVING_SUCCESS,
  MEDIA_HOUSE_SAVING_ERROR
} from 'src/store/actions/stats/media_house'

const storeObjectConfig = {
  objectName: "DisplayStats",
  getterName: "getMediaHouse",
  loadActionName: LOAD_MEDIA_HOUSE,
  loadingMutationName: MEDIA_HOUSE_LOADING,
  loadingSuccessMutationName: MEDIA_HOUSE_LOADING_SUCCESS,
  loadingErrorMutationName: MEDIA_HOUSE_LOADING_ERROR,
  invalidMutationName: MEDIA_HOUSE_INVALID,
  saveActionName: SAVE_MEDIA_HOUSE,
  savingMutationName: MEDIA_HOUSE_SAVING,
  savingSuccessMutationName: MEDIA_HOUSE_SAVING_SUCCESS,
  savingErrorMutationName: MEDIA_HOUSE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/stats/mediaHouse",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
