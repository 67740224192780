<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <h5 class="title">Kolejność</h5>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="card-body row">
          <!-- Wybór projektu -->
          <div class="col-sm-4">
            <fg-input :error="getError('project_id')" data-vv-as="Projekt">
              <el-select
                id="questionOrderLeftSelect"
                v-on:visible-change="getLeftSelectWidth()"
                v-model="project_id"
                name="project_id"
                data-vv-as="Projekt"
                filterable
                v-on:input="changeProject($event)"
              >
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    :style="questionOrderLeftSelectWidth !== null ? 'width: ' + questionOrderLeftSelectWidth + 'px' : 'width: 300px'"
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>

          <!-- Wybór wersji -->
          <div class="col-sm-3">
            <fg-input :error="getError('version')" data-vv-as="Wybierz wersję">
              <el-select
                id="rightSelect"
                v-on:visible-change="getRightSelectWidth()"
                v-on:handleOptionClick="changeVersion()"
                v-on:clear="changeVersion()"
                v-model="version"
                name="version"
                data-vv-as="Wybierz wersję"
                filterable
                clearable
              >
                <el-option
                  :style="rightSelectWidth !== null ? 'width: ' + rightSelectWidth + 'px' : 'width: 300px'"
                  v-for="version in versions"
                  :label="'[' + version.module + '] ' + version.version"
                  :value="version.module"
                  :key="version.module"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <!-- Tryb edycji -->
          <div class="col-sm-2" align="center">
            <p-button v-if="editPermitted" :type="checkboxes.edit ? 'warning' : 'success'" size="medium" @click="changeEdit()" style="margin-top: 0">
              {{ checkboxes.edit ? "Anuluj" : "Edycja" }}
            </p-button>
          </div>

          <!-- Wyszukiwarka -->
          <div class="col-sm-3">
            <fg-input class="input-sm" placeholder="Szukaj" v-model="searchQuery" addon-right-icon="nc-icon nc-zoom-split"> </fg-input>
          </div>
        </div>

        <!-- <p v-if="recordObject.insert_order.length > 0 || recordObject.delete_order.length > 0" class="text-danger"><i><a onclick="takeToBottom()">Zapisz</a> wprowadzone zmiany</i></p> -->
        <div v-if="recordObject.insert_order.length > 0 || recordObject.delete_order.length > 0" class="col-sm-12" align="center">
          <p-button type="danger" link @click="takeToBottom()"
            ><i class="fa fa-arrow-down" aria-hidden="true"></i>Zapisz wprowadzone zmiany<i class="fa fa-arrow-down" aria-hidden="true"></i
          ></p-button>
        </div>

        <!-- Tabela danych -->
        <el-table :data="queriedData" stripe style="width: 100%" @sort-change="sortChange" v-on:filter-change="changeFilter($event)">
          <el-table-column type="expand" v-if="version.length === 0">
            <template v-slot="props">
              <div class="row">
                <!-- Tabela wersji projektu dla danego pytania -->
                <div class="col-sm-11">
                  <label>Wersje</label>
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                        v-for="version in props.row.versions"
                        :key="props.row.question_id + '_' + version.module"
                        v-bind:class="{ blue: version.is_lite === 1, pink: version.tags === 'INACTIVE', grey: version.is_active === 0 }"
                      >
                        <td>
                          <p-checkbox
                            :disabled="true"
                            v-bind:checked="version.permitted"
                            v-on:input="checkboxChecked($event, props.row, version.module)"
                          ></p-checkbox>
                        </td>
                        <td>{{ version.module }}</td>
                        <td>{{ version.version }}</td>
                        <td>{{ version.description }}</td>
                        <td v-bind:class="{ bold: version.priority !== props.row.project_priority }">
                          {{ version.priority !== null ? version.priority : props.row.project_priority }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="ID" property="question_id" width="80" sortable="custom"></el-table-column>
          <el-table-column label="Treść pytania" property="question_content_html" min-width="300" sortable="custom">
            <template v-slot="props">
              <div style="word-break: keep-all" :id="'question_order_question_content_' + props.$index">
                {{ props.row.question_content_html }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Typ"
            property="question_type_alias"
            :filter-multiple="false"
            :filters="this.filterTypes"
            min-width="120"
          ></el-table-column>
          <el-table-column label="Priorytet" property="sort_priority" align="center" min-width="120" sortable="custom">
            <template v-slot="props">
              <div style="padding: 12px 0px" v-if="version.length === 0">
                {{ props.row.priority_new !== null ? props.row.priority_new : props.row.project_priority }}
                <el-tooltip
                  v-if="badgeNoVersion(props.row)"
                  class="item"
                  effect="light"
                  :content="props.row.priority.toString()"
                  placement="top"
                  :open-delay="500"
                >
                  <el-badge value="!" class="item" type="danger" v-if="badgeNoVersion(props.row)"></el-badge>
                </el-tooltip>
              </div>
              <div style="padding: 12px 0px" v-if="version.length > 0">
                {{
                  props.row.versions[Object.keys(props.row.versions)[0]].priority_new !== null
                    ? props.row.versions[Object.keys(props.row.versions)[0]].priority_new
                    : props.row.versions[Object.keys(props.row.versions)[0]].priority
                }}
                <el-tooltip
                  v-if="badgeVersion(props.row)"
                  class="item"
                  effect="light"
                  :content="props.row.priority.toString() + '/' + props.row.project_priority.toString()"
                  placement="top"
                  :open-delay="500"
                >
                  <el-badge value="!" class="item" type="danger" v-if="badgeVersion(props.row)"></el-badge>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Status" property="question_is_active" align="center" width="110" sortable="custom">
            <template v-slot="props">
              <el-tag v-if="props.row.subquestion === 0" effect="dark" :type="tagType(props.row.question_is_active)" size="small">{{
                props.row.question_is_active
              }}</el-tag>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="Edytuj/Usuń" align="center" v-if="checkboxes.edit" width="140">
            <template v-slot="props">
              <p-button type="success" size="sm" icon @click.prevent="handleEditPriority(props.row)">
                <i class="fa fa-pencil-square-o"></i>
              </p-button>
              <span style="padding-right: 10px"></span>
              <p-button type="danger" size="sm" icon :disabled="removeButtonDisable(props.row)" @click.prevent="handleDeletePriority(props.row)">
                <i class="fa fa-minus-square-o"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body row">
        <div class="pagination-info" v-bind:class="{ 'col-sm-4': this.checkboxes.edit === true, 'col-sm-6': this.checkboxes.edit === false }">
          <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
        </div>
        <div class="col-sm-4 text-center" v-if="this.checkboxes.edit">
          <p-button type="default" link @click.prevent="changeEdit()"> Anuluj </p-button>
          <p-button type="success" style="margin-left: 2vw" @click.prevent="saveData">Zapisz</p-button>
        </div>
        <div v-bind:class="{ 'col-sm-4': this.checkboxes.edit === true, 'col-sm-6': this.checkboxes.edit === false }">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"> </p-pagination>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
import Vue from "vue";

import Swal from "sweetalert2";
import BackToTop from "vue-backtotop";

// ui components
import { Table, TableColumn, Select, Option, OptionGroup, Switch, Tag, Badge, Tooltip } from "element-ui";
import { Tabs, TabPane, Card } from "src/components/UIComponents";
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_PROJECT_CONFIGS, PROJECT_CONFIGS_INVALID } from "src/store/actions/questions/project_configs";
import { LOAD_QUESTION_ORDER, QUESTION_ORDER_INVALID, SAVE_QUESTION_ORDER } from "src/store/actions/questions/question_order";
import { LOAD_PROJECT_QUESTIONS, PROJECT_QUESTIONS_INVALID } from "src/store/actions/questions/project_questions";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";

Vue.use(Table);
Vue.use(TableColumn);

export default {
  components: {
    [Tag.name]: Tag,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Switch.name]: Switch,
    [Badge.name]: Badge,
    [Tooltip.name]: Tooltip,
    PPagination,
    TabPane,
    Tabs,
    Card,
    BackToTop,
  },
  data() {
    return {
      project_id: null,
      version: "",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["question_id", "question_content", "question_type"],
      checkboxes: {
        inactive: false,
        edit: false,
      },
      status: "",
      loading: false,
      sort: {
        prop: "priority",
        order: "ascending",
      },
      filterTypes: [
        { text: "CO-REG", value: "CO-REG" },
        { text: "PROFILE", value: "PROFILE" },
        { text: "QUIZ", value: "QUIZ" },
        { text: "OPT-IN", value: "OPT-IN" },
      ],
      activeFilter: "",
      newProjectConfig: {},
      priorities: {},
      deletePriorities: {},
      recordObject: {},
      rightSelectWidth: null,
      questionOrderLeftSelectWidth: null,
    };
  },
  computed: {
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN_QUESTIONS);
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    versions() {
      return Object.values(this.activeProjectModules);
    },
    questions() {
      // lista pytań przypisanych i nie przypisanych
      return JSON.parse(JSON.stringify(this.$store.getters.getProjectQuestions));
    },
    activeProjectModules() {
      // wszystkie moduły aktywnego projektu
      let projects = this.$store.getters.getProjectsAll;
      let filteredData = {};

      for (let [key, project] of Object.entries(projects)) {
        // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
        if (project.project_id === this.project_id) {
          filteredData[project.module] = Object.assign(
            {},
            project,
            { permitted: false },
            { priority: null },
            { priority_new: null },
            { sort_priority: null },
          );
        }
      }

      let sourceData = [];

      for (let ver of Object.keys(filteredData)) {
        // iteracja po nazwach modułów danego projektu
        let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie liczby
        filteredData[ver].number = res;
      }

      sourceData = Object.values(filteredData);
      sourceData.sort((a, b) => a.number - b.number);
      let result = {};
      for (let i in sourceData) {
        result[sourceData[i].module] = sourceData[i];
      }

      return result;
    },
    projectConfigs() {
      // zależność pytanie-projekt-moduł
      return this.$store.getters.getProjectConfigs;
    },
    projectQuestions() {
      // pytania dla danego projektu
      let getter = this.questions;

      let type_map = { "CO-REGISTRATION": "CO-REG", "DOUBLEOPT-IN": "OPT-IN" }; // mapa krótszych nazw typów
      if (this.$store.state.project_questions.loading_status === "success") {
        getter.map((row) => {
          row.question_type_alias = type_map[row.question_type] || row.question_type; // przypisanie aliasów typów
        });
        let filteredData = getter.filter(
          // filtracja danych po tagach oraz przypisaniu do projektu
          (row) =>
            (this.activeFilter === "" || // no filter
              row.question_type_alias === this.activeFilter) &&
            row.is_assigned,
        );
        return filteredData;
      }
      return;
    },
    questionOrder() {
      let order = this.$store.getters.getQuestionOrder;
      let versionOrder = {};
      let projectOrder = {};

      for (let [key, object] of Object.entries(order)) {
        if (object.module === "ALL_VERSION") {
          projectOrder[object.question_id + "_" + object.project_id] = Object.assign({}, object);
        } else {
          versionOrder[object.question_id + "_" + object.module] = Object.assign({}, object);
        }
      }

      return { projectOrder, versionOrder };
    },
    tableData() {
      // table data needs to by an array
      if (this.project_id === null) {
        // zwrócenie pustej tablicy w przypadku braku wybranego projektu
        return [];
      }
      const questions = this.projectQuestions;
      const versionFilter = this.version;

      let data = {};
      let versions = {};
      for (let row in questions) {
        let question_id = questions[row].question_id;
        if (data[question_id] === undefined) {
          data[question_id] = questions[row]; // przypisanie danych pytania
          if (this.questionOrder.projectOrder[question_id + "_" + this.project_id] !== undefined) {
            data[question_id].project_priority = this.questionOrder.projectOrder[question_id + "_" + this.project_id].priority;
          } else {
            data[question_id].project_priority = data[question_id].priority;
          }
          data[question_id].sort_priority = data[question_id].project_priority;
          data[question_id].priority_new = null;
        }
        if (data[question_id].versions === undefined) {
          data[question_id].versions = JSON.parse(JSON.stringify(this.activeProjectModules)); // przypisanie wszystkich modułów projektu
        }

        let versions = data[question_id].versions;
        for (let i in versions) {
          versions[i].priority = data[question_id].project_priority;
        }

        if (
          !Object.keys(data[question_id].versions).includes(questions[row].module) &&
          questions[row].module !== "ALL_VERSION" &&
          questions[row].module !== null
        ) {
          data[question_id].versions[questions[row].module] = {
            // wyświetlanie nieaktywnych modułów dla danego pytania oraz odrzucanie modułów ALL_VERSION
            description: "Wersja nieaktywna",
            module: questions[row].module,
            version: "inactive",
            tags: "INACTIVE",
            project_id: this.project_id,
            permitted: true,
          };
        }
        data[question_id].question_content_html = data[question_id].question_content
          .replace(/<br>?/gm, "\n")
          .replace(/<[^>]*>?/gm, " ")
          .replace(/[ ]{2,}?/gm, " ");
      }

      let newProjectConfig = Object.values(this.newProjectConfig);

      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        for (let conf of newProjectConfig) {
          // przypisywanie odpowiednich oznaczeń do modułów
          if (typeof data[conf.question_id] !== "undefined") {
            let version = data[conf.question_id].versions[conf.module];
            if (typeof version.module !== "undefined") {
              version.permitted = true;
            }
            if (this.questionOrder.versionOrder[conf.question_id + "_" + conf.module] !== undefined) {
              version.priority = this.questionOrder.versionOrder[conf.question_id + "_" + conf.module].priority;
            }
            version.sort_priority = version.priority_new !== null ? version.priority_new : version.priority;
          } else {
            //TODO: wpis dla nieaktywnych modułów
          }
        }
      }

      let filteredData = {};
      for (let i in data) {
        let versions = data[i].versions;
        for (let j in versions) {
          if (versions[j].module === versionFilter && versions[j].permitted === true) {
            filteredData[i] = JSON.parse(JSON.stringify(data[i]));
            filteredData[i].versions = { [versionFilter]: data[i].versions[j] };
          }
        }
      }

      filteredData = Object.values(filteredData).map((row) => Object.assign({}, row));
      filteredData.sort((a, b) => b.versions[Object.keys(b.versions)[0]].sort_priority - a.versions[Object.keys(a.versions)[0]].sort_priority);

      data = Object.values(data).map((row) => Object.assign({}, row));
      data.sort((a, b) => (a.sort_priority < b.sort_priority ? 1 : -1)); // sortowanie danych po priorytecie

      return Object.keys(filteredData).length > 0 ? filteredData : data;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      // paginacja danych
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  methods: {
    async loadData(force, force_all) {
      this.loading = true;

      const filters = {
        question_status: this.status ? "INACTIVE" : "",
        project_id: this.project_id,
      };

      if (force) {
        this.$store.commit(QUESTION_ORDER_INVALID);
      }

      if (force_all) {
        this.$store.commit(QUESTION_ORDER_INVALID);
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(PROJECTS_ALL_INVALID);
        this.$store.commit(PROJECT_QUESTIONS_INVALID);
        this.$store.commit(PROJECT_CONFIGS_INVALID);
      }

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_QUESTION_ORDER, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      if (this.project_id !== null) {
        this.$store.dispatch(LOAD_PROJECT_QUESTIONS, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        await this.$store.dispatch(LOAD_PROJECT_CONFIGS, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        this.initConfig();
      }

      this.loading = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    removeButtonDisable(row) {
      let ifVersion = this.version.length > 0 ? true : false;
      let version = row.versions[Object.keys(row.versions)[0]];
      if (ifVersion) {
        if (version.priority_new !== null && version.priority_new === row.project_priority) {
          return true;
        }
        if (version.priority_new === null && version.priority === row.project_priority) {
          return true;
        }
      } else {
        if (row.priority_new !== null && row.priority_new === row.priority) {
          return true;
        }
        if (row.priority_new === null && row.project_priority === row.priority) {
          return true;
        }
      }
      return false;
    },
    badgeNoVersion(row) {
      if (row.priority_new !== null && row.priority_new !== row.priority) {
        return true;
      }
      if (row.priority_new === null && row.project_priority !== row.priority) {
        return true;
      }
      return false;
    },
    badgeVersion(row) {
      let version = row.versions[Object.keys(row.versions)[0]];

      if (version.priority_new !== null && version.priority_new !== row.project_priority) {
        return true;
      }
      if (version.priority_new === null && version.priority !== row.project_priority) {
        return true;
      }
      return false;
    },
    sortChange(obj) {
      this.sort = {
        prop: obj.prop,
        order: obj.order,
      };
      if (obj.order === "descending") {
        if (obj.prop === "question_is_active" || obj.prop === "question_content_html") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (obj.prop === "question_is_active" || obj.prop === "question_content_html") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        this.tableData.sort((a, b) => b.priority - a.priority);
      }
      this.searchQuery += " ";
      this.searchQuery = this.searchQuery.slice(0, -1);
    },
    tagType(value) {
      // przypisanie tagów
      switch (value) {
        case "ACTIVE":
          return "success";
        case "INACTIVE":
          return "warning";
        case "ARCHIVE":
          return "danger";
        default:
          return "info";
      }
    },
    changeFilter(payload) {
      // funkcja obsługująca filtrowanie danych po tagach
      let filter = Object.values(payload);
      if (filter[0][0] === undefined) {
        // sprawdzenie czy filtr został wybrany
        this.activeFilter = "";
      } else {
        this.activeFilter = filter[0][0]; // przypisanie filtra do zmiennej
      }
    },
    changeProject(payload) {
      // funkcja obsługująca zmianę projektu i wymuszająca przeładowanie danych
      this.project_id = payload;
      this.priorities = {};
      this.deletePriorities = {};
      this.$store.commit(PROJECT_CONFIGS_INVALID);
      this.$store.commit(PROJECT_QUESTIONS_INVALID);
      this.loadData(true, false);
    },
    initConfig() {
      // początkowa konfiguracja modułów
      this.newProjectConfig = {};
      let projectConfigs = this.$store.getters.getProjectConfigs;
      for (let i in projectConfigs) {
        this.newProjectConfig[projectConfigs[i].module + "_" + projectConfigs[i].question_id] = {
          module: projectConfigs[i].module,
          question_id: projectConfigs[i].question_id,
          previous_assign: true,
          current_assign: true,
        };
      }
    },
    record() {
      let insertOrder = Object.values(this.priorities);
      let deleteOrder = Object.values(this.deletePriorities);
      let insert_order = {};
      let delete_order = {};
      let record = {};

      for (let el in insertOrder) {
        if (insertOrder[el].old_priority !== insertOrder[el].new_priority) {
          // sprawdzenie czy poprzednie przypisanie różni się od aktualnego
          insert_order[el] = insertOrder[el];
        }
      }

      for (let el in deleteOrder) {
        if (deleteOrder[el].old_priority !== deleteOrder[el].new_priority) {
          // sprawdzenie czy poprzednie przypisanie różni się od aktualnego
          delete_order[el] = deleteOrder[el];
        }
      }

      delete_order = Object.values(delete_order);
      insert_order = Object.values(insert_order);

      record = {
        // stworzenie obiektu
        insert_order: insert_order,
        delete_order: delete_order,
      };

      this.recordObject = record;
    },
    async saveData() {
      // tworzenie tablicy zmian - odznaczenia modułów i przypisania nowych, a następnie zapisania danych do bazy
      this.record();
      let record = this.recordObject;

      if (record.insert_order.length > 0 || record.delete_order.length > 0) {
        await this.$store.dispatch(SAVE_QUESTION_ORDER, record).catch((error) => {
          // zapisanie danych do bazy
          console.log(error.response);
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getQuestionOrderModifiedRecords === true) {
          // wyświetlenie informacji o zapisaniu
          eventBus.$emit("notify", {
            message: "Rekord został zmodyfikowany",
            type: "success",
          });

          this.checkboxes.edit = false;
          this.priorities = {};
          this.deletePriorities = {};
          this.record();
          this.loadData(true, false);
        }
      }
    },
    handleEditPriority(row) {
      // zmiana priorytetu
      if (this.checkboxes.edit === false) return;
      let priority;
      let version = row.versions[Object.keys(row.versions)[0]]; // przypisanie obiektu wersji projektu
      if (this.version.length > 0) {
        // sprawdzenie czy został wybrany poziom wersji
        priority =
          row.versions[Object.keys(row.versions)[0]].priority_new === null
            ? row.versions[Object.keys(row.versions)[0]].priority
            : row.versions[Object.keys(row.versions)[0]].priority_new;
      } else {
        priority = row.priority_new === null ? row.project_priority : row.priority_new;
      }
      var varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Wprowadź priorytet",
          html: '<div class="form-group">' + '<input id="swal-input1" value="' + priority + '" type="number" class="form-control"/>' + "</div>",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            let key = varThis.version === "" ? row.question_id : row.question_id + "_" + varThis.version; // klucz do zapisu obiektu
            if (varThis.version.length > 0) {
              // sprawdzenie czy został wybrany poziom wersji
              row.versions[Object.keys(row.versions)[0]].priority_new = parseInt(document.getElementById("swal-input1").value); // przypisanie wartości priorytetu na poziomie wersji
              row.versions[Object.keys(row.versions)[0]].sort_priority = row.versions[Object.keys(row.versions)[0]].priority_new;
              varThis.priorities[key] = {
                // stworzenie obiektu zmiany priorytetu do zapisu
                old_priority: row.versions[Object.keys(row.versions)[0]].priority,
                new_priority: parseInt(document.getElementById("swal-input1").value),
                question_id: row.question_id,
                project_id: varThis.project_id,
                module: varThis.version,
              };
              if (varThis.deletePriorities[key]) {
                // usunięcie obiektu zmian jeżeli istniał
                delete varThis.deletePriorities[key];
              }
              varThis.queriedData.sort(
                (a, b) => b.versions[Object.keys(row.versions)[0]].sort_priority - a.versions[Object.keys(row.versions)[0]].sort_priority,
              );
            } else {
              row.priority_new = parseInt(document.getElementById("swal-input1").value); // przypisanei wartości priorytetu na poziomie projektu
              row.sort_priority = row.priority_new;
              varThis.priorities[key] = {
                old_priority: row.project_priority,
                new_priority: parseInt(document.getElementById("swal-input1").value),
                question_id: row.question_id,
                project_id: varThis.project_id,
                module: null,
              };
              if (varThis.deletePriorities[key]) {
                delete varThis.deletePriorities[key];
              }
              varThis.queriedData.sort((a, b) => b.sort_priority - a.sort_priority);
            }
            varThis.searchQuery = " ";
            varThis.searchQuery = "";
            varThis.record();
          }
        })
        .catch(() => {
          return;
        });
    },
    handleDeletePriority(row) {
      if (this.checkboxes.edit === false) return;
      var varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Oznacz priorytet do usunięcia",
          text: `Wybranemu priorytetowi zostanie przywrócona wartość domyślna`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            let key = varThis.version === "" ? row.question_id : row.question_id + "_" + varThis.version;
            if (varThis.version.length > 0) {
              row.versions[Object.keys(row.versions)[0]].priority_new = row.project_priority;
              row.versions[Object.keys(row.versions)[0]].sort_priority = row.versions[Object.keys(row.versions)[0]].priority_new;
              varThis.deletePriorities[key] = {
                old_priority: row.versions[Object.keys(row.versions)[0]].priority,
                new_priority: row.versions[Object.keys(row.versions)[0]].priority_new,
                question_id: row.question_id,
                project_id: varThis.project_id,
                module: varThis.version,
              };
              if (varThis.priorities[key]) {
                delete varThis.priorities[key];
              }
              varThis.queriedData.sort(
                (a, b) => b.versions[Object.keys(row.versions)[0]].sort_priority - a.versions[Object.keys(row.versions)[0]].sort_priority,
              );
            } else {
              row.priority_new = row.priority;
              row.sort_priority = row.priority_new;
              varThis.deletePriorities[key] = {
                old_priority: row.project_priority,
                new_priority: row.priority_new,
                question_id: row.question_id,
                project_id: varThis.project_id,
                module: null,
              };
              if (varThis.priorities[key]) {
                delete varThis.priorities[key];
              }
              varThis.queriedData.sort((a, b) => b.sort_priority - a.sort_priority);
            }
            varThis.searchQuery = " ";
            varThis.searchQuery = "";
            varThis.record();
          }
        })
        .catch(() => {
          return;
        });
    },
    changeVersion() {
      this.priorities = {};
      this.deletePriorities = {};
    },
    changeEdit() {
      if (this.checkboxes.edit === true) {
        this.record();
        let record = this.recordObject;

        let varThis = this;
        if (record.delete_order.length > 0 || record.insert_order.length > 0) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-danger btn-fill",
              cancelButton: "btn btn-light btn-fill",
            },
            buttonsStyling: false,
          });

          swalWithBootstrapButtons
            .fire({
              title: "Czy chcesz wyjść bez zapisywania?",
              text: `Zmiany zostaną odrzucone`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Akceptuj",
              cancelButtonText: "Anuluj",
              reverseButtons: true,
            })
            .then((res) => {
              if (res.isConfirmed) {
                let tableData = varThis.tableData;
                for (let i in tableData) {
                  if (tableData[i].priority_new !== null) {
                    tableData[i].priority_new = null;
                  }
                }
                varThis.priorities = {};
                varThis.deletePriorities = {};
                varThis.checkboxes.edit = false;
                varThis.record();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          this.checkboxes.edit = false;
        }
      } else {
        this.checkboxes.edit = true;
      }
    },
    getRightSelectWidth() {
      this.rightSelectWidth = document.getElementById("rightSelect").offsetWidth;
    },
    getLeftSelectWidth() {
      this.questionOrderLeftSelectWidth = document.getElementById("questionOrderLeftSelect").offsetWidth;
    },
    takeToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
  },
  created() {
    this.loadData(false, false);
    this.record();
    eventBus.$on("forceReload", () => {
      this.loadData(false, true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>

<style scoped>
.has-gutter:hover {
  cursor: pointer;
}

.el-table,
.el-table tr,
.el-table thead th {
  background-color: #fff !important;
}
.blue > td {
  /* color: blue; */
  background-color: #edf9ff;
}
.red {
  color: red;
}
.pink {
  color: pink;
}
.grey > td {
  /* background-color: #ededed; */
  color: silver;
}
.bold {
  font-weight: bold;
}

a,
a:hover {
  text-decoration: none;
  color: red;
}
</style>
