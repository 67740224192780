<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 v-if="!editMode" class="card-title">Nowy system</h4>
        <h4 v-if="editMode" class="card-title">Edycja systemu</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Nazwa Systemu</label>
          <fg-input
            type="text"
            name="system_name"
            data-vv-as="Nazwa systemu"
            v-validate="modelValidations.system_name"
            :error="getError('system_name')"
            v-model="model.system_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Opis systemu</label>
          <fg-input
            type="text"
            name="system_description"
            data-vv-as="Opis systemu"
            v-validate="modelValidations.system_description"
            :error="getError('system_description')"
            v-model="model.system_description"
          ></fg-input>
        </div>

      </div>
      <div class="card-footer text-center">
        <router-link to="/admin/systems">
          <p-button type="default" link style="margin-right: 5vw">
            Anuluj
          </p-button>
        </router-link>
        <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Select, Option, Switch } from "element-ui";

import { SAVE_SYSTEM, SYSTEMS_INVALID } from "src/store/actions/admin/systems";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  data() {
    return {
      model: {
        system_id: null,
        system_name: "",
        system_description: "",
      },
      modelValidations: {
        system_id: {
          numeric: true,
          min: 1
        },
        system_name: {
          required: true,
          // regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .-]+)$",
          regex: regexp.NAME_REGEXP,
          min: 3,
          max: 32
        },
        system_description: {
          // regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .-]+)$",
          regex: regexp.TEXT_REGEXP,
          max: 128
        },
      },
      editMode: true
    };
  },
  computed: {
    system_id() {
      return this.$route.params.id;
    }
  },
  methods: {
    loadData() {
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll()

      if (isValid) {
        await this.$store.dispatch(SAVE_SYSTEM, this.model).catch(error => {
          let message = ''
          console.log(error.response)
          switch(error.response.data.code) {
            case 409:
              message = 'Nazwy muszą być unikalne!'
              break;
            default:
              message = "Błąd zapisu danych!"
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning"
          });
        })

        if (this.$store.getters.getSystemsModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
            type: "success"
          });

          this.$store.commit(SYSTEMS_INVALID) // oznacz nieaktualność danych
          this.$router.push({ name: "Systemy" }) // przekieruj do listy
        }
      }
    }
  },
  created() {
    this.loadData();
    if (!!this.system_id) {
      let system = this.$store.getters.getSystems[this.system_id];
      this.model = Object.assign(this.model, system);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  }
};
</script>
<style>
</style>


