<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications transition-name="notification-list" transition-mode="out-in"> </notifications>
    <router-view name="header"></router-view>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <BlockUI v-if="blockFlag">
      <h3>Dostępna nowa wersja aplikacji, przeładuj stronę</h3>
      <p-button link @click="reload()">Odśwież</p-button>
    </BlockUI>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import "sweetalert2/dist/sweetalert2.css";
import "vue-notifyjs/themes/default.css";

import { LOAD_PROFILE_PREFS } from "src/store/actions/admin/profile_prefs";
import { LOAD_PROFILE } from "src/store/actions/admin/profile";
import { AUTH_LOGOUT } from "src/store/actions/auth";

// event bus
import { eventBus } from "./main";

export default {
  data() {
    return {
      blockFlag: false,
    };
  },
  created() {
    if (this.$store.getters.isAuthenticated) {
      // load user data or push login form
      this.init();
    } else {
      this.logout();
    }

    eventBus.$on("logout", () => {
      this.logout();
    });

    eventBus.$on("logged", () => {
      this.init();
    });

    eventBus.$on("incompatibility", () => {
      this.refresh();
    });

    eventBus.$on("notify", (messageData) => {
      this.notify(messageData);
    });
  },
  methods: {
    reload() {
      location.reload(true);
    },
    logout() {
      console.log("Unauthorized, logging out ...");
      eventBus.$emit("notify", {
        message: "Sesja zakończona!",
        type: "warning",
      });
      this.$store.dispatch(AUTH_LOGOUT);
      if (this.$router.currentRoute.name !== "Login") {
        this.$router.push({ name: "Login" });
      }
    },
    init() {
      // app data initialization
      this.$store
        .dispatch(LOAD_PROFILE)
        .then(() => {
          this.$store.dispatch(LOAD_PROFILE_PREFS).catch((error) => {
            eventBus.$emit("notify", {
              message: "Błąd wczytywania danych!",
              type: "warning",
            });
          });
          if (this.$router.currentRoute.name !== "Overview") {
            this.$router.push({ name: "Overview" });
          }
        })
        .catch((error) => {
          this.logout();
        });
    },
    refresh() {
      console.log("Invalid compatibility version");
      eventBus.$emit("notify", {
        message: "Nieaktualna wersja aplikacji, odśwież stronę!",
        type: "danger",
      });
      this.blockFlag = true;
    },
    notify(messageData) {
      // function displays notifications skipping duplicated messages
      if (
        typeof this.$store.state.notifications.notifications[messageData.message] === "undefined" ||
        new Date().getTime() - this.$store.state.notifications.notifications[messageData.message] > this.$store.state.notifications.MESSAGE_INTERVAL
      ) {
        this.$store.commit("updateNotificationTimestamp", messageData.message);
        this.$notify(messageData);
      }
    },
  },
};
</script>
<style lang="scss">
.v--modal-overlay {
  // nadpisanie z-index modala
  z-index: 1030 !important;
}

.el-select .el-input {
  &:hover {
    .el-input__icon,
    input {
      &::placeholder {
        color: black !important;
      }
      color: black !important;
    }
  }
}

.el-tag.el-tag--info {
  color: #eef8fb !important;
}

.el-tree-node__content {
  height: 35px !important;
}

.el-table th > .cell {
  word-break: break-word;
}

/* change font size to change size */

.loader {
  font-size: 20px;
  position: fixed;
  top: 0px;
  right: 5vw;
  width: 4em;
  height: 1em;
  margin: 100px auto;
  z-index: 1000;
}

.dot {
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background: #333;
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.dot1,
.dot2 {
  left: 0;
}

.dot3 {
  left: 1.5em;
}

.dot4 {
  left: 3em;
}

@keyframes reveal {
  from {
    transform: scale(0.001);
  }
  to {
    transform: scale(1);
  }
}

@keyframes slide {
  to {
    transform: translateX(1.5em);
  }
}

.dot1 {
  animation-name: reveal;
}

.dot2,
.dot3 {
  animation-name: slide;
}

.dot4 {
  animation-name: reveal;
  animation-direction: reverse; /* thx @HugoGiraudel */
}
</style>

<style>
.swal2-icon {
  width: 1.5em !important;
  height: 1.5em !important;
  border-width: 0.1em !important;
  margin: 30px !important;
}
.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1em !important;
}

.el-progress-bar__outer {
  background-color: #e06c7b !important;
}

.flatPickr {
  width: 250px;
  height: 40px;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 10px;
}
</style>
