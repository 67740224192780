import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_STATUS_RAPORT_CHART_DATA,
  STATUS_RAPORT_CHART_DATA_LOADING,
  STATUS_RAPORT_CHART_DATA_LOADING_SUCCESS,
  STATUS_RAPORT_CHART_DATA_LOADING_ERROR,
  STATUS_RAPORT_CHART_DATA_INVALID
} from 'src/store/actions/secure/statusRaport/status_raport_chart_data'

const storeObjectConfig = {
  objectName: "StatusRaport",
  getterName: "getStatusRaportChartData",
  loadActionName: LOAD_STATUS_RAPORT_CHART_DATA,
  loadingMutationName: STATUS_RAPORT_CHART_DATA_LOADING,
  loadingSuccessMutationName: STATUS_RAPORT_CHART_DATA_LOADING_SUCCESS,
  loadingErrorMutationName: STATUS_RAPORT_CHART_DATA_LOADING_ERROR,
  invalidMutationName: STATUS_RAPORT_CHART_DATA_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/statusRaport/chart_data",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
