<template>
  <div :class="details ? 'conv-inner-wrapper' : 'conv-inner-wrapper conv-inner-wrapper-details'">
    <div class="conv-card-border text-center">
      <el-tooltip class="item" effect="light" :content="description" placement="top" :open-delay="500">
        <div class="conv-card-header">{{ description }}</div>
      </el-tooltip>
      <div class="conv-card-name">{{ `(${name})` }}</div>
      <div class="conv-input-counter">{{ getCounter(inputCounter, input_percentage) }}</div>
      <el-progress
        v-if="next && next.percentage"
        class="conv-progress"
        :percentage="getPercentage(next.percentage)"
        :stroke-width="8"
        :color="'#9ce08f'"
        :show-text="false"
      ></el-progress>
      <div v-if="summary">{{ `Współczynnik konwersji dla ścieżki: ${summary}%` }}</div>
    </div>
    <div class="conv-arrow-wrapper" v-if="next">
      <div class="conv-arrow-box">
        <div class="conv-arrow-border">
          <div class="conv-arrow-box-header">{{ getCounter(next.count, next.percentage) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Progress, Tooltip } from "element-ui";

import utils from "src/utils";

export default {
  props: ["details", "name", "description", "entrance", "step_udid", "next", "summary", "input_percentage"],
  components: {
    [Progress.name]: Progress,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {};
  },
  methods: {
    formatNumber(num) {
      return utils.formatNumber(num);
    },
    getPercentage(percentage) {
      return percentage > 100 ? 100 : percentage;
    },
    getCounter(counter, percentage) {
      let result = "";
      if (!counter) counter = 0;
      result += this.formatNumber(counter);
      if (percentage) {
        result += ` (${percentage}%)`;
      }
      return result;
    },
  },
  computed: {
    inputCounter() {
      return this.entrance ? this.entrance.count : this.step_udid.display_page_unique_udid;
    },
  },
};
</script>
<style scoped lang="scss">
.conv-inner-wrapper {
  height: 100%;
}

.conv-inner-wrapper-details {
  width: 360px;
}

.conv-card-border {
  border: 1px solid gray;
  width: 100%;
  height: 150px;
}

.conv-card-header {
  padding-top: 8px;
  font-size: 1.8em;
  overflow: hidden;
  height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.conv-card-name {
  font-size: 1.4em;
}

.conv-input-counter {
  font-size: 2em;
  font-weight: bold;
  color: #1b69bd;
}

.conv-progress {
  margin: 10px 20px;
}

.conv-arrow-wrapper {
  background-image: url("@/assets/arrow.svg");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-x: center;
  display: flex;
  height: auto;
  min-height: calc(100% - 150px);
  align-items: center;
}

.conv-arrow-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  width: 100%;
}

.conv-arrow-border {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: rgba(248, 249, 250, 0.3);
}

.conv-arrow-box-header {
  font-size: 1.8em;
  font-weight: bold;
}

.conv-arrow-box-content {
  font-size: 1.1em;
}
</style>
