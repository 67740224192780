<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row headerRow">
      <div class="col-sm-12 col-md-6">
        <h5 class="title">Statystyki Demograficzne</h5>
      </div>
    </div>

    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 text-sm-left col-md-4 text-md-left MailingStats__dateRange">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="-"
              start-placeholder="Data początkowa"
              end-placeholder="Data końcowa"
              :picker-options="pickerOptions"
              :clearable="false"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
            >
            </el-date-picker>
          </div>
          <div class="col-sm-4">
            <label>Projekt</label>
            <fg-input>
              <el-select v-model="filter.projects" name="projects" filterable clearable multiple>
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Filtr banerów</label>
            <fg-input
              class="MailingStats__banner_filter"
              placeholder="Filtruj po nazwie"
              name="banner_filter"
              data-vv-as="Filtr banerów"
              v-model="filter.banner_filter"
              v-validate="filterValidation.banner_filter"
              :error="getError('banner_filter')"
            >
            </fg-input>
          </div>

        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Baner</label>
            <fg-input>
              <el-select
                v-model="filter.banners"
                name="banners"
                filterable
                remote
                reserve-keyword
                clearable
                multiple
                :remote-method="remoteSearch"
                :loading="loading"
              >
                <el-option
                  v-for="banner in bannerConfig"
                  :label="'[' + banner.BannerID + '] ' + banner.BannerName"
                  :value="banner.BannerID"
                  :key="banner.BannerID"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-4 col-lg-2">
            <label class="d-block">Płeć</label>
            <el-select v-model="filter.sex" multiple placeholder="Wybierz pleć" filterable clearable class="mb-2">
              <el-option
                v-for="sex in sexList"
                :key="sex"
                :label="sex"
                :value="sex">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-4 col-lg-2">
            <label class="d-block">Wiek</label>
            <el-select v-model="filter.age" multiple placeholder="Wybierz wiek" filterable clearable class="mb-2">
              <el-option
                v-for="age in ageList"
                :key="age"
                :label="age"
                :value="age">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-4 col-lg-2">
            <label class="d-block">Lead rodzaj</label>
            <el-select v-model="filter.leadKind"  placeholder="Get lead kind" filterable @change="clarSortMytable">
              <el-option
                v-for="(leadKind, keyKind) in leadKindList"
                :key="keyKind"
                :label="leadKind"
                :value="keyKind">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-12 right-bottom">
            <p-button class="MailingStats__buttonsMargin" type="success" @click="loadStats">Statystyki</p-button>
          </div>
        </div>
      </div>
      <hr />
      <h6>
        <div class="card-footer" style="display: flex; justify-content: space-between">
          <span>Przedział dat:</span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </div>
      </h6>
    </div>

    <div class="card">
      <div class="card-body row">
        <el-table  ref="myTable" :data="queriedData" style="width: 100%" :default-sort="{ prop: 'project_id', order: 'descending' }">
          <el-table-column label="ID" property="project_id" width="60" align="left"></el-table-column>
          <el-table-column label="Projekt" property="project_name" min-width="150" align="left">
            <template v-slot="props">
              {{ props.row.project_name }}
            </template>
          </el-table-column>
          <el-table-column label="Płeć" property="sex" min-width="100" align="left">
            <template v-slot="props">
              <div class="sexColumn">
                <span
                  ><b>{{ props.row.sex }}</b></span
                >
                <span>{{formatNumber(props.row[`sum_${filter.leadKind}`])}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="isAge('18-24')" label="18-24" :prop="`p1_${filter.leadKind}`" width="100" align="right" sortable>
          </el-table-column>
          <el-table-column v-if="isAge('25-34')" label="25-34" :prop="`p2_${filter.leadKind}`" width="110" align="right" sortable>
          </el-table-column>
          <el-table-column v-if="isAge('35-444')" label="35-44" :prop="`p3_${filter.leadKind}`" width="120" align="right" sortable>
          </el-table-column>
          <el-table-column v-if="isAge('45-55')" label="45-55" :prop="`p4_${filter.leadKind}`" width="120" align="right" sortable>
          </el-table-column>
          <el-table-column v-if="isAge('55+')" label="55+" :prop="`p5_${filter.leadKind}`" width="120" align="right" sortable>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body row">
        <div class="col-sm-6 pagination-info">
          <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"> </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

import {
  DatePicker,
  Select,
  Option,
  OptionGroup,
  Tooltip,
  RadioButton,
  RadioGroup,
  CheckboxGroup,
  CheckboxButton
} from "element-ui";

// ui components
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions
import { LOAD_DEMOGRAPHY_STATS, DEMOGRAPHY_STATS_INVALID } from "src/store/actions/stats/demography_stats";
import { LOAD_BANNER_CONFIG, BANNER_CONFIG_INVALID } from "src/store/actions/stats/banner_config";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";

// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
    PPagination,
  },
  data() {
    return {
      loading: false,
      filter: {
        banners: [],
        banner_filter: "",
        projects: [],
        sex:[],
        age:[],
        leadKind:'lead',
      },
      filterValidation: {
        banner_filter: {
          regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ._-]+)$",
          max: 32,
        },
      },
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["project_id"],
      dateRange: ["", ""],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      questionSelectWidth: null,
      sexList: ["K", "M"],
      ageList:['18-24','25-34','35-44','45-55','55+'],
      leadKindList:{'lead':'Lead','new_lead':'NewLead','complete':'Complete'},
    };
  },
  computed: {

    demographyStats() {
      return this.$store.getters.getDemographyStats;
    },
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    tableData() {
      if (this.$store.state.demography_stats.loading_status !== "success") return [];

      let check = this.checkAll;
      let demographyStats = this.demographyStats;
      let tableData = [];


      for (let pid in demographyStats) {
        // iteracja po bannerach
        let record = demographyStats[pid];
        let tableObject = {
          project_id: record.project_id,
          project_name: record.project_name,
          sum_complete: 0,
          sum_new_lead: 0,
          sum_lead: 0,
          p1_complete: 0,
          p1_new_lead: 0,
          p1_lead: 0,
          p2_complete: 0,
          p2_new_lead: 0,
          p2_lead: 0,
          p3_complete: 0,
          p3_new_lead: 0,
          p3_lead: 0,
          p4_complete: 0,
          p4_new_lead: 0,
          p4_lead: 0,
          p5_complete: 0,
          p5_new_lead: 0,
          p5_lead: 0,
        };

        if(this.filter.sex.length === 0 || this.filter.sex.includes('M')) {
          let tableObjectM = {...tableObject, sex: "M"};

          if(this.isAge('18-24')) {
            this.getTableObject(tableObjectM, "p1", "M", record, "18-24");
          }
          if(this.isAge('25-34')) {
            this.getTableObject(tableObjectM, "p2", "M", record, "25-34");
          }
          if(this.isAge('35-44')) {
            this.getTableObject(tableObjectM, "p3", "M", record, "35-44");
          }
          if(this.isAge('45-55')) {
            this.getTableObject(tableObjectM, "p4", "M", record, "45-55");
          }
          if(this.isAge('55+')) {
            this.getTableObject(tableObjectM, "p5", "M", record, "55+");
          }
          tableObjectM.sum_complete =
            tableObjectM.p1_complete + tableObjectM.p2_complete + tableObjectM.p3_complete + tableObjectM.p4_complete + tableObjectM.p5_complete;
          tableObjectM.sum_lead =
            tableObjectM.p1_lead + tableObjectM.p2_lead + tableObjectM.p3_lead + tableObjectM.p4_lead + tableObjectM.p5_lead;
          tableObjectM.sum_new_lead =
            tableObjectM.p1_new_lead + tableObjectM.p2_new_lead + tableObjectM.p3_new_lead + tableObjectM.p4_new_lead + tableObjectM.p5_new_lead;

          tableData.push(tableObjectM);
        }
        if(this.filter.sex.length === 0 || this.filter.sex.includes('K')) {
          let tableObjectK = { ...tableObject, sex: "K" };
          if(this.isAge('18-24')) {
            this.getTableObject(tableObjectK, "p1", "K", record, "18-24");
          }
          if(this.isAge('25-34')) {
            this.getTableObject(tableObjectK, "p2", "K", record, "25-34");
          }
          if(this.isAge('35-44')) {
            this.getTableObject(tableObjectK, "p3", "K", record, "35-44");
          }
          if(this.isAge('45-55')) {
            this.getTableObject(tableObjectK, "p4", "K", record, "45-55");
          }
          if(this.isAge('55+')) {
            this.getTableObject(tableObjectK, "p5", "K", record, "55+");
          }

          tableObjectK.sum_complete =
            tableObjectK.p1_complete + tableObjectK.p2_complete + tableObjectK.p3_complete + tableObjectK.p4_complete + tableObjectK.p5_complete;
          tableObjectK.sum_new_lead =
            tableObjectK.p1_new_lead + tableObjectK.p2_new_lead + tableObjectK.p3_new_lead + tableObjectK.p4_new_lead + tableObjectK.p5_new_lead;
          tableObjectK.sum_lead =
            tableObjectK.p1_lead + tableObjectK.p2_lead + tableObjectK.p3_lead + tableObjectK.p4_lead + tableObjectK.p5_lead;
          tableData.push(tableObjectK);
        }
      }
      tableData.sort((a, b) => b.project_id - a.project_id);
      return tableData;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
  },
  methods: {
    clarSortMytable(){
      this.$refs.myTable.clearSort();
    },
    isAge(age){
      if(this.filter.age.length > 0 && this.filter.age.includes(age) === false){
        return false;
      }
      return true;
    },
    formatNumber(num) {
      return utils.formatNumber(num);
    },
    getPercentage(a, b) {
      if (b === 0) {
        return "-";
      }
      return Math.round((a / b) * 100 * 10) / 10 + "%";
    },
    async loadData(force) {
      this.loading = true;
      if (force) {
        // this.$store.commit(SETTLEMENTS_INVALID);
      }

      await this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    async loadStats(force) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        return;
      }

      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      if (force) {
        this.$store.commit(DEMOGRAPHY_STATS_INVALID);
      }
      this.loading = true;

      if (force && this.dateRange[0] !== "") {
        let date = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        };

        const filters = Object.assign({}, date, this.filter);
        filters.mailingType = this.mailingType;

        await this.$store.dispatch(LOAD_DEMOGRAPHY_STATS, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
        this.loading = false;
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, { searchQuery }).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    getTableObject(tableObject, objectName, sex, record, recordName) {
      if (record[sex] && record[sex][recordName]) {
        tableObject[`${objectName}_complete`] = record[sex][recordName].complete ? record[sex][recordName].complete : 0;
        tableObject[`${objectName}_new_lead`] = record[sex][recordName].new_lead ? record[sex][recordName].new_lead : 0;
        tableObject[`${objectName}_lead`] = record[sex][recordName].lead ? record[sex][recordName].lead : 0;
      }

      return tableObject;
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if(this.filter.sex.length === 0 || this.filter.sex.length === 2){
        if (columnIndex < 2) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        }
      }
    },
  },
  mounted() {},
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadStats(true);
    });
  },
  beforeDestroy: function () {
    this.$store.commit(DEMOGRAPHY_STATS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped>
.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.MailingStats__dateRange {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 10px 0;
}

.MailingStats__buttonsMargin {
  margin-left: 10px;
  margin-right: 10px;
}

.count-footer {
  font-size: 12px;
  padding-top: 3px;
}

.sexColumn {
  display: flex;
  justify-content: space-between;
}
</style>
