export const LOAD_DEMOGRAPHY_STATS = "LOAD_DEMOGRAPHY_STATS";
export const DEMOGRAPHY_STATS_LOADING = "DEMOGRAPHY_STATS_LOADING";
export const DEMOGRAPHY_STATS_LOADING_SUCCESS = "DEMOGRAPHY_STATS_LOADING_SUCCESS";
export const DEMOGRAPHY_STATS_LOADING_ERROR = "DEMOGRAPHY_STATS_LOADING_ERROR";
export const DEMOGRAPHY_STATS_INVALID = "DEMOGRAPHY_STATS_INVALID";

export const SAVE_DEMOGRAPHY_STATS = "SAVE_DEMOGRAPHY_STATS";
export const DEMOGRAPHY_STATS_SAVING = "DEMOGRAPHY_STATS_SAVING";
export const DEMOGRAPHY_STATS_SAVING_SUCCESS = "DEMOGRAPHY_STATS_SAVING_SUCCESS";
export const DEMOGRAPHY_STATS_SAVING_ERROR = "DEMOGRAPHY_STATS_SAVING_ERROR";
