<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 text-sm-left col-md-4 text-md-left MailingStats__dateRange">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="-"
              start-placeholder="Data początkowa"
              end-placeholder="Data końcowa"
              :picker-options="pickerOptions"
              :clearable="false"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
            >
            </el-date-picker>
          </div>
          <div class="col-sm-4">
            <div>
              <label>Rodzaj wysyłki</label>
            </div>
            <el-radio-group v-model="mailingType" size="medium">
              <el-radio-button label="Wewnętrzne"></el-radio-button>
              <el-radio-button label="Zewnętrzne"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="col-sm-4 right-bottom">
            <p-button class="MailingStats__buttonsMargin" type="success" @click="loadStats">Statystyki</p-button>
            <p-button class="MailingStats__buttonsMargin" type="success" @click="handleChart">Wykresy</p-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Filtr banerów</label>
            <fg-input
              class="MailingStats__banner_filter"
              placeholder="Filtruj po nazwie"
              name="banner_filter"
              data-vv-as="Filtr banerów"
              v-model="filter.banner_filter"
              v-validate="filterValidation.banner_filter"
              :error="getError('banner_filter')"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Baner</label>
            <fg-input>
              <el-select
                v-model="filter.banners"
                name="banners"
                filterable
                remote
                reserve-keyword
                clearable
                multiple
                :remote-method="remoteSearch"
                :loading="loading"
              >
                <el-option
                  v-for="banner in bannerConfig"
                  :label="'[' + banner.BannerID + '] ' + banner.BannerName"
                  :value="banner.BannerID"
                  :key="banner.BannerID"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Projekt</label>
            <fg-input>
              <el-select v-model="filter.projects" name="projects" filterable clearable multiple>
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>
      <hr />
      <h6>
        <div class="card-footer" style="display: flex; justify-content: space-between">
          <span>Przedział dat:</span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </div>
        <div class="card-footer count-footer" style="display: flex; justify-content: space-between">
          <span>Suma wysłanych:</span>
          <span>{{ sumSent | formatNumber }}</span>
        </div>
      </h6>
    </div>

    <div class="card">
      <div class="card-body row">
        <div class="col-sm-3">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </div>
        <div class="col-sm-6 text-center">
          <p-button type="info" size="sm" :disabled="!showButton" @click="showDetails()">Szczegóły</p-button>
        </div>
        <div class="col-sm-3">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Szukaj" v-model="searchQuery" addon-right-icon="nc-icon nc-zoom-split"> </fg-input>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <el-table
          :data="queriedData"
          style="width: 100%"
          @header-click="headerClick"
          @sort-change="sortChange"
          @cell-click="cellClick"
          :cell-style="colStyle"
          :header-cell-style="headerStyle"
          :default-sort="{ prop: 'banner_id', order: 'descending' }"
        >
          <el-table-column label="Check" property="check" width="70" align="center">
            <template slot="header">
              <i class="fa fa-check-square-o" aria-hidden="true"></i>
            </template>
            <template v-slot="props">
              <p-checkbox @input="rowChecked($event, props.row.banner_id)" v-model="rowCheck[props.row.banner_id]"></p-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="Projekt" property="project_id" min-width="150" align="left" sortable="custom">
            <template v-slot="props">
              {{ props.row.project_name }}
            </template>
          </el-table-column>
          <el-table-column label="Banner" property="banner_id" min-width="200" align="left" sortable="custom">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="props.row.list_name" placement="top" :open-delay="200">
                <span>
                  <div style="cursor: pointer">
                    {{ "[" + props.row.banner_id + "] " + props.row.banner_name }}
                  </div>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Lista" property="list_name" min-width="100" align="left" sortable="custom"> </el-table-column>
          <el-table-column label="WP" property="wp_or" width="140" align="left" sortable="custom">
            <template v-slot="props">
              <div>
                {{ props.row.wp_or + "/" + props.row.wp_ctr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="ONET" property="onet_or" width="140" align="left" sortable="custom">
            <template v-slot="props">
              <div>
                {{ props.row.onet_or + "/" + props.row.onet_ctr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="O2" property="o2_or" width="140" align="left" sortable="custom">
            <template v-slot="props">
              <div>
                {{ props.row.o2_or + "/" + props.row.o2_ctr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="INTERIA" property="interia_or" width="140" align="left" sortable="custom">
            <template v-slot="props">
              <div>
                {{ props.row.interia_or + "/" + props.row.interia_ctr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="GMAIL" property="gmail_or" width="140" align="left" sortable="custom">
            <template v-slot="props">
              <div>
                {{ props.row.gmail_or + "/" + props.row.gmail_ctr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="INNA" property="inna_or" width="140" align="left" sortable="custom">
            <template v-slot="props">
              <div>
                {{ props.row.inna_or + "/" + props.row.inna_ctr }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body row">
        <div class="col-sm-6 pagination-info">
          <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"> </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

import { DatePicker, Select, Option, OptionGroup, Tooltip, RadioButton, RadioGroup } from "element-ui";

// ui components
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions
import { LOAD_MAILING_STATS, MAILING_STATS_INVALID } from "src/store/actions/stats/mailing_stats";
import { LOAD_BANNER_CONFIG, BANNER_CONFIG_INVALID } from "src/store/actions/stats/banner_config";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";

import MailingStatsChartModal from "src/components/Utils4/stats/mailings/MailingStatsChartModal.vue";
import MailingStatsModal from "src/components/Utils4/stats/mailings/MailingStatsModal.vue";

// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

import * as priv from "src/privileges.js";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    PPagination,
  },
  data() {
    return {
      loading: false,
      filter: {
        banners: [],
        banner_filter: "",
        projects: [],
      },
      filterValidation: {
        banner_filter: {
          regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ._-]+)$",
          max: 32,
        },
      },
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      rowCheck: {},
      checkedRows: {},
      checkAll: false,
      showButton: false,
      sort: {
        prop: "bannerid",
        order: "ascending",
      },
      searchQuery: "",
      propsToSearch: ["banner_id", "banner_name"],
      mailingType: "Wewnętrzne",
      dateRange: ["", ""],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      questionSelectWidth: null,
    };
  },
  computed: {
    mailingStats() {
      return this.$store.getters.getMailingStats;
    },
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    tableData() {
      if (this.$store.state.mailing_stats.loading_status !== "success") return [];

      let check = this.checkAll;
      let mailingStats = this.mailingStats;
      let tableData = [];

      for (let bid in mailingStats) {
        // iteracja po bannerach
        let record = mailingStats[bid];
        let tableObject = {
          project_id: record.project_id,
          project_name: record.project_name,
          banner_id: bid,
          banner_name: record.banner_name,
          list_name: record.list_name,
          o2_sent: 0,
          o2_displayed: 0,
          o2_clicked: 0,
          o2_or: "-",
          o2_ctr: "-",
          gmail_sent: 0,
          gmail_displayed: 0,
          gmail_clicked: 0,
          gmail_or: "-",
          gmail_ctr: "-",
          inna_sent: 0,
          inna_displayed: 0,
          inna_clicked: 0,
          inna_or: "-",
          inna_ctr: "-",
          interia_sent: 0,
          interia_displayed: 0,
          interia_clicked: 0,
          interia_or: "-",
          interia_ctr: "-",
          onet_sent: 0,
          onet_displayed: 0,
          onet_clicked: 0,
          onet_or: "-",
          onet_ctr: "-",
          wp_sent: 0,
          wp_displayed: 0,
          wp_clicked: 0,
          wp_or: "-",
          wp_ctr: "-",
        };
        if (record["O2"]) {
          tableObject.o2_sent = record["O2"].sent ? record["O2"].sent : 0;
          tableObject.o2_displayed = record["O2"].displayed ? record["O2"].displayed : 0;
          tableObject.o2_clicked = record["O2"].clicked ? record["O2"].clicked : 0;
          tableObject.o2_or = this.getPercentage(tableObject.o2_displayed, tableObject.o2_sent);
          tableObject.o2_ctr = this.getPercentage(tableObject.o2_clicked, tableObject.o2_sent);
        }
        if (record["GMAIL"]) {
          tableObject.gmail_sent = record["GMAIL"].sent ? record["GMAIL"].sent : 0;
          tableObject.gmail_displayed = record["GMAIL"].displayed ? record["GMAIL"].displayed : 0;
          tableObject.gmail_clicked = record["GMAIL"].clicked ? record["GMAIL"].clicked : 0;
          tableObject.gmail_or = this.getPercentage(tableObject.gmail_displayed, tableObject.gmail_sent);
          tableObject.gmail_ctr = this.getPercentage(tableObject.gmail_clicked, tableObject.gmail_sent);
        }
        if (record["INNA"]) {
          tableObject.inna_sent = record["INNA"].sent ? record["INNA"].sent : 0;
          tableObject.inna_displayed = record["INNA"].displayed ? record["INNA"].displayed : 0;
          tableObject.inna_clicked = record["INNA"].clicked ? record["INNA"].clicked : 0;
          tableObject.inna_or = this.getPercentage(tableObject.inna_displayed, tableObject.inna_sent);
          tableObject.inna_ctr = this.getPercentage(tableObject.inna_clicked, tableObject.inna_sent);
        }
        if (record["INTERIA"]) {
          tableObject.interia_sent = record["INTERIA"].sent ? record["INTERIA"].sent : 0;
          tableObject.interia_displayed = record["INTERIA"].displayed ? record["INTERIA"].displayed : 0;
          tableObject.interia_clicked = record["INTERIA"].clicked ? record["INTERIA"].clicked : 0;
          tableObject.interia_or = this.getPercentage(tableObject.interia_displayed, tableObject.interia_sent);
          tableObject.interia_ctr = this.getPercentage(tableObject.interia_clicked, tableObject.interia_sent);
        }
        if (record["ONET"]) {
          tableObject.onet_sent = record["ONET"].sent ? record["ONET"].sent : 0;
          tableObject.onet_displayed = record["ONET"].displayed ? record["ONET"].displayed : 0;
          tableObject.onet_clicked = record["ONET"].clicked ? record["ONET"].clicked : 0;
          tableObject.onet_or = this.getPercentage(tableObject.onet_displayed, tableObject.onet_sent);
          tableObject.onet_ctr = this.getPercentage(tableObject.onet_clicked, tableObject.onet_sent);
        }
        if (record["WP"]) {
          tableObject.wp_sent = record["WP"].sent ? record["WP"].sent : 0;
          tableObject.wp_displayed = record["WP"].displayed ? record["WP"].displayed : 0;
          tableObject.wp_clicked = record["WP"].clicked ? record["WP"].clicked : 0;
          tableObject.wp_or = this.getPercentage(tableObject.wp_displayed, tableObject.wp_sent);
          tableObject.wp_ctr = this.getPercentage(tableObject.wp_clicked, tableObject.wp_sent);
        }
        tableObject.sent =
          tableObject.o2_sent +
          tableObject.gmail_sent +
          tableObject.inna_sent +
          tableObject.interia_sent +
          tableObject.onet_sent +
          tableObject.wp_sent;
        tableData.push(tableObject);
      }
      tableData.sort((a, b) => b.banner_id - a.banner_id);
      return tableData;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
    sumSent() {
      let cnt = 0;

      if (this.mailingStats && Object.values(this.mailingStats).length > 0) {
        Object.values(this.mailingStats).forEach((row) => {
          cnt += row.all_sent;
        });
      }

      return cnt;
    },
  },
  methods: {
    getPercentage(a, b) {
      if (b === 0) {
        return "-";
      }
      return Math.round((a / b) * 100 * 10) / 10 + "%";
    },
    cellClick(row, column) {
      if (column.property === "banner_id") {
        let banner_id = row.banner_id;
        let mailingStats = { banner_id: this.mailingStats[banner_id] };
        this.showModal(mailingStats);
      }
    },
    showDetails() {
      let checkedRows = Object.keys(this.checkedRows);
      let mailingStats = {};
      checkedRows.map((bid) => {
        mailingStats[bid] = this.mailingStats[bid];
      });
      this.showModal(mailingStats);
    },
    showModal(mailingStats) {
      // wyświetlenie modala szczegółów
      this.$modal.show(
        MailingStatsModal,
        {
          mailingStats: mailingStats,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(MailingStatsModal);
              },
            },
          ],
        },
        {
          name: "MailingStatsModal",
          draggable: false,
          scrollable: true,
          width: "80%",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    async loadData(force) {
      this.loading = true;
      if (force) {
        // this.$store.commit(SETTLEMENTS_INVALID);
      }

      await this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    async loadStats(force) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        return;
      }

      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      if (force) {
        this.$store.commit(MAILING_STATS_INVALID);
      }
      this.loading = true;

      if (force && this.dateRange[0] !== "") {
        let date = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        };

        const filters = Object.assign({}, date, this.filter);
        filters.mailingType = this.mailingType;

        await this.$store.dispatch(LOAD_MAILING_STATS, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
        this.loading = false;
        this.initRowCheck();
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, { searchQuery }).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    rowChecked(bool, banner_id) {
      if (bool) {
        this.checkedRows[banner_id] = true;
      } else {
        delete this.checkedRows[banner_id];
      }
      if (Object.keys(this.checkedRows).length > 0) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    initRowCheck() {
      this.rowCheck = {};
      this.checkedRows = {};
      for (let pid in this.mailingStats) {
        for (let bid in this.mailingStats[pid]) {
          this.rowCheck[bid] = false;
        }
      }
    },
    headerClick(column, event) {
      if (column.label === "Check") {
        if (!this.checkAll) {
          for (let i in this.tableData) {
            this.checkedRows[this.tableData[i].banner_id] = true;
            this.rowCheck[this.tableData[i].banner_id] = true;
            this.showButton = true;
          }
        } else {
          for (let i in this.rowCheck) {
            this.rowCheck[i] = false;
          }
          this.checkedRows = {};
          this.showButton = false;
        }
        this.checkAll = !this.checkAll;
      }
    },
    sortChange(obj) {
      this.sort = {
        prop: obj.prop,
        order: obj.order,
      };
      if (obj.order === "descending") {
        if (obj.prop.includes("or")) {
          this.tableData.sort(
            (a, b) => parseFloat(b[obj.prop]) - parseFloat(a[obj.prop]) || isNaN(parseFloat(a[obj.prop])) - isNaN(parseFloat(b[obj.prop])),
          );
        }
        if (obj.prop === "domain" || obj.prop === "banner_name") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (obj.prop.includes("or")) {
          this.tableData.sort(
            (a, b) => parseFloat(a[obj.prop]) - parseFloat(b[obj.prop]) || isNaN(parseFloat(b[obj.prop])) - isNaN(parseFloat(a[obj.prop])),
          );
        }
        if (obj.prop === "domain" || obj.prop === "banner_name") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        this.tableData.sort((a, b) => a.banner_id - b.banner_id);
      }
      this.searchQuery += " ";
      this.searchQuery = this.searchQuery.slice(0, -1);
    },
    handleChart() {
      // wyświetlenie modala wykresu
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        return;
      }
      let filter = utils.deepCopy(this.filter);
      let mailingType = utils.deepCopy(this.mailingType);

      let date = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
      };

      this.$modal.show(
        MailingStatsChartModal,
        {
          date: date,
          filter: filter,
          mailingType: mailingType,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(MailingStatsChartModal);
              },
            },
          ],
        },
        {
          name: "MailingStatsChartModal",
          draggable: false,
          scrollable: true,
          width: "90%",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    colStyle() {
      return "font-size: 12px;";
    },
    headerStyle() {
      return "font-size: 12px;";
    },
  },
  mounted() {},
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadStats(true);
    });
  },
  beforeDestroy: function () {
    this.$store.commit(MAILING_STATS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped>
.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.MailingStats__dateRange {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 10px 0;
}

.MailingStats__buttonsMargin {
  margin-left: 10px;
  margin-right: 10px;
}

.count-footer {
  font-size: 12px;
  padding-top: 3px;
}
</style>
