<template>
  <div class="card">
    <div class="card-body conv-card-body-table">
      <table class="table table-hover">
        <tbody>
          <tr v-for="(row, index) in tableData" :key="index">
            <td class="conv-table-row">{{ `${getDescription(row.description)} (${row.name})` }}</td>
            <td class="conv-table-row text-right">{{ formatNumber(row.count) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import utils from "src/utils";

export default {
  props: ["tableData"],
  data() {
    return {};
  },
  methods: {
    formatNumber(num) {
      return utils.formatNumber(num);
    },
    getDescription(description) {
      if (_.isNull(description)) {
        return "-";
      } else {
        return description;
      }
    },
  },
};
</script>
<style scoped>
.conv-table-row {
  padding: 4px;
}

.conv-card-body-table {
  padding: 3px !important;
  margin-bottom: -15px !important;
}

table tr:first-child td {
  border-top: none;
}
</style>
