<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="title">Rozliczenia</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker v-model="dateRange" type="daterange" align="center" unlink-panels range-separator="-"
          start-placeholder="Data początkowa" end-placeholder="Data końcowa" :picker-options="pickerOptions" :clearable="false">
        </el-date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body row">
              <div class="col-sm-12 col-md-4">
                <label>Partner</label>
                <fg-input :error="getError('partner_id')" data-vv-as="Partner">
                  <el-select id="leftSelect" v-on:visible-change="getLeftSelectWidth()" v-model="partner_id"
                    name="partner_id" data-vv-as="Partner" filterable>
                    <el-option
                      v-for="partner in partners"
                      :style="leftSelectWidth !== null ? 'width: ' + leftSelectWidth + 'px' : 'width: 300px'"
                      :label="'[' + partner.partner_id + '] ' + partner.partner_name"
                      :value="partner.partner_id"
                      :key="partner.partner_id"
                      >
                    </el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-sm-12 col-md-4">
                <label>Dokładność</label>
                <fg-input>
                  <el-select
                    v-model="time_resolution"
                    name="time_resolution"
                    filterable
                  >
                    <el-option
                      v-for="time_resolution in time_resolutions"
                      :label="time_resolution.time_resolution_name"
                      :value="time_resolution.time_resolution_id"
                      :key="time_resolution.time_resolution_id"
                    ></el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-sm-12 col-md-4 text-md-right text-center">
                <label>Typ</label>
                <fg-input>
                  <el-switch
                    v-model="model_type"
                    active-value="sell"
                    inactive-value="buy"
                    active-text="Sprzedaż"
                    inactive-text="Kupno"
                    active-color="#6bd098"
                    inactive-color="#4286f4"
                  ></el-switch>
                </fg-input>
              </div>
            </div>
            <div class="card-body row">
              <el-table :data="tableData" :summary-method="getSummaries" show-summary :default-sort="{prop: 'Zakres', order: 'ascending'}">
                <el-table-column type="expand">
                  <template v-slot="props">
                    <div class="row">
                      <table class="table table-borderless tabelaLeadow">
                        <thead>
                          <td>Rodzaj</td>
                          <td>Liczba</td>
                          <td>Stawka</td>
                          <td>Koszt</td>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Nowy telefon</td>
                            <td>{{ props.row.TylkoNowyTelefon }}</td>
                            <td>{{ props.row.TylkoNowyTelefonStawka.toLocaleString() + ' zł' }}</td>
                            <td>{{ props.row.TylkoNowyTelefonKwota.toLocaleString() + ' zł' }}</td>
                          </tr>
                          <tr>
                            <td>Nowy email</td>
                            <td>{{ props.row.TylkoNowyEmail }}</td>
                            <td>{{ props.row.TylkoNowyEmailStawka.toLocaleString() + ' zł' }}</td>
                            <td>{{ props.row.TylkoNowyEmailKwota.toLocaleString() + ' zł' }}</td>
                          </tr>
                          <tr>
                            <td>Nowy telefon i mail</td>
                            <td>{{ props.row.NowyEmailTelefon }}</td>
                            <td>{{ props.row.NowyEmailTelefonStawka.toLocaleString() + ' zł' }}</td>
                            <td>{{ props.row.NowyEmailTelefonKwota.toLocaleString() + ' zł' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Deal ID" property="deal_id" width="120" align="center" sortable></el-table-column>
                <el-table-column label="Zakres" property="ShortDate" width="120" align="center" sortable>
                  <template v-slot="props">
                    {{ props.row.Zakres }}
                  </template>
                </el-table-column>
                <el-table-column label="Liczba" property="SumaLeadow" align="right" min-width="190" sortable>
                  <template v-slot="props">
                    {{ props.row.SumaLeadow.toLocaleString() }}
                  </template>
                </el-table-column>
                <el-table-column label="Koszt" property="SumaKwot" align="right" min-width="140" sortable>
                  <template v-slot="props">
                    {{ props.row.SumaKwot.toLocaleString() + ' zł' }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="card-footer row">
              <div class="col-sm-2"></div>
              <div class="col-sm-8 text-center">
                <p-button type="success" @click.prevent="loadData">Wyświetl</p-button>
              </div>
              <div class="col-sm-2 text-right" v-if="this.$store.state.settlements.loading_status === 'success'">
                <downloadExcel
                  :data   = "tableData"
                  :fields = "json_fields"
                  worksheet = "Worksheet"
                  :title="fileTitle"
                  :footer="fileFooter"
                  :name    = "fileName + '.xls'">
                  <p-button type="default" link>XLS</p-button>
              </downloadExcel>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment"; // time manipulation library

import { DatePicker } from "element-ui";

// ui components
import { Table, TableColumn, Select, Option, Switch } from "element-ui";
import { Tabs, TabPane, Card } from 'src/components/UIComponents'
import PPagination from 'src/components/UIComponents/Pagination.vue';

import JsonExcel from 'vue-json-excel'

// vuex actions
import { LOAD_SETTLEMENTS, SETTLEMENTS_INVALID } from "src/store/actions/raports/settlements";
import { LOAD_SETTLEMENTS_PARTNERS } from "src/store/actions/raports/settlements_partners";
// import { PARTNERS_INVALID } from "src/store/actions/partners";

// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

import * as priv from 'src/privileges.js';

Vue.use(Table);
Vue.use(TableColumn);

Vue.use(JsonExcel);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [DatePicker.name]: DatePicker,
    downloadExcel: JsonExcel,
    PPagination,
    TabPane,
    Tabs,
    Card
  },
  data() {
    return {
      partner_id: null,
      leftSelectWidth: null,
      model_type: 'buy',
      dateRange: [utils.today(), utils.today()],
      pickerOptions: {
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      json_fields: {
        'Deal ID': 'deal_id',
        'Zakres': 'Zakres',
        'NowyEmailTelefon': 'NowyEmailTelefon',
        'NowyEmailTelefonStawka': 'NowyEmailTelefonStawka',
        'NowyEmailTelefonKwota': 'NowyEmailTelefonKwotaLabel',
        'TylkoNowyEmail': 'TylkoNowyEmail',
        'TylkoNowyEmailStawka': 'TylkoNowyEmailStawkaLabel',
        'TylkoNowyEmailKwota': 'TylkoNowyEmailKwotaLabel',
        'TylkoNowyTelefon': 'TylkoNowyTelefon',
        'TylkoNowyTelefonStawka': 'TylkoNowyTelefonStawkaLabel',
        'TylkoNowyTelefonKwota': 'TylkoNowyTelefonKwotaLabel',
        'Suma Leadów': 'SumaLeadow',
        'Suma Kwot': 'SumaKwotLabel'
      },
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
      time_resolutions: [
        { time_resolution_id: "RANGE", time_resolution_name: "Cały zakres" },
        { time_resolution_id: "MONTH", time_resolution_name: "Miesiąc" },
        { time_resolution_id: "WEEK", time_resolution_name: "Tydzień" },
        { time_resolution_id: "DAY", time_resolution_name: "Dzień" },
      ],
      time_resolution: "RANGE",
    }
  },
  computed: {
    partners() {
      return this.$store.getters.getSettlementsPartners;
    },
    settlements() {
      return Object.values(this.$store.getters.getSettlements);
    },
    tableData() {
      if (this.$store.state.settlements.loading_status === 'success') {
        const settlements = JSON.parse(JSON.stringify(this.settlements));
        let stats = [];
        if(this.partner_id === 53) {
          stats = settlements.map(row =>
            Object.assign({}, row, {
              NowyEmailTelefonStawkaLabel: String(row.NowyEmailTelefonStawka).replace(".", ",") + ' zł',
              NowyEmailTelefonKwotaLabel: String(row.NowyEmailTelefonKwota).replace(".", ",") + ' zł',
              TylkoNowyEmailStawkaLabel: String(row.TylkoNowyEmailStawka).replace(".", ",") + ' zł',
              TylkoNowyEmailKwotaLabel: String(row.TylkoNowyEmailKwota).replace(".", ",") + ' zł',
              TylkoNowyTelefonStawkaLabel: String(row.TylkoNowyTelefonStawka).replace(".", ",") + ' zł',
              TylkoNowyTelefonKwotaLabel: String(row.TylkoNowyTelefonKwota).replace(".", ",") + ' zł',
              SumaKwotLabel: String(row.SumaKwot).replace(".", ",") + ' zł'
            })
          );
        }
        return stats
      } return []
    },
    fileName() {
      if(this.partner_id !== null) {
        return this.partners[this.partner_id].partner_name + '_' + moment(this.dateRange[0]).format('YYYY-MM-DD') + '_' + moment(this.dateRange[1]).format('YYYY-MM-DD');
      }
      return 'filename';
    },
    fileTitle() {
      if(this.partner_id !== null) {
        return 'Raport dla ' + this.partners[this.partner_id].partner_name + ' dla zakresu od ' + moment(this.dateRange[0]).format('YYYY-MM-DD') + ' do ' + moment(this.dateRange[1]).format('YYYY-MM-DD');
      }
      return 'Raport';
    },
    fileFooter() {
      let actualDate = new Date();
      actualDate = moment(actualDate).format("YYYY-MM-DD, HH:mm:ss");
      if(this.partner_id !== null) {
        return 'Wygenerowano ' + actualDate + ' przez utils4.mobi-me.pl';
      }
      return 'Wygenerowano przez utils4.mobi-me.pl';
    }
  },
  methods: {
    loadData(force) {
      if (force) {
         if (this.dateRange === null) {
          eventBus.$emit("notify", {
            message: "Należy wybrać przedział czasowy!",
            type: "warning"
          });
        } else if (this.partner_id === null) {
          eventBus.$emit("notify", {
            message: "Należy wybrać partnera!",
            type: "warning"
          });
        }
        this.$store.commit(SETTLEMENTS_INVALID);
      }

      this.$store.dispatch(LOAD_SETTLEMENTS_PARTNERS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      if(this.partner_id !== null && this.dateRange !== null) {
        const filters = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
          partner_id: this.partner_id,
          type: this.model_type,
          time_resolution: this.time_resolution
        }

        this.$store.dispatch(LOAD_SETTLEMENTS, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          return;
        }
        if (index === 1) {
          sums[index] = 'Suma';
          return;
        }
        if (index === 3) {
          const values = data.map(item => Number(item[column.property]));
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0).toLocaleString();
        } else {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0).toLocaleString() + ' zł';
          } else {
            sums[index] = ' - ';
          }
        }
      });

      return sums;
    },
    getLeftSelectWidth() {
      this.leftSelectWidth = document.getElementById('leftSelect').offsetWidth;
    }
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style scoped>
.tabelaLeadow td:not(:first-child) {
  text-align: right;
}
</style>
