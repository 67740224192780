<template>
  <div>
    <div class="row">
      <div class="col-md-11">
        <div class="form-group">
          <label>Mail</label>
          <fg-input :error="getError('mail')" data-vv-as="Mail">
            <el-select
              v-model="model.mail"
              name="mail"
              data-vv-as="Mail"
              v-validate="modelValidations.mail"
              filterable
              allow-create
              clearable
              @input="handleMailChange"
            >
              <el-option v-for="record in mail" :label="record" :value="record" :key="record"></el-option>
            </el-select>
          </fg-input>
        </div>
      </div>

      <div class="col-sm-1 helper">
        <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
          <div slot="content">Aby dodać ręcznie email należy wpisać go w danym polu i wybrać kliknięciem</div>
          <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip } from "element-ui";

// event bus
import { eventBus } from "src/main";

import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },
  props: ["mail"],
  data() {
    return {
      model: {
        mail: "",
      },
      modelValidations: {
        mail: {
          regex: regexp.EMAIL_REGEXP,
          min: 1,
          max: 128,
        },
      },
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    handleMailChange() {
      eventBus.$emit("EmailForm_MailChanged", {
        mail: this.model.mail,
      });
    },
  },
  async created() {
    if (this.mail.length > 0) {
      this.model.mail = this.mail[0];
      this.handleMailChange();
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.row:last-child {
  justify-content: center;
}
.helper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
</style>
