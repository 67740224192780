import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  IP_API
} from 'src/store/config'

import {
  SAVE_SEND_EMAIL,
  SEND_EMAIL_SAVING,
  SEND_EMAIL_SAVING_SUCCESS,
  SEND_EMAIL_SAVING_ERROR
} from 'src/store/actions/api-ip/send_email'

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getSendEmail",
  saveActionName: SAVE_SEND_EMAIL,
  savingMutationName: SEND_EMAIL_SAVING,
  savingSuccessMutationName: SEND_EMAIL_SAVING_SUCCESS,
  savingErrorMutationName: SEND_EMAIL_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: IP_API,
  validPeriod: 10, // minutes
  recordType: 'plain',
  saveParams: {
    url: "/mails/send",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
