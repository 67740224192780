<template>
  <div class="card">
    <form>
      <div class="card-header">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h4 v-if="!editMode" class="card-title">Nowy użytkownik</h4>
            <h4 v-if="editMode" class="card-title">Edycja użytkownika {{ getUserLoginById }}</h4>
          </div>
          <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
            <p-button type="default" link v-if="editMode" @click.prevent="showSwal()">
              Usuń użytkownika
            </p-button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Login użytkownika</label>
          <fg-input
            type="text"
            name="user_login"
            data-vv-as="Login użytkownika"
            v-validate="modelValidations.user_login"
            :error="getError('user_login')"
            v-model="model.user_login"
          ></fg-input>
        </div>

        <div class="form-group" v-if="!editMode">
          <label>Hasło użytkownika</label>
          <fg-input
            type="password"
            name="user_pass"
            data-vv-as="Login użytkownika"
            v-validate="modelValidations.user_pass"
            :error="getError('user_pass')"
            v-model="model.user_pass"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Nazwa partnera</label>
          <fg-input
            type="text"
            name="user_partner_name"
            data-vv-as="Nazwa partnera"
            v-validate="modelValidations.user_partner_name"
            :error="getError('user_partner_name')"
            v-model="model.user_partner_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Imię</label>
          <fg-input
            type="text"
            name="user_first_name"
            data-vv-as="Imię"
            v-validate="modelValidations.user_first_name"
            :error="getError('user_first_name')"
            v-model="model.user_first_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Nazwisko</label>
          <fg-input
            type="text"
            name="user_last_name"
            data-vv-as="Nazwisko"
            v-validate="modelValidations.user_last_name"
            :error="getError('user_last_name')"
            v-model="model.user_last_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Email</label>
          <fg-input
            type="text"
            name="user_email"
            data-vv-as="Email"
            v-validate="modelValidations.user_email"
            :error="getError('user_email')"
            v-model="model.user_email"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Telefon</label>
          <fg-input
            type="text"
            name="user_phone"
            data-vv-as="Telefon"
            v-validate="modelValidations.user_phone"
            :error="getError('user_phone')"
            v-model="model.user_phone"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Opis</label>
          <fg-input
            type="text"
            name="user_description"
            data-vv-as="Opis"
            v-validate="modelValidations.user_description"
            :error="getError('user_description')"
            v-model="model.user_description"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Typ użytkownika</label>
          <fg-input :error="getError('user_user_type')" data-vv-as="Nazwa systemu">
            <el-select
              v-model="model.user_user_type"
              name="system_name"
              data-vv-as="Nazwa systemu"
              v-validate="modelValidations.user_user_type"
              filterable
              multiple
              clearable
            >
              <el-option v-for="type in userTypeDict" :label="type" :value="type" :key="type"></el-option>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div class="card-footer text-center">
        <router-link to="/admin/users">
          <p-button type="default" link style="margin-right: 5vw">
            Anuluj
          </p-button>
        </router-link>
        <p-button type="warning" style="margin-right: 5vw" @click.prevent="handleEdit()">
          Zmień hasło
        </p-button>
        <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Select, Option, Switch } from "element-ui";
import Swal from "sweetalert2";

import { SAVE_USER, DELETE_USER, USERS_INVALID } from "src/store/actions/admin/users";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
  },
  data() {
    return {
      model: {
        user_id: null,
        user_login: "",
        user_pass: "",
        user_partner_name: "",
        user_first_name: "",
        user_last_name: "",
        user_email: "",
        user_phone: "",
        user_description: "",
        user_user_type: [],
      },
      modelValidations: {
        user_id: {
          numeric: true,
          min: 1,
        },
        user_login: {
          required: true,
          regex: regexp.NAME_REGEXP,
          min: 3,
          max: 32,
        },
        user_pass: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 3,
          max: 32,
        },
        user_partner_name: {
          regex: regexp.NAME_REGEXP,
          min: 3,
          max: 32,
        },
        user_first_name: {
          regex: regexp.NAME_REGEXP,
          min: 3,
          max: 32,
        },
        user_last_name: {
          regex: regexp.NAME_REGEXP,
          min: 3,
          max: 32,
        },
        user_email: {
          regex: regexp.EMAIL_REGEXP,
          min: 3,
          max: 64,
        },
        user_phone: {
          numeric: true,
          min: 3,
          max: 32,
        },
        user_description: {
          regex: regexp.TEXT_REGEXP,
          max: 128,
        },
        user_user_type: {},
      },
      editMode: true,
      userTypeDict: ["ADMIN", "MM", "4M"],
    };
  },
  computed: {
    user_id() {
      return this.$route.params.id;
    },
    getUserLoginById() {
      return this.$store.getters.getUserLoginById(this.user_id);
    },
    deleteDataModel() {
      return {
        user_id: this.model.user_id,
      };
    },
  },
  methods: {
    loadData() {},
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();

      if (isValid) {
        await this.$store.dispatch(SAVE_USER, this.model).catch(error => {
          let message = "";
          console.log(error.response);
          switch (error.response.data.code) {
            case 409:
              message = "Nazwy muszą być unikalne!";
              break;
            default:
              message = "Błąd zapisu danych!";
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning",
          });
        });

        if (this.$store.getters.getUsersModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
            type: "success",
          });

          this.$store.commit(USERS_INVALID); // oznacz nieaktualność danych
          this.$router.push({ name: "Użytkownicy" }); // przekieruj do listy
        }
      }
    },
    handleEdit() {
      this.$router.push("/admin/user_password/" + this.model.user_id);
    },
    showSwal() {
      var varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Czy jesteś pewien?",
          text: `Użytkownik zostanie deaktywowany`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Tak, usuń!",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then(res => {
          if (res.isConfirmed) {
            varThis.deleteData();
          }
        })
        .catch(() => {
          return;
        });
    },
    async deleteData() {
      await this.$store.dispatch(DELETE_USER, this.deleteDataModel).catch(error => {
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd usuwania danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getUsersDeletedRecords === true) {
        eventBus.$emit("notify", {
          message: "Rekord został usunięty",
          type: "success",
        });

        this.$store.commit(USERS_INVALID); // oznacz nieaktualność danych
        this.$router.push({ name: "Użytkownicy" }); // przekieruj do listy
      }
    },
  },
  created() {
    this.loadData();
    if (!!this.user_id) {
      let user = JSON.parse(JSON.stringify(this.$store.getters.getUsers[this.user_id]));
      if (user.user_user_type !== null) {
        let type_array = user.user_user_type.split(",");
        user.user_user_type = [];
        for (let i in type_array) {
          user.user_user_type.push(type_array[i]);
        }
      } else {
        user.user_user_type = [];
      }
      this.model = Object.assign(this.model, user);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  },
};
</script>
<style></style>
