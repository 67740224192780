<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-10">
        <h5 class="title">{{ templateID ? `Edycja szablonu ${templateID}` : "Nowy szablon" }}</h5>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <label style="margin-top: 0;">Administrator</label>
            <el-select v-model="model.company_id" size="small" filterable placeholder="Wybierz">
              <el-option v-for="cmp in company" :key="cmp.id" :label="cmp.name" :value="cmp.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label>Typ</label>
            <el-select v-model="model.response_type_id" size="small" filterable placeholder="Wybierz">
              <el-option v-for="type in responseTypes" :key="type.id" :label="'[' + type.id + '] ' + type.name" :value="type.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label>Szablon</label>
            <appCodeTextarea v-model="model.body" :height="400" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label>Opis</label>
            <el-input v-model="model.description"></el-input>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label>Domyślny</label>
            <el-switch v-model="model.is_default" :active-value="1" :inactive-value="0" active-color="#6bd098"></el-switch>
          </div>
        </div>
        <div class="row center">
          <p-button style="margin-right: 10px;" link @click="$router.push('/complaints/mailbox_templates')">Anuluj</p-button>
          <p-button type="success" @click="saveData()">Zapisz</p-button>
        </div>
      </div>
      <div class="corner">
        <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
          <div v-for="(bbcode, key) in bbcodes" :key="key" slot="content">
            <b>{{ key }}</b> - {{ bbcode.description }}
          </div>
          <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Tooltip, Input, Switch, Select, Option } from "element-ui";

// vuex actions
import { SAVE_RESPONSE_TEMPLATE, LOAD_RESPONSE_TEMPLATES, RESPONSE_TEMPLATES_INVALID } from "src/store/actions/complaints/response_templates";

// event bus
import { eventBus } from "src/main";

import CodeTextarea from "src/components/GeneralViews/CodeTextarea.vue";
import templates_bbcodes from "src/components/Utils4/complaints/templates_bbcodes";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    [Input.name]: Input,
    [Switch.name]: Switch,
    [Select.name]: Select,
    [Option.name]: Option,
    appCodeTextarea: CodeTextarea,
  },
  data() {
    return {
      loading: false,
      model: {
        id: null,
        response_type_id: null,
        company_id: null,
        body: "",
        description: "",
        is_default: false,
      },
      compareModel: {
        id: null,
        response_type_id: null,
        company_id: null,
        body: "",
        description: "",
        is_default: false,
      },
    };
  },
  computed: {
    responseTemplates() {
      return this.$store.getters.getResponseTemplates;
    },
    templateID() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
    company() {
      return this.$store.getters.getCompany;
    },
    bbcodes() {
      return templates_bbcodes;
    },
    responseTypes() {
      return this.$store.getters.getResponseTypes;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData(force) {
      this.loading = true;

      if (force) {
        this.$store.commit(RESPONSE_TEMPLATES_INVALID);
      }

      await this.$store.dispatch(LOAD_RESPONSE_TEMPLATES, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    async saveData() {
      if (JSON.stringify(this.model) !== JSON.stringify(this.compareModel)) {
        if (this.model.response_type_id === null) {
          eventBus.$emit("notify", {
            message: "Należy wybrać typ szablonu",
            type: "warning",
          });
          return;
        }
        if (this.model.body === "") {
          eventBus.$emit("notify", {
            message: "Treść szablonu nie może być pusta",
            type: "warning",
          });
          return;
        }
        this.loading = true;

        await this.$store.dispatch(SAVE_RESPONSE_TEMPLATE, this.model).catch(error => {
          console.log(error.response);
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getResponseTemplatesModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został zmodyfikowany",
            type: "success",
          });

          this.$store.commit(RESPONSE_TEMPLATES_INVALID); // oznacz nieaktualność danych
          this.$router.push("/complaints/mailbox_templates");
        }

        this.loading = false;
      }
    },
  },
  created() {
    if (this.templateID) {
      this.model = _.cloneDeep(this.responseTemplates[this.templateID]);

      this.compareModel = _.cloneDeep(this.model);
    } else {
      this.model.company_id = Number(this.$route.query.company);
    }
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy() {
    this.$store.commit(RESPONSE_TEMPLATES_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="scss" scoped>
.center {
  justify-content: center;
  align-content: center;
  display: flex;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

label {
  display: block;
  margin-top: 15px;
}

.corner {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-top: 15px;
}
</style>
