import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_SYSTEMS,
  SYSTEMS_LOADING,
  SYSTEMS_LOADING_SUCCESS,
  SYSTEMS_LOADING_ERROR,
  SYSTEMS_INVALID,
  SAVE_SYSTEM,
  SYSTEM_SAVING,
  SYSTEM_SAVING_SUCCESS,
  SYSTEM_SAVING_ERROR
} from 'src/store/actions/admin/systems'

const storeObjectConfig = {
  objectName: "Systems",
  getterName: "getSystems",
  loadActionName: LOAD_SYSTEMS,
  loadingMutationName: SYSTEMS_LOADING,
  loadingSuccessMutationName: SYSTEMS_LOADING_SUCCESS,
  loadingErrorMutationName: SYSTEMS_LOADING_ERROR,
  invalidMutationName: SYSTEMS_INVALID,
  saveActionName: SAVE_SYSTEM,
  savingMutationName: SYSTEM_SAVING,
  savingSuccessMutationName: SYSTEM_SAVING_SUCCESS,
  savingErrorMutationName: SYSTEM_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/systems",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/systems",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
