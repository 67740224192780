<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h5 class="card-title">Użytkownicy z reklamacji</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-3 mt-4 my-md-auto">
        <el-select v-model="typeDeleted" multiple placeholder="Po czym usunąć" class="float-lg-right">
          <el-option v-for="typeDelete in typesDeleted" :key="typeDelete.value" :label="typeDelete.label" :value="typeDelete.value"> </el-option>
        </el-select>
      </div>
      <div class="col-sm-12 text-sm-left col-md-3 col-lg-3 mt-4 my-md-auto">
        <flat-pickr class="flatPickr" v-model="pickrDate" :config="configDate" placeholder="Zakres Dat"></flat-pickr>
      </div>
      <div class="col-sm-3 mt-4 my-md-auto col-md-2">
        <p-button type="success" @click="loadData" class="float-md-right float-lg-left">Pokaż</p-button>
      </div>
    </div>
    <div v-if="listFiles.length > 0">
      <el-table :data="displayData" style="width: 100%" @sort-change="changeSort" :default-sort="{ prop: 'dodano', order: 'descending' }">
        <el-table-column prop="file" label="Plik" sortable="custom" :sort-orders="['ascending', 'descending']">
          <template v-slot="scope">
            <a target="_blank" :href="getFileUrl(scope.row.file)">{{ scope.row.file }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="dodano" label="Dodano" sortable="custom" :sort-orders="['ascending', 'descending']"></el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,prev, pager, next"
        :total="totalShow"
        :page-size="perPage"
        @current-change="setCurrentPage"
        @size-change="changePerPage"
        :page-sizes="[10, 20, 30, 40, 50]"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import { LOAD_USERS_FROM_COMPLAINTS, INVALID_USERS_FROM_COMPLAINTS } from "../../../store/actions/sales/user_from_complaints";
import {
  GET_USERS_FROM_COMPLAINTS_LIST,
  LOAD_USERS_FROM_COMPLAINTS_LIST,
  INVALID_USERS_FROM_COMPLAINTS_LIST,
} from "../../../store/actions/sales/user_from_complaints_list";

import { eventBus } from "../../../main";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Polish } from "flatpickr/dist/l10n/pl.js";
import { FIRST_DAY_OF_WEEK } from "src/config";
import { Select, Option, Pagination } from "element-ui";
import { mapState } from "vuex";

export default {
  components: {
    flatPickr,
    [Select.name]: Select,
    [Option.name]: Option,
    [Pagination.name]: Pagination,
  },
  computed: {
    date() {
      let result = [];
      if (this.pickrDate) {
        const newDate = this.pickrDate.split(" do ");
        if (newDate[0]) result.push(newDate[0]);
        if (newDate[1]) result.push(newDate[1]);
      }
      return result;
    },

    filesList() {
      let result = [];
      if (this.$store.state.user_from_complaints_list.loading_status === "success") {
        result = this.$store.getters[GET_USERS_FROM_COMPLAINTS_LIST].listFiles;
        let resultTmp = result.map(function (e, i) {
          let mobj = Object.keys(e).map((k) => {
            let n = {};
            n[k] = e[k];
            return n;
          });
          let nobj = {};

          for (let kk in mobj) {
            nobj = Object.assign(nobj, mobj[kk]);
          }
          return nobj;
        });

        resultTmp.sort((a, b) => (a.dodano.toUpperCase() < b.dodano.toUpperCase() ? 1 : -1));

        result = resultTmp;
      }
      return result;
    },
    listFiles: {
      get() {
        if (this.listFilesPresent.length === 0 || this.$store.state.user_from_complaints_list.loading_status === "success") {
          this.listFilesPresent = this.filesList;
        }
        return this.listFilesPresent;
      },
      set(newValue) {
        this.listFilesPresent = newValue;
      },
    },
    totalShow() {
      return this.listFiles.length;
    },
    displayData() {
      return this.listFiles.slice(this.perPage * this.currentPage - this.perPage, this.perPage * this.currentPage);
    },
  },
  data() {
    return {
      listFilesPresent: [],
      currentPage: 1,
      perPage: 10,
      loading: false,
      pickrDate: null,
      typeDeleted: [],
      typesDeleted: [
        { label: "UserDataId", value: "user_data_id" },
        { label: "Email", value: "EMAIL" },
      ],
      configDate: {
        locale: {
          ...Polish,
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
        mode: "range",
      },
    };
  },
  methods: {
    changeSort(sortData) {
      this.sortByfile(sortData.prop, sortData.order);
    },
    sortByfile(key, order) {
      let result = [];
      if (order === "ascending") {
        result = this.listFiles.sort((a, b) => (a[key].toUpperCase() < b[key].toUpperCase() ? 1 : -1));
      } else {
        result = this.listFiles.sort((a, b) => (a[key].toUpperCase() > b[key].toUpperCase() ? 1 : -1));
      }
      this.listFiles = result;
    },
    getFileUrl(file) {
      let pathUrl = this.$store.getters[GET_USERS_FROM_COMPLAINTS_LIST].pathToUrl;
      const userToken = this.$store.state.auth.token;
      return pathUrl + "?token=" + userToken + "&name=" + file;
    },
    setCurrentPage(val) {
      this.currentPage = val;
    },
    changePerPage(val) {
      this.perPage = val;
    },
    async initLoad() {
      this.loading = true;

      this.$store.commit(INVALID_USERS_FROM_COMPLAINTS_LIST);

      this.$store.dispatch(LOAD_USERS_FROM_COMPLAINTS_LIST, {}).catch((error) => {
        let err_msg = JSON.parse(error.response.data.description);
        let msg = "";
        if ("missing" in err_msg) {
          msg = "Brak parametru: " + err_msg.missing;
        }
        eventBus.$emit("notify", {
          message: msg,
          type: "warning",
        });
      });

      this.loading = false;
    },

    async loadData() {
      if (!this.date[0] || !this.date[1]) {
        eventBus.$emit("notify", {
          message: "Należy wybrać zakres dat!",
          type: "warning",
        });
        return;
      }

      if (!this.typeDeleted.length) {
        eventBus.$emit("notify", {
          message: "Należy wybrać po czym usunąć!",
          type: "warning",
        });
        return;
      }

      const filters = {
        date_from: this.date[0],
        date_to: this.date[1],
        types_deleted: this.typeDeleted,
      };

      this.$store.commit(INVALID_USERS_FROM_COMPLAINTS);

      this.$store
        .dispatch(LOAD_USERS_FROM_COMPLAINTS, filters)
        .then(() => {
          let fileName = null;
          let message = null;
          if (this.$store.state.user_from_complaints.loading_status === "success") {
            fileName = this.$store.getters.GET_USERS_FROM_COMPLAINTS.fileName;
            message = this.$store.getters.GET_USERS_FROM_COMPLAINTS.descryption;
          }
          if (fileName) {
            message = "Dodano plik: " + fileName;
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning",
          });
          this.initLoad(message);
        })
        .catch((error) => {
          let err_msg = JSON.parse(error.response.data.description);
          let msg = "";
          if ("missing" in err_msg) {
            msg = "Brak parametru: " + err_msg.missing;
          }
          eventBus.$emit("notify", {
            message: msg,
            type: "warning",
          });
        });

      this.loading = false;
    },
  },
  created() {
    eventBus.$on("forceReload", () => {
      //this.loadData();
      this.initLoad();
    });
    this.initLoad();
  },
  beforeDestroy: function () {
    this.$store.commit(INVALID_USERS_FROM_COMPLAINTS);
    this.$store.commit(INVALID_USERS_FROM_COMPLAINTS_LIST);
    eventBus.$off("forceReload");
  },
};
</script>
<style>
.flatPickr {
  width: 200px;
}
</style>
