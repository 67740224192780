<template>
  <div>
    <el-table :data="tableData" size="mini" style="width: 100%" >
      <el-table-column v-if="!isBannersSum" label="BANNER" min-width="200" property="" align="right">
        <template v-slot="props">
          <el-tooltip class="item" effect="light" :content="props.row.BannerName" placement="top" :open-delay="200">
            <span :style="props.row.sellClients.length>0 ? 'cursor: pointer;' : ''" @click="bannerClick(props.row.sellClients)">[{{props.row.BannerID}}] {{props.row.BannerName}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column min-width="100" v-if="!isBannersSum" label="Typ" property="Preferences" align="right">

      </el-table-column>
      <el-table-column min-width="100" v-if="!isBannersSum" label="Wydawca" property="" align="right">
        <template v-slot="props">
          [{{props.row.MediaHouseID}}] {{mediaHouse.find((media)=>media.ID===props.row.MediaHouseID).Name}}
        </template>
      </el-table-column>
      <el-table-column label="Otwarcia" width="105" prop="display" align="right"></el-table-column>
      <el-table-column label="Klik" min-width="60" prop="click" align="right"></el-table-column>
      <el-table-column label="Lead f1" min-width="90" prop="LeadF1" align="right"></el-table-column>
      <el-table-column label="SPRZ/F1" min-width="80" prop="S/F1" align="right"></el-table-column>
      <el-table-column label="Przychód" min-width="110" prop="income" align="right"></el-table-column>
      <el-table-column label="Koszt" min-width="70" prop="cost" align="right"></el-table-column>
      <el-table-column label="ZYSK/STRATA" min-width="120" prop="zysk_strata" align="right"></el-table-column>
      <el-table-column v-if="showRoi" label="ROI HOT%" prop="roi" min-width="130" align="right">
        <template v-slot="props">
          <div class="progress" style="height: 15px">
            <div
              :class="
                props.row.roi < 0
                  ? 'progress-bar bg-danger'
                  : 'progress-bar bg-success'
              "
              :style="'width: ' + props.row.roi + '%;'"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span class="percentageValue">{{
                  props.row.roi + "%"
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="showRoi" label="ROI%" prop="roiHot" min-width="130" align="right">
        <template v-slot="props">
          <div class="progress" style="height: 15px">
            <div
              :class="
                props.row.roiHot < 0
                  ? 'progress-bar bg-danger'
                  : 'progress-bar bg-success'
              "
              :style="'width: ' + props.row.roiHot + '%;'"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span class="percentageValue">{{
                  props.row.roiHot + "%"
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

import "moment/locale/pl";

import BanersEfficiencyTableModal from "@/components/Utils4/sales/views/BanersEfficiencyTableModal.vue";

import {Tooltip} from "element-ui";

export default {
  components: {
    [Tooltip.name]: Tooltip,
  },
  props: ["tableData","isBannersSum","showRoi","mediaHouse"],
  methods: {
    getRatio(a, b) {
      if ((Number.isInteger(a) === false || Number.isInteger(b) === false) || (a === 0 || b === null)) {
        return "-";
      }
      return Math.round((a / b) * 100) + "%";
    },
    bannerClick(sellClients){
      if(sellClients.length>0){
      this.$modal.show(
        BanersEfficiencyTableModal,
        {
          sellClients:sellClients,
        },
        {
          name: "BanersEfficiencyTableModal",
          draggable: false,
          scrollable: true,
          width: "600px",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );}
    }
  },
};
</script>
<style scoped>
.percentageValue {
  color: #383838;
  font-weight: bold;
}
</style>

<style>
.mailing-efficiency__hide-expand :first-child {
  visibility: hidden !important;
}
</style>
