const bbcodes = {
  "{MS_B}": {
    condition: true,
    param: "Sex",
    description: "Płeć w bierniku: Pana/Panią",
    results: {
      M: "Pana",
      K: "Panią",
    },
  },
  "{MS_D}": {
    condition: true,
    param: "Sex",
    description: "Płeć w dopełniaczu: Pana/Pani",
    results: {
      M: "Pana",
      K: "Pani",
    },
  },
  "{MS}": {
    condition: true,
    param: "Sex",
    description: "Płeć: Pan/Pani",
    results: {
      M: "Pan",
      K: "Pani",
    },
  },
  "{FULL_KEY_N}": {
    condition: true,
    param: "key",
    description: "Klucz: adresem email/numerem telefonu",
    results: {
      email: "adresem email",
      phone: "numerem telefonu",
    },
  },
  "{REGISTRATION_DATE}": {
    condition: false,
    description: "Data rejestracji",
    param: "RegistrationDate",
  },
  "{REGISTRATION_TIME}": {
    condition: false,
    description: "Czas rejestracji",
    param: "RegistrationTime",
  },
  "{GENDER_SUFFIX}": {
    condition: true,
    param: "Sex",
    description: "Suffix płci: ''/'a'",
    results: {
      M: "",
      K: "a",
    },
  },
  "{SERVICE_URL}": {
    condition: false,
    description: "Adres serwisu",
    param: "HostName",
  },
  "{SERVICE}": {
    condition: false,
    description: "Nazwa serwisu",
    param: "ProjectName",
  },
  "{CONSENTS}": {
    condition: false,
    description: "Wyrażone zgody",
    param: "Consents",
  },
  "{OBLIGATION}": {
    condition: false,
    description: "Obowiązek informacyjny",
    param: "Obligation",
  },
  "{EMAIL}": {
    condition: false,
    description: "Email",
    param: "Email",
  },
  "{COMPANY}": {
    condition: false,
    description: "Firmy do których rekord został sprzedany",
    param: "Company",
  },
  "{IP}": {
    condition: false,
    description: "Adres IP użyty podczas rejestracji",
    param: "IpAddress",
  },
};

export default bbcodes;
