<template>
  <div class="modal-wrapper" :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row header-row">
      <div class="col-sm-11">
        <h5 class="title">Wyświetlenia pytań</h5>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer" aria-hidden="true"></i>
    </div>

    <div class="card">
      <div class="card-body row">
        <el-table
          :data="tableData"
          style="width: 100%"
          :span-method="objectSpanMethod"
          @cell-click="cellClick"
          @cell-dblclick="cellDoubleClick"
          :row-class-name="tableRowClassName"
          :header-cell-style="headerStyle"
        >
          <el-table-column label="ID" property="question_id" width="100" align="center">
            <template v-slot="props">
              <div v-if="props.row.mode === 'question'">
                {{ props.row.question_id }}
              </div>
              <div v-else-if="props.row.mode === 'project'">
                {{ props.row.project_id }}
              </div>
              <div v-else-if="props.row.mode === 'answer'">
                {{ props.row.answer_id }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Typ" property="question_type" width="120" align="center">
            <template v-slot="props">
              {{ props.row.project_id === "ALL_PROJECTS" ? props.row.question_type : "" }}
            </template>
          </el-table-column>
          <el-table-column label="Treść" property="question_content" align="left" min-width="200">
            <template v-slot="props">
              <div v-if="props.row.mode === 'question'" style="cursor: pointer; word-break: keep-all">
                {{ props.row.question_content }}
              </div>
              <div v-else-if="props.row.mode === 'project'" style="padding-left: 25px; cursor: pointer; word-break: keep-all">
                {{ props.row.project_name }}
              </div>
              <div v-else-if="props.row.mode === 'version'" style="padding-left: 50px; word-break: keep-all">
                {{ props.row.question_content }}
              </div>
              <div
                v-else-if="props.row.mode === 'answer' && props.row.question_content === null"
                style="padding-left: 80px; word-break: keep-all; cursor: pointer"
                @click="showAnswerModal(props.row)"
              >
                pokaż odpowiedzi
              </div>
              <div v-else-if="props.row.mode === 'answer'" style="padding-left: 80px; word-break: keep-all">
                {{ props.row.question_content }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="" property="answer" align="center" width="120">
            <template slot="header"> Odpowiedzi </template>
            <template v-slot="props">
              <div v-if="answer_stats[props.row.question_id]">
                <div
                  style="cursor: pointer"
                  v-if="
                    props.row.mode !== 'answer' && !answerBoolObject[props.row.question_id + '_' + props.row.project_id + '_' + props.row.version]
                  "
                >
                  <i class="fa fa-arrow-down" aria-hidden="true"></i>
                </div>
                <div
                  style="cursor: pointer"
                  v-if="props.row.mode !== 'answer' && answerBoolObject[props.row.question_id + '_' + props.row.project_id + '_' + props.row.version]"
                >
                  <i class="fa fa-arrow-up" aria-hidden="true"></i>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Wyśw" property="DisplayCounter" align="right" width="120"></el-table-column>
          <el-table-column label="Wyśw UU" property="DisplayCounterUU" align="right" width="120"></el-table-column>
          <el-table-column label="Odp" property="AnswerCounter" align="right" width="120"></el-table-column>
          <el-table-column label="Odp UU" property="AnswerCounterUU" align="right" width="120"></el-table-column>
          <el-table-column label="Odp [%]" property="AnswerPercentage" align="right" width="120"></el-table-column>
          <el-table-column label="Odp sprz" property="ClientCounter" align="right" width="120"></el-table-column>
          <el-table-column label="Odp sprz [%]" property="ClientPercentage" align="right" width="120"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

import { Table, TableColumn, DatePicker, Select, Option, OptionGroup, Collapse, CollapseItem } from "element-ui";

// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

import * as priv from "src/privileges.js";

import { LOAD_DISPLAY_STATS_DETAILS, DISPLAY_STATS_DETAILS_INVALID } from "src/store/actions/stats/display_stats_details";
import { LOAD_QUESTION_ANSWER_STATS_DETAILS, QUESTION_ANSWER_STATS_DETAILS_INVALID } from "src/store/actions/stats/question_answer_stats_details";

import DisplayStatsAnswerModal from "src/components/Utils4/stats/DisplayStatsAnswerModal.vue";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [CollapseItem.name]: CollapseItem,
    [Collapse.name]: Collapse,
  },
  data() {
    return {
      loading: false,
      displayBoolObject: {},
      answerBoolObject: {},
      referenceCounter: 0,
      counter: 0,
    };
  },
  props: ["filters"],
  computed: {
    display_stats() {
      return this.$store.getters.getDisplayStatsDetails;
    },
    answer_stats() {
      return this.$store.getters.getQuestionAnswerStatsDetails;
    },
    tableData() {
      let display_stats = this.display_stats;
      const display_stats_array = Object.values(display_stats);
      display_stats_array.sort((a, b) => b.priority - a.priority);
      let answer_stats = this.answer_stats;
      let unique_answer_stats = this.unique_answer_stats;
      let tableData = [];
      let displayBoolObject = this.displayBoolObject;
      let counter = this.counter;
      let referenceCounter = this.referenceCounter;
      if (counter !== referenceCounter) {
        // licznik wymuszający przeliczenie tabeli
        for (let i in display_stats_array) {
          // iteracja wyświetleń  na poziomie pytania
          let qid = display_stats_array[i].question_id;
          tableData.push({
            // dodanie poziomu pytania
            question_id: qid,
            question_type: display_stats[qid].question_type,
            question_content: display_stats[qid].question_content,
            project_id: "ALL_PROJECTS",
            project_name: "Wszystkie projekty",
            version: "ALL_VERSION",
            answer_id: "",
            DisplayCounter: display_stats[qid].ALL_PROJECTS.ALL_VERSION.DisplayCounter,
            DisplayCounterUU: display_stats[qid].ALL_PROJECTS.ALL_VERSION.DisplayCounterUU,
            AnswerCounter: answer_stats[qid] ? answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounter : 0,
            AnswerCounterUU: answer_stats[qid] ? answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounterUU : 0,
            AnswerPercentageValue: answer_stats[qid]
              ? parseFloat(
                  (
                    (answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounterUU /
                      display_stats[qid].ALL_PROJECTS.ALL_VERSION.DisplayCounterUU) *
                    100
                  ).toFixed(2),
                )
              : 0, // AnswerCounterUU/DisplayCounterUU*100 [%]
            AnswerPercentage: answer_stats[qid]
              ? (
                  (answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounterUU /
                    display_stats[qid].ALL_PROJECTS.ALL_VERSION.DisplayCounterUU) *
                  100
                ).toFixed(2) + "%"
              : "0.00%", // AnswerCounterUU/DisplayCounterUU*100 [%]
            ClientCounter: answer_stats[qid] ? answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.ClientCounter : 0,
            ClientPercentageValue: answer_stats[qid]
              ? parseFloat(
                  (
                    (answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.ClientCounter /
                      answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounter) *
                    100
                  ).toFixed(2),
                )
              : 0,
            ClientPercentage: answer_stats[qid]
              ? (
                  (answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.ClientCounter /
                    answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounter) *
                  100
                ).toFixed(2) + "%"
              : "0.00%", // ClientCounter/AnswerCounter*100 [%]
            mode: "question",
          });

          if (answer_stats[qid]) {
            // sprawdzenie czy isnieją statystyki odpowiedzi dla tego pytania
            if (this.answerBoolObject[qid + "_ALL_PROJECTS_ALL_VERSION"]) {
              // sprawdzenie czy są wyświetlane odpowiedzi na poziomie pytania
              for (let ans in answer_stats[qid].ALL_PROJECTS.ALL_VERSION) {
                // iteracja po odpowiedziach na poziomie pytania
                if (ans !== "ALL_ANSWER") {
                  tableData.push({
                    // dodanie odpowiedzi na poziomie pytania
                    question_id: qid,
                    question_type: "",
                    question_content: answer_stats[qid].ALL_PROJECTS.ALL_VERSION[ans].answer_content,
                    project_id: "ALL_PROJECTS",
                    project_name: "Wszystkie projekty",
                    version: "ALL_VERSION",
                    answer_id: ans,
                    DisplayCounter: "",
                    DisplayCounterUU: "",
                    AnswerCounter: answer_stats[qid] ? answer_stats[qid].ALL_PROJECTS.ALL_VERSION[ans].AnswerCounter : 0,
                    AnswerCounterUU: answer_stats[qid] ? answer_stats[qid].ALL_PROJECTS.ALL_VERSION[ans].AnswerCounterUU : 0,
                    AnswerPercentageValue: answer_stats[qid]
                      ? parseFloat(
                          (
                            (answer_stats[qid].ALL_PROJECTS.ALL_VERSION[ans].AnswerCounterUU /
                              answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounterUU) *
                            100
                          ).toFixed(2),
                        )
                      : 0, // AnswerCounterUU/AnswerCounterUU(od pytania)*100 [%]
                    AnswerPercentage: answer_stats[qid]
                      ? (
                          (answer_stats[qid].ALL_PROJECTS.ALL_VERSION[ans].AnswerCounterUU /
                            answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounterUU) *
                          100
                        ).toFixed(2) + "%"
                      : "0.00%", // AnswerCounterUU/AnswerCounterUU(od pytania)*100 [%]
                    ClientCounter: "",
                    ClientPercentageValue: 0,
                    ClientPercentage: "",
                    mode: "answer",
                  });
                }
              }
            }
          }

          for (let proj in display_stats[qid].projects) {
            // iteracja wyświetleń po poziomie projektu
            if (displayBoolObject[qid + "_ALL_PROJECTS"]) {
              // sprawdzenie czy są wyświetlanie projekty
              tableData.push({
                // dodanie poziomu projektów
                question_id: qid,
                question_type: display_stats[qid].question_type,
                question_content: proj,
                project_id: proj,
                project_name: display_stats[qid].projects[proj].ALL_VERSION.project_name,
                version: "ALL_VERSION",
                answer_id: "",
                DisplayCounter: display_stats[qid].projects[proj].ALL_VERSION.DisplayCounter,
                DisplayCounterUU: display_stats[qid].projects[proj].ALL_VERSION.DisplayCounterUU,
                AnswerCounter:
                  answer_stats[qid] && answer_stats[qid].projects[proj] ? answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.AnswerCounter : 0,
                AnswerCounterUU:
                  answer_stats[qid] && answer_stats[qid].projects[proj] ? answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.AnswerCounterUU : 0,
                AnswerPercentageValue:
                  answer_stats[qid] && answer_stats[qid].projects[proj]
                    ? parseFloat(
                        (
                          (answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.AnswerCounterUU /
                            display_stats[qid].projects[proj].ALL_VERSION.DisplayCounterUU) *
                          100
                        ).toFixed(2),
                      )
                    : 0, // AnswerCounterUU/DisplayCounterUU*100 [%]
                AnswerPercentage:
                  answer_stats[qid] && answer_stats[qid].projects[proj]
                    ? (
                        (answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.AnswerCounterUU /
                          display_stats[qid].projects[proj].ALL_VERSION.DisplayCounterUU) *
                        100
                      ).toFixed(2) + "%"
                    : "0.00%", // AnswerCounterUU/DisplayCounterUU*100 [%]
                ClientCounter:
                  answer_stats[qid] && answer_stats[qid].projects[proj] ? answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.ClientCounter : 0,
                ClientPercentageValue:
                  answer_stats[qid] && answer_stats[qid].projects[proj]
                    ? parseFloat(
                        (
                          (answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.ClientCounter /
                            answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.AnswerCounter) *
                          100
                        ).toFixed(2),
                      )
                    : 0,
                ClientPercentage:
                  answer_stats[qid] && answer_stats[qid].projects[proj]
                    ? (
                        (answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.ClientCounter /
                          answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.AnswerCounter) *
                        100
                      ).toFixed(2) + "%"
                    : "0.00%", // ClientCounter/AnswerCounter*100 [%]
                mode: "project",
              });

              if (answer_stats[qid]) {
                // sprawdzenie czy isnieją statystyki odpowiedzi dla tego pytania
                if (this.answerBoolObject[qid + "_" + proj + "_ALL_VERSION"] && answer_stats[qid].projects.hasOwnProperty(proj)) {
                  // sprawdzenie czy są wyświetlane odpowiedzi na poziomie projektu
                  for (let ans in answer_stats[qid].projects[proj].ALL_VERSION) {
                    // iteracja po odpowiedziach na poziomie projektu
                    if (ans !== "ALL_ANSWER") {
                      tableData.push({
                        // dodanie odpowiedzi na poziomie projektu
                        question_id: qid,
                        question_type: "",
                        question_content: answer_stats[qid].projects[proj].ALL_VERSION[ans].answer_content,
                        project_id: proj,
                        project_name: display_stats[qid].projects[proj].ALL_VERSION.project_name,
                        version: "ALL_VERSION",
                        answer_id: ans,
                        DisplayCounter: "",
                        DisplayCounterUU: "",
                        AnswerCounter:
                          answer_stats[qid] && answer_stats[qid].projects[proj] ? answer_stats[qid].projects[proj].ALL_VERSION[ans].AnswerCounter : 0,
                        AnswerCounterUU:
                          answer_stats[qid] && answer_stats[qid].projects[proj]
                            ? answer_stats[qid].projects[proj].ALL_VERSION[ans].AnswerCounterUU
                            : 0,
                        AnswerPercentageValue:
                          answer_stats[qid] && answer_stats[qid].projects[proj]
                            ? parseFloat(
                                (
                                  (answer_stats[qid].projects[proj].ALL_VERSION[ans].AnswerCounterUU /
                                    answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.AnswerCounterUU) *
                                  100
                                ).toFixed(2),
                              )
                            : 0, // AnswerCounterUU/AnswerCounterUU(od projektu)*100 [%]
                        AnswerPercentage:
                          answer_stats[qid] && answer_stats[qid].projects[proj]
                            ? (
                                (answer_stats[qid].projects[proj].ALL_VERSION[ans].AnswerCounterUU /
                                  answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.AnswerCounterUU) *
                                100
                              ).toFixed(2) + "%"
                            : "0.00%", // AnswerCounterUU/AnswerCounterUU(od projektu)*100 [%]
                        ClientCounter: "",
                        ClientPercentageValue: 0,
                        ClientPercentage: "",
                        mode: "answer",
                      });
                    }
                  }
                }
              }
            }
            for (let ver in display_stats[qid].projects[proj]) {
              // iteracja wyświetleń po poziomie wersji
              if (ver !== "ALL_VERSION") {
                if (displayBoolObject[qid + "_" + proj]) {
                  // sprawdzenie czy są wyświetlane wersje
                  tableData.push({
                    // dodanie poziomu wersji
                    question_id: qid,
                    question_type: display_stats[qid].question_type,
                    question_content: ver,
                    project_id: proj,
                    project_name: display_stats[qid].projects[proj].ALL_VERSION.project_name,
                    version: ver,
                    answer_id: "",
                    DisplayCounter: display_stats[qid].projects[proj][ver].DisplayCounter,
                    DisplayCounterUU: display_stats[qid].projects[proj][ver].DisplayCounterUU,
                    AnswerCounter:
                      answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                        ? answer_stats[qid].projects[proj][ver].ALL_ANSWER.AnswerCounter
                        : 0,
                    AnswerCounterUU:
                      answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                        ? answer_stats[qid].projects[proj][ver].ALL_ANSWER.AnswerCounterUU
                        : 0,
                    AnswerPercentageValue:
                      answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                        ? parseFloat(
                            (
                              (answer_stats[qid].projects[proj][ver].ALL_ANSWER.AnswerCounterUU /
                                display_stats[qid].projects[proj][ver].DisplayCounterUU) *
                              100
                            ).toFixed(2),
                          )
                        : 0, // AnswerCounterUU/DisplayCounterUU*100 [%]
                    AnswerPercentage:
                      answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                        ? (
                            (answer_stats[qid].projects[proj][ver].ALL_ANSWER.AnswerCounterUU /
                              display_stats[qid].projects[proj][ver].DisplayCounterUU) *
                            100
                          ).toFixed(2) + "%"
                        : "0.00%", // AnswerCounterUU/DisplayCounterUU*100 [%]
                    ClientCounter:
                      answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                        ? answer_stats[qid].projects[proj][ver].ALL_ANSWER.ClientCounter
                        : 0,
                    ClientPercentageValue:
                      answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                        ? parseFloat(
                            (
                              (answer_stats[qid].projects[proj][ver].ALL_ANSWER.ClientCounter /
                                answer_stats[qid].projects[proj][ver].ALL_ANSWER.AnswerCounter) *
                              100
                            ).toFixed(2),
                          )
                        : 0,
                    ClientPercentage:
                      answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                        ? (
                            (answer_stats[qid].projects[proj][ver].ALL_ANSWER.ClientCounter /
                              answer_stats[qid].projects[proj][ver].ALL_ANSWER.AnswerCounter) *
                            100
                          ).toFixed(2) + "%"
                        : "0.00%", // ClientCounter/AnswerCounter*100 [%]
                    mode: "version",
                  });

                  if (answer_stats[qid]) {
                    // sprawdzenie czy isnieją statystyki odpowiedzi dla tego pytania
                    if (this.answerBoolObject[qid + "_" + proj + "_" + ver] && answer_stats[qid].projects.hasOwnProperty(proj)) {
                      // sprawdzenie czy są wyświetlane odpowiedzi na poziomie wersji
                      for (let ans in answer_stats[qid].projects[proj][ver]) {
                        // iteracja po odpowiedziach na poziomie wersji
                        if (ans !== "ALL_ANSWER") {
                          tableData.push({
                            // dodanie odpowiedzi na poziomie wersji
                            question_id: qid,
                            question_type: "",
                            question_content: answer_stats[qid].projects[proj][ver][ans].answer_content,
                            project_id: proj,
                            project_name: display_stats[qid].projects[proj].ALL_VERSION.project_name,
                            version: ver,
                            DisplayCounter: "",
                            DisplayCounterUU: "",
                            answer_id: ans,
                            AnswerCounter:
                              answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                                ? answer_stats[qid].projects[proj][ver][ans].AnswerCounter
                                : 0,
                            AnswerCounterUU:
                              answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                                ? answer_stats[qid].projects[proj][ver][ans].AnswerCounterUU
                                : 0,
                            AnswerPercentageValue:
                              answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                                ? parseFloat(
                                    (
                                      (answer_stats[qid].projects[proj][ver][ans].AnswerCounterUU /
                                        answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.AnswerCounterUU) *
                                      100
                                    ).toFixed(2),
                                  )
                                : 0, // AnswerCounterUU/AnswerCounterUU(od wersji)*100 [%]
                            AnswerPercentage:
                              answer_stats[qid] && answer_stats[qid].projects[proj] && answer_stats[qid].projects[proj][ver]
                                ? (
                                    (answer_stats[qid].projects[proj][ver][ans].AnswerCounterUU /
                                      answer_stats[qid].projects[proj].ALL_VERSION.ALL_ANSWER.AnswerCounterUU) *
                                    100
                                  ).toFixed(2) + "%"
                                : "0.00%", // AnswerCounterUU/AnswerCounterUU(od wersji)*100 [%]
                            ClientCounter: "",
                            ClientPercentageValue: 0,
                            ClientPercentage: "",
                            mode: "answer",
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        referenceCounter++;
      }
      return tableData;
    },
  },
  methods: {
    async loadStats(filters) {
      this.loading = true;

      this.$store.commit(QUESTION_ANSWER_STATS_DETAILS_INVALID);
      this.$store.commit(DISPLAY_STATS_DETAILS_INVALID);

      filters.is_detailed = true;

      this.$store.dispatch(LOAD_QUESTION_ANSWER_STATS_DETAILS, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_DISPLAY_STATS_DETAILS, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
      this.createBoolObjects();
    },
    headerStyle(obj) {
      return "font-size: 12px;";
    },
    createBoolObjects() {
      let data = this.display_stats;
      let displayBoolObject = this.displayBoolObject;
      let answerBoolObject = this.answerBoolObject;
      for (let qid in data) {
        displayBoolObject[qid + "_ALL_PROJECTS"] = false;
        answerBoolObject[qid + "_ALL_PROJECTS_ALL_VERSION"] = false;
        for (let proj in data[qid].projects) {
          displayBoolObject[qid + "_" + proj] = false;
          displayBoolObject[qid + "_" + proj + "_ALL_VERSION"] = false;
          for (let ver in data[qid][proj]) {
            if (ver !== "ALL_VERSION") {
              displayBoolObject[qid + "_" + proj + "_" + ver] = false;
            }
          }
        }
      }
      this.counter++;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.mode === "project") {
        return "table-info";
      } else if (row.mode === "version") {
        return "table-warning";
      } else if (row.mode === "answer") {
        return "table-success";
      }
      return "other-row";
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    cellClick(row, column) {
      let columnName = column.property;
      let qid = row.question_id;
      let pid = row.project_id;
      let ver = row.version;
      if (columnName === "question_content" && row.mode !== "version" && row.mode !== "answer") {
        this.displayBoolObject[qid + "_" + pid] = !this.displayBoolObject[qid + "_" + pid];
        this.counter++;
      }
      if (columnName === "answer" && this.answer_stats[row.question_id]) {
        this.answerBoolObject[qid + "_" + pid + "_" + ver] = !this.answerBoolObject[qid + "_" + pid + "_" + ver];
        this.counter++;
      }
    },
    cellDoubleClick(row, column) {
      let columnName = column.property;
      let qid = row.question_id;
      let pid = row.project_id;
      if (columnName === "question_content") {
        let prefix = qid + "_";
        let regex = new RegExp(prefix + ".+", "g");
        for (let key in this.displayBoolObject) {
          if (key.search(regex) !== -1) {
            this.displayBoolObject[key] = false;
          }
        }
        for (let key in this.answerBoolObject) {
          if (key.search(regex) !== -1) {
            this.answerBoolObject[key] = false;
          }
        }
        this.counter++;
      } else if (columnName === "answer") {
        let prefix = qid + "_";
        let regex = new RegExp(prefix + ".+", "g");
        for (let key in this.answerBoolObject) {
          if (key.search(regex) !== -1) {
            this.answerBoolObject[key] = false;
          }
        }
        this.counter++;
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (row.mode === "answer") {
        if (columnIndex === 2) {
          return {
            rowspan: 1,
            colspan: 4,
          };
        } else if (columnIndex === 3 || columnIndex === 4 || columnIndex === 5) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    showAnswerModal(row) {
      let filters = Object.assign({}, this.filters);
      filters.question = row.question_id;

      this.$modal.show(
        DisplayStatsAnswerModal,
        {
          filters: filters,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(DisplayStatsAnswerModal);
              },
            },
          ],
        },
        {
          name: "DisplayStatsAnswerModal",
          draggable: false,
          scrollable: true,
          width: "90%",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
  },
  created() {
    this.loadStats(this.filters);
    eventBus.$on("forceReload", () => {
      // this.loadData(true);
      // this.loadStats(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped>
.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}
.card {
  margin: 0;
  display: flex;
}
.header-row {
  padding-top: 15px;
  padding-left: 15px;
}
.modal-wrapper {
  position: relative;
}
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
