/* eslint-disable promise/param-names */
import axios from "axios";

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from '../actions/auth'

import {
  PROFILE_REQUEST, LOAD_PROFILE
} from 'src/store/actions/admin/profile'

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
  hasLoadedOnce: false
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  getToken: state => state.token
}

const actions = {
  // user authorization
  [AUTH_REQUEST]: ({
    commit,
    dispatch
  }, user) => {
    return new Promise((resolve, reject) => {
      // update processing status
      commit(AUTH_REQUEST)

      // call API
      axios.post(BASE_API + '/login/login/', JSON.stringify(user))
        .then(resp => {

          // save token to local storage
          localStorage.setItem('user-token', resp.data.token)

          // update processing status
          commit(AUTH_SUCCESS, resp)

          // load user profile data
          dispatch(LOAD_PROFILE)

          resolve(resp)
        })
        .catch(error => {
          console.log(error)

          // update processing status
          commit(AUTH_ERROR, error)

          // remove token from local storage
          localStorage.removeItem('user-token')

          reject(error)
        });
    })
  },
  [AUTH_LOGOUT]: ({
    commit,
    dispatch
  }) => {
    return new Promise((resolve, reject) => {
      // update processing status
      commit(AUTH_LOGOUT)

      // remove token from local storage
      localStorage.removeItem('user-token')

      resolve()
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.token = resp.data.token
    state.hasLoadedOnce = true
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
