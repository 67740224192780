export const LOAD_MAILING_STATS_HOUR = "LOAD_MAILING_STATS_HOUR";
export const MAILING_STATS_HOUR_LOADING = "MAILING_STATS_HOUR_LOADING";
export const MAILING_STATS_HOUR_LOADING_SUCCESS = "MAILING_STATS_HOUR_LOADING_SUCCESS";
export const MAILING_STATS_HOUR_LOADING_ERROR = "MAILING_STATS_HOUR_LOADING_ERROR";
export const MAILING_STATS_HOUR_INVALID = "MAILING_STATS_HOUR_INVALID";

export const SAVE_MAILING_STATS_HOUR = "SAVE_MAILING_STATS_HOUR";
export const MAILING_STATS_HOUR_SAVING = "MAILING_STATS_HOUR_SAVING";
export const MAILING_STATS_HOUR_SAVING_SUCCESS = "MAILING_STATS_HOUR_SAVING_SUCCESS";
export const MAILING_STATS_HOUR_SAVING_ERROR = "MAILING_STATS_HOUR_SAVING_ERROR";
