<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h5 class="title">Słownik zgód</h5>
      </div>
      <div class="col-sm-12 col-md-4 text-md-right">
        <el-select v-model="active_owner_id" size="small" filterable clearable placeholder="Filtruj">
          <el-option v-for="owner in consentsOwners" :key="owner.id" :label="owner.name" :value="owner.id"></el-option>
        </el-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="queriedData" style="width: 100%; font-size: 12px;" :row-style="rowStyle">
              <el-table-column label="ID" width="80" align="center" property="id">
                <template v-slot="props">
                  <div>{{ props.row.id }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Typ" width="80" align="center" property="typ_zgody">
                <template v-slot="props">
                  <div>{{ props.row.typ_zgody ? props.row.typ_zgody : "ob" }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Administrator" width="160" align="center" property="typ_zgody">
                <template v-slot="props">
                  <div>{{ getOwners(props.row.owners) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Treść" min-width="300" align="center" property="tresc_zgody">
                <template v-slot="props">
                  <div style="word-break: keep-all; padding-top: 3px;">
                    {{ props.row.tresc_zgody }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Dodał" width="120" align="center" property="user_id">
                <template v-slot="props">
                  <div style="word-break: keep-all; padding-top: 3px;">
                    {{ getUserName(props.row.user_id) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Data dodania" width="120" align="center" property="add_time">
                <template v-slot="props">
                  <div style="word-break: keep-all; padding-top: 3px;">
                    {{ props.row.add_time }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="80">
                <template slot="header">
                  <p-button type="success" icon round @click="showModal('add', null)">
                    <i class="fa fa-plus"></i>
                  </p-button>
                </template>
                <template v-slot="props">
                  <p-button type="success" size="sm" icon @click="showModal('edit', props.row)">
                    <i class="fa fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-body row">
            <div class="col-sm-6 pagination-info">
              <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
// ui components
import { Select, Option, OptionGroup, Tooltip } from "element-ui";
import BackToTop from "vue-backtotop";
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions
import { LOAD_CONSENTS_DICT, CONSENTS_DICT_INVALID } from "src/store/actions/consents/consents_dict";
import { LOAD_CONSENTS_OWNERS, CONSENTS_OWNERS_INVALID } from "src/store/actions/consents/consents_owners";
import { LOAD_USERS_LIST, USERS_LIST_INVALID } from "src/store/actions/admin/users_list";

import ConsentsDictModal from "./modals/ConsentsDictModal.vue";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    PPagination,
    BackToTop,
  },
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      active_owner_id: "",
    };
  },
  computed: {
    usersList() {
      return this.$store.getters.getUsersList;
    },
    consentsDict() {
      return this.$store.getters.getConsentsDict;
    },
    consentsOwners() {
      return this.$store.getters.getConsentsOwners;
    },
    tableData() {
      let data = Object.values(this.consentsDict);
      data = data.filter(x => this.active_owner_id === "" || x.owners.some(owner => owner.id === this.active_owner_id));
      return data;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData;

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_CONSENTS_DICT);
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(USERS_LIST_INVALID);
      }
      this.$store.commit(CONSENTS_DICT_INVALID);

      this.$store.dispatch(LOAD_USERS_LIST, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_CONSENTS_OWNERS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_CONSENTS_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getUserName(user_id) {
      if (this.usersList[user_id]) {
        return this.usersList[user_id].user_name;
      } else {
        return user_id;
      }
    },
    rowStyle({ row }) {
      if (row.site_id === null) {
        return "background-color: #f2fcff; font-weight: bold;";
      }
      return "";
    },
    showModal(mode, row) {
      // wyświetlenie modala edycji zgód
      this.$modal.show(
        ConsentsDictModal,
        {
          mode: mode,
          row: row,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(ConsentsDictModal);
              },
            },
          ],
        },
        {
          name: "ConsentsDictModal",
          draggable: false,
          scrollable: true,
          width: "70%",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    getOwners(owners) {
      return owners.map(x => x.name).join(", ");
    },
  },
  mounted() {
    eventBus.$on("consentsDictSaved", () => {
      this.loadData(true);
    });
  },
  async created() {
    this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="scss" scoped>
.highlight {
  font-size: 1.2em;
  font-weight: bold;
}
.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
