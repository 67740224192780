<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 v-if="mode === 'add'" class="title">Dodaj nowe przychody</h5>
          <h5 v-if="mode !== 'add'" class="title">Edytuj przychód</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer; opacity: 0.5" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <el-form label-position="left" label-width="100px">
            <el-form-item label="Tydzień">
              <flat-pickr
                class="flatPickr"
                v-model="pickrWeek"
                :config="config"
                placeholder="Wybierz tydzień"
                :disabled="mode === 'edit'"
              ></flat-pickr>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card bg-light border border-secondary">
            <div class="card-header">
              <div class="row">
                <div class="col-sm-9">
                  <h6 class="card-title">Konfiguracja</h6>
                </div>
                <div class="col-sm-3 text-right" v-if="mode === 'add'">
                  <p-button size="sm" type="success" @click.prevent="addNewRow">Dodaj</p-button>
                </div>
              </div>
            </div>
            <div class="row" v-for="(element, index) in model.config_json" :key="index">
              <div class="col-md-4">
                <div class="form-group label-floating">
                  <label class="control-label">Projekt</label>
                  <el-select
                    v-model="element.project_id"
                    name="project"
                    data-vv-as="Projekt"
                    style="width: 100%"
                    filterable
                    :disabled="mode === 'edit'"
                  >
                    <el-option
                      v-for="project in projects"
                      :label="'[' + project.project_id + '] ' + project.project_name"
                      :value="project.project_id"
                      :key="project.project_id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div :class="mode === 'add' ? 'col-md-3' : 'col-md-4'">
                <div class="form-group label-floating">
                  <label class="control-label">Przychód</label>
                  <el-input type="number" placeholder="Wprowadź przychód" v-model="model.config_json[index].income"> </el-input>
                </div>
              </div>
              <div :class="mode === 'add' ? 'col-md-3' : 'col-md-4'">
                <div class="form-group label-floating">
                  <label class="control-label">Wyświetlenia</label>
                  <el-input type="number" placeholder="Wprowadź wyświetlenia" v-model="model.config_json[index].displays"> </el-input>
                </div>
              </div>
              <div class="col-md-1 align-self-center" v-if="mode === 'add'">
                <p-button size="sm" type="danger" @click.prevent="deleteRow(index)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </p-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row center_row">
        <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library
// ui components
import { Input, InputNumber, DatePicker, Form, FormItem, Select, Option } from "element-ui";

// vuex actions
import { SAVE_UPSELLING_INCOME, UPSELLING_INCOME_INVALID } from "src/store/actions/secure/upselling_income";

// event bus
import { eventBus } from "src/main";
import { FIRST_DAY_OF_WEEK } from "src/config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Polish } from "flatpickr/dist/l10n/pl.js";
import weekSelect from "flatpickr/dist/plugins/weekSelect/weekSelect.js";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPickr,
  },
  props: ["mode", "row", "parentWeek", "copyData"],
  data() {
    return {
      loading: false,
      pickrWeek: null,
      config: {
        locale: {
          ...Polish,
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
        plugins: [new weekSelect({})],
        altInput: true,
        altInputClass: "flatPickr",
        altFormat: "T\\y\\dz\\ień W",
      },
      model: {
        id: null,
        date_from: "",
        date_to: "",
        config_json: [
          {
            project_id: null,
            displays: 0,
            income: 0,
          },
        ],
      },
      modelValidations: {
        id: {
          numeric: true,
          min: 1,
        },
      },
    };
  },
  computed: {
    week() {
      let resultDate = null;
      if (this.pickrWeek) {
        let resultDate = new Date(this.pickrWeek);
        const selectedDay = resultDate.getDay();

        // 0: -4, 1: -5, 2: +1, 3: 0, 4: -1, 5: -2, 6: -3
        if (selectedDay >= 2) {
          resultDate.setDate(resultDate.getDate() + (3 - selectedDay));
        } else {
          resultDate.setDate(resultDate.getDate() + (-4 - selectedDay));
        }

        return resultDate;
      }

      return resultDate;
    },
    dateFrom() {
      let firstDay = null;
      if (this.week !== null) {
        firstDay = new Date(this.week.getTime());
        firstDay.setDate(firstDay.getDate() - 1);
      }
      return firstDay;
    },
    dateTo() {
      let lastDay = null;
      if (this.week !== null) {
        lastDay = new Date(this.week.getTime());
        lastDay.setDate(lastDay.getDate() + 5);
      }
      return lastDay;
    },
    projects() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjects);
      let uniqueProjects = {};

      for (let i in projects) {
        // wybranie unikalnych projektów
        uniqueProjects[projects[i].project_id] = { project_id: projects[i].project_id, project_name: projects[i].project_name };
      }

      let result = Object.values(uniqueProjects).sort((a, b) => (a.project_id > b.project_id ? 1 : -1)); // sortowanie projektów po nazwie

      return result;
    },
  },
  methods: {
    addNewRow() {
      this.model.config_json.push({ project_id: null, displays: 0, income: 0 });
    },
    deleteRow(index) {
      this.model.config_json.splice(index, 1);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        let model = this.model;
        if (this.week === null) {
          eventBus.$emit("notify", {
            message: "Należy wybrać tydzień!",
            type: "warning",
          });
          return;
        }
        if (this.model.config_json.some((x) => x.project_id === null)) {
          eventBus.$emit("notify", {
            message: "Każdy projekt musi zostać wybrany!",
            type: "warning",
          });
          return;
        }
        if (this.model.config_json.length === 0) {
          eventBus.$emit("notify", {
            message: "Konfiguracja nie może być pusta!",
            type: "warning",
          });
          return;
        }
        model.date_from = moment(this.dateFrom).format("YYYY-MM-DD");
        model.date_to = moment(this.dateTo).format("YYYY-MM-DD");
        model.mode = this.mode;
        console.log(model);

        await this.$store.dispatch(SAVE_UPSELLING_INCOME, model).catch((error) => {
          console.log(error.response);
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getUpsellingIncomeModifiedRecords > 0) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.mode === "edit" ? "zmodyfikowany" : "dodany"),
            type: "success",
          });

          this.$store.commit(UPSELLING_INCOME_INVALID); // oznacz nieaktualność danych
          eventBus.$emit("upsellingIncomeSaved");
          this.$emit("close");
        }
      }
    },
  },
  async created() {
    if (this.mode === "edit") {
      const copyRow = JSON.parse(
        JSON.stringify({
          project_id: this.row.project_id,
          displays: this.row.displays,
          income: this.row.income,
        }),
      );
      this.model.config_json = [];
      this.model.config_json.push(copyRow);
      this.pickrWeek = new Date(this.parentWeek);
    }
    if (this.mode === "copy") {
      this.model.config_json = this.copyData;
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.center_row {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}
</style>
