<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-10">
        <h5 class="title">Historia użytkownika</h5>
      </div>
      <div class="col-sm-2">
        <router-link
          :to="{
            name: 'Reklamacje',
            params: {
              company: $route.params.company,
              mailbox: $route.params.mailbox,
              complaintID: $route.params.complaintID,
            },
          }"
        >
          <p-button type="default" link v-if="complaintCheck"> Powrót do reklamacji </p-button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <label>Wpisz ID użytkownika</label>
              <fg-input :error="getError('ID użytkownika')" data-vv-as="ID użytkownika">
                <el-input
                  :disabled="selectedFilter == 'teledata'"
                  type="text"
                  inputmode="numeric"
                  name="ID użytkownika"
                  clearable
                  placeholder="ID użytkownika"
                  v-model="model.user_id"
                  :error="getError('ID użytkownika')"
                >
                </el-input>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label>Wpisz numer telefonu</label>
              <fg-input :error="getError('Numer telefonu')" data-vv-as="Numer telefonu">
                <el-input
                  :disabled="selectedFilter == 'user_id'"
                  type="tel"
                  name="Numer telefonu"
                  clearable
                  placeholder="Numer telefonu"
                  v-model="model.phone"
                  :error="getError('Numer telefonu')"
                >
                </el-input>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label>lub adres email</label>
              <fg-input :error="getError('Adres email')" data-vv-as="Adres email">
                <el-input
                  :disabled="selectedFilter == 'user_id'"
                  type="email"
                  name="Adres email"
                  clearable
                  placeholder="Adres email"
                  v-model="model.mail"
                  v-validate="!model.mail.includes('*') ? modelValidations.mail : ''"
                  :error="getError('Adres email')"
                >
                </el-input>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <p-button type="success" size="sm" @click="loadData">Sprawdź</p-button>
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="margin-left: 10px">
        <div class="card-body">
          <h6>Filtry</h6>
          <div class="row">
            <div class="col-sm-12">
              <el-checkbox v-model="actionFilters.registration" disabled>Rejestracja</el-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-checkbox v-model="actionFilters.complaints">Reklamacje</el-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-checkbox v-model="actionFilters.removal">Blacklista</el-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-checkbox v-model="actionFilters.quiz">Konkurs</el-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-checkbox v-model="actionFilters.sale">Sprzedaż</el-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-checkbox v-model="actionFilters.mailings">Mailingi</el-checkbox>
              <div class="mailingHint">(ostatnie 3 msc)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="this.$store.state.user_history.loading_status === 'success'">
      <div class="card-body">
        <div class="row">
          <el-table :data="userHistory" style="width: 100%" :default-sort="{ prop: 'eventTime', order: 'descending' }">
            <el-table-column type="expand">
              <template v-slot="props">
                <div class="cont">
                  <div v-if="props.row.type === 'registration'">
                    <div style="display: none">{{ counter }}</div>
                    <div class="row">
                      <div class="col-sm-6">
                        <h6><u>Zweryfikowane</u></h6>
                        <p><b>Imię: </b>{{ getParam(props.row.Name) }}</p>
                        <p><b>Nazwisko: </b>{{ getParam(props.row.Surname) }}</p>
                        <p><b>Telefon: </b>{{ getParam(props.row.Phone) }}</p>
                        <p><b>Email: </b>{{ getParam(props.row.Email) }}</p>
                        <p><b>Data urodzenia: </b>{{ getParam(props.row.Birthdate) }}</p>
                        <p><b>Adres: </b>{{ getUserAdress("tc", props.row) }}</p>
                      </div>
                      <div class="col-sm-6">
                        <h6><u>Oryginalne</u></h6>
                        <p><b>Imię: </b>{{ getParam(props.row.NameUD) }}</p>
                        <p><b>Nazwisko: </b>{{ getParam(props.row.SurnameUD) }}</p>
                        <p><b>Telefon: </b>{{ getParam(props.row.PhoneUD) }}</p>
                        <p><b>Email: </b>{{ getParam(props.row.EmailUD) }}</p>
                        <p><b>Data urodzenia: </b>{{ getParam(props.row.BirthdateUD) }}</p>
                        <p><b>Adres: </b>{{ getUserAdress("ud", props.row) }}</p>
                      </div>
                    </div>
                    <br />
                    <p>
                      <b>Projekt: </b><span>{{ "[" + props.row.ProjectID + "] " + props.row.ProjectName + " - " }}</span>
                      <span>{{ props.row.Module + " " }}</span>
                      <span v-if="projects[props.row.Module]">{{ projects[props.row.Module].version }}</span>
                    </p>
                    <p>
                      <b>Dom mediowy: </b
                      >{{
                        props.row.MediaHouseId !== 0 && props.row.MediaHouseId !== null
                          ? "[" + props.row.MediaHouseId + "] " + props.row.MediaHouseName
                          : "brak"
                      }}
                    </p>
                    <p><b>Adres IP: </b>{{ props.row.IpAddress !== "" && props.row.IpAddress !== null ? props.row.IpAddress : "brak" }}</p>
                    <br />
                    <p style="cursor: pointer" v-if="!userConsents[props.row.UserDataID]" @click="getUserConsents(props.row.UserDataID)">
                      <b>Pokaż zgody</b>
                    </p>
                    <div v-else>
                      <appConsentsComponent :consents="userConsents[props.row.UserDataID]" type="consent" />
                      <appConsentsComponent :consents="userConsents[props.row.UserDataID]" type="disclosure" />
                    </div>
                  </div>
                  <div v-else-if="props.row.type === 'complaint'">
                    <p><b>Skrzynka: </b>{{ props.row.mailbox_name }}</p>
                    <p><b>Status: </b>{{ props.row.status_name }}</p>
                  </div>
                  <div v-else-if="props.row.type === 'quiz'">
                    <p><b>Imię: </b>{{ getParam(props.row.Name) }}</p>
                    <p><b>Nazwisko: </b>{{ getParam(props.row.Surname) }}</p>
                    <p><b>Data urodzenia: </b>{{ getParam(props.row.Birthdate) }}</p>
                    <p><b>Nr tel: </b>{{ `${getParam(props.row.Phone)}` }}</p>
                    <p><b>Email: </b>{{ `${getParam(props.row.Email)}` }}</p>
                    <p><b>Adres: </b>{{ getUserAdress("tc", props.row) }}</p>
                    <p>
                      <b>Projekt: </b><span>{{ "[" + props.row.ProjectID + "] " + props.row.ProjectName + " - " }}</span>
                      <span>{{ props.row.Module + " " }}</span>
                      <span v-if="projects[props.row.Module]">{{ projects[props.row.Module].version }}</span>
                    </p>
                    <p>
                      <b>Dom mediowy: </b
                      >{{
                        props.row.MediaHouseId !== 0 && props.row.MediaHouseId !== null
                          ? "[" + props.row.MediaHouseId + "] " + props.row.MediaHouseName
                          : "brak"
                      }}
                    </p>
                    <p><b>Adres IP: </b>{{ props.row.IpAddress !== "" && props.row.IpAddress !== null ? props.row.IpAddress : "brak" }}</p>
                  </div>
                  <div v-else-if="props.row.type === 'sale'">
                    <p><b>Klient: </b>{{ `[${props.row.clientID}] ${props.row.clientName}` }}</p>
                    <p><b>Partner: </b>{{ `[${props.row.partnerID}] ${props.row.partnerName}` }}</p>
                    <p><b>Nr tel: </b>{{ `${getParam(props.row.phone)}` }}</p>
                    <p><b>Email: </b>{{ `${getParam(props.row.email)}` }}</p>
                  </div>
                  <div v-if="props.row.type === 'removal'">
                    <p><b>Baner: </b>{{ `${props.row.BannerId}` }}</p>
                    <p><b>Media House: </b>{{ `${props.row.MediaHouseId}` }}</p>
                  </div>
                  <div v-if="props.row.type === 'mailing'">
                    <p><b>Mail: </b>{{ props.row.email }}</p>
                    <p><b>UserDataID: </b>{{ props.row.UserDataID }}</p>
                    <p><b>Email nadacy: </b>{{ getParam(props.row.from_email) }}</p>
                    <p><b>Nazwa nadacy: </b>{{ getParam(props.row.from_name) }}</p>
                    <p><b>Temat: </b>{{ props.row.subject }}</p>
                    <p><b>Treść: </b><span class="mailing_link" @click="openMailingContent(props.row.message)">Otwórz</span></p>
                    <p><b>ID Banera: </b>{{ props.row.banner_id }}</p>
                    <p><b>ID kampanii: </b>{{ props.row.campaign_id }}</p>
                    <p><b>ID listy: </b>{{ props.row.sended_list_id }}</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Data" property="eventTime" width="200"></el-table-column>
            <el-table-column label="" property="type" width="60">
              <template v-slot="props">
                <span v-if="props.row.type === 'removal'"><i class="fa fa-trash text-danger" aria-hidden="true"></i></span>
                <span v-else-if="props.row.type === 'registration'"><i class="fa fa-id-card-o text-warning" aria-hidden="true"></i></span>
                <span v-else-if="props.row.type === 'complaint'"><i class="fa fa-id-card-o text-danger" aria-hidden="true"></i></span>
                <span v-else-if="props.row.type === 'quiz'"><i class="fa fa-pencil text-info" aria-hidden="true"></i></span>
                <span v-else-if="props.row.type === 'sale'"><i class="fa fa-usd text-success" aria-hidden="true"></i></span>
                <span v-else-if="props.row.type === 'mailing'"><i class="fa fa-envelope-o text-info" aria-hidden="true"></i></span>
              </template>
            </el-table-column>
            <el-table-column label="Akcja" property="type" min-width="100">
              <template v-slot="props">
                <span v-if="props.row.type === 'removal'">{{ props.row.Action }}</span>
                <span v-else-if="props.row.type === 'registration'">{{ `Rejestracja: ${props.row.ProjectName} ${props.row.Module}` }}</span>
                <span v-else-if="props.row.type === 'complaint'">{{ `Reklamacja ${props.row.id}: ${getComplaintsInfo(props.row)}` }}</span>
                <span v-else-if="props.row.type === 'quiz'">{{ `Konkurs: ${props.row.ProjectName} ${props.row.Module}` }}</span>
                <span v-else-if="props.row.type === 'sale'">{{ getSaleInfo(props.row) }}</span>
                <span v-else-if="props.row.type === 'mailing'">{{ `Mailing: ${props.row.campaign_name}` }}</span>
              </template>
            </el-table-column>
            <el-table-column label="UserDataID" property="UserDataID" width="200">
              <template v-slot="props">
                {{ props.row.UserDataID ? props.row.UserDataID : "-" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Select, Option, Input, Tooltip, Checkbox } from "element-ui";

import Swal from "sweetalert2";

// vuex actions
import { LOAD_USER_CONSENTS, USER_CONSENTS_INVALID } from "src/store/actions/complaints/user_consents";
import { LOAD_USER_HISTORY, USER_HISTORY_INVALID } from "src/store/actions/complaints/user_history";
import { LOAD_PROJECTS } from "src/store/actions/questions/projects";

import ConsentsComponent from "src/components/Utils4/complaints/views/ConsentsComponent.vue";

// event bus
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Tooltip.name]: Tooltip,
    [Checkbox.name]: Checkbox,
    appConsentsComponent: ConsentsComponent,
  },
  data() {
    return {
      loading: false,
      model: {
        phone: "",
        mail: "",
        user_id: "",
      },
      modelValidations: {
        mail: {
          regex: "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}",
        },
      },
      actionFilters: {
        registration: true,
        complaints: true,
        removal: true,
        quiz: false,
        sale: true,
        mailings: false,
      },
      userConsents: {},
      showQuizData: false,
      complaintCheck: false,
      counter: 0,
    };
  },
  computed: {
    userHistory() {
      return this.$store.getters.getUserHistory;
    },
    projects() {
      const data = this.$store.getters.getProjects;
      let result = {};
      for (let i in data) {
        result[data[i].module] = data[i];
      }
      return result;
    },
    selectedFilter() {
      if (this.model.user_id.length > 0) {
        return "user_id";
      }
      if (this.model.mail.length > 0 || this.model.phone.length > 0) {
        return "teledata";
      }
      return null;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validatePhone(phone) {
      if (!phone) {
        return false;
      }
      let validatedPhone = phone.replace(/[^0-9]/g, "");
      validatedPhone = validatedPhone.length == 9 ? "48" + validatedPhone : validatedPhone;
      if (validatedPhone.length != 11) {
        return false;
      } else {
        this.model.phone = validatedPhone;
        return validatedPhone;
      }
    },
    async loadData(force) {
      if (this.model.phone.length < 1 && this.model.mail.length < 1 && this.model.user_id.length < 1) {
        eventBus.$emit("notify", {
          message: "Należy podać dane do wyszukania",
          type: "warning",
        });
        return;
      }
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      let phone = "";
      if (this.model.phone.length > 0) {
        phone = this.validatePhone(this.model.phone);
      }

      if (phone !== "" && !phone) {
        eventBus.$emit("notify", {
          message: "Nieprawidłowy numer telefonu",
          type: "warning",
        });
        return;
      }

      this.loading = true;
      this.showQuizData = false;

      const filters = {
        phone: phone,
        mail: this.model.mail,
        user_id: this.model.user_id,
        actionFilters: this.actionFilters,
      };

      if (force) {
        this.$store.commit(USER_HISTORY_INVALID);
      }

      this.$store.dispatch(LOAD_PROJECTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_USER_HISTORY, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    async getUserConsents(userDataID) {
      this.loading = true;

      this.$store.commit(USER_CONSENTS_INVALID);

      await this.$store.dispatch(LOAD_USER_CONSENTS, { userDataID: [userDataID] }).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.userConsents[userDataID] = this.$store.getters.getUserConsents[userDataID];

      this.counter++;
      this.loading = false;
    },
    getUserAdress(db, { Street, StreetUD, HouseNumber, HouseNumberUD, ApartmentNumber, ApartmentNumberUD, PostCode, PostCodeUD, City, CityUD }) {
      let adress = "";
      let street,
        house_number,
        apartment_number,
        postcode,
        city = "";
      if (db === "ud") {
        street = StreetUD;
        house_number = HouseNumberUD;
        apartment_number = ApartmentNumberUD;
        postcode = PostCodeUD;
        city = CityUD;
      } else {
        street = Street;
        house_number = HouseNumber;
        apartment_number = ApartmentNumber;
        postcode = PostCode;
        city = City;
      }
      if (street === "" || street === null) {
        if (postcode !== "" && postcode !== null && postcode !== 0) {
          adress += postcode + " ";
        }
        if (city !== "" && city !== null) {
          adress += city;
        }
        if (adress !== "") {
          return adress;
        } else {
          return "brak";
        }
      }
      adress = street + " " + house_number;
      adress += apartment_number !== null && apartment_number !== "" ? "/" + apartment_number : "";
      adress += ", " + this.getPostcode(postcode) + city;
      return adress;
    },
    getPostcode(code) {
      if (!code) return "";
      if (code.length === 6) return code;
      let postcode = ("00000" + code).slice(-5);
      return postcode.slice(0, 2) + "-" + postcode.slice(2, 5) + " ";
    },
    getParam(param) {
      if (param === null || param === "" || param === 0) {
        return "brak";
      } else {
        return param;
      }
    },
    getSaleInfo(row) {
      let content = "Sprzedaż: ";
      if (row.partnerName) {
        content += row.partnerName + " - ";
      }
      content += row.clientName;
      return content;
    },
    getComplaintsInfo({ from_email, from_msisdn }) {
      let result = "";
      if (from_email) {
        result = from_email;
      }
      if (from_msisdn) {
        if (result.length > 0) {
          result += " / " + from_msisdn;
        }
      }
      return result;
    },
    openMailingContent(message) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-light btn-fill",
          content: "user_history__swal_content",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          html: message,
          showCancelButton: false,
          confirmButtonText: "Wróć",
        })
        .then(() => {
          return;
        })
        .catch(() => {
          return;
        });
    },
  },
  created() {
    let params = this.$route.params;
    if (params.phone) {
      this.model.phone = params.phone;
      this.complaintCheck = true;
    }
    if (params.mail) {
      this.model.mail = params.mail;
      this.complaintCheck = true;
    }
    if (this.complaintCheck) {
      this.loadData(true);
    }
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy() {
    this.$store.commit(USER_HISTORY_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style scoped>
.center {
  justify-content: center;
  align-content: center;
  display: flex;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mailingHint {
  font-size: 0.8em;
  color: gray;
}

.mailing_link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.cont {
  margin: 15px;
}
</style>

<style>
.user_history__swal_content {
  max-width: 100% !important;
  overflow-x: auto !important;
}
</style>
