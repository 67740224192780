import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_POSTCODES,
  POSTCODES_LOADING,
  POSTCODES_LOADING_SUCCESS,
  POSTCODES_LOADING_ERROR,
  POSTCODES_INVALID,
  SAVE_POSTCODE,
  POSTCODE_SAVING,
  POSTCODE_SAVING_SUCCESS,
  POSTCODE_SAVING_ERROR
} from '../../actions/questionForm/postcodes'

const storeObjectConfig = {
  objectName: "Questions",
  getterName: "getPostcodes",
  loadActionName: LOAD_POSTCODES,
  loadingMutationName: POSTCODES_LOADING,
  loadingSuccessMutationName: POSTCODES_LOADING_SUCCESS,
  loadingErrorMutationName: POSTCODES_LOADING_ERROR,
  invalidMutationName: POSTCODES_INVALID,
  saveActionName: SAVE_POSTCODE,
  savingMutationName: POSTCODE_SAVING,
  savingSuccessMutationName: POSTCODE_SAVING_SUCCESS,
  savingErrorMutationName: POSTCODE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'binary',
  loadParams: {
    url: "/questions/questionForm/postcodes",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/questions/questionForm/upload_postcodes",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
