import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_PARTNERS,
  PARTNERS_LOADING,
  PARTNERS_LOADING_SUCCESS,
  PARTNERS_LOADING_ERROR,
  PARTNERS_INVALID,
  SAVE_PARTNER,
  PARTNER_SAVING,
  PARTNER_SAVING_SUCCESS,
  PARTNER_SAVING_ERROR
} from 'src/store/actions/admin/partners'

import {
  ACTIVE_PARTNER
} from 'src/store/actions/common'

const storeObjectConfig = {
  objectName: "Partners",
  getterName: "getPartners",
  loadActionName: LOAD_PARTNERS,
  loadingMutationName: PARTNERS_LOADING,
  loadingSuccessMutationName: PARTNERS_LOADING_SUCCESS,
  loadingErrorMutationName: PARTNERS_LOADING_ERROR,
  invalidMutationName: PARTNERS_INVALID,
  saveActionName: SAVE_PARTNER,
  savingMutationName: PARTNER_SAVING,
  savingSuccessMutationName: PARTNER_SAVING_SUCCESS,
  savingErrorMutationName: PARTNER_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/partners",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/partners",
    method: "put" // {'get','post','put'}
  }
}

const extraGetters = {
  getPartnerNameById: (state) => (id) => {
    return !!state.data[id] ? state.data[id].partner_name : '';
  }
}

// const hookFunctions = {
//   afterSuccessfulLoad({commit, dispatch, getters}) {
//     const data = getters.getPartners;
//     commit(ACTIVE_PARTNER, !!data[getters.getActivePartnerId] ? getters.getActivePartnerId : data[Object.keys(data)[0]]['partner_id']);
//   }
// }

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = Object.assign({}, storeGen.gettersGenerator(storeObjectConfig), extraGetters);
// const actions = storeGen.actionsGenerator(storeObjectConfig, hookFunctions);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
