export const LOAD_USER_GROUP = 'LOAD_USER_GROUP'
export const USER_GROUP_LOADING = 'USER_GROUP_LOADING'
export const USER_GROUP_LOADING_SUCCESS = 'USER_GROUP_LOADING_SUCCESS'
export const USER_GROUP_LOADING_ERROR = 'USER_GROUP_LOADING_ERROR'
export const USER_GROUP_INVALID = 'USER_GROUP_INVALID'

export const SAVE_USER_GROUP = 'SAVE_USER_GROUP'
export const USER_GROUP_SAVING = 'USER_GROUP_SAVING'
export const USER_GROUP_SAVING_SUCCESS = 'USER_GROUP_SAVING_SUCCESS'
export const USER_GROUP_SAVING_ERROR = 'USER_GROUP_SAVING_ERROR'

export const DELETE_USER_GROUP = 'DELETE_USER_GROUP'
export const USER_GROUP_DELETING = 'USER_GROUP_DELETING'
export const USER_GROUP_DELETING_SUCCESS = 'USER_GROUP_DELETING_SUCCESS'
export const USER_GROUP_DELETING_ERROR = 'USER_GROUP_DELETING_ERROR'
