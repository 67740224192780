<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">{{ title }}</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>

      <div class="row" v-if="type === 'questions'">
        <div class="col-md-12">
          <div class="form-group">
            <label>Pytanie</label>
            <fg-input :error="getError('question_id')" data-vv-as="Pytanie">
              <el-select
                id="profilePrefsFormQuestionSelect"
                v-on:visible-change="getProfilePrefsFormQuestionSelectWidth()"
                v-model="questionStatsModel.question_id"
                v-validate="questionStatsModelValidations.question_id"
                name="question_id"
                data-vv-as="Pytanie"
                filterable
              >
                <el-option
                  :style="questionSelectWidth !== null ? 'width: ' + questionSelectWidth + 'px' : 'width: 300px'"
                  v-for="question in questions"
                  :label="'[' + question.id + '] ' + question.question_content"
                  :value="question.id"
                  :key="question.id"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>

      <div class="row" v-if="type === 'questions'">
        <div class="col-md-12">
          <div class="form-group">
            <label>Projekt</label>
            <fg-input :error="getError('project')" data-vv-as="Projekt">
              <el-select
                v-model="questionStatsModel.project"
                v-on:input="clearVersion()"
                name="project"
                data-vv-as="Projekt"
                filterable
              >
                <el-option
                  v-for="project in projects_list"
                  :label="project.project_id === 'ALL_PROJECTS' ? project.project_name : project.project_name + ' [' + project.project_id + ']'"
                  :value="project.project_id"
                  :key="project.project_id"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>

      <div class="row" v-if="type === 'questions'">
        <div class="col-md-12">
          <div class="form-group">
            <label>Wersja</label>
            <fg-input :error="getError('version')" data-vv-as="Wersja">
              <el-select
                v-model="questionStatsModel.version"
                name="version"
                data-vv-as="Wersja"
                filterable
              >
                <el-option
                  v-for="version in projectModules"
                  :label="version.module_name"
                  :value="version.module"
                  :key="version.module"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>

      <div class="row" v-if="type === 'mailings'">
        <div class="col-md-12">
          <div class="form-group">
            <label>Projekt</label>
            <fg-input :error="getError('project')" data-vv-as="Projekt">
              <el-select
                v-model="mailingStatsModel.project_id"
                name="project"
                data-vv-as="Projekt"
                filterable
              >
                <el-option
                  v-for="project in projects_list"
                  :label="project.project_id === 'ALL_PROJECTS' ? project.project_name : project.project_name + ' [' + project.project_id + ']'"
                  :value="project.project_id"
                  :key="project.project_id"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>

      <div class="row" v-if="type === 'mailings'">
        <div class="col-md-12">
          <div class="form-group">
            <label>Rodzaj</label>
            <fg-input :error="getError('mailing_type')" data-vv-as="Rodzaj">
              <el-select
                v-model="mailingStatsModel.mailing_type"
                name="mailing_type"
                data-vv-as="Rodzaj"
              >
                <el-option
                  v-for="type in mailingType"
                  :label="type"
                  :value="type"
                  :key="type"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-center">
          <p-button type="success" @click="saveData">{{ mode === 'edit' ? 'Edytuj' : 'Dodaj'}}</p-button>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option } from "element-ui";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";
import { LOAD_QUESTION_LIST, QUESTION_LIST_INVALID } from 'src/store/actions/questions/question_list';
import { LOAD_PROJECTS, PROJECTS_INVALID } from 'src/store/actions/questions/projects';
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from 'src/store/actions/questions/project_dict';

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: ['mode', 'type', 'row'],
  data() {
    return {
      questionStatsModel: {
        question_id: null,
        project: 'ALL_PROJECTS',
        version: 'ALL_VERSIONS',
      },
      questionStatsModelValidations: {
        question_id: {
          required: true,
          numeric: true,
          min: 1
        },
      },
      mailingStatsModel: {
        project_id: 'ALL_PROJECTS',
        mailing_type: 'Wewnętrzne'
      },
      questionSelectWidth: null,
      mailingType: ['Wewnętrzne', 'Zewnętrzne']
    }
  },
  computed: {
    projectModel() {
      if(this.type === 'questions') {
        return this.questionStatsModel.project;
      } else {
        return this.mailingStatsModel.project_id;
      }
    },
    title() {
      let title = '';
      if(this.mode === 'edit') {
        title = title + 'Edycja preferencji';
      } else {
        title = title + 'Tworzenie preferencji';
      }
      if(this.type === 'questions') {
        title = title + ' pytań';
      } else {
        title = title + ' mailingów';
      }
      return title;
    },
    project_dict() {
      let projects = Object.values(this.$store.getters.getProjectDict);

      let result = Object.values(projects).sort((a,b) => (a.project_name > b.project_name) ? 1 : -1); // sortowanie projektów po nazwie

      result.unshift({project_id: 'ALL_PROJECTS', project_name: 'Wszystkie projekty'});

      return result
    },
    projects() { // lista projektów
      let projects = this.$store.getters.getProjects;
      let uniqueProjects = {}

      for (let i in projects) { // wybranie unikalnych projektów
        uniqueProjects[projects[i].project_id] = {project_id: projects[i].project_id, project_name:projects[i].project_name }
      }

      return uniqueProjects;
    },
    projects_list() {
      let projects = [];
      if(this.type === 'questions') {
        projects = {...this.projects};
      } else {
        projects = {...this.$store.getters.getProjectDict};
      }

      let result = Object.values(projects).sort((a,b) => (a.project_name > b.project_name) ? 1 : -1); // sortowanie projektów po nazwie

      result.unshift({project_id: 'ALL_PROJECTS', project_name: 'Wszystkie projekty'});

      return result
    },
    projectModules(){ // wszystkie moduły projektu
      let projects = this.$store.getters.getProjects;
      let filteredData = {};

      for (let [key, project] of Object.entries(projects)) { // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
        if(project.project_id === this.questionStatsModel.project) {
          filteredData[project.module] = Object.assign({}, project);
        }
      }

      let sourceData = [];

      for (let ver of Object.keys(filteredData)) { // iteracja po nazwach modułów danego projektu
        let res = ver.match('[0-9]+.[0-9]+')[0]; // odnajdywanie liczby
        filteredData[ver].number = res;
        filteredData[ver].module_name = ver;
      }

      filteredData['ALL_VERSIONS'] = {module: 'ALL_VERSIONS', number: 0, module_name: 'Wszystkie wersje'};

      sourceData = Object.values(filteredData);
      sourceData.sort((a,b) => (a.number - b.number));
      let result = {};
      for(let i in sourceData) {
        result[sourceData[i].module] = sourceData[i];
      }

      return result
    },
    questions() {
      let questions = Object.values(this.$store.getters.getQuestionList);
      questions.sort((a,b) => b.id - a.id);
      return questions
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData(force) {
      this.$store.commit(QUESTION_LIST_INVALID);

      if (force) {
        this.$store.commit(PROJECTS_INVALID);
        this.$store.commit(PROJECT_DICT_INVALID);
      }

      this.$store.dispatch(LOAD_PROJECTS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      this.$store.dispatch(LOAD_QUESTION_LIST, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      let model = {};
      if(this.type === 'questions') {
        model = this.questionStatsModel
      } else {
        model = this.mailingStatsModel
      }

      if (isValid) {
        eventBus.$emit('profilePrefsSaved', {row: this.row, type: this.type, model: model});
        this.$emit('close');
      }
    },
    clearVersion() {
      this.questionStatsModel.version = 'ALL_VERSIONS';
    },
    getProfilePrefsFormQuestionSelectWidth() {
      this.questionSelectWidth = document.getElementById('profilePrefsFormQuestionSelect').offsetWidth;
    },
  },
  async created() {
    this.loadData();
    if(this.mode === 'edit') {
      if(this.type === 'questions') {
        this.questionStatsModel.question_id = parseInt(this.row.question_id);
        this.questionStatsModel.project = this.row.project === 'ALL_PROJECTS' ? 'ALL_PROJECTS' : parseInt(this.row.project);
        this.questionStatsModel.version = this.row.version;
      } else {
        this.model.project_id = this.row.project_id ? this.row.project_id : 'ALL_PROJECTS';
        this.model.mailing_type = this.row.mailing_type ? this.row.mailing_type : 'Wewnętrzne';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .row {
    margin: 10px;
  }
  .card {
    margin: 0;
    display: flex;
  }
  .row:last-child {
    justify-content: center;
  }
  .center {
    display: flex;
    margin: auto;
    justify-content: center;
    align-content: center;
  }
  .header_row {
    display: flex;
    justify-content: space-between;
  }
</style>
