import { render, staticRenderFns } from "./FilterDetailsModal.vue?vue&type=template&id=bbec5894&scoped=true&"
import script from "./FilterDetailsModal.vue?vue&type=script&lang=js&"
export * from "./FilterDetailsModal.vue?vue&type=script&lang=js&"
import style0 from "./FilterDetailsModal.vue?vue&type=style&index=0&id=bbec5894&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbec5894",
  null
  
)

export default component.exports