<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="card-body row">
          <div class="col-sm-12 col-md-4">
            <fg-input class="input-sm"
                      id="client_rates_search_input"
                      placeholder="Szukaj"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <el-table :data="queriedData" @sort-change="sortChange" style="width: 100%">
          <el-table-column label="ID Klienta" min-width="100" property="client_id" sortable="custom"></el-table-column>
          <el-table-column label="Nazwa" min-width="200" property="client_name" sortable="custom"></el-table-column>
          <el-table-column label="Od" min-width="100" property="date_from" sortable="custom"></el-table-column>
          <el-table-column label="Stawka" min-width="100" property="rate" align="right" sortable="custom">
            <template v-slot="props">
              {{ props.row.rate.toLocaleString('pl-PL', {style: 'currency', currency: 'pln'}) }}
            </template>
          </el-table-column>
          <el-table-column label="Współczynnik" min-width="120" align="right" property="ratio" sortable="custom">
            <template v-slot="props">
              {{ props.row.ratio.toLocaleString() }}
            </template>
          </el-table-column>
          <el-table-column label="Stawka efektywna" min-width="100" align="right" property="value" sortable="custom">
            <template slot="header">
              <span style="word-break: keep-all;">Stawka efektywna</span>
            </template>
            <template v-slot="props">
              {{ props.row.value.toLocaleString('pl-PL', {style: 'currency', currency: 'pln'}) }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="120" v-if="editPermitted">
            <template slot="header">
              <p-button type="success" @click="showModal('add', {})" icon round>
                <i class="fa fa-plus"></i>
              </p-button>
            </template>
            <template v-slot="props">
              <el-tooltip
                class="item"
                effect="light"
                content="Edytuj wartość"
                placement="top"
                :open-delay="500"
              >
                <p-button
                  type="success"
                  size="sm"
                  icon
                  style="margin: 0 5px 0 5px;"
                  @click="showModal('edit', props.row)"
                >
                  <i class="fa fa-edit"></i>
                </p-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="light"
                content="Nowy zakres"
                placement="top"
                :open-delay="500"
              >
                <p-button
                  type="success"
                  size="sm"
                  icon
                  style="margin: 0 5px 0 5px;"
                  @click="showModal('date', props.row)"
                >
                  <i class="fa fa-calendar"></i>
                </p-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div class="card-body row">
          <div class="pagination-info col-sm-6">
            <p class="category">Pokazuje od {{from + 1}} do {{to}} z {{total}} pozycji</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from "vue";
  import moment from "moment"; // time manipulation library
  import utils from "src/utils";

  import * as priv from 'src/privileges.js';

  // event bus
  import { eventBus } from "src/main";

  import { DatePicker, Tooltip } from "element-ui";
  import PPagination from 'src/components/UIComponents/Pagination.vue';

  import { CLIENT_RATES_INVALID, LOAD_CLIENT_RATES } from "src/store/actions/secure/client_rates";
  import { LOAD_UNIQUE_CLIENTS, UNIQUE_CLIENTS_INVALID } from "src/store/actions/secure/unique_clients";
  import { LOAD_CLIENTS, CLIENTS_INVALID } from "src/store/actions/questionForm/clients";

  import ClientRatesModal from "src/components/Utils4/sales/views/ClientRatesModal.vue";

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      [Tooltip.name]: Tooltip,
      PPagination,
    },
    data() {
      return {
        loading: false,
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        day: moment(utils.today()).format("YYYY-MM-DD"),
        searchQuery: '',
        propsToSearch: ['client_id', 'client_name']
      }
    },
    methods: {
      showModal(mode, row) { // wyświetlenie modala
        this.$modal.show(ClientRatesModal, {
          mode: mode,
          row: row,
          buttons: [{
            title: 'Zamknij',
            handler: () => {
              this.$modal.hide(ClientRatesModal);
            }
          }]
        }, {
          draggable: false,
          width: '450px',
          height: 'auto',
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true
        })
      },
      async loadData(force) {
        this.loading = true;

        const filters = {
          day: moment(this.day).format("YYYY-MM-DD"),
          mode: 'active'
        };

        this.$store.commit(CLIENT_RATES_INVALID);

        if(force) {
          this.$store.commit(UNIQUE_CLIENTS_INVALID);
          this.$store.commit(CLIENTS_INVALID);
        }

        this.$store.dispatch(LOAD_CLIENTS, {}).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });

        this.$store.dispatch(LOAD_UNIQUE_CLIENTS, {}).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });

        await this.$store.dispatch(LOAD_CLIENT_RATES, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });

        this.loading = false;
      },
      sortChange(obj) {
        if(obj.order === 'descending') {
          if(obj.prop === 'client_name' || obj.prop === 'date_from' || obj.prop === 'date_to') {
            this.tableData.sort((a,b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase()) ? 1 : -1);
          } else {
            this.tableData.sort((a,b) => b[obj.prop] - a[obj.prop])
          }
        } else if(obj.order === 'ascending') {
          if(obj.prop === 'client_name' || obj.prop === 'date_from' || obj.prop === 'date_to') {
            this.tableData.sort((a,b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase()) ? 1 : -1);
          } else {
            this.tableData.sort((a,b) => a[obj.prop] - b[obj.prop])
          }
        } else {
          this.tableData.sort((a,b) => b.id - a.id);
        }
        this.searchQuery += ' ';
        this.searchQuery = this.searchQuery.slice(0, -1);
      },
    },
    computed: {
      tableData() {
        const getter = this.$store.getters.getClientRates;
        let tableData = Object.values(getter);
        return tableData
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      queriedData () { // paginacja danych
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.tableData.slice(this.from, this.to)
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString();
              let rowValueLowerCase = row[key].toString().toLowerCase();
              if ((rowValue.includes && rowValue.includes(this.searchQuery)) || rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })

        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      editPermitted() {
        const userPrivileges = this.$store.getters.getProfile.privileges;
        return userPrivileges.includes(priv.PRIV_EDIT_CLIENT_RATES)
      },
    },
    mounted() {
      eventBus.$on('clientRatesSaved', day => { // zapis kosztów
        this.day = day;
        this.loadData();
      });
    },
    created() {
      this.loadData();
      eventBus.$on("forceReload", () => {
        this.loadData();
      });
    },
    beforeDestroy: function () {
      eventBus.$off("forceReload");
    },
  }
</script>
<style scoped>
</style>
