<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h5 class="title">Usuń/Wypisz użytkownika</h5>
      </div>
    </div>
    <div :class="company_id === 1 ? 'row' : 'criteriaSectionRow'">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <label>Administrator</label>
              <el-select v-model="company_id" @change="clearCriteria" name="company" data-vv-as="Administrator" style="width: 100%">
                <el-option v-for="company in company" :label="company.name" :value="company.id" :key="company.id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label>Wpisz numer(y) telefonu</label>
              <el-input type="textarea" autosize placeholder="Numery telefonu" v-model="phoneInput"> </el-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label>lub adres(y) email</label>
              <el-input type="textarea" autosize placeholder="Adresy email" v-model="emailInput"> </el-input>
            </div>
          </div>
          <div class="row marginRow" v-if="company_id !== 1">
            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-11">
                  <div class="form-group">
                    <label>Spółka</label>
                    <el-select v-model="criteriaTempModel.owner" @input="criteriaInput($event, 'owner')" name="owner" data-vv-as="Spółka" filterable>
                      <el-option
                        v-for="record in blacklistMsisdnCriteria.owners"
                        :label="'[' + record.owner_id + '] ' + record.owner_name"
                        :value="record.owner_id"
                        :key="record.owner_id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="col-sm-1 helper">
                  <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                    <div slot="content">Wybrane spółki pokażą się na liście po prawej stronie</div>
                    <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
                  </el-tooltip>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-11">
                  <div class="form-group">
                    <label>Brand</label>
                    <el-select v-model="criteriaTempModel.brand" @input="criteriaInput($event, 'brand')" name="brand" data-vv-as="Brand" filterable>
                      <el-option
                        v-for="record in blacklistMsisdnCriteria.brands"
                        :label="record.brand_name"
                        :value="record.brand_id"
                        :key="record.brand_id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="col-sm-1 helper">
                  <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                    <div slot="content">Wybrane brandy pokażą się na liście po prawej stronie</div>
                    <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
                  </el-tooltip>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-11">
                  <div class="form-group">
                    <label>Projekt</label>
                    <el-select
                      v-model="criteriaTempModel.project"
                      @input="criteriaInput($event, 'project')"
                      name="project"
                      data-vv-as="Projekt"
                      filterable
                    >
                      <el-option
                        v-for="record in blacklistMsisdnCriteria.projects"
                        :label="'[' + record.sms_project_id + '] ' + record.sms_project_name"
                        :value="record.sms_project_id"
                        :key="record.sms_project_id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="col-sm-1 helper">
                  <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                    <div slot="content">Wybrane projekty pokażą się na liście po prawej stronie</div>
                    <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
                  </el-tooltip>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-11">
                  <div class="form-group">
                    <label>Bramka</label>
                    <el-select v-model="criteriaTempModel.gate" @input="criteriaInput($event, 'gate')" name="gate" data-vv-as="Bramka" filterable>
                      <el-option
                        v-for="record in blacklistMsisdnCriteria.sms_premium_numbers"
                        :label="record.sms_premium_number"
                        :value="record.sms_premium_number_id"
                        :key="record.sms_premium_number_id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="col-sm-1 helper">
                  <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                    <div slot="content">Wybrane bramki pokażą się na liście po prawej stronie</div>
                    <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
                  </el-tooltip>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-11">
                  <div class="form-group">
                    <label>Data zakończenia</label>
                    <el-date-picker v-model="criteriaModel.date" type="date" placeholder="Wybierz dzień"> </el-date-picker>
                  </div>
                </div>
                <div class="col-sm-1 helper">
                  <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                    <div slot="content">Data zakończenia blacklisty</div>
                    <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="center criteriaRow"><b>Wybrane kryteria</b></div>
              <div class="criteriaRow">
                <span><b>Administrator: </b></span>
                <span>{{ company[company_id].name }}</span>
              </div>
              <div class="criteriaRow">
                <span><b>Telefon: </b></span>
                <span v-for="(phone, index) in phoneArray" :key="phone + '_' + index">{{ phone + ", " }}</span>
              </div>
              <div class="criteriaRow">
                <span><b>Email: </b></span>
                <span v-for="(email, index) in emailArray" :key="email + '_' + index">{{ email + ", " }}</span>
              </div>
              <div class="criteriaRow">
                <span><b>Spółka: </b></span>
                <span
                  v-for="(selectedOwner, index) in criteriaModel.owner"
                  @click="deleteCriteria(index, 'owner')"
                  :key="selectedOwner"
                  class="criteriaItem"
                  >{{ blacklistMsisdnCriteria.owners.find((x) => x.owner_id === selectedOwner).owner_name + ", " }}</span
                >
              </div>
              <div class="criteriaRow">
                <span><b>Brand: </b></span>
                <span
                  v-for="(selectedBrand, index) in criteriaModel.brand"
                  @click="deleteCriteria(index, 'brand')"
                  :key="selectedBrand"
                  class="criteriaItem"
                  >{{ blacklistMsisdnCriteria.brands.find((x) => x.brand_id === selectedBrand).brand_name + ", " }}</span
                >
              </div>
              <div class="criteriaRow">
                <span><b>Projekt: </b></span>
                <span
                  v-for="(selectedProject, index) in criteriaModel.project"
                  @click="deleteCriteria(index, 'project')"
                  :key="selectedProject"
                  class="criteriaItem"
                  >{{ blacklistMsisdnCriteria.projects.find((x) => x.sms_project_id === selectedProject).sms_project_name + ", " }}</span
                >
              </div>
              <div class="criteriaRow">
                <span><b>Bramka: </b></span>
                <span
                  v-for="(selectedGate, index) in criteriaModel.gate"
                  @click="deleteCriteria(index, 'gate')"
                  :key="selectedGate"
                  class="criteriaItem"
                  >{{
                    blacklistMsisdnCriteria.sms_premium_numbers.find((x) => x.sms_premium_number_id === selectedGate).sms_premium_number + ", "
                  }}</span
                >
              </div>
              <div class="criteriaRow">
                <span><b>Data zakończenia: </b></span>
                <span @click="deleteCriteria(0, 'criteriaDate')" class="criteriaItem">{{ criteriaDate }}</span>
              </div>
            </div>
          </div>
          <div class="row" v-if="company_id === 1">
            <div class="col-sm-6 text-right">
              <p-button :disabled="emailArray.length < 1" type="success" size="sm" @click="handleAction('unsubscribe')">Wypisz</p-button>
            </div>
            <div class="col-sm-6">
              <p-button :disabled="emailArray.length < 1 && phoneArray.length < 1" type="success" size="sm" @click="handleAction('blacklist')"
                >Usuń</p-button
              >
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-sm-12 center">
              <p-button :disabled="emailArray.length < 1 && phoneArray.length < 1" type="success" size="sm" @click="handleBlacklist"
                >Blacklista</p-button
              >
            </div>
          </div>
        </div>
        <div class="corner">
          <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
            <div slot="content"><b>Wypisz</b> - tylko adresy email, oddzielone enterem</div>
            <div slot="content"><b>Usuń</b> - adresy email i/lub numery telefonów, oddzielone enterem</div>
            <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Select, Option, Input, Tooltip, DatePicker } from "element-ui";

// vuex actions
import { SAVE_BLACKLIST_LEAD } from "src/store/actions/complaints/actions/blacklist_lead";
import { SAVE_UNSUBSCRIBE_LEAD } from "src/store/actions/blacklist/unsubscribe_lead";
import { LOAD_COMPANY, COMPANY_INVALID } from "src/store/actions/complaints/company";
import { LOAD_BLACKLIST_MSISDN_CRITERIA, BLACKLIST_MSISDN_CRITERIA_INVALID } from "src/store/actions/blacklist/blacklist_msisdn_criteria";
import { LOAD_BLACKLIST_EMAIL_CRITERIA, BLACKLIST_EMAIL_CRITERIA_INVALID } from "src/store/actions/blacklist/blacklist_email_criteria";
import { SAVE_BLACKLIST_MSISDN } from "src/store/actions/blacklist/blacklist_msisdn";
import { SAVE_BLACKLIST_EMAIL } from "src/store/actions/blacklist/blacklist_email";

// event bus
import { eventBus } from "src/main";

import Swal from "sweetalert2";
import moment from "moment";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Tooltip.name]: Tooltip,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      is_test: false,
      loading: false,
      phoneInput: "",
      emailInput: "",
      company_id: 1,
      criteriaTempModel: {
        owner: null,
        project: null,
        brand: null,
        gate: null,
      },
      criteriaModel: {
        owner: [],
        project: [],
        brand: [],
        gate: [],
        date: null,
      },
    };
  },
  computed: {
    company() {
      return this.$store.getters.getCompany;
    },
    blacklistEmailCriteria() {
      if (this.$store.state.blacklist_email_criteria.loading_status === "success") {
        return this.$store.getters.getBlacklistEmailCriteria;
      } else {
        return { brands: [], owners: [], projects: [] };
      }
    },
    blacklistMsisdnCriteria() {
      if (this.$store.state.blacklist_msisdn_criteria.loading_status === "success") {
        return this.$store.getters.getBlacklistMsisdnCriteria;
      } else {
        return { brands: [], owners: [], projects: [], sms_premium_numbers: [] };
      }
    },
    criteriaDate() {
      if (this.criteriaModel.date) {
        return moment(this.criteriaModel.date).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    phoneArray() {
      const phoneArr = this.phoneInput.split("\n");
      const result = [];
      phoneArr.forEach((phone) => {
        let validatedPhone = phone.replace(/[^0-9]/g, "");
        validatedPhone = validatedPhone.length == 9 ? "48" + validatedPhone : validatedPhone;
        if (validatedPhone.length === 11) {
          result.push(validatedPhone);
        }
      });
      return result;
    },
    emailArray() {
      const emailArr = this.emailInput.split("\n");
      const result = [];
      emailArr.forEach((email) => {
        let validatedEmail = email.match(/\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/);
        if (validatedEmail) {
          result.push(validatedEmail[0]);
        }
      });
      return result;
    },
  },
  methods: {
    async loadData(force) {
      this.loading = true;

      if (force) {
        this.$store.commit(COMPANY_INVALID);
        this.$store.commit(BLACKLIST_MSISDN_CRITERIA_INVALID);
        this.$store.commit(BLACKLIST_EMAIL_CRITERIA_INVALID);
      }

      this.$store.dispatch(LOAD_BLACKLIST_MSISDN_CRITERIA, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_BLACKLIST_EMAIL_CRITERIA, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_COMPANY, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    clearCriteria() {
      this.criteriaModel = { owner: [], project: [], brand: [], gate: [], date: null };
    },
    criteriaInput(e, criteria) {
      if (!this.criteriaModel[criteria].includes(e)) {
        this.criteriaModel[criteria].push(e);
      }
      this.criteriaTempModel[criteria] = null;
    },
    deleteCriteria(index, criteria) {
      if (criteria === "criteriaDate") return (this.criteriaModel.date = null);

      this.criteriaModel[criteria].splice(index, 1);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async handleAction(mode) {
      if (mode === "unsubscribe" && this.emailArray.length < 1) {
        return;
      } else if (mode === "blacklist" && this.emailArray.length < 1 && this.phoneArray.length < 1) {
        return;
      }

      let showData = "";
      if (mode === "unsubscribe") {
        showData = this.emailArray.join("<br>");
      } else {
        showData = this.phoneArray.join("<br>");
        if (showData.length > 0) {
          showData += "<br><br>";
        }
        showData += this.emailArray.join("<br>");
      }

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Czy chcesz usunąć rekordy z następującymi danymi?",
          html: showData,
          showCancelButton: true,
          confirmButtonText: mode === "unsubscribe" ? "Wypisz" : "Usuń",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isDismissed) {
            return;
          }
          if (res.isConfirmed) {
            this.fireAction(mode);
          }
        })
        .catch(() => {
          return;
        });
    },
    async fireAction(mode) {
      let actionCounter = 0;
      let recordCounter = 0;

      if (mode === "unsubscribe") {
        for (let email of this.emailArray) {
          actionCounter += 1;
          let result = await this.unsubscribeLead(email);
          if (result === "error") return;
          recordCounter += result;
        }
      } else if (mode === "blacklist") {
        for (let email of this.emailArray) {
          actionCounter += 1;
          let result = await this.deleteLead(email, null);
          if (result === "error") return;
          recordCounter += result;
        }
        for (let phone of this.phoneArray) {
          actionCounter += 1;
          let result = await this.deleteLead(null, phone);
          if (result === "error") return;
          recordCounter += result;
        }
      }

      const message = `Usunięte rekordy: ${recordCounter} dla zapytań: ${actionCounter}`;

      eventBus.$emit("notify", {
        message: message,
        type: "success",
      });

      this.emailInput = "";
      this.phoneInput = "";
    },
    async handleBlacklist() {
      let model = {
        company_id: this.company_id,
        system_user_id: this.$store.state.profile.data.ID,
        token: this.$store.state.auth.token,
        owner_ids: this.criteriaModel.owner,
        brand_ids: this.criteriaModel.brand,
        sms_project_ids: this.criteriaModel.project,
        sms_premium_number_ids: this.criteriaModel.gate,
      };
      if (this.criteriaModel.date) {
        model.expiration_date = moment(this.criteriaModel.date).format("YYYY-MM-DD");
      }

      if (this.phoneArray.length > 0) {
        const phoneModel = Object.assign({}, model, { msisdns: this.phoneArray });

        await this.$store.dispatch(SAVE_BLACKLIST_MSISDN, phoneModel).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getBlacklistMsisdnModifiedRecords) {
          eventBus.$emit("notify", {
            message: "Zlecono blacklistę telefonu",
            type: "success",
          });

          this.phoneInput = "";
          this.clearCriteria();
        }
      }
      if (this.emailArray.length > 0) {
        const emailModel = Object.assign({}, model, { emails: this.emailArray });

        await this.$store.dispatch(SAVE_BLACKLIST_EMAIL, emailModel).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getBlacklistEmailModifiedRecords) {
          eventBus.$emit("notify", {
            message: "Zlecono blacklistę email",
            type: "success",
          });

          this.emailInput = "";
          this.clearCriteria();
        }
      }
    },
    async unsubscribeLead(email) {
      const model = {
        user_email: email,
        unsubscribe_type: "email",
        is_test: this.is_test,
        company_id: this.company_id,
        incomplete_data_exception: true,
        system_user_id: this.$store.state.profile.data.ID,
        token: this.$store.state.auth.token,
      };

      await this.$store.dispatch(SAVE_UNSUBSCRIBE_LEAD, model).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
        return "error";
      });

      if (this.$store.getters.getUnsubscribeLeadModifiedRecords.unsubscribe_counter) {
        let counter = this.$store.getters.getUnsubscribeLeadModifiedRecords.unsubscribe_counter;
        return counter;
      } else {
        return 0;
      }
    },
    async deleteLead(email, phone) {
      const model = {
        is_test: this.is_test,
        company_id: this.company_id,
        system_user_id: this.$store.state.profile.data.ID,
        token: this.$store.state.auth.token,
      };

      if (email) {
        model.user_email = email;
      }

      if (phone) {
        model.user_msisdn = phone;
      }

      await this.$store.dispatch(SAVE_BLACKLIST_LEAD, model).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
        return "error";
      });

      if (this.$store.getters.getBlacklistLeadModifiedRecords.transaction_id) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  created() {
    this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy() {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="scss" scoped>
.center {
  justify-content: center;
  align-content: center;
  display: flex;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.corner {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-top: 15px;
}

.criteriaSectionRow {
  display: block;
}

.pointer {
  cursor: pointer;
}

.criteriaRow {
  margin-bottom: 10px;
}

.criteriaItem {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.criteriaItem:hover {
  opacity: 0.6;
}

.marginRow {
  margin-top: 30px;
}

.helper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
</style>
