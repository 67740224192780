import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_PROJECT_CONFIGS,
  PROJECT_CONFIGS_LOADING,
  PROJECT_CONFIGS_LOADING_SUCCESS,
  PROJECT_CONFIGS_LOADING_ERROR,
  PROJECT_CONFIGS_INVALID,
  SAVE_PROJECT_CONFIG,
  PROJECT_CONFIG_SAVING,
  PROJECT_CONFIG_SAVING_SUCCESS,
  PROJECT_CONFIG_SAVING_ERROR,
  DELETE_PROJECT_CONFIG,
  PROJECT_CONFIG_DELETING,
  PROJECT_CONFIG_DELETING_SUCCESS,
  PROJECT_CONFIG_DELETING_ERROR
} from 'src/store/actions/questions/project_configs'

const storeObjectConfig = {
  objectName: "Configuration",
  getterName: "getProjectConfigs",
  loadActionName: LOAD_PROJECT_CONFIGS,
  loadingMutationName: PROJECT_CONFIGS_LOADING,
  loadingSuccessMutationName: PROJECT_CONFIGS_LOADING_SUCCESS,
  loadingErrorMutationName: PROJECT_CONFIGS_LOADING_ERROR,
  invalidMutationName: PROJECT_CONFIGS_INVALID,
  saveActionName: SAVE_PROJECT_CONFIG,
  savingMutationName: PROJECT_CONFIG_SAVING,
  savingSuccessMutationName: PROJECT_CONFIG_SAVING_SUCCESS,
  savingErrorMutationName: PROJECT_CONFIG_SAVING_ERROR,
  deleteActionName: DELETE_PROJECT_CONFIG,
  deletingMutationName: PROJECT_CONFIG_DELETING,
  deletingSuccessMutationName: PROJECT_CONFIG_DELETING_SUCCESS,
  deletingErrorMutationName: PROJECT_CONFIG_DELETING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/projectConfigs",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/questions/projectConfigs",
    method: "put" // {'get','post','put'}
  },
  deleteParams: {
    url: "/questions/projectConfigs",
    method: "delete" // {'delete'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
