<template>
  <div class="modal-wrapper" :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row header-row">
      <div class="col-sm-11">
        <div class="row">
          <div class="col-sm-5">
            <h5 class="title">Wykresy mailingów</h5>
          </div>
          <div class="col-sm-3">
            <fg-input>
              <el-select
                v-model="choosedDomains"
                placeholder="Filtruj domeny"
                name="domains"
                filterable
                clearable
                multiple
                @input="counter += 1"
              >
                <el-option
                  v-for="domain in domainArray"
                  :label="domain"
                  :value="domain"
                  :key="domain"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-4 text-sm-right">
            <p class="category">{{ resolutionTitle }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
    </div>

    <div class="row" v-if="loading">
      <div class="col-sm-12">
        <div class="center" style="font-size: 1.3em;">
          Wczytywanie danych
        </div>
      </div>
    </div>
    <div v-if="ready">
      <div class="center" style="font-size: 1.3em;" v-if="Object.values(viewsChart).length === 0">
        Brak danych
      </div>
      <div class="row" v-for="mode in viewsChart" :key="mode.mode">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">{{ mode.mode }}</h4>
              <p class="category">{{ mode.from + ' - ' + mode.to }}</p>
            </template>
            <bar-chart :labels="mode.labels"
                      :height="100"
                      :extra-options="mode.options"
                      :datasets="mode.datasets">
            </bar-chart>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from 'src/components/UIComponents'
import { Select, Option } from "element-ui";
import moment from "moment"; // time manipulation library
import BarChart from 'src/components/UIComponents/Charts/BarChart'

// import {  } from "element-ui";
import { LOAD_MAILING_STATS_CHART_DATA, MAILING_STATS_CHART_DATA_INVALID } from "src/store/actions/stats/mailing_stats_chart_data";
// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

import * as priv from 'src/privileges.js';

const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
  };

export default {
  components: {
      Card,
      BarChart,
      [Select.name]: Select,
      [Option.name]: Option,
    },
  data() {
    return {
      loading: false,
      resolution: '',
      domainColors: {'WP': '#d62b00', 'ONET': '#fff359', 'O2': '#00a824', 'INTERIA': '#0065ad', 'GMAIL': '#210200', 'INNA': '#f169ff'},
      domainArray: ['WP', 'ONET', 'O2', 'INTERIA', 'GMAIL'],
      choosedDomains: [],
      counter: 0,
      referenceCounter: 0,
    }
  },
  props: ['filter', 'date', 'mailingType'],
  computed: {
    chartData() {
      let chartData = this.$store.getters.getMailingStatsChartData;
      // return chartData
      for(let i in chartData) {
        if(typeof chartData[i] === 'object' && chartData[i] !== null && (i === 'CTR' || i === 'OR')) {
          let domainData = chartData[i];
          let dateArray = [];
          let gmailData = domainData.GMAIL;
          let innaData = domainData.INNA;
          let interiaData = domainData.INTERIA;
          let o2Data = domainData.O2;
          let onetData = domainData.ONET;
          let wpData = domainData.WP;
          let gmailChartData = [];
          let innaChartData = [];
          let interiaChartData = [];
          let o2ChartData = [];
          let onetChartData = [];
          let wpChartData = [];

          chartData.DateArray.map(x => {
            let el = x.toString();
            if(el.length === 1) {
              el = "0" + el;
            }
            dateArray.push(el.toString());
          })

          let labels = dateArray;

          let data = {};
          for(let key in dateArray) {
            let day = dateArray[key];
            if(gmailData) {
              gmailData[day] ? gmailChartData.push(gmailData[day]) : gmailChartData.push(0);
            }
            if(innaData) {
              innaData[day] ? innaChartData.push(innaData[day]) : innaChartData.push(0);
            }
            if(interiaData) {
              interiaData[day] ? interiaChartData.push(interiaData[day]) : interiaChartData.push(0);
            }
            if(o2Data) {
              o2Data[day] ? o2ChartData.push(o2Data[day]) : o2ChartData.push(0);
            }
            if(onetData) {
              onetData[day] ? onetChartData.push(onetData[day]) : onetChartData.push(0);
            }
            if(wpData) {
              wpData[day] ? wpChartData.push(wpData[day]) : wpChartData.push(0);
            }
          }
          if(gmailData) {
            gmailData.data = gmailChartData;
          }
          if(innaData) {
            innaData.data = innaChartData;
          }
          if(interiaData) {
            interiaData.data = interiaChartData;
          }
          if(o2Data) {
            o2Data.data = o2ChartData;
          }
          if(onetData) {
            onetData.data = onetChartData;
          }
          if(wpData) {
            wpData.data = wpChartData;
          }
          domainData.labels = labels;
        }
      }

      return chartData;
    },
    viewsChart() {
      let counter = this.counter;
      let referenceCounter = this.referenceCounter;
      let chartData = this.chartData;
      let domains = {};
      if(counter !== referenceCounter) { // licznik wymuszający przeliczenie tabeli
        for(let i in chartData) {
          if(typeof chartData[i] === 'object' && chartData[i] !== null && (i === 'CTR' || i === 'OR')) {
            let data = {};
            data.mode = i;
            data.from = chartData.date_from;
            data.to = chartData.date_to;
            data.labels = [];
            data.datasets = [];
            data.labels = chartData[i].labels;
            if(chartData[i].WP && this.filterCheck('WP')) {
              data.datasets.push({
                label: "WP",
                borderColor: this.domainColors['WP'],
                fill: true,
                backgroundColor: this.domainColors['WP'],
                hoverBorderColor: this.domainColors['WP'],
                borderWidth: 2,
                data: chartData[i].WP.data,
              });
            }
            if(chartData[i].ONET && this.filterCheck('ONET')) {
              data.datasets.push({
                label: "Onet",
                borderColor: this.domainColors['ONET'],
                fill: true,
                backgroundColor: this.domainColors['ONET'],
                hoverBorderColor: this.domainColors['ONET'],
                borderWidth: 2,
                data: chartData[i].ONET.data,
              });
            }
            if(chartData[i].O2 && this.filterCheck('O2')) {
              data.datasets.push({
                label: "O2",
                borderColor: this.domainColors['O2'],
                fill: true,
                backgroundColor: this.domainColors['O2'],
                hoverBorderColor: this.domainColors['O2'],
                borderWidth: 2,
                data: chartData[i].O2.data,
              });
            }
            if(chartData[i].INTERIA && this.filterCheck('INTERIA')) {
              data.datasets.push({
                label: "Interia",
                borderColor: this.domainColors['INTERIA'],
                fill: true,
                backgroundColor: this.domainColors['INTERIA'],
                hoverBorderColor: this.domainColors['INTERIA'],
                borderWidth: 2,
                data: chartData[i].INTERIA.data,
              });
            }
            if(chartData[i].GMAIL && this.filterCheck('GMAIL')) {
              data.datasets.push({
                label: "Gmail",
                borderColor: this.domainColors['GMAIL'],
                fill: true,
                backgroundColor: this.domainColors['GMAIL'],
                hoverBorderColor: this.domainColors['GMAIL'],
                borderWidth: 2,
                data: chartData[i].GMAIL.data,
              });
            }
            if(chartData[i].INNA && this.filterCheck('INNA')) {
              data.datasets.push({
                label: "Inna",
                borderColor: this.domainColors['INNA'],
                fill: true,
                backgroundColor: this.domainColors['INNA'],
                hoverBorderColor: this.domainColors['INNA'],
                borderWidth: 2,
                data: chartData[i].INNA.data,
              });
            }
            var varThis = this;
            data.options = {
              tooltips: tooltipOptions,
              dataset: {
                maxBarThickness: 100,
              },
              scales: {
                xAxes: [{
                  gridLines: {
                    display: true
                  },
                }],
                yAxes: [{
                  gridLines: {
                    display: true
                  },
                  ticks: {
                    min: 0,
                    callback: function (value) {
                      return value + '%';
                    },
                  }
                }]
              },
              legend: {
                display: true
              },
              // onClick:function(e, items){
              //   if ( items.length == 0 ) return; //Clicked outside any bar.

              //   varThis.clickOnChart(e, items);
              // },
            }
            data.options.tooltips.callbacks = {
              label: function(tooltipItem) {
                let label = data.datasets[tooltipItem.datasetIndex].label;
                return label + ": " + Number(tooltipItem.yLabel) + '%';
              },
              afterBody(tooltipItem) {
                let date = tooltipItem[0].xLabel;
                let domain = data.datasets[tooltipItem[0].datasetIndex].label.toUpperCase();
                let multistringText = '';
                if(chartData.banners[domain][date]) {
                  multistringText = [...['Wysłano:' + chartData.SENT[domain][date]], ...['Bannery:'], ...chartData.banners[domain][date]];
                }

                return multistringText;
            }
            };
            domains[i] = data;
          }
        }
        referenceCounter++;
      }

      return domains
    },
    ready() {
      if(this.$store.state.mailing_stats_chart_data.loading_status === 'success') {
        return true
      }
      return false
    },
    resolutionTitle() {
      let resolution = this.resolution;
      if(resolution === 'HOUR') {
        return 'Zakres godzinowy';
      } else if(resolution === 'DAY') {
        return 'Zakres dzienny';
      } else if(resolution === 'WEEK') {
        return 'Zakres tygodniowy';
      } else if(resolution === 'MONTH') {
        return 'Zakres miesięczny';
      } else if(resolution === 'YEAR') {
        return 'Zakres roczny';
      } else return '';
    }
  },
  methods: {
    // clickOnChart(e, items) {
    //   console.log(e, items)
    // },
    filterCheck(param) {
      if(this.choosedDomains.length === 0 || this.choosedDomains.includes(param)) {
         return true
      } else {
         return false
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData() {
      this.loading = true;
      this.$store.commit(MAILING_STATS_CHART_DATA_INVALID);
      let date = this.date;

      let date_from = new Date(date.date_from);
      let date_to = new Date(date.date_to);
      let diff = Math.abs(date_to - date_from)/1000/60/60/24;

      let resolution = '';

      if(diff < 31) {
        resolution = 'DAY';
      } else if(diff >= 31 && diff < 30 * 6) {
        resolution = 'WEEK';
      } else if(diff >= 30 * 6 && diff < 30 * 12) {
        resolution = 'MONTH';
      } else {
        resolution = 'YEAR';
      }

      this.resolution = resolution;

      const filters = Object.assign({}, date, this.filter);
      filters.resolution = resolution;
      filters.mailingType = this.mailingType;

      await this.$store.dispatch(LOAD_MAILING_STATS_CHART_DATA, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
      this.loading = false;
    }
  },
  created() {
    this.counter += 1;
    this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style lang="css" scoped>
.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}
.card {
  margin: 0;
  display: flex;
}
.header-row {
  padding-top: 15px;
  padding-left: 15px;
}
.modal-wrapper {
  position: relative;
}
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
