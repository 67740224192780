<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 v-if="!editMode" class="card-title">Nowy parametr</h4>
        <h4 v-if="editMode" class="card-title">Edycja parametru</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Nazwa</label>
          <fg-input
            type="text"
            name="name"
            data-vv-as="Nazwa"
            v-validate="modelValidations.name"
            :error="getError('name')"
            v-model="model.name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Wartość</label>
          <fg-input
            type="text"
            name="value"
            data-vv-as="Wartość"
            v-validate="modelValidations.value"
            :error="getError('value')"
            v-model="model.value"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Opis</label>
          <fg-input
            type="text"
            name="description"
            data-vv-as="Opis"
            v-validate="modelValidations.description"
            :error="getError('description')"
            v-model="model.description"
          ></fg-input>
        </div>
      </div>
      <div class="card-footer text-center">
        <router-link to="/admin/system_params">
          <p-button type="default" link style="margin-right: 5vw">
            Anuluj
          </p-button>
        </router-link>
        <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { SAVE_SYSTEM_PARAM, SYSTEM_PARAMS_INVALID } from "src/store/actions/admin/system_params";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  data() {
    return {
      model: {
        id: null,
        name: "",
        value: "",
        description: "",
      },
      modelValidations: {
        id: {
          numeric: true,
          min: 1,
        },
        value: {
          max: 255,
        },
        name: {
          required: true,
          regex: regexp.NAME_REGEXP,
          max: 50,
        },
        description: {
          regex: regexp.TEXT_REGEXP,
          max: 512,
        },
      },
      editMode: true,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();

      if (isValid) {
        await this.$store.dispatch(SAVE_SYSTEM_PARAM, this.model).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getSystemParamsModifiedRecords) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
            type: "success",
          });

          this.$store.commit(SYSTEM_PARAMS_INVALID); // oznacz nieaktualność danych
          this.$router.push({ name: "Parametry systemowe" }); // przekieruj do listy
        }
      }
    },
  },
  created() {
    if (!!this.id) {
      let systemParam = this.$store.getters.getSystemParams[this.id];
      this.model = Object.assign(this.model, systemParam);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  },
};
</script>
<style></style>
