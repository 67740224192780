<template>
  <div>
    Pid cfg
  </div>

</template>
<script>
export default {
  data() {
    return {

    };
  },
  components: {},
  computed: {},
  methods: {},
};
</script>
<style scoped>

</style>
