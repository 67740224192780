<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <h5 class="title">Słownik partnerów zgód</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="queriedData" style="width: 100%; font-size: 12px;">
              <el-table-column label="ID" width="80" align="center" property="id">
                <template v-slot="props">
                  <div>{{ props.row.id }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Nazwa" min-width="120" align="center" property="name">
                <template v-slot="props">
                  <div>{{ props.row.name }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Treść" min-width="300" align="left" property="content">
                <template v-slot="props">
                  <div>{{ props.row.content }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="80">
                <template slot="header">
                  <p-button type="success" icon round @click="showModal('add', null)">
                    <i class="fa fa-plus"></i>
                  </p-button>
                </template>
                <template v-slot="props">
                  <p-button
                    type="success"
                    size="sm"
                    icon
                    @click="showModal('edit', props.row)"
                  >
                    <i class="fa fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-body row">
            <div class="col-sm-6 pagination-info">
              <p class="category">Pokazuje od {{from + 1}} do {{to}} z {{total}} pozycji</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
// ui components
import BackToTop from 'vue-backtotop';
import PPagination from 'src/components/UIComponents/Pagination.vue';

// vuex actions
import { LOAD_CONSENTS_PARTNERS_DICT, CONSENTS_PARTNERS_DICT_INVALID } from "src/store/actions/consents/consents_partners_dict";

import ConsentsPartnersDictModal from './ConsentsPartnersDictModal.vue'

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
    PPagination,
    BackToTop
  },
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
    }
  },
  computed: {
    consentsPartnersDict() {
      return this.$store.getters.getConsentsPartnersDict;
    },
    tableData() {
      return Object.values(this.consentsPartnersDict);
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    queriedData () {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.tableData.slice(this.from, this.to)
      }
      let result = this.tableData;

      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_CONSENTS_PARTNERS_DICT)
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
      }
      this.$store.commit(CONSENTS_PARTNERS_DICT_INVALID);

      await this.$store.dispatch(LOAD_CONSENTS_PARTNERS_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    showModal(mode, row) { // wyświetlenie modala edycji partnerów zgód
      this.$modal.show(ConsentsPartnersDictModal, {
        mode: mode,
        row: row,
        buttons: [{
          title: 'Zamknij',
          handler: () => {
            this.$modal.hide(ConsentsPartnersDictModal);
          }
        }]
      }, {
        name: 'ConsentsPartnersDictModal',
        draggable: false,
        width: '60%',
        height: 'auto',
        pivotX: 0.5,
        pivotY: 0.6,
        adaptive: true
      })
    },
  },
  mounted() {
    eventBus.$on("consentsPartnersDictSaved", () => {
      this.loadData(true);
    });
  },
  async created() {
    this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style lang="scss" scoped>
  .highlight {
    font-size: 1.2em;
    font-weight: bold;
  }
  .right-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
</style>
