<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">Przekaż reklamację do</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 center">
          <el-radio-group v-model="model.responder_type" size="small" @change="changeResponder">
            <el-radio-button :label="1">Grupa</el-radio-button>
            <el-radio-button :label="2">Użytkownik</el-radio-button>
            <el-radio-button :label="3" :disabled="true">Partner</el-radio-button>
          </el-radio-group>
        </div>
        <div class="attachment-icon">
          <el-tooltip class="item" effect="light" content="Dodaj załącznik" placement="top" :open-delay="500">
            <div class="action-icon files-icon">
              <i class="fa fa-lg fa-paperclip" aria-hidden="true"></i>
              <input type="file" class="inputfile" id="forwardAttachment" ref="file" multiple v-on:change="handleFileUpload" />
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group" v-if="model.responder_type == 1">
            <label>Grupa</label>
            <fg-input :error="getError('responder_id')" data-vv-as="Grupa">
              <el-select
                v-model="model.responder_id"
                name="responder_id"
                data-vv-as="Grupa"
                v-validate="modelValidations.responder_id"
                filterable
                clearable
              >
                <el-option v-for="group in user_group.group" :label="group.name" :value="group.group_id" :key="group.group_id"></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group" v-if="model.responder_type == 2">
            <label>Użytkownik</label>
            <fg-input :error="getError('responder_id')" data-vv-as="Użytkownik">
              <el-select
                v-model="model.responder_id"
                name="responder_id"
                data-vv-as="Użytkownik"
                v-validate="modelValidations.responder_id"
                filterable
                clearable
              >
                <el-option
                  v-for="user in user_group.user"
                  :label="users[user.system_user_id].user_first_name + ' ' + users[user.system_user_id].user_last_name"
                  :value="user.system_user_id"
                  :key="user.system_user_id"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group" v-if="model.responder_type == 3">
            <label>Partner</label>
            <fg-input :error="getError('responder_id')" data-vv-as="Partner">
              <el-select v-model="model.responder_id" name="responder_id" data-vv-as="Partner" v-validate="modelValidations.id" filterable clearable>
                <el-option
                  v-for="partner in partners"
                  :label="partner.partner_name"
                  :value="partner.partner_id"
                  :key="partner.partner_id"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group">
            <label>Wiadomość</label>
            <fg-input
              type="text"
              name="message"
              data-vv-as="Wiadomość"
              v-validate="modelValidations.message"
              :error="getError('message')"
              v-model="model.message"
            ></fg-input>
          </div>

          <div v-if="fileNames.length > 0">
            <hr />
            <div>Dodane załączniki:</div>
            <div v-for="(file, index) in fileNames" :key="index">
              <span class="response_files" @click="deleteSubmitedFile(index)">
                <i :class="getFileClass(files[index].type)" aria-hidden="true"></i>
                <span style="padding-left: 10px">{{ file }}</span>
              </span>
            </div>
          </div>

          <div class="row">
            <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip, RadioGroup, RadioButton } from "element-ui";

import { SAVE_UPLOAD_COMPLAINT_FLOW_FILES } from "src/store/actions/complaints/actions/upload_complaint_flow_files";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
  },
  props: ["complaint_id", "users", "partners", "user_group", "mailbox", "request"],
  data() {
    return {
      model: {
        complaint_id: this.complaint_id,
        responder_type: 1,
        responder_id: null,
        message: "",
        mailbox: this.mailbox,
        request: this.request,
      },
      modelValidations: {
        id: {
          required: true,
          numeric: true,
          min: 1,
        },
        responder_type: {
          required: true,
        },
        responder_id: {
          required: true,
        },
        message: {
          // regex: regexp.TEXT_REGEXP,
          required: true,
          min: 1,
          max: 1024,
        },
      },
      files: [],
      fileNames: [],
    };
  },
  computed: {
    usersList() {
      const data = Object.values(this.users).filter((row) => row.user_user_type !== null && row.user_user_type.includes("MM"));
      return data;
    },
    responderEmail() {
      const result = [];
      if (this.model.responder_id) {
        if (this.model.responder_type === 1) {
          let filteredUsers = this.user_group.user.filter((x) => x.group_id === this.model.responder_id);
          filteredUsers.forEach((x) => result.push(this.users[x.system_user_id].user_email));
        } else if (this.model.responder_type === 2) {
          result.push(this.users[this.model.responder_id].user_email);
        }
      }
      return result;
    },
  },
  methods: {
    changeResponder(label) {
      this.model.responder_id = null;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        let files = [];

        if (this.files.length > 0) {
          // send response files to server
          let submitResult = await this.submitResponseFile();
          console.log(submitResult);
          files = submitResult;

          if (!submitResult) {
            return;
          }
        }

        const model = { ...this.model, responders: this.responderEmail, files: files };
        eventBus.$emit("handleForward", {
          forward: model,
        });
        this.$emit("close");
      }
    },
    async submitResponseFile() {
      const FILES_SIZE_LIMIT = 1024 * 1024 * 25; // 25Mb
      let files_size = 0;
      let result = true;

      this.files.forEach((file) => {
        files_size += file.size;
      });

      if (files_size > FILES_SIZE_LIMIT) {
        eventBus.$emit("notify", {
          message: "Rozmiar załączników przekracza dozwoloną wartość (> 25MB)",
          type: "warning",
        });
        return;
      }

      // Initialize the form data
      let formData = new FormData();
      // Add the form data we need to submit
      this.files.forEach((file, index) => {
        formData.append("file" + "_" + index, file);
      });

      await this.$store.dispatch(SAVE_UPLOAD_COMPLAINT_FLOW_FILES, formData).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu załącznika!",
          type: "warning",
        });
        result = false;
      });

      result = this.$store.getters.getUploadComplaintFlowFilesModifiedRecords;

      if (result) {
        this.files = [];
        this.fileNames = [];
      }

      return result;
    },
    handleFileUpload(e) {
      var files = e.target.files;
      if (!files.length) return;
      Array.from(files).forEach((file) => {
        this.files.push(file);
        this.fileNames.push(file.name);
        console.log(file.type);
      });
      document.getElementById("forwardAttachment").value = null;
    },
    deleteSubmitedFile(index) {
      this.files.splice(index, 1);
      this.fileNames.splice(index, 1);
    },
    getFileClass(type) {
      switch (type) {
        case "application/pdf":
          return "fa fa-file-pdf-o";
        case "text/plain":
          return "fa fa-file-text-o";
        case "image/gif":
          return "fa fa-file-image-o";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "fa fa-file-excel-o";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "fa fa-file-word-o";
        default:
          return "fa fa-file-o";
      }
    },
  },
  async created() {},
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}

.attachment-icon {
  margin-top: 5px;
  right: 30px;
  position: absolute;
}

.files-icon {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.files-icon input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.response_files {
  font-weight: bold;
}

.response_files:hover {
  text-decoration: line-through 2px #dc3545;
  cursor: pointer;
}
</style>
