import storeGen from '@/store/storeGenerator'
import {
    AUTH_LOGOUT
} from '@/store/actions/auth'

import {
    BASE_API
} from '../../config'

import {
  ERROR_KO_QUESTIONS,
  GET_KO_QUESTIONS,
  GET_KO_QUESTIONS_OBJECT,
  INVALID_KO_QUESTIONS,
  LOAD_KO_QUESTIONS,
  LOADING_KO_QUESTIONS,SAVING_KO_QUESTIONS,
  SAVE_KO_QUESTIONS, SAVING_ERROR_KO_QUESTIONS, SAVING_SUCCESS_KO_QUESTIONS,
  SUCCESS_KO_QUESTIONS

} from "@/store/actions/korejestracja/ko_questions";

const storeObjectConfig = {
    objectName: GET_KO_QUESTIONS_OBJECT,
    getterName: GET_KO_QUESTIONS,
    loadActionName: LOAD_KO_QUESTIONS,
    loadingMutationName: LOADING_KO_QUESTIONS,
    loadingSuccessMutationName: SUCCESS_KO_QUESTIONS,
    loadingErrorMutationName: ERROR_KO_QUESTIONS,
    invalidMutationName: INVALID_KO_QUESTIONS,
    saveActionName: SAVE_KO_QUESTIONS,
    savingMutationName: SAVING_KO_QUESTIONS,
    savingSuccessMutationName: SAVING_SUCCESS_KO_QUESTIONS,
    savingErrorMutationName: SAVING_ERROR_KO_QUESTIONS,
    logoutMutationName: AUTH_LOGOUT,
    apiUrl: BASE_API,
    validPeriod: 10, // minutes
    recordType: 'json',
    loadParams: {
        url: "/kosend/koQuestions",
        method: "get" // {'get','post','put'}
    },
    saveParams: {
        url: "/sales/userFromComplaints",
        method: "post" // {'get','post','put'}
    }
}

const state = storeGen.stateGenerator(storeObjectConfig);// stan stora
const getters = storeGen.gettersGenerator(storeObjectConfig);//pobiera dane ze stora
const actions = storeGen.actionsGenerator(storeObjectConfig);//poberanie dany do stora async
const mutations = storeGen.mutationsGenerator(storeObjectConfig);//zmieniają stan stora

export default {
    state,
    getters,
    actions,
    mutations
}
