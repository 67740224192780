<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-5">
        <h5 class="card-title">Raport sprzedaży</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-2 vertical-center">
        <el-switch v-model="raportTypeSells" active-text="Sprzedaż" inactive-text="Przychód" active-color="#17a2b8" inactive-color="#28a745">
        </el-switch>
      </div>
      <div class="col-sm-12 text-sm-left col-md-3 vertical-center">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="-"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd"
          :default-value="defaultDate"
        >
        </el-date-picker>
      </div>
      <div class="col-sm-3 col-md-1">
        <p-button type="success" @click="loadData('show')">Pokaż</p-button>
      </div>
      <div class="col-sm-3 col-md-1">
        <p-button type="success" @click="loadData('excel')">Excel</p-button>
      </div>
    </div>

    <div class="card" v-if="Object.values(salesRaportSum).length > 0">
      <div class="card-header">
        <h5>Podsumowanie</h5>
      </div>
      <div class="card-body">
        <el-table
          :data="Object.values(salesRaportSum.Clients)"
          :cell-style="colStyle"
          :header-cell-style="headerStyle"
          size="mini"
          style="width: 100%"
        >
          <el-table-column fixed label="Kanał" min-width="300" property="ClientID">
            <template v-slot="props">
              <span v-if="props.row.ClientID !== null">{{ "[" + props.row.ClientID + "] " + props.row.Client }}</span>
              <span v-else>{{ props.row.Client }}</span>
            </template>
          </el-table-column>
          <el-table-column v-for="date in dateArray" :key="date" min-width="80" align="center">
            <template slot="header">
              {{ showArrayObj[date] }}
            </template>
            <template v-slot="props">
              <span>{{
                showArrayObj[date] === "Wszystkie"
                  ? formatNumber(props.row.Wszystkie[raportTypeSells ? "Sells" : "Income"])
                  : formatNumber(props.row.Days[date][raportTypeSells ? "Sells" : "Income"])
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div v-for="raport in salesRaport" :key="raport.ProjectID">
      <div class="card">
        <div class="card-header">
          <h5>
            {{ "[" + raport.ProjectID + "] " + raport.Project }}
          </h5>
        </div>
        <div class="card-body">
          <el-table :data="Object.values(raport.Clients)" :cell-style="colStyle" style="width: 100%" :header-cell-style="headerStyle" size="mini">
            <el-table-column fixed label="Kanał" min-width="300" property="ClientID">
              <template v-slot="props">
                <span v-if="props.row.ClientID !== null">{{ "[" + props.row.ClientID + "] " + props.row.Client }}</span>
                <span v-else>{{ props.row.Client }}</span>
              </template>
            </el-table-column>
            <el-table-column v-for="date in dateArray" :key="date" min-width="80" align="center">
              <template slot="header">
                {{ showArrayObj[date] }}
              </template>
              <template v-slot="props">
                <span>{{
                  showArrayObj[date] === "Wszystkie"
                    ? formatNumber(props.row.Wszystkie[raportTypeSells ? "Sells" : "Income"])
                    : formatNumber(props.row.Days[date][raportTypeSells ? "Sells" : "Income"])
                }}</span>
                <span v-if="incomeTag(date, showArrayObj[date], props.row)"><el-tag type="danger" effect="dark" size="small">!</el-tag></span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";

import { DatePicker, Tooltip, Switch, Tag } from "element-ui";

import { LOAD_SALES_RAPORT, SALES_RAPORT_INVALID } from "src/store/actions/secure/sales_raport";
import { LOAD_SALES_RAPORT_EXCEL, SALES_RAPORT_EXCEL_INVALID } from "src/store/actions/secure/sales_raport_excel";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Tooltip.name]: Tooltip,
    [Switch.name]: Switch,
    [Tag.name]: Tag,
  },
  data() {
    return {
      loading: false,
      dateRange: ["", ""],
      dateArray: [],
      raportTypeSells: true,
    };
  },
  methods: {
    async loadData(mode) {
      this.createDateArray();
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }
      if (!this.checkDateRange()) {
        eventBus.$emit("notify", {
          message: "Zakres dat musi obejmować jeden miesiąc!",
          type: "warning",
        });
        return;
      }
      this.loading = true;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
      };

      this.$store.commit(mode === "excel" ? SALES_RAPORT_EXCEL_INVALID : SALES_RAPORT_INVALID);

      await this.$store.dispatch(mode === "excel" ? LOAD_SALES_RAPORT_EXCEL : LOAD_SALES_RAPORT, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      if (mode === "excel") {
        const tmpName = this.$store.getters.getSalesRaportExcel.tmpname;
        const fileName = this.$store.getters.getSalesRaportExcel.name;
        this.downloadFile(tmpName, fileName);
      }

      this.loading = false;
    },
    colStyle({ row, columnIndex }) {
      const columnID = this.dateArray.length;
      if (row.Client === "Wszystkie" || columnIndex === columnID) {
        return "background-color: #f2fcff; font-weight: bold;";
      }
    },
    formatNumber(number, decimals = 0) {
      if (!this.raportTypeSells) {
        decimals = 2;
      }
      if (number === null) {
        return this.raportTypeSells ? 0 : 0 + " zł";
      }
      let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number).toFixed(decimals).split(".");
      n[0] = n[0]
        .split("")
        .reverse()
        .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
        .reverse()
        .join("");
      let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

      return this.raportTypeSells ? final : final + " zł";
    },
    createDateArray() {
      const firstDay = new Date(this.dateRange[0]);
      const lastDay = new Date(this.dateRange[1]);
      let nextDay = new Date(moment(firstDay, "YYYY-MM-DD").add(1, "days"));
      const dateArr = [];
      dateArr.push(moment(firstDay).format("YYYY-MM-DD"));
      while (nextDay.getTime() <= lastDay.getTime()) {
        dateArr.push(moment(nextDay).format("YYYY-MM-DD"));
        nextDay = new Date(moment(nextDay, "YYYY-MM-DD").add(1, "days"));
      }
      dateArr.push("Wszystkie");
      this.dateArray = dateArr;
    },
    getfirstDayOfWeek(dateObject, firstDayOfWeekIndex) {
      const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff = dayOfWeek >= firstDayOfWeekIndex ? dayOfWeek - firstDayOfWeekIndex : 6 - dayOfWeek;

      firstDayOfWeek.setDate(dateObject.getDate() - diff);
      firstDayOfWeek.setHours(0, 0, 0, 0);

      return firstDayOfWeek;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-contract.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    checkDateRange() {
      const startMonth = new Date(this.dateRange[0]).getMonth();
      const endMonth = new Date(this.dateRange[1]).getMonth();
      if (startMonth !== endMonth) {
        return false;
      } else {
        return true;
      }
    },
    incomeTag(date, header, row) {
      if (header === "Wszystkie") {
        if (row.Wszystkie.Income === null) {
          return true;
        } else {
          return false;
        }
      } else {
        if (row.Days[date].Income === null) {
          return true;
        } else {
          return false;
        }
      }
    },
    headerStyle(obj) {
      return "font-size: 12px;";
    },
  },
  computed: {
    salesRaport() {
      let data = {};
      if (this.$store.state.sales_raport.loading_status === "success") {
        data = JSON.parse(JSON.stringify(this.$store.getters.getSalesRaport));
        delete data.Wszystkie;
        for (const project in data) {
          for (const client in data[project].Clients) {
            const clientObj = data[project].Clients[client];
            this.dateArray.map((x) => {
              if (!Object.keys(clientObj.Days).includes(x)) {
                clientObj.Days[x] = {
                  Income: 0,
                  Sells: 0,
                  SendDays: x,
                };
              }
            });
          }
          const projectArray = [];
          let tempClient = data[project].Clients;
          let projectSummary = tempClient.Wszystkie;
          delete tempClient.Wszystkie;
          let tempArray = Object.values(tempClient);
          tempArray.sort((a, b) => (a.Client.toLowerCase() > b.Client.toLowerCase() ? 1 : -1));
          tempArray.map((x) => projectArray.push(x));
          tempArray.push(projectSummary);
          data[project].Clients = tempArray;
        }
      }
      return data;
    },
    salesRaportSum() {
      let result = { Clients: {}, Project: "Wszystkie", ProjectID: null };
      if (this.$store.state.sales_raport.loading_status === "success") {
        let data = JSON.parse(JSON.stringify(this.$store.getters.getSalesRaport)).Wszystkie;
        if (data && data.Clients) {
          for (const client in data.Clients) {
            this.dateArray.map((x) => {
              if (!Object.keys(data.Clients[client].Days).includes(x)) {
                data.Clients[client].Days[x] = {
                  Income: 0,
                  Sells: 0,
                  SendDays: x,
                };
              }
            });
          }
          const projectArray = [];
          let tempClient = data.Clients;
          let projectSummary = tempClient.Wszystkie;
          delete tempClient.Wszystkie;
          let tempArray = Object.values(tempClient);
          tempArray.sort((a, b) => (a.Client.toLowerCase() > b.Client.toLowerCase() ? 1 : -1));
          tempArray.map((x) => projectArray.push(x));
          tempArray.push(projectSummary);
          data.Clients = tempArray;
          result = data;
        }
      }
      return result;
    },
    pickerOptions() {
      const today = utils.today();
      const firstDayOfWeek = this.getfirstDayOfWeek(today, 1);

      const shortcuts = [
        {
          text: "Dzisiaj",
          onClick(picker) {
            const end = utils.today();
            const start = utils.today();
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Ostatni tydzień",
          onClick(picker) {
            const end = utils.today();
            const start = utils.today();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Aktualny miesiąc",
          onClick(picker) {
            const end = utils.today();
            const start = utils.today();
            start.setDate(1);
            end.setHours(23, 59, 59);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Poprzedni miesiąc",
          onClick(picker) {
            const end = utils.today();
            const start = utils.today();
            start.setDate(1);
            start.setMonth(start.getMonth() - 1);
            end.setDate(0);
            end.setHours(23, 59, 59);
            picker.$emit("pick", [start, end]);
          },
        },
      ];
      return { firstDayOfWeek: 1, shortcuts };
    },
    showArrayObj() {
      const dateArray = this.dateArray;
      const objArray = {};
      dateArray.map((x) => {
        if (x !== "Wszystkie") {
          objArray[x] = moment(x).format("MM-DD");
        } else {
          objArray[x] = x;
        }
      });
      return objArray;
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
  },
  mounted() {},
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    this.$store.commit(SALES_RAPORT_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.percentageValue {
  color: #383838;
  font-weight: bold;
}

.weekHeader {
  padding: 15px 15px 5px !important;
}

.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
