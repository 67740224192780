export const LOAD_HOTJAR_CONFIG = 'LOAD_HOTJAR_CONFIG'
export const HOTJAR_CONFIG_LOADING = 'HOTJAR_CONFIG_LOADING'
export const HOTJAR_CONFIG_LOADING_SUCCESS = 'HOTJAR_CONFIG_LOADING_SUCCESS'
export const HOTJAR_CONFIG_LOADING_ERROR = 'HOTJAR_CONFIG_LOADING_ERROR'
export const HOTJAR_CONFIG_INVALID = 'HOTJAR_CONFIG_INVALID'

export const SAVE_HOTJAR_CONFIG = 'SAVE_HOTJAR_CONFIG'
export const HOTJAR_CONFIG_SAVING = 'HOTJAR_CONFIG_SAVING'
export const HOTJAR_CONFIG_SAVING_SUCCESS = 'HOTJAR_CONFIG_SAVING_SUCCESS'
export const HOTJAR_CONFIG_SAVING_ERROR = 'HOTJAR_CONFIG_SAVING_ERROR'
