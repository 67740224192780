<template>
  <div>
    <div class="row">
      <div class="col-sm-11">
        <h5 class="title">Endpointy</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body row">
              <div class="col-sm-6">
                  <el-select
                    class="select-default"
                    v-model="pagination.perPage"
                    placeholder="Per page">
                    <el-option
                      class="select-default"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-sm-6">
                  <div class="pull-right">
                    <fg-input class="input-sm"
                              placeholder="Szukaj"
                              v-model="searchQuery"
                              addon-right-icon="nc-icon nc-zoom-split">
                    </fg-input>
                  </div>
                </div>
              </div>
            <el-table :data="queriedData" :default-sort = "{prop: 'endpoint_add_time', order: 'ascending'}">
              <el-table-column type="expand">
                <template v-slot="props">
                  <p>Utworzony: {{ props.row.endpoint_add_time }}</p>
                  <p>Opis: {{ props.row.endpoint_description }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Nazwa" property="endpoint_name" min-width="120" sortable></el-table-column>
              <el-table-column label="Aktywny" align="center" width="140" sortable>
                <template v-slot="props">
                  <fg-input>
                    <el-switch
                      :value="props.row.endpoint_is_active"
                      active-value="true"
                      inactive-value="false"
                      active-color="#6bd098"
                    ></el-switch>
                  </fg-input>
                </template>
              </el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="80">
                <template slot="header">
                  <router-link to="/admin/endpoint_form">
                    <p-button type="success" icon round>
                      <i class="fa fa-plus"></i>
                    </p-button>
                  </router-link>
                </template>
                <template v-slot="props">
                  <p-button
                    type="success"
                    size="sm"
                    icon
                    @click="handleEdit(props.$index, props.row)"
                  >
                    <i class="fa fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-body row">
            <div class="col-sm-6 pagination-info">
              <p class="category">Pokazuje od {{from + 1}} do {{to}} z {{total}} pozycji</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

// ui components
import { Table, TableColumn, Select, Option, Switch } from "element-ui";
import { Tabs, TabPane, Card } from 'src/components/UIComponents'
import PPagination from 'src/components/UIComponents/Pagination.vue';

// vuex actions
import { LOAD_ENDPOINTS, ENDPOINTS_INVALID } from "src/store/actions/admin/endpoints";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';

Vue.use(Table);
Vue.use(TableColumn);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    PPagination,
    TabPane,
    Tabs,
    Card
  },
  data() {
    return {
      pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
      searchQuery: '',
      propsToSearch: ['endpoint_name'],
      checkboxes: {
        partners: true,
        sites: false,
      }
    }
  },
  computed: {
    tableData() {
      return Object.values(this.$store.getters.getEndpoints);
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN)
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    queriedData () {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.pagedData
      }
      let result = this.tableData
        .filter((row) => {
          let isIncluded = false
          for (let key of this.propsToSearch) {
            let rowValue = row[key].toString()
            if (rowValue.includes && rowValue.includes(this.searchQuery)) {
              isIncluded = true
            }
          }
          return isIncluded
        })
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  methods: {
    loadData(force) {
      const filters = {}

      if (force) {
        this.$store.commit(ENDPOINTS_INVALID);
      }

      this.$store.dispatch(LOAD_ENDPOINTS, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    handleEdit(index, row) {
      this.$router.push("/admin/endpoint_form/" + row.endpoint_id);
    }
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style>
</style>
