import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_EXPORT_MAILING_STATS,
  EXPORT_MAILING_STATS_LOADING,
  EXPORT_MAILING_STATS_LOADING_SUCCESS,
  EXPORT_MAILING_STATS_LOADING_ERROR,
  EXPORT_MAILING_STATS_INVALID,
} from "src/store/actions/stats/export_mailing_stats";

const storeObjectConfig = {
  objectName: "MailingStats",
  getterName: "getExportMailingStats",
  loadActionName: LOAD_EXPORT_MAILING_STATS,
  loadingMutationName: EXPORT_MAILING_STATS_LOADING,
  loadingSuccessMutationName: EXPORT_MAILING_STATS_LOADING_SUCCESS,
  loadingErrorMutationName: EXPORT_MAILING_STATS_LOADING_ERROR,
  invalidMutationName: EXPORT_MAILING_STATS_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/stats/mailingStats/export_mailing_stats",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
