import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_PERMISSIONS,
  PERMISSIONS_LOADING,
  PERMISSIONS_LOADING_SUCCESS,
  PERMISSIONS_LOADING_ERROR,
  PERMISSIONS_INVALID,
  SAVE_PERMISSION,
  PERMISSION_SAVING,
  PERMISSION_SAVING_SUCCESS,
  PERMISSION_SAVING_ERROR
} from 'src/store/actions/admin/permissions'

const storeObjectConfig = {
  objectName: "Permissions",
  getterName: "getPermissions",
  loadActionName: LOAD_PERMISSIONS,
  loadingMutationName: PERMISSIONS_LOADING,
  loadingSuccessMutationName: PERMISSIONS_LOADING_SUCCESS,
  loadingErrorMutationName: PERMISSIONS_LOADING_ERROR,
  invalidMutationName: PERMISSIONS_INVALID,
  saveActionName: SAVE_PERMISSION,
  savingMutationName: PERMISSION_SAVING,
  savingSuccessMutationName: PERMISSION_SAVING_SUCCESS,
  savingErrorMutationName: PERMISSION_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/permissions",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/permissions",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
