import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_QUIZ,
  QUIZ_LOADING,
  QUIZ_LOADING_SUCCESS,
  QUIZ_LOADING_ERROR,
  QUIZ_INVALID,
  SAVE_QUIZ,
  QUIZ_SAVING,
  QUIZ_SAVING_SUCCESS,
  QUIZ_SAVING_ERROR
} from 'src/store/actions/raports/quiz'

const storeObjectConfig = {
  objectName: "Quiz",
  getterName: "getQuiz",
  loadActionName: LOAD_QUIZ,
  loadingMutationName: QUIZ_LOADING,
  loadingSuccessMutationName: QUIZ_LOADING_SUCCESS,
  loadingErrorMutationName: QUIZ_LOADING_ERROR,
  invalidMutationName: QUIZ_INVALID,
  saveActionName: SAVE_QUIZ,
  savingMutationName: QUIZ_SAVING,
  savingSuccessMutationName: QUIZ_SAVING_SUCCESS,
  savingErrorMutationName: QUIZ_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/quiz",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
