<template>
  <div>
    <div class="row">
      <div class="col-sm-8">
        <h5 id="postcodesDictTitle" class="title">Kody pocztowe</h5>
      </div>
      <div class="col-sm-4 text-right">
        <p-button id="postcodesDictAddButton" v-if="editPermitted" type="success" size="sm" @click="showModal">Dodaj plik</p-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column label="ID" width="100" sortable property="id"></el-table-column>
              <el-table-column label="Nazwa" min-width="120" sortable property="name" align="center"></el-table-column>
              <el-table-column label="Grupa" min-width="120" sortable property="group_name" align="center"></el-table-column>
              <el-table-column label="Ilość" min-width="100" sortable property="group_name" align="center">
                <template v-slot="props">
                  <div :id="'postcodesDictCountRow_' + props.row.id">
                    {{ postCodesCount[props.row.id] }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
// ui components
import { Tag, Tooltip } from "element-ui";
import BackToTop from 'vue-backtotop';
import PPagination from 'src/components/UIComponents/Pagination.vue';

import PostcodesForm from "src/components/Utils4/dictionaries/PostcodesForm.vue";

// vuex actions
import { LOAD_POSTCODES, SAVE_POSTCODES, POSTCODES_INVALID} from "src/store/actions/questionForm/postcodes";
import { LOAD_POSTCODES_COUNT, POSTCODES_COUNT_INVALID } from "src/store/actions/questionForm/postcodes_count";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Tag.name]: Tag,
    [Tooltip.name]: Tooltip,
    PPagination,
    BackToTop
  },
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      // searchQuery: '',
      // propsToSearch: ['id', 'category_name']
    }
  },
  computed: {
    postCodesCount() {
      return this.$store.getters.getPostcodesCount;
    },
    tableData() {
      let data = Object.values(this.$store.getters.getPostcodes);
      let result = [];
      data.map(el => {
        for(let i in el) {
          result.push(el[i])
        }
      })
      return result
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    queriedData () {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.tableData.slice(this.from, this.to)
      }
      let result = this.tableData
        .filter((row) => {
          let isIncluded = false
          for (let key of this.propsToSearch) {
            let rowValue = row[key].toString();
            let rowValueLowerCase = row[key].toString().toLowerCase();
            if ((rowValue.includes && rowValue.includes(this.searchQuery)) || rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery)) {
              isIncluded = true
            }
          }

          return isIncluded
        })

      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_POSTCODES_DICT)
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(POSTCODES_INVALID);
        this.$store.commit(POSTCODES_COUNT_INVALID);
      }
      this.$store.dispatch(LOAD_POSTCODES_COUNT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      await this.$store.dispatch(LOAD_POSTCODES, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
    },
    showModal(mode, row) { // wyświetlenie modala uploadu pliku
      this.$modal.show(PostcodesForm, {
        buttons: [{
          title: 'Zamknij',
          handler: () => {
            this.$modal.hide(PostcodesForm);
          }
        }]
      }, {
        draggable: false,
        width: '50%',
        height: 'auto',
        pivotX: 0.5,
        pivotY: 0.6,
        adaptive: true
      })
    },
  },
  mounted() {
    eventBus.$on('postcodesSaved', () => { // zapis pliku
      this.loadData();
    });
  },
  async created() {
    await this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style lang="scss" scoped>
  .parentCat {
    font-weight: bold;
    font-size: 1.2em;
  }
  .subcat {
    padding-left: 30px;
    font-style: italic;
  }
</style>
