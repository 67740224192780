<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 v-if="!editMode" class="card-title">Nowa rola</h4>
        <h4 v-if="editMode" class="card-title">Edycja roli</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Nazwa systemu</label>
          <fg-input :error="getError('system_name')" data-vv-as="Nazwa systemu">
            <el-select
              v-model="model.role_system_id"
              name="system_name"
              data-vv-as="Nazwa systemu"
              v-validate="modelValidations.system_name"
              filterable
            >
              <el-option
                v-for="system in systems"
                :label="system.system_name"
                :value="system.system_id"
                :key="system.system_id"
              ></el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="form-group">
          <label>Nazwa Roli</label>
          <fg-input
            type="text"
            name="role_name"
            data-vv-as="Nazwa roli"
            v-validate="modelValidations.role_name"
            :error="getError('role_name')"
            v-model="model.role_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Opis roli</label>
          <fg-input
            type="text"
            name="role_description"
            data-vv-as="Opis roli"
            v-validate="modelValidations.role_description"
            :error="getError('role_description')"
            v-model="model.role_description"
          ></fg-input>
        </div>

      </div>
      <div class="card-footer text-center">
        <router-link to="/admin/roles">
          <p-button type="default" link style="margin-right: 5vw">
            Anuluj
          </p-button>
        </router-link>
        <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Select, Option, Switch } from "element-ui";

import { SAVE_ROLE, ROLES_INVALID } from "src/store/actions/admin/roles";
import { LOAD_SYSTEMS } from "src/store/actions/admin/systems";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  data() {
    return {
      model: {
        role_id: null,
        role_system_id: null,
        role_name: "",
        role_description: "",
        system_name: ""
      },
      modelValidations: {
        role_id: {
          numeric: true,
          min: 1
        },
        role_system_id: {
          numeric: true,
          required: true,
          min: 1
        },
        role_name: {
          required: true,
          // regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .-]+)$",
          regex: regexp.NAME_REGEXP,
          min: 3,
          max: 32
        },
        role_description: {
          // regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .-]+)$",
          regex: regexp.TEXT_REGEXP,
          max: 64
        },
      },
      editMode: true
    };
  },
  computed: {
    role_id() {
      return this.$route.params.id;
    },
    systems() {
      // table data needs to by an array
      return this.$store.getters.getSystems;
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch(LOAD_SYSTEMS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll()

      if (isValid) {
        await this.$store.dispatch(SAVE_ROLE, this.model).catch(error => {
          let message = ''
          console.log(error.response)
          switch(error.response.data.code) {
            case 409:
              message = 'Nazwy muszą być unikalne!'
              break;
            default:
              message = "Błąd zapisu danych!"
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning"
          });
        })

        if (this.$store.getters.getRolesModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
            type: "success"
          });
        this.$store.commit(ROLES_INVALID) // oznacz nieaktualność danych
        this.$router.push({ name: "Role" }) // przekieruj do listy
        }
      }
    }
  },
  created() {
    this.loadData();
    if (!!this.role_id) {
      let role = this.$store.getters.getRoles[this.role_id];
      this.model = Object.assign(this.model, role);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  }
};
</script>
<style>
</style>


