export default {
  today: () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  },
  deepCopy: (obj) => {
    return JSON.parse(JSON.stringify(obj));
  },
  formatNumber(number, decimals = 0) {
    if (typeof number !== "number") return 0;
    let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
    let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

    let n = Math.abs(number).toFixed(decimals).split(".");
    n[0] = n[0]
      .split("")
      .reverse()
      .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
      .reverse()
      .join("");
    let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

    return final;
  },
};
