export const LOAD_DEDUPLICATION = "LOAD_DEDUPLICATION";
export const DEDUPLICATION_LOADING = "DEDUPLICATION_LOADING";
export const DEDUPLICATION_LOADING_SUCCESS = "DEDUPLICATION_LOADING_SUCCESS";
export const DEDUPLICATION_LOADING_ERROR = "DEDUPLICATION_LOADING_ERROR";
export const DEDUPLICATION_INVALID = "DEDUPLICATION_INVALID";

export const SAVE_DEDUPLICATION = "SAVE_DEDUPLICATION";
export const DEDUPLICATION_SAVING = "DEDUPLICATION_SAVING";
export const DEDUPLICATION_SAVING_SUCCESS = "DEDUPLICATION_SAVING_SUCCESS";
export const DEDUPLICATION_SAVING_ERROR = "DEDUPLICATION_SAVING_ERROR";

export const DELETE_DEDUPLICATION = "DELETE_DEDUPLICATION";
export const DEDUPLICATION_DELETING = "DEDUPLICATION_DELETING";
export const DEDUPLICATION_DELETING_SUCCESS = "DEDUPLICATION_DELETING_SUCCESS";
export const DEDUPLICATION_DELETING_ERROR = "DEDUPLICATION_DELETING_ERROR";
