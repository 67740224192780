import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_DASHBOARD_STATS,
  DASHBOARD_STATS_LOADING,
  DASHBOARD_STATS_LOADING_SUCCESS,
  DASHBOARD_STATS_LOADING_ERROR,
  DASHBOARD_STATS_INVALID,
  SAVE_DASHBOARD_STATS,
  DASHBOARD_STATS_SAVING,
  DASHBOARD_STATS_SAVING_SUCCESS,
  DASHBOARD_STATS_SAVING_ERROR
} from 'src/store/actions/stats/dashboard_stats'

const storeObjectConfig = {
  objectName: "DashboardStats",
  getterName: "getDashboardStats",
  loadActionName: LOAD_DASHBOARD_STATS,
  loadingMutationName: DASHBOARD_STATS_LOADING,
  loadingSuccessMutationName: DASHBOARD_STATS_LOADING_SUCCESS,
  loadingErrorMutationName: DASHBOARD_STATS_LOADING_ERROR,
  invalidMutationName: DASHBOARD_STATS_INVALID,
  saveActionName: SAVE_DASHBOARD_STATS,
  savingMutationName: DASHBOARD_STATS_SAVING,
  savingSuccessMutationName: DASHBOARD_STATS_SAVING_SUCCESS,
  savingErrorMutationName: DASHBOARD_STATS_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/stats/dashboardStats",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
