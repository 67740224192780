<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label>Tło paska bocznego</label>
            <fg-input>
              <el-select v-model="model.json_config.backgroundColor" v-validate="modelValidations.backgroundColor" name="backgroundColor" filterable>
                <el-option
                  :style="'background-color:' + color.name"
                  v-for="color in sidebarDict.backgroundColor"
                  :label="color.label"
                  :value="color.name"
                  :key="color.name"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-6">
            <label>Motyw paska bocznego</label>
            <fg-input>
              <el-select v-model="model.json_config.activeColor" v-validate="modelValidations.activeColor" name="activeColor" filterable>
                <el-option
                  :style="'color:' + color.color"
                  v-for="color in sidebarDict.activeColor"
                  :label="color.label"
                  :value="color.name"
                  :key="color.name"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div v-if="testsPermitted">
          <br />
          <label>Wyniki testów automatycznych</label>
          <br />
          <div class="row">
            <div class="col-md-12">
              <el-switch
                name="showTestsResults"
                v-model="model.json_config.testsResults"
                active-text="Wyświetlaj"
                active-color="#28a745"
                inactive-text="Nie wyświetlaj"
                inactive-color="#dc3545"
              ></el-switch>
            </div>
          </div>
        </div>
        <div v-if="statsPermitted">
          <br />
          <label>Korejestracja</label>
          <br />
          <div class="row">
            <div class="col-md-12">
              <el-switch
                name="showQuestionsEfficiency"
                v-model="model.json_config.questionsEfficiency"
                active-text="Wyświetlaj"
                active-color="#28a745"
                inactive-text="Nie wyświetlaj"
                inactive-color="#dc3545"
              ></el-switch>
            </div>
          </div>
        </div>
        <div v-if="complaintsPermitted">
          <br />
          <label>Reklamacje</label>
          <br />
          <div class="row">
            <div class="col-md-12">
              <el-switch
                name="showComplaints"
                v-model="model.json_config.complaints"
                active-text="Wyświetlaj"
                active-color="#28a745"
                inactive-text="Nie wyświetlaj"
                inactive-color="#dc3545"
              ></el-switch>
            </div>
          </div>
        </div>
        <div v-if="statsPermitted">
          <br />
          <label>Raporty zdarzeń</label>
          <br />
          <div class="row">
            <div class="col-md-12">
              <el-switch
                name="showEventsRaport"
                v-model="model.json_config.eventsRaport"
                active-text="Wyświetlaj"
                active-color="#28a745"
                inactive-text="Nie wyświetlaj"
                inactive-color="#dc3545"
              ></el-switch>
            </div>
          </div>
        </div>
        <div v-if="mailingStatsPermitted">
          <br />
          <label>Ilość wysłanych maili</label>
          <br />
          <div class="row">
            <div class="col-md-12">
              <el-switch
                name="showMailsSendProvider"
                v-model="model.json_config.mailsSendProvider"
                active-text="Wyświetlaj"
                active-color="#28a745"
                inactive-text="Nie wyświetlaj"
                inactive-color="#dc3545"
              ></el-switch>
            </div>
          </div>
        </div>
        <div v-if="statsPermitted">
          <br />
          <label>Statystyki pytań</label>
          <div class="row">
            <div class="col-md-12">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Pozycja</th>
                    <th scope="col">Pytanie</th>
                    <th scope="col">Projekt</th>
                    <th scope="col">Wersja</th>
                    <th scope="col">
                      <p-button type="success" icon round @click="handleEdit('questions')">
                        <i class="fa fa-plus"></i>
                      </p-button>
                    </th>
                  </tr>
                </thead>
                <draggable v-model="model.json_config.dashboard" tag="tbody" handle=".handle">
                  <tr v-for="(row, index) in model.json_config.dashboard" :key="row.question_id + '_' + row.project + '_' + row.version">
                    <td style="width: 150px;"><i style="cursor: pointer" class="fa fa-bars handle" aria-hidden="true"></i> {{ index + 1 }}</td>
                    <td scope="row" style="word-break: keep-all; text-align: left;">
                      {{ questions[row.question_id] ? "[" + row.question_id + "] " + questions[row.question_id].question_content : row.question_id }}
                    </td>
                    <td scope="row">
                      {{
                        row.project === "ALL_PROJECTS"
                          ? "Wszystkie"
                          : projects[row.project]
                          ? "[" + row.project + "] " + projects[row.project].project_name
                          : row.project
                      }}
                    </td>
                    <td scope="row" style="min-width: 150px">{{ row.version === "ALL_VERSIONS" ? "Wszystkie" : row.version }}</td>
                    <td style="width: 120px">
                      <p-button style="margin-right: 2px;" type="success" size="sm" icon @click="handleEdit('questions', row)">
                        <i class="fa fa-edit" aria-hidden="true"></i>
                      </p-button>
                      <p-button style="margin-left: 2px;" type="danger" size="sm" icon @click="handleDelete('questions', row)">
                        <i class="fa fa-trash"></i>
                      </p-button>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
        <div v-if="mailingStatsPermitted">
          <br />
          <label>Statystyki mailingów</label>
          <div class="row">
            <div class="col-md-12">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Pozycja</th>
                    <th scope="col">Projekt</th>
                    <th scope="col">Rodzaj</th>
                    <th scope="col">
                      <p-button type="success" icon round @click="handleEdit('mailings')">
                        <i class="fa fa-plus"></i>
                      </p-button>
                    </th>
                  </tr>
                </thead>
                <draggable v-model="model.json_config.mailingStats" tag="tbody" handle=".handle">
                  <tr v-for="(row, index) in model.json_config.mailingStats" :key="row.project_id + '_' + row.mailing_type + '_' + index">
                    <td style="width: 150px;"><i style="cursor: pointer" class="fa fa-bars handle" aria-hidden="true"></i> {{ index + 1 }}</td>
                    <td scope="row">
                      {{
                        row.project_id === "ALL_PROJECTS"
                          ? "Wszystkie"
                          : project_dict[row.project_id]
                          ? "[" + row.project_id + "] " + project_dict[row.project_id].project_name
                          : row.project_id
                      }}
                    </td>
                    <td scope="row" style="min-width: 150px">{{ row.mailing_type }}</td>
                    <td style="width: 120px">
                      <p-button style="margin-right: 2px;" type="success" size="sm" icon @click="handleEdit('mailings', row)">
                        <i class="fa fa-edit" aria-hidden="true"></i>
                      </p-button>
                      <p-button style="margin-left: 2px;" type="danger" size="sm" icon @click="handleDelete('mailings', row)">
                        <i class="fa fa-trash"></i>
                      </p-button>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <p-button :disabled="!isEdited" type="success" @click="saveData">Zapisz</p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as regexp from "src/regexp.js";
import * as priv from "src/privileges.js";
import { eventBus } from "src/main";

import { Select, Option, Switch } from "element-ui";

import Swal from "sweetalert2";
import draggable from "vuedraggable";

import { SAVE_PROFILE_PREFS, LOAD_PROFILE_PREFS, PROFILE_PREFS_INVALID } from "src/store/actions/admin/profile_prefs";
import { LOAD_QUESTION_LIST, QUESTION_LIST_INVALID } from "src/store/actions/questions/question_list";
import { LOAD_PROJECTS, PROJECTS_INVALID } from "src/store/actions/questions/projects";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { DASHBOARD_STATS_INVALID, LOAD_DASHBOARD_STATS } from "src/store/actions/stats/dashboard_stats";
import { DASHBOARD_MAILING_STATS_INVALID, LOAD_DASHBOARD_MAILING_STATS } from "src/store/actions/stats/dashboard_mailing_stats";
import { LOAD_QUESTIONS_EFFICIENCY_DASHBOARD, QUESTIONS_EFFICIENCY_DASHBOARD_INVALID } from "src/store/actions/stats/questions_efficiency_dashboard";

import ProfilePrefsForm from "src/components/Dashboard/Views/Pages/ProfilePrefsForm.vue";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    draggable,
  },
  data() {
    return {
      model: {
        id: null,
        api_user_id: null,
        json_config: {
          activeColor: "success",
          backgroundColor: "black",
          dashboard: [],
          mailingStats: [],
          questionsEfficiency: false,
          testsResults: false,
          complaints: false,
          eventsRaport: false,
          mailsSendProvider: false,
        },
      },
      compare_json_config: {
        activeColor: "success",
        backgroundColor: "black",
        dashboard: [],
        mailingStats: [],
        questionsEfficiency: false,
        testsResults: false,
        complaints: false,
        eventsRaport: false,
        mailsSendProvider: false,
      },
      modelValidations: {
        id: {
          numeric: true,
          min: 1,
        },
        api_user_id: {
          numeric: true,
          min: 1,
        },
        activeColor: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 3,
          max: 16,
        },
        backgroundColor: {
          required: true,
          regex: regexp.NAME_REGEXP,
          min: 3,
          max: 16,
        },
      },
      sidebarDict: {
        activeColor: [
          { name: "primary", color: "#007bff", label: "NIEBIESKI" },
          { name: "info", color: "#17a2b8", label: "BŁĘKITNY" },
          { name: "success", color: "#28a745", label: "ZIELONY" },
          { name: "warning", color: "#ffc107", label: "ŻÓŁTY" },
          { name: "danger", color: "#dc3545", label: "CZERWONY" },
        ],
        backgroundColor: [
          { name: "white", label: "BIAŁY" },
          { name: "brown", label: "BRĄZOWY" },
          { name: "black", label: "CZARNY" },
        ],
      },
      leaveAnswer: true,
    };
  },
  computed: {
    prefs() {
      return this.$store.getters.getProfilePrefs;
    },
    project_dict() {
      return this.$store.getters.getProjectDict;
    },
    projects() {
      // lista projektów
      let projects = this.$store.getters.getProjects;
      let uniqueProjects = {};

      for (let i in projects) {
        // wybranie unikalnych projektów
        uniqueProjects[projects[i].project_id] = { project_id: projects[i].project_id, project_name: projects[i].project_name };
      }

      return uniqueProjects;
    },
    questions() {
      return this.$store.getters.getQuestionList;
    },
    statsPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      if (userPrivileges) {
        return userPrivileges.includes(priv.PRIV_READ_ADMIN_STATS);
      } else return false;
    },
    testsPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      if (userPrivileges) {
        return userPrivileges.includes(priv.PRIV_READ_TESTS_RESULTS);
      } else return false;
    },
    mailingStatsPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      if (userPrivileges) {
        return userPrivileges.includes(priv.PRIV_READ_MAILING_SENDED_AMOUNT);
      } else return false;
    },
    complaintsPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      if (userPrivileges) {
        return userPrivileges.includes(priv.PRIV_READ_COMPLAINTS);
      } else return false;
    },
    isEdited() {
      return JSON.stringify(this.model.json_config) !== JSON.stringify(this.compare_json_config);
    },
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
    },
    async loadData(force) {
      this.$store.commit(QUESTION_LIST_INVALID);

      if (force) {
        this.$store.commit(PROFILE_PREFS_INVALID);
        this.$store.commit(PROJECTS_INVALID);
      }

      if (this.statsPermitted) {
        this.$store.dispatch(LOAD_PROJECTS, {}).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        this.$store.dispatch(LOAD_QUESTION_LIST, {}).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }

      if (this.mailingStatsPermitted) {
        this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }

      await this.$store.dispatch(LOAD_PROFILE_PREFS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    handleEdit(type, row) {
      if (row) {
        this.showModal("edit", type, row);
      } else {
        this.showModal("new", type, {});
      }
    },
    handleDelete(type, row) {
      let mode = type === "questions" ? "dashboard" : "mailingStats";
      let dashboard = this.model.json_config[mode];
      for (let i in dashboard) {
        if (JSON.stringify(dashboard[i]) === JSON.stringify(row)) {
          dashboard.splice(i, 1);
        }
      }
    },
    showModal(mode, type, row) {
      // wyświetlenie modala edycji preferencji
      this.$modal.show(
        ProfilePrefsForm,
        {
          mode: mode,
          type: type,
          row: row,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(ProfilePrefsForm);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "50%",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      let model = JSON.parse(JSON.stringify(this.model));

      if (!this.statsPermitted) {
        model.json_config.dashboard = [];
        model.json_config.questionsEfficiency = 0;
        model.json_config.eventsRaport = 0;
      } else {
        model.json_config.questionsEfficiency = model.json_config.questionsEfficiency ? 1 : 0;
        model.json_config.eventsRaport = model.json_config.eventsRaport ? 1 : 0;
      }

      if (!this.testsPermitted) {
        model.json_config.testsResults = 0;
      } else {
        model.json_config.testsResults = model.json_config.testsResults ? 1 : 0;
      }

      if (!this.mailingStatsPermitted) {
        model.json_config.mailingStats = [];
        model.json_config.mailsSendProvider = 0;
      }else {
        model.json_config.mailsSendProvider = model.json_config.mailsSendProvider ? 1 : 0;
      }

      if (!this.complaintsPermitted) {
        model.json_config.complaints = 0;
      } else {
        model.json_config.complaints = model.json_config.complaints ? 1 : 0;
      }

      if (isValid) {
        await this.$store.dispatch(SAVE_PROFILE_PREFS, model).catch(error => {
          console.log(error.response);
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getProfilePrefsModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został zmodyfikowany",
            type: "success",
          });

          this.$store.commit(PROFILE_PREFS_INVALID); // oznacz nieaktualność danych
          this.initData();

          this.$store.commit(DASHBOARD_STATS_INVALID);
          this.$store.commit(DASHBOARD_MAILING_STATS_INVALID);
          this.$store.commit(QUESTIONS_EFFICIENCY_DASHBOARD_INVALID);
        }
      }
    },
    async initData() {
      await this.loadData();
      if (this.prefs.id !== undefined) {
        this.model.id = this.prefs.id;
        this.model.api_user_id = this.prefs.api_user_id;
        this.model.json_config = JSON.parse(this.prefs.json_config);
        if (this.model.json_config.questionsEfficiency) {
          this.model.json_config.questionsEfficiency = this.model.json_config.questionsEfficiency == "1" ? true : false;
        }
        if (this.model.json_config.testsResults) {
          this.model.json_config.testsResults = this.model.json_config.testsResults == "1" ? true : false;
        }
        if (this.model.json_config.complaints) {
          this.model.json_config.complaints = this.model.json_config.complaints == "1" ? true : false;
        }
        if (this.model.json_config.eventsRaport) {
          this.model.json_config.eventsRaport = this.model.json_config.eventsRaport == "1" ? true : false;
        }
        if (this.model.json_config.mailsSendProvider) {
          this.model.json_config.mailsSendProvider = this.model.json_config.mailsSendProvider == "1" ? true : false;
        }
      }
      this.compare_json_config = JSON.parse(JSON.stringify(this.model.json_config));
    },
    updateModel(row, type, model) {
      let mode = type === "questions" ? "dashboard" : "mailingStats";
      let dashboard = this.model.json_config[mode];
      if (Object.keys(row).length === 0) {
        dashboard.push(model);
      } else {
        for (let i in dashboard) {
          if (JSON.stringify(dashboard[i]) === JSON.stringify(row)) {
            dashboard[i] = model;
          }
        }
      }
      let tempModel = JSON.parse(JSON.stringify(this.model));
      this.model = {};
      this.model = tempModel;
    },
    async leaveCheck() {
      let varThis = this;
      this.leaveAnswer = true;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      if (this.isEdited) {
        await swalWithBootstrapButtons
          .fire({
            title: "Czy chcesz wyjść bez zapisywania?",
            text: `Zmiany zostaną odrzucone`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Akceptuj",
            cancelButtonText: "Anuluj",
            reverseButtons: true,
          })
          .then(res => {
            console.log(res);
            if (res.isDismissed) {
              varThis.leaveAnswer = false;
              return;
            }
            if (res.isConfirmed) {
              varThis.leaveAnswer = true;
              return;
            }
          })
          .catch(() => {
            return;
          });
      }
    },
  },
  created() {
    this.initData();
    eventBus.$on("forceReload", () => {
      this.initData();
    });
    eventBus.$on("profilePrefsSaved", obj => {
      this.updateModel(obj.row, obj.type, obj.model);
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
    eventBus.$off("profilePrefsSaved");
  },
  async beforeRouteLeave(to, form, next) {
    await this.leaveCheck();
    const answer = this.leaveAnswer;
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
};
</script>
<style scoped>
/* draggable */
.flip-list-move {
  transition: transform 0.5s;
}

.flip-list-leave-active {
  display: none !important;
}

.no-move {
  transition: transform 0s;
}

.no-leave-active {
  display: none !important;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
  min-width: 200px;
}

.list-drag-item {
  cursor: move;
}

.list-drag-item i {
  cursor: pointer;
}

tr {
  text-align: center;
}
</style>
