<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Statystyki Banerów</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="-"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd"
          :default-value="defaultDate"
        ></el-date-picker>
      </div>
    </div>


    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <label>Projekt</label>
            <fg-input>
              <el-select v-model="filter.project_id" name="project_id" filterable clearable multiple>
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>

          <div class="col-sm-6 col-md-4 ">
            <label>Typ</label>
            <fg-input>
              <el-select v-model="filter.bannerPreferences" name="bannerPreferences" filterable clearable multiple>
                <el-option
                  v-for="(item,key) in bannersTypesList"
                  :key="key"
                  :label="item"
                  :value="item"
                >
                </el-option>

              </el-select>
            </fg-input>
          </div>

          <div class="col-sm-6 col-md-4 ">
            <label>Wydawca</label>
            <fg-input>
              <el-select v-model="filter.media_house" name="mediaHouse" filterable clearable multiple>
                <el-option v-for="item in media_house" :label="'[' + item.ID + '] ' + item.Name" :value="item.ID"
                           :key="item.ID"></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="row">
          <!--div class="col-md-12 col-lg-4">
            <label for="bannerTypeGroup">Rodzaj wysyłki</label>
            <el-checkbox-group name="bannerTypeGroup" v-model="filter.bannerType">
              <el-checkbox-button v-for="type in bannerTypes" :label="type" :key="type">{{ type }}</el-checkbox-button>
            </el-checkbox-group>
          </div-->
          <div class="col-sm-12  d-flex mt-sm-0">
            <div class="d-inline-flex ml-auto">
              <el-switch
                class="my-auto mr-2 leftSwitch"
                name="showAll"
                v-model="showAll"
                active-text="Dni"
                active-color="#fd7e14"
                inactive-text="Projekty"
                inactive-color="#17a2b8"
              ></el-switch>
              <el-switch class="my-auto mr-1" name="showROI" v-model="showROI" active-text="ROI" active-color="#28a745"
                         inactive-color="#c4c4c4"></el-switch>
              <p-button type="success" @click="loadData('show')" class="d-flex ml-auto">Pokaż</p-button>
              <!--p-button type="success" @click="loadData('excel')" class=" mt-auto mb-0">Excel</p-button-->
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="card-footer" style="display: flex; justify-content: space-between">
        <h6>
          <span>Przedział dat: </span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </h6>
      </div>
    </div>
    <div class="card" v-if="bannersDataSum.length > 0 && Object.keys(bannersDataSum[0]).length > 0">
      <div class="card-header">
        <h5>Podsumowanie</h5>
      </div>
      <div class="card-body">
        <app-baners-efficiency-table :table-data="bannersDataSum" :isBannersSum="true"
                                     :show-roi="showROI"></app-baners-efficiency-table>
      </div>
    </div>

    <div class="card" v-if="!showAll && bannersProjectList.length > 0 &&  bannersDataPid(pid.ProjectID).length>0"
         v-for="(pid, index) in getProjectList" :key="index">
      <div class="card-header">
        <h5>{{ pid.PidName }}</h5>
      </div>
      <div class="card-body">
        <app-baners-efficiency-table :table-data="bannersDataPid(pid.ProjectID)" :isBannersSum="false"
                                     :media-house="media_house"
                                     :show-roi="showROI"></app-baners-efficiency-table>
      </div>
    </div>
    <div class="card" v-if="showAll && bannersDatesList.length > 0 &&  bannersDataDate(date_day).length>0"
         v-for="(date_day, index) in bannersDatesList" :key="index">
      <div class="card-header">
        <h5>{{ date_day }}-dni</h5>
      </div>
      <div class="card-body">
        <app-baners-efficiency-table :table-data="bannersDataDate(date_day)" :isBannersSum="false"
                                     :media-house="media_house"
                                     :show-roi="showROI"></app-baners-efficiency-table>
      </div>
    </div>

  </div>
</template>
<script>


import {eventBus} from "@/main";
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";
import BanersEfficiencyTable from "@/components/Utils4/sales/views/BanersEfficiencyTable.vue";

import {DatePicker, Option, OptionGroup, Select, Switch, Tooltip, CheckboxGroup, CheckboxButton} from "element-ui";

import {
  BANERS_EFFICIENCY_EXCEL_DISPLAY,
  BANERS_EFFICIENCY_EXCEL_DISPLAY_INVALID,
  BANERS_EFFICIENCY_INVALID, BANERS_EFFICIENCY_LOAD, BANERS_EFFICIENCY_GETTER_NAME
} from "@/store/actions/sales/baners_efficiency";
import appMailingEfficiencyTable from "@/components/Utils4/sales/views/MailingEfficiencyTable.vue";
import {LOAD_MEDIA_HOUSE} from "@/store/actions/stats/media_house";


export default {
  components: {
    appMailingEfficiencyTable,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
    appBanersEfficiencyTable: BanersEfficiencyTable
  },
  data() {
    return {
      loading: false,
      showROI: true,
      showAll: false,
      filter: {
        project_id: [],
        bannersTypes: ["Zewnętrzne", "Wewnętrzne"],
        bannerPreferences: [],
        media_house: [],
      },
      bannersDataPresent: [],
      bannersProjectPresent: [],
      pickrDate: null,
      dateRange: ["", ""],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    media_house() {
      let data = Object.values(this.$store.getters.getMediaHouse);
      data.sort((a, b) => b.ID - a.ID);
      return data;
    },
    bannersDataSum() {
      let result = [];

      if (this.$store.state.baners_efficiency.loading_status === "success") {
        result.push(this.$store.getters[BANERS_EFFICIENCY_GETTER_NAME].bannersDataSum);
      }
      return result;
    },
    bannersData() {
      let result = [];

      if (this.$store.state.baners_efficiency.loading_status === "success") {
        result = this.$store.getters[BANERS_EFFICIENCY_GETTER_NAME].bannersData;

      }
      return result;
    },
    bannersDataToShow: {
      get() {
        if (this.filter.bannerPreferences.length === 0 && this.filter.media_house.length === 0 && this.filter.project_id) {
          this.bannersDataPresent = this.bannersData;
        }
        return this.bannersDataPresent;
      },
      set(newValue) {
        this.bannersDataPresent = newValue;
      }
    },
    getBannersData() {
      return this.bannersDataToShow;
    },
    bannersTypesList() {
      let result = [];

      if (this.$store.state.baners_efficiency.loading_status === "success") {
        result = this.$store.getters[BANERS_EFFICIENCY_GETTER_NAME].bannersTypesList;

      }
      return result;
    },
    bannersProjectList() {
      let result = [];

      if (this.$store.state.baners_efficiency.loading_status === "success") {
        result = this.$store.getters[BANERS_EFFICIENCY_GETTER_NAME].bannersProjectList;
      }
      return result;
    },
    bannersDatesList() {
      let result = [];

      if (this.$store.state.baners_efficiency.loading_status === "success") {
        result = this.$store.getters[BANERS_EFFICIENCY_GETTER_NAME].bannersDatesList;
      }
      return result;
    },
    bannersProjectListToShow: {
      get() {
        if (this.filter.project_id.length === 0) {
          this.bannersProjectPresent = this.bannersProjectList;
        }
        return this.bannersProjectPresent;
      },
      set(newValue) {

        this.bannersProjectPresent = newValue;
      }
    },
    getProjectList() {
      return this.bannersProjectListToShow;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    date() {
      let result = [];
      if (this.pickrDate) {
        const newDate = this.pickrDate.split(" do ");
        if (newDate[0]) result.push(newDate[0]);
        if (newDate[1]) result.push(newDate[1]);
      }
      return result;
    },

    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },

  },


  watch: {
    filter: {
      handler: function (val) {
        let bannersDataToShow = [], bannersData = this.bannersData;
        if (val.project_id.length > 0) {
          let projectToShow = [], bannersProjectList = this.bannersProjectList;

          for (let key in bannersProjectList) {
            let projectId = bannersProjectList[key].ProjectID;
            if (this.filter.project_id.includes(projectId)) {
              projectToShow.push(bannersProjectList[key]);
            }
          }
          this.bannersProjectListToShow = projectToShow;
        }

        if (val.media_house.length > 0) {
          for (let key in bannersData) {
            let mediaHouse = bannersData[key].MediaHouseID;
            if (this.filter.media_house.includes(mediaHouse)) {
              bannersDataToShow.push(bannersData[key]);
            }
          }
          this.bannersDataPresent = bannersDataToShow;
        }

        if (val.bannerPreferences.length > 0) {
          let bannersDataToShow = [], bannersData = this.getBannersData;
          for (let key in bannersData) {
            let preferences = bannersData[key].Preferences;
            if (this.filter.bannerPreferences.includes(preferences)) {
              bannersDataToShow.push(bannersData[key]);
            }
          }
          this.bannersDataPresent = bannersDataToShow;
        }
      },
      deep: true
    },
  },
  methods: {
    async loadDicts() {
      this.$store.dispatch(LOAD_MEDIA_HOUSE, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    bannersDataPid(pid) {
      let result = [], bannersData = this.getBannersData;
      bannersData.map((el) => {
        if (el.ProjectID === pid) {
          result.push(el);
        }
      });
      return result;
    },
    bannersDataDate(date_day) {

      let result = [], bannersData = this.getBannersData;
      bannersData.map((el) => {
        if (el.hasOwnProperty('days')) {
          el.days.map((el_day) => {
            if (el_day.ActionTime === date_day) {
              el_day.BannerID = el.BannerID;
              el_day.BannerName = el.BannerName;
              el_day.MediaHouseID = el.MediaHouseID;
              el_day.Price = el.Price;
              el_day.Preferences = el.Preferences;

              result.push(el_day);
            }

          });
        }


      });
      return result;
    },
    async loadData(mode) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }
      this.loading = true;

      let date = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD 00:00:00"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD 23:59:59"),
      };
      const filters = Object.assign({}, date, this.filter);

      this.$store.commit(mode === "excel" ? BANERS_EFFICIENCY_EXCEL_DISPLAY_INVALID : BANERS_EFFICIENCY_INVALID);

      await this.$store
        .dispatch(mode === "excel" ? BANERS_EFFICIENCY_EXCEL_DISPLAY : BANERS_EFFICIENCY_LOAD, filters)
        .then(() => {

          let message = 'Wczytano dane';
          eventBus.$emit("notify", {
            message: message,
            type: "warning",
          });
        })
        .catch((error) => {
          let isJson;
          let msg = "";
          let err_msg;
          try {
            isJson = true;
            err_msg = JSON.parse(error.response.data.description);
          } catch (e) {
            isJson = false
          }
          if (isJson === true) {
            if ("missing" in err_msg) {
              msg = "Brak parametru: " + err_msg.missing;
            }
          } else {
            msg = error.response.data.description;
          }
          eventBus.$emit("notify", {
            message: msg,
            type: "warning",
          });
        });
      if (mode === "excel") {
        const tmpName = this.$store.getters.getCustomerEfficiencyExel.tmpname;
        const fileName = this.$store.getters.getCustomerEfficiencyExel.name;


        this.downloadFile(tmpName, fileName);
      }
      this.loading = false;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-contract.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    formatCurrency(row, column, cellValue) {
      return this.$options.filters.formatCurrency(cellValue);
    }
  },
  created() {
    this.loadDicts();
    eventBus.$on("forceReload", () => {
      this.loadDicts();
      this.loadData();
    });
  },
  beforeDestroy: function () {
    this.$store.commit(BANERS_EFFICIENCY_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
