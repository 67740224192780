import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_USER_GROUP,
  USER_GROUP_LOADING,
  USER_GROUP_LOADING_SUCCESS,
  USER_GROUP_LOADING_ERROR,
  USER_GROUP_INVALID,
  SAVE_USER_GROUP,
  USER_GROUP_SAVING,
  USER_GROUP_SAVING_SUCCESS,
  USER_GROUP_SAVING_ERROR,
  DELETE_USER_GROUP,
  USER_GROUP_DELETING,
  USER_GROUP_DELETING_SUCCESS,
  USER_GROUP_DELETING_ERROR
} from '../../actions/complaints/user_group'

const storeObjectConfig = {
  objectName: "UserGroup",
  getterName: "getUserGroup",
  loadActionName: LOAD_USER_GROUP,
  loadingMutationName: USER_GROUP_LOADING,
  loadingSuccessMutationName: USER_GROUP_LOADING_SUCCESS,
  loadingErrorMutationName: USER_GROUP_LOADING_ERROR,
  invalidMutationName: USER_GROUP_INVALID,
  saveActionName: SAVE_USER_GROUP,
  savingMutationName: USER_GROUP_SAVING,
  savingSuccessMutationName: USER_GROUP_SAVING_SUCCESS,
  savingErrorMutationName: USER_GROUP_SAVING_ERROR,
  deleteActionName: DELETE_USER_GROUP,
  deletingMutationName: USER_GROUP_DELETING,
  deletingSuccessMutationName: USER_GROUP_DELETING_SUCCESS,
  deletingErrorMutationName: USER_GROUP_DELETING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/complaints/userGroup/admin",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/complaints/userGroup/admin",
    method: "put" // {'get','post','put'}
  },
  deleteParams: {
    url: "/complaints/userGroup/admin",
    method: "delete" // {'delete'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
