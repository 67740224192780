<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <form>
        <div class="card-header">
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <h4 id="question_form_header">{{ editMode ? "Edycja pytania " + question_id : "Nowe pytanie" }}</h4>
            </div>
            <div class="col-sm-12 text-sm-left col-md-8 text-md-right" style="display: flex; align-items: flex-end; justify-content: flex-end">
              <span>
                <el-tooltip class="item" effect="light" content="Pauzowanie pytania po wyrobieniu limitów" placement="top" :open-delay="500">
                  <p-checkbox v-model="questionModel.auto_pause" :inline="true">Auto pauza</p-checkbox>
                </el-tooltip>
              </span>
              <span>
                <el-tooltip
                  v-if="questionModel.question_type === 'CO-REGISTRATION'"
                  class="item"
                  effect="light"
                  content="Wyświetlanie pytania na końcu pytań korejestracyjnych niezależnie od limitów"
                  placement="top"
                  :open-delay="500"
                >
                  <p-checkbox v-if="questionModel.question_type === 'CO-REGISTRATION'" v-model="questionModel.force_display" :inline="true"
                    >Wyświetlaj na końcu poza limitami</p-checkbox
                  >
                </el-tooltip>
              </span>
              <span>
                <p-button class="text-md-right" size="sm" type="success" @click.prevent="showCloneModal">Klonuj pytanie</p-button>
              </span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Treść pytania</label>
            <el-input
              id="question_from_question_content"
              type="textarea"
              name="question_content"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="Treść pytania"
              v-model="questionModel.question_content"
              v-validate="questionModelValidations.question_content"
              :error="getError('question_content')"
            >
            </el-input>
          </div>

          <div class="form-group">
            <el-tooltip class="item" effect="light" placement="top" :open-delay="1000">
              <div slot="content">
                Stała treść wyświetlana pod pytaniem.<br />
                Np. Po wybraniu kwoty pożyczki konsultant firmy <i>Profi Credit</i> zadzwoni, aby przedstawić szczegóły.
              </div>
              <label>Stopka pytania</label>
            </el-tooltip>
            <el-input
              type="textarea"
              name="question_footer"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="Treść stopki"
              v-model="questionModel.question_footer"
              v-validate="questionModelValidations.question_footer"
              :error="getError('question_footer')"
            >
            </el-input>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <label>Typ pytania</label>
              <fg-input :error="getError('question_type')" data-vv-as="Typ pytania">
                <el-select
                  v-on:input="questionModel.force_display = false"
                  v-model="questionModel.question_type"
                  name="question_type"
                  data-vv-as="Typ pytania"
                  v-validate="questionModelValidations.question_type"
                  filterable
                >
                  <el-option v-for="question_type in question_types" :label="question_type" :value="question_type" :key="question_type"></el-option>
                </el-select>
              </fg-input>
            </div>

            <div class="col-sm-12 col-md-6">
              <el-tooltip class="item" effect="light" placement="top" :open-delay="1000">
                <div slot="content">
                  Priorytet jest używany do sortowania pytań.<br />
                  Im większy priorytet, tym wyżej znajduje się pytanie.<br />
                  Np. pytanie z priorytetem 10000 będzie wyświetlone przed pytaniem z priorytetem 9000.
                </div>
                <label>Priorytet</label>
              </el-tooltip>
              <fg-input
                type="number"
                name="priority"
                data-vv-as="Priorytet"
                v-validate="questionModelValidations.priority"
                :error="getError('priority')"
                v-model="questionModel.priority"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <label>Typ szablonu</label>
              <fg-input :error="getError('template_name')" data-vv-as="Typ szablonu">
                <el-select
                  id="question_form_template_name"
                  v-on:handleOptionClick="changeTemplate($event.value)"
                  v-model="questionModel.template_name"
                  name="template_name"
                  data-vv-as="Typ szablonu"
                  v-validate="questionModelValidations.template_name"
                  filterable
                >
                  <el-option
                    v-for="template_name in question_template_dict"
                    :label="template_name.name"
                    :value="template_name.name"
                    :key="template_name.id"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>

            <div class="col-sm-12 col-md-6">
              <el-tooltip class="item" effect="light" placement="top" :open-delay="1000">
                <div slot="content">Grupowanie pytań do celów deduplikacji (tylko wyświetlanie).</div>
                <label>Grupa pytań</label>
              </el-tooltip>
              <fg-input :error="getError('group_id')" data-vv-as="Grupa pytań">
                <el-select
                  v-model="groupModel.group_id"
                  name="group_id"
                  data-vv-as="Grupa pytań"
                  v-validate="groupModelValidation.group_id"
                  filterable
                  clearable
                >
                  <el-option v-for="group in groups" :label="group.group_name" :value="group.group_id" :key="group.group_id"></el-option>
                </el-select>
              </fg-input>
            </div>
          </div>

          <div class="form-group">
            <label>Aktywność</label>
            <fg-input :error="getError('question_is_active')" data-vv-as="Aktywność">
              <el-select
                v-model="questionModel.question_is_active"
                name="question_is_active"
                data-vv-as="Aktywność"
                v-validate="questionModelValidations.question_is_active"
                filterable
              >
                <el-option
                  v-for="question_is_active in question_is_active_dict"
                  :label="question_is_active"
                  :value="question_is_active"
                  :key="question_is_active"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <hr v-if="editMode" />

          <div class="row" v-if="editMode">
            <div class="col-sm-12 col-md-6 text-sm-left">
              <label>Odpowiedzi</label>
            </div>
            <div class="col-sm-12 col-md-6 text-md-right text-sm-left">
              <p-button
                v-if="answers.length > 0"
                class="text-md-right"
                size="sm"
                :type="editAnswers ? 'warning' : 'success'"
                @click.prevent="editAnswers = !editAnswers"
                >{{ editAnswers ? "Wyłącz edycję" : "Edytuj odpowiedzi" }}</p-button
              >
            </div>
          </div>

          <div class="row" v-if="editMode">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Pozycja</th>
                      <th scope="col">ID</th>
                      <th scope="col">Treść</th>
                      <th scope="col">Ważność</th>
                      <th scope="col">Reguły walidacji</th>
                      <th scope="col">Typ</th>
                      <th scope="col">Pytanie podrzędne</th>
                      <th scope="col">Klienci</th>
                      <th scope="col">
                        <p-button type="success" icon round @click="handleEdit(false)">
                          <i class="fa fa-plus"></i>
                        </p-button>
                      </th>
                    </tr>
                  </thead>
                  <draggable v-model="answers_table" tag="tbody" @change="moved" handle=".handle">
                    <tr v-for="(answer, index) in answers_table" :key="answer.answer_id">
                      <td id="question_from_answer_table_answer_position">
                        <i style="cursor: pointer" v-if="editAnswers" class="fa fa-bars handle" aria-hidden="true"></i> {{ answer.answer_position }}
                      </td>
                      <td scope="row" id="question_from_answer_table_answer_id">
                        {{ typeof answer.answer_id === "number" ? answer.answer_id : null }}
                      </td>
                      <td id="question_from_answer_table_answer_content">
                        <div v-if="!editAnswers">
                          {{ answer.answer_content }}
                          <hr v-if="answerStats[answer.answer_id]" />
                          {{ answerStats[answer.answer_id] }}
                        </div>
                        <div v-else>
                          <el-input
                            type="textarea"
                            name="answer_content"
                            :autosize="{ minRows: 1, maxRows: 4 }"
                            placeholder="Treść odpowiedzi"
                            v-model="answer.answer_content"
                            v-validate="answerModelValidations.answer_content"
                            :error="getError('answer_content')"
                          >
                          </el-input>
                          <hr />
                          <el-input
                            type="textarea"
                            name="description"
                            :autosize="{ minRows: 1, maxRows: 4 }"
                            placeholder="Treść statystyk"
                            v-model="answerStats[answer.answer_id]"
                            v-validate="answerStatsModelValidations.description"
                            :error="getError('description')"
                          >
                          </el-input>
                        </div>
                      </td>
                      <td style="width: 100px">
                        <div v-if="!editAnswers">
                          {{ answer.expiration_days }}
                        </div>
                        <div v-else>
                          <fg-input
                            type="number"
                            name="expiration_days"
                            data-vv-as="Ważność"
                            v-validate="answerModelValidations.expiration_days"
                            :error="getError('expiration_days')"
                            v-model="answer.expiration_days"
                          ></fg-input>
                        </div>
                      </td>
                      <td>
                        <div v-if="answer_rules[answer.answer_id]">
                          {{ answer_rules[answer.answer_id].validation.replace(/,+/g, ", ") }}
                        </div>
                      </td>
                      <td id="question_from_answer_table_answer_type">{{ answer.answer_type }}</td>
                      <td style="width: 100px">
                        <el-tooltip
                          v-if="answer.next_question_id !== null"
                          class="item"
                          effect="light"
                          :content="questions[answer.next_question_id].question_content"
                          placement="top"
                          :open-delay="500"
                        >
                          <span>{{ answer.next_question_id }}</span>
                        </el-tooltip>
                      </td>
                      <td>
                        <fg-input :error="getError('client_id')" data-vv-as="Klienci">
                          <el-select
                            v-model="clientModel.client_id[answer.answer_id]"
                            name="client_id"
                            data-vv-as="Klienci"
                            multiple
                            filterable
                            :disabled="!editAnswers"
                            v-on:input="clientsKey += 1"
                            v-on:remove-tag="clientsKey += 1"
                          >
                            <el-option
                              v-for="client in clients"
                              :label="client.client_name"
                              :value="client.client_id"
                              :key="client.client_id"
                            ></el-option>
                          </el-select>
                        </fg-input>
                      </td>
                      <td>
                        <el-tooltip v-if="editAnswers" effect="light" content="Klonuj" placement="top" :open-delay="500">
                          <p-button v-if="editAnswers" type="success" size="sm" icon @click="handleClone(index, answer)">
                            <i class="fa fa-clone"></i>
                          </p-button>
                        </el-tooltip>
                        <el-tooltip effect="light" content="Edytuj" placement="top" :open-delay="500">
                          <p-button v-if="answerCheck(answer)" type="success" size="sm" icon @click="handleEdit(true, answer)">
                            <i class="fa fa-edit"></i>
                          </p-button>
                        </el-tooltip>
                        <el-tooltip v-if="editAnswers" effect="light" content="Usuń" placement="top" :open-delay="500">
                          <p-button v-if="editAnswers" type="danger" size="sm" icon @click="handleDelete(index, answer)">
                            <i class="fa fa-trash"></i>
                          </p-button>
                        </el-tooltip>
                      </td>
                    </tr>
                  </draggable>
                </table>
              </div>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-sm-12 col-md-6 text-sm-left">
              <label>Kryteria</label>
            </div>
            <div class="col-sm-12 col-md-6 text-md-right text-sm-left">
              <p-button class="text-md-right" size="sm" :type="editCriterias ? 'warning' : 'success'" @click.prevent="criteriasHandler()">{{
                editCriterias ? "Wyłącz edycję" : "Edytuj kryteria"
              }}</p-button>
            </div>
          </div>

          <div class="form-group">
            <app-criteria
              :key="this.criteriaKey"
              :editMode="this.editCriterias"
              :props_question_id="this.question_id"
              :props_question_type="this.question_type"
            ></app-criteria>
          </div>

          <!-- potrzebne żeby zmusić computed do przeliczenia i zaktualizowania komponentu -->
          <div style="visibility: hidden">{{ clientsKey }}</div>
        </div>
        <div class="card-footer text-center">
          <router-link to="/questions/questions">
            <p-button type="default" link style="margin-right: 5vw"> Anuluj </p-button>
          </router-link>
          <p-button v-if="!editMode" type="success" @click.prevent="handleSave">Utwórz</p-button>
          <p-button v-if="editMode" type="success" @click.prevent="handleSave">Aktualizuj</p-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// ui components
import { Select, Option, Switch, Input, Tooltip } from "element-ui";

import draggable from "vuedraggable";
import Swal from "sweetalert2";

// vuex actions
import { LOAD_QUESTION_DATA, QUESTION_DATA_INVALID } from "src/store/actions/questionForm/question_data";
import { LOAD_GROUPS } from "src/store/actions/questions/groups";
import { LOAD_QUESTION_GROUP, QUESTION_GROUP_INVALID, SAVE_QUESTION_GROUP } from "src/store/actions/questions/question_group";
import { LOAD_CRITERIAS } from "src/store/actions/questionForm/criterias";
import { LOAD_QUESTION_CRITERIA, QUESTION_CRITERIA_INVALID, SAVE_QUESTION_CRITERIA } from "src/store/actions/questionForm/question_criteria";
import { LOAD_QUESTION_TEMPLATES } from "src/store/actions/questionForm/question_templates";
import { LOAD_QUESTION_TEMPLATE_FIELD_TYPES } from "src/store/actions/questionForm/question_template_field_types";
import { LOAD_ANSWERS, ANSWERS_INVALID, SAVE_ANSWER } from "src/store/actions/questions/answers";
import { LOAD_ANSWER_STATS, ANSWER_STATS_INVALID, SAVE_ANSWER_STATS } from "src/store/actions/questionForm/answer_stats";
import { LOAD_ANSWER_RULES, ANSWER_RULES_INVALID, SAVE_ANSWER_RULES } from "src/store/actions/questionForm/answer_rules";
import { LOAD_CLIENTS } from "src/store/actions/questionForm/clients";
import { LOAD_ANSWER_CLIENT, ANSWER_CLIENT_INVALID, SAVE_ANSWER_CLIENT } from "src/store/actions/questionForm/answer_client";
import { ANSWER_TAGS_INVALID, SAVE_ANSWER_TAG } from "src/store/actions/questionForm/answer_tags";
import { ANSWER_CATEGORYS_INVALID, SAVE_ANSWER_CATEGORY } from "src/store/actions/questionForm/answer_categorys";
import { SAVE_QUESTION, LOAD_QUESTIONS, QUESTIONS_INVALID } from "src/store/actions/questions/questions";
import { QUESTION_LIST_INVALID } from "src/store/actions/questions/question_list";

import Criteria from "src/components/Utils4/questions/criteria/Criteria.vue";
import CloneQuestionForm from "src/components/Utils4/questions/forms/CloneQuestionForm.vue";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [Input.name]: Input,
    [Tooltip.name]: Tooltip,
    draggable,
    "app-criteria": Criteria,
  },
  data() {
    return {
      isDragging: false,
      delayedDragging: false,
      questionModel: {
        question_id: null,
        question_content: "",
        question_type: "",
        priority: 0,
        template_name: "",
        expiration_days: 0,
        question_footer: "",
        question_is_active: "",
        force_display: false,
        auto_pause: false,
      },
      questionModelComparison: {
        question_id: null,
        question_content: "",
        question_type: "",
        priority: 0,
        template_name: "",
        expiration_days: 0,
        question_footer: "",
        question_is_active: "",
        force_display: false,
        auto_pause: false,
      },
      questionModelValidations: {
        question_id: {
          numeric: true,
          min: 1,
        },
        question_content: {
          // TODO: REGEX dla treści pytania! zabronione '', zezwolić na litery innych języków
          required: true,
          min: 1,
          max: 1024,
        },
        question_type: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 3,
          max: 32,
        },
        priority: {
          required: true,
          numeric: true,
          min: 0,
        },
        template_name: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 1,
          max: 32,
        },
        expiration_days: {
          required: true,
          numeric: true,
          min: 0,
        },
        question_footer: {
          // regex: regexp.TEXT_REGEXP, TODO: poprawić walidację treści
          min: 1,
          max: 256,
        },
        question_is_active: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          max: 32,
        },
      },
      answers: [],
      answers_table: [],
      answerModelValidations: {
        answer_content: {
          min: 0,
          max: 1024,
        },
        expiration_days: {
          required: true,
          numeric: true,
          min: 0,
        },
      },
      answerStats: {},
      answerStatsModelValidations: {
        description: {
          min: 1,
          max: 1024,
        },
      },
      criteriasSaveObject: {},
      groupModel: {
        question_id: this.question_id,
        group_id: null,
      },
      groupModelValidation: {
        question_id: {
          // required: true,
          numeric: true,
          min: 1,
        },
        group_id: {
          // required: true,
          numeric: true,
          min: 1,
        },
      },
      clientModel: {
        client_id: {},
      },
      clientCompareModel: {
        client_id: {},
      },
      clientModelValidation: {
        // answer_id: {
        //   required: true,
        //   numeric: true,
        //   min: 1
        // }
      },
      question_types: ["CO-REGISTRATION", "DOUBLEOPT-IN", "PROFILE", "QUIZ"],
      question_is_active_dict: ["ACTIVE", "INACTIVE", "ARCHIVE"],
      answer_rules_dict: ["NOT_EMPTY", "IS_TEXT", "IS_CHILD", "IS_DATE", "IS_YEAR", "IS_MONTH", "IS_YEAR_MONTH", "IS_YEAR_MONTH_FUTURE"],
      editMode: true,
      editAnswers: false,
      editCriterias: false,
      loading: false,
      createdQuestionID: null,
      criteriaKey: 0,
      answerCloneIDs: {},
      clientsKey: 0,
    };
  },
  computed: {
    question_id() {
      if (this.createdQuestionID === null) {
        return this.$route.params.id !== undefined ? this.$route.params.id : null;
      } else {
        return this.createdQuestionID;
      }
    },
    question_type() {
      if (this.questions && this.questions[this.question_id]) {
        console.log(this.questions, this.question_id);
        return this.questions[this.question_id].question_type;
      } else {
        return null;
      }
    },
    questions() {
      return this.$store.getters.getQuestions;
    },
    groups() {
      return Object.values(this.$store.getters.getGroups);
    },
    clients() {
      return Object.values(this.$store.getters.getClients);
    },
    answer_client() {
      return this.$store.getters.getAnswerClient;
    },
    answer_stats() {
      return this.$store.getters.getAnswerStats;
    },
    answer_rules() {
      return this.$store.getters.getAnswerRules;
    },
    question_template_dict() {
      return this.$store.getters.getQuestionTemplates;
    },
    question_template_fieldTypes() {
      return this.$store.getters.getQuestionTemplateFieldTypes;
    },
  },
  methods: {
    moved(event) {
      // indeksowanie tablic przy każdej zmianie kolejności
      this.answers_table.forEach((el, index) => (el.answer_position = index + 1));
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
    },
    async loadData(force) {
      let filters = {
        question_id: parseInt(this.question_id),
      };

      const p1 = this.$store.dispatch(LOAD_ANSWERS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const p2 = this.$store.dispatch(LOAD_QUESTION_TEMPLATES, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const p3 = this.$store.dispatch(LOAD_QUESTION_TEMPLATE_FIELD_TYPES, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const p4 = this.$store.dispatch(LOAD_ANSWER_STATS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const p5 = this.$store.dispatch(LOAD_ANSWER_RULES, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const p6 = this.$store.dispatch(LOAD_GROUPS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const p7 = this.$store.dispatch(LOAD_CLIENTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      if (force) {
        this.$store.commit(QUESTION_GROUP_INVALID);
        this.$store.commit(QUESTION_DATA_INVALID);
        this.$store.commit(QUESTIONS_INVALID);
        this.$store.commit(QUESTION_LIST_INVALID);
      }
      let pq1 = true;
      let pq2 = true;
      let pq3 = true;
      let pq4 = true;

      if (this.question_id !== null) {
        pq1 = this.$store.dispatch(LOAD_ANSWER_CLIENT, {}).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        pq2 = this.$store.dispatch(LOAD_QUESTION_GROUP, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        pq3 = this.$store.dispatch(LOAD_QUESTION_DATA, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        pq4 = this.$store.dispatch(LOAD_QUESTIONS, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }
      const end = await Promise.all([p1, p2, p3, p4, p5, p6, p7, pq1, pq2, pq3, pq4]);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    answerCheck(answer) {
      return typeof answer.answer_id !== "string";
    },
    handleClone(index, row) {
      this.answers.push(JSON.parse(JSON.stringify(row)));
      let answer_id = row.answer_id;
      while (typeof answer_id === "string") {
        answer_id = this.answerCloneIDs[answer_id];
      }
      this.answerCloneIDs["new_answer" + (this.answers.length - 1)] = answer_id;
      this.answers[this.answers.length - 1].answer_id = "new_answer" + (this.answers.length - 1);
      if (this.clientModel.client_id[row.answer_id].length > 0) {
        this.clientModel.client_id["new_answer" + (this.answers.length - 1)] = this.clientModel.client_id[row.answer_id];
      }
      this.clientCompareModel.client_id["new_answer" + (this.answers.length - 1)] = [];
      if (this.answerStats[row.answer_id] !== undefined) {
        this.answerStats["new_answer" + (this.answers.length - 1)] = this.answerStats[row.answer_id];
      }
      this.answers_table.push(JSON.parse(JSON.stringify(this.answers[this.answers.length - 1])));
      this.moved();
    },
    async handleEdit(isEdit, row) {
      if (await this.checkChanges()) {
        if (isEdit) {
          this.editRouting(true, row);
        } else {
          this.editRouting(false, row);
        }
      } else {
        if (isEdit) {
          this.$router.push("/questions/answer_form/qid=" + this.question_id + ",aid=" + row.answer_id);
        } else {
          this.$router.push("/questions/answer_form/qid=" + this.question_id);
        }
      }
    },
    handleDelete(index, row) {
      if (this.answerCloneIDs[row.answer_id]) {
        delete this.answerCloneIDs[row.answer_id];
      }
      this.answers.map((el) => {
        if (el.answer_id === row.answer_id) {
          el.is_active = 0;
        }
      });
      this.answers_table = this.answers.filter((row) => row.is_active === 1);
      this.moved();
    },
    changeTemplate(value) {
      let templateID;
      for (let i in this.question_template_dict) {
        if (this.question_template_dict[i].name === value) {
          templateID = this.question_template_dict[i].id;
        }
      }
      let fieldType = this.question_template_fieldTypes[templateID];
      let answerFieldType;
      for (let i in fieldType) {
        if (fieldType[i].is_default === 1) {
          answerFieldType = fieldType[i].answer_field_type;
        }
      }
      for (let i in this.answers) {
        this.answers[i].answer_type = answerFieldType;
      }
      for (let i in this.answers_table) {
        this.answers_table[i].answer_type = answerFieldType;
      }
    },
    createGroupModel(question_id) {
      let groupModel = {};
      // let groupModelCheck = false;
      this.groupModel.question_id = parseInt(question_id);
      if (this.groupModel.group_id === "") this.groupModel.group_id = null;
      if (this.$store.getters.getQuestionGroup[question_id] === undefined && this.groupModel.group_id !== null) {
        groupModel = this.groupModel;
        groupModel.mode = "insert";
        // groupModelCheck = true;
      } else if (this.$store.getters.getQuestionGroup[question_id] !== undefined) {
        if (this.groupModel.group_id === null) {
          groupModel = this.groupModel;
          groupModel.mode = "delete";
          // groupModelCheck = true;
        } else if (this.$store.getters.getQuestionGroup[question_id].group_id !== this.groupModel.group_id) {
          groupModel = this.groupModel;
          groupModel.mode = "update";
          // groupModelCheck = true;
        }
      }
      return groupModel;
    },
    createAnswerModel(question_id) {
      let answerModel = { insert_model: {}, update_model: {} };
      if (question_id !== null) {
        for (let i in this.answers) {
          this.answers_table.map((el) => {
            if (el.answer_id === this.answers[i].answer_id) {
              // this.answers[i].answer_position = el.answer_position;
              this.answers[i] = el;
            }
          });
          if (this.answers[i].is_active === 0 && typeof this.answers[i].answer_id !== "number") {
          } else if (this.answers[i].is_active !== 0 && typeof this.answers[i].answer_id !== "number") {
            answerModel.insert_model[this.answers[i].answer_id] = this.answers[i];
            answerModel.insert_model[this.answers[i].answer_id].answer_content = answerModel.insert_model[this.answers[i].answer_id].answer_content
              .toString()
              .replace(/[']/gm, "'");
          } else {
            if (JSON.stringify(this.answers[i]) !== JSON.stringify(this.$store.getters.getAnswers[question_id][this.answers[i].answer_id])) {
              answerModel.update_model[this.answers[i].answer_id] = this.answers[i];
              if (answerModel.update_model[this.answers[i].answer_id].answer_content) {
                answerModel.update_model[this.answers[i].answer_id].answer_content = answerModel.update_model[
                  this.answers[i].answer_id
                ].answer_content
                  .toString()
                  .replace(/[']/gm, "'");
              }
            }
          }
        }
      }
      return answerModel;
    },
    createClientModel() {
      let client_ids = this.clientModel.client_id;
      let client_compare_ids = this.clientCompareModel.client_id;
      let clientModelInsert = {};
      let clientModelDelete = {};
      for (let i in client_ids) {
        let difference1 = client_ids[i].filter((x) => !client_compare_ids[i].includes(x));
        if (difference1.length > 0) {
          clientModelInsert[i] = difference1;
        }
        let difference2 = client_compare_ids[i].filter((x) => !client_ids[i].includes(x));
        if (difference2.length > 0) {
          clientModelDelete[i] = difference2;
        }
      }
      let clientModel = { clientModelInsert: clientModelInsert, clientModelDelete: clientModelDelete };
      return clientModel;
    },
    createStatsModel() {
      let answerStatsCompareModel = undefined;
      let statsModelInsert = {};
      let statsModelUpdate = {};
      let statsModelDelete = {};
      let statsModel = {};
      for (let i in this.answers) {
        let answer_id = this.answers[i].answer_id;

        let answerStatsModel = this.answerStats[answer_id];

        if (this.answer_stats[answer_id] !== undefined) {
          answerStatsCompareModel = JSON.parse(JSON.stringify(this.answer_stats[answer_id].description));
        } else {
          answerStatsCompareModel = undefined;
        }
        if (answerStatsCompareModel !== undefined && answerStatsModel !== undefined) {
          if (answerStatsModel !== answerStatsCompareModel) {
            if (answerStatsModel.length === 0) {
              statsModelDelete[answer_id] = { answer_id: answer_id, description: answerStatsCompareModel };
            } else if (answerStatsModel.length !== 0) {
              statsModelUpdate[answer_id] = { answer_id: answer_id, description: answerStatsModel };
            }
          }
        } else if (answerStatsModel !== undefined) {
          if (answerStatsModel.length > 0) {
            statsModelInsert[answer_id] = { answer_id: answer_id, description: answerStatsModel };
          }
        }
      }
      statsModel = { statsModelDelete, statsModelUpdate, statsModelInsert };
      return statsModel;
    },
    checkChanges() {
      // sprawdzenie czy powstały jakieś zmiany przed przejściem do edycji odpowiedzi
      let question_id = this.question_id;

      let questionModelCheck = false;
      if (JSON.stringify(this.questionModel) !== JSON.stringify(this.questionModelComparison)) {
        questionModelCheck = true;
      }

      // group model
      let groupModel = {};
      let groupModelCheck = false;
      groupModel = this.createGroupModel(question_id);
      if (Object.values(groupModel).length > 0) {
        groupModelCheck = true;
      }

      // criteria model
      let criteriaModelCheck = false;
      if (
        Object.keys(this.criteriasSaveObject).length > 0 &&
        (this.criteriasSaveObject.insert_data.length > 0 ||
          this.criteriasSaveObject.update_data.length > 0 ||
          this.criteriasSaveObject.delete_data.length > 0)
      ) {
        criteriaModelCheck = true;
      }

      // answer model
      let answerModelCheck = false;
      let answerModel = { insert_model: {}, update_model: {} };
      answerModel = this.createAnswerModel(question_id);
      if (Object.keys(answerModel.insert_model).length > 0 || Object.keys(answerModel.update_model).length > 0) {
        answerModelCheck = true;
      }

      // client model
      let clientModelCheck = false;
      let clientModel = { clientModelInsert: {}, clientModelDelete: {} };
      clientModel = this.createClientModel();
      if (Object.values(clientModel.clientModelInsert).length > 0 || Object.values(clientModel.clientModelDelete).length > 0) {
        clientModelCheck = true;
      }

      // stats model
      let statsModelCheck = false;
      let statsModel = { statsModelDelete: {}, statsModelUpdate: {}, statsModelInsert: {} };
      statsModel = this.createStatsModel();
      if (
        Object.keys(statsModel.statsModelDelete).length > 0 ||
        Object.keys(statsModel.statsModelUpdate).length > 0 ||
        Object.keys(statsModel.statsModelInsert).length > 0
      ) {
        statsModelCheck = true;
      }

      // sprawdzenie zmian
      let checkArray = [questionModelCheck, groupModelCheck, answerModelCheck, clientModelCheck, criteriaModelCheck, statsModelCheck];
      let result = false;
      if (checkArray.some((el) => el === true)) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();

      let question_id = this.question_id;
      let createdQuestionID = null;

      this.questionModel.question_content = this.questionModel.question_content.replace(/[']/gm, "'");

      if (isValid) {
        // question model
        let questionModelCheck = false;
        if (JSON.stringify(this.questionModel) !== JSON.stringify(this.questionModelComparison)) {
          questionModelCheck = true;
        }

        if (question_id === null) {
          // tworzenie nowego pytania
          if (questionModelCheck) {
            await this.$store.dispatch(SAVE_QUESTION, this.questionModel).catch((error) => {
              console.log(error.response);
              eventBus.$emit("notify", {
                message: "Błąd zapisu pytania!",
                type: "warning",
              });
            });
            createdQuestionID = this.$store.getters.getQuestionsModifiedRecords;
            if (typeof createdQuestionID === "number") {
              this.createdQuestionID = createdQuestionID;
              question_id = createdQuestionID;

              eventBus.$emit("notify", {
                message: "Pytanie zostało dodane",
                type: "success",
              });

              this.$store.commit(QUESTION_DATA_INVALID);
              this.$store.commit(QUESTIONS_INVALID);
              this.$store.commit(QUESTION_LIST_INVALID);

              // group model
              let groupModel = {};
              let groupModelCheck = false;
              groupModel = this.createGroupModel(question_id);
              if (Object.values(groupModel).length > 0) {
                groupModelCheck = true;

                await this.$store.dispatch(SAVE_QUESTION_GROUP, groupModel).catch((error) => {
                  console.log(error.response);
                  eventBus.$emit("notify", {
                    message: "Błąd zapisu grup!",
                    type: "warning",
                  });
                });

                if (this.$store.getters.getQuestionGroupModifiedRecords === true) {
                  eventBus.$emit("notify", {
                    message: "Grupa została dodana",
                    type: "success",
                  });
                  this.$store.commit(QUESTION_GROUP_INVALID);
                }
              }

              // criteria model
              let criteriaModelCheck = false;
              if (
                Object.keys(this.criteriasSaveObject).length > 0 &&
                (this.criteriasSaveObject.insert_data.length > 0 ||
                  this.criteriasSaveObject.update_data.length > 0 ||
                  this.criteriasSaveObject.delete_data.length > 0)
              ) {
                if (this.criteriasSaveObject.question_id === null) {
                  this.criteriasSaveObject.question_id = question_id;
                }
                criteriaModelCheck = true;

                await this.$store.dispatch(SAVE_QUESTION_CRITERIA, this.criteriasSaveObject).catch((error) => {
                  console.log(error.response);
                  eventBus.$emit("notify", {
                    message: "Błąd zapisu kryteriów!",
                    type: "warning",
                  });
                });

                if (this.$store.getters.getQuestionCriteriaModifiedRecords === true) {
                  eventBus.$emit("notify", {
                    message: "Kryteria zostały dodane",
                    type: "success",
                  });
                  this.$store.commit(QUESTION_CRITERIA_INVALID);
                }
              }

              this.loading = true;
              await this.loadData(true);
              await this.initData();
              this.initClients();
              this.initAnswerStats();

              this.editMode = true;
              this.loading = false;
            }
          }
        } else {
          // szeroko pojęta edycja pytania
          if (questionModelCheck) {
            await this.$store.dispatch(SAVE_QUESTION, this.questionModel).catch((error) => {
              console.log(error.response);
              eventBus.$emit("notify", {
                message: "Błąd zapisu pytania!",
                type: "warning",
              });
            });

            if (typeof this.$store.getters.getQuestionsModifiedRecords === "number") {
              eventBus.$emit("notify", {
                message: "Pytanie zostało zmodyfikowane",
                type: "success",
              });
              this.$store.commit(QUESTION_DATA_INVALID);
              this.$store.commit(QUESTIONS_INVALID);
              this.$store.commit(QUESTION_LIST_INVALID);
            }
          }

          // group model
          let groupModel = {};
          let groupModelCheck = false;
          groupModel = this.createGroupModel(question_id);
          if (Object.values(groupModel).length > 0) {
            groupModelCheck = true;

            await this.$store.dispatch(SAVE_QUESTION_GROUP, groupModel).catch((error) => {
              console.log(error.response);
              eventBus.$emit("notify", {
                message: "Błąd zapisu grup!",
                type: "warning",
              });
            });

            if (this.$store.getters.getQuestionGroupModifiedRecords === true) {
              let text = "dodana";
              if (groupModel.mode === "update") {
                text = "zmodyfikowana";
              } else if (groupModel.mode === "delete") {
                text = "usunięta";
              }
              eventBus.$emit("notify", {
                message: `Grupa została ${text}`,
                type: "success",
              });
              this.$store.commit(QUESTION_GROUP_INVALID);
            }
          }

          // criteria model
          let criteriaModelCheck = false;
          if (
            Object.keys(this.criteriasSaveObject).length > 0 &&
            (this.criteriasSaveObject.insert_data.length > 0 ||
              this.criteriasSaveObject.update_data.length > 0 ||
              this.criteriasSaveObject.delete_data.length > 0)
          ) {
            if (this.criteriasSaveObject.question_id === null) {
              this.criteriasSaveObject.question_id = question_id;
            }
            criteriaModelCheck = true;

            await this.$store.dispatch(SAVE_QUESTION_CRITERIA, this.criteriasSaveObject).catch((error) => {
              console.log(error.response);
              eventBus.$emit("notify", {
                message: "Błąd zapisu kryteriów!",
                type: "warning",
              });
            });

            if (this.$store.getters.getQuestionCriteriaModifiedRecords === true) {
              eventBus.$emit("notify", {
                message: "Kryteria zostały zmodyfikowane",
                type: "success",
              });
              this.$store.commit(QUESTION_CRITERIA_INVALID);
            }
          }

          // sprawdzenie zmiany typu szablonu i usunięcie reguł odpowiedzi
          let oldQuestionModel = this.$store.getters.getQuestionData[question_id];
          let deleteRulesModel = [];
          if (oldQuestionModel.template_name === "defaultTextarea" || oldQuestionModel.template_name === "defaultTextbox") {
            if (this.questionModel.template_name !== "defaultTextarea" && this.questionModel.template_name !== "defaultTextbox") {
              this.answers.map((el) => {
                if (typeof el.answer_id === "number") {
                  deleteRulesModel.push(el.answer_id);
                }
              });
            }
          }
          if (deleteRulesModel.length > 0) {
            await this.$store.dispatch(SAVE_ANSWER_RULES, { deleteFromQuestion: deleteRulesModel }).catch((error) => {
              console.log(error.response);
              eventBus.$emit("notify", {
                message: "Błąd zapisu reguł!",
                type: "warning",
              });
            });

            let saveRulesResult = JSON.parse(this.$store.getters.getAnswerRulesModifiedRecords);
            if (saveRulesResult.deleteFromQuestion > 0) {
              eventBus.$emit("notify", {
                message: "Reguły zostały usunięte",
                type: "success",
              });
              this.$store.commit(ANSWER_RULES_INVALID);
            }
          }

          // answer model
          let answerModelCheck = false;
          let answerModel = { insert_model: {}, update_model: {} };
          answerModel = this.createAnswerModel(question_id);
          if (Object.keys(answerModel.insert_model).length > 0 || Object.keys(answerModel.update_model).length > 0) {
            answerModelCheck = true;
          }

          if (Object.keys(answerModel.insert_model).length > 0) {
            // przypadek klonowania odpowiedzi
            // zapis nowych odpowiedzi
            // stworzenie modeli podległych
            // zapis podległych modeli

            // answer model
            if (answerModelCheck) {
              await this.$store.dispatch(SAVE_ANSWER, answerModel).catch((error) => {
                console.log(error.response);
                eventBus.$emit("notify", {
                  message: "Błąd zapisu odpowiedzi!",
                  type: "warning",
                });
              });
              let saveAnswerResult = JSON.parse(this.$store.getters.getAnswersModifiedRecords);
              if (saveAnswerResult.insert !== null || saveAnswerResult.update !== null) {
                eventBus.$emit("notify", {
                  message: "Odpowiedzi zostały zmodyfikowane",
                  type: "success",
                });
                this.$store.commit(ANSWERS_INVALID);
                let savedIDs = JSON.parse(this.$store.getters.getAnswersModifiedRecords).insert;

                // client model
                let clientModelCheck = false;
                let clientModel = { clientModelInsert: {}, clientModelDelete: {} };
                clientModel = this.createClientModel();
                if (Object.values(clientModel.clientModelInsert).length > 0 || Object.values(clientModel.clientModelDelete).length > 0) {
                  let newClientModel = {};
                  for (let i in clientModel) {
                    let model = clientModel[i];
                    if (!newClientModel[i]) {
                      newClientModel[i] = {};
                    }
                    for (let key in model) {
                      if (savedIDs[key]) {
                        newClientModel[i][savedIDs[key]] = model[key];
                      } else {
                        newClientModel[i][key] = model[key];
                      }
                    }
                  }
                  clientModelCheck = true;

                  await this.$store.dispatch(SAVE_ANSWER_CLIENT, newClientModel).catch((error) => {
                    console.log(error.response);
                    eventBus.$emit("notify", {
                      message: "Błąd zapisu klientów!",
                      type: "warning",
                    });
                  });
                  let saveClientResult = JSON.parse(this.$store.getters.getAnswerClientModifiedRecords);
                  if (saveClientResult.insert > 0 || saveClientResult.delete > 0) {
                    eventBus.$emit("notify", {
                      message: "Klienci zostali zmodyfikowani",
                      type: "success",
                    });
                    this.$store.commit(ANSWER_CLIENT_INVALID);
                  }
                }

                // stats model
                let statsModelCheck = false;
                let statsModel = { statsModelDelete: {}, statsModelUpdate: {}, statsModelInsert: {} };
                statsModel = this.createStatsModel();
                if (
                  Object.keys(statsModel.statsModelDelete).length > 0 ||
                  Object.keys(statsModel.statsModelUpdate).length > 0 ||
                  Object.keys(statsModel.statsModelInsert).length > 0
                ) {
                  let newStatsModel = {};
                  for (let i in statsModel) {
                    let model = statsModel[i];
                    if (!newStatsModel[i]) {
                      newStatsModel[i] = {};
                    }
                    for (let key in model) {
                      if (savedIDs[key]) {
                        newStatsModel[i][savedIDs[key]] = model[key];
                        newStatsModel[i][savedIDs[key]].answer_id = savedIDs[key];
                      } else {
                        newStatsModel[i][key] = model[key];
                      }
                    }
                  }
                  statsModelCheck = true;

                  await this.$store.dispatch(SAVE_ANSWER_STATS, newStatsModel).catch((error) => {
                    console.log(error.response);
                    eventBus.$emit("notify", {
                      message: "Błąd zapisu statystyk!",
                      type: "warning",
                    });
                  });
                  let saveStatsResult = JSON.parse(this.$store.getters.getAnswerStatsModifiedRecords);
                  if (saveStatsResult.insert > 0 || saveStatsResult.update > 0 || saveStatsResult.delete > 0) {
                    eventBus.$emit("notify", {
                      message: "Statystyki zostały zmodyfikowane",
                      type: "success",
                    });
                    this.$store.commit(ANSWER_STATS_INVALID);
                  }
                }

                // tags model
                let copy_object = {};
                for (let i in savedIDs) {
                  copy_object[savedIDs[i]] = this.answerCloneIDs[i];
                }
                await this.$store.dispatch(SAVE_ANSWER_TAG, { copy: copy_object }).catch((error) => {
                  console.log(error.response);
                  eventBus.$emit("notify", {
                    message: "Błąd zapisu tagów!",
                    type: "warning",
                  });
                });
                let saveTagResult = JSON.parse(this.$store.getters.getAnswerTagsModifiedRecords);
                if (saveTagResult.insert > 0 || saveTagResult.create > 0 || saveTagResult.delete > 0 || saveTagResult.copy > 0) {
                  eventBus.$emit("notify", {
                    message: "Tagi zostały dodane",
                    type: "success",
                  });
                  this.$store.commit(ANSWER_TAGS_INVALID);
                }

                // categorys model
                await this.$store.dispatch(SAVE_ANSWER_CATEGORY, { copy: copy_object }).catch((error) => {
                  console.log(error.response);
                  eventBus.$emit("notify", {
                    message: "Błąd zapisu kategorii!",
                    type: "warning",
                  });
                });
                let saveCategoryResult = JSON.parse(this.$store.getters.getAnswerCategorysModifiedRecords);
                if (saveCategoryResult.insert > 0 || saveCategoryResult.delete > 0 || saveCategoryResult.copy > 0) {
                  eventBus.$emit("notify", {
                    message: "Kategorie zostały dodane",
                    type: "success",
                  });
                  this.$store.commit(ANSWER_CATEGORYS_INVALID);
                }

                // rules model
                await this.$store.dispatch(SAVE_ANSWER_RULES, { copy: copy_object }).catch((error) => {
                  console.log(error.response);
                  eventBus.$emit("notify", {
                    message: "Błąd zapisu reguł!",
                    type: "warning",
                  });
                });
                let saveRulesResult = JSON.parse(this.$store.getters.getAnswerRulesModifiedRecords);
                if (saveRulesResult.insert > 0 || saveRulesResult.delete > 0 || saveRulesResult.copy > 0) {
                  eventBus.$emit("notify", {
                    message: "Reguły zostały dodane",
                    type: "success",
                  });
                  this.$store.commit(ANSWER_RULES_INVALID);
                }
              }
            }

            this.loading = true;
            await this.loadData(true);
            await this.initData();
            this.initClients();
            this.initAnswerStats();

            this.editMode = true;
            this.loading = false;
          } else {
            // przypadek edycji i deaktywacji istniejących odpowiedzi i pozostałych danych
            // answer model
            if (answerModelCheck) {
              await this.$store.dispatch(SAVE_ANSWER, answerModel).catch((error) => {
                console.log(error.response);
                eventBus.$emit("notify", {
                  message: "Błąd zapisu odpowiedzi!",
                  type: "warning",
                });
              });
              if (JSON.parse(this.$store.getters.getAnswersModifiedRecords).update > 0) {
                eventBus.$emit("notify", {
                  message: "Odpowiedzi zostały zmodyfikowane",
                  type: "success",
                });
                this.$store.commit(ANSWERS_INVALID);
              }
            }

            // client model
            let clientModelCheck = false;
            let clientModel = { clientModelInsert: {}, clientModelDelete: {} };
            clientModel = this.createClientModel();
            if (Object.values(clientModel.clientModelInsert).length > 0 || Object.values(clientModel.clientModelDelete).length > 0) {
              clientModelCheck = true;

              await this.$store.dispatch(SAVE_ANSWER_CLIENT, clientModel).catch((error) => {
                console.log(error.response);
                eventBus.$emit("notify", {
                  message: "Błąd zapisu klientów!",
                  type: "warning",
                });
              });
              let saveResult = JSON.parse(this.$store.getters.getAnswerClientModifiedRecords);
              if (saveResult.insert > 0 || saveResult.delete > 0) {
                eventBus.$emit("notify", {
                  message: "Klienci zostali zmodyfikowani",
                  type: "success",
                });
                this.$store.commit(ANSWER_CLIENT_INVALID);
              }
            }

            // stats model
            let statsModelCheck = false;
            let statsModel = { statsModelDelete: {}, statsModelUpdate: {}, statsModelInsert: {} };
            statsModel = this.createStatsModel();
            if (
              Object.keys(statsModel.statsModelDelete).length > 0 ||
              Object.keys(statsModel.statsModelUpdate).length > 0 ||
              Object.keys(statsModel.statsModelInsert).length > 0
            ) {
              statsModelCheck = true;

              await this.$store.dispatch(SAVE_ANSWER_STATS, statsModel).catch((error) => {
                console.log(error.response);
                eventBus.$emit("notify", {
                  message: "Błąd zapisu statystyk!",
                  type: "warning",
                });
              });
              let saveResult = JSON.parse(this.$store.getters.getAnswerStatsModifiedRecords);
              if (saveResult.insert > 0 || saveResult.update > 0 || saveResult.delete > 0) {
                eventBus.$emit("notify", {
                  message: "Statystyki zostały zmodyfikowane",
                  type: "success",
                });
                this.$store.commit(ANSWER_STATS_INVALID);
              }
            }

            this.loading = true;
            await this.loadData(true);
            await this.initData();
            this.initClients();
            this.initAnswerStats();

            this.editMode = true;
            this.loading = false;
          }
        }
      } else {
        if (this.questionModel.question_content.length === 0) {
          eventBus.$emit("notify", {
            message: "Treść pytania nie może być pusta!",
            type: "warning",
          });
        }
      }
    },
    initData() {
      this.criteriaKey += 1; // klucz komponentu kryteriów, służy do przeładowywania komponentu przy wyczytywaniu danych
      this.criteriasSaveObject = {};
      let question = this.$store.getters.getQuestionData[this.question_id];
      if (question === undefined) {
        this.questionModel = {
          question_id: null,
          question_content: "",
          question_type: "",
          priority: 0,
          template_name: "",
          expiration_days: 0,
          question_footer: "",
          question_is_active: "",
          force_display: false,
          auto_pause: false,
        };
      } else {
        this.questionModel = Object.assign(this.questionModel, question);
        if (this.questionModel.force_display === 1) {
          this.questionModel.force_display = true;
        } else {
          this.questionModel.force_display = false;
        }
        if (this.questionModel.auto_pause === 1) {
          this.questionModel.auto_pause = true;
        } else {
          this.questionModel.auto_pause = false;
        }
      }
      this.questionModelComparison = JSON.parse(JSON.stringify(this.questionModel));
      let group = this.$store.getters.getQuestionGroup[this.question_id];
      if (group !== undefined) {
        this.groupModel = Object.assign(this.groupModel, group);
        this.groupModel.question_id = this.question_id;
      } else {
        this.groupModel = {
          question_id: this.question_id,
          group_id: null,
        };
      }

      this.answers = [];
      if (this.$store.getters.getAnswers[this.question_id] !== undefined) {
        this.answers = Object.values(JSON.parse(JSON.stringify(this.$store.getters.getAnswers[this.question_id])));
      }
      this.answers = this.answers.sort((a, b) => {
        if (a.answer_position > b.answer_position) return 1;
        if (a.answer_position < b.answer_position) return -1;

        if (a.answer_id > b.answer_id) return 1;
        if (a.answer_id < b.answer_id) return -1;
      });
      this.answers_table = Object.values(this.answers);
      this.moved();
    },
    initClients() {
      let answer_id = null;
      let answer_client = this.answer_client;
      this.clientModel.client_id = {};
      let clients = this.$store.getters.getClients;
      let data = {};
      let cnt = 1;
      for (let i in this.answers) {
        answer_id = this.answers[i].answer_id;
        if (answer_client[answer_id] !== undefined) {
          let clients_array = answer_client[answer_id];
          for (let j in clients_array) {
            if (this.clientModel.client_id[answer_id] === undefined) {
              this.clientModel.client_id[answer_id] = [];
            }
            this.clientModel.client_id[answer_id].push(clients_array[j].client_id);
          }
        } else {
          this.clientModel.client_id[answer_id] = [];
        }
      }
      this.clientCompareModel = JSON.parse(JSON.stringify(this.clientModel));
    },
    initAnswerStats() {
      this.answerStats = {};
      let answers = this.answers;
      for (let i in answers) {
        let answer_id = answers[i].answer_id;
        if (this.answer_stats[answer_id] !== undefined) {
          this.answerStats[answer_id] = this.answer_stats[answer_id].description;
        }
      }
    },
    criteriasHandler() {
      if (this.editCriterias) {
        eventBus.$emit("saveCriterias");
      } else {
        this.editCriterias = true;
      }
    },
    handleSave() {
      if (!this.editAnswers && !this.editCriterias) {
        this.saveData();
      } else {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });

        let text = "";
        if (this.editAnswers && this.editCriterias) {
          text = " odpowiedzi i kryteriów";
        } else if (this.editAnswers) {
          text = " odpowiedzi";
        } else if (this.editCriterias) {
          text = " kryteriów";
        }
        if (this.editAnswers) var varThis = this;
        swalWithBootstrapButtons
          .fire({
            title: `Aby zapisać zmiany zakończ edycję ${text}`,
            icon: "warning",
            confirmButtonText: "Ok",
          })
          .then(function () {
            return;
          })
          .catch(() => {
            return;
          });
      }
    },
    editRouting(isEdit, row) {
      var varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Czy chcesz kontynuować bez zapisywania?",
          text: `Zmiany zostaną odrzucone`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isDismissed) {
            return;
          }
          if (res.isConfirmed) {
            if (isEdit) {
              varThis.$router.push("/questions/answer_form/qid=" + varThis.question_id + ",aid=" + row.answer_id);
            } else {
              varThis.$router.push("/questions/answer_form/qid=" + varThis.question_id);
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    async forceReload() {
      this.$store.commit(QUESTION_GROUP_INVALID);
      this.$store.commit(QUESTION_DATA_INVALID);
      this.$store.commit(QUESTIONS_INVALID);
      this.$store.commit(QUESTION_LIST_INVALID);
      this.$store.commit(QUESTION_CRITERIA_INVALID);
      this.$store.commit(ANSWER_CLIENT_INVALID);
      this.$store.commit(ANSWERS_INVALID);
      this.$store.commit(ANSWER_STATS_INVALID);
      this.$store.commit(ANSWER_TAGS_INVALID);
      this.$store.commit(ANSWER_CATEGORYS_INVALID);
      this.$store.commit(ANSWER_RULES_INVALID);
      this.loading = true;
      await this.loadData(true);
      await this.initData();
      this.initClients();
      this.initAnswerStats();

      this.loading = false;
    },
    showCloneModal() {
      // wyświetlenie modala uploadu pliku
      this.$modal.show(
        CloneQuestionForm,
        {
          props_question_id: this.question_id,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(CloneQuestionForm);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "400px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
  mounted() {
    eventBus.$on("criteriasObject", (object) => {
      if (typeof object === "string") {
        eventBus.$emit("notify", {
          message: object,
          type: "warning",
        });
        this.editCriterias = true;
      } else {
        this.criteriasSaveObject = object;
        this.editCriterias = false;
      }
    });
    eventBus.$on("questionCloned", () => {
      this.$store.commit(QUESTION_GROUP_INVALID);
      this.$store.commit(QUESTION_DATA_INVALID);
      this.$store.commit(QUESTIONS_INVALID);
      this.$store.commit(QUESTION_LIST_INVALID);
      this.$store.commit(QUESTION_CRITERIA_INVALID);
      this.$store.commit(ANSWER_CLIENT_INVALID);
      this.$store.commit(ANSWERS_INVALID);
      this.$store.commit(ANSWER_STATS_INVALID);
      this.$store.commit(ANSWER_TAGS_INVALID);
      this.$store.commit(ANSWER_CATEGORYS_INVALID);
      this.$store.commit(ANSWER_RULES_INVALID);
      this.$router.push("/questions");
    });
  },
  async created() {
    this.loading = true;
    await this.loadData(true);
    if (!!this.question_id) {
      await this.initData();
      this.initClients();
      this.initAnswerStats();

      this.editMode = true;
    } else {
      this.editMode = false;
    }
    this.loading = false;
    eventBus.$on("forceReload", () => {
      this.forceReload();
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>

<style scoped>
/* draggable */
.flip-list-move {
  transition: transform 0.5s;
}

.flip-list-leave-active {
  display: none !important;
}

.no-move {
  transition: transform 0s;
}

.no-leave-active {
  display: none !important;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
  min-width: 200px;
}

.list-drag-item {
  cursor: move;
}

.list-drag-item i {
  cursor: pointer;
}

tr {
  text-align: center;
}
</style>
