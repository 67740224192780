import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_MACRO,
  MACRO_LOADING,
  MACRO_LOADING_SUCCESS,
  MACRO_LOADING_ERROR,
  MACRO_INVALID,
  SAVE_MACRO,
  MACRO_SAVING,
  MACRO_SAVING_SUCCESS,
  MACRO_SAVING_ERROR
} from '../../actions/complaints/macro'

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getMacro",
  loadActionName: LOAD_MACRO,
  loadingMutationName: MACRO_LOADING,
  loadingSuccessMutationName: MACRO_LOADING_SUCCESS,
  loadingErrorMutationName: MACRO_LOADING_ERROR,
  invalidMutationName: MACRO_INVALID,
  saveActionName: SAVE_MACRO,
  savingMutationName: MACRO_SAVING,
  savingSuccessMutationName: MACRO_SAVING_SUCCESS,
  savingErrorMutationName: MACRO_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/complaints/macro",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/complaints/macro",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
