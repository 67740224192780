import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_TESTS_REQUESTS,
  TESTS_REQUESTS_LOADING,
  TESTS_REQUESTS_LOADING_SUCCESS,
  TESTS_REQUESTS_LOADING_ERROR,
  TESTS_REQUESTS_INVALID,
  SAVE_TESTS_REQUEST,
  TESTS_REQUEST_SAVING,
  TESTS_REQUEST_SAVING_SUCCESS,
  TESTS_REQUEST_SAVING_ERROR
} from '../../actions/tests/tests_requests'

const storeObjectConfig = {
  objectName: "TestsResults",
  getterName: "getTestsRequests",
  loadActionName: LOAD_TESTS_REQUESTS,
  loadingMutationName: TESTS_REQUESTS_LOADING,
  loadingSuccessMutationName: TESTS_REQUESTS_LOADING_SUCCESS,
  loadingErrorMutationName: TESTS_REQUESTS_LOADING_ERROR,
  invalidMutationName: TESTS_REQUESTS_INVALID,
  saveActionName: SAVE_TESTS_REQUEST,
  savingMutationName: TESTS_REQUEST_SAVING,
  savingSuccessMutationName: TESTS_REQUEST_SAVING_SUCCESS,
  savingErrorMutationName: TESTS_REQUEST_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/tests/testsRequests",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/tests/testsRequests",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
