<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title" v-if="mode === 'edit'">Edytuj konfigurację</h5>
          <h5 class="title" v-else>Dodaj konfigurację</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row" v-if="this.$store.state.project_dict.loading_status === 'success'">
        <div class="col-md-12">
          <div class="form-group">
            <label>Projekt</label>
            <el-select
              id="hotjarConfigProjectSelect"
              v-on:visible-change="getUpperLeftSelectWidth()"
              v-on:clear="clearVersions()"
              v-on:handleOptionClick="clearVersions()"
              v-model="model.project_id"
              placeholder="Wszystkie"
              name="project"
              data-vv-as="Projekt"
              style="width: 100%;"
              filterable
              clearable
            >
              <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                <el-option
                  :style="upperLeftSelectWidth !== null ? 'width: ' + upperLeftSelectWidth + 'px' : 'width: 300px'"
                  :id="'hotjarConfigProjectSelectOption_' + item.project_id"
                  v-for="item in group.options"
                  :key="item.project_id"
                  :label="item.project_name + ' [' + item.project_id + ']'"
                  :value="item.project_id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </div>
        </div>
      </div>
      <div class="row" v-if="this.$store.state.project_dict.loading_status === 'success'">
        <div class="col-md-12">
          <div class="form-group">
            <label>Wersja</label>
            <el-select v-model="model.module" name="module" placeholder="Wszystkie" style="width: 100%;" filterable clearable>
              <el-option-group v-for="group in projectModules" :key="group.label" :label="group.label">
                <el-option
                  v-for="version in group.options"
                  :label="version.module + ' [' + version.tags + ']'"
                  :value="version.module"
                  :key="version.module"
                ></el-option>
              </el-option-group>
            </el-select>
          </div>
        </div>
      </div>
      <div class="row">
        <p-button style="margin-right: 10px;" type="warning" @click.prevent="$emit('close')">Anuluj</p-button>
        <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Select, Option, OptionGroup, Tag } from "element-ui";

// event bus
import { eventBus } from "src/main";

import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";
import { SAVE_HOTJAR_CONFIG } from "src/store/actions/tests/hotjar_config";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tag.name]: Tag,
  },
  props: ["mode", "row"],
  data() {
    return {
      loading: false,
      model: {
        project_id: this.row.project_id,
        module: this.row.module,
      },
      versionProjectObject: {},
      upperLeftSelectWidth: null,
    };
  },
  methods: {
    async initLoad(force) {
      this.loading = true;

      if (force) {
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(PROJECTS_ALL_INVALID);
      }

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    getUpperLeftSelectWidth() {
      this.upperLeftSelectWidth = document.getElementById("hotjarConfigProjectSelect").offsetWidth;
    },
    clearVersions() {
      this.model.module = "";
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      let record = {
        insert: this.model,
        delete: this.row.project_id == "" ? null : _.cloneDeep(this.row),
      };

      if (record.insert.project_id == "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać projekt!",
          type: "warning",
        });
        return;
      }

      console.log(record);
      if (record.delete) {
        record.delete.module = record.delete.module === "Wszystkie" ? null : record.delete.module;
      }

      await this.$store.dispatch(SAVE_HOTJAR_CONFIG, record).catch(error => {
        let message = "";
        console.log(error.response);
        switch (error.response.data.code) {
          case 406:
            message = "Nie wybrano projektu!";
            break;
          case 409:
            message = "Wybrana konfiguracja już istnieje!";
            break;
          default:
            message = "Błąd zapisu danych!";
        }
        // zapisanie danych do bazy
        console.log(error.response);
        eventBus.$emit("notify", {
          message: message,
          type: "warning",
        });
      });

      if (this.$store.getters.getHotjarConfigModifiedRecords === true) {
        // wyświetlenie informacji o zapisaniu
        eventBus.$emit("notify", {
          message: "Rekord został zmodyfikowany",
          type: "success",
        });

        eventBus.$emit("hotjarConfigSaved");
        this.$emit("close");
      }
    },
  },
  computed: {
    projects() {
      return this.$store.getters.getProjectDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map(x => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    modules() {
      return this.$store.getters.getProjectsAll;
    },
    projectModules() {
      // wszystkie moduły projektu
      let choosedProjects = this.model.project_id == "" ? [] : [this.model.project_id];
      if (Array.isArray(choosedProjects) && choosedProjects.length > 0) {
        let projects = this.$store.getters.getProjectsAll;
        let activeProjects = choosedProjects;
        let filteredData = {};
        let temp = {};
        const sortedData = {};
        for (let i in activeProjects) {
          for (let [key, project] of Object.entries(projects)) {
            // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
            if (project.project_id === activeProjects[i]) {
              temp[project.module] = Object.assign({}, project);
              filteredData[project.project_id] = Object.assign({}, temp);
            }
          }
          temp = {};
        }

        let sourceData = [];

        for (let i in filteredData) {
          // sortowanie danych
          let tempData = filteredData[i];
          let sourceData = {};
          for (let ver of Object.keys(tempData)) {
            // iteracja po nazwach modułów danego projektu
            let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie pierwszej liczby
            filteredData[i][ver].number = res;
          }
        }

        let resultModules = [];

        for (let project_id in filteredData) {
          let projectModules = filteredData[project_id];
          sourceData = Object.values(projectModules).filter(x => x.is_active);
          sourceData.sort((a, b) => a.number - b.number);
          let result = {};
          for (let i in sourceData) {
            this.versionProjectObject[sourceData[i].module] = parseInt(project_id);
            result[sourceData[i].module] = sourceData[i];
          }
          resultModules.push({
            project_id,
            project_id,
            label: this.projects[project_id].project_name,
            options: result,
          });
        }

        return resultModules;
      }
      return [];
    },
  },
  created() {
    this.initLoad(true);
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}
</style>
