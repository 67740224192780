<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-10">
        <h5 class="title">Dane użytkownika</h5>
      </div>
      <div class="col-sm-2">
        <router-link
          :to="{
            name: 'Reklamacje',
            params: {
              company: $route.params.company,
              mailbox: $route.params.mailbox,
              complaintID: $route.params.complaintID,
            },
          }"
        >
          <p-button type="default" link v-if="complaintCheck"> Powrót do reklamacji </p-button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <label>Wpisz ID użytkownika</label>
              <fg-input :error="getError('ID użytkownika')" data-vv-as="ID użytkownika">
                <el-input
                  :disabled="selectedFilter == 'teledata'"
                  type="text"
                  inputmode="numeric"
                  name="ID użytkownika"
                  clearable
                  placeholder="ID użytkownika"
                  v-model="model.user_id"
                  :error="getError('ID użytkownika')"
                >
                </el-input>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label>Wpisz numer telefonu</label>
              <fg-input :error="getError('Numer telefonu')" data-vv-as="Numer telefonu">
                <el-input
                  :disabled="selectedFilter == 'user_id'"
                  type="tel"
                  name="Numer telefonu"
                  clearable
                  placeholder="Numer telefonu"
                  v-model="model.phone"
                  :error="getError('Numer telefonu')"
                >
                </el-input>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label>lub adres email</label>
              <fg-input :error="getError('Adres email')" data-vv-as="Adres email">
                <el-input
                  :disabled="selectedFilter == 'user_id'"
                  type="email"
                  name="Adres email"
                  clearable
                  placeholder="Adres email"
                  v-model="model.mail"
                  v-validate="!model.mail.includes('*') ? modelValidations.mail : ''"
                  :error="getError('Adres email')"
                >
                </el-input>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <p-button type="success" size="sm" @click="loadData">Sprawdź</p-button>
            </div>
            <div class="col-sm-7" v-if="this.$store.state.user_data.loading_status === 'success' && Object.keys(userData.userQuiz).length > 0">
              <p-button type="info" size="sm" @click="showQuizData = !showQuizData">{{
                showQuizData ? "Schowaj konkurs" : "Pokaż konkurs"
              }}</p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="this.$store.state.user_data.loading_status === 'success'">
      <div class="card-body">
        <div class="row">
          <el-table
            :data="Object.values(userData.userData)"
            style="width: 100%"
            :default-sort="{ prop: 'AddTime', order: 'descending' }"
            :cell-style="rowStyle"
          >
            <el-table-column type="expand">
              <template v-slot="props">
                <div class="row expand">
                  <div class="col-sm-6">
                    <h6><u>Zweryfikowane</u></h6>
                    <p><b>Imię: </b>{{ getParam(props.row.Name) }}</p>
                    <p><b>Nazwisko: </b>{{ getParam(props.row.Surname) }}</p>
                    <p><b>Telefon: </b>{{ getParam(props.row.Phone) }}</p>
                    <p>
                      <b>Email: </b><span :style="emailCheck(props.row) ? 'color: red;' : ''">{{ getParam(props.row.Email) }}</span>
                    </p>
                    <p><b>Data urodzenia: </b>{{ getParam(props.row.Birthdate) }}</p>
                    <p><b>Adres: </b>{{ getUserAdress("tc", props.row) }}</p>
                  </div>
                  <div class="col-sm-6">
                    <h6><u>Oryginalne</u></h6>
                    <p><b>Imię: </b>{{ getParam(props.row.NameUD) }}</p>
                    <p><b>Nazwisko: </b>{{ getParam(props.row.SurnameUD) }}</p>
                    <p><b>Telefon: </b>{{ getParam(props.row.PhoneUD) }}</p>
                    <p>
                      <b>Email: </b><span :style="emailCheck(props.row) ? 'color: red;' : ''">{{ getParam(props.row.EmailUD) }}</span>
                    </p>
                    <p><b>Data urodzenia: </b>{{ getParam(props.row.BirthdateUD) }}</p>
                    <p><b>Adres: </b>{{ getUserAdress("ud", props.row) }}</p>
                  </div>
                </div>
                <br />
                <div class="row expand">
                  <div class="col-sm-12">
                    <p>
                      <b>Projekt: </b><span>{{ "[" + props.row.ProjectID + "] " + props.row.ProjectName + " - " }}</span>
                      <span>{{ props.row.Module + " " }}</span>
                      <span v-if="projects[props.row.Module]">{{ projects[props.row.Module].version }}</span>
                    </p>
                    <p>
                      <b>Dom mediowy: </b
                      >{{
                        props.row.MediaHouseId !== 0 && props.row.MediaHouseId !== null
                          ? "[" + props.row.MediaHouseId + "] " + props.row.MediaHouseName
                          : "brak"
                      }}
                    </p>
                    <p><b>Adres IP: </b>{{ props.row.IpAddress !== "" && props.row.IpAddress !== null ? props.row.IpAddress : "brak" }}</p>
                    <p><b>UserDataID: </b>{{ props.row.UserDataID }}</p>
                  </div>
                </div>
                <br />
                <div class="row expand">
                  <div class="col-sm-12">
                    <appConsentsComponent :consents="userData.userConsent[props.row.UserDataID]" type="consent" />
                    <appConsentsComponent :consents="userData.userConsent[props.row.UserDataID]" type="disclosure" />
                  </div>
                </div>
                <br />
                <div class="row expand">
                  <div class="col-sm-12">
                    <p><b>Historia akcji:</b></p>
                    <p
                      class="overflow"
                      v-for="(action, index) in userData.userRemoval[props.row.UserDataID]"
                      :key="action.Action + '_' + action.ActionDate + '_' + index"
                    >
                      <el-tooltip class="item" effect="light" :content="action.Action" placement="top" :open-delay="500">
                        <span>{{ action.ActionDate + " " + action.Action }}</span>
                      </el-tooltip>
                    </p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Dodano" property="AddTime" width="180" sortable></el-table-column>
            <el-table-column label="Nr tel" property="Phone" width="150" sortable></el-table-column>
            <el-table-column label="Email" property="Email" min-width="160" sortable></el-table-column>
            <el-table-column label="Imię" property="Name" align="center" min-width="120" sortable></el-table-column>
            <el-table-column label="Nazwisko" property="Surname" align="center" min-width="140" sortable></el-table-column>
            <el-table-column label="Zgody" property="Consents" align="center" width="120" sortable>
              <template v-slot="props">
                <span>{{ props.row.Consents }}</span>
              </template>
            </el-table-column>
            <el-table-column property="ConsentsBadge" align="center" width="60">
              <template v-slot="props">
                <el-tooltip class="item" effect="light" content="Liczba zgód" placement="top" :open-delay="500">
                  <div class="consentsBadge">{{ checkConsents(props.row.UserDataID, true).result }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="" property="ComplaintCheck" align="center" width="80" v-if="complaintCheck">
              <template v-slot="props">
                <div v-if="checkConsents(props.row.UserDataID).result">
                  <el-tooltip class="item" effect="light" content="Wybierz dane do reklamacji" placement="top" :open-delay="500">
                    <p-button @click="goToComplaints(props.row)" type="success" size="sm">
                      <i class="fa fa-share-square-o" aria-hidden="true"></i>
                    </p-button>
                  </el-tooltip>
                </div>
                <div v-else-if="checkConsents(props.row.UserDataID).asterix">
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Wybierz dane do reklamacji ze zgodami technicznymi"
                    placement="top"
                    :open-delay="500"
                  >
                    <p-button @click="goToComplaints(props.row)" type="warning" size="sm">
                      <i class="fa fa-share-square-o" aria-hidden="true"></i>
                    </p-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="card" v-if="this.$store.state.user_data.loading_status === 'success' && showQuizData">
      <div class="card-body">
        <div class="row">
          <el-table :data="Object.values(userData.userQuiz)" style="width: 100%" :default-sort="{ prop: 'AddTime', order: 'descending' }">
            <el-table-column type="expand">
              <template v-slot="props">
                <p><b>Data urodzenia: </b>{{ getParam(props.row.Birthdate) }}</p>
                <p><b>Adres: </b>{{ getUserAdress("tc", props.row) }}</p>
                <p>
                  <b>Projekt: </b><span>{{ "[" + props.row.ProjectID + "] " + props.row.ProjectName + " - " }}</span>
                  <span>{{ props.row.Module + " " }}</span>
                  <span v-if="projects[props.row.Module]">{{ projects[props.row.Module].version }}</span>
                </p>
                <p>
                  <b>Dom mediowy: </b
                  >{{
                    props.row.MediaHouseId !== 0 && props.row.MediaHouseId !== null
                      ? "[" + props.row.MediaHouseId + "] " + props.row.MediaHouseName
                      : "brak"
                  }}
                </p>
                <p><b>Adres IP: </b>{{ props.row.IpAddress !== "" && props.row.IpAddress !== null ? props.row.IpAddress : "brak" }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Dodano" property="AddTime" min-width="100" sortable></el-table-column>
            <el-table-column label="Nr tel" property="Phone" min-width="100" sortable></el-table-column>
            <el-table-column label="Email" property="Email" min-width="120" sortable></el-table-column>
            <el-table-column label="Imię" property="Name" align="center" min-width="120" sortable></el-table-column>
            <el-table-column label="Nazwisko" property="Surname" align="center" min-width="140" sortable></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Select, Option, Input, Tooltip, Tag } from "element-ui";

import Swal from "sweetalert2";

// vuex actions
import { LOAD_USER_DATA, USER_DATA_INVALID } from "src/store/actions/complaints/user_data";
import { LOAD_USER_SALES, USER_SALES_INVALID } from "src/store/actions/complaints/user_sales";
import { LOAD_PROJECTS } from "src/store/actions/questions/projects";

import ConsentsComponent from "src/components/Utils4/complaints/views/ConsentsComponent.vue";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Tooltip.name]: Tooltip,
    [Tag.name]: Tag,
    appConsentsComponent: ConsentsComponent,
  },
  data() {
    return {
      loading: false,
      model: {
        user_id: "",
        phone: "",
        mail: "",
      },
      modelValidations: {
        mail: {
          regex: "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}",
        },
      },
      showQuizData: false,
      complaintCheck: false,
    };
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    },
    userSales() {
      return this.$store.getters.getUserSales;
    },
    projects() {
      const data = this.$store.getters.getProjects;
      let result = {};
      for (let i in data) {
        result[data[i].module] = data[i];
      }
      return result;
    },
    suggestedRecord() {
      const consents = this.userData.userConsent;
      const data = this.userData.userData;

      let consentsCounter = {};
      let result;

      for (let udid in consents) {
        consentsCounter[udid] = Object.values(consents[udid]).filter((x) => {
          return x.Consent[0] !== "*" && x.Type != 0;
        }).length;
      }

      const maxCounter = Math.max(...Object.values(consentsCounter));

      let tempDate;

      for (let udid in consentsCounter) {
        if (consentsCounter[udid] === maxCounter) {
          if (!tempDate || tempDate < data[udid].AddTime) {
            tempDate = data[udid].AddTime;
            result = udid;
          }
        }
      }

      return result;
    },
    selectedFilter() {
      if (this.model.user_id.length > 0) {
        return "user_id";
      }
      if (this.model.mail.length > 0 || this.model.phone.length > 0) {
        return "teledata";
      }
      return null;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validatePhone(phone) {
      if (!phone) {
        return false;
      }
      let validatedPhone = phone.replace(/[^0-9]/g, "");
      validatedPhone = validatedPhone.length == 9 ? "48" + validatedPhone : validatedPhone;
      if (validatedPhone.length != 11) {
        return false;
      } else {
        this.model.phone = validatedPhone;
        return validatedPhone;
      }
    },
    async loadData(force) {
      if (this.model.phone.length < 1 && this.model.mail.length < 1 && this.model.user_id.length < 1) {
        eventBus.$emit("notify", {
          message: "Należy podać dane do wyszukania",
          type: "warning",
        });
        return;
      }
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      let phone = "";
      if (this.model.phone.length > 0) {
        phone = this.validatePhone(this.model.phone);
      }

      if (phone !== "" && !phone) {
        eventBus.$emit("notify", {
          message: "Nieprawidłowy numer telefonu",
          type: "warning",
        });
        return;
      }

      this.loading = true;
      this.showQuizData = false;

      const filters = {
        phone: phone,
        mail: this.model.mail,
        user_id: this.model.user_id,
      };

      this.$store.commit(USER_DATA_INVALID);
      this.$store.commit(USER_SALES_INVALID);

      this.$store.dispatch(LOAD_PROJECTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_USER_SALES, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_USER_DATA, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    getEmail({ Email, EmailUD }) {
      if (typeof Email === "string" && Email.length > 0) {
        return Email;
      } else if (typeof EmailUD === "string" && EmailUD.length > 0) {
        return EmailUD;
      } else {
        return "";
      }
    },
    getPhone({ Phone, PhoneUD }) {
      if (Phone !== 0) {
        return Phone;
      } else if (PhoneUD !== 0) {
        return PhoneUD;
      } else {
        return "";
      }
    },
    getUserAdress(db, { Street, StreetUD, HouseNumber, HouseNumberUD, ApartmentNumber, ApartmentNumberUD, PostCode, PostCodeUD, City, CityUD }) {
      let adress = "";
      let street,
        house_number,
        apartment_number,
        postcode,
        city = "";
      if (db === "ud") {
        street = StreetUD;
        house_number = HouseNumberUD;
        apartment_number = ApartmentNumberUD;
        postcode = PostCodeUD;
        city = CityUD;
      } else {
        street = Street;
        house_number = HouseNumber;
        apartment_number = ApartmentNumber;
        postcode = PostCode;
        city = City;
      }
      if (street === "" || street === null) {
        if (postcode !== "" && postcode !== null) {
          adress += postcode + " ";
        }
        if (city !== "" && city !== null) {
          adress += city;
        }
        if (adress !== "") {
          return adress;
        } else {
          return "brak";
        }
      }
      adress = street + " " + house_number;
      adress += apartment_number !== null && apartment_number !== "" ? "/" + apartment_number : "";
      adress += ", " + this.getPostcode(postcode) + " " + city;
      return adress;
    },
    getPostcode(code) {
      if (!code) return "";
      if (code.length === 6) return code;
      let postcode = ("00000" + code).slice(-5);
      return postcode.slice(0, 2) + "-" + postcode.slice(2, 5);
    },
    getParam(param) {
      if (param === null || param === "" || param === 0) {
        return "brak";
      } else {
        return param;
      }
    },
    emailCheck({ Email, EmailUD }) {
      return Email !== EmailUD;
    },
    checkConsents(userDataID, ifCounter = false) {
      const data = this.userData.userConsent[userDataID];
      let result = { result: false, asterix: false };
      let counter = 0;
      if (data) {
        for (let i in data) {
          if (data[i].Consent[0] === "*") {
            result.asterix = true;
          }
          if (data[i].Consent[0] !== "*" && data[i].Type != 0) {
            counter += 1;
          }
        }
        if (counter > 0) {
          result.result = true;
        }
      }
      if (ifCounter) {
        result.result = counter;
      }
      return result;
    },
    goToComplaints(row) {
      if (this.checkConsents(row.UserDataID).result || this.checkConsents(row.UserDataID).asterix) {
        if (!row.Active) {
          const varThis = this;

          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: `btn btn-success btn-fill`,
              cancelButton: "btn btn-light btn-fill",
            },
            buttonsStyling: false,
          });

          swalWithBootstrapButtons
            .fire({
              title: `Czy chcesz pobrać dane zlecone do usunięcia?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Pobierz",
              cancelButtonText: "Anuluj",
              reverseButtons: true,
            })
            .then(async (res) => {
              if (res.isDismissed) {
                return;
              }
              if (res.isConfirmed) {
                varThis.$router.push({
                  name: "Reklamacje",
                  params: {
                    row: row,
                    consents: varThis.userData.userConsent[row.UserDataID],
                    sales: varThis.userSales,
                    mailbox: varThis.$route.params.mailbox,
                    company: varThis.$route.params.company,
                    complaintID: varThis.$route.params.complaintID,
                    searchKeys: varThis.model,
                  },
                });
              }
            })
            .catch(() => {
              return;
            });
        } else {
          if (this.checkConsents(row.UserDataID).asterix) {
            const varThis = this;

            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: `btn btn-success btn-fill`,
                cancelButton: "btn btn-light btn-fill",
              },
              buttonsStyling: false,
            });

            swalWithBootstrapButtons
              .fire({
                title: `Uwaga! Zgody w wybranym rekordzie są techniczne - tylko do użytku wewnętrznego!`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Pobierz",
                cancelButtonText: "Anuluj",
                reverseButtons: true,
              })
              .then(async (res) => {
                if (res.isDismissed) {
                  return;
                }
                if (res.isConfirmed) {
                  varThis.$router.push({
                    name: "Reklamacje",
                    params: {
                      row: row,
                      consents: varThis.userData.userConsent[row.UserDataID],
                      sales: varThis.userSales,
                      mailbox: varThis.$route.params.mailbox,
                      company: varThis.$route.params.company,
                      complaintID: varThis.$route.params.complaintID,
                      searchKeys: varThis.model,
                    },
                  });
                }
              })
              .catch(() => {
                return;
              });
          } else {
            this.$router.push({
              name: "Reklamacje",
              params: {
                row: row,
                consents: this.userData.userConsent[row.UserDataID],
                sales: this.userSales,
                mailbox: this.$route.params.mailbox,
                company: this.$route.params.company,
                complaintID: this.$route.params.complaintID,
                searchKeys: this.model,
              },
            });
          }
        }
      }
    },
    rowStyle({ row }) {
      if (!row.Active) {
        return "background-color: #ffabb3;";
      }
      if (row.UserDataID === Number(this.suggestedRecord)) {
        return "background-color: #bdffbd;";
      }
      return "";
    },
  },
  created() {
    let params = this.$route.params;
    if (params.phone) {
      this.model.phone = params.phone;
      this.complaintCheck = true;
    }
    if (params.mail) {
      this.model.mail = params.mail;
      this.complaintCheck = true;
    }
    if (this.complaintCheck) {
      this.loadData(true);
    }
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy() {
    this.$store.commit(USER_DATA_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="scss" scoped>
.center {
  justify-content: center;
  align-content: center;
  display: flex;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.consentsBadge {
  font-size: 1.1em;
  font-weight: bold;
  color: #007bff;
  width: 25px;
  background-color: white;
  border: 1px solid #007bff;
  border-radius: 3px;
  display: inline-block;
}

.expand {
  margin: 0;
}
</style>
