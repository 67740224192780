<template>
  <div>
    <div class="card-body" v-if="!sidePanelBool">
      <div class="row">
        <div :class="editPermitted ? 'col-sm-11 cursors_pointer' : 'col-sm-12 cursors_pointer'">
          <div class="row">
            <div class="col-sm-6" @click="showSidePanel(complaint.id)">
              <p class="mail_paragraph">
                ID:
                <b>{{ complaint.complaint_hash.toUpperCase() + "-" + complaint.id }}</b>
              </p>
              <p class="mail_paragraph">
                <el-tooltip class="item" :content="getSender(complaint)" effect="light" placement="top" :open-delay="300">
                  <span
                    >Nadawca: <b>{{ getSender(complaint) }}</b></span
                  >
                </el-tooltip>
              </p>
              <p class="mail_paragraph">
                Iteracja: <b>{{ complaint.complaint_counter }}</b>
              </p>
              <p class="mail_paragraph">
                Obsługujący:
                <i>{{ getCurrentOwner(complaint) }}</i>
              </p>
            </div>
            <div class="col-sm-6" @click="showSidePanel(complaint.id)">
              <p class="mail_paragraph">
                Data:
                <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                  <div slot="content"><b>Rozpoczęcie: </b>{{ complaint.event_time }}</div>
                  <div v-if="complaint.update_time !== complaint.event_time" slot="content"><b>Aktualizacja: </b>{{ complaint.update_time }}</div>
                  <b>{{ complaint.update_time !== complaint.event_time ? complaint.update_time : complaint.event_time }}</b>
                </el-tooltip>
              </p>
              <p class="mail_paragraph">
                <span>
                  Status:
                  <b>{{ filters.find((x) => x.id == complaint.status_id).label }}</b>
                </span>
                <span v-if="complaint.api_call_status === 'PENDING'" class="mail_badge">
                  <el-tooltip class="item" effect="light" content="W trakcie przetwarzania" placement="top" :open-delay="500">
                    <el-tag type="danger" effect="dark" size="small">!</el-tag>
                  </el-tooltip>
                </span>
              </p>
              <p class="mail_paragraph">
                Pozostało:
                <b>{{ getRemainingTime(complaint) }}</b>
              </p>
              <p class="mail_paragraph" v-if="mailboxAlias">
                Skrzynka:
                <el-tooltip class="item" effect="light" :content="mailboxAlias" placement="top" :open-delay="500">
                  <span>{{ mailboxAlias }}</span>
                </el-tooltip>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 cursors_pointer" @click="showSidePanel(complaint.id)">
              <p class="mail_paragraph">
                Temat:
                <i>{{ subject }}</i>
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="editPermitted"
          :class="activeComplaintID ? 'col-sm-1 spam_icon spam_icon__active_border' : 'col-sm-1 spam_icon spam_icon__disactive_border'"
          style="min-width: 30px"
          @click="handleSpam(complaint)"
        >
          <span v-if="complaint.status_id == 2" class="fa-stack">
            <i class="fa fa-trash-o fa-stack-2x"></i>
            <i class="fa fa-ban fa-stack-2x text-danger"></i>
          </span>
          <span v-else>
            <i class="fa fa-trash fa-2x" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="card-body row" v-else>
      <div class="col-sm cursors_pointer card-info" @click="showSidePanel(complaint.id)">
        <div class="row">
          <div class="col-sm-6">
            ID:
            <b>{{ complaint.complaint_hash.toUpperCase() + "-" + complaint.id }}</b>
          </div>
          <div class="col-sm-6">
            <span>
              <p class="mail_paragraph">
                Status:
                <b>{{ filters.find((x) => x.id == complaint.status_id).label }}</b>
              </p>
            </span>
            <span v-if="complaint.api_call_status === 'PENDING'" class="mail_badge">
              <el-tooltip class="item" effect="light" content="W trakcie przetwarzania" placement="top" :open-delay="500">
                <el-tag type="danger" effect="dark" size="small">!</el-tag>
              </el-tooltip>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            Pozostało:
            <b>{{ getRemainingTime(complaint) }}</b>
          </div>
          <div class="col-sm-6">
            Iteracja: <b>{{ complaint.complaint_counter }}</b>
          </div>
        </div>
        <p class="mail_paragraph">
          Data:
          <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
            <div slot="content"><b>Rozpoczęcie: </b>{{ complaint.event_time }}</div>
            <div v-if="complaint.update_time !== complaint.event_time" slot="content"><b>Aktualizacja: </b>{{ complaint.update_time }}</div>
            <b>{{ complaint.update_time !== complaint.event_time ? complaint.update_time : complaint.event_time }}</b>
          </el-tooltip>
        </p>
        <p class="mail_paragraph">
          <el-tooltip class="item" :content="getSender(complaint)" effect="light" placement="top" :open-delay="300">
            <span
              >Nadawca: <b>{{ getSender(complaint) }}</b></span
            >
          </el-tooltip>
        </p>
        <p class="mail_paragraph">
          Temat:
          <i>{{ subject }}</i>
        </p>
        <p class="mail_paragraph" v-if="mailboxAlias">
          Skrzynka:
          <el-tooltip class="item" effect="light" :content="mailboxAlias" placement="top" :open-delay="500">
            <span>{{ mailboxAlias }}</span>
          </el-tooltip>
        </p>
        <p class="mail_paragraph">
          Obsługujący:
          <i>{{ getCurrentOwner(complaint) }}</i>
        </p>
      </div>
      <div
        v-if="editPermitted"
        :class="activeComplaintID ? 'col-sm-auto spam_icon spam_icon__active_border' : 'col-sm-auto spam_icon spam_icon__disactive_border'"
        @click="handleSpam(complaint)"
      >
        <span v-if="complaint.status_id == 2" class="fa-stack">
          <i class="fa fa-trash-o fa-stack-2x"></i>
          <i class="fa fa-ban fa-stack-2x text-danger"></i>
        </span>
        <span v-else>
          <i class="fa fa-trash fa-2x" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";

import { Tooltip, Tag } from "element-ui";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    [Tag.name]: Tag,
  },
  props: ["users", "complaint", "sidePanelBool", "filters", "activeComplaintID"],
  methods: {
    getCurrentOwner({ current_user_id, current_group_name }) {
      if (current_user_id) {
        return this.users[current_user_id].user_first_name + " " + this.users[current_user_id].user_last_name;
      } else if (current_group_name) {
        return current_group_name;
      } else {
        return "-";
      }
    },
    showSidePanel(complaint_id) {
      eventBus.$emit("showSidePanel", {
        complaint_id: complaint_id,
      });
    },
    handleSpam(complaint) {
      eventBus.$emit("handleSpam", {
        complaint: complaint,
      });
    },
    getRemainingTime(complaint) {
      return complaint.status_id != 2 && complaint.status_id != 6 && complaint.remaining_time ? complaint.remaining_time + " dni" : "-";
    },
    getSender({ from_email, from_msisdn }) {
      let sender = "";
      if (from_msisdn) sender += from_msisdn;
      if (from_email) {
        if (from_msisdn) {
          sender += " / " + from_email;
        } else {
          sender += from_email;
        }
      }
      if (sender === "") sender = "-";
      return sender;
    },
  },
  computed: {
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_COMPLAINTS);
    },
    subject() {
      if (this.complaint.requests[0]) {
        const initRequest = _.last(this.complaint.requests);
        if (initRequest.type === "action") {
          return initRequest.action_type_name;
        } else {
          return initRequest.subject ? initRequest.subject : "-";
        }
      }
    },
    mailboxAlias() {
      if (this.complaint.requests[0]) {
        const initRequest = _.last(this.complaint.requests);
        if (initRequest.type === "request") {
          return initRequest.mailbox_alias ? initRequest.mailbox_alias : "-";
        } else {
          return "-";
        }
      }
    },
  },
};
</script>
<style scoped>
.cursors_pointer {
  cursor: pointer;
}

.mail_paragraph {
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
}

.card-info {
  max-width: 88%;
}

.spam_control {
  text-align: right;
  margin: auto;
  padding-right: 30px;
}

.spam_icon {
  opacity: 0.6;
  cursor: pointer;
  padding-right: 5px;
  padding-left: 5px;
  transition: opacity 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.spam_icon:hover {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.spam_icon__active_border {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.spam_icon__disactive_border {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

@media (max-width: 1250px) {
  .spam_icon__active_border {
    border-left: none;
    margin: auto;
  }
}

@media (max-width: 990px) {
  .spam_icon__active_border {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 575px) {
  .spam_icon__disactive_border {
    border-left: none;
    margin: auto;
  }

  .mail_badge {
    position: absolute;
  }
}

@media (min-width: 575px) {
  .mail_badge {
    position: absolute;
    left: -25px;
  }
}
</style>
