<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">Historia blacklisty</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="text-center" v-if="!blacklistHistory[model.phone]">
            Brak danych
          </div>
          <ul v-else>
            <li v-for="(record, index) in blacklistHistory[model.phone]" :key="index">
              <div><b>Data dodania: </b> {{ record.add_time ? record.add_time : "brak" }}</div>
              <div><b>Typ operacji: </b> {{ record.operation_type ? record.operation_type : "brak" }}</div>
            </li>
          </ul>
          <div class="row">
            <p-button type="success" @click.prevent="$emit('close')">Zamknij</p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input } from "element-ui";

// event bus
import { eventBus } from "src/main";

import { LOAD_BLACKLIST_HISTORY, BLACKLIST_HISTORY_INVALID } from "src/store/actions/blacklist/blacklist_history";

export default {
  components: {
    [Input.name]: Input,
  },
  props: ["phone"],
  data() {
    return {
      loading: false,
      model: {
        phone: this.phone,
      },
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData() {
      if (!this.checkData) return;

      this.model.phone = this.model.phone.length === 9 ? "48" + this.model.phone : this.model.phone;

      const filters = {
        msisdn: this.model.phone,
        system_user_id: this.$store.state.profile.data.ID,
      };

      this.loading = true;

      this.$store.commit(BLACKLIST_HISTORY_INVALID);

      await this.$store.dispatch(LOAD_BLACKLIST_HISTORY, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
  },
  computed: {
    blacklistHistory() {
      return this.$store.getters.getBlacklistHistory;
    },
    checkData() {
      if (this.model.phone === "" && !(this.model.phone.length === 9 || this.model.phone.length === 11)) return false;
      else return true;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}
</style>
