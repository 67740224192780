<template>
  <div>{{ testVar }}</div>
</template>

<script>
// vuex actions
import { LOAD_ROLES, ROLES_INVALID } from "src/store/actions/admin/roles";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";

export default {
  data() {
    return {
      testVar: "Kampanie oczekujące",
    };
  },
  components: {},
  computed: {},
  methods: {},
};
</script>

<style>
</style>
