export const LOAD_USERS = 'LOAD_USERS'
export const USERS_LOADING = 'USERS_LOADING'
export const USERS_LOADING_SUCCESS = 'USERS_LOADING_SUCCESS'
export const USERS_LOADING_ERROR = 'USERS_LOADING_ERROR'
export const USERS_INVALID = 'USERS_INVALID'

export const SAVE_USER = 'SAVE_USER'
export const USER_SAVING = 'USER_SAVING'
export const USER_SAVING_SUCCESS = 'USER_SAVING_SUCCESS'
export const USER_SAVING_ERROR = 'USER_SAVING_ERROR'

export const DELETE_USER = 'DELETE_USER'
export const USER_DELETING = 'USER_DELETING'
export const USER_DELETING_SUCCESS = 'USER_DELETING_SUCCESS'
export const USER_DELETING_ERROR = 'USER_DELETING_ERROR'