<template>
  <div class="card bg-light border border-secondary">
    <div class="card-header">
      <div class="row">
        <div class="col-sm-9">
          <h6 class="card-title">Demografia</h6>
        </div>
        <div class="col-sm-3 text-right">
          <p-button v-if="editMode" size="sm" type="success" @click.prevent="addNewRow('demograficModel')">Dodaj</p-button>
        </div>
      </div>
    </div>
    <div v-for="(element, index) in demograficModel.config_json" :key="'demografic' + index">
      <div class="row" v-if="index > 0">
        <div class="col-sm-10 text-center">
          <el-badge value="LUB" class="item" type="warning"></el-badge>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1 center-item">{{ index + 1 }})</div>
        <div class="col-md-2">
          <div class="form-group label-floating">
            <label class="control-label">Wiek od</label>
            <input :disabled="!editMode" min="0" type="number" class="form-control" v-model="element.AGE_FROM" />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group label-floating">
            <label class="control-label">Wiek do</label>
            <input :disabled="!editMode" min="0" type="number" class="form-control" v-model="element.AGE_TO" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group label-floating">
            <label class="control-label">Płeć</label>
            <el-select :disabled="!editMode" v-model="element.SEX" placeholder="Wybierz" clearable>
              <el-option v-for="item in sexDict" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-1 align-self-center">
          <p-button v-if="editMode" size="sm" type="danger" @click.prevent="deleteRow(index, 'demograficModel')">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </p-button>
        </div>
      </div>
    </div>

    <div class="card-header">
      <div class="row">
        <div class="col-sm-9">
          <h6 class="card-title">Lokalizacja</h6>
        </div>
        <div class="col-sm-3 text-right locationalRow">
          <el-tooltip v-if="editMode" class="item" effect="light" content="Dodaj załącznik" placement="top" :open-delay="500">
            <div class="action-icon files-icon">
              <i class="fa fa-lg fa-file-o" aria-hidden="true"></i>
              <input type="file" class="inputfile" id="criteriaFile" ref="file" v-on:change="handleFileUpload" />
            </div>
          </el-tooltip>
          <p-button v-if="editMode" size="sm" type="success" @click.prevent="addNewRow('locationalModel')">Dodaj</p-button>
        </div>
      </div>
    </div>
    <div v-for="(element, index) in locationalModel.config_json" :key="'locational' + index">
      <div class="row" v-if="index > 0">
        <div class="col-sm-10 text-center">
          <el-badge value="LUB" class="item" type="warning"></el-badge>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1 center-item">{{ index + 1 }})</div>
        <div class="col-md-4">
          <div class="form-group label-floating">
            <label class="control-label">Miasto</label>
            <input :disabled="!editMode" type="text" class="form-control" v-model="element.CITY" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group label-floating">
            <label class="control-label">Kod pocztowy</label>
            <el-select :disabled="!editMode" v-model="element.POSTCODE" placeholder="Wybierz" clearable filterable>
              <el-option-group v-for="group in Object.keys(postcodes)" :key="group" :label="group">
                <el-option v-for="item in postcodes[group]" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-option-group>
            </el-select>
          </div>
        </div>
        <div class="col-md-1 align-self-center" style="margin-top: 20px">
          <el-tooltip effect="light" content="Pełny adres bez numeru mieszkania" placement="top" :open-delay="500">
            <div class="align-self-center">
              <p-checkbox :disabled="!editMode" v-model="element.IS_DETACHED_HOUSE">Dom</p-checkbox>
            </div>
          </el-tooltip>
        </div>
        <div class="col-md-1 align-self-center">
          <p-button v-if="editMode" size="sm" type="danger" @click.prevent="deleteRow(index, 'locationalModel')">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </p-button>
        </div>
      </div>
    </div>

    <div class="card-header">
      <div class="row">
        <div class="col-sm-9">
          <h6 class="card-title">Operator</h6>
        </div>
        <div class="col-sm-3 text-right">
          <p-button v-if="false" size="sm" type="success" @click.prevent="addNewRow('operatorModel')">Dodaj</p-button>
        </div>
      </div>
    </div>
    <div v-for="(element, index) in operatorModel.config_json" :key="'operator' + index">
      <div class="row" v-if="index > 0">
        <div class="col-sm-10 text-center">
          <el-badge value="LUB" class="item" type="warning"></el-badge>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1 center-item">{{ index + 1 }})</div>
        <div class="col-md-2">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.OPERATOR_GSM.E">T-mobile</p-checkbox>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.OPERATOR_GSM.P">Plus</p-checkbox>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.OPERATOR_GSM.I">Orange</p-checkbox>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.OPERATOR_GSM[4]">Play</p-checkbox>
          </div>
        </div>
        <div class="col-md-1 align-self-center">
          <p-button v-if="editMode" size="sm" type="danger" @click.prevent="deleteRow(index, 'operatorModel')">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </p-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <p v-if="operatorCheck" style="font-size: 0.8em" class="text-muted">
          Nie zaznaczenie żadnego operatora jest równoznaczne z wybraniem wszystkich operatorów, numerów stacjonarnych oraz nie rozpoznanych.
        </p>
      </div>
    </div>

    <div class="card-header">
      <div class="row">
        <div class="col-sm-9">
          <h6 class="card-title">Czas</h6>
        </div>
        <div class="col-sm-3 text-right">
          <p-button v-if="editMode" size="sm" type="success" @click.prevent="addNewRow('timeModel')">Dodaj</p-button>
        </div>
      </div>
    </div>
    <div v-for="(element, index) in timeModel.config_json" :key="'time' + index" style="margin-bottom: 20px">
      <div class="row" v-if="index > 0">
        <div class="col-sm-10 text-center">
          <el-badge value="LUB" class="item" type="warning"></el-badge>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1 center-item">{{ index + 1 }})</div>
        <div class="col-md-1 center-item">Dzień:</div>
        <div class="col-md-1">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.DAY[1]">Pn.</p-checkbox>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.DAY[2]">Wt.</p-checkbox>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.DAY[3]">Śr.</p-checkbox>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.DAY[4]">Cz.</p-checkbox>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.DAY[5]">Pt.</p-checkbox>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.DAY[6]">Sb.</p-checkbox>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group label-floating center-left">
            <p-checkbox :disabled="!editMode" v-model="element.DAY[0]">Nd.</p-checkbox>
          </div>
        </div>
        <div class="col-md-1 align-self-center">
          <p-button v-if="editMode" size="sm" type="danger" @click.prevent="deleteRow(index, 'timeModel')">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </p-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-1 center-item">Godzina:</div>
        <div class="col-md-4">
          <el-time-select
            :disabled="!editMode"
            placeholder="Start"
            v-model="element.HOUR_RANGE.from"
            size="small"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
            }"
          >
          </el-time-select>
        </div>
        <div class="col-md-4 center-left">
          <el-time-select
            :disabled="!editMode"
            placeholder="Koniec"
            v-model="element.HOUR_RANGE.to"
            size="small"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
            }"
          >
          </el-time-select>
        </div>
      </div>
    </div>

    <div v-if="props_question_type === 'CO-REGISTRATION'">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <h6 class="card-title">Opcje dodatkowe</h6>
          </div>
          <div class="col-sm-3 text-right">
            <p-button v-if="editMode" size="sm" type="success" @click.prevent="addNewRow('optionModel')">Dodaj</p-button>
          </div>
        </div>
      </div>
      <div v-for="(element, index) in optionModel.config_json" :key="'option' + index">
        <div class="row" v-if="index > 0">
          <div class="col-sm-10 text-center">
            <el-badge value="LUB" class="item" type="warning"></el-badge>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1 center-item">{{ index + 1 }})</div>
          <div class="col-md-2 align-self-center">
            <el-tooltip effect="light" content="Czy deduplikacja sprzedażowa" placement="top" :open-delay="500">
              <div class="align-self-center">
                <p-checkbox :disabled="!editMode" v-model="element.DEDUPLICATION">Deduplikacja</p-checkbox>
              </div>
            </el-tooltip>
          </div>
          <div class="col-md-6"></div>
          <div class="col-md-1 align-self-center">
            <p-button v-if="editMode" size="sm" type="danger" @click.prevent="deleteRow(index, 'optionModel')">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </p-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <p-button v-if="editMode" v-on:click='createSaveObject'>Zapisz</p-button> -->
  </div>
</template>

<script>
import { eventBus } from "src/main";
import { Input, Select, Option, OptionGroup, Checkbox, TimeSelect, Badge, Tooltip } from "element-ui";
import Swal from "sweetalert2";

import { LOAD_CRITERIAS } from "src/store/actions/questionForm/criterias";
import { LOAD_QUESTION_CRITERIA } from "src/store/actions/questionForm/question_criteria";
import { LOAD_POSTCODES } from "src/store/actions/questionForm/postcodes";
import { QUESTION_CRITERIA_INVALID } from "src/store/actions/questionForm/question_criteria";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Checkbox.name]: Checkbox,
    [TimeSelect.name]: TimeSelect,
    [Tooltip.name]: Tooltip,
    [Badge.name]: Badge,
  },
  props: {
    props_question_id: null,
    props_question_type: null,
    editMode: false,
  },
  data() {
    return {
      questionID: null, // 812, 239, 797
      demograficModel: {
        config_json: [
          {
            group: 1,
            AGE_FROM: "",
            AGE_TO: "",
            SEX: "",
          },
        ],
      },
      locationalModel: {
        config_json: [
          {
            group: 1,
            CITY: "",
            POSTCODE: "",
            IS_DETACHED_HOUSE: false,
          },
        ],
      },
      operatorModel: {
        config_json: [
          {
            group: 1,
            OPERATOR_GSM: {
              E: false,
              P: false,
              I: false,
              4: false,
            },
          },
        ],
      },
      timeModel: {
        config_json: [
          {
            group: 1,
            DAY: {
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
              6: false,
              0: false,
            },
            HOUR_RANGE: {
              from: null,
              to: null,
            },
          },
        ],
      },
      optionModel: {
        config_json: [
          {
            group: 1,
            DEDUPLICATION: false,
          },
        ],
      },
      sexDict: [
        {
          value: "M",
          label: "Mężczyzna",
        },
        {
          value: "K",
          label: "Kobieta",
        },
      ],
      dataModel: {
        1: "demograficModel",
        2: "locationalModel",
        3: "operatorModel",
        4: "timeModel",
        5: "optionModel",
      },
      saveObject: {},
    };
  },
  methods: {
    addNewRow(model) {
      // dodanie nowej grupy dla danego modelu
      if (model === "demograficModel") {
        this.demograficModel.config_json.push({ group: Object.keys(this.demograficModel.config_json).length + 1, AGE_FROM: "", AGE_TO: "", SEX: "" });
      } else if (model === "locationalModel") {
        this.locationalModel.config_json.push({
          group: Object.keys(this.locationalModel.config_json).length + 1,
          CITY: "",
          POSTCODE: "",
          IS_DETACHED_HOUSE: false,
        });
      } else if (model === "operatorModel") {
        this.operatorModel.config_json.push({
          group: Object.keys(this.operatorModel.config_json).length + 1,
          OPERATOR_GSM: { 4: false, E: false, I: false, P: false },
        });
      } else if (model === "timeModel") {
        this.timeModel.config_json.push({
          group: Object.keys(this.timeModel.config_json).length + 1,
          DAY: { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 0: false },
          HOUR_RANGE: { from: null, to: null },
        });
      } else if (model === "optionModel") {
        this.optionModel.config_json.push({
          group: Object.keys(this.optionModel.config_json).length + 1,
          DEDUPLICATION: false,
        });
      }
    },
    deleteRow(index, model) {
      // usunięcie grupy oraz przenumerowanie
      this[model].config_json.splice(index, 1);
      if (this[model].config_json.length === 0) {
        this.addNewRow(model);
      }
      let cnt = 1;
      this[model].config_json.map((row) => (row.group = cnt++));
    },
    createSaveObject() {
      let saveObject = {
        question_id: this.questionID,
        insert_data: [],
        update_data: [],
        delete_data: [],
      };
      let model = this.dataModel;

      for (let i in model) {
        let config_data = this[model[i]].config_json;
        let compare_data = this[model[i]].compare_json;

        for (let j in config_data) {
          // przypadek dla dodania grupy oraz edycji wartości
          for (let key in config_data[j]) {
            if (key === "group") continue; // ominięcie klucza 'group'
            let config_data_value = config_data[j][key]; // wartość
            if (typeof config_data_value === "object") {
              // przypadek obiektu
              if (config_data[j] !== undefined && compare_data[j] !== undefined) {
                // sprawdzenie czy obydwie grupy istnieją
                let compare_data_value = compare_data[j][key];
                if (key === "HOUR_RANGE") {
                  // przypadek dla timepickera
                  if (config_data_value.from !== compare_data_value.from || config_data_value.to !== compare_data_value.to) {
                    if (
                      (config_data_value.from === null || config_data_value.to === null) &&
                      (compare_data_value.from !== null || compare_data_value.to !== null)
                    ) {
                      // przypadek usunięcia
                      saveObject.delete_data.push({
                        group_number: compare_data[j].group,
                        criteria_id: this.criteria[key].criteria_id,
                        value: { from: compare_data_value.from, to: compare_data_value.to },
                      });
                    } else if (
                      (config_data_value.from !== null || config_data_value.to !== null) &&
                      (compare_data_value.from === null || compare_data_value.to === null)
                    ) {
                      // przypadek dodania
                      saveObject.insert_data.push({
                        group_number: config_data[j].group,
                        criteria_id: this.criteria[key].criteria_id,
                        value: { from: config_data_value.from, to: config_data_value.to },
                      });
                    } else {
                      saveObject.update_data.push({
                        group_number: config_data[j].group,
                        criteria_id: this.criteria[key].criteria_id,
                        value: { from: config_data_value.from, to: config_data_value.to },
                      });
                    }
                  }
                }
                for (let el in config_data_value) {
                  let config_object_value = config_data_value[el];
                  let compare_object_value = compare_data_value[el];
                  if (typeof config_object_value === "boolean") {
                    // przypadek dla wartości logicznych
                    if (config_object_value === true && compare_object_value === false) {
                      saveObject.insert_data.push({ group_number: config_data[j].group, criteria_id: this.criteria[key].criteria_id, value: el });
                    } else if (config_object_value === false && compare_object_value === true) {
                      saveObject.delete_data.push({ group_number: config_data[j].group, criteria_id: this.criteria[key].criteria_id, value: el });
                    }
                  }
                }
              } else {
                // przypadek dla dodanej grupy
                if (compare_data[j] === undefined) {
                  // przypadek dodania grupy
                  if (key === "HOUR_RANGE") {
                    // przypadek dla timepickera
                    if (config_data_value.from !== null || config_data_value.to !== null) {
                      // przypadek dodania
                      saveObject.insert_data.push({
                        group_number: config_data[j].group,
                        criteria_id: this.criteria[key].criteria_id,
                        value: { from: config_data_value.from, to: config_data_value.to },
                      });
                    }
                  }
                  for (let el in config_data_value) {
                    let config_object_value = config_data_value[el];
                    if (typeof config_object_value === "boolean") {
                      // przypadek dla wartości logicznych
                      if (config_object_value === true) {
                        saveObject.insert_data.push({ group_number: config_data[j].group, criteria_id: this.criteria[key].criteria_id, value: el });
                      }
                    }
                  }
                }
              }
            } else {
              // przypadek dla wartości inputów w DEMOGRAFII i LOKALIZACJI
              if (config_data[j] !== undefined && compare_data[j] !== undefined) {
                // sprawdzenie czy obydwie grupy istnieją
                let compare_data_value = compare_data[j][key];
                if (config_data[j][key] != compare_data[j][key]) {
                  // porównywanie wartości w grupie
                  if ((config_data_value != "" || config_data_value != null) && (compare_data_value == "" || compare_data_value == null)) {
                    // przypadek wpisania nowej wartości
                    saveObject.insert_data.push({
                      group_number: config_data[j].group,
                      criteria_id: this.criteria[key].criteria_id,
                      value: config_data_value,
                    });
                  } else if ((config_data_value == "" || config_data_value == null) && (compare_data_value != "" || compare_data_value != null)) {
                    // przypadek usunięcia starej wartości
                    saveObject.delete_data.push({
                      group_number: config_data[j].group,
                      criteria_id: this.criteria[key].criteria_id,
                      value: compare_data_value,
                    });
                  } else {
                    // przypadek edycji wartości
                    saveObject.update_data.push({
                      group_number: config_data[j].group,
                      criteria_id: this.criteria[key].criteria_id,
                      value: config_data_value,
                    });
                  }
                }
              } else {
                // przypadek dla dodanej grupy
                if (compare_data[j] === undefined) {
                  // przypadek dodania grupy
                  if (config_data_value !== "" && config_data_value !== null) {
                    saveObject.insert_data.push({
                      group_number: config_data[j].group,
                      criteria_id: this.criteria[key].criteria_id,
                      value: config_data_value,
                    });
                  }
                }
              }
            }
          }
        }

        for (let j in compare_data) {
          // przypadek usunięcia grupy
          for (let key in compare_data[j]) {
            if (key === "group") continue;
            let compare_data_value = compare_data[j][key]; // wartość
            if (typeof compare_data_value === "object") {
              // przypadek obiektu
              if (compare_data[j] !== undefined && config_data[j] === undefined) {
                // sprawdzenie usunięcia grupy
                let compare_data_value = compare_data[j][key];
                if (key === "HOUR_RANGE") {
                  // przypadek dla timepickera
                  if (compare_data_value.from !== null || compare_data_value.to !== null) {
                    // przypadek usunięcia
                    saveObject.delete_data.push({
                      group_number: compare_data[j].group,
                      criteria_id: this.criteria[key].criteria_id,
                      value: { from: compare_data_value.from, to: compare_data_value.to },
                    });
                  }
                }
                for (let el in compare_data_value) {
                  let compare_object_value = compare_data_value[el];
                  if (typeof compare_object_value === "boolean") {
                    // przypadek dla wartości logicznych
                    if (compare_object_value === true) {
                      saveObject.delete_data.push({ group_number: compare_data[j].group, criteria_id: this.criteria[key].criteria_id, value: el });
                    }
                  }
                }
              }
            } else {
              // przypadek dla pozostałych wartości
              if (compare_data[j] !== undefined && config_data[j] === undefined) {
                // sprawdzenie usunięcia grupy
                if (compare_data_value !== "" && compare_data_value !== null) {
                  // sprawdzenie czy wartości nie są puste
                  saveObject.delete_data.push({
                    group_number: compare_data[j].group,
                    criteria_id: this.criteria[key].criteria_id,
                    value: compare_data_value,
                  });
                }
              }
            }
          }
        }
      }

      this.saveObject = saveObject;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData(force) {
      let filters = {
        question_id: this.questionID,
      };

      this.$store.dispatch(LOAD_CRITERIAS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_POSTCODES, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      if (force) {
        this.$store.commit(QUESTION_CRITERIA_INVALID);
      }

      if (this.questionID !== null)
        await this.$store.dispatch(LOAD_QUESTION_CRITERIA, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
    },
    initData() {
      let data = this.question_criteria;
      let model = this.dataModel;
      for (let i in data) {
        if (data[i].group_number !== Object.keys(this[model[data[i].criteria_type_id]].config_json).length) {
          // sprawdzenie czy należy dodać nową grupę
          this.addNewRow(model[data[i].criteria_type_id]); // dodanie nowej grupy dla danego modelu
        }
        if (data[i].criteria_id === 9 || data[i].criteria_id === 12) {
          // przypisywanie dla modelu Operatorów oraz Dni
          this[model[data[i].criteria_type_id]].config_json[data[i].group_number - 1][data[i].criteria_name][data[i].value] = true;
        } else if (data[i].criteria_id === 13) {
          // przypisywanie dla modelu godzin
          this[model[data[i].criteria_type_id]].config_json[data[i].group_number - 1][data[i].criteria_name].from = JSON.parse(data[i].value).from;
          this[model[data[i].criteria_type_id]].config_json[data[i].group_number - 1][data[i].criteria_name].to = JSON.parse(data[i].value).to;
        } else if (data[i].criteria_id === 14 || data[i].criteria_id === 16) {
          this[model[data[i].criteria_type_id]].config_json[data[i].group_number - 1][data[i].criteria_name] = !!data[i].value;
        } else {
          // przypisywanie danych dla wszystkich pozostałych modeli
          this[model[data[i].criteria_type_id]].config_json[data[i].group_number - 1][data[i].criteria_name] = data[i].value;
        }
      }
      for (let i in model) {
        // stworzenie modeli porównawczych
        this[model[i]].compare_json = JSON.parse(JSON.stringify(this[model[i]].config_json));
      }
    },
    async handleFileUpload(e) {
      const file = e.target.files[0];

      const data = await file.text();

      const locationArray = data.split("\n");

      this.handleFileModal(locationArray);

      document.getElementById("criteriaFile").value = null;
    },
    handleFileModal(result) {
      let varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: `Czy chcesz dodać te miejscowości?`,
          text: result.join(", "),
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            varThis.handleAddLocationalFiles(result);
          }
        })
        .catch(() => {
          return;
        });
    },
    handleAddLocationalFiles(arr) {
      arr.forEach((row) => {
        if (
          this.locationalModel.config_json[this.locationalModel.config_json.length - 1].CITY === "" &&
          this.locationalModel.config_json[this.locationalModel.config_json.length - 1].POSTCODE === ""
        ) {
          this.locationalModel.config_json[this.locationalModel.config_json.length - 1].CITY = row;
        } else {
          this.addNewRow("locationalModel");
          this.locationalModel.config_json[this.locationalModel.config_json.length - 1].CITY = row;
        }
      });
    },
  },
  computed: {
    question_criteria() {
      if (this.questionID !== null) {
        return this.$store.getters.getQuestionCriteria;
      } else {
        return [];
      }
    },
    criteria() {
      let data = this.$store.getters.getCriterias;
      let result = {};
      for (let i in data) {
        result[data[i].criteria_name] = data[i];
      }
      return result;
    },
    postcodes() {
      let data = this.$store.getters.getPostcodes;
      let result = {};
      for (let i in data) {
        if (result[i] === undefined) {
          result[i] = [];
        }
        for (let j in data[i]) {
          result[i].push(data[i][j]);
        }
      }
      for (let i in result) {
        result[i].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      }
      return result;
    },
    operatorCheck() {
      let check = true;
      this.operatorModel.config_json.map((el) => {
        if (el.OPERATOR_GSM["4"] || el.OPERATOR_GSM["E"] || el.OPERATOR_GSM["I"] || el.OPERATOR_GSM["P"]) {
          check = false;
        } else {
          check = true;
        }
      });
      return check;
    },
  },
  mounted() {
    eventBus.$on("saveCriterias", () => {
      if (this.timeModel.config_json.some((el) => typeof el.HOUR_RANGE.from !== typeof el.HOUR_RANGE.to)) {
        let msg = "Przedział czasowy musi być zamknięty bądź pusty!";
        eventBus.$emit("criteriasObject", msg);
      } else {
        this.createSaveObject();
        eventBus.$emit("criteriasObject", this.saveObject);
      }
    });
  },
  async created() {
    if (this.props_question_id !== null && this.props_question_id !== undefined) {
      this.questionID = parseInt(this.props_question_id);
    }
    await this.loadData(true);
    this.initData();
  },
};
</script>

<style scoped>
.age_input {
  width: 70px;
}
.center-item {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.center-left {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.action-icon {
  display: inline-block;
  justify-content: center;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  position: relative;
}

.action-icon:hover {
  transition: background-color 200ms ease-in-out;
  background-color: #f7f7f7;
}

.files-icon {
  padding: 10px;
  margin: 0 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.files-icon input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.locationalRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
