<template>
  <div class="modal-wrapper" :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row header-row">
      <div class="col-sm-11">
        <h5 class="title">Odpowiedzi pytania {{ filters.question }}</h5>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer" aria-hidden="true"></i>
    </div>

    <div class="card">
      <div class="card-body row">
        <el-table :data="tableData" size="mini" style="width: 100%" :header-cell-style="headerStyle">
          <el-table-column label="ID" property="QuestionAnswerID" width="100" align="center"></el-table-column>
          <el-table-column label="Treść" property="Answer" align="left" min-width="200">
            <template v-slot="props">
              <div style="word-break: keep-all">
                {{ props.row.Answer }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Data" property="AnswerTime" align="left" width="180"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, DatePicker, Select, Option, OptionGroup, Collapse, CollapseItem } from "element-ui";

// event bus
import { eventBus } from "src/main";

import { LOAD_QUESTION_ANSWER_CUSTOM, QUESTION_ANSWER_CUSTOM_INVALID } from "src/store/actions/stats/question_answer_custom";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [CollapseItem.name]: CollapseItem,
    [Collapse.name]: Collapse,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: ["filters"],
  computed: {
    answer_custom() {
      return this.$store.getters.getQuestionAnswerCustom;
    },
    tableData() {
      if (this.$store.state.question_answer_custom.loading_status === "success") {
        return this.answer_custom;
      } else {
        return [];
      }
    },
  },
  methods: {
    async loadStats(filters) {
      this.loading = true;

      this.$store.commit(QUESTION_ANSWER_CUSTOM_INVALID);

      await this.$store.dispatch(LOAD_QUESTION_ANSWER_CUSTOM, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    headerStyle(obj) {
      return "font-size: 12px;";
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
  },
  created() {
    this.loadStats(this.filters);
    eventBus.$on("forceReload", () => {
      this.loadStats();
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped>
.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}
.card {
  margin: 0;
  display: flex;
}
.header-row {
  padding-top: 15px;
  padding-left: 15px;
}
.modal-wrapper {
  position: relative;
}
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
