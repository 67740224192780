export const LOAD_MACRO = 'LOAD_MACRO'
export const MACRO_LOADING = 'MACRO_LOADING'
export const MACRO_LOADING_SUCCESS = 'MACRO_LOADING_SUCCESS'
export const MACRO_LOADING_ERROR = 'MACRO_LOADING_ERROR'
export const MACRO_INVALID = 'MACRO_INVALID'

export const SAVE_MACRO = 'SAVE_MACRO'
export const MACRO_SAVING = 'MACRO_SAVING'
export const MACRO_SAVING_SUCCESS = 'MACRO_SAVING_SUCCESS'
export const MACRO_SAVING_ERROR = 'MACRO_SAVING_ERROR'
