import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_USERS_IMPORT,
  USERS_IMPORT_LOADING,
  USERS_IMPORT_LOADING_SUCCESS,
  USERS_IMPORT_LOADING_ERROR,
  USERS_IMPORT_INVALID,
  SAVE_USER_IMPORT,
  USER_IMPORT_SAVING,
  USER_IMPORT_SAVING_SUCCESS,
  USER_IMPORT_SAVING_ERROR
} from 'src/store/actions/admin/users_import'

const storeObjectConfig = {
  objectName: "UsersImport",
  getterName: "getUsersImport",
  loadActionName: LOAD_USERS_IMPORT,
  loadingMutationName: USERS_IMPORT_LOADING,
  loadingSuccessMutationName: USERS_IMPORT_LOADING_SUCCESS,
  loadingErrorMutationName: USERS_IMPORT_LOADING_ERROR,
  invalidMutationName: USERS_IMPORT_INVALID,
  saveActionName: SAVE_USER_IMPORT,
  savingMutationName: USER_IMPORT_SAVING,
  savingSuccessMutationName: USER_IMPORT_SAVING_SUCCESS,
  savingErrorMutationName: USER_IMPORT_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/users/import",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/users/import",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
