import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_ROLES,
  ROLES_LOADING,
  ROLES_LOADING_SUCCESS,
  ROLES_LOADING_ERROR,
  ROLES_INVALID,
  SAVE_ROLE,
  ROLE_SAVING,
  ROLE_SAVING_SUCCESS,
  ROLE_SAVING_ERROR
} from 'src/store/actions/admin/roles'

const storeObjectConfig = {
  objectName: "Roles",
  getterName: "getRoles",
  loadActionName: LOAD_ROLES,
  loadingMutationName: ROLES_LOADING,
  loadingSuccessMutationName: ROLES_LOADING_SUCCESS,
  loadingErrorMutationName: ROLES_LOADING_ERROR,
  invalidMutationName: ROLES_INVALID,
  saveActionName: SAVE_ROLE,
  savingMutationName: ROLE_SAVING,
  savingSuccessMutationName: ROLE_SAVING_SUCCESS,
  savingErrorMutationName: ROLE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/roles",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/roles",
    method: "put" // {'get','post','put'}
  }
}

const extraGetters = {
  getRoleNameById: (state) => (id) => {
    return !!state.data[id] ? state.data[id].role_name : '';
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = Object.assign({}, storeGen.gettersGenerator(storeObjectConfig), extraGetters);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
