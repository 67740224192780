import { render, staticRenderFns } from "./Criteria.vue?vue&type=template&id=41a698b2&scoped=true&"
import script from "./Criteria.vue?vue&type=script&lang=js&"
export * from "./Criteria.vue?vue&type=script&lang=js&"
import style0 from "./Criteria.vue?vue&type=style&index=0&id=41a698b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41a698b2",
  null
  
)

export default component.exports