export const LOAD_MAILING_STATS_DEMOGRAPHY = "LOAD_MAILING_STATS_DEMOGRAPHY";
export const MAILING_STATS_DEMOGRAPHY_LOADING = "MAILING_STATS_DEMOGRAPHY_LOADING";
export const MAILING_STATS_DEMOGRAPHY_LOADING_SUCCESS = "MAILING_STATS_DEMOGRAPHY_LOADING_SUCCESS";
export const MAILING_STATS_DEMOGRAPHY_LOADING_ERROR = "MAILING_STATS_DEMOGRAPHY_LOADING_ERROR";
export const MAILING_STATS_DEMOGRAPHY_INVALID = "MAILING_STATS_DEMOGRAPHY_INVALID";

export const SAVE_MAILING_STATS_DEMOGRAPHY = "SAVE_MAILING_STATS_DEMOGRAPHY";
export const MAILING_STATS_DEMOGRAPHY_SAVING = "MAILING_STATS_DEMOGRAPHY_SAVING";
export const MAILING_STATS_DEMOGRAPHY_SAVING_SUCCESS = "MAILING_STATS_DEMOGRAPHY_SAVING_SUCCESS";
export const MAILING_STATS_DEMOGRAPHY_SAVING_ERROR = "MAILING_STATS_DEMOGRAPHY_SAVING_ERROR";
