// utility functions
import utils from 'src/utils'

import {
  DATE_RANGE, ACTIVE_PARTNER
} from '../actions/common'

const state = {
  dateRange: [utils.today(), utils.today()],
  activePartnerId: 0,
}

const getters = {
  getFromDate() {
    return state.dateRange[0];
  },
  getToDate() {
    return state.dateRange[1];
  },
  getDateRange() {
    return state.dateRange;
  },
  getActivePartnerId() {
    return state.activePartnerId;
  }
}

const actions = {
  // update date range
  [DATE_RANGE]: ({
    commit
  }, payload) => {
    commit(DATE_RANGE, payload);
  },
  [ACTIVE_PARTNER]: ({
    commit
  }, payload) => {
    commit(ACTIVE_PARTNER, payload);
  }

};

const mutations = {
  [DATE_RANGE]: (state, payload) => {
    state.dateRange = payload;
  },
  [ACTIVE_PARTNER]: (state, payload) => {
    state.activePartnerId = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}
