import storeGen from "../../storeGenerator";

import { AUTH_LOGOUT } from "../../actions/auth";

import { SECURE_API, BASE_API, IP_API } from "src/store/config";

import {
  SAVE_BLACKLIST_MSISDN,
  BLACKLIST_MSISDN_SAVING,
  BLACKLIST_MSISDN_SAVING_SUCCESS,
  BLACKLIST_MSISDN_SAVING_ERROR,
} from "../../actions/blacklist/blacklist_msisdn";

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getBlacklistMsisdn",
  saveActionName: SAVE_BLACKLIST_MSISDN,
  savingMutationName: BLACKLIST_MSISDN_SAVING,
  savingSuccessMutationName: BLACKLIST_MSISDN_SAVING_SUCCESS,
  savingErrorMutationName: BLACKLIST_MSISDN_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: IP_API,
  validPeriod: 10, // minutes
  recordType: "plain",
  saveParams: {
    url: "/blacklist/msisdn",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
