<template>
  <div class="sidebar" :data-color="userBackgroundColor" :data-active-color="userActiveColor">
    <div class="logo">
      <router-link to="/" style="text-decoration-line: none; color: white">
        <a class="simple-text logo-mini">
          <div class="logo-img">
            <img :src="logo" alt />
          </div>
        </a>
        <a class="simple-text logo-normal">{{ title }}</a>
      </router-link>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in activeSidebarLinks" :key="link.name + index" :link="link" :id="'sidbarlink_' + link.name">
            <template v-if="link.children">
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
                :id="'sidbarlink' + link.name + '_' + subLink.name"
              ></sidebar-item>
            </template>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import "perfect-scrollbar/dist/css/perfect-scrollbar.css";
export default {
  props: {
    title: {
      type: String,
      default: "MobiMe",
      description: "MobiMe",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: value => {
        let acceptedValues = ["white", "brown", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar background color (white|brown|black)",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: value => {
        let acceptedValues = ["primary", "info", "success", "warning", "danger"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar active text color (primary|info|success|warning|danger)",
    },
    logo: {
      type: String,
      default: "/static/img/logo-mobime.png",
      description: "Sidebar Logo",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description: "Sidebar links. Can be also provided as children components (sidebar-item)",
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    async initScrollBarAsync() {
      const PerfectScroll = await import("perfect-scrollbar");
      PerfectScroll.initialize(this.$refs.sidebarScrollArea);
    },
  },
  mounted() {
    this.initScrollBarAsync();
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  computed: {
    profilePrefs() {
      if (this.$store.state.profile_prefs.loading_status === "success") {
        if (this.$store.getters.getProfilePrefs.json_config) {
          return JSON.parse(this.$store.getters.getProfilePrefs.json_config);
        } else return {};
      } else return {};
    },
    userActiveColor() {
      if (this.profilePrefs.activeColor) {
        return this.profilePrefs.activeColor;
      } else return this.activeColor;
    },
    userBackgroundColor() {
      if (this.profilePrefs.backgroundColor) {
        return this.profilePrefs.backgroundColor;
      } else return this.backgroundColor;
    },
    activeSidebarLinks() {
      let activeSidebarLinks = [];
      const userPrivileges = this.$store.getters.getProfile.privileges;
      const sidebarLinks = this.sidebarLinks;
      if (typeof userPrivileges === "undefined") return [];

      sidebarLinks.forEach(element => {
        let activeChildrenLinks = null;
        if ("children" in element) {
          activeChildrenLinks = [];

          element.children.forEach(childrenElement => {
            if (!("privileges" in childrenElement) || childrenElement.privileges === []) {
              activeChildrenLinks.push(Object.assign({}, childrenElement));
            } else if (userPrivileges.includes(childrenElement.privileges[0])) {
              activeChildrenLinks.push(Object.assign({}, childrenElement));
            } else {
              // brak uprawnien dla elementu menu
            }
          });
        }

        if (!("privileges" in element) || element.privileges === []) {
          activeSidebarLinks.push(Object.assign({}, element, { children: activeChildrenLinks }));
        } else if (userPrivileges.some(t1 => element.privileges.includes(t1))) {
          activeSidebarLinks.push(Object.assign({}, element, { children: activeChildrenLinks }));
        } else {
          // brak uprawnien do elementu menu
        }
      });
      return activeSidebarLinks;
    },
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
