import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_QUESTION_ORDER,
  QUESTION_ORDER_LOADING,
  QUESTION_ORDER_LOADING_SUCCESS,
  QUESTION_ORDER_LOADING_ERROR,
  QUESTION_ORDER_INVALID,
  SAVE_QUESTION_ORDER,
  QUESTION_ORDER_SAVING,
  QUESTION_ORDER_SAVING_SUCCESS,
  QUESTION_ORDER_SAVING_ERROR
} from 'src/store/actions/questions/question_order'

const storeObjectConfig = {
  objectName: "QuestionOrder",
  getterName: "getQuestionOrder",
  loadActionName: LOAD_QUESTION_ORDER,
  loadingMutationName: QUESTION_ORDER_LOADING,
  loadingSuccessMutationName: QUESTION_ORDER_LOADING_SUCCESS,
  loadingErrorMutationName: QUESTION_ORDER_LOADING_ERROR,
  invalidMutationName: QUESTION_ORDER_INVALID,
  saveActionName: SAVE_QUESTION_ORDER,
  savingMutationName: QUESTION_ORDER_SAVING,
  savingSuccessMutationName: QUESTION_ORDER_SAVING_SUCCESS,
  savingErrorMutationName: QUESTION_ORDER_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/questionOrder",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/questions/questionOrder",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
