import storeGen from '../../../storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  BASE_API
} from 'src/store/config'

import {
  SAVE_BLACKLIST_LEAD,
  BLACKLIST_LEAD_SAVING,
  BLACKLIST_LEAD_SAVING_SUCCESS,
  BLACKLIST_LEAD_SAVING_ERROR
} from '../../../actions/complaints/actions/blacklist_lead'

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getBlacklistLead",
  saveActionName: SAVE_BLACKLIST_LEAD,
  savingMutationName: BLACKLIST_LEAD_SAVING,
  savingSuccessMutationName: BLACKLIST_LEAD_SAVING_SUCCESS,
  savingErrorMutationName: BLACKLIST_LEAD_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'plain',
  saveParams: {
    url: "/blacklist/lead",
    method: "delete" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
