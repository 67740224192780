import storeGen from "../../storeGenerator";

import { AUTH_LOGOUT } from "../../actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_MAILBOX_TEMPLATES,
  MAILBOX_TEMPLATES_LOADING,
  MAILBOX_TEMPLATES_LOADING_SUCCESS,
  MAILBOX_TEMPLATES_LOADING_ERROR,
  MAILBOX_TEMPLATES_INVALID,
  SAVE_MAILBOX_TEMPLATE,
  MAILBOX_TEMPLATE_SAVING,
  MAILBOX_TEMPLATE_SAVING_SUCCESS,
  MAILBOX_TEMPLATE_SAVING_ERROR,
} from "../../actions/complaints/mailbox_templates";

const storeObjectConfig = {
  objectName: "MailboxTemplates",
  getterName: "getMailboxTemplates",
  loadActionName: LOAD_MAILBOX_TEMPLATES,
  loadingMutationName: MAILBOX_TEMPLATES_LOADING,
  loadingSuccessMutationName: MAILBOX_TEMPLATES_LOADING_SUCCESS,
  loadingErrorMutationName: MAILBOX_TEMPLATES_LOADING_ERROR,
  invalidMutationName: MAILBOX_TEMPLATES_INVALID,
  saveActionName: SAVE_MAILBOX_TEMPLATE,
  savingMutationName: MAILBOX_TEMPLATE_SAVING,
  savingSuccessMutationName: MAILBOX_TEMPLATE_SAVING_SUCCESS,
  savingErrorMutationName: MAILBOX_TEMPLATE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/complaints/mailboxes/templates",
    method: "post", // {'get','post','put'}
  },
  saveParams: {
    url: "/complaints/mailboxes/templates",
    method: "put", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
