import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_HOTJAR_CONFIG,
  HOTJAR_CONFIG_LOADING,
  HOTJAR_CONFIG_LOADING_SUCCESS,
  HOTJAR_CONFIG_LOADING_ERROR,
  HOTJAR_CONFIG_INVALID,
  SAVE_HOTJAR_CONFIG,
  HOTJAR_CONFIG_SAVING,
  HOTJAR_CONFIG_SAVING_SUCCESS,
  HOTJAR_CONFIG_SAVING_ERROR
} from '../../actions/tests/hotjar_config'

const storeObjectConfig = {
  objectName: "HotjarConfig",
  getterName: "getHotjarConfig",
  loadActionName: LOAD_HOTJAR_CONFIG,
  loadingMutationName: HOTJAR_CONFIG_LOADING,
  loadingSuccessMutationName: HOTJAR_CONFIG_LOADING_SUCCESS,
  loadingErrorMutationName: HOTJAR_CONFIG_LOADING_ERROR,
  invalidMutationName: HOTJAR_CONFIG_INVALID,
  saveActionName: SAVE_HOTJAR_CONFIG,
  savingMutationName: HOTJAR_CONFIG_SAVING,
  savingSuccessMutationName: HOTJAR_CONFIG_SAVING_SUCCESS,
  savingErrorMutationName: HOTJAR_CONFIG_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/tests/hotjarConfig",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/tests/hotjarConfig",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
