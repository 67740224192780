<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <h5 class="title">Aktywność pytań korejestracyjnych</h5>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <label>Projekt</label>
            <fg-input>
              <el-select
                v-on:clear="clearVersions()"
                v-on:remove-tag="removeVersion($event)"
                v-model="projects"
                name="projects"
                filterable
                clearable
                multiple
              >
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Wersja</label>
            <fg-input>
              <el-select v-model="versions" name="versions" filterable clearable multiple>
                <el-option-group v-for="group in projectModules" :key="group.label" :label="group.label">
                  <el-option
                    v-for="version in group.options"
                    :label="version.module + ' [' + version.tags + ']'"
                    :value="version.module"
                    :key="version.module"
                  ></el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Pytanie</label>
            <fg-input>
              <el-select
                id="questionActivityQuestionSelect"
                v-on:visible-change="getQuestionSelectWidth()"
                v-model="questions"
                name="questions"
                filterable
                clearable
                multiple
              >
                <el-option
                  :style="questionSelectWidth !== null ? 'width: ' + questionSelectWidth + 'px' : 'width: 300px'"
                  v-for="question in question_list"
                  :label="'[' + question.id + '] ' + question.question_content"
                  :value="question.id"
                  :key="question.id"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="card-footer text-center">
          <p-button type="success" @click.prevent="loadStats(true)">Wczytaj</p-button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div v-for="project in activity_data" :key="project.ProjectId">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <h5>
                    {{ "[" + project.ProjectID + "] " + project.ProjectName }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <el-table :data="Object.values(project.modules)" style="width: 100%">
                <el-table-column fixed label="Wersja" min-width="100" property="module">
                  <template v-slot="props">
                    <el-tooltip class="item" effect="light" :content="props.row.version" placement="left" :open-delay="300">
                      <span>{{ props.row.module }}</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column v-for="question in question_activity.questionArray" :key="question" width="80" align="center">
                  <template slot="header">
                    <el-tooltip class="item" effect="light" :content="activeQuestions[question].Content" placement="top" :open-delay="300">
                      <span>{{ question }}</span>
                    </el-tooltip>
                  </template>
                  <template v-slot="props">
                    <span :class="`ball ${props.row.questions[question] ? 'ball__green' : 'ball__red'}`"></span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vuex actions
import { LOAD_QUESTION_ACTIVITY, QUESTION_ACTIVITY_INVALID } from "src/store/actions/stats/question_activity";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_QUESTION_LIST, QUESTION_LIST_INVALID } from "src/store/actions/questions/question_list";
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";

import { Tooltip, Select, Option, OptionGroup } from "element-ui";

// event bus
import { eventBus } from "src/main";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
  },
  data() {
    return {
      loading: false,
      projects: [],
      versions: [],
      questions: [],
      versionProjectObject: {},
      questionSelectWidth: null,
    };
  },
  computed: {
    question_activity() {
      return this.$store.getters.getQuestionActivity;
    },
    activity_data() {
      let data = [];
      if (this.question_activity.projects) {
        data = JSON.parse(JSON.stringify(Object.values(this.question_activity.projects)));
      }

      data.sort((a, b) => (a.ProjectName.toLowerCase() > b.ProjectName.toLowerCase() ? 1 : -1));

      for (let i in data) {
        data[i].modules = Object.values(data[i].modules);

        data[i].modules.forEach(mod => {
          let res = mod.module.match("[0-9]+.[0-9]+")[0];
          mod.num = res;
        });

        data[i].modules.sort((a, b) => a.num - b.num);
      }

      return data;
    },
    activeQuestions() {
      return this.question_activity.questions;
    },
    question_list() {
      let data = Object.values(this.$store.getters.getQuestionList).filter(x => x.question_type === "CO-REGISTRATION");
      data.sort((a, b) => b.id - a.id);
      return data;
    },
    projectsList() {
      return this.$store.getters.getProjectDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map(x => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    projectModules() {
      // wszystkie moduły projektu
      if (Array.isArray(this.projects) && this.projects.length > 0) {
        let projects = this.$store.getters.getProjectsAll;
        let activeProjects = this.projects;
        let filteredData = {};
        let temp = {};
        const sortedData = {};
        for (let i in activeProjects) {
          for (let [key, project] of Object.entries(projects)) {
            // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
            if (project.project_id === activeProjects[i]) {
              temp[project.module] = Object.assign({}, project);
              filteredData[project.project_id] = Object.assign({}, temp);
            }
          }
          temp = {};
        }

        let sourceData = [];

        for (let i in filteredData) {
          // sortowanie danych
          let tempData = filteredData[i];
          let sourceData = {};
          for (let ver of Object.keys(tempData)) {
            // iteracja po nazwach modułów danego projektu
            let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie pierwszej liczby
            filteredData[i][ver].number = res;
          }
        }

        let resultModules = [];

        for (let project_id in filteredData) {
          let projectModules = filteredData[project_id];
          sourceData = Object.values(projectModules);
          sourceData.sort((a, b) => a.number - b.number);
          let result = {};
          for (let i in sourceData) {
            this.versionProjectObject[sourceData[i].module] = parseInt(project_id);
            result[sourceData[i].module] = sourceData[i];
          }
          resultModules.push({
            project_id,
            project_id,
            label: this.projectsList[project_id].project_name,
            options: result,
          });
        }

        return resultModules;
      }
      return [];
    },
  },
  methods: {
    async loadData(force) {
      this.loading = true;

      if (force) {
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(QUESTION_LIST_INVALID);
        this.$store.commit(PROJECTS_ALL_INVALID);
      }

      this.$store.dispatch(LOAD_QUESTION_LIST, { subquestion: false }).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    async loadStats(force) {
      this.loading = true;
      if (force) {
        this.$store.commit(QUESTION_ACTIVITY_INVALID);
      }

      await this.$store
        .dispatch(LOAD_QUESTION_ACTIVITY, { projects: this.projects, versions: this.versions, questions: this.questions })
        .catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

      this.loading = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    clearVersions() {
      this.versions = [];
    },
    removeVersion(project_id) {
      let versions = this.versions;
      let filteredVersions = [];
      for (let i in versions) {
        if (this.versionProjectObject[versions[i]] !== project_id) {
          filteredVersions.push(versions[i]);
        }
      }
      this.versions = filteredVersions;
    },
    getQuestionSelectWidth() {
      this.questionSelectWidth = document.getElementById("questionActivityQuestionSelect").offsetWidth;
    },
  },
  created() {
    this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped>
.ball {
  background: gray;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
  color: white;
  box-shadow: 2px -2px 1px rgba(191, 191, 191, 0.6);
}

.ball__text {
  padding: 5px;
}

.ball__green {
  background: radial-gradient(#00b800, #006800);
}

.ball__red {
  background: radial-gradient(#b80000, #680000);
}

.button-col {
  display: flex;
  justify-content: flex-end;
}
</style>
