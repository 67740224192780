export const LOAD_MAILING_COSTS = "LOAD_MAILING_COSTS";
export const MAILING_COSTS_LOADING = "MAILING_COSTS_LOADING";
export const MAILING_COSTS_LOADING_SUCCESS = "MAILING_COSTS_LOADING_SUCCESS";
export const MAILING_COSTS_LOADING_ERROR = "MAILING_COSTS_LOADING_ERROR";
export const MAILING_COSTS_INVALID = "MAILING_COSTS_INVALID";

export const SAVE_MAILING_COST = "SAVE_MAILING_COST";
export const MAILING_COST_SAVING = "MAILING_COST_SAVING";
export const MAILING_COST_SAVING_SUCCESS = "MAILING_COST_SAVING_SUCCESS";
export const MAILING_COST_SAVING_ERROR = "MAILING_COST_SAVING_ERROR";

export const DELETE_MAILING_COSTS = "DELETE_MAILING_COSTS";
export const MAILING_COSTS_DELETING = "MAILING_COSTS_DELETING";
export const MAILING_COSTS_DELETING_SUCCESS = "MAILING_COSTS_DELETING_SUCCESS";
export const MAILING_COSTS_DELETING_ERROR = "MAILING_COSTS_DELETING_ERROR";
