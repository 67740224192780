<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-9">
        <h5 class="title">
          <i class="fa fa-home" style="cursor: pointer; margin-right: 10px" aria-hidden="true"
             @click="goToDashboard()"></i>
          Reklamacje - {{ mailboxName }}
          <i v-if="sidePanelBool" class="fa fa-angle-double-left" style="cursor: pointer" aria-hidden="true"
             @click="closeSidePanel()"></i>
        </h5>
      </div>
      <div class="col-sm-3 text-right">
        <p-button
          type="default"
          link
          @click="
            closeSidePanel();
            loadData(false);
          "
        >
          Odśwież
        </p-button>
        <p-button type="success" link @click="handleCreateComplaint()"> Dodaj</p-button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-auto" style="min-width: 300px; margin-bottom: 20px" v-show="!sidePanelBool">
        <appTreeView
          v-if="selectedMailboxID"
          :activeMailbox="String(selectedMailboxID)"
          :activeCompany="Number(companyID)"
          :data="companies"
          @changeMailbox="changeMailbox"
          @changeCompany="changeCompany"
        />
        <div v-else>Brak skrzynek</div>
      </div>
      <div :class="sidePanelBool ? 'col-md-12 col-lg-4' : 'col-md-12 col-lg-8'">
        <div style="margin-bottom: 0px">
          <div class="row">
            <div class="col-sm-5">
              <el-tooltip class="item" effect="light" content="Status zgłoszenia" placement="top" :open-delay="500">
                <el-select
                  v-model="selectedFilterID"
                  name="selectedFilterID"
                  data-vv-as="Status"
                  filterable
                  v-on:input="changeFilter()"
                  style="width: 100%"
                >
                  <el-option v-for="filter in filters" :label="filter.label" :value="filter.id"
                             :key="filter.id"></el-option>
                </el-select>
              </el-tooltip>
            </div>
            <div class="col-sm-6">
              <el-tooltip class="item" effect="light" content="Szukaj" placement="top" :open-delay="500">
                <el-input
                  class="input-sm"
                  placeholder="Szukaj - nadawca, id, treść"
                  v-on:input="handleSearch($event)"
                  v-model="searchQuery"
                  clearable
                  suffix-icon="nc-icon nc-zoom-split"
                >
                </el-input>
              </el-tooltip>
            </div>
            <div class="col-sm-1 center cursors_pointer options_expander" @click="showMoreOptions = !showMoreOptions">
              <i :class="showMoreOptions ? 'fa fa-angle-double-up fa-lg' : 'fa fa-angle-double-down fa-lg'"
                 aria-hidden="true"></i>
            </div>
          </div>
          <div class="row" v-if="showMoreOptions" style="margin-bottom: 5px">
            <div class="col-sm-4">
              <el-tooltip class="item" effect="light" content="Sortowanie" placement="top" :open-delay="500">
                <el-select v-model="sortOrder" name="sortOrder" data-vv-as="Sortowanie" v-on:input="changeFilter()"
                           style="width: 100%">
                  <el-option v-for="order in sortKeys" :label="order.label" :value="order.name"
                             :key="order.name"></el-option>
                </el-select>
              </el-tooltip>
            </div>
            <div class="col-sm-5">
              <el-tooltip class="item" effect="light" content="Obsługujący" placement="top" :open-delay="500">
                <el-select v-model="selectedOwnerID" name="ownerFilter" data-vv-as="Obsługująy"
                           v-on:input="changeFilter()" style="width: 100%">
                  <el-option v-for="owner in userGroupArray" :label="owner.name" :value="owner.id"
                             :key="owner.id"></el-option>
                </el-select>
              </el-tooltip>
            </div>
            <div class="col-sm-3">
              <el-tooltip class="item" effect="light" content="Liczba wyświetlanych zgłoszeń" placement="top"
                          :open-delay="500">
                <el-select v-model="msgLimit" name="msgLimit" data-vv-as="Limit" v-on:input="changeFilter()"
                           style="width: 100%">
                  <el-option v-for="limit in [5, 10, 15, 25, 50]" :label="limit" :value="limit"
                             :key="limit"></el-option>
                </el-select>
              </el-tooltip>
            </div>
          </div>
          <div :class="sidePanelBool ? 'card card-list card-list-long' : 'card card-list'" v-if="complaintsReady">
            <div
              :class="complaint.id === activeComplaintID ? 'card border border-success mb-3' : 'card'"
              v-for="complaint in filteredMails"
              :key="complaint.id"
              style="margin-bottom: 10px"
            >
              <!-- Complaints Cards component -->
              <appComplaintsCard
                :complaint="complaint"
                :sidePanelBool="sidePanelBool"
                :filters="filters"
                :activeComplaintID="activeComplaintID"
                :users="users"
              ></appComplaintsCard>
            </div>
          </div>
          <div
            v-else-if="$store.state.complaints.loading_status !== 'loading' && !complaintsReady"
            class="center"
            style="margin: 20px; font-size: 1.2em"
          >
            Brak danych
          </div>
          <div class="block center">
            <el-pagination
              v-on:current-change="changeFilter($event)"
              :hide-on-single-page="true"
              background
              layout="prev, pager, next"
              :total="maxPage * msgLimit"
              :page-size="msgLimit"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="col-lg-8" v-if="sidePanelBool">
        <appComplaintToolbar
          :editPermitted="editPermitted"
          :companyID="companyID"
          :macro="macro"
          :userData="userData"
          :userDataCounter="userDataCounter"
          :activeComplaintID="activeComplaintID"
          :showResponseBool="showResponseBool"
          :isManualComplaints="isManualComplaints"
          :statusID="complaints.complaints && complaints.complaints[activeComplaintID].status_id"
        ></appComplaintToolbar>
        <div class="row" v-show="showResponseBool">
          <div class="col-xl-12 col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col">
                    <h5>Temat: {{ subject }}</h5>
                  </div>
                  <div class="col-auto text-right" style="padding: 0 5px">
                    <el-tooltip class="item" effect="light" content="Dodaj załącznik" placement="top" :open-delay="500">
                      <div class="action-icon files-icon">
                        <i class="fa fa-lg fa-paperclip" aria-hidden="true"></i>
                        <input type="file" class="inputfile" id="responseFile" ref="file" multiple
                               v-on:change="handleFileUpload"/>
                      </div>
                    </el-tooltip>
                  </div>
                  <div class="col-sm-4 text-right" style="display: inline-flex">
                    <div v-if="failedTemplateParams.length > 0" class="failed-templates-exclamation">
                      <el-tooltip class="item" effect="light" :content="failedTemplateParams.join(', ')" placement="top"
                                  :open-delay="500">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                      </el-tooltip>
                    </div>
                    <div class="action-select">
                      <el-select
                        v-model="activeResponseTemplateID"
                        @input="fillTemplate()"
                        name="activeResponseTemplateID"
                        data-vv-as="Szablon"
                        filterable
                        clearable
                        style="width: 100%"
                      >
                        <el-option
                          v-for="template in filteredTemplates"
                          :label="template.id + '. ' + template.description"
                          :value="template.id"
                          :key="template.id"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <label>Treść odpowiedzi</label>
                <span v-html="preTemplate"></span>
                <el-input type="textarea" name="response" :autosize="{ minRows: 4, maxRows: 8 }"
                          placeholder="Treść odpowiedzi" v-model="response">
                </el-input>
                <span v-html="postTemplate"></span>

                <div class="row">
                  <div class="col-sm-4">
                    <p-button :disabled="response.length < 1" type="success" size="sm" @click="sendResponse">Wyślij
                    </p-button>
                    <span> do </span>
                  </div>
                  <div class="col-sm-8">
                    <fg-input :error="getError('mail')" data-vv-as="Mail">
                      <el-select v-model="responseEmail" name="mail" data-vv-as="Mail" v-validate="mailValidator"
                                 filterable allow-create clearable>
                        <el-option v-for="record in responseEmailArray" :label="record" :value="record"
                                   :key="record"></el-option>
                      </el-select>
                    </fg-input>
                  </div>
                </div>

                <div v-if="fileNames.length > 0">
                  <hr/>
                  <div>Dodane załączniki:</div>
                  <div v-for="(file, index) in fileNames" :key="index">
                    <span class="response_files" @click="deleteSubmitedFile(index)">
                      <i :class="getFileClass(files[index].type)" aria-hidden="true"></i>
                      <span style="padding-left: 10px">{{ file }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="complaintsReady && activeComplaintID">
          <div v-for="request in complaints.complaints[activeComplaintID].requests"
               v-bind:key="request.type + '_' + request.id">
            <div v-if="request.type === 'response'" class="d-flex justify-content-end">
              <div style="display: none">{{ changeCounter }}</div>
              <div class="card border border-success mb-3 card-witdh">
                <div class="card-header">
                  <div class="row">
                    <div class="col-sm-6">
                      <i class="fa fa-reply" aria-hidden="true" style="padding-right: 5px"></i>
                      {{ request.sort_time }}
                    </div>
                    <div class="col-sm-6 text-right">
                      <span>
                        {{ `[${request.system_user_id}] ${getUser(request.system_user_id)}` }}
                      </span>
                      <span
                        @click="
                          request.show_response = !request.show_response;
                          changeCounter++;
                        "
                      >
                        <i
                          :class="request.show_response ? 'fa fa-chevron-right response-arrow' : 'fa fa-chevron-right response-arrow-rotate'"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-if="request.show_response" class="card-body">
                  <h5 class="card-title" style="font-size: 16px">{{ "Temat: " + getSubject(request.subject) }}</h5>
                  <p class="card-text">
                    <span id="responseContainer" v-html="prepareHTMLMessage(request.message)"></span>
                  </p>
                </div>
                <div v-else style="height: 10px"></div>
              </div>
            </div>
            <div v-if="request.type === 'request'" class="d-flex justify-content-start">
              <div class="card border border-danger mb-3 card-witdh">
                <div class="card-header">
                  <div class="row">
                    <div class="col-sm-6">
                      <i class="fa fa-envelope-o" aria-hidden="true" style="padding-right: 5px"></i>
                      {{ request.sort_time }}
                    </div>
                    <div class="col-sm-6 text-right">
                      {{ getRequestSender(request, complaints.complaints[activeComplaintID]) }}
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <h5 class="card-title" style="font-size: 16px">
                    {{ "Temat: " + getSubject(request.subject) }}
                  </h5>
                  <p class="card-text card-response">
                    <span v-html="prepareHTMLMessage(request.message)"></span>
                  </p>
                  <div v-if="request.request_files">
                    <hr/>
                    <div>Załączniki:</div>
                    <div class="request_files" v-for="file in request.request_files" :key="file.id"
                         @click="downloadFile(file)">
                      <i :class="getFileClass(file.mime_type)" aria-hidden="true"></i>
                      <span style="padding-left: 10px">{{ file.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="request.type === 'action'" class="d-flex justify-content-end">
              <div class="card border border-warning mb-3 card-witdh">
                <div class="card-header">{{ request.sort_time }}</div>
                <div class="card-body">
                  <p class="card-text">
                    <span
                    ><b>{{
                        `${request.action_type_name} [${request.key_type}] - ${request.key} (rekordy: ${request.action_counter})`
                      }}</b></span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div v-if="request.type === 'operation'" class="d-flex justify-content-end">
              <div class="card border border-info mb-3 card-witdh">
                <div class="card-header">
                  <div class="row">
                    <div class="col-sm-6">
                      <i :class="getOperationIcon(request)" aria-hidden="true" style="padding-right: 5px"></i>
                      <span>{{ request.sort_time }}</span>
                    </div>
                    <div class="col-sm-6 text-right">
                      {{ `[${request.system_user_id}] ${getUser(request.system_user_id)}` }}
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <p class="card-text no-margin">
                    <span
                    ><b>{{ getOperationInfo(request) }}</b></span
                    >
                  </p>
                  <p class="card-text no-margin">
                    <span>{{ getComment(request) }}</span>
                  </p>
                  <div v-if="request.complaint_flow_files">
                    <hr/>
                    <div>Załączniki:</div>
                    <div class="request_files" v-for="file in request.complaint_flow_files" :key="file.id"
                         @click="downloadFile(file)">
                      <i :class="getFileClass(file.mime_type)" aria-hidden="true"></i>
                      <span style="padding-left: 10px">{{ file.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import {
  Select,
  Option,
  OptionGroup,
  Input,
  Tree,
  Tag,
  Tooltip,
  Pagination,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Button
} from "element-ui";

// vuex actions
import {LOAD_COMPLAINTS, COMPLAINTS_INVALID} from "src/store/actions/complaints/complaints";
import {LOAD_RESPONSE_TEMPLATES, RESPONSE_TEMPLATES_INVALID} from "src/store/actions/complaints/response_templates";
import {LOAD_MAILBOXES, MAILBOXES_INVALID} from "src/store/actions/complaints/mailboxes";
import {LOAD_USERS, USERS_INVALID} from "src/store/actions/admin/users";
import {LOAD_USER_GROUP_LIST, USER_GROUP_LIST_INVALID} from "src/store/actions/complaints/user_group_list";
import {LOAD_PARTNERS, PARTNERS_INVALID} from "src/store/actions/admin/partners";
import {LOAD_USER_DATA_COUNTER, USER_DATA_COUNTER_INVALID} from "src/store/actions/complaints/user_data_counter";
import {LOAD_MACRO, MACRO_INVALID} from "src/store/actions/complaints/macro";
import {SAVE_RESPONSE_FILES} from "src/store/actions/complaints/response_files";

import {SAVE_SET_SPAM} from "src/store/actions/complaints/actions/set_spam";
import {SAVE_SET_RESPONSE} from "src/store/actions/complaints/actions/set_response";
import {SAVE_SET_FORWARD} from "src/store/actions/complaints/actions/set_forward";
import {SAVE_SET_STATUS} from "src/store/actions/complaints/actions/set_status";
import {SAVE_SET_CLOSE} from "src/store/actions/complaints/actions/set_close";

// event bus
import {eventBus} from "src/main";
import moment from "moment";

import Swal from "sweetalert2";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

import ForwardComplaint from "src/components/Utils4/complaints/views/ForwardComplaint.vue";
import HandleUserData from "src/components/Utils4/complaints/views/HandleUserData.vue";
import HandleBlacklist from "src/components/Utils4/complaints/views/HandleBlacklist.vue";
import HandleStatusChange from "src/components/Utils4/complaints/views/HandleStatusChange.vue";
import CreateComplaint from "src/components/Utils4/complaints/views/CreateComplaint.vue";
import templates_bbcodes from "src/components/Utils4/complaints/templates_bbcodes";
import HandleMacro from "src/components/Utils4/complaints/views/HandleMacro.vue";

import ComplaintsCard from "src/components/Utils4/complaints/views/ComplaintsCard.vue";
import ComplaintToolbar from "src/components/Utils4/complaints/views/ComplaintToolbar.vue";
import TreeView from "src/components/Utils4/complaints/views/TreeView.vue";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Input.name]: Input,
    [Tree.name]: Tree,
    [Tag.name]: Tag,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
    appComplaintsCard: ComplaintsCard,
    appComplaintToolbar: ComplaintToolbar,
    appTreeView: TreeView,
  },
  data() {
    return {
      loading: false,
      sidePanelBool: false,
      showMoreOptions: false,
      showResponseBool: false,
      activeComplaintID: null,
      activeResponseTemplateID: null,
      companies: {},
      preTemplate: "",
      postTemplate: "",
      response: "",
      responseEmail: "",
      responseEmailArray: [],
      mailValidator: {
        regex: regexp.EMAIL_REGEXP,
        min: 1,
        max: 128,
      },
      filters: [
        {id: -1, name: "NEW/REOPENED", label: "Nowe/Otwarte"},
        {id: 0, name: "ALL", label: "Wszystkie"},
        {id: 1, name: "NEW", label: "Nowe"},
        {id: 2, name: "SPAM", label: "Spam"},
        {id: 3, name: "REOPENED", label: "Otwarte"},
        {id: 4, name: "WAITING", label: "Zawieszone"},
        {id: 5, name: "TOSEND", label: "Do wysłania"},
        {id: 6, name: "CLOSED", label: "Zamknięte"},
        {id: 7, name: "ERROR", label: "Błędne"},
        {id: 999, name: "PENDING", label: "Przetwarzane"},
      ],
      selectedFilterID: -1,
      selectedOwnerID: "user_" + this.$store.state.profile.data.ID,
      selectedMailboxID: null,
      companyID: null,
      mailboxName: "",
      searchQuery: "",
      searchTimer: 0,
      page: 1,
      maxPage: 1,
      msgLimit: 25,
      sortOrder: "RESPONSE_DESC",
      sortKeys: [
        {name: "CREATED_ASC", label: "Najstarsze utworzone"},
        {name: "CREATED_DESC", label: "Najnowsze utworzone"},
        {name: "UPDATED_ASC", label: "Najstarsze akcje"},
        {name: "UPDATED_DESC", label: "Najnowsze akcje"},
        {name: "RESPONSE_ASC", label: "Najstarszy email"},
        {name: "RESPONSE_DESC", label: "Najnowszy email"},
        {name: "REMAINING_ASC", label: "Najkrótszy czas"},
        {name: "REMAINING_DESC", label: "Najdłuższy czas"},
      ],
      bbcodes: templates_bbcodes,
      userSearchKeys: null,
      userData: null,
      consents: null,
      obligation: null,
      sales: null,
      files: [],
      fileNames: [],
      changeCounter: 0,
      userDataCounter: {},
    };
  },
  computed: {
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_COMPLAINTS);
    },
    partners() {
      return this.$store.getters.getPartners;
    },
    filteredMails() {
      if (this.complaintsReady) {
        let result = Object.values(this.complaints.complaints);
        const arrgs = this.sortOrder.split("_");
        const type = arrgs[0] === "CREATED" ? "event_time" : "update_time";
        if (arrgs[1] === "ASC") {
          result = result.sort((a, b) => moment(a[type]).diff(moment(b[type])));
        } else {
          result = result.sort((a, b) => moment(b[type]).diff(moment(a[type])));
        }
        return result;
      } else {
        return [];
      }
    },
    complaints() {
      return _.cloneDeep(this.$store.getters.getComplaints);
    },
    users() {
      return this.$store.getters.getUsers;
    },
    userGroup() {
      let data = _.cloneDeep(this.$store.getters.getUserGroupList);
      if (data.user) {
        data.user.map((x) => {
          if (this.users.isPrototypeOf(x.system_user_id)) {
            x.name = this.users[x.system_user_id].user_first_name + " " + this.users[x.system_user_id].user_last_name;
            x.email = this.users[x.system_user_id].user_email;
          }
        });
        return data;
      }
      return {group: [], user: []};
    },
    userGroupArray() {
      const data = this.userGroup;
      const result = [];
      data.group.forEach((x) => {
        let obj = {};
        obj.name = "[" + x.name + "]";
        obj.id = "group_" + x.group_id;
        obj.type = "group";
        result.push(obj);
      });
      data.user.forEach((x) => {
        let obj = {};
        obj.name = x.name;
        obj.id = "user_" + x.system_user_id;
        obj.type = "user";
        result.push(obj);
      });
      result.unshift({name: "Wszystkie", id: "all", type: "all"});
      return result;
    },
    partners() {
      return this.$store.getters.getPartners;
    },
    responseTemplates() {
      return this.$store.getters.getResponseTemplates;
    },
    filteredTemplates() {
      return Object.values(this.responseTemplates).filter((x) => x.company_id === this.companyID);
    },
    mailboxes() {
      return this.$store.getters.getMailboxes;
    },
    complaintsReady() {
      return this.$store.state.complaints.loading_status === "success" && !_.isEmpty(this.complaints.complaints);
    },
    subject() {
      if (this.complaintsReady && this.activeComplaintID && this.complaints.complaints[this.activeComplaintID].requests[0]) {
        const initRequest = _.last(this.complaints.complaints[this.activeComplaintID].requests);
        if (initRequest.type === "action") {
          return initRequest.action_type_name;
        } else {
          return initRequest.subject ? initRequest.subject : "-";
        }
      } else {
        return "brak";
      }
    },
    isManualComplaints() {
      if (String(this.selectedMailboxID).includes("_")) {
        return true;
      } else {
        return false;
      }
    },
    failedTemplateParams() {
      let failedParams = [];

      if (this.response == "") return [];

      const found = this.response.match(/{[^}]+}/gm);
      let uniqueFailed = [];

      if (found) {
        found.forEach((bbcode) => {
          failedParams.push(bbcode);
        });

        uniqueFailed = failedParams.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      }

      return uniqueFailed;
    },
    macro() {
      if (!this.editPermitted) return [];
      return this.$store.getters.getMacro.filter((x) => x.company_id == this.companyID);
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    showSidePanel(complaint_id) {
      this.prepareTemplates();
      this.sidePanelBool = true;
      this.showResponseBool = false;
      this.activeComplaintID = complaint_id;
      this.response = "";
      this.responseEmail = this.complaints.complaints[complaint_id].from_email;
      this.prepareEmailArray();
      this.userData = null;
      this.consents = null;
      this.obligation = null;
      this.sales = null;
      this.activeResponseTemplateID = "";
      this.userSearchKeys = null;
      this.files = [];
      this.fileNames = [];
      this.loadUserDataCounter(complaint_id);
    },
    closeSidePanel() {
      this.sidePanelBool = false;
      this.showResponseBool = false;
      this.activeComplaintID = null;
      this.userData = null;
      this.consents = null;
      this.obligation = null;
      this.sales = null;
      this.userSearchKeys = null;
      this.preTemplate = "";
      this.postTemplate = "";
      this.response = "";
      this.responseEmail = "";
      this.responseEmailArray = [];
      this.activeResponseTemplateID = "";
      this.files = [];
      this.fileNames = [];
      this.countMailboxes();
    },
    async loadData(force) {
      this.loading = true;

      this.$store.commit(COMPLAINTS_INVALID);
      if (force) {
        this.$store.commit(USERS_INVALID);
        this.$store.commit(USER_GROUP_LIST_INVALID);
        this.$store.commit(PARTNERS_INVALID);
        this.$store.commit(RESPONSE_TEMPLATES_INVALID);
        this.$store.commit(MAILBOXES_INVALID);
        this.$store.commit(MACRO_INVALID);
      }

      await this.$store
        .dispatch(LOAD_MAILBOXES, {})
        .then(() => {
          if (force && !_.isEmpty(this.mailboxes)) {
            this.companies = _.cloneDeep(this.mailboxes.companies);
            let companyID = this.companyID ? this.companyID : Number(_.findKey(this.companies, "id"));
            let mailboxID = this.selectedMailboxID ? this.selectedMailboxID : _.findKey(this.companies[companyID].mailboxes, "id");
            this.companyID = companyID;
            this.selectedMailboxID = mailboxID;
            this.prepareCompanies(companyID, mailboxID);
          }
        })
        .catch((err) => {
          console.log(err);
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

      const filters = {
        mailbox_id: this.selectedMailboxID,
        status_id: this.selectedFilterID,
        order: this.sortOrder,
        limit: this.msgLimit,
        owner: this.selectedOwnerID,
        searchQuery: this.searchQuery,
        page: this.page,
      };

      this.$store.dispatch(LOAD_USERS, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_USER_GROUP_LIST, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PARTNERS, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      if (!this.selectedMailboxID) {
        this.loading = false;
        return;
      }

      this.$store.dispatch(LOAD_RESPONSE_TEMPLATES, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      if (this.editPermitted) {
        this.$store.dispatch(LOAD_MACRO, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }

      await this.$store
        .dispatch(LOAD_COMPLAINTS, filters)
        .then(() => {
          if (this.complaints.allCount[this.selectedMailboxID]) {
            this.maxPage = Math.ceil(this.complaints.allCount[this.selectedMailboxID].counter / this.msgLimit);
          } else {
            this.maxPage = 1;
          }
        })
        .catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

      this.loading = false;
      this.countMailboxes();
    },
    countMailboxes() {
      let counters = this.$store.getters.getComplaints.counter;
      let companyCounters = this.$store.getters.getComplaints.companyCounter;
      for (const company_id in this.companies) {
        const company = this.companies[company_id];
        if (companyCounters[company_id]) {
          company.count = companyCounters[company_id].counter;
        }
        for (const mailbox_id in this.companies[company_id].mailboxes) {
          const mailbox = this.companies[company_id].mailboxes[mailbox_id];
          if (counters[mailbox_id]) {
            mailbox.count = counters[mailbox_id].counter;
          }
        }
      }
    },
    prepareCompanies(companyID, mailboxID) {
      companyID = companyID ? companyID : this.companyID;
      mailboxID = mailboxID ? mailboxID : this.selectedMailboxID;
      this.companies = _.cloneDeep(this.mailboxes.companies);
      this.mailboxName = this.companies[companyID].mailboxes[mailboxID].name;
    },
    async changeFilter(currentPage, keepData = false) {
      this.showResponseBool = false;
      let keepOpen = false;

      if (this.sidePanelBool) keepOpen = true;

      if (!keepData) {
        this.sidePanelBool = false;
        this.activeComplaintID = null;

        if (currentPage) {
          this.page = currentPage;
        } else {
          this.page = 1;
          this.maxPage = 1;
        }
      } else {
        let userData = this.userData;
        let userSearchKeys = this.userSearchKeys;
      }

      await this.loadData(false);
      window.scrollTo(0, 0);

      if (!keepData) {
        if (keepOpen && !_.isEmpty(this.filteredMails)) {
          if (this.sortOrder.includes("DESC")) {
            this.showSidePanel(this.filteredMails[0].id);
          } else {
            this.showSidePanel(_.last(this.filteredMails).id);
          }
        }
      } else {
        if (this.sidePanelBool && !_.isEmpty(Object.keys(this.complaints.complaints)) && this.complaints.complaints[this.activeComplaintID]) {
          this.showSidePanel(this.activeComplaintID);
        }
        let activeComplaint = this.complaints.complaints[this.activeComplaintID];
        if (
          userData.Phone == activeComplaint.from_msisdn ||
          userData.Email == activeComplaint.from_email ||
          userData.PhoneUD == activeComplaint.from_msisdn ||
          userData.EmailUD == activeComplaint.from_email
        ) {
          this.userData = userData;
          this.userSearchKeys = userSearchKeys;
        }
      }
    },
    debounce(cb, delay) {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(cb, delay);
    },
    handleSearch(query) {
      this.debounce(() => {
        this.changeFilter();
      }, 1000);
    },
    async submitResponseFile() {
      const FILES_SIZE_LIMIT = 1024 * 1024 * 25; // 25Mb
      let files_size = 0;
      let result = true;

      this.files.forEach((file) => {
        files_size += file.size;
      });

      if (files_size > FILES_SIZE_LIMIT) {
        eventBus.$emit("notify", {
          message: "Rozmiar załączników przekracza dozwoloną wartość (> 25MB)",
          type: "warning",
        });
        return;
      }

      // Initialize the form data
      let formData = new FormData();
      // Add the form data we need to submit
      this.files.forEach((file, index) => {
        formData.append("file" + "_" + index, file);
      });

      await this.$store.dispatch(SAVE_RESPONSE_FILES, formData).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu załącznika!",
          type: "warning",
        });
        result = false;
      });

      result = this.$store.getters.getResponseFilesModifiedRecords;

      if (result) {
        this.files = [];
        this.fileNames = [];
      }

      return result;
    },
    async sendResponse() {
      let files = [];

      if (this.failedTemplateParams.length > 0) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-light btn-fill",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons
          .fire({
            title: "W wiadomości znajdują się niepodmienione bbcody!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Wróć",
          })
          .then(() => {
            return;
          })
          .catch(() => {
            return;
          });
      } else {
        if (this.files.length > 0) {
          // send response files to server
          let submitResult = await this.submitResponseFile();
          files = submitResult;

          if (!submitResult) {
            return;
          }
        }

        if (!this.responseEmail.match(/\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/g)) {
          return eventBus.$emit("notify", {
            message: "Należy podać prawidłowy adres email!",
            type: "warning",
          });
        }

        const activeComplaint = this.complaints.complaints[this.activeComplaintID];
        let model = {
          complaint_id: this.activeComplaintID,
          complaint_flow_id: activeComplaint.complaint_flow_id,
          mailbox_id: this.selectedMailboxID,
          email: this.responseEmail,
          subject: this.subject === "-" ? null : this.subject,
          message: this.response,
          response_template_id: this.activeResponseTemplateID,
          files: files,
        };

        // replace new line characters with html <br> tag
        model.message = model.message.replace(/(?:\r\n|\r|\n)/g, "<br>");

        await this.$store.dispatch(SAVE_SET_RESPONSE, model).catch((error) => {
          let message = "";
          console.log(error.response);
          switch (error.response.data.code) {
            case 403:
              message = "Należy wybrać testową skrzynkę!";
              break;
            default:
              message = "Błąd zapisu danych!";
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning",
          });
        });

        if (this.$store.getters.getSetResponseModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Wiadomość została wysłana",
            type: "success",
          });
          this.changeFilter();
        }
      }
    },
    prepareTemplates() {
      let template = this.companies[this.companyID].mailboxes[this.selectedMailboxID].template;
      if (template) {
        this.preTemplate = template.split("{MESSAGE}")[0];
        this.postTemplate = template.split("{MESSAGE}")[1];
      } else {
        this.preTemplate = "";
        this.postTemplate = "";
      }
    },
    changeMailbox(id) {
      this.selectedMailboxID = id;
      this.mailboxName = this.companies[this.companyID].mailboxes[this.selectedMailboxID].name;
      if (this.sidePanelBool) {
        this.closeSidePanel();
      }
      this.page = 1;
      this.maxPage = 1;
      this.loadData(false);
    },
    changeCompany(id) {
      this.companyID = id;
    },
    async handleSpam(complaint) {
      const newStatus = complaint.status_id == 2 ? 3 : 2;

      let model = {
        complaint_id: complaint.id,
        status_id: newStatus,
      };

      await this.$store.dispatch(SAVE_SET_SPAM, model).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getSetSpamModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Status został zmieniony",
          type: "success",
        });
        this.changeFilter(this.page);
      }
    },
    async handleForward(model) {
      await this.$store.dispatch(SAVE_SET_FORWARD, model).catch((error) => {
        let message = "";
        console.log(error.response);
        switch (error.response.data.code) {
          case 403:
            message = "Nieobsługiwany odbiorca!";
            break;
          default:
            message = "Błąd zapisu danych!";
        }
        eventBus.$emit("notify", {
          message: message,
          type: "warning",
        });
      });

      if (this.$store.getters.getSetForwardModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Reklamacja została przekazana",
          type: "success",
        });

        this.changeFilter();
      }
    },
    getUser(user_id) {
      if (user_id) {
        return this.users[user_id].user_first_name + " " + this.users[user_id].user_last_name;
      } else {
        return "brak";
      }
    },
    getCurrentOwner({current_user_id, current_group_name}) {
      if (current_user_id) {
        return this.users[current_user_id].user_first_name + " " + this.users[current_user_id].user_last_name;
      } else {
        return current_group_name;
      }
    },
    getComment(request) {
      let content = "";
      if (request.operation_type == "ASSIGN") {
        content += `[Do: ${this.getCurrentOwner(request)}] `;
      }
      if (request.comment) {
        content += request.comment;
      }
      return content;
    },
    handleResponse() {
      if (String(this.selectedMailboxID).includes("_") || !this.editPermitted) {
        return;
      }
      if (this.showResponseBool) {
        this.showResponseBool = false;
        this.response = "";
        this.responseEmail = "";
        this.responseEmailArray = [];
        this.activeResponseTemplateID = null;
        this.files = [];
        this.fileNames = [];
      } else {
        this.showResponseBool = true;
        this.responseEmail = this.complaints.complaints[this.activeComplaintID].from_email;
        this.prepareEmailArray();
      }
    },
    fillTemplate() {
      if (_.isNull(this.activeResponseTemplateID) || this.activeResponseTemplateID === "") {
        return (this.response = "");
      }

      let template = this.responseTemplates[this.activeResponseTemplateID].body;

      if (!_.isNull(this.userData)) {
        const found = template.match(/{[^}]+}/gm);
        let param;
        if (found) {
          found.forEach((bbcode) => {
            let bbObj = this.bbcodes[bbcode];
            if (bbObj.condition) {
              if (bbObj.param === "key") {
                if (_.isEmpty(this.userSearchKeys.mail)) {
                  param = "phone";
                } else {
                  param = "email";
                }
              } else {
                if (this.userData[bbObj.param]) {
                  param = this.userData[bbObj.param];
                } else {
                  return;
                }
              }
              template = template.replace(bbcode, bbObj.results[param]);
            } else {
              if (bbObj.param === "Consents" && this.consents) {
                param = this.consents;
                template = template.replace(bbcode, param);
              } else if (bbObj.param === "Obligation" && this.obligation) {
                param = this.obligation;
                template = template.replace(bbcode, param);
              } else if (bbObj.param === "Company" && this.sales) {
                param = this.sales;
                template = template.replace(bbcode, param);
              } else if (bbObj.param === "IpAddress") {
                if (this.userData[bbObj.param]) {
                  param = `z adresu IP: ${this.userData[bbObj.param]}`;
                } else {
                  param = "";
                }
                template = template.replace(bbcode, param);
              } else if (this.userData[bbObj.param]) {
                param = this.userData[bbObj.param];
                template = template.replace(bbcode, param);
              }
            }
          });
        }
      }
      this.response = template;
    },
    forwardComplaint() {
      const mailbox = this.mailboxes.companies[this.companyID]?.mailboxes[this.selectedMailboxID]?.name;
      const requests = this.complaints.complaints[this.activeComplaintID]?.requests;
      const request = requests ? requests[requests.length - 1].message : null;
      this.$modal.show(
        ForwardComplaint,
        {
          complaint_id: this.activeComplaintID,
          users: this.users,
          partners: this.partners,
          user_group: this.userGroup,
          mailbox: mailbox,
          request: request,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(ForwardComplaint);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "400px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    handleCreateComplaint() {
      this.$modal.show(
        CreateComplaint,
        {
          companies: this.companies,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(CreateComplaint);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "400px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    searchData() {
      let phone = [];
      let mail = [];
      const requests = this.complaints.complaints[this.activeComplaintID].requests.filter((x) => x.type === "request");
      if (!_.isEmpty(requests)) {
        let message = "";
        requests.forEach((msg) => {
          message += msg.message + "\r\n"; // concat all user messages to check for phone and mail
        });
        const tagsRegex = /(<([^>]+)>)/gi;
        message = message.replace(/\&nbsp;/g, " ");
        message = message.replace(tagsRegex, " ");
        phone = message.match(/(\+?(\d{2})? ?-?\d{3} ?-?\d{3} ?-?\d{3})/g);
        mail = message.match(/\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/g);

        if (phone) {
          phone = phone.filter((x) => {
            const indexOfFirst = message.indexOf(x);
            const specialExpressions = ["regon", "krs", "nip"];
            if (indexOfFirst != -1) {
              let startOf = indexOfFirst - 8;
              let endOf = indexOfFirst;
              let searchResult = message.substring(startOf, endOf);
              let result = 1;
              specialExpressions.forEach((exp) => {
                let regex = new RegExp(exp, "gmi");
                let res = searchResult.search(regex);
                if (res > 0) {
                  result = 0;
                }
              });
              return result;
            }
          });
        }
      }

      phone = [...new Set(phone)];
      mail = [...new Set(mail)];

      if (this.complaints.complaints[this.activeComplaintID].from_email) {
        mail.unshift(this.complaints.complaints[this.activeComplaintID].from_email);
      }

      if (this.complaints.complaints[this.activeComplaintID].from_msisdn) {
        phone.unshift(String(this.complaints.complaints[this.activeComplaintID].from_msisdn));
      }

      // disabled domains check
      // const approvedDomains = ["pl", "com", "eu"];

      // mail = mail.filter(x => {
      //   return x !== this.mailboxName && approvedDomains.includes(x.slice(x.lastIndexOf(".") + 1));
      // });
      phone = phone.map((x) => {
        x = x.replace(/\s/g, "");
        x = x.replace(/-/g, "");
        x = x.replace(/\D/g, "");
        if (x.length === 9) {
          x = "48" + x;
        }
        return x;
      });

      mail = _.uniq(mail);
      phone = _.uniq(phone);

      return {phone, mail};
    },
    handleUserData(mode, isMM, blacklistMode) {
      if (mode === "delete" && !this.editPermitted) return;
      let component = isMM ? HandleUserData : HandleBlacklist;

      let phone, mail;
      if (this.userSearchKeys) {
        phone = this.userSearchKeys.phone === "" ? [] : [this.userSearchKeys.phone];
        mail = this.userSearchKeys.mail === "" ? [] : [this.userSearchKeys.mail];
      } else {
        let searchResult = this.searchData();
        phone = searchResult.phone;
        mail = searchResult.mail;
      }

      this.$modal.show(
        component,
        {
          phone: phone,
          mail: mail,
          mailbox: this.selectedMailboxID,
          company: this.companyID,
          complaintID: this.activeComplaintID,
          mode: mode,
          blacklistMode,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(component);
              },
            },
          ],
        },
        {
          draggable: false,
          width: isMM ? "500px" : "800px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    handleStatusChange(mode) {
      if (mode === "pause" && !this.editPermitted) return;

      const activeStatus = this.complaints.complaints[this.activeComplaintID].status_id;
      const status_id = activeStatus == 4 ? 3 : 4;

      this.$modal.show(
        HandleStatusChange,
        {
          complaintID: this.activeComplaintID,
          mode: mode,
          status_id: status_id,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(HandleStatusChange);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "500px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    async saveStatusChange(model) {
      const activeStatus = this.complaints.complaints[this.activeComplaintID].status_id;
      model.status_id = activeStatus == 4 ? 3 : 4;

      await this.$store.dispatch(SAVE_SET_STATUS, model).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getSetStatusModifiedRecords === true) {
        let message = "";
        if (model.mode === "comment") {
          message = "Komentarz został dodany";
        } else {
          message = "Reklamacja została ";
          if (mode.status_id === 3) {
            message += "przywrócona";
          } else {
            message += "zawieszona";
          }
        }
        eventBus.$emit("notify", {
          message: message,
          type: "success",
        });
        this.changeFilter();
      }
    },
    async closeComplaint() {
      if (!this.editPermitted) return;

      const activeStatus = this.complaints.complaints[this.activeComplaintID].status_id;
      let model = {
        complaint_id: this.activeComplaintID,
        status_id: activeStatus == 6 ? 3 : 6,
      };

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: `btn btn-${model.status_id === 6 ? "danger" : "success"} btn-fill`,
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: `Czy na pewno chcesz ${model.status_id === 6 ? "zamknąć" : "przywrócić"} reklamację?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: model.status_id === 6 ? "Zamknij" : "Przywróć",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then(async (res) => {
          if (res.isDismissed) {
            return;
          }
          if (res.isConfirmed) {
            await this.$store.dispatch(SAVE_SET_CLOSE, model).catch((error) => {
              eventBus.$emit("notify", {
                message: "Błąd zapisu danych!",
                type: "warning",
              });
            });

            if (this.$store.getters.getSetCloseModifiedRecords === true) {
              eventBus.$emit("notify", {
                message: `Reklamacja została ${activeStatus == 6 ? "przywrócona" : "zamknięta"}`,
                type: "success",
              });
              // this.closeSidePanel();
              await this.changeFilter();
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    downloadFile(file) {
      const filePath = file.file_path;
      const fileName = file.name;
      const fileSize = file.size;
      const fileType = file.mime_type;

      const basePath = "https://api-utils4.mobi-me.pl/download/download/request_file/";
      const userToken = this.$store.state.auth.token;
      const file_path = `${basePath}?token=${userToken}&filepath=${filePath}&filename=${fileName}&filesize=${fileSize}&filetype=${fileType}`;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getFileClass(type) {
      switch (type) {
        case "application/pdf":
          return "fa fa-file-pdf-o";
        case "text/plain":
          return "fa fa-file-text-o";
        case "image/gif":
          return "fa fa-file-image-o";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "fa fa-file-excel-o";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "fa fa-file-word-o";
        default:
          return "fa fa-file-o";
      }
    },
    handleFileUpload(e) {
      var files = e.target.files;
      if (!files.length) return;
      Array.from(files).forEach((file) => {
        this.files.push(file);
        this.fileNames.push(file.name);
      });
      document.getElementById("responseFile").value = null;
    },
    deleteSubmitedFile(index) {
      this.files.splice(index, 1);
      this.fileNames.splice(index, 1);
    },
    getSubject(subject) {
      return subject ? subject : "-";
    },
    getOperationInfo(request) {
      switch (request.operation_type) {
        case "CLOSE":
          return "Reklamacja została zamknięta";
        case "OPEN":
          return "Reklamacja została otwarta";
        case "COMMENT":
          return "Dodano komentarz do reklamacji";
        case "SUSPEND":
          return "Zawieszono reklamację";
        case "ASSIGN":
          let responder = "";
          if (request.current_user_id) {
            responder = this.users[request.current_user_id].user_first_name + " " + this.users[request.current_user_id].user_last_name;
          } else {
            responder = request.current_group_name;
          }
          return "Przekazano reklamację do: " + responder;
        case "UNSUBSCRIBE":
          return request.api_call_status === "PENDING" ? "Wypisano użytkownika - OCZEKUJE" : "Wypisano użytkownika";
        case "DELETE":
          return request.api_call_status === "PENDING" ? "Usunięto dane użytkownika - OCZEKUJE" : "Usunięto dane użytkownika";
        case "SPAM":
          return "Oznaczono reklamację jako spam";
        default:
          return "Nieznana akcja";
      }
    },
    getOperationIcon(request) {
      switch (request.operation_type) {
        case "CLOSE":
          return "fa fa-times";
        case "OPEN":
          return "fa fa-undo";
        case "COMMENT":
          return "fa fa-comment-o";
        case "SUSPEND":
          return "fa fa-clock-o";
        case "ASSIGN":
          return "fa fa-user-o";
        case "UNSUBSCRIBE":
          return "fa fa-ban";
        case "DELETE":
          return "fa fa-ban";
        case "SPAM":
          return "fa fa-trash-o";
        default:
          return "";
      }
    },
    prepareEmailArray() {
      const {mail} = this.searchData();
      this.responseEmailArray = mail;
    },
    prepareHTMLMessage(html) {
      const newLineRegex = /\r?\n|\r/g;
      const tagsRegex = /<style.*?<\/style>/gi;
      html = html.toString().replace(newLineRegex, " ");
      return html.replace(tagsRegex, " ");
    },
    getRequestSender(request, complaint) {
      return String(this.selectedMailboxID).includes("_")
        ? `[${complaint.system_user_id}] ${this.users[complaint.system_user_id].user_first_name} ${
          this.users[complaint.system_user_id].user_last_name
        }`
        : request.email;
    },
    goToDashboard() {
      this.$router.push({name: "Pulpit"});
    },
    validatePhone(phone) {
      if (!phone) {
        return false;
      }
      let validatedPhone = phone.replace(/[^0-9]/g, "");
      validatedPhone = validatedPhone.length == 9 ? "48" + validatedPhone : validatedPhone;
      if (validatedPhone.length != 11) {
        return false;
      } else {
        this.model.phone = validatedPhone;
        return validatedPhone;
      }
    },
    async loadUserDataCounter(complaint_id) {
      let phone = this.complaints.complaints[this.activeComplaintID].from_msisdn;
      let mail = this.complaints.complaints[this.activeComplaintID].from_email;

      if (phone && String(phone).length > 0) {
        phone = this.validatePhone(String(phone));
      }

      if (phone !== "" && !phone) {
        phone = "";
      }

      let mailCheck = mail.match(/[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/);

      if (!mailCheck) {
        mail = "";
      }

      if (phone.length < 1 && mail.length < 1) {
        return;
      }

      const filters = {
        phone: phone,
        mail: mail,
      };

      this.loading = true;

      this.$store.commit(USER_DATA_COUNTER_INVALID);

      await this.$store
        .dispatch(LOAD_USER_DATA_COUNTER, filters)
        .then(() => {
          const result = _.isObject(this.$store.getters.getUserDataCounter) ? 0 : this.$store.getters.getUserDataCounter;
          this.userDataCounter = {};
          this.userDataCounter[complaint_id] = result;
        })
        .catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

      this.loading = false;
    },
    handleMacroAction(action) {
      let phone, mail;
      if (this.userSearchKeys) {
        phone = this.userSearchKeys.phone === "" ? [] : [this.userSearchKeys.phone];
        mail = this.userSearchKeys.mail === "" ? [] : [this.userSearchKeys.mail];
      } else {
        let searchResult = this.searchData();
        phone = searchResult.phone;
        mail = searchResult.mail;
      }

      const activeComplaint = this.complaints.complaints[this.activeComplaintID];

      this.$modal.show(
        HandleMacro,
        {
          phone: phone,
          mail: mail,
          mailbox: this.selectedMailboxID,
          company: this.companyID,
          complaintID: this.activeComplaintID,
          complaint_flow_id: activeComplaint.complaint_flow_id,
          response_email: this.responseEmail,
          subject: this.subject === "-" ? null : this.subject,
          macro: action,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(HandleMacro);
              },
            },
          ],
        },
        {
          draggable: false,
          scrollable: true,
          width: "600px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
  },
  async created() {
    const params = this.$route.params;
    if (params.complaintID) {
      this.selectedMailboxID = params.mailbox;
      this.companyID = params.company;

      if (params.from === "history") {
        this.searchQuery = params.complaintID;
        this.selectedOwnerID = "all";
        this.selectedFilterID = 0;
        await this.loadData(true);
      }
      this.activeComplaintID = params.complaintID;
      this.loadUserDataCounter(this.activeComplaintID);
      this.sidePanelBool = true;
      this.response = "";
      this.responseEmail = this.complaints.complaints[this.activeComplaintID].from_email;
      this.prepareEmailArray();
      if (this.complaints.allCount[this.selectedMailboxID]) {
        this.maxPage = Math.ceil(this.complaints.allCount[this.selectedMailboxID].counter / this.msgLimit);
      }
      if (params.row) {
        this.userData = params.row;
      }
      if (params.consents) {
        let consents = "";
        let obligation = "";
        const regex = /(<([^>]+)>)/gi;
        const consentsArray = Object.values(params.consents);
        const filteredConsentsArray = consentsArray.filter((row) => {
          return row.Type != 0 && row.Consent[0] !== "*";
        });
        const filteredObligationArray = consentsArray.filter((row) => {
          return row.Type == 0 && row.Consent[0] !== "*";
        });
        filteredConsentsArray.forEach((row, index) => {
          let clearedConsent = row.Consent.replace(regex, " ");
          consents += clearedConsent;
          if (index + 1 < filteredConsentsArray.length) {
            consents += "\r\n\r\n";
          }
        });
        filteredObligationArray.forEach((row, index) => {
          let clearedObligation = row.Consent.replace(regex, " ");
          obligation += clearedObligation;
          if (index + 1 < filteredObligationArray.length) {
            obligation += "\r\n\r\n";
          }
        });
        this.consents = consents;
        this.obligation = obligation;
      }
      if (params.sales) {
        let sales = "";
        const salesArray = Object.values(params.sales);
        salesArray.forEach((row, index) => {
          sales += row.name;
          if (index + 1 < salesArray.length) {
            sales += "\r\n";
          }
        });
        this.sales = sales;
      }
      if (params.searchKeys) {
        this.userSearchKeys = params.searchKeys;
      }
      this.prepareCompanies();
      this.prepareTemplates();
    } else if (params.mailbox) {
      this.selectedMailboxID = params.mailbox;
      this.companyID = params.company;
      if (params.status) {
        this.selectedFilterID = Number(params.status);
      }
      if (params.owner) {
        this.selectedOwnerID = params.owner;
        this.showMoreOptions = true;
      }
      await this.loadData(true);
    } else {
      this.loadData(true);
    }
    eventBus.$on("forceReload", () => {
      this.closeSidePanel();
      this.loadData(true);
    });
    eventBus.$on("showSidePanel", ({complaint_id}) => {
      this.showSidePanel(complaint_id);
    });
    eventBus.$on("handleSpam", ({complaint}) => {
      this.handleSpam(complaint);
    });
    eventBus.$on("handleForward", ({forward}) => {
      this.handleForward(forward);
    });
    eventBus.$on("saveStatusChange", ({statusModel}) => {
      this.saveStatusChange(statusModel);
    });
    eventBus.$on("handleLeadCompleted", () => {
      this.changeFilter(null, true);
    });
    eventBus.$on("macroCompleted", () => {
      this.changeFilter();
    });
    eventBus.$on("createComplaint", () => {
      this.changeFilter(null);
    });
    eventBus.$on("emitHandleMacroAction", (action) => {
      this.handleMacroAction(action);
    });
    eventBus.$on("emitHandleUserData", ({mode, isMM, blacklistMode}) => {
      this.handleUserData(mode, isMM, blacklistMode);
      // this.handleBlacklist(mode);
    });
    eventBus.$on("emitHandleResponse", () => {
      this.handleResponse();
    });
    eventBus.$on("emitForwardComplaint", () => {
      this.forwardComplaint();
    });
    eventBus.$on("emitCloseComplaint", () => {
      this.closeComplaint();
    });
    eventBus.$on("emitHandleStatusChange", ({mode}) => {
      this.handleStatusChange(mode);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
    eventBus.$off("showSidePanel");
    eventBus.$off("handleSpam");
    eventBus.$off("handleForward");
    eventBus.$off("saveStatusChange");
    eventBus.$off("handleLeadCompleted");
    eventBus.$off("macroCompleted");
    eventBus.$off("createComplaint");
    eventBus.$off("emitHandleMacroAction");
    eventBus.$off("emitHandleUserData");
    eventBus.$off("emitHandleResponse");
    eventBus.$off("emitForwardComplaint");
    eventBus.$off("emitCloseComplaint");
    eventBus.$off("emitHandleStatusChange");
  },
};
</script>

<style lang="scss" scoped>
.center {
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
}

.cursors_pointer {
  cursor: pointer;
}

.filters {
  margin-bottom: 5px;
}

.card-witdh {
  width: 80%;
}

.card-list {
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.card-list-long {
  max-height: 120vh;
}

.card-response {
  max-width: 100%;
  overflow-x: auto;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-right: 8px;
}

.el-tree {
  border-radius: 10px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}

.action-select {
  display: inline-block;
}

.action-icon {
  display: inline-block;
  justify-content: center;
  padding: 7px 15px;
  margin: 0 20px;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  position: relative;
}

.action-icon:hover {
  transition: background-color 200ms ease-in-out;
  background-color: #f7f7f7;
}

.files-icon {
  padding: 10px;
  margin: auto;
}

.block-separator {
  display: inline-block;
  width: 1px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  vertical-align: middle;
}

.complaints__pagination li {
  display: inline;
  margin: 0 5px;
}

.options_expander {
  margin-bottom: 10px;
}

.failed-templates-exclamation {
  position: inherit;
  top: 10px;
  padding-right: 10px;
  color: #dc3545;
}

.request_files {
  font-weight: bold;
}

.request_files:hover {
  text-decoration: underline;
  cursor: pointer;
}

.response_files {
  font-weight: bold;
}

.response_files:hover {
  text-decoration: line-through 2px #dc3545;
  cursor: pointer;
}

.files-icon {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.files-icon input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.response-arrow {
  transition: 0.1s ease-in;
  margin-left: 10px;
  cursor: pointer;
}

.response-arrow-rotate {
  margin-left: 10px;
  transform: rotate(90deg);
  transition: 0.1s ease-in;
  cursor: pointer;
}

.no-margin {
  margin: 0;
}
</style>
