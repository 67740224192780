<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <h5 class="title">{{ editMode ? "Edycja Kampanii " + idCampaign : "Nowa Kampanii" }}</h5>
      </div>
    </div>
    <form>
      <div v-if="!loading" class="row">
        <div class="col-12">
          <label>Nazwa Kampanii</label>
          <fg-input :error="getErrors('name')">
            <el-input
              placeholder="Podaj nazwę"
              data-vv-as="Nazwa Kampanii"
              v-model="campaignModel.name"
              v-validate="campaignModelValidations.campaignName"
              name="name"
            ></el-input>
          </fg-input>
        </div>
      </div>
      <div class="card-footer text-center">
        <router-link to="/mailings/work_campaigns">
          <p-button type="default" link style="margin-right: 5vw"> Anuluj </p-button>
        </router-link>
        <p-button v-if="!editMode" type="success" @click.prevent="handleSave">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="handleSave">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";

import BackToTop from "vue-backtotop";
// vuex actions
//import { LOAD_ROLES, ROLES_INVALID } from "src/store/actions/admin/roles";
import { SAVE_MALING, MAILINGS_INVALID } from "../../../store/actions/mailings/mailings-list";

// ui components
import { Input } from "element-ui";
import { Tabs, TabPane, Card } from "src/components/UIComponents";

// event bus
import { eventBus } from "../../../main";

import * as priv from "../../../privileges";

export default {
  data() {
    return {
      loading: false,
      editMode: true,
      typeCampaign: null,
      idCampaign: null,
      campaignModel: {
        name: null,
      },
      campaignModelValidations: {
        campaignName: {
          required: true,
          min: 3,
          max: 100,
        },
      },
    };
  },
  components: {
    [Input.name]: Input,
  },
  computed: {
    setTypeCampaign() {
      this.typeCampaign = this.$route.params.typeCampaign;
    },
    getTypeCampaign() {
      return this.typeCampaign;
    },
    setIdCampaign() {
      this.idCampaign = this.$route.params.idCampaign;
    },
    getIdCampaign() {
      return this.idCampaign;
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        if (this.getIdCampaign === null) {
          this.editMode = false;
        } else {
          this.editMode = true;
        }
        this.$store.commit(MAILINGS_INVALID);
      }
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        if (this.idCampaign === null) {
          await this.$store.dispatch(SAVE_MALING, this.campaignModel).catch((error) => {
            eventBus.$emit("notify", {
              message: "Błąd zapisu Kampanii!",
              type: "warning",
            });
          });
          let idCampaign = this.$store.getters.getWorkingModifiedRecords.id;
          if (typeof idCampaign === "number") {
            this.idCampaign = idCampaign;
            eventBus.$emit("notify", {
              message: "Kampania została dodane",
              type: "success",
            });

            this.loading = true;
            await this.loadData(true);
            this.loading = false;
          }
        } else {
          this.campaignModel.id = this.idCampaign;
          await this.$store.dispatch(SAVE_MALING, this.campaignModel).catch((error) => {
            eventBus.$emit("notify", {
              message: "Błąd zapisu Kampanii!",
              type: "warning",
            });
          });
        }
      }
    },
    handleSave() {
      this.saveData();
    },

    getErrors(nameField) {
      return this.errors.first(nameField);
    },
  },
  created() {
    this.loadData(true);
    eventBus.$on("forceReload", () => {
      //this.loadData(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>

<style>
</style>
