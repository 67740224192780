import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_DISPLAY_STATS,
  DISPLAY_STATS_LOADING,
  DISPLAY_STATS_LOADING_SUCCESS,
  DISPLAY_STATS_LOADING_ERROR,
  DISPLAY_STATS_INVALID
} from 'src/store/actions/stats/display_stats'

const storeObjectConfig = {
  objectName: "DisplayStats",
  getterName: "getDisplayStats",
  loadActionName: LOAD_DISPLAY_STATS,
  loadingMutationName: DISPLAY_STATS_LOADING,
  loadingSuccessMutationName: DISPLAY_STATS_LOADING_SUCCESS,
  loadingErrorMutationName: DISPLAY_STATS_LOADING_ERROR,
  invalidMutationName: DISPLAY_STATS_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/stats/stats/display_stats",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
