<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4">
            <label for="project">Projekt</label>
            <el-select v-model="projects" name="project" data-vv-as="Projekt" style="width: 100%" multiple filterable clearable>
              <el-option
                v-for="project in projectDict"
                :label="'[' + project.project_id + '] ' + project.project_name"
                :value="project.project_id"
                :key="project.project_id"
              ></el-option>
            </el-select>
          </div>
          <div class="col-sm-3">
            <label style="display: block; margin-bottom: 7px">Wersja</label>
            <el-select v-model="versions" name="versions" filterable clearable multiple style="width: 100%">
              <el-option-group v-for="group in projectModules" :key="group.label" :label="group.label">
                <el-option
                  v-for="version in group.options"
                  :label="version.module + ' [' + version.tags + ']'"
                  :value="version.module"
                  :key="version.module"
                ></el-option>
              </el-option-group>
            </el-select>
          </div>
          <div class="col-sm-5 right-bottom">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="-"
              start-placeholder="Data początkowa"
              end-placeholder="Data końcowa"
              :picker-options="pickerOptions"
              :clearable="false"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
            ></el-date-picker>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-sm-4 left-bottom">
            <div style="width: 100%">
              <label style="display: block; margin-bottom: 7px">Baner</label>
              <el-select
                style="width: 100%"
                v-model="bannersArray"
                name="banners"
                filterable
                remote
                reserve-keyword
                clearable
                multiple
                :remote-method="remoteSearch"
                :loading="loading"
              >
                <el-option
                  v-for="banner in bannerConfig"
                  :label="'[' + banner.BannerID + '] ' + banner.BannerName"
                  :value="banner.BannerID"
                  :key="banner.BannerID"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="col-sm-3">
            <label style="display: block; margin-bottom: 7px">Kanał</label>
            <el-select v-model="chanels" name="chanel" filterable multiple style="width: 100%">
              <el-option v-for="(chanel, k) in chanelList" :key="k" :label="chanel"
                         :value="chanel"></el-option>
            </el-select>
          </div>
          <div class="col-sm-auto right-bottom">
            <p-button style="margin-bottom: 0" type="success" @click="loadData('show')">Pokaż</p-button>
            <p-button style="margin-bottom: 0" type="success" @click="loadData('excel')">Excel</p-button>
          </div>
        </div>
      </div>
      <hr />
      <h6>
        <div class="card-footer" style="display: flex; justify-content: space-between">
          <span>Przedział dat:</span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </div>
      </h6>
    </div>

    <div class="card">
      <div class="card-body row">
        <el-table
          :data="tableData"
          style="width: 100%"
          :span-method="objectSpanMethod"
          @cell-click="cellClick"
          @cell-dblclick="cellDoubleClick"
          :row-style="rowStyle"
          :header-cell-style="headerStyle"
          size="mini"
        >
          <el-table-column label="ID" property="project_id" width="80" align="center">
            <template v-slot="props">
              <div v-if="props.row.mode === 'project'">
                {{ props.row.project_id }}
              </div>
              <div v-else-if="props.row.mode === 'version'">
                {{ "" }}
              </div>
              <div v-else-if="props.row.mode === 'question'">
                {{ props.row.question_id }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="No" property="priority" width="60" align="center">
            <template slot="header">
              <el-tooltip class="item" effect="light" content="Kolejność wyświetlania pytań" placement="top" :open-delay="500">
                <i class="fa fa-sort-numeric-asc" aria-hidden="true"></i>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <div v-if="props.row.mode === 'project'">
                {{ "" }}
              </div>
              <div v-else-if="props.row.mode === 'version'">
                {{ "" }}
              </div>
              <div v-else-if="props.row.mode === 'question'">
                {{ props.row.order }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Treść" property="project_name" align="left" min-width="200">
            <template v-slot="props">
              <div v-if="props.row.mode === 'project'" style="cursor: pointer; word-break: keep-all">
                {{ props.row.project_name }}
              </div>
              <div v-else-if="props.row.mode === 'version'" style="padding-left: 20px; word-break: keep-all">
                {{ props.row.module }}
              </div>
              <div v-else-if="props.row.mode === 'question'" style="padding-left: 40px; word-break: keep-all">
                {{ props.row.question_content }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="" property="question" align="center" min-width="80">
            <template slot="header"> Pytania </template>
            <template v-slot="props">
              <div style="cursor: pointer" v-if="props.row.mode !== 'question' && !questionBoolObject[props.row.project_id + '_' + props.row.module]">
                <i class="fa fa-plus-square-o fa-lg" aria-hidden="true"></i>
              </div>
              <div style="cursor: pointer" v-if="props.row.mode !== 'question' && questionBoolObject[props.row.project_id + '_' + props.row.module]">
                <i class="fa fa-minus-square-o fa-lg" aria-hidden="true"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Kanał" property="channel" width="100" align="center">
            <template v-slot="props">
              {{ props.row.channel }}
            </template>
          </el-table-column>
          <el-table-column label="Wyśw" property="displayed" align="right" width="100" sortable>
            <template slot="header">
              <el-tooltip class="item" effect="light" placement="top" content="Wyświetlenia" :open-delay="500">
                <span>Wyśw</span>
              </el-tooltip>
            </template>
            <template v-slot="props">
              {{ props.row.displayed | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="Sprz" property="sold_percent" align="right" width="100" sortable :sort-method=test>
            <template slot="header">
              <el-tooltip class="item" effect="light" placement="top" content="Sprzedaże" :open-delay="500">
                <span>Sprz</span>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="formatNumber(props.row.sold)" placement="left" :open-delay="500">
                <span>{{ props.row.sold_percent }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="$ Hot" property="income_hot" align="right" width="100" sortable>
            <template slot="header">
              <el-tooltip class="item" effect="light" placement="top" content="Przychód Hot" :open-delay="500">
                <span>$ Hot</span>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="formatNumber(props.row.income_hot, 2) + ' zł'" placement="left" :open-delay="500">
                <span>{{ formatNumber(props.row.income_hot) + " zł" }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="$ Warm" property="income_warm" align="right" width="100" sortable>
            <template slot="header">
              <el-tooltip class="item" effect="light" placement="top" content="Przychód Warm" :open-delay="500">
                <span>$ Warm</span>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="formatNumber(props.row.income_warm, 2) + ' zł'" placement="left" :open-delay="500">
                <span>{{ formatNumber(props.row.income_warm) + " zł" }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Rent" property="efficiency" align="right" width="100" sortable>
            <template slot="header">
              <el-tooltip class="item" effect="light" placement="top" content="Rentowność (income/display*1000)" :open-delay="500">
                <span>RENT</span>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" placement="left" :content="formatNumber(props.row.efficiency, 2) + ' zł'" :open-delay="500">
                <span>{{ formatNumber(props.row.efficiency) + " zł" }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Status" property="is_active" align="center" width="100">
            <template v-slot="props">
              <el-tag
                v-if="props.row.mode === 'question' && props.row.is_subquestion === 0"
                effect="dark"
                :type="tagType(props.row.is_active)"
                size="small"
                >{{ props.row.is_active }}</el-tag
              >
              <div v-else>-</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <p class="text-muted">
      <i>Sortowanie pytań w projektach po rentowności. Rozwijanie wersji po kliknięciu w nazwę projektu, rozwijanie pytań po kliknięciu plusa.</i>
    </p>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";

import { DatePicker, Select, Option, Tooltip, OptionGroup, Tag } from "element-ui";

import { LOAD_QUESTIONS_EFFICIENCY_PROJECTS, QUESTIONS_EFFICIENCY_PROJECTS_INVALID } from "src/store/actions/secure/questions_efficiency_projects";
import { LOAD_PROJECTS, PROJECTS_INVALID } from "src/store/actions/questions/projects";
import { LOAD_BANNER_CONFIG, BANNER_CONFIG_INVALID } from "src/store/actions/stats/banner_config";
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import {
  LOAD_QUESTIONS_EFFICIENCY_EXCEL,
  QUESTIONS_EFFICIENCY_EXCEL_INVALID
} from "@/store/actions/secure/questions_efficiency_excel";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [OptionGroup.name]: OptionGroup,
    [Tag.name]: Tag,
  },
  data() {
    return {
      projectBoolObject: {},
      questionBoolObject: {},
      referenceCounter: 0,
      counter: 0,
      loading: false,
      projects: [],
      chanels:[],
      versions: [],
      versionProjectObject: {},
      bannersArray: [],
      dateRange: ["", ""],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    test: function(a, b) {
      return this.toFloat(a.sold_percent) - this.toFloat(b.sold_percent)
    },
    toFloat: function(num) {
      return parseFloat(num.replace('%','').replace(',','.'))
    },
    async initLoad(force) {
      this.loading = true;

      // if (force) {
      this.$store.commit(PROJECTS_INVALID);
      this.$store.commit(PROJECT_DICT_INVALID);
      this.$store.commit(PROJECTS_ALL_INVALID);
      // }

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_PROJECTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    async loadData(mode) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        project_list: this.projects,
        banner_list: this.bannersArray,
        versions: this.versions,
        channels:this.chanels
      };

      this.$store.commit(mode === 'excel'?QUESTIONS_EFFICIENCY_EXCEL_INVALID:QUESTIONS_EFFICIENCY_PROJECTS_INVALID);
      this.counter = 0;
      await this.$store.dispatch(mode === 'excel'?LOAD_QUESTIONS_EFFICIENCY_EXCEL:LOAD_QUESTIONS_EFFICIENCY_PROJECTS, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      if (mode === "excel") {
        const tmpName = this.$store.getters.getQuestionsEfficiencyProjectsExcel.tmpname;
        const fileName = this.$store.getters.getQuestionsEfficiencyProjectsExcel.name;

        //console.log(tmpName,fileName);
        this.downloadFile(tmpName, fileName);
      }else {
        this.createBoolObjects();
      }
      this.loading = false;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-contract.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getDetailedDate(date) {
      moment.locale("pl");
      const dateObj = Date.parse(date);
      return moment(dateObj).format("dddd, Do MMMM YYYY");
    },
    formatNumber(number, decimals = 0) {
      let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number).toFixed(decimals).split(".");
      n[0] = n[0]
        .split("")
        .reverse()
        .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
        .reverse()
        .join("");
      let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

      return final;
    },
    cellClick(row, column) {
      let columnName = column.property;
      let pid = row.project_id;
      let ver = row.module;
      if (columnName === "project_name" && row.mode !== "version" && row.mode !== "question") {
        this.projectBoolObject[pid] = !this.projectBoolObject[pid];
        this.counter++;
      }
      if (columnName === "question") {
        this.questionBoolObject[pid + "_" + ver] = !this.questionBoolObject[pid + "_" + ver];
        this.counter++;
      }
    },
    cellDoubleClick(row, column) {
      let columnName = column.property;
      if (columnName === "project_name") {
        for (let key in this.projectBoolObject) {
          this.projectBoolObject[key] = false;
        }
        for (let key in this.questionBoolObject) {
          this.questionBoolObject[key] = false;
        }
        this.counter++;
      } else if (columnName === "question") {
        for (let key in this.questionBoolObject) {
          this.questionBoolObject[key] = false;
        }
        this.counter++;
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (row.mode === "question") {
        if (columnIndex === 0) {
          return {
            rowspan: row.col_span,
            colspan: 1,
          };
        }
        if (columnIndex === 1) {
          return {
            rowspan: row.col_span,
            colspan: 1,
          };
        }
        if (columnIndex === 2) {
          return {
            rowspan: row.col_span,
            colspan: 2,
          };
        } else if (columnIndex === 3) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    // tableRowClassName({row, rowIndex}) {
    //   if (row.mode === 'version') {
    //     return 'table-warning';
    //   } else if (row.mode === 'question') {
    //     return 'table-success'
    //   }
    //   return 'other-row';
    // },
    rowStyle({ row }) {
      if (row.mode === "version") {
        return "background-color: #fffaf0";
      } else if (row.mode === "question") {
        return "background-color: #f0f8ff;";
      }
      return "font-weight: bold;";
    },
    headerStyle() {
      return "font-size: 12px;";
    },
    createBoolObjects() {
      let data = this.efficiencyRaport;
      let projectBoolObject = this.projectBoolObject;
      let questionBoolObject = this.questionBoolObject;
      for (let pid in data) {
        projectBoolObject[pid] = false;
        questionBoolObject[pid + "_ALL_VERSIONS"] = false;
        for (let mod in data[pid].modules) {
          questionBoolObject[pid + "_" + mod] = false;
        }
      }
      this.counter++;
    },
    getEfficiency(income, display) {
      const result = (income / display) * 1000;
      return result;
    },
    getConversion(sales, display) {
      const result = this.formatNumber((sales / display) * 100, 2) + "%";
      return result;
    },
    countOrder(questions) {
      questions = Object.values(questions);
      questions.sort((a, b) => b.priority - a.priority);
      let counter = 1;
      questions.forEach((question) => {
        for (let k in question) {
          if (k !== "priority") {
            question[k].order = counter;
          }
        }
        counter += 1;
      });
      return questions;
    },
    sortQuestions(questions) {
      questions.forEach((question) => {
        let question_revenue = 0;
        let question_display = 0;
        let question_income_hot = 0;
        let question_income_warm = 0;
        for (let k in question) {
          const channel = question[k];
          if (k !== "priority") {
            question_display += channel.displayed;
            question_income_hot += channel.income_hot;
            question_income_warm += channel.income_warm;
          }
        }
        question_revenue = ((question_income_hot + question_income_warm) / question_display) * 1000;
        question.revenue = question_revenue;
      });
      questions.sort((a, b) => b.revenue - a.revenue);
      return questions;
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, { searchQuery }).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    tagType(value) {
      // przypisanie tagów
      switch (value) {
        case "ACTIVE":
          return "success";
        case "INACTIVE":
          return "warning";
        case "ARCHIVE":
          return "danger";
        default:
          return "info";
      }
    },
    clearVersions() {
      this.versions = [];
    },
    removeVersion(project_id) {
      let versions = this.versions;
      let filteredVersions = [];
      for (let i in versions) {
        if (this.versionProjectObject[versions[i]] !== project_id) {
          filteredVersions.push(versions[i]);
        }
      }
      this.versions = filteredVersions;
    },
  },
  computed: {
    chanelList(){

      return ['DISPLAY','INNE','MAILING'];
    },
    tableData() {
      const raport = Object.values(this.efficiencyRaport);
      raport.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      let tableData = [];

      let counter = this.counter;
      let referenceCounter = this.referenceCounter;
      if (counter !== referenceCounter) {
        // licznik wymuszający przeliczenie tabeli
        for (let i in raport) {
          const project = raport[i];

          const project_id = project.project_id;
          const project_name = project.project_name;
          const modules = project.modules;

          tableData.push({
            col_span: 1,
            mode: "project",
            project_id: project_id,
            project_name: project_name,
            module: "ALL_VERSIONS",
            channel: "-",
            question_id: "ALL_QUESTIONS",
            question_content: "",
            priority: "",
            order: "",
            displayed: project.ALL_VERSIONS.ALL_QUESTIONS.displayed,
            sold: project.ALL_VERSIONS.ALL_QUESTIONS.sold,
            sold_percent: this.getConversion(project.ALL_VERSIONS.ALL_QUESTIONS.sold, project.ALL_VERSIONS.ALL_QUESTIONS.displayed),
            income_hot: project.ALL_VERSIONS.ALL_QUESTIONS.income_hot,
            income_warm: project.ALL_VERSIONS.ALL_QUESTIONS.income_warm,
            efficiency: this.getEfficiency(
              project.ALL_VERSIONS.ALL_QUESTIONS.income_hot + project.ALL_VERSIONS.ALL_QUESTIONS.income_warm,
              project.ALL_VERSIONS.ALL_QUESTIONS.displayed,
            ),
          });

          if (this.questionBoolObject[project_id + "_ALL_VERSIONS"]) {
            for (let j in project.ALL_VERSIONS.questions) {
              const question = project.ALL_VERSIONS.questions[j];
              const col_span = Object.values(question).length - 2;

              let first_row = true;

              for (let k in question) {
                const channel = question[k];

                if (k !== "revenue" && k !== "priority") {
                  tableData.push({
                    col_span: first_row ? col_span : 0,
                    mode: "question",
                    project_id: project_id,
                    project_name: project_name,
                    module: "ALL_VERSIONS",
                    channel: channel.channel,
                    question_id: channel.question_id,
                    question_content: channel.question_content,
                    priority: channel.priority,
                    order: channel.order,
                    displayed: channel.displayed,
                    sold: channel.sold,
                    sold_percent: this.getConversion(channel.sold, channel.displayed),
                    income_hot: channel.income_hot,
                    income_warm: channel.income_warm,
                    is_subquestion: channel.is_subquestion,
                    is_active: channel.is_active,
                    efficiency: this.getEfficiency(channel.income_hot + channel.income_warm, channel.displayed),
                  });

                  first_row = false;
                }
              }
            }
          }

          if (this.projectBoolObject[project_id]) {
            for (let module_name in modules) {
              const version = modules[module_name];

              tableData.push({
                col_span: 1,
                mode: "version",
                project_id: project_id,
                project_name: project_name,
                module: module_name,
                channel: "-",
                question_id: "",
                question_content: "",
                priority: "",
                order: "",
                displayed: version.ALL_QUESTIONS.displayed,
                sold: version.ALL_QUESTIONS.sold,
                sold_percent: this.getConversion(version.ALL_QUESTIONS.sold, version.ALL_QUESTIONS.displayed),
                income_hot: version.ALL_QUESTIONS.income_hot,
                income_warm: version.ALL_QUESTIONS.income_warm,
                efficiency: this.getEfficiency(version.ALL_QUESTIONS.income_hot + version.ALL_QUESTIONS.income_warm, version.ALL_QUESTIONS.displayed),
              });

              if (this.questionBoolObject[project_id + "_" + module_name]) {
                for (let j in version.questions) {
                  const question = version.questions[j];
                  const col_span = Object.values(question).length - 2;

                  let first_row = true;

                  for (let k in question) {
                    const channel = question[k];

                    if (k !== "revenue" && k !== "priority") {
                      tableData.push({
                        col_span: first_row ? col_span : 0,
                        mode: "question",
                        project_id: project_id,
                        project_name: project_name,
                        module: "ALL_VERSIONS",
                        channel: channel.channel,
                        question_id: channel.question_id,
                        question_content: channel.question_content,
                        priority: channel.priority,
                        order: channel.order,
                        displayed: channel.displayed,
                        sold: channel.sold,
                        sold_percent: this.getConversion(channel.sold, channel.displayed),
                        income_hot: channel.income_hot,
                        income_warm: channel.income_warm,
                        is_subquestion: channel.is_subquestion,
                        is_active: channel.is_active,
                        efficiency: this.getEfficiency(channel.income_hot + channel.income_warm, channel.displayed),
                      });

                      first_row = false;
                    }
                  }
                }
              }
            }
          }
        }
        referenceCounter += 1;
      }
      return tableData;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    efficiencyRaport() {
      let data = {};
      if (this.$store.state.questions_efficiency_projects.loading_status === "success") {
        data = JSON.parse(JSON.stringify(this.$store.getters.getQuestionsEfficiencyProjects));
        for (let i in data) {
          let project_questions = data[i].ALL_VERSIONS.questions;
          project_questions = this.countOrder(project_questions);
          project_questions = this.sortQuestions(project_questions);
          data[i].ALL_VERSIONS.questions = project_questions;

          for (let j in data[i].modules) {
            let module_questions = data[i].modules[j].questions;
            module_questions = this.countOrder(module_questions);
            module_questions = this.sortQuestions(module_questions);
            data[i].modules[j].questions = module_questions;
          }
        }
      }
      return data;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjects);
      let uniqueProjects = {};

      for (let i in projects) {
        // wybranie unikalnych projektów
        uniqueProjects[projects[i].project_id] = {
          project_id: projects[i].project_id,
          project_name: projects[i].project_name,
        };
      }

      let result = Object.values(uniqueProjects).sort((a, b) => (a.project_id > b.project_id ? 1 : -1)); // sortowanie projektów po nazwie

      return result;
    },
    projectsGetter() {
      return this.$store.getters.getProjectDict;
    },
    projectModules() {
      // wszystkie moduły projektu
      if (Array.isArray(this.projects) && this.projects.length > 0) {
        let projects = this.$store.getters.getProjectsAll;
        let activeProjects = this.projects;
        let filteredData = {};
        let temp = {};
        const sortedData = {};
        for (let i in activeProjects) {
          for (let [key, project] of Object.entries(projects)) {
            // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
            if (project.project_id === activeProjects[i]) {
              temp[project.module] = Object.assign({}, project);
              filteredData[project.project_id] = Object.assign({}, temp);
            }
          }
          temp = {};
        }

        let sourceData = [];

        for (let i in filteredData) {
          // sortowanie danych
          let tempData = filteredData[i];
          let sourceData = {};
          for (let ver of Object.keys(tempData)) {
            // iteracja po nazwach modułów danego projektu
            let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie pierwszej liczby
            filteredData[i][ver].number = res;
          }
        }

        let resultModules = [];

        for (let project_id in filteredData) {
          let projectModules = filteredData[project_id];
          sourceData = Object.values(projectModules);
          sourceData.sort((a, b) => a.number - b.number);
          let result = {};
          for (let i in sourceData) {
            this.versionProjectObject[sourceData[i].module] = parseInt(project_id);
            result[sourceData[i].module] = sourceData[i];
          }
          resultModules.push({
            project_id,
            label: this.projectsGetter[project_id].project_name,
            options: result,
          });
        }

        return resultModules;
      }
      return [];
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
  },
  mounted() {},
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
      this.initLoad(true);
    });
    this.initLoad(false);
  },
  beforeDestroy: function () {
    this.$store.commit(QUESTIONS_EFFICIENCY_PROJECTS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.left-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
</style>

<style></style>
