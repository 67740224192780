<template>
  <div class="login-page">
    <!-- <app-navbar></app-navbar> -->
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <card type="login">
                <h3 slot="header" class="header text-center">Login</h3>

                <fg-input
                  v-model="form.login"
                  addon-left-icon="nc-icon nc-single-02"
                  placeholder="Login"
                  v-on:keyup.enter = 'login'
                ></fg-input>

                <fg-input
                  v-model="form.password"
                  addon-left-icon="nc-icon nc-key-25"
                  placeholder="Hasło"
                  type="password"
                  v-on:keyup.enter = 'login'
                ></fg-input>

                <!-- <br> -->
                <!-- <p-checkbox>
                    Subscribe to newsletter
                </p-checkbox>-->
                <p-button
                  slot="footer"
                  type="warning"
                  round
                  block
                  class="mb-3"
                  @click="login"
                >Zaloguj</p-button>
              </card>
            </div>
          </div>
        </div>
        <!-- <app-footer></app-footer> -->
        <div
          class="full-page-background"
          style="background-image: url(static/img/background/damir-bosnjak.jpg) "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Checkbox, Button } from "src/components/UIComponents";
import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";
import { AUTH_REQUEST } from "src/store/actions/auth";

// event bus
import { eventBus } from "src/main";

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    // [Checkbox.name]: Checkbox,
    [Button.name]: Button
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    login() {
      const { login, password } = this.form;

      this.$store
        .dispatch(AUTH_REQUEST, { login, password })
        .then(() => {
          eventBus.$emit('logged');
          this.$router.push({ name: "Overview" });
          // location.reload(true);
        })
        .catch(error => {
          eventBus.$emit("notify", {
            message: "Niepoprawny login lub hasło!",
            type: "warning"
          });
        });
    }
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },
  beforeDestroy() {
    this.closeMenu();
  }
};
</script>
<style>
</style>
