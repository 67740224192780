<template>
  <div>
    <h4 class="title">Profil użytkownika</h4>
    <div class="card">
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-md-6">
              <fg-input type="text"
                        id="profile_form_first_name"
                        label="Imię"
                        v-model="model.FirstName">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input type="text"
                        id='profile_form_last_name'
                        label="Nazwisko"
                        v-model="model.LastName">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <fg-input type="text"
                        id="profile_form_login"
                        label="Login"
                        :disabled="true"
                        v-model="model.Login">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input type="text"
                        id="profile_form_company"
                        label="Firma"
                        v-model="model.Company">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <fg-input type="email"
                        id="profile_form_email"
                        label="Email"
                        v-model="model.Email">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input type="tel"
                        id="profile_form_phone"
                        label="Numer telefonu"
                        v-model="model.Phone">
              </fg-input>
            </div>
          </div>
          <div class="text-center">
            <button type="submit" id="profile_form_submit_button" class="btn btn-info btn-fill btn-wd" @click.prevent="updateProfile">
              Aktualizuj dane
            </button>
          </div>
          <div class="clearfix"></div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { LOAD_PROFILE, SAVE_PROFILE, PROFILE_INVALID } from 'src/store/actions/admin/profile';

  import { eventBus } from "src/main";

  export default {
    data() {
      return {
        model: {
          FirstName: '',
          LastName: '',
          Login: '',
          Company: '',
          Email: '',
          Phone: ''
        }
      }
    },
    computed: {
      profile() {
        return this.$store.getters.getProfile
      }
    },
    methods: {
      updateProfile () {
        this.$store.dispatch(SAVE_PROFILE, this.model); // zapisz zmiany do bazy

        this.$store.commit(PROFILE_INVALID) // oznacz nieaktualność danych
        // this.$router.push({ name: "Overview" }) // przekieruj do listy
        eventBus.$emit("notify", {
            message: "Dane zmienione pomyślnie",
            type: "success"
          });
      },
      async loadData() {
        await this.$store.dispatch(LOAD_PROFILE, {}).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });
        this.model = {...this.profile};
      }
    },
    created() {
      this.loadData();
    }
  }

</script>
<style>

</style>
