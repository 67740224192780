import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_ANSWER_CATEGORYS,
  ANSWER_CATEGORYS_LOADING,
  ANSWER_CATEGORYS_LOADING_SUCCESS,
  ANSWER_CATEGORYS_LOADING_ERROR,
  ANSWER_CATEGORYS_INVALID,
  SAVE_ANSWER_CATEGORY,
  ANSWER_CATEGORY_SAVING,
  ANSWER_CATEGORY_SAVING_SUCCESS,
  ANSWER_CATEGORY_SAVING_ERROR
} from '../../actions/questionForm/answer_categorys'

const storeObjectConfig = {
  objectName: "Answers",
  getterName: "getAnswerCategorys",
  loadActionName: LOAD_ANSWER_CATEGORYS,
  loadingMutationName: ANSWER_CATEGORYS_LOADING,
  loadingSuccessMutationName: ANSWER_CATEGORYS_LOADING_SUCCESS,
  loadingErrorMutationName: ANSWER_CATEGORYS_LOADING_ERROR,
  invalidMutationName: ANSWER_CATEGORYS_INVALID,
  saveActionName: SAVE_ANSWER_CATEGORY,
  savingMutationName: ANSWER_CATEGORY_SAVING,
  savingSuccessMutationName: ANSWER_CATEGORY_SAVING_SUCCESS,
  savingErrorMutationName: ANSWER_CATEGORY_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/answers/answer_categorys",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/questions/answers/answer_categorys",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
