<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row header_row">
          <div class="col-sm-10">
            <h5 class="title">{{ title }}</h5>
          </div>
          <div>
            <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
          </div>
        </div>
        <div class="row" v-if="mode === 'group'">
          <div class="col-sm-12">
            <label>Nazwa grupy</label>
            <fg-input
              type="text"
              name="group_name"
              data-vv-as="Nazwa grupy"
              :error="getError('Nazwa grupy')"
              min="0"
              v-model="groupModel.group_name"
              v-validate="groupModelValidations.group_name"
            ></fg-input>
          </div>
          <div class="col-sm-12">
            <label>Opis</label>
            <fg-input
              type="text"
              name="description"
              data-vv-as="Opis"
              :error="getError('Opis')"
              min="0"
              v-model="groupModel.description"
              v-validate="groupModelValidations.description"
            ></fg-input>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-sm-12">
            <div class="form-group">
              <label>Pytania</label>
              <fg-input :error="getError('question_ids')" data-vv-as="Pytania">
                <el-select
                  v-model="questionModel.question_ids"
                  name="question_ids"
                  id="QuestionGroupFromQuestionIDsSelect"
                  v-on:visible-change="getSelectWidth()"
                  data-vv-as="Pytania"
                  filterable
                  clearable
                  multiple
                >
                  <el-option
                    :style="selectWidth !== null ? 'width: ' + selectWidth + 'px' : 'width: 300px'"
                    v-for="question in questions"
                    :label="'[' + question.question_id + '] ' + question.question_content"
                    :value="question.question_id"
                    :key="question.question_id"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>
          </div>
        </div>

        <div class="row" style="margin: 0;">
          <div class="center margin-row">
            <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip, Badge } from "element-ui";

// event bus
import { eventBus } from "src/main";

import { SAVE_GROUP, GROUPS_INVALID } from "src/store/actions/questions/groups";
import { SAVE_QUESTION_GROUP, QUESTION_GROUP_INVALID } from "src/store/actions/questions/question_group";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Badge.name]: Badge,
  },
  data() {
    return {
      selectWidth: null,
      groupModel: {
        group_id: null,
        group_name: '',
        description: ''
      },
      questionModel: {
        question_ids: []
      },
      groupModelValidations: {
        group_name: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 1,
          max: 32
        },
        group_name: {
          regex: regexp.TEXT_REGEXP,
          min: 0,
          max: 128
        },
      },
      groupModelCompare: {}
    }
  },
  props: ['mode', 'row', 'activeQuestionIDs'],
  computed: {
    title() {
      let title = '';
      if(this.mode === 'question') {
        title = 'Dodaj pytanie/a do grupy';
      } else {
        if(this.row) {
          title = 'Edytuj grupę';
        } else {
          title = 'Dodaj nową grupę';
        }
      }
      return title;
    },
    questions() {
      let getter = this.$store.getters.getQuestions;
      return Object.values(getter).filter(x => (x.question_type === 'CO-REGISTRATION' || x.question_type === 'QUIZ') && !this.activeQuestionIDs.includes(x.question_id) );
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      if(this.mode === 'group') {
        if(JSON.stringify(this.groupModel) !== JSON.stringify(this.groupModelCompare)) {
          await this.$store.dispatch(SAVE_GROUP, this.groupModel).catch(error => {
            eventBus.$emit("notify", {
              message: "Błąd zapisu!",
              type: "warning"
            });
          });

          if(this.$store.getters.getGroupsModifiedRecords > 0) {
            eventBus.$emit("notify", {
              message: this.row ? 'Rekord został zmodyfikowany' : 'Rekord został dodany',
              type: "success"
            });

            this.$store.commit(GROUPS_INVALID) // oznacz nieaktualność danych
            eventBus.$emit('questionGroupSaved');
            this.$emit('close');
          }
        }
      } else {
        if(this.questionModel.question_ids.length > 0) {
          let model = {
            mode: 'addGroup',
            group_id: this.groupModel.group_id,
            question_ids: this.questionModel.question_ids
          };

          await this.$store.dispatch(SAVE_QUESTION_GROUP, model).catch(error => {
            eventBus.$emit("notify", {
              message: "Błąd zapisu!",
              type: "warning"
            });
          });

          if(this.$store.getters.getQuestionGroupModifiedRecords > 0) {
            eventBus.$emit("notify", {
              message: 'Rekord został dodany',
              type: "success"
            });

            this.$store.commit(QUESTION_GROUP_INVALID) // oznacz nieaktualność danych
            eventBus.$emit('questionGroupSaved');
            this.$emit('close');
          }
        }
      }
    },
    getSelectWidth() {
      this.selectWidth = document.getElementById('QuestionGroupFromQuestionIDsSelect').offsetWidth;
    },
  },
  async created() {
    if(this.row !== null) {
      this.groupModel = this.row;
      this.groupModelCompare = JSON.parse(JSON.stringify(this.groupModel));
    }

  },
};
</script>

<style lang="scss" scoped>
  .card {
    margin: 0;
    display: flex;
  }
  h5 {
    margin: auto 0;
  }
  .center {
    display: flex;
    margin: auto;
    justify-content: center;
    align-content: center;
  }
  .header_row {
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px 0;
  }
</style>
