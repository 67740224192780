import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_USER_GROUP_LIST,
  USER_GROUP_LIST_LOADING,
  USER_GROUP_LIST_LOADING_SUCCESS,
  USER_GROUP_LIST_LOADING_ERROR,
  USER_GROUP_LIST_INVALID,
} from '../../actions/complaints/user_group_list'

const storeObjectConfig = {
  objectName: "UserGroup",
  getterName: "getUserGroupList",
  loadActionName: LOAD_USER_GROUP_LIST,
  loadingMutationName: USER_GROUP_LIST_LOADING,
  loadingSuccessMutationName: USER_GROUP_LIST_LOADING_SUCCESS,
  loadingErrorMutationName: USER_GROUP_LIST_LOADING_ERROR,
  invalidMutationName: USER_GROUP_LIST_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/complaints/userGroup",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
