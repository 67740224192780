import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_CATEGORYS,
  CATEGORYS_LOADING,
  CATEGORYS_LOADING_SUCCESS,
  CATEGORYS_LOADING_ERROR,
  CATEGORYS_INVALID,
  SAVE_CATEGORY,
  CATEGORY_SAVING,
  CATEGORY_SAVING_SUCCESS,
  CATEGORY_SAVING_ERROR
} from '../../actions/questionForm/categorys'

const storeObjectConfig = {
  objectName: "Answers",
  getterName: "getCategorys",
  loadActionName: LOAD_CATEGORYS,
  loadingMutationName: CATEGORYS_LOADING,
  loadingSuccessMutationName: CATEGORYS_LOADING_SUCCESS,
  loadingErrorMutationName: CATEGORYS_LOADING_ERROR,
  invalidMutationName: CATEGORYS_INVALID,
  saveActionName: SAVE_CATEGORY,
  savingMutationName: CATEGORY_SAVING,
  savingSuccessMutationName: CATEGORY_SAVING_SUCCESS,
  savingErrorMutationName: CATEGORY_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/answers/categorys",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/questions/answers/categorys",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
