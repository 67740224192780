export const MAILER_LISTS_LOAD = "MAILER_LISTS_LOAD";
export const MAILER_LISTS_LOADING = "MAILER_LISTS_LOADING";
export const MAILER_LISTS_LOADING_SUCCESS = "MAILER_LISTS_LOADING_SUCCESS";
export const MAILER_LISTS_LOADING_ERROR = "MAILER_LISTS_LOADING_ERROR";
export const MAILER_LISTS_INVALID = "MAILER_LISTS_INVALID";

export const MAILER_LISTS_GETTER_NAME = "MAILER_LISTS_GETTER_NAME";
export const MAILER_LISTS_OB_NAME = "MAILER_LISTS_OB_NAME";
export const MAILER_LISTS_EXCEL_DISPLAY = "MAILER_LISTS_EXCEL_DISPLAY";
export const MAILER_LISTS_EXCEL_DISPLAY_INVALID = "MAILER_LISTS_EXCEL_DISPLAY_INVALID";
