import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_USERS,
  USERS_LOADING,
  USERS_LOADING_SUCCESS,
  USERS_LOADING_ERROR,
  USERS_INVALID,
  SAVE_USER,
  USER_SAVING,
  USER_SAVING_SUCCESS,
  USER_SAVING_ERROR,
  DELETE_USER,
  USER_DELETING,
  USER_DELETING_SUCCESS,
  USER_DELETING_ERROR
} from 'src/store/actions/admin/users'

const storeObjectConfig = {
  objectName: "Users",
  getterName: "getUsers",
  loadActionName: LOAD_USERS,
  loadingMutationName: USERS_LOADING,
  loadingSuccessMutationName: USERS_LOADING_SUCCESS,
  loadingErrorMutationName: USERS_LOADING_ERROR,
  invalidMutationName: USERS_INVALID,
  saveActionName: SAVE_USER,
  savingMutationName: USER_SAVING,
  savingSuccessMutationName: USER_SAVING_SUCCESS,
  savingErrorMutationName: USER_SAVING_ERROR,
  deleteActionName: DELETE_USER,
  deletingMutationName: USER_DELETING,
  deletingSuccessMutationName: USER_DELETING_SUCCESS,
  deletingErrorMutationName: USER_DELETING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/users",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/users",
    method: "put" // {'get','post','put'}
  },
  deleteParams: {
    url: "/admin/users",
    method: "delete" // {'delete'}
  }
}

const extraGetters = {
  getUserLoginById: (state) => (id) => {
    return !!state.data[id] ? state.data[id].user_login : '';
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = Object.assign({}, storeGen.gettersGenerator(storeObjectConfig), extraGetters);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
