<template>
  <div class="user">
<!--     <div class="photo">
      <img src="static/img/faces/face-2.jpg"/>
    </div> -->
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">

         <span>
        <div class="nc-icon nc-single-02"></div>
           {{ userName }}
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <router-link to="/pages/user" tag="li" exact>
              <a>
                <span class="sidebar-mini-icon">P</span>
                <span class="sidebar-normal">Profil</span>
              </a>
            </router-link>
            <router-link to="/pages/password" tag="li" exact>
              <a href="#">
                <span class="sidebar-mini-icon">H</span>
                <span class="sidebar-normal">Zmień hasło</span>
              </a>
            </router-link>
            <router-link to="/logout" tag="li" exact>
              <a href="#">
                <span class="sidebar-mini-icon">W</span>
                <span class="sidebar-normal">Wyloguj</span>
              </a>
            </router-link>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions'
  import { AUTH_LOGOUT } from "src/store/actions/auth"

  export default {
    components: {
      CollapseTransition
    },
    data() {
      return {
        isClosed: true
      }
    },
    computed: {
      userName: function() {
        let profile = this.$store.getters.getProfile;
        return profile.FirstName + ' ' + profile.LastName;
      }
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      },
      logout() {
        this.$store
          .dispatch(AUTH_LOGOUT)
          .then(() => {
            this.$router.push({ name: "Login" });
          })
          .catch(err => console.log(err));
        // todo: obsłużyć błąd
      }
    }
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
