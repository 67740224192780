import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_FILTER_EXCEPTION,
  FILTER_EXCEPTION_LOADING,
  FILTER_EXCEPTION_LOADING_SUCCESS,
  FILTER_EXCEPTION_LOADING_ERROR,
  FILTER_EXCEPTION_INVALID,
  SAVE_FILTER_EXCEPTION,
  FILTER_EXCEPTION_SAVING,
  FILTER_EXCEPTION_SAVING_SUCCESS,
  FILTER_EXCEPTION_SAVING_ERROR,
  DELETE_FILTER_EXCEPTION,
  FILTER_EXCEPTION_DELETING,
  FILTER_EXCEPTION_DELETING_SUCCESS,
  FILTER_EXCEPTION_DELETING_ERROR,
} from "src/store/actions/filter/filter_exception";

const storeObjectConfig = {
  objectName: "FilterAnalyser",
  getterName: "getFilterException",
  loadActionName: LOAD_FILTER_EXCEPTION,
  loadingMutationName: FILTER_EXCEPTION_LOADING,
  loadingSuccessMutationName: FILTER_EXCEPTION_LOADING_SUCCESS,
  loadingErrorMutationName: FILTER_EXCEPTION_LOADING_ERROR,
  invalidMutationName: FILTER_EXCEPTION_INVALID,
  saveActionName: SAVE_FILTER_EXCEPTION,
  savingMutationName: FILTER_EXCEPTION_SAVING,
  savingSuccessMutationName: FILTER_EXCEPTION_SAVING_SUCCESS,
  savingErrorMutationName: FILTER_EXCEPTION_SAVING_ERROR,
  deleteActionName: DELETE_FILTER_EXCEPTION,
  deletingMutationName: FILTER_EXCEPTION_DELETING,
  deletingSuccessMutationName: FILTER_EXCEPTION_DELETING_SUCCESS,
  deletingErrorMutationName: FILTER_EXCEPTION_DELETING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/filter/filterException",
    method: "post", // {'get','post','put'}
  },
  saveParams: {
    url: "/filter/filterException",
    method: "put", // {'get','post','put'}
  },
  deleteParams: {
    url: "/filter/filterException",
    method: "delete", // {'delete'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
