export const LOAD_PBR = "LOAD_PBR";
export const PBR_LOADING = "PBR_LOADING";
export const PBR_LOADING_SUCCESS = "PBR_LOADING_SUCCESS";
export const PBR_LOADING_ERROR = "PBR_LOADING_ERROR";
export const PBR_INVALID = "PBR_INVALID";

export const SAVE_PBR = "SAVE_PBR";
export const PBR_SAVING = "PBR_SAVING";
export const PBR_SAVING_SUCCESS = "PBR_SAVING_SUCCESS";
export const PBR_SAVING_ERROR = "PBR_SAVING_ERROR";

export const DELETE_PBR = "DELETE_PBR";
export const PBR_DELETING = "PBR_DELETING";
export const PBR_DELETING_SUCCESS = "PBR_DELETING_SUCCESS";
export const PBR_DELETING_ERROR = "PBR_DELETING_ERROR";
