<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <h5 class="title">{{ testVar }}</h5>
      </div>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-12">
        <el-table :data="workings" style="width: 100%">
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="name" label="NAZWA"> </el-table-column>
          <el-table-column align="center" v-if="editPermitted" width="80">
            <template slot="header">
              <router-link :to="'/mailings/add-campaign'">
                <p-button type="success" icon round>
                  <i class="fa fa-plus"></i>
                </p-button>
              </router-link>
            </template>
            <template v-slot="props">
              <p-button type="success" size="sm" icon :id="'mailings_edit_button_' + props.$index" @click="handleEdit(props.$index, props.row)">
                <i class="fa fa-edit"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import BackToTop from "vue-backtotop";
// vuex actions
//import { LOAD_ROLES, ROLES_INVALID } from "src/store/actions/admin/roles";
import { LOAD_MAILINGS, GET_WORKING, MAILINGS_INVALID } from "../../../store/actions/mailings/mailings-list";

// ui components
import { Table, TableColumn, Select, Option, Switch, Tag, Scrollbar, Badge, Tooltip, Radio, RadioGroup, RadioButton, Checkbox } from "element-ui";
import { Tabs, TabPane, Card } from "src/components/UIComponents";
import PPagination from "src/components/UIComponents/Pagination.vue";

// event bus
import { eventBus } from "../../../main";

import * as priv from "../../../privileges";

Vue.use(Table);
Vue.use(TableColumn);

export default {
  data() {
    return {
      testVar: "Kampanie robocze",
      loading: false,
    };
  },
  components: {
    [Tag.name]: Tag,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [Scrollbar.name]: Scrollbar,
    [Badge.name]: Badge,
    [Tooltip.name]: Tooltip,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    [Checkbox.name]: Checkbox,
    PPagination,
    TabPane,
    Tabs,
    Card,
    BackToTop,
  },
  computed: {
    workings() {
      return this.$store.getters.getWorking;
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_MAILINGS_SEND);
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        console.log("force");
        this.$store.commit(MAILINGS_INVALID);
      }
      this.loading = true;
      await this.$store.dispatch(GET_WORKING, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    handleEdit(index, row) {
      this.$router.push("/mailings/add-campaign/" + row.id);
    },
  },
  mounted() {},
  created() {
    console.log("start");
    this.loadData(true);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>

<style></style>
