export const MAILING_EFFINCIENCY_CONST_LOAD = "MAILING_EFFINCIENCY_CONST_LOAD";
export const MAILING_EFFINCIENCY_CONST_LOADING = "MAILING_EFFINCIENCY_CONST_LOADING";
export const MAILING_EFFINCIENCY_CONST_LOADING_SUCCESS = "MAILING_EFFINCIENCY_CONST_LOADING_SUCCESS";
export const MAILING_EFFINCIENCY_CONST_LOADING_ERROR = "MAILING_EFFINCIENCY_CONST_LOADING_ERROR";
export const MAILING_EFFINCIENCY_CONST_INVALID = "MAILING_EFFINCIENCY_CONST_INVALID";

export const MAILING_EFFINCIENCY_CONST_GETTER_NAME = "MAILING_EFFINCIENCY_CONST_GETTER_NAME";
export const MAILING_EFFINCIENCY_CONST_OB_NAME = "MAILING_EFFINCIENCY_CONST_OB_NAME";
export const MAILING_EFFINCIENCY_CONST_EXCEL_DISPLAY = "MAILING_EFFINCIENCY_CONST_EXCEL_DISPLAY";
export const MAILING_EFFINCIENCY_CONST_EXCEL_DISPLAY_INVALID = "MAILING_EFFINCIENCY_CONST_EXCEL_DISPLAY_INVALID";
