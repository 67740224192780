import store from './store' // Vuex
import { eventBus } from "src/main";

import { LOAD_ECHO } from "src/store/actions/echo";
import { ECHO_INVALID } from "src/store/actions/echo";

export default async function initEcho() {
  await store.dispatch(LOAD_ECHO, {}).catch(error => {
    console.log(error);
    eventBus.$emit('logout');
  });

  store.commit(ECHO_INVALID);
}
