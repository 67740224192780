<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">Utwórz reklamację</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Firma</label>
            <el-select v-model="model.company_name" name="company_name" filterable clearable>
              <el-option v-for="company in companies" :label="company.name" :value="company.name" :key="company.name"></el-option>
            </el-select>
          </div>

          <div class="form-group">
            <label>Wpisz numer telefonu</label>
            <fg-input
              type="text"
              name="from_msisdn"
              data-vv-as="Telefon"
              v-validate="modelValidations.from_msisdn"
              :error="getError('from_msisdn')"
              v-model="model.from_msisdn"
            >
            </fg-input>
          </div>
          <div class="form-group">
            <label>lub adres email</label>
            <fg-input
              type="text"
              name="from_email"
              data-vv-as="Email"
              v-validate="modelValidations.from_email"
              :error="getError('from_email')"
              v-model="model.from_email"
            >
            </fg-input>
          </div>

          <div class="form-group">
            <label>Treść</label>
            <el-input type="textarea" name="comment" v-model="model.comment"></el-input>
          </div>

          <div class="row">
            <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip } from "element-ui";

import { SAVE_CREATE_COMPLAINT } from "src/store/actions/complaints/actions/create_complaint";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },
  props: ["companies"],
  data() {
    return {
      model: {
        system_id: 6,
        company_name: "",
        from_email: "",
        from_msisdn: "",
        system_user_id: this.$store.state.profile.data.ID,
        status_name: "NEW",
        comment: "",
      },
      modelValidations: {
        from_msisdn: {
          numeric: true,
          min: 9,
          max: 11,
        },
        from_email: {
          regex: "^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$",
          min: 1,
          max: 128,
        },
      },
    };
  },
  computed: {},
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        if (this.model.company_name === "") {
          eventBus.$emit("notify", {
            message: "Należy wybrać firmę",
            type: "warning",
          });
          return;
        }
        if (this.model.from_msisdn === "" && this.model.from_email === "") {
          eventBus.$emit("notify", {
            message: "Należy podać telefon lub email",
            type: "warning",
          });
          return;
        }
        if (this.model.comment === "") {
          eventBus.$emit("notify", {
            message: "Treść reklamacji nie może być pusta",
            type: "warning",
          });
          return;
        }

        this.model.from_msisdn.length === 9 ? (this.model.from_msisdn = "48" + this.model.from_msisdn) : this.model.from_msisdn;

        if (this.model.from_msisdn !== "" && this.model.from_msisdn.length !== 11) {
          eventBus.$emit("notify", {
            message: "Nieprawidłowy numer telefonu",
            type: "warning",
          });
          return;
        }

        await this.$store.dispatch(SAVE_CREATE_COMPLAINT, this.model).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getCreateComplaintModifiedRecords.complaint_id) {
          eventBus.$emit("notify", {
            message: "Reklamacja została dodana",
            type: "success",
          });
          eventBus.$emit("createComplaint");
          this.$emit("close");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}
</style>
