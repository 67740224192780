<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h6 class="title">{{ 'Sprzedaż dla banera "' + bannerName + '" [' + bannerID + ']' }}</h6>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;  opacity: 0.5;" aria-hidden="true"></i>
        </div>
      </div>

      <div class="card-body">
        <el-table :data="bannerSales" style="width: 100%" :cell-style="colStyle" :header-cell-style="headerStyle">
          <el-table-column label="Klient" min-width="130" property="ClientID" sortable>
            <template v-slot="props">
              {{ '[' + props.row.ClientID + '] ' + props.row.ClientName }}
            </template>
          </el-table-column>
          <el-table-column label="Leady" width="100" property="Leads" sortable align="right">
            <template v-slot="props">
              {{ props.row.Leads | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="Przychód" width="130" property="Income" sortable align="right">
            <template v-slot="props">
              {{ props.row.Income | formatCurrency }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

// vuex actions
import { LOAD_BANNER_SALES, BANNER_SALES_INVALID } from "src/store/actions/secure/banner_sales";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
  },
  props: ['bannerID', 'bannerName', 'dateRange'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    bannerSales() {
      if(this.$store.state.banner_sales.loading_status === 'success') {
        return this.$store.getters.getBannerSales;
      } else {
        return [];
      }
    }
  },
  methods: {
    async loadData() {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning"
        });
        return;
      }

      this.loading = true;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        banner_id: this.bannerID
      };

      this.$store.commit(BANNER_SALES_INVALID);

      await this.$store
        .dispatch(LOAD_BANNER_SALES, filters)
        .catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });

      this.loading = false;
    },
    colStyle() {
      return 'font-size: 12px;';
    },
    headerStyle() {
      return "font-size: 12px;";
    },
  },
  async created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
  .row {
    margin: 10px;
  }
  .card {
    margin: 0;
    display: flex;
  }
  .row:last-child {
    justify-content: center;
  }
  .center {
    justify-content: center;
    align-content: center;
  }
  .header_row {
    display: flex;
    justify-content: space-between;
  }
</style>
