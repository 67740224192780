import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_CLIENTS,
  CLIENTS_LOADING,
  CLIENTS_LOADING_SUCCESS,
  CLIENTS_LOADING_ERROR,
  CLIENTS_INVALID,
  SAVE_CLIENT,
  CLIENT_SAVING,
  CLIENT_SAVING_SUCCESS,
  CLIENT_SAVING_ERROR
} from '../../actions/questionForm/clients'

const storeObjectConfig = {
  objectName: "Questions",
  getterName: "getClients",
  loadActionName: LOAD_CLIENTS,
  loadingMutationName: CLIENTS_LOADING,
  loadingSuccessMutationName: CLIENTS_LOADING_SUCCESS,
  loadingErrorMutationName: CLIENTS_LOADING_ERROR,
  invalidMutationName: CLIENTS_INVALID,
  saveActionName: SAVE_CLIENT,
  savingMutationName: CLIENT_SAVING,
  savingSuccessMutationName: CLIENT_SAVING_SUCCESS,
  savingErrorMutationName: CLIENT_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/questionForm/clients",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/questions/questionForm/clients",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
