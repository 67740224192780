<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-11">
        <h5 class="title">Nagrody</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="tableData" :default-sort="{ prop: 'id', order: 'descending' }">
              <el-table-column type="expand">
                <template v-slot="props">
                  <p>Nazwa: {{ props.row.name }}</p>
                  <p>Pełna nazwa: {{ props.row.full_name }}</p>
                  <p>Opis: {{ props.row.description }}</p>
                  <p>Cena: {{ props.row.price_str ? props.row.price_str : props.row.price }}</p>
                  <p>Obrazek: {{ props.row.img_name }}</p>
                  <p>Opis losowania: {{ props.row.description_losowania }}</p>
                </template>
              </el-table-column>
              <el-table-column label="ID" property="id" min-width="80" sortable></el-table-column>
              <el-table-column label="Nazwa" property="short_name" min-width="120" sortable>
                <template v-slot="props">
                  {{ props.row.short_name ? props.row.short_name : props.row.name }}
                </template>
              </el-table-column>
              <el-table-column label="ID odpowiedzi" property="answer_id" align="center" sortable></el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="80">
                <template slot="header">
                  <router-link to="/questions/prize_form">
                    <p-button type="success" icon round>
                      <i class="fa fa-plus"></i>
                    </p-button>
                  </router-link>
                </template>
                <template v-slot="props">
                  <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                    <i class="fa fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

// ui components
import { Table, TableColumn } from "element-ui";

// vuex actions
import { LOAD_PRIZES, PRIZES_INVALID } from "src/store/actions/questions/prizes";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";

Vue.use(Table);
Vue.use(TableColumn);

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    answersData() {
      let getter = this.$store.getters.getPrizes;
      return getter.answer;
    },
    prizeData() {
      let getter = this.$store.getters.getPrizes;
      return getter.prize;
    },
    tableData() {
      let data = [];
      if (this.prizeData) {
        data = Object.values(this.$store.getters.getPrizes.prize);
      }
      return data;
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_PRIZES);
    },
  },
  methods: {
    async loadData(force) {
      const filters = {};

      if (force) {
        this.$store.commit(PRIZES_INVALID);
      }

      this.loading = true;

      await this.$store.dispatch(LOAD_PRIZES, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    handleEdit(index, row) {
      this.$router.push("/questions/prize_form/" + row.id);
    },
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
  },
};
</script>

<style></style>
