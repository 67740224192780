import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_ROLE_PRIVS,
  ROLE_PRIVS_LOADING,
  ROLE_PRIVS_LOADING_SUCCESS,
  ROLE_PRIVS_LOADING_ERROR,
  ROLE_PRIVS_INVALID,
  SAVE_ROLE_PRIV,
  ROLE_PRIV_SAVING,
  ROLE_PRIV_SAVING_SUCCESS,
  ROLE_PRIV_SAVING_ERROR
} from 'src/store/actions/admin/role_privs'

const storeObjectConfig = {
  objectName: "RolePrivs",
  getterName: "getRolePrivs",
  loadActionName: LOAD_ROLE_PRIVS,
  loadingMutationName: ROLE_PRIVS_LOADING,
  loadingSuccessMutationName: ROLE_PRIVS_LOADING_SUCCESS,
  loadingErrorMutationName: ROLE_PRIVS_LOADING_ERROR,
  invalidMutationName: ROLE_PRIVS_INVALID,
  saveActionName: SAVE_ROLE_PRIV,
  savingMutationName: ROLE_PRIV_SAVING,
  savingSuccessMutationName: ROLE_PRIV_SAVING_SUCCESS,
  savingErrorMutationName: ROLE_PRIV_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/rolePrivs",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/rolePrivs",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
