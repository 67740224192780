<template>
  <div>
    <div class="row">
      <div class="col-sm-11">
        <h5 class="title">Systemy</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="tableData" :default-sort = "{prop: 'system_add_time', order: 'ascending'}">
              <el-table-column type="expand">
                <template v-slot="props">
                  <p>Utworzony: {{ props.row.system_add_time }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Nazwa" property="system_name" min-width="120" sortable></el-table-column>
              <el-table-column label="Opis" property="system_description" align="center" sortable></el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="80">
                <template slot="header">
                  <router-link to="/admin/system_form">
                    <p-button type="success" icon round>
                      <i class="fa fa-plus"></i>
                    </p-button>
                  </router-link>
                </template>
                <template v-slot="props">
                  <p-button
                    type="success"
                    size="sm"
                    icon
                    @click="handleEdit(props.$index, props.row)"
                  >
                    <i class="fa fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

// ui components
import { Table, TableColumn } from "element-ui";

// vuex actions
import { LOAD_SYSTEMS, SYSTEMS_INVALID } from "src/store/actions/admin/systems";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';

Vue.use(Table);
Vue.use(TableColumn);

export default {
  computed: {
    tableData() {
      // table data needs to by an array
      return Object.values(this.$store.getters.getSystems);
    },
      editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN)
    }
  },
  methods: {
    loadData(force) {
      const filters = {}

      if (force) {
        this.$store.commit(SYSTEMS_INVALID);
      }

      this.$store.dispatch(LOAD_SYSTEMS, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    handleEdit(index, row) {
      this.$router.push("/admin/system_form/" + row.system_id);
    }
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style>
</style>
