<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-12">
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o pull-right" style="cursor: pointer;  opacity: 0.5;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <el-table :data="sellClients" style="width: 100%" :cell-style="colStyle" :header-cell-style="headerStyle">
            <el-table-column label="Klient" min-width="130" property="klient" sortable>
              <template v-slot="props">
                {{ '[' + props.row.klient + '] ' + props.row.nazwa }}
              </template>
            </el-table-column>
            <el-table-column label="Sprzedane" min-width="130" property="AmountSell" sortable>
            </el-table-column>
            <el-table-column label="Przychód" min-width="130" property="income" sortable>
            </el-table-column>
          </el-table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: ['sellClients'],
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {
    colStyle() {
      return 'font-size: 12px;';
    },
    headerStyle() {
      return "font-size: 12px;";
    },
  },
};
</script>

<style lang="scss" scoped>
  .row {
    margin: 10px;
  }
  .card {
    margin: 0;
    display: flex;
  }
  .row:last-child {
    justify-content: center;
  }
  .center {
    justify-content: center;
    align-content: center;
  }
  .header_row {
    display: flex;
    justify-content: space-between;
  }
</style>
