<template>
  <div class="container">
    <div class="card">
      <div class="card-body" style="padding-right: 3px">
        <ul id="outer_ul">
          <li v-for="company in data" :key="'company_' + company.id" @click="$emit('changeCompany', company.id)" class="company-list-item">
            <div class="company">
              <div>
                <i
                  :class="activeCompany === company.id ? 'fa fa-chevron-right company-arrow' : 'fa fa-chevron-right company-arrow-rotate'"
                  aria-hidden="true"
                ></i>
                {{ company.name }}
              </div>
              <div class="counter" v-if="company.count && company.count > 0">
                <el-tag type="warning" effect="dark" size="small" style="margin-bottom: 0">{{ company.count }}</el-tag>
              </div>
            </div>
            <div class="slide-fade">
              <ul id="inner_ul">
                <li
                  v-for="mailbox in company.mailboxes"
                  :key="'mailbox_' + mailbox.id"
                  @click="$emit('changeMailbox', mailbox.id)"
                  :class="activeCompany === company.id ? 'nested active' : 'nested'"
                >
                  <div :class="activeMailbox == mailbox.id ? 'mailbox mailbox-active' : 'mailbox'">
                    <div class="contextType">
                      <i :class="getIcon(mailbox.type)" aria-hidden="true"></i>
                    </div>
                    <div>{{ prepareName(mailbox.name) }}</div>
                    <div class="counter" v-if="mailbox.count && mailbox.count > 0">
                      <el-tag type="info" effect="dark" size="small" style="margin-bottom: 0">{{ mailbox.count }}</el-tag>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Tag } from "element-ui";

export default {
  components: {
    [Tag.name]: Tag,
  },
  props: {
    activeCompany: {
      type: Number,
      required: true,
    },
    activeMailbox: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    changeCompany(id) {
      this.$emit("changeCompany", id);
    },
    changeMailbox(id) {
      this.$emit("changeMailbox", id);
    },
    prepareName(name) {
      return name.split("@")[0];
    },
    getIcon(type) {
      switch (type) {
        case "mail":
          return "fa fa-envelope-o";
        case "manual":
          return "fa fa-pencil-square-o";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
}

ul {
  list-style-type: none;
}

#outer_ul {
  margin: 0;
  padding: 0;
}

#inner_ul {
  padding-left: 15px;
}

.company-list-item {
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.company-arrow {
  transition: 0.1s ease-in;
  margin-right: 10px;
}

.company-arrow-rotate {
  margin-right: 10px;
  transform: rotate(90deg);
  transition: 0.1s ease-in;
}

li.nested {
  list-style: none;
  height: 0;
  line-height: 1.5em;
  margin: 0;
  overflow: hidden;
  font-size: 0.9em;
  font-weight: normal;
}

li.active {
  height: 2em;
  margin: 2px 0;
}

.slide-fade li {
  transition: all 0.4s ease-out;
  opacity: 0;
}
.slide-fade li.active {
  opacity: 1;
}

.company {
  display: flex;
  justify-content: space-between;
}

.mailbox {
  cursor: pointer;
  padding: 2px 5px 2px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mailbox-active {
  background-color: rgb(241, 251, 251);
}

.counter {
  margin-left: 10px;
}

.contextType {
  position: absolute;
  left: 15px;
}
</style>
