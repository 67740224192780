<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="title">Wyświetlenia pytań</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          align="center"
          unlink-panels
          range-separator="-"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd HH:mm"
        ></el-date-picker>
      </div>
    </div>

    <div class="card">
      <form>
        <div class="card-body">
          <el-collapse>
            <el-collapse-item>
              <template v-slot:title>
                <strong>FILTRY</strong>
              </template>

              <fieldset>
                <div class="form-group column-sizing">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Typ pytania</label>
                      <fg-input>
                        <el-select v-model="filter.question_types" name="question_types" filterable clearable multiple>
                          <el-option v-for="type in questionTypeDict" :label="type" :value="type"
                                     :key="type"></el-option>
                        </el-select>
                      </fg-input>
                    </div>
                    <div class="col-md-6">
                      <label>Pytanie</label>
                      <fg-input>
                        <el-select
                          id="displayStatsQuestionSelect"
                          v-on:visible-change="getQuestionSelectWidth()"
                          v-model="filter.questions"
                          name="questions"
                          filterable
                          clearable
                          multiple
                        >
                          <el-option
                            :style="questionSelectWidth !== null ? 'width: ' + questionSelectWidth + 'px' : 'width: 300px'"
                            v-for="question in question_list"
                            :label="'[' + question.id + '] ' + question.question_content"
                            :value="question.id"
                            :key="question.id"
                          ></el-option>
                        </el-select>
                      </fg-input>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <div class="form-group column-sizing">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Projekt</label>
                      <fg-input>
                        <el-select
                          v-on:clear="clearVersions()"
                          v-on:remove-tag="removeVersion($event)"
                          v-model="filter.projects"
                          name="projects"
                          filterable
                          clearable
                          multiple
                        >
                          <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                            <el-option
                              v-for="item in group.options"
                              :key="item.project_id"
                              :label="item.project_name + ' [' + item.project_id + ']'"
                              :value="item.project_id"
                            >
                            </el-option>
                          </el-option-group>
                        </el-select>
                      </fg-input>
                    </div>
                    <div class="col-md-4">
                      <label>Wersja</label>
                      <fg-input>
                        <el-select v-model="filter.versions" name="versions" filterable clearable multiple>
                          <el-option-group v-for="group in projectModules" :key="group.label" :label="group.label">
                            <el-option
                              v-for="version in group.options"
                              :label="version.module + ' [' + version.tags + ']'"
                              :value="version.module"
                              :key="version.module"
                            ></el-option>
                          </el-option-group>
                        </el-select>
                      </fg-input>
                    </div>
                    <div class="col-md-4">
                      <label>Grupa wersji</label>
                      <fg-input>
                        <el-select v-model="filter.project_types" name="project_types" filterable clearable multiple>
                          <el-option
                            v-for="type in groupProjectName"
                            :label="type.group_name"
                            :value="type.group_id"
                            :key="type.group_id + '_' + type.group_name"
                          ></el-option>
                        </el-select>
                      </fg-input>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <div class="form-group column-sizing">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Banner</label>
                      <fg-input>
                        <el-select
                          v-model="filter.banners"
                          name="banners"
                          filterable
                          remote
                          reserve-keyword
                          clearable
                          multiple
                          :remote-method="remoteSearch"
                          :loading="loading"
                        >
                          <el-option
                            v-for="banner in bannerConfig"
                            :label="'[' + banner.BannerID + '] ' + banner.BannerName"
                            :value="banner.BannerID"
                            :key="banner.BannerID"
                          ></el-option>
                        </el-select>
                      </fg-input>
                    </div>
                    <div class="col-md-4">
                      <label>Wydawca</label>
                      <fg-input>
                        <el-select v-model="filter.media_house" name="media_house" filterable clearable multiple>
                          <el-option v-for="item in mediaHouse" :label="'[' + item.ID + '] ' + item.Name"
                                     :value="item.ID" :key="item.ID"></el-option>
                        </el-select>
                      </fg-input>
                    </div>
                    <div class="col-md-4">
                      <label>Klient</label>
                      <fg-input>
                        <el-select v-model="filter.clients" name="clients" filterable clearable multiple>
                          <el-option
                            v-for="client in clients"
                            :label="'[' + client.client_id + '] ' + client.client_name"
                            :value="client.client_id"
                            :key="client.client_id"
                          ></el-option>
                        </el-select>
                      </fg-input>
                    </div>
                  </div>
                </div>
              </fieldset>
            </el-collapse-item>
          </el-collapse>
        </div>

        <div class="card-footer text-center">
          <div style="padding-right: 5px; display: inline-block">
            <p-button type="success" @click.prevent="loadStats(true)">Wczytaj</p-button>
          </div>
          <div style="padding-left: 5px; display: inline-block">
            <p-button type="success" @click.prevent="exportStats()">Excel</p-button>
          </div>
        </div>
      </form>
    </div>

    <div class="card">
      <div class="card-body row">
        <div class="col-sm-3">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="col-sm-3 text-sm-right">
          <p-button type="info" size="sm" :disabled="!showButton" @click="showDetails()">Szczegóły</p-button>
        </div>
        <div class="col-sm-3">
          <p-button type="info" size="sm" :disabled="!showButton" @click="showCharts()">Wykresy</p-button>
        </div>
        <div class="col-sm-3">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Szukaj" v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split"></fg-input>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <el-table
          :data="queriedData"
          style="width: 100%"
          @header-click="headerClick"
          @sort-change="sortChange"
          @cell-click="cellClick"
          @cell-dblclick="cellDoubleClick"
          :header-cell-style="headerStyle"
          size="mini"
        >
          <el-table-column label="Check" property="check" width="70" align="center">
            <template v-slot="header">
              <i class="fa fa-check-square-o" aria-hidden="true"></i>
            </template>
            <template v-slot="props">
              <p-checkbox @input="rowChecked($event, props.row.question_id)"
                          v-model="rowCheck[props.row.question_id]"></p-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="ID" property="question_id" width="100" align="center"
                           sortable="custom"></el-table-column>
          <el-table-column label="Typ" property="question_type" width="120" align="center"
                           sortable="custom"></el-table-column>
          <el-table-column label="Treść" property="question_content" align="left" min-width="200" sortable="custom">
            <template v-slot="props">
              <div style="cursor: pointer; word-break: keep-all">{{ props.row.question_content }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Wyświetlenia" property="DisplayCounterUU" align="right" min-width="100"
                           sortable="custom">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="props.row.DisplayCounter.toString()" placement="top"
                          :open-delay="500">
                <span>{{ props.row.DisplayCounterUU }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Odpowiedzi" property="AnswerCounterUU" align="right" min-width="100"
                           sortable="custom">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="props.row.AnswerCounter.toString()" placement="top"
                          :open-delay="500">
                <span>{{ props.row.AnswerCounterUU }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Odpowiedzi [%]" property="AnswerPercentage" align="right" min-width="100"
                           sortable="custom"></el-table-column>
          <el-table-column label="Odp sprz [%]" property="ClientPercentage" align="right" min-width="100"
                           sortable="custom"></el-table-column>
          <el-table-column label="Sprz Real [%]" property="questionSoldPercentage" align="right" min-width="100"
                           sortable="custom"></el-table-column>
          <el-table-column label="Status" property="is_active" align="center" width="110" sortable="custom">
            <template v-slot="props">
              <el-tag v-if="props.row.subquestion === 0" effect="dark" :type="tagType(props.row.is_active)"
                      size="small">{{
                  props.row.is_active
                }}
              </el-tag>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column align="center" width="80">
            <template v-slot="props">
              <p-button type="info" size="sm" icon :id="'display_stats_chart_button_' + props.$index"
                        @click="handleChart('single', props.row)">
                <i class="fa fa-bar-chart" aria-hidden="true"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body row">
        <div class="col-sm-6 pagination-info">
          <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
                        :total="pagination.total"></p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

import {
  Table,
  TableColumn,
  DatePicker,
  Select,
  Option,
  OptionGroup,
  Collapse,
  CollapseItem,
  Tooltip,
  Tag
} from "element-ui";

// ui components
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions
import {LOAD_DISPLAY_STATS, DISPLAY_STATS_INVALID} from "src/store/actions/stats/display_stats";
import {LOAD_EXPORT_DISPLAY_STATS, EXPORT_DISPLAY_STATS_INVALID} from "src/store/actions/stats/export_display_stats";
import {LOAD_QUESTION_ANSWER_STATS, QUESTION_ANSWER_STATS_INVALID} from "src/store/actions/stats/question_answer_stats";
import {LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID} from "src/store/actions/questions/projects_all";
import {LOAD_PROJECT_DICT, PROJECT_DICT_INVALID} from "src/store/actions/questions/project_dict";
import {LOAD_BANNER_CONFIG, BANNER_CONFIG_INVALID} from "src/store/actions/stats/banner_config";
import {LOAD_GROUP_PROJECT_NAME} from "src/store/actions/stats/group_project_name";
import {LOAD_MEDIA_HOUSE} from "src/store/actions/stats/media_house";
import {LOAD_QUESTION_LIST, QUESTION_LIST_INVALID} from "src/store/actions/questions/question_list";
//import { LOAD_DISPLAY_STATS_CHART, DISPLAY_STATS_CHART_INVALID } from "src/store/actions/stats/display_stats_chart";
import {LOAD_CLIENTS} from "src/store/actions/questionForm/clients";

import DisplayStatsModal from "src/components/Utils4/stats/DisplayStatsModal.vue";
import DisplayStatsChartModal from "src/components/Utils4/stats/DisplayStatsChartModal.vue";

// event bus
import {eventBus} from "src/main";
import utils from "src/utils";


export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [CollapseItem.name]: CollapseItem,
    [Collapse.name]: Collapse,
    [Tooltip.name]: Tooltip,
    [Tag.name]: Tag,
    PPagination,
  },
  data() {
    return {
      loading: false,
      filter: {
        question_types: [],
        questions: [],
        projects: [],
        versions: [],
        project_types: [],
        banners: [],
        media_house: [],
        clients: [],
      },
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      rowCheck: {},
      checkedRows: {},
      checkAll: false,
      showButton: false,
      sort: {
        prop: "priority",
        order: "descending",
      },
      searchQuery: "",
      propsToSearch: ["question_id", "question_content", "question_type"],
      answerBoolObject: {},
      referenceCounter: 0,
      counter: 0,
      questionTypeDict: ["CO-REGISTRATION", "DOUBLEOPT-IN", "PROFILE", "QUIZ"],
      projectTypeDict: ["FOLLOWUP", "LITE", "MULTIPAGE", "RECOMMEND", "REKO", "ROTATE"],
      versionProjectObject: {},
      dateRange: [moment(utils.today()).format("YYYY-MM-DD 00:00:00"), moment(utils.today()).format("YYYY-MM-DD 23:59:59")],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      questionSelectWidth: null,
    };
  },
  computed: {
    display_stats() {
      return this.$store.getters.getDisplayStats;
    },
    answer_stats() {
      return this.$store.getters.getQuestionAnswerStats;
    },
    question_list() {
      let data = Object.values(this.$store.getters.getQuestionList);
      data.sort((a, b) => b.id - a.id);
      return data;
    },
    projects() {
      return this.$store.getters.getProjectDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    projectModules() {
      // wszystkie moduły projektu
      if (Array.isArray(this.filter.projects) && this.filter.projects.length > 0) {
        let projects = this.$store.getters.getProjectsAll;
        let activeProjects = this.filter.projects;
        let filteredData = {};
        let temp = {};
        for (let i in activeProjects) {
          for (let [key, project] of Object.entries(projects)) {
            // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
            if (project.project_id === activeProjects[i]) {
              temp[project.module] = Object.assign({}, project);
              filteredData[project.project_id] = Object.assign({}, temp);
            }
          }
          temp = {};
        }

        let sourceData = [];

        for (let i in filteredData) {
          // sortowanie danych
          let tempData = filteredData[i];
          for (let ver of Object.keys(tempData)) {
            // iteracja po nazwach modułów danego projektu
            // odnajdywanie pierwszej liczby
            filteredData[i][ver].number = ver.match("[0-9]+.[0-9]+")[0];
          }
        }

        let resultModules = [];

        for (let project_id in filteredData) {
          let projectModules = filteredData[project_id];
          sourceData = Object.values(projectModules);
          sourceData.sort((a, b) => a.number - b.number);
          let result = {};
          for (let i in sourceData) {
            this.versionProjectObject[sourceData[i].module] = parseInt(project_id);
            result[sourceData[i].module] = sourceData[i];
          }
          resultModules.push({
            project_id,
            label: this.projects[project_id].project_name,
            options: result,
          });
        }

        return resultModules;
      }
      return [];
    },
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
    groupProjectName() {
      let data = Object.values(this.$store.getters.getGroupProjectName);
      data.sort((a, b) => (a.group_name > b.group_name ? 1 : -1));
      return data;
    },
    mediaHouse() {
      let data = Object.values(this.$store.getters.getMediaHouse);
      data.sort((a, b) => b.ID - a.ID);
      return data;
    },
    clients() {
      let data = Object.values(this.$store.getters.getClients);
      data.sort((a, b) => b.client_id - a.client_id);
      return data;
    },
    tableData() {
      let display_stats = this.display_stats;
      let answer_stats = this.answer_stats;
      let tableData = [];
      let counter = this.counter;
      let referenceCounter = this.referenceCounter;
      if (counter !== referenceCounter) {
        // licznik wymuszający przeliczenie tabeli
        for (let qid in display_stats) {
          // iteracja wyświetleń  na poziomie pytania
          // this.rowCheck[qid] = false;

          tableData.push({
            // dodanie poziomu pytania
            question_id: qid,
            question_type: display_stats[qid].question_type,
            question_content: display_stats[qid].question_content,
            subquestion: display_stats[qid].subquestion,
            is_active: display_stats[qid].is_active,
            priority: display_stats[qid].priority,
            project_id: "ALL_PROJECTS",
            version: "ALL_VERSION",
            answer_id: "",
            DisplayCounter: display_stats[qid].ALL_PROJECTS.ALL_VERSION.DisplayCounter,
            DisplayCounterUU: display_stats[qid].ALL_PROJECTS.ALL_VERSION.DisplayCounterUU,
            AnswerCounter: answer_stats[qid] ? answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounter : 0,
            AnswerCounterUU: answer_stats[qid] ? answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounterUU : 0,
            AnswerPercentageValue: answer_stats[qid]
              ? parseFloat(
                (
                  (answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounterUU /
                    display_stats[qid].ALL_PROJECTS.ALL_VERSION.DisplayCounterUU) *
                  100
                ).toFixed(2),
              )
              : 0, // AnswerCounterUU/DisplayCounterUU*100
            AnswerPercentage: answer_stats[qid]
              ? (
              (answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounterUU /
                display_stats[qid].ALL_PROJECTS.ALL_VERSION.DisplayCounterUU) *
              100
            ).toFixed(2) + "%"
              : "0.00%", // AnswerCounterUU/DisplayCounterUU*100 [%]
            ClientCounter: answer_stats[qid] ? answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.ClientCounter : 0,
            questionSoldPercentage: answer_stats[qid]
              ?
              ( answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.ClientCounter > 0)?
              (
                (answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.questionSold /
                  answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.ClientCounter) *
                100
              ).toFixed(2)+'%':'0.00%'
              : "0.00%",
            questionSoldPercentageValue: answer_stats[qid] && answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.ClientCounter>0
              ?parseFloat(
              (
                (answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.questionSold /
                  answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.ClientCounter) *
                100
              ).toFixed(2)
              )
              : 0,
            ClientPercentageValue: answer_stats[qid]
              ? parseFloat(
                (
                  (answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.ClientCounter /
                    answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounter) *
                  100
                ).toFixed(2),
              )
              : 0, // ClientCounter/AnswerCounter*100
            ClientPercentage: answer_stats[qid]
              ? (
              (answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.ClientCounter /
                answer_stats[qid].ALL_PROJECTS.ALL_VERSION.ALL_ANSWER.AnswerCounter) *
              100
            ).toFixed(2) + "%"
              : "0.00%", // ClientCounter/AnswerCounter*100 [%]
            mode: "question",
          });
        }
        referenceCounter++;
        tableData.sort((a, b) => b.priority - a.priority);
      }
      return tableData;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    chartData() {
      return this.$store.getters.getChartData;
    },
  },
  methods: {
    async loadData(force) {
      this.loading = true;

      this.$store.commit(QUESTION_LIST_INVALID);
      if (force) {
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(PROJECTS_ALL_INVALID);
      }

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_CLIENTS, {}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_QUESTION_LIST, {subquestion: true}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_GROUP_PROJECT_NAME, {}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_MEDIA_HOUSE, {}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    async exportStats() {
      this.loading = true;
      if (this.dateRange === null) {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        return;
      }

      this.$store.commit(EXPORT_DISPLAY_STATS_INVALID);

      let date = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
      };

      let filters = Object.assign({}, date, this.filter);
      filters.is_detailed = true;

      await this.$store.dispatch(LOAD_EXPORT_DISPLAY_STATS, filters).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const tmpName = this.$store.getters.getExportDisplayStats.tmpname;
      const fileName = this.$store.getters.getExportDisplayStats.name;
      this.downloadFile(tmpName, fileName);

      this.loading = false;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-utils4.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = `${basePath}?token=${userToken}&tmpname=${tmpName}&name=${fileName}`;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async loadStats(force) {
      this.loading = true;
      if (this.dateRange === null) {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        return;
      }
      if (force) {
        this.$store.commit(QUESTION_ANSWER_STATS_INVALID);
        this.$store.commit(DISPLAY_STATS_INVALID);
      }

      if (force && this.dateRange !== null) {
        let date = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
        };

        const filters = Object.assign({}, date, this.filter);

        this.$store.dispatch(LOAD_QUESTION_ANSWER_STATS, filters).catch(() => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        await this.$store.dispatch(LOAD_DISPLAY_STATS, filters).catch(() => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        this.counter++;
        this.loading = false;
        this.initRowCheck();
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, {searchQuery}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    headerStyle() {
      return "font-size: 12px;";
    },
    cellClick(row, column) {
      if (column.property === "question_content") {
        this.showModal([row.question_id]);
      }
    },
    cellDoubleClick(row, column) {
    },
    showDetails() {
      let checkedRows = Object.keys(this.checkedRows);
      let question_array = [];
      checkedRows.map((qid) => {
        question_array.push(qid);
      });
      this.showModal(question_array);
    },
    showCharts() {
      let checkedRows = Object.keys(this.checkedRows);
      this.handleChart("many", checkedRows);
    },
    rowChecked(bool, question_id) {
      if (bool) {
        this.checkedRows[question_id] = true;
      } else {
        delete this.checkedRows[question_id];
      }
      if (Object.keys(this.checkedRows).length > 0) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    initRowCheck() {
      this.rowCheck = {};
      this.checkedRows = {};
      for (let qid in this.display_stats) {
        this.rowCheck[qid] = false;
      }
    },
    headerClick(column) {
      if (column.label === "Check") {
        if (!this.checkAll) {
          for (let i in this.tableData) {
            this.checkedRows[this.tableData[i].question_id] = true;
            this.rowCheck[this.tableData[i].question_id] = true;
            this.showButton = true;
          }
        } else {
          for (let i in this.rowCheck) {
            this.rowCheck[i] = false;
          }
          this.checkedRows = {};
          this.showButton = false;
        }
        this.checkAll = !this.checkAll;
      }
    },
    clearVersions() {
      this.filter.versions = [];
    },
    sortChange(obj) {
      this.sort = {
        prop: obj.prop,
        order: obj.order,
      };
      if (obj.order === "descending") {
        if (obj.prop === "is_active" || obj.prop === "question_type" || obj.prop === "question_content") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else if (obj.prop === "AnswerPercentage" || obj.prop === "UniqueAnswerPercentage" || obj.prop === "ClientPercentage" || obj.prop === "questionSoldPercentage") {
          this.tableData.sort((a, b) => b[obj.prop + "Value"] - a[obj.prop + "Value"]);
        } else {
          this.tableData.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (obj.prop === "is_active" || obj.prop === "question_type" || obj.prop === "question_content") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else if (obj.prop === "AnswerPercentage" || obj.prop === "UniqueAnswerPercentage" || obj.prop === "ClientPercentage" || obj.prop === "questionSoldPercentage") {
          this.tableData.sort((a, b) => a[obj.prop + "Value"] - b[obj.prop + "Value"]);
        } else {
          this.tableData.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        this.tableData.sort((a, b) => b.priority - a.priority);
      }
      this.searchQuery += " ";
      this.searchQuery = this.searchQuery.slice(0, -1);
    },
    removeVersion(project_id) {
      let versions = this.filter.versions;
      let filteredVersions = [];
      for (let i in versions) {
        if (this.versionProjectObject[versions[i]] !== project_id) {
          filteredVersions.push(versions[i]);
        }
      }
      this.filter.versions = filteredVersions;
    },
    getQuestionSelectWidth() {
      this.questionSelectWidth = document.getElementById("displayStatsQuestionSelect").offsetWidth;
    },
    showModal(questions) {
      // wyświetlenie modala szczegółów
      let date = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
      };

      let filters = Object.assign({}, date, this.filter);
      filters.questions = questions;

      this.$modal.show(
        DisplayStatsModal,
        {
          filters: filters,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(DisplayStatsModal);
              },
            },
          ],
        },
        {
          name: "DisplayStatsModal",
          draggable: false,
          scrollable: true,
          width: "90%",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    handleChart(mode, data) {
      // wyświetlenie modala wykresu
      let filter = JSON.parse(JSON.stringify(this.filter));

      let date = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
      };

      if (mode === "single") {
        filter.questions = [data.question_id];
      } else {
        filter.questions = data;
      }

      this.$modal.show(
        DisplayStatsChartModal,
        {
          date: date,
          filter: filter,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(DisplayStatsChartModal);
              },
            },
          ],
        },
        {
          name: "DisplayStatsChartModal",
          draggable: false,
          scrollable: true,
          width: "90%",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    tagType(value) {
      // przypisanie tagów
      switch (value) {
        case "ACTIVE":
          return "success";
        case "INACTIVE":
          return "warning";
        case "ARCHIVE":
          return "danger";
        default:
          return "info";
      }
    },
  },
  mounted() {
    // eventBus.$on('postcodesSaved', () => { // zapis pliku
    //   this.loadData();
    // });
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
      this.loadStats(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped></style>
