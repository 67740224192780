import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_BANNER_SALES,
  BANNER_SALES_LOADING,
  BANNER_SALES_LOADING_SUCCESS,
  BANNER_SALES_LOADING_ERROR,
  BANNER_SALES_INVALID
} from 'src/store/actions/secure/banner_sales'

const storeObjectConfig = {
  objectName: "BannerSales",
  getterName: "getBannerSales",
  loadActionName: LOAD_BANNER_SALES,
  loadingMutationName: BANNER_SALES_LOADING,
  loadingSuccessMutationName: BANNER_SALES_LOADING_SUCCESS,
  loadingErrorMutationName: BANNER_SALES_LOADING_ERROR,
  invalidMutationName: BANNER_SALES_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/bannerSales",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
