import { render, staticRenderFns } from "./DisplayCosts.vue?vue&type=template&id=064a3c7d&scoped=true&"
import script from "./DisplayCosts.vue?vue&type=script&lang=js&"
export * from "./DisplayCosts.vue?vue&type=script&lang=js&"
import style0 from "./DisplayCosts.vue?vue&type=style&index=0&id=064a3c7d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064a3c7d",
  null
  
)

export default component.exports