<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h5 class="card-title">Raport statusowy</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-3 vertical-center">
        <el-select v-model="project_id" name="project" data-vv-as="Projekt" style="width: 100%" filterable>
          <el-option
            v-for="project in projects"
            :label="project.project_id !== 0 ? project.project_name + ' [' + project.project_id + ']' : project.project_name"
            :value="project.project_id"
            :key="project.project_id"
          ></el-option>
        </el-select>
      </div>
      <div class="col-sm-12 col-md-1 datePickerCheck">
        <el-checkbox-button v-model="datePickerCheck" @change="changeDateType()" label="Zakres"></el-checkbox-button>
      </div>
      <div v-show="!datePickerCheck" class="col-sm-12 text-sm-left col-md-3 vertical-center">
        <flat-pickr class="flatPickr" v-model="pickrWeek" :config="config" placeholder="Wybierz tydzień"></flat-pickr>
      </div>
      <div v-show="datePickerCheck" class="col-sm-12 text-sm-left col-md-3 vertical-center">
        <flat-pickr class="flatPickr" v-model="pickrDate" :config="configDate" placeholder="Zakres"></flat-pickr>
      </div>
      <div class="col-sm-3 col-md-1">
        <p-button type="success" @click="loadData">Pokaż</p-button>
      </div>
    </div>

    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <h6>
          <div class="card-header weekHeader" style="display: flex; justify-content: space-between">
            <span>Przedział dat:</span>
            <span>{{ dateHeader }}</span>
          </div>
        </h6>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <stats-card type="success" icon="nc-icon nc-money-coins" small-title="Przychód" :title="income | formatCurrency">
          <div class="stats" style="height: 25px" slot="footer">
            <div v-if="statusSalesRaport.length > 0" style="display: flex; justify-content: space-between">
              <el-tooltip class="item" effect="light" content="Przychody Hot" placement="top" :open-delay="200">
                <span>
                  <i style="color: #dc3545" class="fa fa-fire" aria-hidden="true"></i>
                  {{ incomeHot | formatCurrency }}
                </span>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="Przychody Warm" placement="top" :open-delay="200">
                <span>
                  <i style="color: #ffc107" class="fa fa-thermometer-three-quarters" aria-hidden="true"></i>
                  {{ incomeWarm | formatCurrency }}
                </span>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="Przychody Ruch Spadowy" placement="top" :open-delay="200">
                <span>
                  <i style="color: #007bff" class="fa fa-cart-arrow-down" aria-hidden="true"></i>
                  {{ incomeUpselling.income | formatCurrency }}
                </span>
              </el-tooltip>
            </div>
          </div>
        </stats-card>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <stats-card type="warning" icon="fa fa-cogs" small-title="Koszty" :title="cost | formatCurrency">
          <div class="stats" style="height: 25px" slot="footer"></div>
        </stats-card>
      </div>

      <div class="col-lg-4 col-md-12 col-sm-12">
        <stats-card
          :type="revenue > 0 ? 'success' : 'danger'"
          icon="fa fa-line-chart"
          :small-title="revenue > 0 ? 'Zysk' : 'strata'"
          :title="revenue | formatCurrency"
        >
          <div class="stats" style="height: 25px" slot="footer"></div>
        </stats-card>
      </div>
    </div>

    <div v-if="statusProductionRaport.length > 0">
      <div class="row">
        <div :class="chartsReady ? 'col-sm-8' : 'col-sm-12'">
          <div class="card bg-light border border-secondary">
            <div class="card-header">
              <h6 class="card-title">Rentowność</h6>
            </div>
            <div class="card">
              <el-table :data="profability" style="width: 100%" size="mini" :header-cell-style="headerStyle">
                <el-table-column label="Źródło" min-width="120" property="channel"></el-table-column>
                <el-table-column label="Przychód" min-width="120" property="income" align="right">
                  <template v-slot="props">
                    {{ props.row.income | formatCurrency }}
                  </template>
                </el-table-column>
                <el-table-column label="Koszt" min-width="120" property="cost" align="right">
                  <template v-slot="props">
                    {{ props.row.cost | formatCurrency }}
                  </template>
                </el-table-column>
                <el-table-column label="Zysk/Strata" min-width="120" property="revenue" align="right">
                  <template v-slot="props">
                    {{ props.row.revenue | formatCurrency }}
                  </template>
                </el-table-column>
                <el-table-column label="ROI" min-width="120" property="roi" align="right">
                  <template v-slot="props">
                    {{ props.row.roi }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="col-md-2" v-if="chartsReady">
          <chart-card :chart-data="incomeChart" chart-type="Pie" title="Przychody" description="Podział przychodów na źródła">
            <template slot="header">
              <h6 class="card-title">Źródła przychodów</h6>
            </template>
            <template slot="footer">
              <div class="legend">
                <div v-for="(data, index) in pieChartData.labels" :key="data">
                  <i :style="'color: ' + pieChartData.backgroundColor[index] + ';'" class="fa fa-circle"></i>
                  {{ data }}
                </div>
              </div>
            </template>
          </chart-card>
        </div>
        <div class="col-md-2" v-if="chartsReady">
          <chart-card :chart-data="costChart" chart-type="Pie" title="Koszty" description="Podział kosztów na źródła">
            <template slot="header">
              <h6 class="card-title">Źródła kosztów</h6>
            </template>
            <template slot="footer">
              <div class="legend">
                <div v-for="(data, index) in pieChartData.labels" :key="data">
                  <i :style="'color: ' + pieChartData.backgroundColor[index] + ';'" class="fa fa-circle"></i>
                  {{ data }}
                </div>
              </div>
            </template>
          </chart-card>
        </div>
      </div>
    </div>

    <div v-if="Object.values(statusProductionRaportGetter).length > 0" class="card bg-light border border-secondary">
      <div class="card-header">
        <h6 class="card-title">Produkcja</h6>
      </div>
      <div class="card">
        <el-table
          :data="statusProductionRaport"
          size="mini"
          style="width: 100%"
          @cell-click="cellClick"
          :row-style="rowStyle"
          :header-cell-style="headerStyle"
        >
          <el-table-column fixed type="expand">
            <template v-slot="props">
              <div class="tableRow">
                <p>{{ "Nowy Lead F1: " + formatNumber(props.row.NewLeadF1) }}</p>
                <p>{{ "Nowa Osoba: " + formatNumber(props.row.NewPerson) }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed label="Źródło" min-width="230" property="Channel">
            <template v-slot="props">
              <i
                v-if="props.row.Channel === 'Ruch pozostały' || props.row.Channel === 'Maile stałe'"
                style="cursor: pointer"
                :class="displayBool[props.row.Channel] ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o'"
                aria-hidden="true"
              ></i
              ><span class="breakWords" :style="cellStyle(props.row.Channel, 'Channel')"
                ><b> {{ props.row.Channel.replace(" - ruch pozostały", "") }}</b></span
              >
            </template>
          </el-table-column>
          <el-table-column fixed label="Koszt" min-width="130" property="Cost" align="right">
            <template v-slot="props">
              <div v-if="['Mailingi follow-up', 'Mailingi postprocessing','Mailingi doubleoptin'].includes(props.row.Channel)">
                <el-tooltip class="item" effect="light" :content="'Koszt wirtualny'" placement="top" :open-delay="200">
                  <span :style="cellStyle(props.row.Channel, 'Cost')">{{ props.row.Cost | formatCurrency }}</span>
                </el-tooltip>
              </div>
              <div v-else>
                <span :style="cellStyle(props.row.Channel, 'Cost')">{{ props.row.Cost | formatCurrency }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Wysłane" min-width="110" property="Sent" align="right">
            <template v-slot="props">
              {{ props.row.Channel === "Display" || props.row.Channel === "Suma" ? " - " : formatNumber(props.row.Sent) }}
            </template>
          </el-table-column>
          <el-table-column label="OR" min-width="80" property="Display" align="right">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="props.row.Display.toString()" placement="top" :open-delay="200">
                <span v-if="props.row.Sent === 0"> - </span>
                <span v-if="props.row.Sent !== 0">{{ getPercentage(props.row.Display, props.row.Sent) }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="CTR" min-width="80" property="Click" align="right">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="props.row.Click.toString()" placement="top" :open-delay="200">
                <span v-if="props.row.Sent === 0"> - </span>
                <span v-if="props.row.Sent !== 0">{{ getPercentage(props.row.Click, props.row.Sent) }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="L" min-width="100" property="Lead" align="right">
            <template v-slot="props">
              {{ props.row.Lead | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="NL" min-width="100" property="NewLead" align="right">
            <template v-slot="props">
              {{ props.row.NewLead | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="F1" min-width="100" property="LeadF1" align="right">
            <template v-slot="props">
              {{ props.row.LeadF1 | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="LH" min-width="100" property="LeadHot" align="right">
            <template v-slot="props">
              {{ props.row.LeadHot | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="PL" min-width="100" property="FullLead" align="right">
            <template v-slot="props">
              {{ props.row.FullLead | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="NPL" min-width="100" property="NewFullLead" align="right">
            <template v-slot="props">
              {{ props.row.NewFullLead | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="CPL(L)" min-width="120" property="CPL(L)" align="right">
            <template v-slot="props">
              {{ getCPL(props.row.Cost, props.row.Lead) }}
            </template>
          </el-table-column>
          <el-table-column label="CPL(NL)" min-width="120" property="CPL(NL)" align="right">
            <template v-slot="props">
              {{ getCPL(props.row.Cost, props.row.NewLead) }}
            </template>
          </el-table-column>
          <el-table-column label="CPL(F1)" min-width="120" property="CPL(F1)" align="right">
            <template v-slot="props">
              {{ getCPL(props.row.Cost, props.row.LeadF1) }}
            </template>
          </el-table-column>
          <el-table-column label="CPL(PL)" min-width="120" property="CPL(PL)" align="right">
            <template v-slot="props">
              {{ getCPL(props.row.Cost, props.row.FullLead) }}
            </template>
          </el-table-column>
          <el-table-column label="CPL(LH)" min-width="120" property="CPL(LH)" align="right">
            <template v-slot="props">
              {{ getCPL(props.row.Cost, props.row.LeadHot) }}
            </template>
          </el-table-column>
          <el-table-column label="CPL(NPL)" min-width="120" property="CPL(NPL)" align="right">
            <template v-slot="props">
              {{ getCPL(props.row.Cost, props.row.NewFullLead) }}
            </template>
          </el-table-column>
          <el-table-column label="CPL(NO)" min-width="120" property="CPL(NO)" align="right">
            <template v-slot="props">
              {{ getCPL(props.row.Cost, props.row.NewPerson) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div v-if="statusSalesRaport.length > 0" class="card bg-light border border-secondary">
      <div class="card-header">
        <h6 class="card-title">Sprzedaż</h6>
      </div>
      <div class="card">
        <el-table
          :data="statusSalesRaport"
          style="width: 100%"
          size="mini"
          :row-style="rowStyle"
          :header-cell-style="headerStyle"
          :summary-method="getSummaries"
          show-summary
        >
          <el-table-column fixed type="expand">
            <template v-slot="props">
              <div class="row tableRow">
                <div style="width: 400px">
                  <table class="table" v-if="salesDetails.projects[props.row.ChannelID]">
                    <thead>
                      <th>Projekt</th>
                      <th>Leady</th>
                    </thead>
                    <tbody>
                      <tr v-for="project in salesDetails.projects[props.row.ChannelID]" :key="project.ProjectID">
                        <td>
                          {{ "[" + project.ProjectID + "] " + project.Project }}
                        </td>
                        <td>{{ project.Leads }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col"></div>
                <div style="width: 400px">
                  <table class="table" v-if="salesDetails.sources[props.row.ChannelID]">
                    <thead>
                      <th>Źródło</th>
                      <th>Leady</th>
                    </thead>
                    <tbody>
                      <tr v-for="source in salesDetails.sources[props.row.ChannelID]" :key="source.Source">
                        <td>{{ source.Source }}</td>
                        <td>{{ source.Leads }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="ID" width="100" property="ChannelID" sortable></el-table-column>
          <el-table-column label="Typ" width="100" property="Type" sortable>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="'Przychody ' + props.row.Type" placement="top" :open-delay="200">
                <span
                  ><i
                    :style="props.row.Type === 'hot' ? 'color: #dc3545' : 'color: #ffc107;'"
                    :class="props.row.Type === 'hot' ? 'fa fa-fire fa-lg' : 'fa fa-thermometer-three-quarters fa-lg'"
                    aria-hidden="true"
                  ></i
                ></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Kanał" min-width="120" property="Channel" sortable></el-table-column>
          <el-table-column label="Leady" min-width="120" property="Leads" align="right" sortable>
            <template v-slot="props">
              <el-tooltip
                class="item"
                effect="light"
                :content="'Poprzedni tydzień: ' + formatNumber(props.row.OldLeads)"
                placement="top"
                :open-delay="200"
              >
                <span>{{ props.row.Leads | formatNumber }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Przychód" min-width="120" property="Income" align="right" sortable>
            <template v-slot="props">
              <el-tooltip
                class="item"
                effect="light"
                :content="'Poprzedni tydzień: ' + formatNumber(props.row.OldIncome, 2) + ' zł'"
                placement="top"
                :open-delay="200"
              >
                <span>{{ props.row.Income | formatCurrency }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Zmiana" min-width="120" property="ChangePercent" align="right" sortable>
            <template v-slot="props">
              <span
                v-if="props.row.ChangePercent !== null"
                :style="props.row.ChangePercent > 0 ? 'color: #28a745;' : props.row.ChangePercent < 0 ? 'color: #dc3545;' : 'color: #17a2b8;'"
                >{{ Math.round(props.row.ChangePercent) + "%" }}
                <i
                  :class="props.row.ChangePercent > 0 ? 'fa fa-arrow-up' : props.row.ChangePercent < 0 ? 'fa fa-arrow-down' : 'fa fa-bars'"
                  aria-hidden="true"
                ></i
              ></span>
              <span style="color: #ffc107" v-else><i class="fa fa-star" aria-hidden="true"></i></span>
            </template>
          </el-table-column>
          <el-table-column label="Wykres" width="120" property="Chart" align="right">
            <template v-slot="props">
              <p-button type="info" size="sm" icon :id="'status_raport_chart_button_' + props.$index" @click="handleChart(props.row)">
                <i class="fa fa-bar-chart" aria-hidden="true"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="card-header">
        <h6 class="card-title">Ruch spadowy</h6>
      </div>
      <div class="card">
        <el-table :data="incomeArray" style="width: 100%">
          <el-table-column label="Przychód" width="200" property="income" align="right">
            <template v-slot="props">
              <span>{{ props.row.income | formatCurrency }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Wyświetlenia" width="200" property="displays" align="right">
            <template v-slot="props">
              <span>{{ props.row.displays | formatNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column label="CPM" width="200" property="cpm" align="right">
            <template v-slot="props">
              <span>{{ props.row.displays > 0 ? (props.row.income / props.row.displays) * 1000 : 0 | formatCurrency }}</span>
            </template>
          </el-table-column>
          <el-table-column label="" min-width="200" property="" align="right">
            <template>
              <span></span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";
import { FIRST_DAY_OF_WEEK } from "src/config";

import { DatePicker, Select, Option, Tooltip, CheckboxButton } from "element-ui";
import StatsCard from "src/components/UIComponents/Cards/StatsCard";

import PieChart from "src/components/UIComponents/Charts/PieChart";
import ChartCard from "src/components/UIComponents/Cards/ChartCard";

import { LOAD_STATUS_PRODUCTION_RAPORT, STATUS_PRODUCTION_RAPORT_INVALID } from "src/store/actions/secure/statusRaport/status_production_raport";
import { LOAD_STATUS_SALES_RAPORT, STATUS_SALES_RAPORT_INVALID } from "src/store/actions/secure/statusRaport/status_sales_raport";
import { LOAD_STATUS_SALES_DETAILS, STATUS_SALES_DETAILS_INVALID } from "src/store/actions/secure/statusRaport/status_sales_details";
import { LOAD_PROJECTS, PROJECTS_INVALID } from "src/store/actions/questions/projects";
import { LOAD_UPSELLING_INCOME, UPSELLING_INCOME_INVALID } from "src/store/actions/secure/upselling_income";

import StatusRaportChartModal from "src/components/Utils4/sales/views/StatusRaportChartModal.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Polish } from "flatpickr/dist/l10n/pl.js";
import weekSelect from "flatpickr/dist/plugins/weekSelect/weekSelect.js";

const tooltipOptions = {
  tooltipFillColor: "rgba(0,0,0,0.5)",
  tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipFontSize: 14,
  tooltipFontStyle: "normal",
  tooltipFontColor: "#fff",
  tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipTitleFontSize: 14,
  tooltipTitleFontStyle: "bold",
  tooltipTitleFontColor: "#fff",
  tooltipYPadding: 6,
  tooltipXPadding: 6,
  tooltipCaretSize: 8,
  tooltipCornerRadius: 6,
  tooltipXOffset: 10,
};

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [CheckboxButton.name]: CheckboxButton,
    ChartCard,
    PieChart,
    StatsCard,
    flatPickr,
  },
  data() {
    return {
      loading: false,
      project_id: 0,
      pickrWeek: null,
      pickrDate: null,
      config: {
        locale: {
          ...Polish,
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
        plugins: [new weekSelect({})],
        altInput: true,
        altInputClass: "flatPickr",
        altFormat: "T\\y\\dz\\ień W",
      },
      configDate: {
        locale: {
          ...Polish,
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
        mode: "range",
      },
      datePickerCheck: false,
      pickerOptions: {
        firstDayOfWeek: FIRST_DAY_OF_WEEK,
      },
      displayBool: {
        "Mailingi MM": false,
        "Ruch pozostały": false,
        "Maile stałe":false,
      },
      referenceCounter: 0,
      counter: 0,
      chartsReady: false,
    };
  },
  methods: {
    async initLoad(force) {
      this.loading = true;

      if (force) this.$store.commit(PROJECTS_INVALID);

      await this.$store.dispatch(LOAD_PROJECTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    async loadData() {
      if (this.week === null && !this.date[1]) {
        eventBus.$emit("notify", {
          message: "Należy wybrać tydzień!",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      this.chartsReady = false;

      const date_from = this.week ? moment(this.dateFrom).format("YYYY-MM-DD") : this.date[0];
      const date_to = this.week ? moment(this.dateTo).format("YYYY-MM-DD") : this.date[1];

      const filters = {
        date_from: date_from,
        date_to: date_to,
        project_id: this.project_id,
      };

      this.$store.commit(STATUS_SALES_RAPORT_INVALID);
      this.$store.commit(STATUS_PRODUCTION_RAPORT_INVALID);
      this.$store.commit(STATUS_SALES_DETAILS_INVALID);
      this.$store.commit(UPSELLING_INCOME_INVALID);

      this.$store.dispatch(LOAD_UPSELLING_INCOME, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_STATUS_SALES_DETAILS, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_STATUS_PRODUCTION_RAPORT, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_STATUS_SALES_RAPORT, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    getPercentage(a, b) {
      if (b !== 0) {
        return Math.round((a / b) * 100) + "%";
      }
      return "-";
    },
    getCPL(cost, lead) {
      if (lead > 0) {
        return (cost / lead).toLocaleString("pl-PL", {
          style: "currency",
          currency: "pln",
        });
      } else {
        return "-";
      }
    },
    rowStyle(obj) {
      if (obj.row.Channel.includes("Suma")) {
        return "background-color: #f2fcff; font-weight: bold;";
      }
      return "";
    },
    headerStyle(obj) {
      return "font-size: 12px;";
    },
    cellStyle(channel, column) {
      if (channel === "Ruch pozostały" && column === "Channel") {
        return "cursor: pointer;";
      }
      if (
        channel === "Mailingi stałe follow-up" ||
        channel === "Mailingi stałe postprocessing" ||
        channel === "Mailingi stałe doubleoptin" ||
        channel === "Mailingi wewnętrzne - ruch pozostały" ||
        channel === "Mailingi zewnętrzne - ruch pozostały" ||
        channel === "SMS - ruch pozostały" ||
        channel === "Display - ruch pozostały"
      ) {
        if (column === "Channel") {
          return "padding-left: 20px;";
        }
      }
      if (channel.includes("Mailingi") && !channel.includes("pozostały") && channel !== "Mailingi zewnętrzne" && channel !== "Mailingi wewnętrzne") {
        return "text-decoration: underline; color: #adadad;";
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          return;
        }
        if (index === 1) {
          sums[index] = "Suma";
          return;
        }
        if (index === 4) {
          const values = data.map((item) => Number(item[column.property]));
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.formatNumber(sums[index]);
        } else if (index === 5) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = this.formatNumber(sums[index], 2) + " zł";
          }
        } else {
          sums[index] = " - ";
        }
      });

      return sums;
    },
    cellClick(row, column) {
      if (column.property === "Channel" && row.Channel === "Ruch pozostały") {
        this.displayBool["Ruch pozostały"] = !this.displayBool["Ruch pozostały"];
        this.counter++;
      }

      if (column.property === "Channel" && row.Channel === "Maile stałe") {
        this.displayBool["Maile stałe"] = !this.displayBool["Maile stałe"];
        this.counter++;
      }
    },
    handleChart({ ChannelID, Channel }) {
      // wyświetlenie modala wykresu
      let date = {
        date_from: this.week ? moment(this.dateFrom).format("YYYY-MM-DD") : this.date[0],
        date_to: this.week ? moment(this.dateTo).format("YYYY-MM-DD") : this.date[1],
      };

      this.$modal.show(
        StatusRaportChartModal,
        {
          date: date,
          channel: Channel,
          channel_id: ChannelID,
          project_id: this.project_id,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(StatusRaportChartModal);
              },
            },
          ],
        },
        {
          name: "StatusRaportChartModal",
          draggable: false,
          scrollable: true,
          width: "90%",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    formatNumber(number, decimals = 0) {
      let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number).toFixed(decimals).split(".");
      n[0] = n[0]
        .split("")
        .reverse()
        .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
        .reverse()
        .join("");
      let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

      return final;
    },
    changeDate() {
      const from = this.date[0];
      const to = this.date[1];

      const dayFrom = FIRST_DAY_OF_WEEK;
      const dayTo = FIRST_DAY_OF_WEEK - 1;

      let dateFrom, dateTo;

      if (moment(from).day() < dayFrom) {
        dateFrom = moment(from).isoWeekday(dayFrom).subtract(1, "weeks").format("YYYY-MM-DD");
      } else {
        dateFrom = moment(from).isoWeekday(dayFrom).format("YYYY-MM-DD");
      }

      if (moment(to).day() == dayTo) {
        dateTo = moment(to).isoWeekday(dayTo).format("YYYY-MM-DD");
      } else {
        dateTo = moment(to).isoWeekday(dayTo).add(1, "weeks").format("YYYY-MM-DD");
      }

      this.date = [];
      this.date = [dateFrom, dateTo];
    },
    changeDateType() {
      this.pickrDate = null;
      this.pickrWeek = null;
      this.$store.commit(STATUS_SALES_RAPORT_INVALID);
      this.$store.commit(STATUS_PRODUCTION_RAPORT_INVALID);
      this.$store.commit(STATUS_SALES_DETAILS_INVALID);
      this.$store.commit(UPSELLING_INCOME_INVALID);
    },
  },
  computed: {
    date() {
      let result = [];
      if (this.pickrDate) {
        const newDate = this.pickrDate.split(" do ");
        if (newDate[0]) result.push(newDate[0]);
        if (newDate[1]) result.push(newDate[1]);
      }
      return result;
    },
    week() {
      let resultDate = null;
      if (this.pickrWeek) {
        let resultDate = new Date(this.pickrWeek);
        const selectedDay = resultDate.getDay();

        // 0: -4, 1: -5, 2: +1, 3: 0, 4: -1, 5: -2, 6: -3
        if (selectedDay >= 2) {
          resultDate.setDate(resultDate.getDate() + (3 - selectedDay));
        } else {
          resultDate.setDate(resultDate.getDate() + (-4 - selectedDay));
        }

        return resultDate;
      }

      return resultDate;
    },
    dateFrom() {
      let firstDay = null;
      if (this.week !== null) {
        firstDay = new Date(this.week.getTime());
        firstDay.setDate(firstDay.getDate() - 1);
      }
      return firstDay;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateFrom).format("dddd, Do MMMM YYYY");
    },
    dateTo() {
      let lastDay = null;
      if (this.week !== null) {
        lastDay = new Date(this.week.getTime());
        lastDay.setDate(lastDay.getDate() + 5);
      }
      return lastDay;
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateTo).format("dddd, Do MMMM YYYY");
    },
    income() {
      let income = 0;
      income = this.incomeHot + this.incomeWarm + this.incomeUpselling.income;
      return income;
    },
    incomeHot() {
      let income = 0;
      this.statusSalesRaport.map((x) => {
        if (x.Type === "hot") {
          income += x.Income;
        }
      });
      return income;
    },
    incomeWarm() {
      let income = 0;
      this.statusSalesRaport.map((x) => {
        if (x.Type === "warm") {
          income += x.Income;
        }
      });
      return income;
    },
    incomeUpselling() {
      let income = 0;
      let displays = 0;
      if (this.upsellingIncome.length > 0) {
        this.upsellingIncome.map((x) => {
          income += x.income;
          displays += x.displays;
        });
      }
      return { income, displays };
    },
    incomeArray() {
      return [
        {
          income: this.incomeUpselling.income,
          displays: this.incomeUpselling.displays,
        },
      ];
    },
    cost() {
      if (this.$store.state.status_production_raport.loading_status === "success") {
        return this.statusProductionRaportGetter.Suma.Cost;
      }
      return 0;
    },
    revenue() {
      return this.income - this.cost;
    },
    statusProductionRaportGetter() {
      let data = [];
      if (this.$store.state.status_production_raport.loading_status === "success") {
        return this.$store.getters.getStatusProductionRaport;
      }
      return data;
    },
    statusProductionRaport() {
      let data = [];
      let counter = this.counter;
      let referenceCounter = this.referenceCounter;
      if (counter !== referenceCounter) {
        // licznik wymuszający przeliczenie tabeli
        if (this.$store.state.status_production_raport.loading_status === "success") {
          const raports = this.$store.getters.getStatusProductionRaport;
          if (raports["Mailingi zewnętrzne"]) {
            data.push(raports["Mailingi zewnętrzne"]);
          }
          if (raports["Mailingi wewnętrzne"]) {
            data.push(raports["Mailingi wewnętrzne"]);
          }

          if (!this.displayBool["Maile stałe"]) {
            if (raports["Maile stałe"]) {
              data.push(raports["Maile stałe"]);
            }
          }else{
            if (raports["Maile stałe"]) {
              data.push(raports["Maile stałe"]);
            }
            if (raports["Mailingi stałe follow-up"]) {
              data.push(raports["Mailingi stałe follow-up"]);
            }
            if (raports["Mailingi stałe postprocessing"]) {
              data.push(raports["Mailingi stałe postprocessing"]);
            }
            if (raports["Mailingi stałe doubleoptin"]) {
              data.push(raports["Mailingi stałe doubleoptin"]);
            }
          }
          // if(raports['SumaMailing']) {
          //   data.push(raports['SumaMailing']);
          // }
          if (raports.Display) {
            data.push(raports.Display);
          }
          if (raports.Banery) {
            data.push(raports.Banery);
          }
          if (raports.SMS) {
            data.push(raports.SMS);
          }
          if (raports["Ruch pozostały"]) {
            if (!this.displayBool["Ruch pozostały"]) {
              data.push(raports["Ruch pozostały"]);
            } else {
              data.push(raports["Ruch pozostały"]);
              if (raports["Ruch pozostały"]["Pozostałe"])
                Object.values(raports["Ruch pozostały"]["Pozostałe"]).map((x) => {
                  data.push(x);
                });
            }
          }
          if (raports.Suma) {
            data.push(raports.Suma);
          }
        }
        referenceCounter++;
      }
      return data;
    },
    statusSalesRaport() {
      let data = [];
      if (this.$store.state.status_sales_raport.loading_status === "success") {
        const raport = Object.values(this.$store.getters.getStatusSalesRaport);
        return raport;
      }
      return data;
    },
    salesDetails() {
      return this.$store.getters.getStatusSalesDetails;
    },
    upsellingIncome() {
      let result = [];
      if (this.$store.state.upselling_income.loading_status === "success") {
        const getter = this.$store.getters.getUpsellingIncome;
        if (typeof getter === "object" && getter !== null) {
          result = Object.values(this.$store.getters.getUpsellingIncome);
        }
      }
      return result;
    },
    projects() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjects);
      let uniqueProjects = {};

      for (let i in projects) {
        // wybranie unikalnych projektów
        uniqueProjects[projects[i].project_id] = {
          project_id: projects[i].project_id,
          project_name: projects[i].project_name,
        };
      }

      let result = Object.values(uniqueProjects).sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1)); // sortowanie projektów po nazwie

      result.unshift({
        project_id: 0,
        project_name: "[Wszystkie projekty]",
      });

      return result;
    },
    profability() {
      const prof = [];
      if (
        this.$store.state.status_production_raport.loading_status === "success" &&
        this.$store.state.status_sales_details.loading_status === "success"
      ) {
        const productionRaport = this.statusProductionRaportGetter;
        const salesDetails = this.salesDetails.sources ? this.salesDetails.sources.Suma.Income : undefined;
        let channel = "";

        if (productionRaport && salesDetails) {
          if (productionRaport["Mailingi zewnętrzne"] || salesDetails["Mailingi zewnętrzne"]) {
            channel = "Mailingi zewnętrzne";
            let revenue = 0;
            if (productionRaport[channel] && salesDetails[channel]) {
              revenue = salesDetails[channel] - productionRaport[channel].Cost;
            } else if (productionRaport[channel]) {
              revenue = 0 - productionRaport[channel].Cost;
            } else if (salesDetails[channel]) {
              revenue = salesDetails[channel];
            }
            prof.push({
              channel: channel,
              cost: productionRaport[channel] ? productionRaport[channel].Cost : 0,
              income: salesDetails[channel] ? salesDetails[channel] : 0,
              revenue: revenue,
              roi:
                productionRaport[channel] && productionRaport[channel].Cost > 0
                  ? Math.round((revenue / productionRaport[channel].Cost) * 100 * 100) / 100 + "%"
                  : "∞",
            });
          }

          if (productionRaport["Mailingi wewnętrzne"] || salesDetails["Mailingi wewnętrzne"]) {
            channel = "Mailingi wewnętrzne";
            let revenue = 0;
            if (productionRaport[channel] && salesDetails[channel]) {
              revenue = salesDetails[channel] - productionRaport[channel].Cost;
            } else if (productionRaport[channel]) {
              revenue = 0 - productionRaport[channel].Cost;
            } else if (salesDetails[channel]) {
              revenue = salesDetails[channel];
            }
            prof.push({
              channel: channel,
              cost: 0,
              income: salesDetails[channel] ? salesDetails[channel] : 0,
              revenue: revenue,
              roi:
                productionRaport[channel] && productionRaport[channel].Cost > 0
                  ? Math.round((revenue / productionRaport[channel].Cost) * 100 * 100) / 100 + "%"
                  : "∞",
            });
          }
          if (productionRaport["Maile stałe"] || salesDetails["Maile stałe"]) {
            channel = "Mailingi stałe";
            prof.push({
              channel: channel,
              cost: 0,
              income: salesDetails[channel] ? salesDetails[channel] : 0,
              revenue: salesDetails[channel] ? salesDetails[channel] : 0,
              roi: salesDetails[channel] > 0 ? "∞" : "0%",
            });
          }

          if (productionRaport["Display"] || salesDetails["Display"]) {
            channel = "Display";
            let revenue = 0;
            if (productionRaport[channel] && salesDetails[channel]) {
              revenue = salesDetails[channel] - productionRaport[channel].Cost;
            } else if (productionRaport[channel]) {
              revenue = 0 - productionRaport[channel];
            } else if (salesDetails[channel]) {
              revenue = salesDetails[channel];
            }
            prof.push({
              channel: channel,
              cost: productionRaport[channel] ? productionRaport[channel].Cost : 0,
              income: salesDetails[channel] ? salesDetails[channel] : 0,
              revenue: revenue,
              roi:
                productionRaport[channel] && productionRaport[channel].Cost > 0
                  ? Math.round((revenue / productionRaport[channel].Cost) * 100 * 100) / 100 + "%"
                  : "∞",
            });
          }

          if (productionRaport["SMS"] || salesDetails["SMS"]) {
            channel = "SMS";
            let revenue = 0;
            if (productionRaport[channel] && salesDetails[channel]) {
              revenue = salesDetails[channel] - productionRaport[channel].Cost;
            } else if (productionRaport[channel]) {
              revenue = 0 - productionRaport[channel];
            } else if (salesDetails[channel]) {
              revenue = salesDetails[channel];
            }
            prof.push({
              channel: channel,
              cost: productionRaport[channel] ? productionRaport[channel].Cost : 0,
              income: salesDetails[channel] ? salesDetails[channel] : 0,
              revenue: revenue,
              roi:
                productionRaport[channel] && productionRaport[channel].Cost > 0
                  ? Math.round((revenue / productionRaport[channel].Cost) * 100 * 100) / 100 + "%"
                  : "∞",
            });
          }

          if (this.upsellingIncome.length > 0) {
            const income = this.incomeUpselling.income;
            prof.push({
              channel: "Ruch spadowy",
              cost: 0,
              income: income,
              revenue: income,
              roi: "∞",
            });
          }

          if (salesDetails.Inne) {
            const income = salesDetails.Inne;
            prof.push({
              channel: "Inne",
              cost: 0,
              income: income,
              revenue: income,
              roi: "∞",
            });
          }
          this.chartsReady = true;
        }
      }
      return prof;
    },
    pieChartData() {
      const source = this.profability;
      const backgroundColorObj = {
        "Ruch spadowy": "#e3e3e3",
        "Mailingi wewnętrzne": "#4acccd",
        "Mailingi stałe":"#854acd",
        Display: "#fcc468",
        SMS: "#3cbd3c",
        "Mailingi zewnętrzne": "#c2384b",
        Inne: "#ff9626",
      };

      let result = {
        labels: [],
        backgroundColor: [],
        data: { income: [], cost: [] },
      };

      if (source.length > 0) {
        for (let i in source) {
          result.labels.push(source[i].channel);
          result.data.cost.push(source[i].cost);
          result.data.income.push(source[i].income);
          result.backgroundColor.push(backgroundColorObj[source[i].channel]);
        }
      }

      return result;
    },
    incomeChart() {
      const obj = {
        labels: [],
        datasets: [
          {
            label: "Przychody",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: [],
            borderWidth: 0,
            data: [],
          },
        ],
        options: {
          tooltips: tooltipOptions,
        },
      };

      obj.datasets[0].data = this.pieChartData.data.income;
      obj.datasets[0].backgroundColor = this.pieChartData.backgroundColor;
      obj.labels = this.pieChartData.labels;

      return obj;
    },
    costChart() {
      const obj = {
        labels: [],
        datasets: [
          {
            label: "Koszty",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: [],
            borderWidth: 0,
            data: [],
          },
        ],
        options: {
          tooltips: tooltipOptions,
        },
      };

      obj.datasets[0].data = this.pieChartData.data.cost;
      obj.datasets[0].backgroundColor = this.pieChartData.backgroundColor;
      obj.labels = this.pieChartData.labels;

      return obj;
    },
    dateHeader() {
      if (this.week !== null) {
        return this.dateFromTitle + " - " + this.dateToTitle;
      } else if (this.date[0] && this.date[1]) {
        let from = moment(this.date[0]).format("dddd, Do MMMM YYYY");
        const to = moment(this.date[1]).format("dddd, Do MMMM YYYY");
        return from + " - " + to;
      } else {
        return "Wybierz tydzień";
      }
    },
  },
  mounted() {},
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
      this.initLoad(true);
    });
    this.initLoad(false);
    this.counter++;
  },
  beforeDestroy: function () {
    this.$store.commit(STATUS_SALES_RAPORT_INVALID);
    this.$store.commit(STATUS_PRODUCTION_RAPORT_INVALID);
    this.$store.commit(STATUS_SALES_DETAILS_INVALID);
    this.$store.commit(UPSELLING_INCOME_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.percentageValue {
  color: #383838;
  font-weight: bold;
}

.breakWords {
  word-break: break-word;
}

.weekHeader {
  padding: 15px 15px 5px !important;
}

.left-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datePickerCheck {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1px;
}

.tableRow {
  margin: 5px;
}
</style>
