<template>
  <div class="row">
    <div class="col-sm-12 col-md-12">
      <h5 class="title">Partnerzy zgód dodaj do serwisu</h5>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-sm-8 col-md-4">
          <label>Projekt</label>
          <fg-input :error="getError('project_id')" data-vv-as="Projekt">
            <el-select
              id="ConsentsPartnersSelect"
              v-on:visible-change="getUpperLeftSelectWidth()"
              v-model="project_id"
              name="project_id"
              data-vv-as="Projekt"
              filterable
              v-on:input="loadData()"
            >
              <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                <el-option
                  :style="upperLeftSelectWidth !== null ? 'width: ' + upperLeftSelectWidth + 'px' : 'width: 300px'"
                  :id="'ConsentsPartnersSelectOption_' + item.project_id"
                  v-for="item in group.options"
                  :key="item.project_id"
                  :label="item.project_name + ' [' + item.project_id + ']'"
                  :value="item.project_id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </fg-input>
        </div>
        <div class="col-sm-4 col-md-2">
          <label>Wersja</label>
          <fg-input :error="getError('version')" data-vv-as="Wersja">
            <el-select
              id="ConsentsPartnersVersionSelect"
              v-on:visible-change="getUpperRightSelectWidth()"
              v-model="version"
              name="version"
              data-vv-as="Wersja"
              v-on:input="versionChanged(version)"
              filterable
              clearable
            >
              <el-option
                :style="upperRightSelectWidth !== null ? 'width: ' + upperRightSelectWidth + 'px' : 'width: 300px'"
                :id="'ConsentsPartnersVersionSelectOption_' + version.module"
                v-for="version in activeProjectModules"
                :label="version.module"
                :value="version.module"
                :key="version.module"
              ></el-option>
            </el-select>
          </fg-input>
        </div>
        <div class="col-sm-12 col-md-6 text-md-right right-bottom" v-if="project_id && editPermitted">
          <p-button style="margin-right: 5px;" type="warning" @click.prevent="cancel()">Anuluj</p-button>
          <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <label v-if="project_id !== null">Lista pól</label>
              <label v-else>Wybierz projekt</label>
              <div class="row" v-if="project_id !== null && editPermitted">
                <div class="col-sm-7">
                  <table class="table">
                    <thead>
                    <tr>
                      <th style="width: 150px;" scope="col">Kolejność</th>
                      <th scope="col">Nazwa</th>
                    </tr>
                    </thead>
                    <draggable v-model="grantedFields" tag="tbody" @change="moved" handle=".handle">
                      <tr v-for="(item, index) in grantedFields" :key="index + '_' + item.partner_id">
                        <slot :row="item">
                          <td style="cursor: pointer; width: 150px;"><i class="fa fa-bars handle"
                                                                        aria-hidden="true"></i>
                          </td>
                          <td @click="changeGranted(item)" style="cursor: pointer">
                            <el-tooltip class="item" effect="light" :content="item.content" placement="top"
                                        :open-delay="500">
                              <span>{{ item.partner_name }}</span>
                            </el-tooltip>
                          </td>
                        </slot>
                      </tr>
                    </draggable>
                  </table>
                </div>
                <div class="col-sm-1">
                  <div class="row">
                    <div class="col-sm-12">
                      <el-button style="margin-top: 20px;" plain @click.prevent="moveFields('left')"
                      ><i class="fa fa-angle-double-left" aria-hidden="true"></i
                      ></el-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <el-button style="margin-top: 10px;" plain @click.prevent="moveFields('right')"
                      ><i class="fa fa-angle-double-right" aria-hidden="true"></i
                      ></el-button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th scope="col">Dostępne pola</th>
                    </tr>
                    </thead>
                    <tr v-for="item in availableFields" :key="item.partner_id">
                      <slot :row="item">
                        <td @click="changeGranted(item)" style="cursor: pointer">
                          <el-tooltip class="item" effect="light" :content="item.content" placement="top"
                                      :open-delay="500">
                            <span>{{ item.partner_name }}</span>
                          </el-tooltip>
                        </td>
                      </slot>
                    </tr>
                  </table>
                </div>
              </div>
              <div v-if="project_id !== null && !editPermitted">
                <ul class="consents_partners__read_only_granted_fields">
                  <li v-for="(field, index) in grantedFields" :key="index">{{ field.partner_name }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <back-to-top bottom="50px" right="50px" visibleoffset="100">
        <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
      </back-to-top>
    </div>
  </div>
</template>

<script>
// ui components
import {Select, Option, OptionGroup, Tooltip, Button} from "element-ui";
import BackToTop from "vue-backtotop";

// vuex actions
import {LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID} from "src/store/actions/questions/projects_all";
import {LOAD_PROJECT_DICT, PROJECT_DICT_INVALID} from "src/store/actions/questions/project_dict";
import {
  LOAD_CONSENTS_PARTNERS,
  CONSENTS_PARTNERS_INVALID,
  SAVE_CONSENTS_PARTNERS
} from "src/store/actions/consents/consents_partners";
import {
  LOAD_CONSENTS_PARTNERS_DICT,
  CONSENTS_PARTNERS_DICT_INVALID
} from "src/store/actions/consents/consents_partners_dict";

// event bus
import {eventBus} from "src/main";

import draggable from "vuedraggable";

import * as priv from "src/privileges.js";

export default {
  props: ['active-name'],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
    BackToTop,
    draggable,
  },
  data() {
    return {
      project_id: null,
      version: "",
      site_id: null,
      upperLeftSelectWidth: null,
      upperRightSelectWidth: null,
      allFields: [],
      availableFields: [],
      grantedFields: [],
    };
  },
  computed: {
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map(x => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    activeProjectModules() {
      // wszystkie moduły aktywnego projektu
      let projects = this.$store.getters.getProjectsAll;
      let filteredData = {};

      for (let [key, project] of Object.entries(projects)) {
        // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
        if (project.project_id === this.project_id) {
          filteredData[project.module] = Object.assign({}, project);
        }
      }

      let sourceData = [];

      for (let ver of Object.keys(filteredData)) {
        // iteracja po nazwach modułów danego projektu
        let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie liczby
        filteredData[ver].number = res;
      }

      sourceData = Object.values(filteredData);
      sourceData.sort((a, b) => a.number - b.number);
      let result = {};
      for (let i in sourceData) {
        result[sourceData[i].module] = sourceData[i];
      }

      return result;
    },
    consentPartners() {
      return this.$store.getters.getConsentsPartners;
    },
    consentsPartnersDict() {
      return Object.values(this.$store.getters.getConsentsPartnersDict);
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_CONSENTS_PARTNERS);
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(PROJECTS_ALL_INVALID);
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(CONSENTS_PARTNERS_DICT_INVALID);
      }
      this.$store.commit(CONSENTS_PARTNERS_INVALID);

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych4!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych5!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_CONSENTS_PARTNERS_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych6!",
          type: "warning",
        });
      });
      if (this.project_id !== null) {
        await this.$store.dispatch(LOAD_CONSENTS_PARTNERS, {project_id: this.project_id}).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych7!",
            type: "warning",
          });
        });
      }
      this.initFields();
    },
    initFields() {
      this.allFields = [];
      this.availableFields = [];
      this.grantedFields = [];

      const dict = Object.values(JSON.parse(JSON.stringify(this.$store.getters.getConsentsPartnersDict)));

      const consentsPartners = Object.values(JSON.parse(JSON.stringify(this.$store.getters.getConsentsPartners)));
      let filteredConsentsPartners = consentsPartners.filter(
        consent => (this.version === "" && consent.site_id === null) || (this.version !== null && this.version === consent.short_name),
      );
      let data = {};
      filteredConsentsPartners.forEach(x => {
        data[x.partner_id] = x;
      });

      const data_ids = Object.values(data).map(row => row.partner_id);
      for (let i in dict) {
        let row = dict[i];
        this.allFields.push({
          partner_id: row.id,
          partner_name: row.name,
          granted: data_ids.includes(row.id),
          position: data[row.id] !== undefined ? data[row.id].position : null,
          content: row.content,
        });
      }
      this.setFields();
      this.grantedFields = this.grantedFields.sort((one, two) => {
        return one.position - two.position;
      });
    },
    setFields() {
      this.grantedFields = this.allFields.filter(row => row.granted);
      this.availableFields = this.allFields.filter(row => !row.granted);
    },
    moved(event) {
      // indeksowanie tablic przy każdej zmianie kolejności
      this.grantedFields.forEach((el, index) => (el.position = index + 1));
    },
    onMove({relatedContext, draggedContext}) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
    },
    changeGranted(row) {
      row.granted = !row.granted;
      this.setFields();
      this.moved();
    },
    async saveData() {
      let allGrantedFields = this.grantedFields;
      allGrantedFields.forEach((el, index) => (el.position = index + 1));
      let grantedFields = allGrantedFields.map(({partner_id, position}) => {
        return {partner_id: partner_id, position: position};
      });
      let model = Object.assign({}, {
        project_id: [this.project_id],
        site_id: this.site_id
      }, {grantedFields: grantedFields});

      await this.$store.dispatch(SAVE_CONSENTS_PARTNERS, model).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getConsentsPartnersModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Rekord został zmodyfikowany",
          type: "success",
        });

        this.$store.commit(CONSENTS_PARTNERS_INVALID); // oznacz nieaktualność danych
        this.loadData();
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getUpperLeftSelectWidth() {
      this.upperLeftSelectWidth = document.getElementById("ConsentsPartnersSelect").offsetWidth;
    },
    getUpperRightSelectWidth() {
      this.upperRightSelectWidth = document.getElementById("ConsentsPartnersVersionSelect").offsetWidth;
    },
    versionChanged(version) {
      if (this.activeProjectModules[version]) {
        this.site_id = this.activeProjectModules[version].site_id;
      } else {
        this.site_id = null;
      }
      this.initFields();
    },
    cancel() {
      this.project_id = null;
      this.version = "";
      this.site_id = null;
    },
    moveFields(direction) {
      let set = direction === "left" ? this.availableFields : this.grantedFields;
      set.forEach(x => {
        x.granted = !x.granted;
      });
      this.setFields();
      this.moved();
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
  async created() {
    this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
      eventBus.$emit("notify", {
        message: "Błąd wczytywania danych!",
        type: "warning",
      });
    });

    this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
      eventBus.$emit("notify", {
        message: "Błąd wczytywania danych!",
        type: "warning",
      });
    });

    this.$store.dispatch(LOAD_CONSENTS_PARTNERS_DICT, {}).catch(error => {
      eventBus.$emit("notify", {
        message: "Błąd wczytywania danych!",
        type: "warning",
      });
    });
    eventBus.$on('reload',this.cancel);
    eventBus.$on("forceReload", () => {
      if (this.activeName === 'ConsentPartnersService') {
        this.loadData(true);
      }
    });

  },
  mounted() {
    eventBus.$on("consentsTabChanged", () => {
      this.project_id = null;
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="scss" scoped>
ul.consents_partners__read_only_granted_fields {
  list-style-type: decimal;
  font-weight: bold;
}

.highlight {
  font-size: 1.2em;
  font-weight: bold;
}

.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flip-list-move {
  transition: transform 0.5s;
}

.flip-list-leave-active {
  display: none !important;
}

.no-move {
  transition: transform 0s;
}

.no-leave-active {
  display: none !important;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
  min-width: 200px;
}

.list-drag-item {
  cursor: move;
}

.list-drag-item i {
  cursor: pointer;
}

tr {
  text-align: center;
}
</style>
