import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_SETTLEMENTS,
  SETTLEMENTS_LOADING,
  SETTLEMENTS_LOADING_SUCCESS,
  SETTLEMENTS_LOADING_ERROR,
  SETTLEMENTS_INVALID,
  SAVE_SETTLEMENT,
  SETTLEMENT_SAVING,
  SETTLEMENT_SAVING_SUCCESS,
  SETTLEMENT_SAVING_ERROR
} from 'src/store/actions/raports/settlements'

const storeObjectConfig = {
  objectName: "Settlements",
  getterName: "getSettlements",
  loadActionName: LOAD_SETTLEMENTS,
  loadingMutationName: SETTLEMENTS_LOADING,
  loadingSuccessMutationName: SETTLEMENTS_LOADING_SUCCESS,
  loadingErrorMutationName: SETTLEMENTS_LOADING_ERROR,
  invalidMutationName: SETTLEMENTS_INVALID,
  saveActionName: SAVE_SETTLEMENT,
  savingMutationName: SETTLEMENT_SAVING,
  savingSuccessMutationName: SETTLEMENT_SAVING_SUCCESS,
  savingErrorMutationName: SETTLEMENT_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/settlements/settlements",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
