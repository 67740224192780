import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_TAGS,
  TAGS_LOADING,
  TAGS_LOADING_SUCCESS,
  TAGS_LOADING_ERROR,
  TAGS_INVALID,
  SAVE_TAG,
  TAG_SAVING,
  TAG_SAVING_SUCCESS,
  TAG_SAVING_ERROR
} from '../../actions/questionForm/tags'

const storeObjectConfig = {
  objectName: "Answers",
  getterName: "getTags",
  loadActionName: LOAD_TAGS,
  loadingMutationName: TAGS_LOADING,
  loadingSuccessMutationName: TAGS_LOADING_SUCCESS,
  loadingErrorMutationName: TAGS_LOADING_ERROR,
  invalidMutationName: TAGS_INVALID,
  saveActionName: SAVE_TAG,
  savingMutationName: TAG_SAVING,
  savingSuccessMutationName: TAG_SAVING_SUCCESS,
  savingErrorMutationName: TAG_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/answers/tags",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/questions/answers/tags",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
