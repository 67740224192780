<template>
  <div class="row">
    <div class="col-sm-2 center">
      <b>{{ report.result }}</b>
    </div>
    <div class="col-sm-7">
      <div>{{ report.value }}</div>
      <div class="tip">{{ report.tip }}</div>
    </div>
    <div class="col-sm-2 center">
      <span>[typ {{ report.error_code }}]</span>
      <span v-if="report.error_code > 3" style="margin-left: 5px;">
        <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
          <div slot="content">
            <pre>{{ report.criteria }}</pre>
          </div>
          <div slot="content">
            <pre>{{ report.filter }}</pre>
          </div>
          <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
        </el-tooltip>
      </span>
    </div>
    <div class="col-sm-1">
      <el-tooltip class="item" effect="light" :content="getOption ? 'Usuń wyjątek' : 'Dodaj wyjątek'" placement="top" :open-delay="500">
        <p-button
          :disabled="disableButtons"
          :type="getOption ? 'danger' : 'success'"
          size="sm"
          icon
          @click="handleException(report)"
          v-if="report.error_code > 3"
        >
          <i :class="getOption ? 'fa fa-minus' : 'fa fa-plus'" aria-hidden="true"></i>
        </p-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { eventBus } from "src/main";

import { Tooltip } from "element-ui";

export default {
  components: {
    [Tooltip.name]: Tooltip,
  },
  props: ["report", "index", "type", "disableButtons", "checkedArray"],
  computed: {
    isException() {
      return this.type === "exception";
    },
    isChecked() {
      return this.checkedArray.includes(this.index);
    },
    getOption() {
      if (this.isException) {
        if (this.isChecked) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.isChecked) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    handleException(report) {
      eventBus.$emit("handleConsistencyException", { report: report, index: this.index, type: this.type, mode: this.getOption });
    },
  },
};
</script>

<style scoped>
.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}
</style>
