import storeGen from '@/store/storeGenerator'
import {
    AUTH_LOGOUT
} from '@/store/actions/auth'

import {
    BASE_API
} from '../../config'

import {
  ERROR_KO_SENDS,
  GET_KO_SENDS,
  GET_KO_SENDS_OBJECT, INVALID_KO_SENDS,
  LOAD_KO_SENDS,
  LOADING_KO_SENDS, SAVE_KO_SENDS, SAVING_ERROR_KO_SENDS, SAVING_KO_SENDS, SAVING_SUCCESS_KO_SENDS, SUCCESS_KO_SENDS
} from "@/store/actions/korejestracja/ko_sends";

const storeObjectConfig = {
    objectName: GET_KO_SENDS_OBJECT,
    getterName: GET_KO_SENDS,
    loadActionName: LOAD_KO_SENDS,
    loadingMutationName: LOADING_KO_SENDS,
    loadingSuccessMutationName: SUCCESS_KO_SENDS,
    loadingErrorMutationName: ERROR_KO_SENDS,
    invalidMutationName: INVALID_KO_SENDS,
    saveActionName: SAVE_KO_SENDS,
    savingMutationName: SAVING_KO_SENDS,
    savingSuccessMutationName: SAVING_SUCCESS_KO_SENDS,
    savingErrorMutationName: SAVING_ERROR_KO_SENDS,
    logoutMutationName: AUTH_LOGOUT,
    apiUrl: BASE_API,
    validPeriod: 10, // minutes
    recordType: 'json',
    loadParams: {
        url: "/kosend/koSends",
        method: "get" // {'get','post','put'}
    },
    saveParams: {
        url: "/kosend/koSends/saveKoSends",
        method: "post" // {'get','post','put'}
    }
}

const state = storeGen.stateGenerator(storeObjectConfig);// stan stora
const getters = storeGen.gettersGenerator(storeObjectConfig);//pobiera dane ze stora
const actions = storeGen.actionsGenerator(storeObjectConfig);//poberanie dany do stora async
const mutations = storeGen.mutationsGenerator(storeObjectConfig);//zmieniają stan stora

export default {
    state,
    getters,
    actions,
    mutations
}
