<template>
  <div>
    <h4 class="title">Zmień hasło użytkownika {{ getUserLoginById }}</h4>
    <div class="row">
      <div class="col-lg-4 col-md-4">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="form-group">
                <label>Hasło</label>
                <fg-input  type="password"
                          name="Hasło"
                          v-validate="modelValidations.password"
                          v-model="model.password"
                          :error="getError('Hasło')">
                </fg-input>
              </div>
              <div class="form-group">
                <label>Powtórz hasło</label>
                <fg-input  type="password"
                          name="Powtórz hasło"
                          v-validate="modelValidations.confirmPassword"
                          v-model="model.confirmPassword"
                          :error="getError('Powtórz hasło')">
                </fg-input>
              </div>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-info btn-fill btn-wd"
                  @click.prevent="saveData"
                >Zmień hasło</button>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as regexp from "src/regexp.js";

import { SAVE_PASSWORD } from "src/store/actions/admin/password";
import 'vue-notifyjs/themes/default.css'
import { eventBus } from "src/main";

export default {
  data() {
    return {
      model: {
        password: "",
        confirmPassword: "",
        user_id: null,
        user_pass: ""
      },
      modelValidations: {
        password: {
          required: true,
          min: 5
        },
        confirmPassword: {
          required: true,
          // confirmed: password
        },
        user_pass: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 3,
          max: 32
        }
      }
    };
  },
  computed: {
    user_id() {
      return this.$route.params.id;
    },
    getUserLoginById() {
      return this.$store.getters.getUserLoginById(this.user_id);
    },
    modelPassword() {
      return {
        user_id: this.user_id,
        user_pass: this.model.confirmPassword,
      }
    }
  },
  methods: {
    getError (fieldName) {
      return this.errors.first(fieldName)
    },
    async saveData() {
      const isValid = await this.$validator.validateAll()

      if (isValid) {
        if (this.model.password === this.model.confirmPassword)
        {
          await this.$store.dispatch(SAVE_PASSWORD, this.modelPassword).catch(error => {
            let message = ''
            console.log(error.response)
            eventBus.$emit("notify", {
              message: "Błąd zapisu danych!",
              type: "warning"
            });
          })

          if (this.$store.getters.getPasswordModifiedRecords === true) {
            eventBus.$emit("notify", {
              message: "Rekord został zmodyfikowany",
              type: "success"
            });
            this.$router.push({ name: "Użytkownicy" }) // przekieruj do listy
          }
        }
        else
        {
          eventBus.$emit("notify", {
            message: "Hasła nie są zgodne!",
            type: "warning"
          });
        }
      }
    }
  }
};
</script>

<style>
</style>
