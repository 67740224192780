<template>
  <div v-if="tableData.length > 0">
    <p class="consentsHeader">
      <span
        ><b>{{ typeName + ":" }}</b></span
      >
      <p-button @click="copyConsent()" type="default" link>
        Kopiuj
      </p-button>
    </p>
    <table v-if="tableData.length > 0">
      <thead>
        <tr>
          <th>Admin</th>
          <th>ID</th>
          <th>Treść</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="consent in tableData" :key="consent.zgodaID">
          <td>{{ getConsentOwner(consent) }}</td>
          <td>{{ consent.zgodaID }}</td>
          <td :class="consent.isHidden ? 'hiddenConsent' : ''">{{ getConsentContent(consent) }}</td>
        </tr>
      </tbody>
    </table>
    <br />
  </div>
</template>

<script>
// event bus
import { eventBus } from "src/main";

export default {
  props: ["consents", "type"],
  computed: {
    tableData() {
      if (this.consents) {
        let data = Object.values(this.consents);
        if (this.type === "consent") {
          data = data.filter(x => x.Type != 0);
          data.map(x => {
            if (x.Consent[0] == "*") {
              x.isHidden = true;
            } else {
              x.isHidden = false;
            }
          });
        } else {
          data = data.filter(x => x.Type == 0);
        }
        return data;
      } else {
        return [];
      }
    },
    typeName() {
      if (this.type == "consent") {
        return "Zgody";
      } else {
        return "Obowiązek";
      }
    },
  },
  methods: {
    getConsentOwner(consent) {
      if (consent.OwnerShortName) {
        return consent.OwnerShortName;
      } else {
        return consent.OwnerGroup;
      }
    },
    getConsentContent(consent) {
      const regex = /(<([^>]+)>)/gi;
      return consent.Consent.replace(regex, " ");
    },
    copyConsent() {
      let consent = "";
      let result = "";
      const regex = /(<([^>]+)>)/gi;
      let data = this.tableData;
      if (this.type === "consent") {
        data = data.filter(x => !x.isHidden);
      }
      for (let i in data) {
        consent = data[i].Consent.replace(regex, " ");
        result += consent + "\n";
      }
      navigator.clipboard.writeText(result).then(
        function() {
          eventBus.$emit("notify", {
            message: "Skopiowano zgody do schowka",
            type: "success",
          });
        },
        function(err) {
          eventBus.$emit("notify", {
            message: "Błąd kopiowania",
            type: "warning",
          });
        },
      );
    },
  },
};
</script>

<style scoped>
.consentsHeader {
  display: flex;
  justify-content: space-between;
}

.hiddenConsent {
  color: gray;
}
</style>
