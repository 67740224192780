<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">Informacje sprzedażowe</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="text-center" v-if="userSales.length == 0">
            Brak danych
          </div>
          <ul>
            <li v-for="client in userSales" :key="client.clientName">
              {{ client.name }}
            </li>
          </ul>
          <div class="row">
            <p-button type="success" @click.prevent="$emit('close')">Zamknij</p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input } from "element-ui";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

import { LOAD_USER_SALES, USER_SALES_INVALID } from "src/store/actions/complaints/user_sales";

export default {
  components: {
    [Input.name]: Input,
  },
  props: ["phone", "mail"],
  data() {
    return {
      loading: false,
      model: {
        phone: this.phone,
        mail: this.mail,
      },
      modelValidations: {
        phone: {
          regex: "^(48)?[0-9]{9}$",
          max: 11,
        },
        mail: {
          regex: "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}",
        },
      },
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData() {
      if (this.model.phone.length < 1 && this.model.mail.length < 1) return;
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      this.loading = true;
      const filters = {
        phone: this.model.phone.length === 9 ? "48" + this.model.phone : this.model.phone,
        mail: this.model.mail,
      };

      this.$store.commit(USER_SALES_INVALID);

      await this.$store.dispatch(LOAD_USER_SALES, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
  },
  computed: {
    userSales() {
      return this.$store.getters.getUserSales;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}
</style>
