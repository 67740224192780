import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_QUESTION_DATA,
  QUESTION_DATA_LOADING,
  QUESTION_DATA_LOADING_SUCCESS,
  QUESTION_DATA_LOADING_ERROR,
  QUESTION_DATA_INVALID,
  SAVE_QUESTION_DATA,
  QUESTION_DATA_SAVING,
  QUESTION_DATA_SAVING_SUCCESS,
  QUESTION_DATA_SAVING_ERROR
} from '../../actions/questionForm/question_data'

const storeObjectConfig = {
  objectName: "Questions",
  getterName: "getQuestionData",
  loadActionName: LOAD_QUESTION_DATA,
  loadingMutationName: QUESTION_DATA_LOADING,
  loadingSuccessMutationName: QUESTION_DATA_LOADING_SUCCESS,
  loadingErrorMutationName: QUESTION_DATA_LOADING_ERROR,
  invalidMutationName: QUESTION_DATA_INVALID,
  saveActionName: SAVE_QUESTION_DATA,
  savingMutationName: QUESTION_DATA_SAVING,
  savingSuccessMutationName: QUESTION_DATA_SAVING_SUCCESS,
  savingErrorMutationName: QUESTION_DATA_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/questionForm",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/questions/questionForm",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
