<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Zlecenia testów</h5>
      </div>
    </div>

    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <div>
                  <label for="requestDate">Czas uruchomienia</label>
                </div>
                <el-date-picker
                  v-model="model.start_time"
                  type="datetime"
                  name="requestDate"
                  align="center"
                  placeholder="Teraz"
                  :clearable="true"
                  format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label for="project">Projekt</label>
                <el-select
                  id="testResultsProjectSelect"
                  v-on:visible-change="getUpperLeftSelectWidth()"
                  v-on:clear="clearVersions()"
                  v-on:handleOptionClick="clearVersions()"
                  v-model="model.project_id"
                  placeholder="Wszystkie"
                  name="project"
                  data-vv-as="Projekt"
                  style="width: 100%"
                  filterable
                  clearable
                >
                  <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                    <el-option
                      :style="upperLeftSelectWidth !== null ? 'width: ' + upperLeftSelectWidth + 'px' : 'width: 300px'"
                      :id="'testResultsProjectSelectOption_' + item.project_id"
                      v-for="item in group.options"
                      :key="item.project_id"
                      :label="item.project_name + ' [' + item.project_id + ']'"
                      :value="item.project_id"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label>Wersja</label>
                <el-select v-model="model.module" name="module" placeholder="Wszystkie" style="width: 100%" filterable clearable>
                  <el-option-group v-for="group in projectModules" :key="group.label" :label="group.label">
                    <el-option
                      v-for="version in group.options"
                      :label="version.module + ' [' + version.tags + ']'"
                      :value="version.module"
                      :key="version.module"
                    ></el-option>
                  </el-option-group>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 center">
                <p-button type="success" @click="saveData">Dodaj</p-button>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <el-table :data="testsRequests" style="width: 100%">
              <el-table-column label="Projekt" property="project_id" align="left" min-width="120">
                <template v-slot="props">
                  <div v-if="props.row.project_id">{{ "[" + props.row.project_id + "] " + props.row.project_name }}</div>
                  <div v-else>Wszystkie</div>
                </template>
              </el-table-column>
              <el-table-column label="Wersja" property="module" align="left" min-width="120">
                <template v-slot="props">
                  <div v-if="props.row.module">{{ props.row.module }}</div>
                  <div v-else>Wszystkie</div>
                </template>
              </el-table-column>
              <el-table-column label="Czas" property="start_time" width="200" align="right"></el-table-column>
              <el-table-column label="Status" property="status" width="110" align="right"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";

import { DatePicker, Select, Option, OptionGroup, Tag } from "element-ui";

import { LOAD_TESTS_REQUESTS, TESTS_REQUESTS_INVALID } from "src/store/actions/tests/tests_requests";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";
import { SAVE_TESTS_REQUEST } from "../../../store/actions/tests/tests_requests";
import { LOAD_TESTS_PROJECTS, TESTS_PROJECTS_INVALID } from "../../../store/actions/tests/tests_projects";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tag.name]: Tag,
  },
  data() {
    return {
      loading: false,
      model: {
        start_time: null,
        project_id: "",
        module: "",
      },
      versionProjectObject: {},
      upperLeftSelectWidth: null,
    };
  },
  methods: {
    async initLoad(force) {
      this.loading = true;

      if (force) {
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(PROJECTS_ALL_INVALID);
        this.$store.commit(TESTS_PROJECTS_INVALID);
      }

      this.$store.dispatch(LOAD_TESTS_PROJECTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_TESTS_REQUESTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    async loadData() {
      this.loading = true;

      this.$store.commit(TESTS_REQUESTS_INVALID);

      await this.$store.dispatch(LOAD_TESTS_REQUESTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    getUpperLeftSelectWidth() {
      this.upperLeftSelectWidth = document.getElementById("testResultsProjectSelect").offsetWidth;
    },
    clearVersions() {
      this.model.module = "";
    },
    async saveData() {
      let record = this.model;

      let now = moment(new Date());
      let start_time = moment(record.start_time);

      if (start_time.isBefore(now)) {
        return eventBus.$emit("notify", {
          message: "Czas testu nie może być z przeszłości!",
          type: "warning",
        });
      }
      if (record.start_time) {
        record.start_time = start_time.format("YYYY-MM-DD HH:mm:ss");
      }
      record.owner = this.selectedOwner;

      await this.$store.dispatch(SAVE_TESTS_REQUEST, record).catch((error) => {
        // zapisanie danych do bazy
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getTestsRequestsModifiedRecords === true) {
        // wyświetlenie informacji o zapisaniu
        eventBus.$emit("notify", {
          message: "Rekord został zmodyfikowany",
          type: "success",
        });

        this.loadData(true, false);
      }
    },
  },
  computed: {
    testsProjects() {
      return this.$store.getters.getTestsProjects;
    },
    projects() {
      return this.$store.getters.getProjectDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      const projects = Object.values(this.$store.getters.getProjectDict);
      let testProjects = this.testsProjects;

      testProjects = _.isArray(testProjects) ? testProjects : [];

      let projects_filtered = projects.filter((x) => testProjects.includes(x.project_id));
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects_filtered.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    modules() {
      return this.$store.getters.getProjectsAll;
    },
    projectModules() {
      // wszystkie moduły projektu
      let choosedProjects = this.model.project_id == "" ? [] : [this.model.project_id];
      if (Array.isArray(choosedProjects) && choosedProjects.length > 0) {
        let projects = this.$store.getters.getProjectsAll;
        let activeProjects = choosedProjects;
        let filteredData = {};
        let temp = {};
        const sortedData = {};
        for (let i in activeProjects) {
          for (let [key, project] of Object.entries(projects)) {
            // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
            if (project.project_id === activeProjects[i]) {
              temp[project.module] = Object.assign({}, project);
              filteredData[project.project_id] = Object.assign({}, temp);
            }
          }
          temp = {};
        }

        let sourceData = [];

        for (let i in filteredData) {
          // sortowanie danych
          let tempData = filteredData[i];
          let sourceData = {};
          for (let ver of Object.keys(tempData)) {
            // iteracja po nazwach modułów danego projektu
            let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie pierwszej liczby
            filteredData[i][ver].number = res;
          }
        }

        let resultModules = [];

        for (let project_id in filteredData) {
          let projectModules = filteredData[project_id];
          sourceData = Object.values(projectModules).filter((x) => x.is_active);
          sourceData.sort((a, b) => a.number - b.number);
          let result = {};
          for (let i in sourceData) {
            this.versionProjectObject[sourceData[i].module] = parseInt(project_id);
            result[sourceData[i].module] = sourceData[i];
          }
          resultModules.push({
            project_id,
            project_id,
            label: this.projects[project_id].project_name,
            options: result,
          });
        }

        return resultModules;
      }
      return [];
    },
    testsRequests() {
      return Object.values(this.$store.getters.getTestsRequests);
    },
    selectedOwner() {
      if (this.model.project_id) {
        return this.projects[this.model.project_id].owner;
      } else {
        return null;
      }
    },
  },
  mounted() {},
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
      this.initLoad(true);
    });
    this.initLoad(false);
  },
  beforeDestroy: function () {
    this.$store.commit(TESTS_REQUESTS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.right-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
