export const LOAD_USER_DATA = 'LOAD_USER_DATA'
export const USER_DATA_LOADING = 'USER_DATA_LOADING'
export const USER_DATA_LOADING_SUCCESS = 'USER_DATA_LOADING_SUCCESS'
export const USER_DATA_LOADING_ERROR = 'USER_DATA_LOADING_ERROR'
export const USER_DATA_INVALID = 'USER_DATA_INVALID'

export const SAVE_USER_DATA = 'SAVE_USER_DATA'
export const USER_DATA_SAVING = 'USER_DATA_SAVING'
export const USER_DATA_SAVING_SUCCESS = 'USER_DATA_SAVING_SUCCESS'
export const USER_DATA_SAVING_ERROR = 'USER_DATA_SAVING_ERROR'
