import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_USERS_LIST,
  USERS_LIST_LOADING,
  USERS_LIST_LOADING_SUCCESS,
  USERS_LIST_LOADING_ERROR,
  USERS_LIST_INVALID
} from 'src/store/actions/admin/users_list'

const storeObjectConfig = {
  objectName: "Users",
  getterName: "getUsersList",
  loadActionName: LOAD_USERS_LIST,
  loadingMutationName: USERS_LIST_LOADING,
  loadingSuccessMutationName: USERS_LIST_LOADING_SUCCESS,
  loadingErrorMutationName: USERS_LIST_LOADING_ERROR,
  invalidMutationName: USERS_LIST_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/users/users_list",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
