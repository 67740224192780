import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_MAILING_RAPORT,
  MAILING_RAPORT_LOADING,
  MAILING_RAPORT_LOADING_SUCCESS,
  MAILING_RAPORT_LOADING_ERROR,
  MAILING_RAPORT_INVALID,
  SAVE_MAILING_RAPORT,
  MAILING_RAPORT_SAVING,
  MAILING_RAPORT_SAVING_SUCCESS,
  MAILING_RAPORT_SAVING_ERROR
} from 'src/store/actions/raports/mailing_raport'

const storeObjectConfig = {
  objectName: "Raport",
  getterName: "getMailingRaport",
  loadActionName: LOAD_MAILING_RAPORT,
  loadingMutationName: MAILING_RAPORT_LOADING,
  loadingSuccessMutationName: MAILING_RAPORT_LOADING_SUCCESS,
  loadingErrorMutationName: MAILING_RAPORT_LOADING_ERROR,
  invalidMutationName: MAILING_RAPORT_INVALID,
  saveActionName: SAVE_MAILING_RAPORT,
  savingMutationName: MAILING_RAPORT_SAVING,
  savingSuccessMutationName: MAILING_RAPORT_SAVING_SUCCESS,
  savingErrorMutationName: MAILING_RAPORT_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/stats/mailing",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
