<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row header_row">
          <div class="col-sm-10">
            <h5 id="limitsConfigurationFormTitle" class="title">{{ mode === "add" ? "Dodaj limit" : "Edytuj limit" }}</h5>
          </div>
          <div id="limitsConfigurationFormCloseButton">
            <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
          </div>
        </div>
        <div class="row margin-row">
          <div class="col-sm-6">
            <div class="form-group">
              <label>Projekt</label>
              <fg-input :error="getError('project_id')" data-vv-as="Projekt">
                <el-select
                  v-model="model.project_id"
                  name="project_id"
                  data-vv-as="Projekt"
                  v-validate="modelValidations.project_id"
                  filterable
                  clearable
                  :disabled="mode === 'edit'"
                >
                  <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.project_id"
                      :label="item.project_name + ' [' + item.project_id + ']'"
                      :value="item.project_id"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </fg-input>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>Wersja</label>
              <fg-input :error="getError('module')" data-vv-as="Wersja">
                <el-select
                  v-model="model.module"
                  name="module"
                  data-vv-as="Wersja"
                  v-validate="modelValidations.module"
                  filterable
                  :disabled="mode === 'edit'"
                >
                  <el-option
                    v-for="version in versions"
                    :label="version.module === 'ALL_VERSION' ? 'Cały projekt' : version.module"
                    :value="version.module"
                    :key="version.module"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>
          </div>
        </div>
        <div class="input_fields">
          <div class="row">
            <div class="col-sm-5 center">
              CO-REG
            </div>
            <div class="col-sm-6 right-input">
              <fg-input
                type="number"
                name="CR"
                data-vv-as="CR"
                :error="getError('CR')"
                min="0"
                v-model="model.limits.CR"
                v-validate="modelValidations.limits.CR"
              ></fg-input>
            </div>
            <div class="col-sm-1 badges">
              <el-tooltip class="item" effect="light" :content="contentCR" placement="top" :open-delay="500">
                <el-badge :value="projectLimit.CR ? projectLimit.CR : defaultLimit.CR" class="item" type="danger"></el-badge>
              </el-tooltip>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5 center">
              PROFILE
            </div>
            <div class="col-sm-6 right-input">
              <fg-input
                type="number"
                name="PR"
                data-vv-as="PR"
                :error="getError('PR')"
                min="0"
                v-model="model.limits.PR"
                v-validate="modelValidations.limits.PR"
              ></fg-input>
            </div>
            <div class="col-sm-1 badges">
              <el-tooltip class="item" effect="light" :content="contentPR" placement="top" :open-delay="500">
                <el-badge :value="projectLimit.PR ? projectLimit.PR : defaultLimit.PR" class="item" type="danger"></el-badge>
              </el-tooltip>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5 center">
              SUMA
            </div>
            <div class="col-sm-6 right-input">
              <fg-input
                type="number"
                name="Wszystkie"
                data-vv-as="Wszystkie"
                :error="getError('Wszystkie')"
                min="0"
                v-model="model.limits.ALL"
                v-validate="modelValidations.limits.ALL"
              ></fg-input>
            </div>
            <div class="col-sm-1 badges">
              <el-tooltip class="item" effect="light" :content="contentALL" placement="top" :open-delay="500">
                <el-badge :value="projectLimit.ALL ? projectLimit.ALL : defaultLimit.ALL" class="item" type="danger"></el-badge>
              </el-tooltip>
            </div>
          </div>
        </div>

        <div class="row" style="margin: 0;">
          <div class="center margin-row">
            <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
          </div>
          <div class="pull-right" style="align-self: flex-end; margin: 5px;">
            <el-tooltip class="item" effect="light" placement="top" :open-delay="1000">
              <div slot="content">
                Edycja limitów:<br />
                - Limity są dziedziczone - poziom projektów dziedziczy z domyślnej wartości, poziom wersji dziedziczy z poziomu projektu, o ile nie
                został dodany limit<br />
                - Pozostawienie pustego okienka oznacza brak wybranego limitu - zostanie on odziedziczony. Po prawej stronie cyfra wskazuje limit jaki
                zostanie odziedziczony
              </div>
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, OptionGroup, Tooltip, Badge } from "element-ui";

// vuex actions
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_PROJECT_LIMITS, SAVE_PROJECT_LIMITS, PROJECT_LIMITS_INVALID } from "src/store/actions/questions/project_limits";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [Badge.name]: Badge,
  },
  data() {
    return {
      model: {
        project_id: "",
        module: "ALL_VERSION",
        limits: {
          CR: "",
          PR: "",
          ALL: "",
        },
      },
      modelValidations: {
        project_id: {
          required: true,
          numeric: true,
          min: 1,
        },
        module: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 1,
          max: 32,
        },
        limits: {
          ALL: {
            min: 0,
          },
          PR: {
            min: 0,
          },
          CR: {
            min: 0,
          },
        },
      },
      modelCompare: {},
    };
  },
  props: ["level", "mode", "row", "defaultLimit", "projectLimit"],
  computed: {
    limits() {
      let limits = this.$store.getters.getProjectLimits;
      let result = {};

      for (let i in limits) {
        if (result[limits[i].project_id] === undefined) {
          result[limits[i].project_id] = { project_id: limits[i].project_id, project_name: limits[i].project_name, modules: {} };
        }
        if (result[limits[i].project_id].modules[limits[i].module] === undefined) {
          result[limits[i].project_id].modules[limits[i].module] = limits[i].module;
        }
      }
      return result;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map(x => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    versions() {
      // wszystkie moduły aktywnego projektu
      let projects = this.$store.getters.getProjectsAll;
      let filteredData = {};

      for (let [key, project] of Object.entries(projects)) {
        // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
        if (project.project_id === this.model.project_id) {
          filteredData[project.module] = Object.assign({}, project);
        }
      }

      let sourceData = [];

      for (let ver of Object.keys(filteredData)) {
        // iteracja po nazwach modułów danego projektu
        let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie liczby
        filteredData[ver].number = res;
      }

      filteredData["ALL_VERSION"] = { module: "ALL_VERSION", number: 0 };

      let finalObject = {};

      if (this.mode === "add" && this.model.project_id !== "") {
        // filtrowanie isntniejących już limitów wersji
        let limitVersions = [];
        if (this.limits[this.model.project_id] !== undefined) {
          limitVersions = Object.keys(this.limits[this.model.project_id].modules);
        }
        for (let i in filteredData) {
          if (limitVersions.some(x => x === filteredData[i].module)) {
          } else {
            finalObject[i] = filteredData[i];
          }
        }
      } else {
        finalObject = filteredData;
      }

      sourceData = Object.values(finalObject);
      sourceData.sort((a, b) => a.number - b.number);
      let result = {};
      for (let i in sourceData) {
        result[sourceData[i].module] = sourceData[i];
      }

      return result;
    },
    checkPR() {
      if (this.row === null) return false;
      if (this.row["MAX_PROFILE_QUESTION_PER_SSESSION"] !== undefined) {
        return true;
      }
      return false;
    },
    checkCR() {
      if (this.row === null) return false;
      if (this.row["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"] !== undefined) {
        return true;
      }
      return false;
    },
    checkALL() {
      if (this.row === null) return false;
      if (this.row["MAX_QUESTION_PER_SSESSION"] !== undefined) {
        return true;
      }
      return false;
    },
    contentCR() {
      let content = "";
      if (this.level === "project") {
        content = this.defaultLimit.CR.toString();
      } else {
        if (this.projectLimit.CR) {
          content = this.defaultLimit.CR.toString() + "/" + this.projectLimit.CR.toString();
        } else {
          content = this.defaultLimit.CR.toString() + "/" + this.defaultLimit.CR.toString();
        }
      }
      return content;
    },
    contentPR() {
      let content = "";
      if (this.level === "project") {
        content = this.defaultLimit.PR.toString();
      } else {
        if (this.projectLimit.PR) {
          content = this.defaultLimit.PR.toString() + "/" + this.projectLimit.PR.toString();
        } else {
          content = this.defaultLimit.PR.toString() + "/" + this.defaultLimit.PR.toString();
        }
      }
      return content;
    },
    contentALL() {
      let content = "";
      if (this.level === "project") {
        content = this.defaultLimit.ALL.toString();
      } else {
        if (this.projectLimit.ALL) {
          content = this.defaultLimit.ALL.toString() + "/" + this.projectLimit.ALL.toString();
        } else {
          content = this.defaultLimit.ALL.toString() + "/" + this.defaultLimit.ALL.toString();
        }
      }
      return content;
    },
  },
  methods: {
    async loadData(force) {
      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_LIMITS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      if (force) {
        this.$store.commit(PROJECT_LIMITS_INVALID);
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(PROJECTS_ALL_INVALID);
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    createModel() {
      let saveModel = { project_id: this.model.project_id, module: this.model.module, insert: {}, update: {}, delete: {} };

      // CR
      let modelCR = {
        limit_name: "MAX_CO-REGISTRATION_QUESTION_PER_SSESSION",
      };
      let limitCR = this.model.limits.CR === "" ? null : parseInt(this.model.limits.CR);
      if (this.checkCR) {
        // sprawdzenie czy istnieje zapisany limit
        if (limitCR !== null) {
          // sprawdzenie czy jest wartość inputa
          if (limitCR !== this.modelCompare.limits.CR) {
            // aktualny limit został zmieniony - update
            modelCR.limit_value = limitCR;
            saveModel.update["modelCR"] = modelCR;
          }
        } else {
          // pusty input - delete
          saveModel.delete["modelCR"] = modelCR;
        }
      } else {
        if (limitCR !== null) {
          // został nadany nowy limit - insert
          modelCR.limit_value = limitCR;
          saveModel.insert["modelCR"] = modelCR;
        }
      }

      // PR
      let modelPR = {
        limit_name: "MAX_PROFILE_QUESTION_PER_SSESSION",
      };
      let limitPR = this.model.limits.PR === "" ? null : parseInt(this.model.limits.PR);
      if (this.checkPR) {
        // sprawdzenie czy istnieje zapisany limit
        if (limitPR !== null) {
          // sprawdzenie czy jest wartość inputa
          if (limitPR !== this.modelCompare.limits.PR) {
            // aktualny limit został zmieniony - update
            modelPR.limit_value = limitPR;
            saveModel.update["modelPR"] = modelPR;
          }
        } else {
          // pusty input - delete
          saveModel.delete["modelPR"] = modelPR;
        }
      } else {
        if (limitPR !== null) {
          // został nadany nowy limit - insert
          modelPR.limit_value = limitPR;
          saveModel.insert["modelPR"] = modelPR;
        }
      }

      // ALL
      let modelALL = {
        limit_name: "MAX_QUESTION_PER_SSESSION",
      };
      let limitALL = this.model.limits.ALL === "" ? null : parseInt(this.model.limits.ALL);
      if (this.checkALL) {
        // sprawdzenie czy istnieje zapisany limit
        if (limitALL !== null) {
          // sprawdzenie czy jest wartość inputa
          if (limitALL !== this.modelCompare.limits.ALL) {
            // aktualny limit został zmieniony - update
            modelALL.limit_value = limitALL;
            saveModel.update["modelALL"] = modelALL;
          }
        } else {
          // pusty input - delete
          saveModel.delete["modelALL"] = modelALL;
        }
      } else {
        if (limitALL !== null) {
          // został nadany nowy limit - insert
          modelALL.limit_value = limitALL;
          saveModel.insert["modelALL"] = modelALL;
        }
      }

      return saveModel;
    },
    async saveData() {
      let model = this.createModel();
      if (Object.keys(model.insert).length > 0 || Object.keys(model.update).length > 0 || Object.keys(model.delete).length > 0) {
        await this.$store.dispatch(SAVE_PROJECT_LIMITS, model).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd zapisu!",
            type: "warning",
          });
        });

        if (this.$store.getters.getProjectLimitsModifiedRecords > 0) {
          eventBus.$emit("notify", {
            message: this.mode === "add" ? "Rekord został dodany" : "Rekord został zmodyfikowany",
            type: "success",
          });

          this.$store.commit(PROJECT_LIMITS_INVALID); // oznacz nieaktualność danych
          eventBus.$emit("limitsSaved");
          this.$emit("close");
        }
      }
    },
  },
  async created() {
    this.loadData();
    if (this.row !== null) {
      if (this.checkPR) {
        this.model.limits.PR = this.row["MAX_PROFILE_QUESTION_PER_SSESSION"];
      }
      if (this.checkALL) {
        this.model.limits.ALL = this.row["MAX_QUESTION_PER_SSESSION"];
      }
      if (this.checkCR) {
        this.model.limits.CR = this.row["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"];
      }
      this.model.project_id = this.row.project_id;
      this.model.module = this.row.module;
    }

    this.modelCompare = JSON.parse(JSON.stringify(this.model));
  },
};
</script>

<style lang="scss" scoped>
.margin-row {
  margin: 5px 10px;
}
.right-input {
  display: flex;
  margin: auto 0;
  flex-direction: column;
  align-items: flex-end;
}
.card {
  margin: 0;
  display: flex;
}
h5 {
  margin: auto 0;
}
.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}
.badges {
  display: flex;
  margin: auto;
  align-items: flex-start;
  padding-left: 0;
}
.bold {
  font-weight: bold;
}
.header_row {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
</style>
