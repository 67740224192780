import storeGen from "../../storeGenerator";

import { AUTH_LOGOUT } from "../../actions/auth";

import { SECURE_API, BASE_API, IP_API } from "src/store/config";

import {
  LOAD_BLACKLIST_EMAIL_CRITERIA,
  BLACKLIST_EMAIL_CRITERIA_LOADING,
  BLACKLIST_EMAIL_CRITERIA_LOADING_SUCCESS,
  BLACKLIST_EMAIL_CRITERIA_LOADING_ERROR,
  BLACKLIST_EMAIL_CRITERIA_INVALID,
} from "../../actions/blacklist/blacklist_email_criteria";

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getBlacklistEmailCriteria",
  loadActionName: LOAD_BLACKLIST_EMAIL_CRITERIA,
  loadingMutationName: BLACKLIST_EMAIL_CRITERIA_LOADING,
  loadingSuccessMutationName: BLACKLIST_EMAIL_CRITERIA_LOADING_SUCCESS,
  loadingErrorMutationName: BLACKLIST_EMAIL_CRITERIA_LOADING_ERROR,
  invalidMutationName: BLACKLIST_EMAIL_CRITERIA_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: IP_API,
  validPeriod: 10, // minutes
  recordType: "plain",
  loadParams: {
    url: "/blacklist/email/criteria",
    method: "get", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
