import storeGen from "../../storeGenerator";

import { AUTH_LOGOUT } from "../../actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  SAVE_BLACKLIST_REMOVE,
  BLACKLIST_REMOVE_SAVING,
  BLACKLIST_REMOVE_SAVING_SUCCESS,
  BLACKLIST_REMOVE_SAVING_ERROR,
} from "../../actions/blacklist/blacklist_remove";

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getBlacklistRemove",
  saveActionName: SAVE_BLACKLIST_REMOVE,
  savingMutationName: BLACKLIST_REMOVE_SAVING,
  savingSuccessMutationName: BLACKLIST_REMOVE_SAVING_SUCCESS,
  savingErrorMutationName: BLACKLIST_REMOVE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "plain",
  saveParams: {
    url: "/blacklist/sms/revoke",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
