<template>
  <div class="conv-wrapper">
    <div class="card">
      <div class="corner">
        <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
          <div slot="content">
            <b>Konwersja przechodzenia</b> - wejścia uu zgodne z sekwencją na dany step względem wejść zgodnie z sekwencją na poprzedni step.
          </div>
          <div slot="content">
            <b style="color: #1b69bd;">Konwersja wejściowa</b> - wyświetlenia uu na danym stepie względem wyświetleń uu na pierwszym stepie.
          </div>
          <div slot="content">
            <b style="color: #28a745;">Konwersja względna</b> - wyświetlenia uu na danym stepie względem wyświetleń uu na poprzednim stepie.
          </div>
          <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
        </el-tooltip>
      </div>
      <div class="card-header text-center module_header">
        <h6 class="card-title">
          {{ name }}
        </h6>
      </div>
      <div class="card-body conv-card-body-table">
        <table class="table table-hover">
          <thead>
            <th>Step</th>
            <th class="text-right">UU</th>
            <th class="text-right">Konwersja przechodzenia</th>
            <th class="text-right">Konwersja wejściowa</th>
            <th class="text-right">Konwersja względna</th>
          </thead>
          <tbody>
            <tr v-for="(row, index) in main" :key="'main_' + index">
              <td class="conv-table-col">{{ `${getDescription(row.description)} (${row.name})` }}</td>
              <td class="conv-table-col conv-table-col-count text-right" :style="{ color: '#1b69bd' }">
                {{ formatNumber(row.step_udid.display_page_unique_udid) }}
              </td>
              <td class="conv-table-col conv-table-col-count text-right" :style="{ color: 'blac' }">{{ countPercentage(index, "next") }}</td>
              <td class="conv-table-col conv-table-col-count text-right" :style="{ color: '#1b69bd' }">{{ countPercentage(index, "main") }}</td>
              <td class="conv-table-col conv-table-col-count text-right" :style="{ color: '#28a745' }">{{ countPercentage(index, "unique") }}</td>
            </tr>
            <tr v-if="other" class="separator-row">
              <td style="padding: 5px;"><b>Akcje specjalne</b></td>
              <td v-for="i in 4" :key="'sep_' + i" class="separator"></td>
            </tr>
            <tr v-for="(row, index) in other" :key="'other_' + index">
              <td class="conv-table-col">{{ `${getDescription(row.description)} (${row.name})` }}</td>
              <td class="conv-table-col conv-table-col-count text-right" :style="{ color: '#1b69bd' }">
                {{ formatNumber(row.display_page_unique_udid) }}
              </td>
              <td class="conv-table-col conv-table-col-count text-right"></td>
              <td class="conv-table-col conv-table-col-count text-right"></td>
              <td class="conv-table-col conv-table-col-count text-right"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import utils from "src/utils";

import { Tooltip } from "element-ui";

export default {
  components: {
    [Tooltip.name]: Tooltip,
  },
  props: ["main", "other", "name"],
  data() {
    return {};
  },
  methods: {
    formatNumber(num, dec) {
      return utils.formatNumber(num, dec);
    },
    rowStyle() {
      return "height: 50px;";
    },
    countPercentage(index, mode) {
      let prevIndex = mode === "main" ? 0 : index - 1;
      if (index === 0) {
        return "-";
      } else {
        if (mode === "next") {
          let percentage = this.main[prevIndex].next.percentage ? this.formatNumber(this.main[prevIndex].next.percentage, 2) : 0;
          return percentage + "%";
        } else {
          return (
            this.formatNumber(
              (this.main[index].step_udid.display_page_unique_udid / this.main[prevIndex].step_udid.display_page_unique_udid) * 100,
              2,
            ) + "%"
          );
        }
      }
    },
    getDescription(description) {
      if (_.isNull(description)) {
        return "-";
      } else {
        return description;
      }
    },
  },
  computed: {},
};
</script>
<style scoped>
.conv-wrapper {
  width: 100%;
}

.module_header {
  padding: 5px !important;
}

.module_header h6 {
  margin: 5px;
}

th {
  padding: 6px;
}

.conv-table-col {
  padding: 4px;
}

.conv-table-col-count {
  width: 120px;
}

.conv-card-body-table {
  padding: 3px !important;
  margin-bottom: -15px !important;
}

table tr:first-child td {
  border-top: none;
}

.separator {
  /* background-color: lightgray; */
  padding: 2px;
}

.separator-row {
  height: 6px;
}

.corner {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
