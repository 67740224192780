export const LOAD_SYSTEM_PARAMS = 'LOAD_SYSTEM_PARAMS'
export const SYSTEM_PARAMS_LOADING = 'SYSTEM_PARAMS_LOADING'
export const SYSTEM_PARAMS_LOADING_SUCCESS = 'SYSTEM_PARAMS_LOADING_SUCCESS'
export const SYSTEM_PARAMS_LOADING_ERROR = 'SYSTEM_PARAMS_LOADING_ERROR'
export const SYSTEM_PARAMS_INVALID = 'SYSTEM_PARAMS_INVALID'

export const SAVE_SYSTEM_PARAM = 'SAVE_SYSTEM_PARAM'
export const SYSTEM_PARAM_SAVING = 'SYSTEM_PARAM_SAVING'
export const SYSTEM_PARAM_SAVING_SUCCESS = 'SYSTEM_PARAM_SAVING_SUCCESS'
export const SYSTEM_PARAM_SAVING_ERROR = 'SYSTEM_PARAM_SAVING_ERROR'
