import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_QUESTION_ANSWER_CUSTOM,
  QUESTION_ANSWER_CUSTOM_LOADING,
  QUESTION_ANSWER_CUSTOM_LOADING_SUCCESS,
  QUESTION_ANSWER_CUSTOM_LOADING_ERROR,
  QUESTION_ANSWER_CUSTOM_INVALID,
} from "src/store/actions/stats/question_answer_custom";

const storeObjectConfig = {
  objectName: "DisplayStats",
  getterName: "getQuestionAnswerCustom",
  loadActionName: LOAD_QUESTION_ANSWER_CUSTOM,
  loadingMutationName: QUESTION_ANSWER_CUSTOM_LOADING,
  loadingSuccessMutationName: QUESTION_ANSWER_CUSTOM_LOADING_SUCCESS,
  loadingErrorMutationName: QUESTION_ANSWER_CUSTOM_LOADING_ERROR,
  invalidMutationName: QUESTION_ANSWER_CUSTOM_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/stats/stats/answer_custom",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
