<template>
  <div class="modal-wrapper" :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row header-row">
      <div class="col-sm-11">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="title">Wykresy pytań</h5>
          </div>
          <div class="col-sm-4 text-sm-right">
            <p class="category">{{ resolutionTitle }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
    </div>

    <div class="row" v-if="loading">
      <div class="col-sm-12">
        <div class="center" style="font-size: 1.3em;">
          Wczytywanie danych
        </div>
      </div>
    </div>
    <div v-if="ready">
      <div class="row" v-for="question in viewsChart" :key="question.question_id">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title">Wyświetlenia pytania {{ question.question_id }}</h4>
              <p v-if="questions[question.question_id]" class="category">{{ questions[question.question_id].question_content }}</p>
              <p class="category">{{ chartData.from + ' - ' + chartData.to }}</p>
            </template>
            <bar-chart :labels="question.labels"
                      :height="100"
                      :extra-options="question.options"
                      :datasets="question.datasets">
            </bar-chart>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from 'src/components/UIComponents'
import moment from "moment"; // time manipulation library
import BarChart from 'src/components/UIComponents/Charts/BarChart'

// import {  } from "element-ui";
import { LOAD_DISPLAY_STATS_CHART, DISPLAY_STATS_CHART_INVALID } from "src/store/actions/stats/display_stats_chart";
// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

import * as priv from 'src/privileges.js';

const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
  };

export default {
  components: {
      Card,
      BarChart,
    },
  data() {
    return {
      loading: false,
      // question_id: 388,
      yTicks: {
        min: 0,
        max: 2
      },
      resolution: ''
    }
  },
  props: ['filter', 'date'],
  computed: {
    questions() {
      return this.$store.getters.getQuestionList;
    },
    chartData() {
      let chartData = this.$store.getters.getChartData;
      for(let i in chartData) {
        if(typeof chartData[i] === 'object' && chartData[i] !== null) {
          let questionData = chartData[i];
          let displayData = questionData.display;
          let answerData = questionData.answer;
          let clientData = questionData.clients;
          let displayChartData = [];
          let answerChartData = [];
          let clientChartData = [];
          let labels = [];
          for(let day in displayData) {

            let dayData = displayData[day];
            let hours = Object.keys(dayData).sort((a,b) => a - b);

            let dayAnswerData = undefined;
            let dayClientData = undefined;

            if(answerData) {
              dayAnswerData = answerData[day];
              dayClientData = clientData[day];
              // let hours = Object.keys(dayAnswerData).sort((a,b) => a - b);
            }

            for(let key in hours)
            {
              let hour = hours[key];
              displayChartData.push(dayData[hour]);
              if(dayAnswerData) {
                answerChartData.push(dayAnswerData[hour]);
              } else {
                answerChartData.push(0);
              }
              if(dayClientData) {
                clientChartData.push(dayClientData[hour]);
              } else {
                clientChartData.push(0);
              }
              labels.push(hour);

              if(dayData[hour] > this.yTicks.max) { // określenie najwyższej wartości dla osi Y wykresu
                let divider = 10;
                if(dayData[hour] < 20) {
                  divider = 2;
                } else if(dayData[hour] >= 20 && dayData[hour] < 50) {
                  divider = 10;
                } else if(dayData[hour] >= 50 && dayData[hour] < 200) {
                  divider = 20;
                } else divider = 50;
                this.yTicks.max = Math.ceil(dayData[hour] / divider) * divider;
              }
            }
          }
          // if(answerData) {
          //   for(let day in answerData) {
          //     let dayAnswerData = answerData[day];
          //     let dayClientData = clientData[day];
          //     let hours = Object.keys(dayAnswerData).sort((a,b) => a - b);
          //     for(let key in hours)
          //     {
          //       let hour = hours[key];
          //       answerChartData.push(dayAnswerData[hour]);
          //       clientChartData.push(dayClientData[hour]);
          //     }
          //   }
          // }
          displayData.data = displayChartData;
          if(answerData) {
            answerData.data = answerChartData;
            clientData.data = clientChartData;
          }
          questionData.labels = labels;
        }
      }

      return chartData;
    },
    viewsChart() {
      let chartData = this.chartData;
      let questions = {};

      for(let i in chartData) {
        if(typeof chartData[i] === 'object' && chartData[i] !== null) {
          let data = {};
          data.question_id = i;
          data.priority = chartData[i].priority;
          data.labels = [];
          data.datasets = [];
          data.labels = chartData[i].labels;
          data.datasets = [
            {
              label: "Wyświetlenia",
              borderColor: '#fcc468',
              fill: true,
              backgroundColor: '#fcc468',
              hoverBorderColor: '#fcc468',
              borderWidth: 5,
              data: chartData[i].display.data,
            },
          ];
          if(chartData[i].answer) {
            data.datasets.push({
              label: "Odpowiedzi",
              borderColor: '#4cbdd7',
              fill: true,
              backgroundColor: '#4cbdd7',
              hoverBorderColor: '#4cbdd7',
              borderWidth: 8,
              data: chartData[i].answer.data,
            });
            if(chartData[i].clients_sum > 0) {
              data.datasets.push({
                label: "Sprzedażowe",
                borderColor: '#c94d1c',
                fill: true,
                backgroundColor: '#c94d1c',
                hoverBorderColor: '#c94d1c',
                borderWidth: 8,
                data: chartData[i].clients.data,
              });
            }
          }
          data.options = {
            tooltips: tooltipOptions,
            dataset: {
              maxBarThickness: 100,
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: true,
                },
              }],
              yAxes: [{
                gridLines: {
                  display: true
                },
                ticks: {
                  min: this.yTicks.min,
                  max: this.yTicks.max,
                }
              }]
            }
          }
          questions[i] = data;
        }
      }

      let questions_array = Object.values(questions);
      if(questions_array.length > 0) {
        questions_array.sort((a,b) => b.priority - a.priority);
      }

      return questions_array
    },
    ready() {
      if(this.$store.state.display_stats_chart.loading_status === 'success') {
        return true
      }
      return false
    },
    resolutionTitle() {
      let resolution = this.resolution;
      if(resolution === 'HOUR') {
        return 'Zakres godzinowy';
      } else if(resolution === 'DAY') {
        return 'Zakres dzienny';
      } else if(resolution === 'WEEK') {
        return 'Zakres tygodniowy';
      } else if(resolution === 'MONTH') {
        return 'Zakres miesięczny';
      } else if(resolution === 'YEAR') {
        return 'Zakres roczny';
      } else return '';
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData() {
      this.loading = true;
      this.$store.commit(DISPLAY_STATS_CHART_INVALID);
      let date = this.date;

      let date_from = new Date(date.date_from);
      let date_to = new Date(date.date_to);
      let diff = Math.abs(date_to - date_from)/1000/60/60/24;

      let resolution = '';

      if(diff < 2) {
        resolution = 'HOUR';
      } else if(diff >= 2 && diff < 31) {
        resolution = 'DAY';
      } else if(diff >= 31 && diff < 30 * 6) {
        resolution = 'WEEK';
      } else if(diff >= 30 * 6 && diff < 30 * 12) {
        resolution = 'MONTH';
      } else {
        resolution = 'YEAR';
      }

      this.resolution = resolution;

      const filters = Object.assign({}, date, this.filter);
      filters.resolution = resolution;

      await this.$store.dispatch(LOAD_DISPLAY_STATS_CHART, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
      this.loading = false;
    }
  },
  created() {
    this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData();
      // this.loadStats(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style lang="css" scoped>
.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}
.card {
  margin: 0;
  display: flex;
}
.header-row {
  padding-top: 15px;
  padding-left: 15px;
}
.modal-wrapper {
  position: relative;
}
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
