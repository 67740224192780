<template>
  <div class="conv-wrapper">
    <div v-if="exit" class="main-counter main-counter__left exit-row">
      <div v-if="direction === 'left'" class="arrow-out"></div>
      <b class="exit-counter">{{ getCounter(exit, exit_percentage) }}</b
      ><span style="margin-left: 10px;">Wyjścia</span>
    </div>
    <div v-if="count" :class="`main-counter main-counter__${direction}`">
      <div v-if="direction === 'left'" class="arrow-out"></div>
      <div v-if="direction === 'right'" class="arrow-in"></div>
    </div>
    <appConversionTableComponent v-if="tableData" :tableData="tableData" />
  </div>
</template>
<script>
import utils from "src/utils";

import ConversionTableComponent from "src/components/Utils4/stats/conversion/ConversionTableComponent.vue";

export default {
  components: {
    appConversionTableComponent: ConversionTableComponent,
  },
  props: ["row_data", "exit", "direction", "exit_percentage"],
  data() {
    return {};
  },
  methods: {
    formatNumber(num) {
      return utils.formatNumber(num);
    },
    getCounter(counter, percentage) {
      let result = "";
      result += this.formatNumber(counter);
      if (percentage) {
        result += ` (${percentage}%)`;
      }
      return result;
    },
  },
  computed: {
    tableData() {
      if (this.row_data) {
        return this.row_data.data;
      } else {
        return null;
      }
    },
    count() {
      if (this.row_data) {
        return this.row_data.count;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
.conv-wrapper {
  margin-top: 115px;
}

.main-counter {
  font-size: 1.3em;
  display: flex;
  position: absolute;
  top: 120px;
  margin: 0 10px;
}

.main-counter__right {
  right: -15px;
}

.main-counter__left {
  left: -15px;
}

.exit-row {
  top: 0;
}

.exit-counter {
  color: #1b69bd;
}

.arrow-in {
  margin-left: 8px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #9ce08f;
}

.arrow-out {
  margin-right: 8px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #e06c7b;
}
</style>
