<template>
  <div>
    <div class="row">
      <div class="col-sm-8">
        <h5 id="categorysDictTitle" class="title">Kategorie</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body row">
              <div class="col-sm-8">
              </div>
              <div class="col-sm-4">
                <fg-input class="input-sm"
                          id="categorysDictSearchInput"
                          placeholder="Szukaj"
                          v-model="searchQuery"
                          addon-right-icon="nc-icon nc-zoom-split">
                </fg-input>
              </div>
            </div>
            <el-table :data="queriedData" style="width: 100%">
              <el-table-column label="ID" width="60" property="id"></el-table-column>
              <el-table-column label="Parent ID" width="120" align="center">
                <template v-slot="props">
                  {{ !!props.row.parent_category_id ? props.row.parent_category_id : '-'}}
                </template>
              </el-table-column>
              <el-table-column label="Kategoria" min-width="120">
                <template v-slot="props">
                  <div :id="'categoryDictCategoryNameRow_' + props.row.id" v-bind:class="{'subcat': props.row.parent_category_id !== null, 'parentCat': props.row.parent_category_id === null}">
                    {{ props.row.category_name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="right" v-if="editPermitted" width="120">
                <template slot="header">
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Dodaj kategorię"
                    placement="top"
                    :open-delay="500"
                  >
                    <p-button type="success" icon round @click="showModal('add_category', null)">
                      <i id="categorys_add_button" class="fa fa-plus"></i>
                    </p-button>
                  </el-tooltip>
                </template>
                <template v-slot="props">
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Dodaj podkategorię"
                    placement="top"
                    :open-delay="500"
                  >
                    <p-button
                      v-if="props.row.parent_category_id === null"
                      style="margin: 0 2px;"
                      type="success"
                      size="sm"
                      icon
                      :id="'categorys_add_subcategory_button_' + props.$index"
                      @click="showModal('add_subcategory', props.row)"
                    >
                      <i class="fa fa-plus"></i>
                  </p-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="props.row.parent_category_id === null ? 'Edytuj kategorię' : 'Dodaj podkategorię'"
                    placement="top"
                    :open-delay="500"
                  >
                    <p-button
                      style="margin: 0 2px;"
                      type="success"
                      size="sm"
                      icon
                      :id="'categorys_edit_button_' + props.$index"
                      @click="showModal('edit', props.row)"
                    >
                      <i class="fa fa-edit"></i>
                    </p-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-body row">
            <div class="col-sm-6 pagination-info">
              <p class="category">Pokazuje od {{from + 1}} do {{to}} z {{total}} pozycji</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
// ui components
import { Tag, Tooltip } from "element-ui";
import BackToTop from 'vue-backtotop';
import PPagination from 'src/components/UIComponents/Pagination.vue';

import CategoryDictForm from "src/components/Utils4/dictionaries/CategoryDictForm.vue";

// vuex actions
import { LOAD_CATEGORYS } from "src/store/actions/questionForm/categorys";
import { CATEGORYS_INVALID } from "src/store/actions/questionForm/categorys";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Tag.name]: Tag,
    [Tooltip.name]: Tooltip,
    PPagination,
    BackToTop
  },
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['id', 'parent_category_id', 'category_name']
    }
  },
  computed: {
    tableData() {
      let data = Object.values(JSON.parse(JSON.stringify(this.$store.getters.getCategorys)));
      let result = {};
      for(let i in data) {
        let id = data[i].id;
        let parent_id = data[i].parent_category_id;
        if(parent_id === null) {
          if(result[id] === undefined) {
            result[id] = {};
            result[id].subcategorys = [];
          }
          result[id] = data[i];
        } else {
          if(result[parent_id] === undefined) {
            result[parent_id] = {};
          }
          if(result[parent_id].subcategorys === undefined) {
            result[parent_id].subcategorys = [];
          }
          result[parent_id].subcategorys.push(data[i]);
        }
      }
      let categorys_array = Object.values(result);
      categorys_array.sort((a,b) => (a.category_name.toLowerCase() > b.category_name.toLowerCase()) ? 1 : -1);
      let new_array = [];
      categorys_array.map(parent => {
        if(parent.subcategorys) {
          new_array.push({category_name: parent.category_name, id: parent.id, is_active: parent.is_active, parent_category_id: parent.parent_category_id});
          for(let i in parent.subcategorys) {
            new_array.push(parent.subcategorys[i]);
          }
        } else {
          new_array.push(parent);
        }
      })
      // return Object.values(result);
      return new_array;
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    queriedData () {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.tableData.slice(this.from, this.to)
      }
      let result = this.tableData
        .filter((row) => {
          let isIncluded = false
          for (let key of this.propsToSearch) {
            if(row[key]){
              let rowValue = row[key].toString();
              let rowValueLowerCase = row[key].toString().toLowerCase();
              if ((rowValue.includes && rowValue.includes(this.searchQuery)) || rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
          }

          return isIncluded
        })

      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_CATEGORY_DICT)
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(CATEGORYS_INVALID);
      }

      await this.$store.dispatch(LOAD_CATEGORYS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
    },
    showModal(mode, row) { // wyświetlenie modala kategorii
      this.$modal.show(CategoryDictForm, {
        mode: mode,
        row: row,
        buttons: [{
          title: 'Zamknij',
          handler: () => {
            this.$modal.hide(CategoryDictForm);
          }
        }]
      }, {
        draggable: false,
        width: '50%',
        height: 'auto',
        pivotX: 0.5,
        pivotY: 0.6,
        adaptive: true
      })
    },
  },
  mounted() {
    eventBus.$on('categorySaved', () => { // zapis do obiektu zmian kategorii
      this.loadData();
    });
  },
  async created() {
    await this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style lang="scss" scoped>
  .parentCat {
    font-weight: bold;
    font-size: 1.2em;
  }
  .subcat {
    padding-left: 30px;
    font-style: italic;
  }
</style>
