<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 id="postcodesDictFormTitle" class="title">Dodawanie pliku</h5>
        </div>
        <div>
          <i id="postcodesDictFormCloseButton" v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <span class="btn btn-default btn-file">Wybierz plik
            <input type="file" class="inputfile" id="file" ref="file" v-on:change="handleFileUpload"/>
          </span>
        </div>
        <div class="col-sm-8" v-show="file_name != ''" style="text-align: right; margin: auto;">
          <span>
            Plik: {{ file_name }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">

          <div class="form-group">
          <label>Nazwa</label>
            <fg-input
              type="text"
              name="name"
              data-vv-as="Nazwa"
              v-validate="modelValidations.name"
              :error="getError('name')"
              v-model="model.name"
              :disabled="file_name === ''"
            ></fg-input>
          </div>
        </div>
      </div>

      <div class="row" style="margin: 0;">
        <div class="center">
          <p-button :disabled="file_name === ''" type="success" @click.prevent="saveData()">Zapisz</p-button>
        </div>
        <div class="pull-right" style="align-self: flex-end; margin: 5px;">
          <el-tooltip
              class="item"
              effect="light"
              placement="top"
              :open-delay="1000"
            >
            <div slot="content">Dodawanie pliku:<br>
                      - Akceptowany format pliku to *.txt<br>
                      - Aby kody pocztowe zostały poprawnie przetworzone, muszą znajdować się w pliku tekstowym, a każdy kod powinien znajdować się w nowej linii</div>
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </el-tooltip>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip } from "element-ui";

// vuex actions
import { POSTCODES_INVALID } from "src/store/actions/questionForm/postcodes";
import { SAVE_POSTCODE } from "src/store/actions/questionForm/postcodes";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      model: {
        name: '',
        is_active: 1
      },
      modelValidations: {
        name: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 1,
          max: 32
        },
        is_active: {
          required: true,
          numeric: true,
          min: 0
        },
      },
      file: '',
      file_name: ''
    }
  },
  computed: {

  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    handleFileUpload(e){
      var files = e.target.files
      if (!files.length) return
      this.file = files[0];
      this.file_name = this.file.name;
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      if(!isValid) return;

      if(!this.file) {
        eventBus.$emit("notify", {
          message: "Nie wybrano pliku",
          type: "warning"
        });
        return;
      }

      if(this.file.size > 1024 * 1024) {
        eventBus.$emit("notify", {
          message: "Plik jest za duży (> 1MB)",
          type: "warning"
        });
        return;
      }

      let allowed_extensions = new Array('txt');
      let file_extension = this.file.name.split('.').pop().toLowerCase();

      if(!allowed_extensions.includes(file_extension))
      {
        eventBus.$emit("notify", {
          message: "Niedozwolone rozszerzenie",
          type: "warning"
        });
        return; // invalid file extension
      }

      // Initialize the form data
      let formData = new FormData();
      // Add the form data we need to submit
      formData.append('file', this.file, this.model.name);

      await this.$store.dispatch(SAVE_POSTCODE, formData).catch(error => {
        let message = ''
        console.log(error.response)
        switch(error.response.data.code) {
          case 409:
            message = 'Niepoprawny format pliku!'
            break;
          default:
            message = "Błąd zapisu danych!"
        }
        eventBus.$emit("notify", {
          message: message,
          type: "warning"
        });
      }); // wyślij pliki na serwer

      let saveResult = this.$store.getters.getPostcodesModifiedRecords;
      if (saveResult > 0 ) {
        eventBus.$emit("notify", {
          message: `Dodano rekordów: ${saveResult}`,
          type: "success"
        });

        this.$store.commit(POSTCODES_INVALID) // oznacz nieaktualność danych
        eventBus.$emit('postcodesSaved');
        this.$emit('close');
      }
    },
  },
  async created() {
  },
};
</script>

<style lang="scss" scoped>
  .row {
    margin: 10px;
  }
  .card {
    margin: 0;
    display: flex;
  }
  .row:last-child {
    justify-content: center;
  }
  .center {
    display: flex;
    margin: auto;
    justify-content: center;
    align-content: center;
  }
  .input-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .btn-file {
    position: relative;
    overflow: hidden;
  }
  .btn-file input[type=file] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
  }
  .header_row {
    display: flex;
    justify-content: space-between;
  }
</style>
