<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <tabs pills icons centered tab-content-classes="tab-space">
          <tab-pane title="Pytania" key="questions">
            <span id="QuestionsConfigurationQuestionsPane" slot="title">
                <i class="fa fa-question"></i> Pytania
            </span>
            <app-question-configuration></app-question-configuration>
          </tab-pane>
          <tab-pane title="Projekt" key="project">
            <span id="QuestionsConfigurationProjectPane" slot="title">
                <i class="fa fa-laptop"></i> Projekty
            </span>
            <app-project-configuration></app-project-configuration>
          </tab-pane>
          <tab-pane title="Limity" key="limits">
            <span id="QuestionsConfigurationLimitsPane" slot="title">
                <i class="fa fa-tachometer"></i> Limity
            </span>
            <app-limits-configuration></app-limits-configuration>
            </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

// ui components
import { Tabs, TabPane, Card } from 'src/components/UIComponents'

import QuestionConfiguration from './forms/QuestionConfiguration.vue'
import ProjectConfiguration from './forms/ProjectConfiguration.vue'
import LimitsConfiguration from './forms/LimitsConfiguration.vue'

export default {
  components: {
    appQuestionConfiguration: QuestionConfiguration,
    appProjectConfiguration: ProjectConfiguration,
    appLimitsConfiguration: LimitsConfiguration,
    TabPane,
    Tabs,
    Card
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  created() {
  }
};
</script>

<style lang="scss" scoped>

</style>
