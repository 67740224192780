import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_SYSTEM_PARAMS,
  SYSTEM_PARAMS_LOADING,
  SYSTEM_PARAMS_LOADING_SUCCESS,
  SYSTEM_PARAMS_LOADING_ERROR,
  SYSTEM_PARAMS_INVALID,
  SAVE_SYSTEM_PARAM,
  SYSTEM_PARAM_SAVING,
  SYSTEM_PARAM_SAVING_SUCCESS,
  SYSTEM_PARAM_SAVING_ERROR
} from 'src/store/actions/admin/system_params'

const storeObjectConfig = {
  objectName: "SystemParams",
  getterName: "getSystemParams",
  loadActionName: LOAD_SYSTEM_PARAMS,
  loadingMutationName: SYSTEM_PARAMS_LOADING,
  loadingSuccessMutationName: SYSTEM_PARAMS_LOADING_SUCCESS,
  loadingErrorMutationName: SYSTEM_PARAMS_LOADING_ERROR,
  invalidMutationName: SYSTEM_PARAMS_INVALID,
  saveActionName: SAVE_SYSTEM_PARAM,
  savingMutationName: SYSTEM_PARAM_SAVING,
  savingSuccessMutationName: SYSTEM_PARAM_SAVING_SUCCESS,
  savingErrorMutationName: SYSTEM_PARAM_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/systemParams",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/systemParams",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
