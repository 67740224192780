<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4">
            <label for="project">Pytania</label>
            <el-select
              id="QuestionsEfficiencyQuestionSelect"
              v-on:visible-change="getUpperSelectWidth()"
              v-model="questionArray"
              name="question_id"
              data-vv-as="Pytanie"
              style="width: 100%"
              multiple
              filterable
              clearable
            >
              <el-option
                :style="upperSelectWidth !== null ? 'width: ' + upperSelectWidth + 'px' : 'width: 300px'"
                v-for="question in questionList"
                :id="'QuestionsEfficiencyQuestionSelectOption_' + question.id"
                :label="'[' + question.id + '] ' + question.question_content"
                :value="question.id"
                :key="question.id"
              ></el-option>
            </el-select>
          </div>
          <div class="col-sm-3">
            <label style="display: block; margin-bottom: 7px">Baner</label>
            <el-select
              style="width: 100%"
              v-model="bannersArray"
              name="banners"
              filterable
              remote
              reserve-keyword
              clearable
              multiple
              :remote-method="remoteSearch"
              :loading="loading"
            >
              <el-option
                v-for="banner in bannerConfig"
                :label="'[' + banner.BannerID + '] ' + banner.BannerName"
                :value="banner.BannerID"
                :key="banner.BannerID"
              ></el-option>
            </el-select>
          </div>
          <div class="col-sm-5 right" style="margin-top: 25px">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="-"
              start-placeholder="Data początkowa"
              end-placeholder="Data końcowa"
              :picker-options="pickerOptions"
              :clearable="false"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
            ></el-date-picker>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-md-4">
            <label style="display: block; margin-bottom: 7px">Projekt</label>
            <el-select
              v-on:clear="clearVersions()"
              v-on:remove-tag="removeVersion($event)"
              v-model="projects"
              name="projects"
              filterable
              clearable
              multiple
              style="width: 100%"
            >
              <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                <el-option
                  v-for="item in group.options"
                  :key="item.project_id"
                  :label="item.project_name + ' [' + item.project_id + ']'"
                  :value="item.project_id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </div>
          <div class="col-md-3">
            <label style="display: block; margin-bottom: 7px">Wersja</label>
            <el-select v-model="versions" name="versions" filterable clearable multiple style="width: 100%">
              <el-option-group v-for="group in projectModules" :key="group.label" :label="group.label">
                <el-option
                  v-for="version in group.options"
                  :label="version.module + ' [' + version.tags + ']'"
                  :value="version.module"
                  :key="version.module"
                ></el-option>
              </el-option-group>
            </el-select>
          </div>
          <div class="col"></div>
          <div class="col-sm-auto">
            <p-button style="margin-bottom: 0; margin-top: 25px" type="success" @click="loadData">Pokaż</p-button>
          </div>
        </div>
      </div>
      <hr />
      <h6>
        <div class="card-footer" style="display: flex; justify-content: space-between">
          <span>Przedział dat:</span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </div>
      </h6>
    </div>

    <div class="card">
      <div class="card-body row">
        <el-table
          :data="tableData"
          style="width: 100%"
          @cell-click="cellClick"
          @cell-dblclick="cellDoubleClick"
          :row-style="rowStyle"
          :header-cell-style="headerStyle"
          size="mini"
        >
          <el-table-column label="ID" property="question_id" width="80" align="center">
            <template v-slot="props">
              <div v-if="props.row.mode === 'question'">
                <el-tooltip v-if="props.row.is_subquestion" class="item" effect="light" content="Podpytanie" placement="top" :open-delay="500">
                  <i class="fa fa-level-up" aria-hidden="true"></i>
                </el-tooltip>
                {{ props.row.question_id }}
              </div>
              <div v-else-if="props.row.mode === 'project'">
                {{ props.row.project_id }}
              </div>
              <div v-else-if="props.row.mode === 'version'">
                {{ "" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Treść" property="question_content" align="left" min-width="200">
            <template v-slot="props">
              <div v-if="props.row.mode === 'question'" style="cursor: pointer; word-break: keep-all">
                {{ props.row.question_content }}
              </div>
              <div v-else-if="props.row.mode === 'project'" style="font-weight: bold; cursor: pointer; padding-left: 20px; word-break: keep-all">
                {{ props.row.project_name }}
              </div>
              <div v-else-if="props.row.mode === 'version'" style="padding-left: 40px; word-break: keep-all">
                {{ props.row.version }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Kanał"
            property="channel"
            width="100"
            align="center"
            :filter-multiple="false"
            :filters="this.filterTypes"
            :filter-method="filterHandler"
          >
            <template v-slot="props">
              <span v-if="props.row.mode === 'question'">{{ props.row.channel }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Wyśw" property="displayed" align="right" width="100" sortable>
            <template slot="header">
              <el-tooltip class="item" effect="light" placement="top" content="Wyświetlenia" :open-delay="500">
                <span>Wyśw</span>
              </el-tooltip>
            </template>
            <template v-slot="props">
              {{ props.row.displayed | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="Sprz" property="conversion_rate" align="right" width="100" sortable>
            <template slot="header">
              <el-tooltip class="item" effect="light" placement="top" content="Sprzedaże" :open-delay="500">
                <span>Sprz</span>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="formatNumber(props.row.sold)" placement="left" :open-delay="500">
                <span>{{ getConversion(props.row.sold, props.row.displayed) }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="$ Hot" property="income_hot" align="right" width="100" sortable>
            <template slot="header">
              <el-tooltip class="item" effect="light" placement="top" content="Przychód Hot" :open-delay="500">
                <span>$ Hot</span>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="formatNumber(props.row.income_hot, 2) + ' zł'" placement="left" :open-delay="500">
                <span>{{ formatNumber(props.row.income_hot) + " zł" }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="$ Warm" property="income_warm" align="right" width="100" sortable>
            <template slot="header">
              <el-tooltip class="item" effect="light" placement="top" content="Przychód Warm" :open-delay="500">
                <span>$ Warm</span>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="formatNumber(props.row.income_warm, 2) + ' zł'" placement="left" :open-delay="500">
                <span>{{ formatNumber(props.row.income_warm) + " zł" }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Rent" property="efficiency" align="right" width="100" sortable>
            <template slot="header">
              <el-tooltip class="item" effect="light" placement="top" content="Rentowność (income/display*1000)" :open-delay="500">
                <span>RENT</span>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" placement="left" :content="formatNumber(props.row.efficiency, 2) + ' zł'" :open-delay="500">
                <span>{{ formatNumber(props.row.efficiency) + " zł" }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Status" property="is_active" align="center" width="100">
            <template v-slot="props">
              <el-tag
                v-if="props.row.mode === 'question' && props.row.is_subquestion === 0"
                effect="dark"
                :type="tagType(props.row.is_active)"
                size="small"
                >{{ props.row.is_active }}</el-tag
              >
              <div v-else>-</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <p class="text-muted">
      <i>Sortowanie pytań po ID. Rozwijanie projektów i wersji po kliknięciu w kolumnę treści.</i>
    </p>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";

import { DatePicker, Select, Option, OptionGroup, Tooltip, Tag } from "element-ui";

import { LOAD_QUESTIONS_EFFICIENCY_QUESTIONS, QUESTIONS_EFFICIENCY_QUESTIONS_INVALID } from "src/store/actions/secure/questions_efficiency_questions";
import { LOAD_QUESTION_LIST, QUESTION_LIST_INVALID } from "src/store/actions/questions/question_list";
import { LOAD_BANNER_CONFIG, BANNER_CONFIG_INVALID } from "src/store/actions/stats/banner_config";
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [Tag.name]: Tag,
  },
  data() {
    return {
      projectBoolObject: {},
      referenceCounter: 0,
      counter: 0,
      loading: false,
      questionArray: [],
      bannersArray: [],
      projects: [],
      versions: [],
      dateRange: ["", ""],
      versionProjectObject: {},
      filterTypes: [
        { text: "MAILING", value: "MAILING" },
        { text: "DISPLAY", value: "DISPLAY" },
        { text: "INNE", value: "INNE" },
      ],
      upperSelectWidth: null,
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    async initLoad(force) {
      this.loading = true;

      this.$store.commit(QUESTION_LIST_INVALID);
      this.$store.commit(PROJECT_DICT_INVALID);
      this.$store.commit(PROJECTS_ALL_INVALID);

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store
        .dispatch(LOAD_QUESTION_LIST, {
          subquestion: true,
          question_types: ["CO-REGISTRATION", "PROFILE", "QUIZ"],
        })
        .catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

      this.loading = false;
    },
    async loadData() {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        question_list: this.questionArray,
        banner_list: this.bannersArray,
        project_list: this.projects,
        versions: this.versions,
      };

      this.$store.commit(QUESTIONS_EFFICIENCY_QUESTIONS_INVALID);

      await this.$store.dispatch(LOAD_QUESTIONS_EFFICIENCY_QUESTIONS, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.createBoolObjects();

      this.loading = false;
    },
    getDetailedDate(date) {
      moment.locale("pl");
      const dateObj = Date.parse(date);
      return moment(dateObj).format("dddd, Do MMMM YYYY");
    },
    formatNumber(number, decimals = 0) {
      let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number).toFixed(decimals).split(".");
      n[0] = n[0]
        .split("")
        .reverse()
        .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
        .reverse()
        .join("");
      let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

      return final;
    },
    cellClick(row, column) {
      let columnName = column.property;
      let qid = row.question_id;
      let ch = row.channel;
      let pid = row.project_id;
      if (columnName === "question_content" && row.mode !== "version") {
        this.projectBoolObject[qid + "_" + ch + "_" + pid] = !this.projectBoolObject[qid + "_" + ch + "_" + pid];
        this.counter++;
      }
    },
    cellDoubleClick(row, column) {
      let columnName = column.property;
      if (columnName === "question_content") {
        for (let key in this.projectBoolObject) {
          this.projectBoolObject[key] = false;
        }
        this.counter++;
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (row.mode === "question") {
        if (columnIndex === 0) {
          return {
            rowspan: row.col_span,
            colspan: 1,
          };
        }
        if (columnIndex === 1) {
          return {
            rowspan: row.col_span,
            colspan: 1,
          };
        }
        if (columnIndex === 2) {
          return {
            rowspan: row.col_span,
            colspan: 2,
          };
        } else if (columnIndex === 3) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    rowStyle({ row }) {
      if (row.mode === "version") {
        return "background-color: #fffaf0";
      } else if (row.mode === "question") {
        return "background-color: #f0f8ff;";
      }
      return "font-weight: bold;";
    },
    headerStyle() {
      return "font-size: 12px;";
    },
    createBoolObjects() {
      let data = this.efficiencyRaport;
      let projectBoolObject = this.projectBoolObject;
      for (let qid in data) {
        for (let channel in data[qid]) {
          projectBoolObject[qid + "_" + channel + "_ALL_PROJECTS"] = false;
          const projects = data[qid][channel].projects;

          for (let pid in projects) {
            projectBoolObject[qid + "_" + channel + "_" + pid] = false;
          }
        }
      }
      this.counter++;
    },
    getEfficiency(income, display) {
      const result = (income / display) * 1000;
      return result;
    },
    getConversion(sales, display) {
      const result = this.formatNumber((sales / display) * 100, 2) + "%";
      return result;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    getUpperSelectWidth() {
      this.upperSelectWidth = document.getElementById("QuestionsEfficiencyQuestionSelect").offsetWidth;
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, { searchQuery }).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    clearVersions() {
      this.versions = [];
    },
    removeVersion(project_id) {
      let versions = this.versions;
      let filteredVersions = [];
      for (let i in versions) {
        if (this.versionProjectObject[versions[i]] !== project_id) {
          filteredVersions.push(versions[i]);
        }
      }
      this.versions = filteredVersions;
    },
    tagType(value) {
      // przypisanie tagów
      switch (value) {
        case "ACTIVE":
          return "success";
        case "INACTIVE":
          return "warning";
        case "ARCHIVE":
          return "danger";
        default:
          return "info";
      }
    },
  },
  computed: {
    tableData() {
      const raport = this.efficiencyRaport;
      let tableData = [];

      let counter = this.counter;
      let referenceCounter = this.referenceCounter;
      if (counter !== referenceCounter) {
        // licznik wymuszający przeliczenie tabeli
        for (let i in raport) {
          const channel = raport[i];

          for (let channel_name in channel) {
            const question = channel[channel_name];

            const question_id = question.question_id;
            const question_content = question.question_content;
            const projects = question.projects;

            tableData.push({
              mode: "question",
              channel: channel_name,
              question_id: question_id,
              question_content: question_content,
              is_subquestion: question.is_subquestion,
              project_id: "ALL_PROJECTS",
              project_name: "",
              version: "ALL_MODULES",
              priority: question.ALL_PROJECTS.ALL_VERSIONS.priority,
              displayed: question.ALL_PROJECTS.ALL_VERSIONS.displayed,
              sold: question.ALL_PROJECTS.ALL_VERSIONS.sold,
              income_hot: question.ALL_PROJECTS.ALL_VERSIONS.income_hot,
              income_warm: question.ALL_PROJECTS.ALL_VERSIONS.income_warm,
              efficiency: this.getEfficiency(
                question.ALL_PROJECTS.ALL_VERSIONS.income_hot + question.ALL_PROJECTS.ALL_VERSIONS.income_warm,
                question.ALL_PROJECTS.ALL_VERSIONS.displayed,
              ),
              conversion_rate: question.ALL_PROJECTS.ALL_VERSIONS.sold / question.ALL_PROJECTS.ALL_VERSIONS.displayed,
              is_active: question.is_active,
            });

            if (this.projectBoolObject[question_id + "_" + channel_name + "_ALL_PROJECTS"]) {
              for (let j in projects) {
                const project = projects[j].ALL_VERSIONS;
                const project_id = projects[j].ALL_VERSIONS.project_id;

                tableData.push({
                  mode: "project",
                  channel: channel_name,
                  question_id: question_id,
                  question_content: question_content,
                  is_subquestion: 0,
                  project_id: project_id,
                  project_name: project.project_name,
                  version: "ALL_MODULES",
                  priority: project.priority,
                  displayed: project.displayed,
                  sold: project.sold,
                  income_hot: project.income_hot,
                  income_warm: project.income_warm,
                  efficiency: this.getEfficiency(project.income_hot + project.income_warm, project.displayed),
                  conversion_rate: project.sold / project.displayed,
                });

                if (this.projectBoolObject[question_id + "_" + channel_name + "_" + project_id]) {
                  for (let module_name in projects[j].modules) {
                    const version = projects[j].modules[module_name];

                    tableData.push({
                      mode: "version",
                      channel: channel_name,
                      question_id: question_id,
                      question_content: question_content,
                      is_subquestion: 0,
                      project_id: project.project_id,
                      project_name: project.project_name,
                      version: module_name,
                      priority: version.priority,
                      displayed: version.displayed,
                      sold: version.sold,
                      income_hot: version.income_hot,
                      income_warm: version.income_warm,
                      efficiency: this.getEfficiency(version.income_hot + version.income_warm, version.displayed),
                      conversion_rate: version.sold / version.displayed,
                    });
                  }
                }
              }
            }
          }
        }
        referenceCounter += 1;
      }
      return tableData;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    efficiencyRaport() {
      let data = {};
      if (this.$store.state.questions_efficiency_questions.loading_status === "success") {
        data = JSON.parse(JSON.stringify(this.$store.getters.getQuestionsEfficiencyQuestions));
        return data;
      }
      return data;
    },
    questionList() {
      return this.$store.getters.getQuestionList;
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
    projectsGetter() {
      return this.$store.getters.getProjectDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    projectModules() {
      // wszystkie moduły projektu
      if (Array.isArray(this.projects) && this.projects.length > 0) {
        let projects = this.$store.getters.getProjectsAll;
        let activeProjects = this.projects;
        let filteredData = {};
        let temp = {};
        const sortedData = {};
        for (let i in activeProjects) {
          for (let [key, project] of Object.entries(projects)) {
            // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
            if (project.project_id === activeProjects[i]) {
              temp[project.module] = Object.assign({}, project);
              filteredData[project.project_id] = Object.assign({}, temp);
            }
          }
          temp = {};
        }

        let sourceData = [];

        for (let i in filteredData) {
          // sortowanie danych
          let tempData = filteredData[i];
          let sourceData = {};
          for (let ver of Object.keys(tempData)) {
            // iteracja po nazwach modułów danego projektu
            let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie pierwszej liczby
            filteredData[i][ver].number = res;
          }
        }

        let resultModules = [];

        for (let project_id in filteredData) {
          let projectModules = filteredData[project_id];
          sourceData = Object.values(projectModules);
          sourceData.sort((a, b) => a.number - b.number);
          let result = {};
          for (let i in sourceData) {
            this.versionProjectObject[sourceData[i].module] = parseInt(project_id);
            result[sourceData[i].module] = sourceData[i];
          }
          resultModules.push({
            project_id,
            project_id,
            label: this.projectsGetter[project_id].project_name,
            options: result,
          });
        }

        return resultModules;
      }
      return [];
    },
  },
  mounted() {},
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
      this.initLoad(true);
    });
    this.initLoad(false);
  },
  beforeDestroy: function () {
    this.$store.commit(QUESTIONS_EFFICIENCY_QUESTIONS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.right-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.left-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
</style>

<style></style>
