import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_ANSWER_CLIENT,
  ANSWER_CLIENT_LOADING,
  ANSWER_CLIENT_LOADING_SUCCESS,
  ANSWER_CLIENT_LOADING_ERROR,
  ANSWER_CLIENT_INVALID,
  SAVE_ANSWER_CLIENT,
  ANSWER_CLIENT_SAVING,
  ANSWER_CLIENT_SAVING_SUCCESS,
  ANSWER_CLIENT_SAVING_ERROR
} from '../../actions/questionForm/answer_client'

const storeObjectConfig = {
  objectName: "Questions",
  getterName: "getAnswerClient",
  loadActionName: LOAD_ANSWER_CLIENT,
  loadingMutationName: ANSWER_CLIENT_LOADING,
  loadingSuccessMutationName: ANSWER_CLIENT_LOADING_SUCCESS,
  loadingErrorMutationName: ANSWER_CLIENT_LOADING_ERROR,
  invalidMutationName: ANSWER_CLIENT_INVALID,
  saveActionName: SAVE_ANSWER_CLIENT,
  savingMutationName: ANSWER_CLIENT_SAVING,
  savingSuccessMutationName: ANSWER_CLIENT_SAVING_SUCCESS,
  savingErrorMutationName: ANSWER_CLIENT_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/answers/answer_client",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/questions/answers/answer_client",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
