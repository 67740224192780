export const BANERS_EFFICIENCY_LOAD = "BANERS_EFFICIENCY_LOAD";
export const BANERS_EFFICIENCY_LOADING = "BANERS_EFFICIENCY_LOADING";
export const BANERS_EFFICIENCY_LOADING_SUCCESS = "BANERS_EFFICIENCY_LOADING_SUCCESS";
export const BANERS_EFFICIENCY_LOADING_ERROR = "BANERS_EFFICIENCY_LOADING_ERROR";
export const BANERS_EFFICIENCY_INVALID = "BANERS_EFFICIENCY_INVALID";

export const BANERS_EFFICIENCY_GETTER_NAME = "BANERS_EFFICIENCY_GETTER_NAME";
export const BANERS_EFFICIENCY_OB_NAME = "BANERS_EFFICIENCY_OB_NAME";
export const BANERS_EFFICIENCY_EXCEL_DISPLAY = "BANERS_EFFICIENCY_EXCEL_DISPLAY";
export const BANERS_EFFICIENCY_EXCEL_DISPLAY_INVALID = "BANERS_EFFICIENCY_EXCEL_DISPLAY_INVALID";
