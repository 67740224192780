export const LOAD_DISPLAY_COSTS = 'LOAD_DISPLAY_COSTS'
export const DISPLAY_COSTS_LOADING = 'DISPLAY_COSTS_LOADING'
export const DISPLAY_COSTS_LOADING_SUCCESS = 'DISPLAY_COSTS_LOADING_SUCCESS'
export const DISPLAY_COSTS_LOADING_ERROR = 'DISPLAY_COSTS_LOADING_ERROR'
export const DISPLAY_COSTS_INVALID = 'DISPLAY_COSTS_INVALID'

export const SAVE_DISPLAY_COSTS = 'SAVE_DISPLAY_COSTS'
export const DISPLAY_COSTS_SAVING = 'DISPLAY_COSTS_SAVING'
export const DISPLAY_COSTS_SAVING_SUCCESS = 'DISPLAY_COSTS_SAVING_SUCCESS'
export const DISPLAY_COSTS_SAVING_ERROR = 'DISPLAY_COSTS_SAVING_ERROR'

export const DELETE_DISPLAY_COSTS = 'DELETE_DISPLAY_COSTS'
export const DISPLAY_COSTS_DELETING = 'DISPLAY_COSTS_DELETING'
export const DISPLAY_COSTS_DELETING_SUCCESS = 'DISPLAY_COSTS_DELETING_SUCCESS'
export const DISPLAY_COSTS_DELETING_ERROR = 'DISPLAY_COSTS_DELETING_ERROR'