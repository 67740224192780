<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title" v-if="mode === 'search'">Wyszukaj dane użytkownika</h5>
          <h5 class="title" v-else-if="mode === 'delete'">Usuń dane użytkownika</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <div class="form-group">
            <label>Telefon</label>
            <fg-input :error="getError('phone')" data-vv-as="Telefon">
              <el-select
                v-model="model.phone"
                name="phone"
                data-vv-as="Telefon"
                v-validate="modelValidations.phone"
                filterable
                allow-create
                clearable
              >
                <el-option v-for="record in phone" :label="record" :value="record" :key="record"></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>

        <div class="col-sm-1 helper">
          <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
            <div slot="content">Aby dodać ręcznie numer należy wpisać go w danym polu i wybrać kliknięciem</div>
            <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
          </el-tooltip>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <div class="form-group">
            <label>Mail</label>
            <fg-input :error="getError('mail')" data-vv-as="Mail">
              <el-select v-model="model.mail" name="mail" data-vv-as="Mail" v-validate="modelValidations.mail" filterable allow-create clearable>
                <el-option v-for="record in mail" :label="record" :value="record" :key="record"></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>

        <div class="col-sm-1 helper">
          <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
            <div slot="content">Aby dodać ręcznie email należy wpisać go w danym polu i wybrać kliknięciem</div>
            <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
          </el-tooltip>
        </div>
      </div>
      <div class="row" v-if="mode === 'search'">
        <p-button :disabled="!checkData" type="success" style="margin-right: 5px;" @click="handleData()">Dane</p-button>
        <p-button :disabled="!checkData" type="success" @click="handleHistory()">Historia</p-button>
        <p-button :disabled="!checkData" type="success" style="margin-right: 5px;" @click="handleCheck()">Sprzedaże</p-button>
      </div>
      <div class="row" v-else-if="mode === 'check'">
        <p-button :disabled="!checkData" type="success" @click="handleCheck()">Sprawdź</p-button>
      </div>
      <div class="row" v-else-if="mode === 'delete'">
        <p-button style="margin-right: 5px;" link @click="$emit('close')">Anuluj</p-button>
        <p-button :disabled="!checkData" style="margin-right: 5px;" type="info" @click="unsubscribeLead()">Wypisz</p-button>
        <p-button :disabled="!checkData" type="danger" @click="deleteLead()">Usuń</p-button>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip } from "element-ui";

// vuex actions
// import { SAVE_CATEGORY } from "src/store/actions/questionForm/categorys";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

import UserSales from "src/components/Utils4/complaints/views/UserSales.vue";

import { SAVE_BLACKLIST_LEAD } from "src/store/actions/complaints/actions/blacklist_lead";
import { SAVE_UNSUBSCRIBE_LEAD } from "src/store/actions/blacklist/unsubscribe_lead";
import { SAVE_SET_API_CALL } from "src/store/actions/complaints/actions/set_api_call";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },
  props: ["phone", "mail", "mailbox", "company", "complaintID", "mode"],
  data() {
    return {
      is_test: false,
      model: {
        phone: "",
        mail: "",
      },
      modelValidations: {
        phone: {
          numeric: true,
          min: 9,
          max: 11,
        },
        mail: {
          regex: regexp.EMAIL_REGEXP,
          min: 1,
          max: 128,
        },
      },
    };
  },
  computed: {
    checkData() {
      if (this.model.mail === "" && this.model.phone === "") return false;
      else return true;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    handleData() {
      this.$emit("close");
      this.$router.push({
        name: "Dane użytkownika",
        params: {
          phone: this.model.phone,
          mail: this.model.mail,
          company: this.company,
          mailbox: this.mailbox,
          complaintID: this.complaintID,
        },
      });
    },
    handleHistory() {
      this.$emit("close");
      this.$router.push({
        name: "Historia użytkownika",
        params: {
          phone: this.model.phone,
          mail: this.model.mail,
          company: this.company,
          mailbox: this.mailbox,
          complaintID: this.complaintID,
        },
      });
    },
    handleCheck() {
      const phone = this.model.phone.length == 9 ? "48" + this.model.phone : this.model.phone;

      this.$emit("close");
      console.log(this.model);
      this.$modal.show(
        UserSales,
        {
          phone: phone,
          mail: this.model.mail,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(UserSales);
              },
            },
          ],
        },
        {
          draggable: false,
          scrollable: true,
          width: "500px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    async setAPICall(data) {
      let result = null;

      await this.$store.dispatch(SAVE_SET_API_CALL, data).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getSetAPICallModifiedRecords) {
        result = this.$store.getters.getSetAPICallModifiedRecords;
      }

      return result;
    },
    async unsubscribeLead() {
      const apiCallData = {
        user_email: this.model.mail,
        user_msisdn: null,
        complaint_id: this.complaintID,
        operation_type: "UNSUBSCRIBE",
      };

      let complaint_flow_id = await this.setAPICall(apiCallData);

      if (!complaint_flow_id) {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
        return;
      }

      const model = {
        user_email: this.model.mail,
        unsubscribe_type: "email",
        is_test: this.is_test,
        complaint_id: this.complaintID,
        complaint_flow_id: complaint_flow_id,
        incomplete_data_exception: true,
        system_user_id: this.$store.state.profile.data.ID,
        token: this.$store.state.auth.token,
      };

      console.log(model);

      await this.$store.dispatch(SAVE_UNSUBSCRIBE_LEAD, model).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getUnsubscribeLeadModifiedRecords.unsubscribe_counter) {
        eventBus.$emit("notify", {
          message: `Zlecono wypisanie użytkownika`,
          type: "success",
        });
        eventBus.$emit("handleLeadCompleted");
        this.$emit("close");
      }
    },
    async deleteLead() {
      const phone = this.model.phone.length == 9 ? "48" + this.model.phone : this.model.phone;

      const apiCallData = {
        user_email: this.model.mail,
        user_msisdn: phone !== "" ? phone : null,
        complaint_id: this.complaintID,
        operation_type: "DELETE",
      };

      let complaint_flow_id = await this.setAPICall(apiCallData);

      if (!complaint_flow_id) {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
        return;
      }

      const model = {
        user_email: this.model.mail,
        is_test: this.is_test,
        complaint_id: this.complaintID,
        complaint_flow_id: complaint_flow_id,
        system_user_id: this.$store.state.profile.data.ID,
        token: this.$store.state.auth.token,
      };

      if (phone !== "") {
        model.user_msisdn = phone;
      }

      console.log(model);

      await this.$store.dispatch(SAVE_BLACKLIST_LEAD, model).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getBlacklistLeadModifiedRecords.transaction_id) {
        eventBus.$emit("notify", {
          message: "Zlecono usunięcie użytkownika",
          type: "success",
        });
        eventBus.$emit("handleLeadCompleted");
        this.$emit("close");
      }
    },
  },
  async created() {
    if (this.phone.length > 0) {
      this.model.phone = this.phone[0];
    }
    if (this.mail.length > 0) {
      this.model.mail = this.mail[0];
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}

.formButton {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.helper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
</style>
