import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_EVENTS_RAPORT,
  EVENTS_RAPORT_LOADING,
  EVENTS_RAPORT_LOADING_SUCCESS,
  EVENTS_RAPORT_LOADING_ERROR,
  EVENTS_RAPORT_INVALID,
} from "src/store/actions/raports/events_raport";

const storeObjectConfig = {
  objectName: "EventsRaport",
  getterName: "getEventsRaport",
  loadActionName: LOAD_EVENTS_RAPORT,
  loadingMutationName: EVENTS_RAPORT_LOADING,
  loadingSuccessMutationName: EVENTS_RAPORT_LOADING_SUCCESS,
  loadingErrorMutationName: EVENTS_RAPORT_LOADING_ERROR,
  invalidMutationName: EVENTS_RAPORT_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/raports/eventsRaport",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
