import storeGen from "../../../storeGenerator";

import { AUTH_LOGOUT } from "../../../actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_BLACKLIST_OWNER,
  BLACKLIST_OWNER_LOADING,
  BLACKLIST_OWNER_LOADING_SUCCESS,
  BLACKLIST_OWNER_LOADING_ERROR,
  BLACKLIST_OWNER_INVALID,
} from "../../../actions/complaints/dict/blacklist_owner";

const storeObjectConfig = {
  objectName: "BlacklistOwner",
  getterName: "getBlacklistOwner",
  loadActionName: LOAD_BLACKLIST_OWNER,
  loadingMutationName: BLACKLIST_OWNER_LOADING,
  loadingSuccessMutationName: BLACKLIST_OWNER_LOADING_SUCCESS,
  loadingErrorMutationName: BLACKLIST_OWNER_LOADING_ERROR,
  invalidMutationName: BLACKLIST_OWNER_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/complaints/blacklist/owner",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
