<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="card-body row">
          <div class="col-sm-4">
            <fg-input :error="getError('project_id')" data-vv-as="Projekt">
              <el-select
                :disabled="ifEdit"
                id="projectConfigurationProjectSelect"
                v-on:visible-change="getUpperLeftSelectWidth()"
                v-model="project_id"
                name="project_id"
                data-vv-as="Projekt"
                filterable
                v-on:input="changeProject($event)"
              >
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    :style="upperLeftSelectWidth !== null ? 'width: ' + upperLeftSelectWidth + 'px' : 'width: 300px'"
                    :id="'projectConfigurationProjectSelectOption_' + item.project_id"
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-2">
            <fg-input :error="getError('version')" data-vv-as="Wersja">
              <el-select
                :disabled="ifEdit"
                id="projectConfigurationVersionSelect"
                v-on:visible-change="getUpperRightSelectWidth()"
                v-model="version"
                name="version"
                data-vv-as="Wersja"
                filterable
                clearable
              >
                <el-option
                  :style="upperRightSelectWidth !== null ? 'width: ' + upperRightSelectWidth + 'px' : 'width: 300px'"
                  v-for="version in activeProjectModules"
                  :id="'projectConfigurationVersionSelectOption_' + version.module"
                  :label="version.module"
                  :value="version.module"
                  :key="version.module"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-3" align="center">
            <p-button type="success" size="medium" :inline="true" @click="loadData(true, false)" style="margin-top: 0; margin-right: 10px">
              Wczytaj
            </p-button>
            <p-button
              v-if="editPermitted"
              :type="ifEdit ? 'warning' : 'success'"
              size="medium"
              :inline="true"
              @click="changeEdit()"
              style="margin-top: 0"
            >
              {{ ifEdit ? "Anuluj" : "Edycja" }}
            </p-button>
          </div>
          <div class="col-sm-3">
            <fg-input
              class="input-sm"
              id="projectConfigurationSearchInput"
              placeholder="Szukaj"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>

          <div v-if="recordObject.insert_question.length > 0 || recordObject.delete_question.length > 0" class="col-sm-12" align="center">
            <p-button type="danger" link @click="takeToBottom()"
              ><i class="fa fa-arrow-down" aria-hidden="true"></i>Zapisz wprowadzone zmiany<i class="fa fa-arrow-down" aria-hidden="true"></i
            ></p-button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-left: 15px">
            <el-checkbox-group v-model="questionFilters">
              <el-checkbox-button v-for="filter in questionFiltersArray" :label="filter" :key="filter">{{
                questionFiltersLabels[filter]
              }}</el-checkbox-button>
            </el-checkbox-group>
          </div>
        </div>
        <el-table :data="queriedData" stripe style="width: 100%" @sort-change="sortChange" v-on:filter-change="changeFilter($event)">
          <el-table-column type="expand">
            <template v-slot="props">
              <div class="row" v-if="!versionChosed">
                <div class="col-sm-11">
                  <label>Wersje</label>
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                        v-for="version in props.row.versions"
                        :key="props.row.question_id + '_' + version.module"
                        v-bind:class="{ blue: version.is_lite === 1, pink: version.tags === 'INACTIVE', grey: version.is_active === 0 }"
                      >
                        <td>
                          <p-checkbox
                            :disabled="!ifEdit"
                            v-bind:checked="version.permitted"
                            v-on:input="checkboxChecked($event, props.row, version.module)"
                          ></p-checkbox>
                        </td>
                        <td :id="'projectConfigurationProjectVersionRow_' + version.module">{{ version.module }}</td>
                        <td>{{ version.version }}</td>
                        <td>{{ version.description }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-sm-1" v-if="editPermitted && ifEdit">
                  <p-button style="width: 35px; margin: 20px 0 10px 0" size="sm" type="default" @click.prevent="changeCheckboxes(props.row, true)"
                    ><i class="fa fa-check-square-o" aria-hidden="true"></i
                  ></p-button>
                  <br />
                  <p-button style="width: 35px" size="sm" type="default" @click.prevent="changeCheckboxes(props.row, false)"
                    ><i class="fa fa-square-o" aria-hidden="true"></i
                  ></p-button>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-sm-11">
                  <label>Odpowiedzi</label>
                  <table class="table table-borderless">
                    <tbody>
                      <tr v-for="answer in props.row.answers" :key="props.row.question_id + '_' + answer.answer_id">
                        <td>{{ answer.answer_position }}</td>
                        <td :id="'projectConfigurationQuestionAnswerRow_' + answer.answer_id">[{{ answer.answer_id }}]</td>
                        <td>{{ answer.answer_content }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="ID" property="question_id" width="80" sortable="custom"></el-table-column>
          <el-table-column label="Treść pytania" property="question_content_html" min-width="300" sortable="custom">
            <template v-slot="props">
              <div style="word-break: keep-all" :id="'projectConfigurationQuestionContentRow_' + props.row.question_id">
                <el-tooltip
                  v-if="!props.row.subquestion && props.row.parent"
                  class="item"
                  effect="light"
                  content="Pytanie nadrzędne"
                  placement="top"
                  :open-delay="500"
                >
                  <i class="fa fa-level-up" aria-hidden="true"></i>
                </el-tooltip>
                <el-tooltip v-if="props.row.subquestion" class="item" effect="light" content="Podpytanie" placement="top" :open-delay="500">
                  <span style="margin-right: 10px"><i class="fa fa-level-down" aria-hidden="true"></i></span>
                </el-tooltip>
                <el-tooltip class="item" effect="light" :content="props.row.all_answers" placement="top" :open-delay="1000">
                  <span>{{ props.row.question_content_html }}</span>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Typ"
            property="question_type_alias"
            :filter-multiple="false"
            :filters="this.filterTypes"
            min-width="120"
          ></el-table-column>
          <el-table-column :label="version" align="center" width="100" v-if="versionChosed">
            <template v-slot="props">
              <p-checkbox
                v-if="versionChosed"
                :disabled="!ifEdit"
                v-bind:checked="props.row.versions[version].permitted"
                v-on:input="checkboxChecked($event, props.row, props.row.versions[version].module)"
              ></p-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="Status"
            property="question_is_active"
            align="center"
            width="140"
            sortable="custom"
            v-if="questionFilters.includes('inactive', 'archive')"
          >
            <template v-slot="props">
              <el-tag :type="tagType(props.row.question_is_active)" effect="dark" size="small">{{ props.row.question_is_active }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Usuń" align="center" v-if="ifEdit && deletePermitted" width="140">
            <template v-slot="props">
              <p-button type="danger" size="sm" icon @click.prevent="handleDelete(props.row)">
                <i class="fa fa-minus-square-o"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body row">
        <div class="pagination-info" v-bind:class="{ 'col-sm-4': this.ifEdit === true, 'col-sm-6': this.ifEdit === false }">
          <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
        </div>
        <div class="col-sm-4 text-center" v-if="this.ifEdit">
          <p-button type="default" link @click.prevent="changeEdit()"> Anuluj </p-button>
          <p-button type="success" style="margin-left: 2vw" @click.prevent="saveData(false, {})">Zapisz</p-button>
        </div>
        <div v-bind:class="{ 'col-sm-4': this.ifEdit === true, 'col-sm-6': this.ifEdit === false }">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"> </p-pagination>
        </div>
      </div>
    </div>

    <div class="card" v-if="editPermitted">
      <div class="card-body">
        <div class="pull-right">
          <el-tooltip class="item" effect="light" placement="top" :open-delay="1000">
            <div slot="content">
              Dodawnie pytań do projektu:<br />
              - pytania typu CO-REGISTRATION - domyślnie aktywne są wszystkie wersje poza LITE, możliwość blokowania poszczególnych wersji lub
              dodawania wersji LITE<br />
              - pytania typu PROFILE i DOUBLEOPT-IN - domyślnie aktywne są wszystkie wersje, możliwość blokowania poszczególnych wersji<br />
              - pytanie typu QUIZ - domyślnie blokowane są wszystkie wersje, trzeba wybrać wersje do wyświetlania
            </div>
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </el-tooltip>
        </div>
        <div class="card-body row">
          <div
            v-bind:class="{
              'col-sm-4': Object.keys(this.selectedQuestion).length !== 0,
              'col-sm-12': Object.keys(this.selectedQuestion).length === 0,
            }"
          >
            <label>Dodaj pytanie do projektu</label>
            <fg-input :error="getError('selectedQuestionID')" data-vv-as="Dodaj pytanie">
              <el-select
                id="projectConfigurationAddQuestionSelect"
                v-on:visible-change="getBottomSelectWidth()"
                v-model="selectedQuestionID"
                name="selectedQuestionID"
                data-vv-as="Dodaj pytanie"
                filterable
                clearable
                :popper-append-to-body="false"
              >
                <el-option
                  :style="bottomSelectWidth !== null ? 'width: ' + bottomSelectWidth + 'px' : 'width: 300px'"
                  v-for="question in distinctQuestions"
                  :id="'projectConfigurationAddQuestionSelect_' + question.question_id"
                  :label="'[' + question.question_id + '] ' + question.question_content"
                  :value="question.question_id"
                  :key="question.question_id"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-6">
            <p v-if="Object.keys(this.selectedQuestion).length !== 0"><label>Treść pytania</label></p>
            {{ this.selectedQuestion.question_content }}
          </div>
          <div class="col-sm-2 text-center" v-if="Object.keys(this.selectedQuestion).length !== 0">
            <p-button type="success" @click.prevent="addQuestion">Dodaj</p-button>
          </div>
        </div>
        <div class="row" v-if="Object.keys(this.selectedQuestion).length !== 0 && this.selectedQuestion.question_type === 'QUIZ'">
          <div class="col-sm-12">
            <label>Wybierz wersje</label>
            <fg-input :error="getError('selectedVersions')" data-vv-as="Dodaj wersje">
              <el-select
                id="projectConfigurationAddQuestionSelectVersion"
                v-on:visible-change="getBottomRightSelectWidth()"
                v-model="selectedVersions"
                name="selectedVersions"
                data-vv-as="Dodaj wersje"
                multiple
                filterable
                clearable
                :popper-append-to-body="false"
              >
                <el-option
                  :style="bottomRightSelectWidth !== null ? 'width: ' + bottomRightSelectWidth + 'px' : 'width: 300px'"
                  v-for="version in activeProjectModules"
                  :id="'projectConfigurationAddQuestionSelectVersion_' + version.module"
                  :label="'[' + version.version + '] ' + version.module"
                  :value="version.module"
                  :key="version.module"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
import Vue from "vue";

import Swal from "sweetalert2";
import BackToTop from "vue-backtotop";

// ui components
import { Table, TableColumn, Select, Option, OptionGroup, Switch, Tag, Tooltip, CheckboxButton, CheckboxGroup } from "element-ui";
import { Tabs, TabPane, Card } from "src/components/UIComponents";
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_ANSWERS, ANSWERS_INVALID } from "src/store/actions/questions/answers";
import {
  LOAD_PROJECT_CONFIGS,
  PROJECT_CONFIGS_INVALID,
  SAVE_PROJECT_CONFIG,
  DELETE_PROJECT_CONFIG,
} from "src/store/actions/questions/project_configs";
import { LOAD_PROJECT_QUESTIONS, PROJECT_QUESTIONS_INVALID } from "src/store/actions/questions/project_questions";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";

Vue.use(Table);
Vue.use(TableColumn);

export default {
  components: {
    [Tag.name]: Tag,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Switch.name]: Switch,
    [Tooltip.name]: Tooltip,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
    PPagination,
    TabPane,
    Tabs,
    Card,
    BackToTop,
  },
  data() {
    return {
      project_id: null,
      version: "",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["question_id", "question_content", "question_type"],
      ifEdit: false,
      questionFilters: [],
      questionFiltersArray: ["inactive", "archive", "subquestions"],
      questionFiltersLabels: {
        inactive: "Nieaktywne",
        archive: "Archiwalne",
        subquestions: "Podpytania",
      },
      loading: false,
      sort: {
        prop: "question_id",
        order: "ascending",
      },
      filterTypes: [
        { text: "CO-REG", value: "CO-REG" },
        { text: "PROFILE", value: "PROFILE" },
        { text: "QUIZ", value: "QUIZ" },
        { text: "OPT-IN", value: "OPT-IN" },
      ],
      activeFilter: "",
      newProjectConfig: {},
      selectedQuestionID: null,
      selectedVersions: [],
      recordObject: {},
      bottomSelectWidth: null,
      bottomRightSelectWidth: null,
      upperLeftSelectWidth: null,
      upperRightSelectWidth: null,
    };
  },
  computed: {
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN_QUESTIONS_CONFIG);
    },
    deletePermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_DELETE_ADMIN_QUESTIONS_CONFIG);
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    questions() {
      // lista pytań przypisanych i nie przypisanych
      return JSON.parse(JSON.stringify(this.$store.getters.getProjectQuestions));
    },
    answers() {
      // lista odpowiedzi
      return JSON.parse(JSON.stringify(this.$store.getters.getAnswers));
    },
    questionTypes() {
      let getter = this.questions;
      let result = {};
      for (let i in getter) {
        if (result[getter[i].question_id] === undefined) {
          result[getter[i].question_id] = getter[i].question_type;
        }
      }
      return result;
    },
    distinctQuestions() {
      // lista pytań nie przypisanych do listy dodawania pytań
      let getter = this.questions;

      if (this.project_id === null) {
        // zwrócenie pustej tablicy w przypadku braku wybranego projektu
        return [];
      }

      if (this.$store.state.project_questions.loading_status === "success") {
        // filtracja pytań nie przypisanych
        let filteredData = getter.filter((row) => row.is_assigned === 0);
        filteredData.sort((a, b) => (a.question_id < b.question_id ? 1 : -1)); // sortowanie danych po priorytecie
        return filteredData;
      }
      return;
    },
    selectedQuestion() {
      // wybrane pytanie z listy pytań do dodania
      let questions = this.distinctQuestions;
      let question_id = this.selectedQuestionID;

      let question = {};

      if (questions !== undefined) {
        // znalezienie pytania po wybranym ID
        for (let i in questions) {
          if (questions[i].question_id === question_id) {
            question = questions[i];
          }
        }
      }

      this.selectedVersions = [];

      return question;
    },
    activeProjectModules() {
      // wszystkie moduły aktywnego projektu
      let projects = this.$store.getters.getProjectsAll;
      let filteredData = {};

      for (let [key, project] of Object.entries(projects)) {
        // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
        if (project.project_id === this.project_id) {
          filteredData[project.module] = Object.assign({}, project, { permitted: false });
        }
      }

      let sourceData = [];

      for (let ver of Object.keys(filteredData)) {
        // iteracja po nazwach modułów danego projektu
        let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie liczby
        filteredData[ver].number = res;
      }

      sourceData = Object.values(filteredData);
      sourceData.sort((a, b) => a.number - b.number);
      let result = {};
      for (let i in sourceData) {
        result[sourceData[i].module] = sourceData[i];
      }

      return result;
    },
    projectConfigs() {
      // zależność pytanie-projekt-moduł
      return this.$store.getters.getProjectConfigs;
    },
    projectQuestions() {
      // pytania dla danego projektu
      let getter = this.questions;
      let type_map = { "CO-REGISTRATION": "CO-REG", "DOUBLEOPT-IN": "OPT-IN" }; // mapa krótszych nazw typów
      if (this.$store.state.project_questions.loading_status === "success") {
        getter.map((row) => {
          row.question_type_alias = type_map[row.question_type] || row.question_type; // przypisanie aliasów typów
        });
        let filteredData = getter.filter(
          // filtracja danych po tagach oraz przypisaniu do projektu
          (row) =>
            (this.activeFilter === "" || // no filter
              row.question_type_alias === this.activeFilter) &&
            row.is_assigned,
        );
        return filteredData;
      }
      return;
    },
    tableData() {
      // table data needs to by an array
      if (this.project_id === null) {
        // zwrócenie pustej tablicy w przypadku braku wybranego projektu
        return [];
      }
      const questions = this.projectQuestions;

      let data = {};
      let versions = {};
      for (let row in questions) {
        let question_id = questions[row].question_id;
        if (data[question_id] === undefined) {
          data[question_id] = questions[row]; // przypisanie danych pytania
        }
        if (data[question_id].versions === undefined) {
          data[question_id].versions = JSON.parse(JSON.stringify(this.activeProjectModules)); // przypisanie wszystkich modułów projektu
        }
        if (data[question_id].answers === undefined) {
          data[question_id].answers = this.answers[question_id]; // przypisanie wszystkich modułów projektu
          data[question_id].all_answers = "";
          let answers = data[question_id].answers;
          for (let i in answers) {
            data[question_id].all_answers += answers[i].answer_position + ") " + answers[i].answer_content + "; ";
          }
        }
        if (
          !Object.keys(data[question_id].versions).includes(questions[row].module) &&
          questions[row].module !== "ALL_VERSION" &&
          questions[row].module !== null
        ) {
          data[question_id].versions[questions[row].module] = {
            // wyświetlanie nieaktywnych modułów dla danego pytania oraz odrzucanie modułów ALL_VERSION
            description: "Wersja nieaktywna",
            module: questions[row].module,
            version: "inactive",
            tags: "INACTIVE",
            project_id: this.project_id,
            permitted: true,
          };
        }
        data[question_id].question_content_html = data[question_id].question_content
          .replace(/<br>?/gm, "\n")
          .replace(/<[^>]*>?/gm, " ")
          .replace(/[ ]{2,}?/gm, " ");
      }

      let newProjectConfig = Object.values(this.newProjectConfig);

      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        for (let conf of newProjectConfig) {
          // przypisywanie odpowiednich oznaczeń do modułów
          if (typeof data[conf.question_id] !== "undefined" && typeof data[conf.question_id].versions[conf.module].module !== "undefined") {
            data[conf.question_id].versions[conf.module].permitted = true;
          } else {
            //TODO: wpis dla nieaktywnych modułów
          }
        }
      }

      data = Object.values(data).map((row) => Object.assign({}, row));
      data.sort((a, b) => (a.priority < b.priority ? 1 : -1)); // sortowanie danych po priorytecie

      return data;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      // paginacja danych
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    versionChosed() {
      if (this.version.length > 0) return true;
      else return false;
    },
  },
  methods: {
    async loadData(force, force_all) {
      this.loading = true;

      const filters = {
        project_id: this.project_id,
      };

      this.questionFiltersArray.forEach((x) => {
        if (this.questionFilters.includes(x)) {
          filters[x] = true;
        } else {
          filters[x] = false;
        }
      });

      if (force) {
        this.$store.commit(PROJECT_CONFIGS_INVALID);
        this.$store.commit(PROJECT_QUESTIONS_INVALID);
      }

      if (force_all) {
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(PROJECTS_ALL_INVALID);
        this.$store.commit(ANSWERS_INVALID);
        this.$store.commit(PROJECT_CONFIGS_INVALID);
        this.$store.commit(PROJECT_QUESTIONS_INVALID);
      }

      const p1 = this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const p2 = this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const p3 = this.$store.dispatch(LOAD_ANSWERS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const promiseArray = [p1, p2, p3];

      if (this.project_id !== null) {
        const p4 = this.$store.dispatch(LOAD_PROJECT_QUESTIONS, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        const p5 = this.$store.dispatch(LOAD_PROJECT_CONFIGS, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        promiseArray.push(p4, p5);
      }

      const end = await Promise.all(promiseArray);

      this.initConfig();
      this.loading = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    sortChange(obj) {
      this.sort = {
        prop: obj.prop,
        order: obj.order,
      };
      if (obj.order === "descending") {
        if (obj.prop === "question_content_html" || obj.prop === "question_is_active") {
          this.queriedData.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.queriedData.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (obj.prop === "question_content_html" || obj.prop === "question_is_active") {
          this.queriedData.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.queriedData.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        this.queriedData.sort((a, b) => b.priority - a.priority);
      }
      this.searchQuery += " ";
      this.searchQuery = this.searchQuery.slice(0, -1);
    },
    tagType(value) {
      // przypisanie tagów
      switch (value) {
        case "ACTIVE":
          return "success";
        case "INACTIVE":
          return "warning";
        case "ARCHIVE":
          return "danger";
        default:
          return "info";
      }
    },
    changeFilter(payload) {
      // funkcja obsługująca filtrowanie danych po tagach
      let filter = Object.values(payload);
      if (filter[0][0] === undefined) {
        // sprawdzenie czy filtr został wybrany
        this.activeFilter = "";
      } else {
        this.activeFilter = filter[0][0]; // przypisanie filtra do zmiennej
      }
    },
    changeProject(payload) {
      // funkcja obsługująca zmianę projektu i wymuszająca przeładowanie danych
      this.project_id = payload;
      this.selectedQuestionID = null;
    },
    initConfig() {
      // początkowa konfiguracja modułów
      this.newProjectConfig = {};
      let projectConfigs = this.projectConfigs;
      for (let i in projectConfigs) {
        if (projectConfigs[i].project_id === this.project_id && projectConfigs[i].module !== "ALL_VERSION") {
          this.newProjectConfig[projectConfigs[i].module + "_" + projectConfigs[i].question_id] = {
            module: projectConfigs[i].module,
            question_id: projectConfigs[i].question_id,
            previous_assign: true,
            current_assign: true,
            question_type: projectConfigs[i].question_type,
            question_tag:
              this.activeProjectModules[projectConfigs[i].module] !== undefined
                ? this.activeProjectModules[projectConfigs[i].module].tags
                : "inactive",
          };
        }
      }
    },
    checkboxChecked(checkedBool, row, checkedModule) {
      // funkcja obsługująca zmianę przypisania checkboxa danej wersji pytania
      let checkedConfig = checkedModule + "_" + row.question_id;
      let projectConfig = this.newProjectConfig;

      if (Object.keys(projectConfig).includes(checkedConfig)) {
        projectConfig[checkedConfig].current_assign = checkedBool;
      } else {
        projectConfig[checkedConfig] = {
          module: checkedModule,
          question_id: row.question_id,
          previous_assign: false,
          current_assign: checkedBool,
          question_type: this.questionTypes[row.question_id],
          question_tag: this.activeProjectModules[checkedModule].tags,
        };
      }
      this.record();
    },
    changeCheckboxes(row, direction) {
      if (this.editPermitted) {
        let versions = row.versions;
        for (let i in versions) {
          this.checkboxChecked(direction, row, versions[i].module);
          versions[i].permitted = direction;
        }
      }
    },
    record() {
      let newProjectConfig = Object.values(this.newProjectConfig);
      let insert_question = {};
      let delete_question = {};
      for (let el in newProjectConfig) {
        if (newProjectConfig[el].current_assign !== newProjectConfig[el].previous_assign) {
          // sprawdzenie czy poprzednie przypisanie różni się od aktualnego
          if (newProjectConfig[el].previous_assign === false) {
            // sprawdzenie czy moduł został zaznaczony czy odznaczony
            insert_question[el] = newProjectConfig[el];
          } else {
            delete_question[el] = newProjectConfig[el];
          }
        }
      }

      insert_question = Object.values(insert_question);
      delete_question = Object.values(delete_question);
      let record = {
        // stworzenie obiektu
        project_id: this.project_id,
        insert_question: insert_question,
        delete_question: delete_question,
      };

      this.recordObject = record;
    },
    changeEdit() {
      this.record();

      if (this.ifEdit === true) {
        let record = this.recordObject;
        let varThis = this;

        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
            cancelButton: "btn btn-light btn-fill",
          },
          buttonsStyling: false,
        });

        if (record.delete_question.length > 0 || record.insert_question.length > 0) {
          swalWithBootstrapButtons
            .fire({
              title: "Czy chcesz wyjść bez zapisywania?",
              text: `Zmiany zostaną odrzucone`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Akceptuj",
              cancelButtonText: "Anuluj",
              reverseButtons: true,
            })
            .then((res) => {
              if (res.isConfirmed) {
                varThis.initConfig();
                varThis.ifEdit = false;
                varThis.record();
                if (varThis.version.length > 0) varThis.version = "";
              }
            })
            .catch(() => {
              return;
            });
        } else {
          this.ifEdit = false;
        }
      } else {
        this.ifEdit = true;
      }
    },
    async saveData(addQuestion, questionRecord) {
      // tworzenie tablicy zmian - odznaczenia modułów i przypisania nowych, a następnie zapisania danych do bazy
      let record = {};

      if (addQuestion) {
        record = {
          project_id: this.project_id,
          insert_question: questionRecord,
          delete_question: [],
        };
      } else {
        this.record();
        record = this.recordObject;
      }

      if (record.insert_question.length > 0 || record.delete_question.length > 0) {
        await this.$store.dispatch(SAVE_PROJECT_CONFIG, record).catch((error) => {
          // zapisanie danych do bazy
          console.log(error.response);
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getProjectConfigsModifiedRecords === true) {
          // wyświetlenie informacji o zapisaniu
          eventBus.$emit("notify", {
            message: "Rekord został zmodyfikowany",
            type: "success",
          });

          this.ifEdit = false;
          this.loadData(true, false);
        } else {
          eventBus.$emit("notify", {
            message: "Coś poszło nie tak..",
            type: "warning",
          });
        }
      }
      this.initConfig();
      this.record();
    },
    async deleteData(record) {
      await this.$store.dispatch(DELETE_PROJECT_CONFIG, record).catch((error) => {
        // zapisanie danych do bazy
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getProjectConfigsDeletingStatus === "deleted") {
        // wyświetlenie informacji o zapisaniu
        eventBus.$emit("notify", {
          message: "Rekord został usunięty",
          type: "success",
        });

        this.ifEdit = false;
        this.loadData(true, false);
        this.searchQuery = " ";
        this.searchQuery = "";
      }
    },
    addQuestion() {
      // funkcja obsługująca dodanie pytania
      var varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      if (this.selectedQuestion.question_type === "QUIZ" && this.selectedVersions.length === 0) {
        eventBus.$emit("notify", {
          message: `Należy wybrać wersję pytania typu ${varThis.selectedQuestion.question_type}!`,
          type: "warning",
        });
        return;
      }
      swalWithBootstrapButtons
        .fire({
          title: "Czy chcesz dodać to pytanie do projektu?",
          text: `Pytanie zostanie zapisane`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            if (varThis.selectedQuestion.question_type !== "QUIZ") {
              let question = {
                current_assign: true,
                module: null,
                previous_assign: false,
                question_id: varThis.selectedQuestion.question_id,
                question_type: varThis.selectedQuestion.question_type,
              };
              varThis.saveData(true, [question]);
              varThis.selectedQuestionID = null;
            } else {
              let questions = [];
              let versions = varThis.selectedVersions;
              for (let i in versions) {
                let question = {
                  current_assign: true,
                  module: versions[i],
                  previous_assign: false,
                  question_id: varThis.selectedQuestion.question_id,
                  question_type: varThis.selectedQuestion.question_type,
                };
                questions.push(question);
              }
              varThis.saveData(true, questions);
              varThis.selectedQuestionID = null;
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    handleDelete(row) {
      var varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Czy chcesz usunąć to pytanie z projektu?",
          text: `Pytanie zostanie usunięte`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            let model = {
              question_id: row.question_id,
              project_id: varThis.project_id,
            };
            varThis.deleteData(model);
          }
        })
        .catch(() => {
          return;
        });
    },
    getBottomSelectWidth() {
      this.bottomSelectWidth = document.getElementById("projectConfigurationAddQuestionSelect").offsetWidth;
    },
    getBottomRightSelectWidth() {
      this.bottomRightSelectWidth = document.getElementById("projectConfigurationAddQuestionSelectVersion").offsetWidth;
    },
    getUpperLeftSelectWidth() {
      this.upperLeftSelectWidth = document.getElementById("projectConfigurationProjectSelect").offsetWidth;
    },
    getUpperRightSelectWidth() {
      this.upperRightSelectWidth = document.getElementById("projectConfigurationVersionSelect").offsetWidth;
    },
    takeToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
  },
  created() {
    this.loadData(false, false);
    this.record();
    eventBus.$on("forceReload", () => {
      if (this.project_id !== null) this.project_id = null;
      this.loadData(false, true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
    this.$store.commit(PROJECT_CONFIGS_INVALID);
    this.$store.commit(PROJECT_QUESTIONS_INVALID);
  },
};
</script>

<style scoped>
.has-gutter:hover {
  cursor: pointer;
}

.el-table,
.el-table tr,
.el-table thead th {
  background-color: #fff !important;
}
.blue > td {
  /* color: blue; */
  background-color: #edf9ff;
}
.red {
  color: red;
}
.pink {
  color: pink;
}
.grey > td {
  /* background-color: #ededed; */
  color: silver;
}
a,
a:hover {
  text-decoration: none;
  color: red;
}
</style>
