<template></template>
<script>
import { AUTH_LOGOUT } from "src/store/actions/auth"
export default {
  created() {
    this.$store
      .dispatch(AUTH_LOGOUT)
      .then(() => {
        this.$router.push({ name: "Login" });
      })
      .catch(err => console.log(err));
    // todo: obsłużyć błąd
  }
};
</script>
<style>
</style>
