<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title" v-if="mode === 'add'">Dodaj użytkownika do grupy</h5>
          <h5 class="title" v-else>Edytuj przypisanie użytkownika</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <div class="form-group">
            <label>Użytkownik</label>
            <el-select v-model="model.user_id" name="user_id" filterable :disabled="mode === 'edit'">
              <el-option v-for="user in users_list" :label="user.name" :value="user.system_user_id" :key="user.system_user_id"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <div class="form-group">
            <label>Grupa</label>
            <el-select v-model="model.group_id" name="group_id" filterable>
              <el-option v-for="group in group_list" :label="group.name" :value="group.group_id" :key="group.group_id"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="row">
        <p-button style="margin-right: 5px;" link @click="$emit('close')">Anuluj</p-button>
        <p-button type="success" @click="saveData()">Zapisz</p-button>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option } from "element-ui";

// event bus
import { eventBus } from "src/main";

import { SAVE_USER_GROUP } from "src/store/actions/complaints/user_group";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: ["mode", "users", "privileged", "assigned", "group_list", "row"],
  data() {
    return {
      model: {
        user_id: null,
        group_id: null,
        previous_group_id: null,
        mode: this.mode,
      },
      compare_model: {
        user_id: null,
        group_id: null,
        previous_group_id: null,
        mode: this.mode,
      },
    };
  },
  computed: {
    users_list() {
      let data = this.privileged;
      if (this.mode === "edit") {
        if (!data[this.row.system_user_id]) {
          const user = this.users[this.row.system_user_id];
          data[this.row.system_user_id] = {
            group_id: null,
            name: user.user_first_name + " " + user.user_last_name,
            system_user_id: user.user_id,
            type: "user",
          };
        }
      } else {
        data = Object.values(data).filter(user => {
          return !this.assigned.some(asgn => asgn.system_user_id == user.system_user_id);
        });
      }
      return data;
    },
  },
  methods: {
    async saveData() {
      if (this.model.group_id == null || this.model.user_id == null) {
        eventBus.$emit("notify", {
          message: `Należy podać dane`,
          type: "warning",
        });
        return;
      }
      if (JSON.stringify(this.model) === JSON.stringify(this.compare_model)) return;

      await this.$store.dispatch(SAVE_USER_GROUP, this.model).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getUserGroup) {
        eventBus.$emit("notify", {
          message: `Rekord zapisany`,
          type: "success",
        });
        eventBus.$emit("userGroupSaved");
        this.$emit("close");
      }
    },
  },
  async created() {
    if (this.mode === "edit") {
      this.model.user_id = this.row.system_user_id;
      this.model.group_id = this.row.group_id;
      this.model.previous_group_id = this.row.group_id;
    }
    this.compare_model = _.cloneDeep(this.model);
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}

.formButton {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.helper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
</style>
