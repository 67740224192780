import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_SALES_RAPORT_EXCEL,
  SALES_RAPORT_EXCEL_LOADING,
  SALES_RAPORT_EXCEL_LOADING_SUCCESS,
  SALES_RAPORT_EXCEL_LOADING_ERROR,
  SALES_RAPORT_EXCEL_INVALID
} from 'src/store/actions/secure/sales_raport_excel'

const storeObjectConfig = {
  objectName: "SalesRaport",
  getterName: "getSalesRaportExcel",
  loadActionName: LOAD_SALES_RAPORT_EXCEL,
  loadingMutationName: SALES_RAPORT_EXCEL_LOADING,
  loadingSuccessMutationName: SALES_RAPORT_EXCEL_LOADING_SUCCESS,
  loadingErrorMutationName: SALES_RAPORT_EXCEL_LOADING_ERROR,
  invalidMutationName: SALES_RAPORT_EXCEL_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/salesRaport/excel",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
