<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 class="card-title">Importuj użytkownika</h4>
      </div>
      <div class="card-body">

        <div class="form-group">
          <label>Użytkownik</label>
          <fg-input :error="getError('user_id')" data-vv-as="Użytkownik">
            <el-select
              v-model="model.user_id"
              name="system_name"
              data-vv-as="Użytkownik"
              v-validate="modelValidations.user_id"
              filterable
            >
              <el-option
                v-for="user in distinctUsers"
                :label="`[${user.user_id}] ${user.user_login} / ${user.user_name}`"
                :value="user.user_id"
                :key="user.user_id"
              ></el-option>
            </el-select>
          </fg-input>
        </div>

      </div>
      <div class="card-footer text-center">
        <router-link to="/admin/users">
          <p-button type="default" link style="margin-right: 5vw">
            Anuluj
          </p-button>
        </router-link>
        <p-button type="success" @click.prevent="saveData">Zapisz</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Select, Option, Switch } from "element-ui";

import { LOAD_USERS, USERS_INVALID } from "src/store/actions/admin/users";
import { LOAD_USERS_IMPORT, USERS_IMPORT_INVALID, SAVE_USER_IMPORT } from "src/store/actions/admin/users_import";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  data() {
    return {
      model: {
        user_id: null,
      },
      modelValidations: {
        user_id: {
          numeric: true,
          min: 1
        },
      },
    };
  },
  computed: {
    users_de01() {
      return this.$store.getters.getUsersImport;
    },
    users_api() {
      return this.$store.getters.getUsers;
    },
    distinctUsers() {
      if(this.$store.state.users.loading_status === 'success' && this.$store.state.users_import.loading_status === 'success'){
        let users_api = Object.keys(this.users_api);
        let users_de01 = Object.keys(this.users_de01);
        let result = [];

        let unique = users_de01.filter((o) => users_api.indexOf(o) === -1);

        for(let i in unique) {
          result.push(this.users_de01[unique[i]]);
        }
        return result
      }
      return []
    }
  },
  methods: {
    loadData(force) {
      const filters = {}

      if (force) {
        this.$store.commit(USERS_IMPORT_INVALID);
      }

      this.$store.dispatch(LOAD_USERS, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      this.$store.dispatch(LOAD_USERS_IMPORT, {is_active: true}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll()

      if (isValid) {
        await this.$store.dispatch(SAVE_USER_IMPORT, this.model).catch(error => {
          console.log(error.response)
          switch(error.response.data.code) {
            case 409:
              message = 'Nazwy muszą być unikalne!'
              break;
            default:
              message = "Błąd zapisu danych!"
          }
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning"
          });
        })

        if (this.$store.getters.getUsersImportModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został dodany",
            type: "success"
          });

          this.$store.commit(USERS_IMPORT_INVALID) // oznacz nieaktualność danych
          this.$store.commit(USERS_INVALID) // oznacz nieaktualność danych
          this.$router.push({ name: "Użytkownicy" }) // przekieruj do listy
        }
      }
    }
  },
  created() {
    this.loadData();
  }
};
</script>
<style>
</style>


