import { render, staticRenderFns } from "./ConsentsPartnersDict.vue?vue&type=template&id=5d8e48eb&scoped=true&"
import script from "./ConsentsPartnersDict.vue?vue&type=script&lang=js&"
export * from "./ConsentsPartnersDict.vue?vue&type=script&lang=js&"
import style0 from "./ConsentsPartnersDict.vue?vue&type=style&index=0&id=5d8e48eb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8e48eb",
  null
  
)

export default component.exports