import storeGen from '@/store/storeGenerator'
import {
  AUTH_LOGOUT
} from '@/store/actions/auth'

import {
  BASE_API
} from '../../config'


import {
  ERROR_MAILINGS_DASHBOARD_SENDS,
  GET_MAILINGS_DASHBOARD_SENDS,
  GET_MAILINGS_DASHBOARD_SENDS_OBJECT, INVALID_MAILINGS_DASHBOARD_SENDS,
  LOAD_MAILINGS_DASHBOARD_SENDS,
  LOADING_MAILINGS_DASHBOARD_SENDS,
  SUCCESS_MAILINGS_DASHBOARD_SENDS
} from "@/store/actions/mailings/mailingsDashboardSends";

const storeObjectConfig = {
  objectName: GET_MAILINGS_DASHBOARD_SENDS_OBJECT,
  getterName: GET_MAILINGS_DASHBOARD_SENDS,
  loadActionName: LOAD_MAILINGS_DASHBOARD_SENDS,
  loadingMutationName: LOADING_MAILINGS_DASHBOARD_SENDS,
  loadingSuccessMutationName: SUCCESS_MAILINGS_DASHBOARD_SENDS,
  loadingErrorMutationName: ERROR_MAILINGS_DASHBOARD_SENDS,
  invalidMutationName: INVALID_MAILINGS_DASHBOARD_SENDS,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/mailings/dashboardMailings/getSendsToProviders",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);// stan stora
const getters = storeGen.gettersGenerator(storeObjectConfig);//pobiera dane ze stora
const actions = storeGen.actionsGenerator(storeObjectConfig);//poberanie dany do stora async
const mutations = storeGen.mutationsGenerator(storeObjectConfig);//zmieniają stan stora

export default {
  state,
  getters,
  actions,
  mutations
}
