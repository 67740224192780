import { render, staticRenderFns } from "./ClientRatesHistoric.vue?vue&type=template&id=3217023e&scoped=true&"
import script from "./ClientRatesHistoric.vue?vue&type=script&lang=js&"
export * from "./ClientRatesHistoric.vue?vue&type=script&lang=js&"
import style0 from "./ClientRatesHistoric.vue?vue&type=style&index=0&id=3217023e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3217023e",
  null
  
)

export default component.exports