<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h5 class="title">Grupy pytań</h5>
      </div>
      <div class="col-sm-12 col-md-4 text-md-right">
        <!-- <el-select if="limitsConfigurationFilterSelect" v-model="active_project_id" size="small" filterable clearable placeholder="Filtruj">
          <el-option
            v-for="project in projects"
            :id="'limitsConfigurationFilterSelectOption_' + project.project_id"
            :key="project.project_id"
            :label="project.project_name + ' [' + project.project_id + ']'"
            :value="project.project_id"
          ></el-option>
        </el-select> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="queriedData" style="width: 100%">
              <el-table-column type="expand">
                <template v-slot="props" v-if="props.row.questions.length > 0">
                  <el-table :data="props.row.questions">
                    <el-table-column label="ID" width="100" property="question_id"></el-table-column>
                    <el-table-column label="Nazwa" property="question_content" min-width="160">
                      <template v-slot="props">
                        <div style="word-break: keep-all;">
                          {{ props.row.question_content }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" v-if="editPermitted" width="70">
                      <template v-slot="props">
                        <el-tooltip class="item" effect="light" content="Usuń pytanie" placement="top" :open-delay="300">
                          <p-button type="danger" size="sm" icon @click="handleDelete(props.row)">
                            <i class="fa fa-trash"></i>
                          </p-button>
                        </el-tooltip>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column label="ID" width="100" property="group_id"></el-table-column>
              <el-table-column label="Nazwa" property="group_name" min-width="160"></el-table-column>
              <el-table-column label="Opis" property="description" min-width="160"></el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="120">
                <template slot="header">
                  <el-tooltip class="item" effect="light" content="Dodaj grupę" placement="top" :open-delay="300">
                    <p-button type="success" icon round @click="showModal('group', null)">
                      <i class="fa fa-plus"></i>
                    </p-button>
                  </el-tooltip>
                </template>
                <template v-slot="props">
                  <el-tooltip class="item" effect="light" content="Dodaj pytanie" placement="top" :open-delay="300">
                    <p-button style="margin-right: 2px;" type="success" size="sm" icon @click="showModal('question', props.row)">
                      <i class="fa fa-plus"></i>
                    </p-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="light" content="Edytuj grupę" placement="top" :open-delay="300">
                    <p-button style="margin-left: 2px;" type="success" size="sm" icon @click="showModal('group', props.row)">
                      <i class="fa fa-edit"></i>
                    </p-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-body row">
            <div class="col-sm-6 pagination-info">
              <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
// ui components
import { Tooltip } from "element-ui";
import BackToTop from "vue-backtotop";
import PPagination from "src/components/UIComponents/Pagination.vue";
import Swal from "sweetalert2";

import QuestionGroupForm from "src/components/Utils4/questions/forms/QuestionGroupForm.vue";

// vuex actions
import { LOAD_GROUPS, GROUPS_INVALID } from "src/store/actions/questions/groups";
import { LOAD_QUESTION_GROUP, QUESTION_GROUP_INVALID, SAVE_QUESTION_GROUP } from "src/store/actions/questions/question_group";
import { LOAD_QUESTIONS, QUESTIONS_INVALID } from "src/store/actions/questions/questions";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    PPagination,
    BackToTop,
  },
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
    };
  },
  computed: {
    groups() {
      return this.$store.getters.getGroups;
    },
    questionGroup() {
      return this.$store.getters.getQuestionGroup;
    },
    activeQuestionIDs() {
      let ids = [];
      let questionGroup = Object.values(this.questionGroup);
      questionGroup.forEach(group => {
        let questions = Object.values(group);
        questions.forEach(question => ids.push(question.question_id));
      });
      return ids;
    },
    tableData() {
      const groups = Object.values(this.groups);
      const questionGroup = this.questionGroup;

      groups.forEach(group => {
        group.questions = questionGroup[group.group_id] ? Object.values(questionGroup[group.group_id]) : [];
      });

      return groups;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData;

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN_QUESTIONS);
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(GROUPS_INVALID);
        this.$store.commit(QUESTION_GROUP_INVALID);
        this.$store.commit(QUESTIONS_INVALID);
      }

      this.$store.dispatch(LOAD_QUESTIONS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_GROUPS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_QUESTION_GROUP, { groupFlag: true }).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {},
    showModal(mode, row) {
      // wyświetlenie modala edycji grupy
      this.$modal.show(
        QuestionGroupForm,
        {
          mode: mode,
          row: row,
          activeQuestionIDs: this.activeQuestionIDs,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(QuestionGroupForm);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "50%",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    handleDelete(row) {
      let varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: `Czy chcesz usunąć pytanie ${row.question_id} z grupy "${row.group_name}"?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then(res => {
          if (res.isConfirmed) {
            varThis.deleteData(row);
          }
        })
        .catch(() => {
          return;
        });
    },
    async deleteData(row) {
      let deleteModel = { question_id: row.question_id, mode: "delete" };

      await this.$store.dispatch(SAVE_QUESTION_GROUP, deleteModel).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu!",
          type: "warning",
        });
      });

      if (this.$store.getters.getQuestionGroupModifiedRecords > 0) {
        eventBus.$emit("notify", {
          message: "Rekord został usunięty",
          type: "success",
        });

        this.$store.commit(QUESTION_GROUP_INVALID); // oznacz nieaktualność danych
        this.loadData(true);
      }
    },
  },
  mounted() {
    eventBus.$on("questionGroupSaved", () => {
      // zapis pliku
      this.loadData();
    });
  },
  async created() {
    await this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped></style>
