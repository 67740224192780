<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">{{ title }}</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Parent</label> <label v-if="mode === 'add_category'" style="font-style: italic; font-size: 0.8em;"> - Nie wybranie rodzica spowoduje stworzenie kategorii głównej</label>
            <fg-input :error="getError('parent_category_id')" data-vv-as="Parent">
              <el-select
                v-model="model.parent_category_id"
                name="parent_category_id"
                data-vv-as="Parent"
                v-validate="modelValidations.parent_category_id"
                filterable
                clearable
                :disabled = 'mode !== "add_category"'
              >
                <el-option
                  v-for="category in parentCategorys"
                  :label="category.category_name"
                  :value="category.id"
                  :key="category.id"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group">
          <label>Nazwa kategorii</label>
            <fg-input
              type="text"
              name="category_name"
              data-vv-as="Nazwa kategorii"
              v-validate="modelValidations.category_name"
              :error="getError('category_name')"
              v-model="model.category_name"
            ></fg-input>
          </div>

          <div class="row">
            <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip } from "element-ui";

// vuex actions
import { LOAD_CATEGORYS } from "src/store/actions/questionForm/categorys";
import { CATEGORYS_INVALID } from "src/store/actions/questionForm/categorys";
import { SAVE_CATEGORY } from "src/store/actions/questionForm/categorys";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },
  props: ['mode', 'row'],
  data() {
    return {
      model: {
        id: null,
        category_name: '',
        is_active: 1,
        parent_category_id: ''
      },
      modelValidations: {
        id: {
          numeric: true,
          min: 1
        },
        category_name: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 1,
          max: 128
        },
        is_active: {
          required: true,
          numeric: true,
          min: 0
        },
      },
      parentCategorys: [],
      compareModel: {}
    }
  },
  computed: {
    title() {
      let result = 'Kategorie';
      if(this.mode === 'edit') {
        if(this.row.parent_category_id === null) {
          result = 'Edytuj kategorię'
        } else {
          result = 'Edytuj podkategorię'
        }
      } else if(this.mode === 'add_subcategory') {
        result = `Dodaj podkategorię do kategorii "${this.row.category_name}"`
      } else {
        result = 'Dodaj kategorię/podkategorię'
      }
      return result
    }
  },
  methods: {
    async loadData(force) {
      await this.$store.dispatch(LOAD_CATEGORYS, {}).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });

      if (force) {
        this.$store.commit(CATEGORYS_INVALID);
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      if(isValid && JSON.stringify(this.model) !== JSON.stringify(this.compareModel)) {
        await this.$store.dispatch(SAVE_CATEGORY, this.model).catch(error => {
          let message = ''
          console.log(error.response)
          switch(error.response.data.code) {
            case 409:
              message = 'Nazwy muszą być unikalne!'
              break;
            default:
              message = "Błąd zapisu danych!"
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning"
          });
        })

        if (this.$store.getters.getCategorysModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.mode === 'edit' ? "zmodyfikowany" : "dodany"),
            type: "success"
          });

          this.$store.commit(CATEGORYS_INVALID) // oznacz nieaktualność danych
          eventBus.$emit('categorySaved');
          this.$emit('close');
        }
      }
    },
    initData() {
      let data = Object.values(this.$store.getters.getCategorys);
      let result = [];
      data.map(el => {
        if(el.parent_category_id === null) {
          result.push(el);
        }
      })
      this.parentCategorys = result;
      if(this.mode === 'edit') {
        this.model = Object.assign({}, this.row);
      } else if(this.mode === 'add_subcategory') {
        this.model.parent_category_id = this.row.id
      }
      this.compareModel = Object.assign({}, this.model);
    }
  },
  async created() {
    await this.loadData();
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
  .row {
    margin: 10px;
  }
  .card {
    margin: 0;
    display: flex;
  }
  .row:last-child {
    justify-content: center;
  }
  .center {
    justify-content: center;
    align-content: center;
  }
  .header_row {
    display: flex;
    justify-content: space-between;
  }
</style>
