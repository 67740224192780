import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_DATABASES,
  DATABASES_LOADING,
  DATABASES_LOADING_SUCCESS,
  DATABASES_LOADING_ERROR,
  DATABASES_INVALID,
  SAVE_DATABASE,
  DATABASE_SAVING,
  DATABASE_SAVING_SUCCESS,
  DATABASE_SAVING_ERROR
} from 'src/store/actions/admin/databases'

const storeObjectConfig = {
  objectName: "Databases",
  getterName: "getDatabases",
  loadActionName: LOAD_DATABASES,
  loadingMutationName: DATABASES_LOADING,
  loadingSuccessMutationName: DATABASES_LOADING_SUCCESS,
  loadingErrorMutationName: DATABASES_LOADING_ERROR,
  invalidMutationName: DATABASES_INVALID,
  saveActionName: SAVE_DATABASE,
  savingMutationName: DATABASE_SAVING,
  savingSuccessMutationName: DATABASE_SAVING_SUCCESS,
  savingErrorMutationName: DATABASE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/databases",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/databases",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
