import storeGen from "../../storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { BASE_API } from "src/store/config";

import {
  SAVE_UNSUBSCRIBE_LEAD,
  UNSUBSCRIBE_LEAD_SAVING,
  UNSUBSCRIBE_LEAD_SAVING_SUCCESS,
  UNSUBSCRIBE_LEAD_SAVING_ERROR,
} from "src/store/actions/blacklist/unsubscribe_lead";

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getUnsubscribeLead",
  saveActionName: SAVE_UNSUBSCRIBE_LEAD,
  savingMutationName: UNSUBSCRIBE_LEAD_SAVING,
  savingSuccessMutationName: UNSUBSCRIBE_LEAD_SAVING_SUCCESS,
  savingErrorMutationName: UNSUBSCRIBE_LEAD_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "plain",
  saveParams: {
    url: "/blacklist/unsubscribe",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
