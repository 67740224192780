import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_PROFILE,
  PROFILE_LOADING,
  PROFILE_LOADING_SUCCESS,
  PROFILE_LOADING_ERROR,
  PROFILE_INVALID,
  SAVE_PROFILE,
  PROFILE_SAVING,
  PROFILE_SAVING_SUCCESS,
  PROFILE_SAVING_ERROR
} from 'src/store/actions/admin/profile'

const storeObjectConfig = {
  objectName: "Profile",
  getterName: "getProfile",
  loadActionName: LOAD_PROFILE,
  loadingMutationName: PROFILE_LOADING,
  loadingSuccessMutationName: PROFILE_LOADING_SUCCESS,
  loadingErrorMutationName: PROFILE_LOADING_ERROR,
  invalidMutationName: PROFILE_INVALID,
  saveActionName: SAVE_PROFILE,
  savingMutationName: PROFILE_SAVING,
  savingSuccessMutationName: PROFILE_SAVING_SUCCESS,
  savingErrorMutationName: PROFILE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/currentUser",
    method: "get" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/currentUser",
    method: "put" // {'get','post','put'}
  }
}

const hookFunctions = {
    afterInvalidLoad({commit, dispatch, getters}) {
      dispatch(AUTH_LOGOUT)
    }
  }

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
// const actions = storeGen.actionsGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig, hookFunctions);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
