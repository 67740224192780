<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <form>
        <div class="card-header">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <h4 v-if="!editMode" class="card-title">Nowa odpowiedź</h4>
              <h4 v-if="editMode" class="card-title">Edycja odpowiedzi {{ this.answer_id }}</h4>
            </div>
            <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
              <router-link :to="'/questions/answer_form/qid=' + question_id">
                <p-button
                  type="success"
                  link
                  size="sm"
                  v-if="editMode"
                >
                  Dodaj nową odpowiedź
                </p-button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body">

          <label>Treść pytania</label>
          <p>{{ questions[question_id].question_content }}</p>

          <label>Odpowiedzi</label>
          <ul>
            <li v-for="answer in question_answers" v-bind:key="answer.answer_id" v-bind:class="{'selectedID': answer.answer_id == answer_id}">
              {{ answer.answer_position + ') ' }}
              <router-link :to="'/questions/answer_form/qid=' + question_id + ',aid=' + answer.answer_id">
                {{ '[' + answer.answer_id + ']' }}
              </router-link>
              {{ ' ' + answer.answer_content }}
            </li>
            <!-- <li v-bind:class="{'selectedID': answer_id === undefined}">
              <router-link :to="'/questions/answer_form/qid=' + question_id">
                <i class="fa fa-plus-square-o" aria-hidden="true"></i> Dodaj kolejną odpowiedź
              </router-link>
            </li> -->
          </ul>

          <p v-if="question_answers.length > 0" class="text-muted" style="font-size: 0.8em;"><i>Zmiana odpowiedzi spowoduje anulowanie dotychczas wprowadzonych zmian</i></p>

          <hr>

          <div class="form-group">
          <label>Treść odpowiedzi</label>
            <el-input
              type="textarea"
              name="answer_content"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="Treść odpowiedzi"
              v-model="answerModel.answer_content"
              v-validate="answerModelValidations.answer_content"
              :error="getError('answer_content')">
            </el-input>
          </div>

          <div class="form-group">
            <el-tooltip
                class="item"
                effect="light"
                placement="top"
                :open-delay="1000"
              >
              <div slot="content">Dedykowana treść do wyświetlenia statystyk w serwisie np. <b>{ANSWER_STATS} osób odpowiedziało {ANSWER_CONTENT}</b> wyświetli tekst o treści <b>2345 osób odpowiedziało TAK, CHCĘ</b>.<br>
                Dopuszczalne jest używanie lokalnych zmiennych środowiskowych:<br>
                <b>{ANSWER_STATS}</b> - ilość osób, która udzieliła takiej samej odpowiedzi<br>
                <b>{ANSWER_CONTENT}</b> - treść odpowiedzi<br>
                jak i globalnych zmiennych środowiskowych np. {NAME}, {VOCATIVE}, itp.</div>
            <label>Treść statystyk</label>
            </el-tooltip>
            <el-input
              type="textarea"
              name="description"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="Treść statystyk"
              v-model="answerStatsModel.description"
              v-validate="answerStatsModelValidations.description"
              :error="getError('description')">
            </el-input>
          </div>

          <div class="row">
            <div class="col-sm-12" v-bind:class="{'col-md-6': answer_type_check}">
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
                :open-delay="1000"
              >
              <div slot="content"><b>RADIO</b> - jedna odpowiedź z wielu, <b>CHECKBOX</b> - wiele odpowiedzi z wielu,<br>
                <b>TEXTBOX</b> - pole tekstowe (wolna odpowiedź), <b>SELECT</b> - jedna odpowiedź z wielu - lista rozwijana (duża ilość odpowiedzi),<br>
                <b>DATE</b> - pole tekstowe/kalendarz w formacie YYYY-MM-DD np. 2016-11-14, <b>YEAR</b> - pole tekstowe w formacie YYYY np. 2016,<br>
                <b>MONTH</b> - pole tekstowe/select oznaczający miesiąc, <b>YEAR_MONTH</b> - pole tekstowe/kalendarz w formacie YYYY-MM np. 2016-11</div>
            <label>Typ odpowiedzi</label>
            </el-tooltip>
              <fg-input :error="getError('answer_type')" data-vv-as="Typ odpowiedzi">
                <el-select
                  v-model="answerModel.answer_type"
                  name="answer_type"
                  data-vv-as="Typ odpowiedzi"
                  v-validate="answerModelValidations.answer_type"
                  filterable
                >
                  <el-option
                    v-for="type in answer_field_types"
                    :label="type.answer_field_type"
                    :value="type.answer_field_type"
                    :key="type.answer_field_type_id"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>

            <div class="col-sm-12 col-md-6" v-if="answer_type_check">
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
                :open-delay="1000"
              >
              <div slot="content"><b>UWAGA!</b> Reguły walidacji wybieramy tylko dla typu <b>TEXTBOX</b>.<br>
                <b>NOT_EMPTY</b> - pole tekstowe nie może pozostać puste.<br>
                <b>IS_TEXT</b> - wprowadzana przez użytkownika wartość musi być tekstem.<br>
                <b>IS_DATE</b> - wprowadzana wartość musi być w formacie YYYY-MM-DD.<br>
                <b>IS_YEAR</b> - wprowadzana wartość musi być rokiem z przedziału od 1919 do 2009.<br>
                <b>IS_MONTH</b> - wprowadzana wartość musi być miesiącem (wartość liczbowa w zakresie od 1 do 12).<br>
                <b>IS_MONTH_YEAR</b> - wprowadzana wartość musi być w formacie YYYY-MM.</div>
            <label>Reguły walidacji</label>
            </el-tooltip>
              <fg-input :error="getError('validation')" data-vv-as="Reguły walidacji">
                <el-select
                  v-model="answerRulesModel.validation"
                  name="validation"
                  data-vv-as="Reguły walidacji"
                  v-validate="answerRulesModelValidations.validation"
                  filterable
                  multiple
                  clearable
                >
                  <el-option
                    v-for="validation in answer_rules_dict"
                    :label="validation"
                    :value="validation"
                    :key="validation"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
                :open-delay="1000"
              >
              <div slot="content">Czas po upływie którego pytanie będzie mogło być ponownie zadane użytkownikowi. Liczony w dniach.<br>
                <b>UWAGA!</b> Aby pytanie świeciło się za każdym razem, należy ustawić wartość 0 (zero).</div>
            <label>Czas ważności</label>
            </el-tooltip>
              <fg-input
                type="number"
                name="expiration_days"
                data-vv-as="Czas ważności"
                v-validate="answerModelValidations.expiration_days"
                :error="getError('expiration_days')"
                v-model="answerModel.expiration_days"
              ></fg-input>
            </div>

            <div class="col-sm-12 col-md-6">
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
                :open-delay="1000"
              >
              <div slot="content">Pytanie wywołane udzieleniem tej odpowiedzi.</div>
            <label>Pytanie wymuszone</label>
            </el-tooltip>
              <fg-input :error="getError('next_question_id')" data-vv-as="Pytanie wymuszone">
                <el-select
                  id="nextQuestionIDSelect"
                  v-on:visible-change="getNextQuestionIDSelectWidth()"
                  v-model="answerModel.next_question_id"
                  name="next_question_id"
                  data-vv-as="Pytanie wymuszone"
                  v-validate="answerModelValidations.next_question_id"
                  filterable
                  clearable
                >
                  <el-option
                    :style="nextQuestionIDSelectWidth !== null ? 'width: ' + nextQuestionIDSelectWidth + 'px' : 'width: 300px'"
                    v-for="question in questions"
                    :label="'[' + question.question_id + '] ' + question.question_content"
                    :value="question.question_id"
                    :key="question.question_id"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12">
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
                :open-delay="1000"
              >
              <div slot="content">Lista klientów/kanałów na potrzeby sprzedaży.</div>
            <label>Klienci</label>
            </el-tooltip>
              <fg-input :error="getError('client_id')" data-vv-as="Klienci">
                <el-select
                  v-model="clientModel.client_id"
                  name="client_id"
                  data-vv-as="Klienci"
                  multiple
                  filterable
                >
                  <el-option
                    v-for="client in clients"
                    :label="client.client_name"
                    :value="client.client_id"
                    :key="client.client_id"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>
          </div>

          <div class="row vertical-center">
            <div class="col-xs-4 col-sm-4 col-md-5">
              <label>Kategorie</label>
              <fg-input :error="getError('answer_category')" data-vv-as="Kategorie">
                <el-select
                  v-model="checkedCategoryIDs"
                  name="answer_category"
                  data-vv-as="Kategorie"
                  filterable
                  multiple
                >
                  <el-option
                    v-for="category in categorys"
                    :label="category.category_name"
                    :value="category.id"
                    :key="category.id"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-xs-2 col-sm-4 col-md-2 text-center">
              <p-button class="buttonNoMargin" type="default" link v-on:click="showCategoryModal()">Kategorie</p-button>
              <p-button class="buttonNoMargin" type="default" link v-on:click="showTagModal()">Tagi</p-button>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-5">
              <label>Tagi</label>
              <fg-input :error="getError('answer_tag')" data-vv-as="Tagi">
                <el-select
                  v-model="checkedTagIDs"
                  name="answer_tag"
                  data-vv-as="Tagi"
                  allow-create
                  filterable
                  multiple
                >
                  <el-option
                    v-for="tag in tags"
                    :label="tag.tag_name"
                    :value="tag.id"
                    :key="tag.id"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>
          </div>

          <!-- potrzebne żeby zmusić computed do przeliczenia i zaktualizowania komponentu -->
          <div style="visibility: hidden;"> {{ this.answerChanged }}</div>

        </div>
        <div class="card-footer text-center">
          <router-link :to="'/questions/question_form/' + question_id">
            <p-button type="default" link style="margin-right: 5vw">
              Anuluj
            </p-button>
          </router-link>
          <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
          <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// ui components
import { Select, Option, Switch, Input, Tooltip } from "element-ui";

// vuex actions
import { LOAD_QUESTIONS } from "src/store/actions/questions/questions";
import { LOAD_ANSWERS, ANSWERS_INVALID, SAVE_ANSWER } from "src/store/actions/questions/answers";
import { LOAD_ANSWER_STATS, ANSWER_STATS_INVALID, SAVE_ANSWER_STATS } from "src/store/actions/questionForm/answer_stats";
import { LOAD_ANSWER_RULES, ANSWER_RULES_INVALID, SAVE_ANSWER_RULES } from "src/store/actions/questionForm/answer_rules";
import { LOAD_ANSWER_FIELD_TYPES } from "src/store/actions/questionForm/answer_field_types";
import { LOAD_ANSWER_CLIENT, ANSWER_CLIENT_INVALID, SAVE_ANSWER_CLIENT } from "src/store/actions/questionForm/answer_client";
import { LOAD_ANSWER_TAGS, ANSWER_TAGS_INVALID, SAVE_ANSWER_TAG } from "src/store/actions/questionForm/answer_tags";
import { LOAD_ANSWER_CATEGORYS, ANSWER_CATEGORYS_INVALID, SAVE_ANSWER_CATEGORY } from "src/store/actions/questionForm/answer_categorys";
import { LOAD_CLIENTS } from "src/store/actions/questionForm/clients";
import { LOAD_TAGS, TAGS_INVALID } from "src/store/actions/questionForm/tags";
import { LOAD_CATEGORYS, CATEGORYS_INVALID } from "src/store/actions/questionForm/categorys";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

import CategoryForm from "src/components/Utils4/questions/forms/CategoryForm.vue";
import TagForm from "src/components/Utils4/questions/forms/TagForm.vue";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [Input.name]: Input,
    [Tooltip.name]: Tooltip
  },
  data() {
    return {
      answerModel: {
        answer_id: null,
        question_id: null,
        answer_content: "",
        answer_position: null,
        next_question_id: null,
        before_next_question_id: null,
        answer_type: '',
        expiration_days: 0,
        is_active: 1
      },
      answerModelValidations: {
        question_id: {
          required: true,
          numeric: true,
          min: 1
        },
        answer_id: {
          numeric: true,
          min: 1
        },
        answer_content: {
          // regex: regexp.TEXT_REGEXP,
          min: 0,
          max: 256
        },
        expiration_days: {
          required: true,
          numeric: true,
          min: 1,
          max: 256
        },
        answer_type: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 1,
          max: 32
        },
        next_question_id: {
          numeric: true,
        },
        is_active: {
          numeric: true,
        },
      },
      answerRulesModel: {
        answer_id: null,
        validation: []
      },
      answerRulesModelValidations: {
        answer_id: {
          numeric: true,
          min: 1
        }
      },
      answerRulesCompareModel: {
        answer_id: null,
        validation: []
      },
      answerStatsModel: {
        answer_id: null,
        description: ''
      },
      answerStatsModelValidations: {
        answer_id: {
          required: true,
          numeric: true,
          min: 1
        },
        description: {
          // regex: "^[-0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ :=,&_!@/\\.+{}]+$",
          min: 1,
          max: 512
        },
      },
      clientModel: {
        answer_id: null,
        client_id: [],
      },
      clientCompareModel: {
        answer_id: null,
        client_id: []
      },
      clientModelValidation: {
        // answer_id: {
        //   required: true,
        //   numeric: true,
        //   min: 1
        // }
      },
      answer_rules_dict: [
        'NOT_EMPTY',
        'IS_TEXT',
        'IS_CHILD',
        'IS_DATE',
        'IS_YEAR',
        'IS_MONTH',
        'IS_YEAR_MONTH',
        'IS_YEAR_MONTH_FUTURE'
      ],
      editMode: true,
      loading: false,
      lastAnswerID: null,
      routingCounter: 0,
      nextQuestionIDSelectWidth: null,
      checkedCategoryIDs: [],
      checkedCategoryIDsReference: [],
      checkedTagIDs: [],
      checkedTagIDsReference: []
    }
  },
  computed: {
    question_id() {
      if(this.$route.params.id !== undefined) {
        let string = this.$route.params.id;
        return string.match(/[0-9]+/g)[0];
      } else return null;
    },
    answer_id() {
      if(this.$route.params.id !== undefined) {
        let string = this.$route.params.id;
        return string.match(/[0-9]+/g)[1];
      } else return 'new_answer';
    },
    questions() {
      return this.$store.getters.getQuestions;
    },
    answers() {
      return this.$store.getters.getAnswers;
    },
    question_answers() {
      let answers = this.answers[this.question_id];
      let result = [];
      if(answers !== undefined) {
        result = Object.values(this.answers[this.question_id]).sort((a,b) => (a.answer_position > b.answer_position) ? 1 : -1);
      }
      return result;
    },
    answer_client() {
      return this.$store.getters.getAnswerClient;
    },
    clients() {
      return Object.values(this.$store.getters.getClients)
    },
    answer_stats() {
      return this.$store.getters.getAnswerStats;
    },
    answer_rules() {
      return this.$store.getters.getAnswerRules;
    },
    answer_field_types() {
      // return this.$store.getters.getAnswerFieldTypes;
      let fieldTypes = this.$store.getters.getQuestionTemplateFieldTypes;
      let questionData = this.$store.getters.getQuestionData[this.question_id];
      let result = [];
      for(let i in fieldTypes) {
        if(fieldTypes[i][0].template_name === questionData.template_name) {
          result = fieldTypes[i];
        }
      }
      return result;
    },
    answer_categorys() {
      return this.$store.getters.getAnswerCategorys[parseInt(this.answer_id)];
    },
    categorys() {
      return Object.values(this.$store.getters.getCategorys);
    },
    answer_tags() {
      return this.$store.getters.getAnswerTags[parseInt(this.answer_id)];
    },
    tags() {
      return Object.values(this.$store.getters.getTags);
    },
    answerChanged() { // computed służący do przeładowywania danych komponentu po zmianie edytowanej odpowiedzi
      if(this.routingCounter > 0) { // inicjacja
        let currentID = this.answer_id !== undefined ? this.answer_id : null; // przypisanie aktualnego ID odpowiedzi
        if(currentID !== this.lastAnswerID) { // wykrycie zmiany odpowiedzi
          if(currentID !== null) { // przypadek zmiany edytowanej odpowiedzi - zainicjowanie danych
            this.editMode = true;
            this.initData();
            this.initCategorys(currentID);
            this.initTags(currentID);
          } else { // przypadek tworzenia nowej odpowiedzi - wyczyszczenie wszystkich modeli
            this.editMode = false;
            this.answerModel = {
              answer_id: null,
              question_id: null,
              answer_content: "",
              answer_position: null,
              next_question_id: null,
              before_next_question_id: null,
              answer_type: '',
              expiration_days: 0,
              is_active: 1
            };
            this.answerRulesModel = {
              answer_id: null,
              validation: []
            };
            this.answerStatsModel = {
              answer_id: null,
              description: ''
            };
            this.checkedCategoryIDs = [];
            this.checkedCategoryIDsReference = [];
            this.checkedTagIDs = [];
            this.checkedTagIDsReference = [];
            this.initClients();
          }
          this.lastAnswerID = currentID;
        }
        this.routingCounter += 1;
      }
      return this.routingCounter;
    },
    answer_type_check() {
      let result = false;
      if(this.answerModel.answer_type === 'TEXTBOX' ||
        this.answerModel.answer_type === 'DATE' ||
        this.answerModel.answer_type === 'MONTH' ||
        this.answerModel.answer_type === 'YEAR' ||
        this.answerModel.answer_type === 'YEAR-MONTH') {
          result = true
        } else {
          result = false
        }
      return result
    }
  },
  methods: {
    async loadData(force) {
      let filters = {
        answer_id: parseInt(this.answer_id)
      };

      const p1 = this.$store.dispatch(LOAD_ANSWER_STATS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const p2 = this.$store.dispatch(LOAD_ANSWER_FIELD_TYPES, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const p3 = this.$store.dispatch(LOAD_ANSWER_RULES, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const p4 = this.$store.dispatch(LOAD_CLIENTS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const p5 = this.$store.dispatch(LOAD_ANSWER_CLIENT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const p6 = this.$store.dispatch(LOAD_ANSWER_CATEGORYS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const p7 = this.$store.dispatch(LOAD_CATEGORYS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const p8 = this.$store.dispatch(LOAD_ANSWER_TAGS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const p9 = this.$store.dispatch(LOAD_TAGS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const p10 = this.$store.dispatch(LOAD_QUESTIONS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const p11 =  this.$store.dispatch(LOAD_ANSWERS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      const end = await Promise.all([p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11]);

      if (force) {
        this.$store.commit(ANSWERS_INVALID);
        this.$store.commit(ANSWER_CATEGORYS_INVALID);
        this.$store.commit(ANSWER_TAGS_INVALID);
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      let answer_id = this.answer_id;

      // answer model
      let answerModelCheck = false;
      if(this.answers[this.question_id] !== undefined && this.answers[this.question_id][this.answer_id] !== undefined) {
        if(JSON.stringify(this.answerModel) !== JSON.stringify(this.answers[this.question_id][this.answer_id])) {
          this.answerModel.question_id = this.answerModel.question_id === null ? this.question_id: this.answerModel.question_id;
          this.answerModel.answer_position = this.answerModel.answer_position === null ? (this.question_answers[this.question_answers.length - 1].answer_position + 1) : this.answerModel.answer_position;
          answerModelCheck = true;
        }
      } else {
        this.answerModel.question_id = this.answerModel.question_id === null ? this.question_id: this.answerModel.question_id;
        this.answerModel.answer_position = this.answerModel.answer_position === null ? (this.question_answers.length + 1) : this.answerModel.answer_position;
        answerModelCheck = true;
      }

      // answer model
      if(isValid) {
        let answerModel = {insert_model: {}, update_model: {}};
        if(this.answerModel.next_question_id === '') this.answerModel.next_question_id = null;
        if(this.answer_id !== undefined && this.answer_id !== null) {
          answerModel.update_model[answer_id] = this.answerModel;
        } else {
          answerModel.insert_model['new_answer'] = this.answerModel;
        }
        if(answerModelCheck) {
          await this.$store.dispatch(SAVE_ANSWER, answerModel).catch(error => {
            console.log(error.response)
            eventBus.$emit("notify", {
              message: "Błąd zapisu odpowiedzi!",
              type: "warning"
            });
          })
          let saveAnswerResult = JSON.parse(this.$store.getters.getAnswersModifiedRecords);
          if(saveAnswerResult.insert || saveAnswerResult.update) {
            eventBus.$emit("notify", {
              message: `Odpowiedź została ${saveAnswerResult.insert ? 'dodana' : 'zmodyfikowana'}`,
              type: "success"
            });
            this.$store.commit(ANSWERS_INVALID);

            if(saveAnswerResult.insert) answer_id = Object.values(JSON.parse(this.$store.getters.getAnswersModifiedRecords).insert)[0];
          }
        }
      }

      // kategorie
      let categoryModelCheck = false;
      let checkedCategoryIDs = this.checkedCategoryIDs;
      let checkedCategoryIDsReference = this.checkedCategoryIDsReference;
      let addCategorys = checkedCategoryIDs.filter((o) => checkedCategoryIDsReference.indexOf(o) === -1);
      let deleteCategorys = checkedCategoryIDsReference.filter((o) => checkedCategoryIDs.indexOf(o) === -1);
      let categoryModel = {answer_id: answer_id, addCategorys: addCategorys, deleteCategorys: deleteCategorys};
      if(addCategorys.length > 0 || deleteCategorys.length > 0) {
        categoryModelCheck = true;
      }
      // tagi
      let tagModelCheck = false;
      let checkedTagIDs = this.checkedTagIDs;
      let checkedTagIDsReference = this.checkedTagIDsReference;
      let addTags = checkedTagIDs.filter((o) => checkedTagIDsReference.indexOf(o) === -1);
      let newTags = addTags.filter(tag => typeof tag !== 'number');
      addTags = addTags.filter(tag => typeof tag === 'number');
      let deleteTags = checkedTagIDsReference.filter((o) => checkedTagIDs.indexOf(o) === -1);
      let tagModel = {answer_id: answer_id, newTags: newTags, addTags: addTags, deleteTags: deleteTags};
      if(addTags.length > 0 || deleteTags.length > 0 || newTags.length > 0) {
        tagModelCheck = true;
      }

      // client model
      let clientModelCheck = false;
      let client_ids = this.clientModel.client_id;
      let client_compare_ids = this.clientCompareModel.client_id;
      let clientModelInsert = {};
      let clientModelDelete = {};

      let difference1 = client_ids.filter(x => !client_compare_ids.includes(x));
      if(difference1.length > 0) {
        clientModelInsert[answer_id] = difference1;
      }
      let difference2 = client_compare_ids.filter(x => !client_ids.includes(x));
      if(difference2.length > 0) {
        clientModelDelete[answer_id] = difference2;
      }
      let clientModel = {clientModelInsert: clientModelInsert, clientModelDelete: clientModelDelete};
      if(Object.values(clientModelInsert).length > 0 || Object.values(clientModelDelete).length > 0) {
        clientModelCheck = true;
      }

      // rules model
      let rulesModelCheck = false;
      let answerRulesModel = this.answerRulesModel.validation;
      let answerRulesCompareModel = this.answerRulesCompareModel.validation;
      let answerRulesModelInsert = {};
      let answerRulesModelUpdate = {};
      let answerRulesModelDelete = {};
      let rulesModel = {}
      if(this.answer_type_check) {
        if(JSON.stringify(answerRulesModel) !== JSON.stringify(answerRulesCompareModel)) {
          if(answerRulesModel.length === 0 && answerRulesCompareModel.length > 0) {
            answerRulesModelDelete = {mode: 'delete', answer_id: answer_id, validation: answerRulesCompareModel};
          } else if(answerRulesModel.length > 0 && answerRulesCompareModel.length === 0) {
            answerRulesModelInsert = {mode: 'insert', answer_id: answer_id, validation: answerRulesModel};
          } else {
            answerRulesModelUpdate = {mode: 'update', answer_id: answer_id, validation: answerRulesModel};
          }
          rulesModel = {answerRulesModelInsert, answerRulesModelUpdate, answerRulesModelDelete};
          rulesModelCheck = true;
        }
      } else {
        if(answerRulesCompareModel.length > 0) {
          answerRulesModelDelete = {mode: 'delete', answer_id: answer_id, validation: answerRulesCompareModel};
          rulesModel = {answerRulesModelInsert, answerRulesModelUpdate, answerRulesModelDelete};
          rulesModelCheck = true;
        }
      }

      // stats model
      let statsModelCheck = false;
      let answerStatsModel = this.answerStatsModel;
      let answerStatsCompareModel = undefined;
      if(this.answer_stats[answer_id] !== undefined) {
        answerStatsCompareModel = JSON.parse(JSON.stringify(this.answer_stats[answer_id]));
      }
      let statsModelInsert = {};
      let statsModelUpdate = {};
      let statsModelDelete = {};
      let statsModel = {};
      if(answerStatsCompareModel !== undefined) {
        if(answerStatsModel.description !== answerStatsCompareModel.description) {
          if(answerStatsModel.description.length === 0) {
            statsModelDelete[answer_id] = {answer_id: answer_id, description: answerStatsCompareModel.description};
          } else if(answerStatsModel.description.length !== 0) {
            statsModelUpdate[answer_id] = {answer_id: answer_id, description: answerStatsModel.description};
          }
        }
      } else {
        if(answerStatsModel.description.length > 0) {
          statsModelInsert[answer_id] = {answer_id: answer_id, description: answerStatsModel.description};
        }
      }
      if(Object.keys(statsModelDelete).length > 0 || Object.keys(statsModelUpdate).length > 0 || Object.keys(statsModelInsert).length > 0) {
        statsModel = {statsModelDelete, statsModelUpdate, statsModelInsert};
        statsModelCheck = true;
      }

      if (isValid && answer_id !== undefined && answer_id !== null) {
        // client model
        if(clientModelCheck) {
          await this.$store.dispatch(SAVE_ANSWER_CLIENT, clientModel).catch(error => {
            console.log(error.response)
            eventBus.$emit("notify", {
              message: "Błąd zapisu klientów!",
              type: "warning"
            });
          })
          let saveClientResult = JSON.parse(this.$store.getters.getAnswerClientModifiedRecords);
          if(saveClientResult.insert > 0 || saveClientResult.delete > 0) {
            eventBus.$emit("notify", {
              message: "Klienci zostali zmodyfikowani",
              type: "success"
            });
            this.$store.commit(ANSWER_CLIENT_INVALID);
          }
        }

        // stats model
        if(statsModelCheck) {
          await this.$store.dispatch(SAVE_ANSWER_STATS, statsModel).catch(error => {
            console.log(error.response)
            eventBus.$emit("notify", {
              message: "Błąd zapisu statystyk!",
              type: "warning"
            });
          })
          let saveStatsResult = JSON.parse(this.$store.getters.getAnswerStatsModifiedRecords);
          if(saveStatsResult.insert > 0 || saveStatsResult.update > 0 || saveStatsResult.delete > 0) {
            eventBus.$emit("notify", {
              message: "Statystyki zostały zmodyfikowane",
              type: "success"
            });
            this.$store.commit(ANSWER_STATS_INVALID);
          }
        }

        // tag model
        if(tagModelCheck) {
          await this.$store.dispatch(SAVE_ANSWER_TAG, tagModel).catch(error => {
            console.log(error.response)
            eventBus.$emit("notify", {
              message: "Błąd zapisu tagów!",
              type: "warning"
            });
          })
          let saveTagResult = JSON.parse(this.$store.getters.getAnswerTagsModifiedRecords);
          if(saveTagResult.insert > 0 || saveTagResult.create > 0 || saveTagResult.delete > 0 || saveTagResult.copy > 0) {
            eventBus.$emit("notify", {
              message: "Tagi zostały zmodyfikowane",
              type: "success"
            });
            this.$store.commit(ANSWER_TAGS_INVALID);
          }
        }

        // categorys model
        if(categoryModelCheck) {
          await this.$store.dispatch(SAVE_ANSWER_CATEGORY, categoryModel).catch(error => {
            console.log(error.response)
            eventBus.$emit("notify", {
              message: "Błąd zapisu kategorii!",
              type: "warning"
            });
          })
          let saveCategoryResult = JSON.parse(this.$store.getters.getAnswerCategorysModifiedRecords);
          if(saveCategoryResult.insert > 0 || saveCategoryResult.delete > 0 || saveCategoryResult.copy > 0) {
            eventBus.$emit("notify", {
              message: "Kategorie zostały zmodyfikowane",
              type: "success"
            });
            this.$store.commit(ANSWER_CATEGORYS_INVALID);
          }
        }

        // rules model
        if(rulesModelCheck) {
          await this.$store.dispatch(SAVE_ANSWER_RULES, rulesModel).catch(error => {
            console.log(error.response)
            eventBus.$emit("notify", {
              message: "Błąd zapisu reguł!",
              type: "warning"
            });
          })
          let saveRulesResult = JSON.parse(this.$store.getters.getAnswerRulesModifiedRecords);
          if(saveRulesResult.insert > 0 || saveRulesResult.update > 0 || saveRulesResult.delete > 0 || saveRulesResult.copy > 0) {
            eventBus.$emit("notify", {
              message: "Reguły zostały zmodyfikowane",
              type: "success"
            });
            this.$store.commit(ANSWER_RULES_INVALID);
          }
        }
        // this.$router.push({ name: "Pytania" }) // przekieruj do listy
        this.$router.push("/questions/question_form/" + this.question_id);
      }
    },
    initData() {
      let answer = JSON.parse(JSON.stringify(this.answers[this.question_id][this.answer_id]));
      this.answerModel = Object.assign(this.answerModel, answer); // inicjacja modelu odpowiedzi
      if(this.answer_stats[this.answer_id] !== undefined) { // inicjacja modelu statystyk
        let answer_stats = JSON.parse(JSON.stringify(this.answer_stats[this.answer_id]));
        this.answerStatsModel = Object.assign(this.answerStatsModel, answer_stats);
      }
      else { // wyczyszczenie statystyk w przypadku braku danych
        this.answerStatsModel.description = '';
      }
      if(this.answer_rules[this.answer_id] !== undefined) { // inicjacja modelu reguł walidacji
        let answer_rules = JSON.parse(JSON.stringify(this.answer_rules[this.answer_id]));
        if(answer_rules.validation !== null) {
          let validation_array = answer_rules.validation.split(",");
          answer_rules.validation = [];
          for(let i in validation_array)
          {
            answer_rules.validation.push(validation_array[i]);
          }
        } else {
          answer_rules.validation = [];
        }
        this.answerRulesModel = Object.assign(this.answerRulesModel, answer_rules);
      } else { // wyczyszczenie reguł w przypadku braku danych
        this.answerRulesModel.validation = [];
      }
        this.answerRulesCompareModel = JSON.parse(JSON.stringify(this.answerRulesModel)); // stworzenie modelu reguł do porównania
        this.initClients();
    },
    initClients() { // inicjacja klientów
      let answer_id = this.answer_id;
      let answer_client = JSON.parse(JSON.stringify(this.answer_client));
      let clients = JSON.parse(JSON.stringify(this.clients));
      this.clientModel.client_id = [];
      this.clientModel.answer_id = this.answer_id;
      if(answer_client[answer_id] !== undefined) {
        let clients_array = answer_client[answer_id];
        for(let j in clients_array) {
          if(!this.clientModel.client_id.some(item => item === clients_array[j].client_id)) {
            this.clientModel.client_id.push(clients_array[j].client_id);
          }
        }
      }
      this.clientCompareModel = JSON.parse(JSON.stringify(this.clientModel));
    },
    initCategorys(current_id) { // inicjacja kategorii
      if(current_id !== undefined && this.answer_categorys !== undefined) {
        this.checkedCategoryIDs = [...this.answer_categorys];
        this.checkedCategoryIDsReference = [...this.checkedCategoryIDs];
      } else {
        this.checkedCategoryIDs = [];
        this.checkedCategoryIDsReference = [];
      }
    },
    initTags(current_id) { // inicjacja tagów
      if(current_id !== undefined && this.answer_tags !== undefined) {
        this.checkedTagIDs = [...this.answer_tags];
        this.checkedTagIDsReference = [...this.checkedTagIDs];
      } else {
        this.checkedTagIDs = [];
        this.checkedTagIDsReference = [];
      }
    },
    showCategoryModal() { // wyświetlenie modala kategorii
      this.$modal.show(CategoryForm, {
        checked_ids_props: this.checkedCategoryIDs,
        buttons: [{
          title: 'Zamknij',
          handler: () => {
            this.$modal.hide(CategoryForm);
          }
        }]
      }, {
        draggable: false,
        width: '80%',
        height: 'auto',
        pivotX: 0.5,
        pivotY: 0.6,
        adaptive: true
      })
    },
    showTagModal() { // wyświetlenie modala tagów
      this.$modal.show(TagForm, {
        checked_ids_props: this.checkedTagIDs,
        buttons: [{
          title: 'Zamknij',
          handler: () => {
            this.$modal.hide(TagForm);
          }
        }]
      }, {
        draggable: false,
        width: '80%',
        height: 'auto',
        pivotX: 0.5,
        pivotY: 0.6,
        adaptive: true
      })
    },
    getNextQuestionIDSelectWidth() {
      this.nextQuestionIDSelectWidth = document.getElementById('nextQuestionIDSelect').offsetWidth;
    },
    async forceReload() {
      this.$store.commit(CATEGORYS_INVALID);
      this.$store.commit(TAGS_INVALID);
      this.$store.commit(ANSWER_CLIENT_INVALID);
      this.$store.commit(ANSWERS_INVALID);
      this.$store.commit(ANSWER_STATS_INVALID);
      this.$store.commit(ANSWER_TAGS_INVALID);
      this.$store.commit(ANSWER_CATEGORYS_INVALID);
      this.$store.commit(ANSWER_RULES_INVALID);
      this.loading = true;
      await this.loadData(true);
      this.lastAnswerID = null;
      this.loading = false;
    }
  },
  mounted() {
    eventBus.$on('answerCategorysChanged', changeHandler => { // zapis do obiektu zmian kategorii
      let changeArray = Object.keys(changeHandler);
      changeArray = changeArray.map(el =>  el = parseInt(el));
      let checkedCategoryIDs = this.checkedCategoryIDs;

      let unique1 = changeArray.filter((o) => checkedCategoryIDs.indexOf(o) === -1);
      let unique2 = checkedCategoryIDs.filter((o) => changeArray.indexOf(o) === -1);
      const difference = unique1.concat(unique2);
      this.checkedCategoryIDs = difference;
    });
    eventBus.$on('answerTagsChanged', changeHandler => { // zapis do obiektu zmian tagów
      let changeArray = Object.keys(changeHandler);
      changeArray = changeArray.map(el =>  el = parseInt(el));
      let checkedTagIDs = this.checkedTagIDs;

      let unique1 = changeArray.filter((o) => checkedTagIDs.indexOf(o) === -1);
      let unique2 = checkedTagIDs.filter((o) => changeArray.indexOf(o) === -1);
      const difference = unique1.concat(unique2);
      this.checkedTagIDs = difference;
    });
  },
  async created() {
    this.loading = true;
    await this.loadData();
    if (!!this.answer_id) {
      this.initData();
      this.initCategorys();
      this.initTags();
      this.editMode = true;
    } else {
      this.editMode = false;
    }
    this.loading = false;
    this.routingCounter += 1;
    eventBus.$on("forceReload", () => {
      this.forceReload();
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style scoped>

.selectedID {
  font-weight: bold;
}

li {
  list-style: none;
}

a {
  color: black;
  cursor: pointer;
}

a:hover {
  color:black;
}

a:link {
  color:black;
}

a:visited {
  color:black;
}

a:active {
  color:black;
}

.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.buttonNoMargin {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
