<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="card demo-icons">
          <div class="card-header">
            <h5 class="card-title">Edycja uprawnień roli: {{ getRoleNameById }}</h5>
          </div>
          <div class="card-body all-icons">
            <div class="row">
              <div class="col-sm-6">
                <el-table
                  :data="grantedPrivs"
                  style="cursor: pointer"
                  :default-sort="{prop: 'role_priv_id', order: 'ascending'}"
                >
                <el-table-column label="Uprawnienia roli" property="priv_name" align="center" sortable>
                  <template v-slot="props">
                    <div @click="changeGranted(props.row)" v-if="!props.row.priv_description">
                      {{props.row.priv_name}}
                    </div>
                    <div @click="changeGranted(props.row)" v-else>
                      {{props.row.priv_description}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Tryb" property="priv_mode" align="center" sortable>
                  <template v-slot="props">
                    <div @click="changeGranted(props.row)">
                      {{props.row.priv_mode}}
                    </div>
                  </template>
                </el-table-column>
                </el-table>
              </div>
              <div class="col-sm-6">
                <el-table
                  :data="availablePrivs"
                  style="cursor: pointer"
                  :default-sort="{prop: 'role_priv_id', order: 'ascending'}"
                >
                  <el-table-column label="Dostępne uprawnienia" property="priv_name" align="center" sortable>
                  <template v-slot="props">
                    <div @click="changeGranted(props.row)" v-if="!props.row.priv_description">
                      {{props.row.priv_name}}
                    </div>
                    <div @click="changeGranted(props.row)" v-else>
                      {{props.row.priv_description}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Tryb" property="priv_mode" align="center" sortable>
                  <template v-slot="props">
                    <div @click="changeGranted(props.row)">
                      {{props.row.priv_mode}}
                    </div>
                  </template>
                </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="card-footer text-center" align="center">
              <router-link to="/admin/roles">
                <p-button type="default" link style="margin-right: 5vw">Anuluj</p-button>
              </router-link>
              <p-button type="success" @click.prevent="saveData">Zapisz</p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

// ui components
import { Table, TableColumn } from "element-ui";

// vuex actions
import { LOAD_ROLE_PRIVS, ROLE_PRIVS_INVALID, SAVE_ROLE_PRIV } from "src/store/actions/admin/role_privs";
import { LOAD_PERMISSIONS, PERMISSIONS_INVALID } from "src/store/actions/admin/permissions";

// event bus
import { eventBus } from "src/main";

Vue.use(Table);
Vue.use(TableColumn);

export default {
  data() {
    return {
      allPrivs: []
    };
  },
  computed: {
    role_id() {
      return this.$route.params.id;
    },
    availablePrivs() {
      // table data needs to by an array
      return this.allPrivs.filter(row => !row.granted);
    },
    grantedPrivs() {
      // table data needs to by an array
      return this.allPrivs.filter(row => row.granted);
    },
    model() {
      return {
        role_id: this.role_id,
        priv_ids: this.grantedPrivs.map(row => row.priv_id)
      };
    },
    getRoleNameById() {
      return this.$store.getters.getRoleNameById(this.role_id);
    },
  },
  methods: {
    initPrivs() {
      const privs = Object.values(this.$store.getters.getPermissions);
      const role_privs_ids = Object.values(
        this.$store.getters.getRolePrivs
      ).map(row => row.priv_id);
      this.allPrivs = privs.map(row =>
        Object.assign({}, row, {
          granted: role_privs_ids.includes(row.priv_id)
        })
      );
    },
    changeGranted(row) {
      row.granted =!(row.granted);
    },
    async loadData(force) {
      const filters = {
        role_id: this.$route.params.id
      };

      if (force) {
        this.$store.commit(PERMISSIONS_INVALID);
        this.$store.commit(ROLE_PRIVS_INVALID);
      }

      await this.$store.dispatch(LOAD_ROLE_PRIVS, filters)
        .catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        })

      await this.$store.dispatch(LOAD_PERMISSIONS, filters)
        .catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        })

      this.initPrivs();
    },
    async saveData() {
      await this.$store.dispatch(SAVE_ROLE_PRIV, this.model).catch(error => {
        console.log(error.response)
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning"
        });
      })

      if (this.$store.getters.getRolePrivsModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Rekord został zmodyfikowany",
          type: "success"
        });
      this.$store.commit(ROLE_PRIVS_INVALID) // oznacz nieaktualność danych
      this.$router.push({ name: "Role" }) // przekieruj do listy
      }
    }
  },
  created() {
    this.loadData(true);
  }
};
</script>
<style>
</style>
