export const LOAD_MAILING_STATS_EFFICIENCY = "LOAD_MAILING_STATS_EFFICIENCY";
export const MAILING_STATS_EFFICIENCY_LOADING = "MAILING_STATS_EFFICIENCY_LOADING";
export const MAILING_STATS_EFFICIENCY_LOADING_SUCCESS = "MAILING_STATS_EFFICIENCY_LOADING_SUCCESS";
export const MAILING_STATS_EFFICIENCY_LOADING_ERROR = "MAILING_STATS_EFFICIENCY_LOADING_ERROR";
export const MAILING_STATS_EFFICIENCY_INVALID = "MAILING_STATS_EFFICIENCY_INVALID";

export const SAVE_MAILING_STATS_EFFICIENCY = "SAVE_MAILING_STATS_EFFICIENCY";
export const MAILING_STATS_EFFICIENCY_SAVING = "MAILING_STATS_EFFICIENCY_SAVING";
export const MAILING_STATS_EFFICIENCY_SAVING_SUCCESS = "MAILING_STATS_EFFICIENCY_SAVING_SUCCESS";
export const MAILING_STATS_EFFICIENCY_SAVING_ERROR = "MAILING_STATS_EFFICIENCY_SAVING_ERROR";
