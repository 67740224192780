<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="card demo-icons">
          <div class="card-header">
            <h5 class="card-title">Edycja roli użytkownika {{ getUserLoginById }}</h5>
          </div>
          <div class="card-body all-icons">
            <div class="row">
              <div class="col-sm-6">
                <el-table
                  :data="grantedRoles"
                  style="cursor: pointer"
                  :default-sort="{prop: 'user_role_id', order: 'ascending'}"
                >
                <el-table-column label="Role użytkownika" property="role_name" align="center" sortable>
                  <template v-slot="props">
                    <div @click="changeGranted(props.row)">
                      {{props.row.role_name}}
                    </div>
                  </template>
                </el-table-column>
                </el-table>
              </div>
              <div class="col-sm-6">
                <el-table
                  :data="availableRoles"
                  style="cursor: pointer"
                  :default-sort="{prop: 'role_add_time', order: 'ascending'}"
                >
                  <el-table-column label="Dostępne role" property="role_name" align="center" sortable>
                  <template v-slot="props">
                    <div @click="changeGranted(props.row)">
                      {{props.row.role_name}}
                    </div>
                  </template>
                </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="card-footer text-center" align="center">
              <router-link to="/admin/users">
                <p-button type="default" link style="margin-right: 5vw">Anuluj</p-button>
              </router-link>
              <p-button type="success" @click.prevent="saveData">Zapisz</p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

// ui components
import { Table, TableColumn } from "element-ui";

// vuex actions
import { LOAD_ROLES, ROLES_INVALID } from "src/store/actions/admin/roles";
import { LOAD_USER_ROLES, USER_ROLES_INVALID, SAVE_USER_ROLE } from "src/store/actions/admin/user_roles";

// event bus
import { eventBus } from "src/main";

Vue.use(Table);
Vue.use(TableColumn);

export default {
  data() {
    return {
      allRoles: []
    };
  },
  computed: {
    user_id() {
      return this.$route.params.id;
    },
    getUserLoginById() {
      return this.$store.getters.getUserLoginById(this.user_id);
    },
    availableRoles() {
      // table data needs to by an array
      return this.allRoles.filter(row => !row.granted);
    },
    grantedRoles() {
      // table data needs to by an array
      return this.allRoles.filter(row => row.granted);
    },
    model() {
      return {
        user_id: this.user_id,
        role_ids: this.grantedRoles.map(row => row.role_id)
      };
    }
  },
  methods: {
    initRoles() {
      const roles = Object.values(this.$store.getters.getRoles);
      const user_roles_ids = Object.values(
        this.$store.getters.getUserRoles
      ).map(row => row.role_id);
      this.allRoles = roles.map(row =>
        Object.assign({}, row, {
          granted: user_roles_ids.includes(row.role_id)
        })
      );
    },
    changeGranted(row) {
      row.granted =!(row.granted);
    },
    handleRowClick: function (payload) {
    },
    async loadData(force) {
      const filters = {
        user_id: this.$route.params.id
      };

      if (force) {
        this.$store.commit(ROLES_INVALID);
        this.$store.commit(USER_ROLES_INVALID);
      }

      await this.$store.dispatch(LOAD_USER_ROLES, filters)
        .catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        })

      await this.$store.dispatch(LOAD_ROLES, filters)
        .catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        })

      this.initRoles();
    },
    async saveData() {
      await this.$store.dispatch(SAVE_USER_ROLE, this.model).catch(error => {
        console.log(error.response)
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning"
        });
      })

      if (this.$store.getters.getUserRolesModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Rekord został zmodyfikowany",
          type: "success"
        });
      this.$store.commit(USER_ROLES_INVALID) // oznacz nieaktualność danych
      this.$router.push({ name: "Użytkownicy" }) // przekieruj do listy
      }
    }
  },
  created() {
    this.loadData(true);
  }
};
</script>
<style>
</style>
