<template>
  <div>
    <div class="row">
      <div class="col-sm-11">
        <h5 class="title">Grupy reklamacyjne</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="tableData" :default-sort="{ prop: 'group_id', order: 'ascending' }">
              <el-table-column label="ID" property="system_user_id" width="120" align="right" sortable></el-table-column>
              <el-table-column label="Nazwa" property="name" min-width="120" sortable></el-table-column>
              <el-table-column label="Grupa" property="group_id" width="150" sortable>
                <template v-slot="props">
                  {{ getGroupName(props.row.group_id) }}
                </template>
              </el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="120">
                <template slot="header">
                  <p-button type="success" icon round @click="handleComplaintsGroup('add', null)">
                    <i class="fa fa-plus"></i>
                  </p-button>
                </template>
                <template v-slot="props">
                  <p-button style="margin-right: 2px;" type="success" size="sm" icon @click="handleComplaintsGroup('edit', props.row)">
                    <i class="fa fa-edit"></i>
                  </p-button>
                  <p-button style="margin-left: 2px;" type="danger" size="sm" icon @click="handleDelete(props.row)">
                    <i class="fa fa-trash"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vuex actions
import { LOAD_USER_GROUP, DELETE_USER_GROUP, USER_GROUP_INVALID } from "src/store/actions/complaints/user_group";
import { LOAD_USERS, USERS_INVALID } from "src/store/actions/admin/users";

import Swal from "sweetalert2";

import ComplaintsGroupModal from "src/components/Utils4/admin/ComplaintsGroupModal.vue";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";

export default {
  data() {
    return {};
  },
  computed: {
    users() {
      return this.$store.getters.getUsers;
    },
    userGroup() {
      let data = _.cloneDeep(this.$store.getters.getUserGroup);
      if (data.user) {
        data.user.map(x => {
          x.name = this.users[x.system_user_id].user_first_name + " " + this.users[x.system_user_id].user_last_name;
          x.email = this.users[x.system_user_id].user_email;
        });
        return data;
      }
      return { group: [], user: [] };
    },
    tableData() {
      return this.userGroup.user;
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN);
    },
  },
  methods: {
    async loadData(force) {
      const filters = {};

      this.$store.commit(USER_GROUP_INVALID);
      if (force) {
        this.$store.commit(USERS_INVALID);
      }

      this.$store.dispatch(LOAD_USERS, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_USER_GROUP, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    handleDelete({ name, system_user_id, group_id }) {
      const model = {
        user_id: system_user_id,
        group_id: group_id,
      };

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: `Czy na pewno chcesz usunąć użytkownika ${name} z grupy ${this.getGroupName(group_id)}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Usuń",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then(async res => {
          if (res.isDismissed) {
            return;
          }
          if (res.isConfirmed) {
            await this.$store.dispatch(DELETE_USER_GROUP, model).catch(error => {
              eventBus.$emit("notify", {
                message: "Błąd zapisu danych!",
                type: "warning",
              });
            });

            if (this.$store.getters.getUserGroupDeletingStatus === "deleted") {
              eventBus.$emit("notify", {
                message: "Rekord został usunięty",
                type: "success",
              });
              this.loadData();
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    getGroupName(group_id) {
      return this.userGroup.group.find(x => x.group_id === group_id).name;
    },
    handleComplaintsGroup(mode, row) {
      this.$modal.show(
        ComplaintsGroupModal,
        {
          mode: mode,
          users: this.users,
          privileged: this.userGroup.privileged,
          assigned: this.userGroup.user,
          group_list: this.userGroup.group,
          row: row,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(ComplaintsGroupModal);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "500px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
    eventBus.$on("userGroupSaved", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
    eventBus.$off("userGroupSaved");
  },
};
</script>

<style></style>
