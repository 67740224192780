<template>
  <div>
    <table class="table-bordered details-table">
      <tbody>
        <tr>
          <td rowspan="9">
            Wszystkie<br /><b>{{ criterias.Suma }}</b>
          </td>
          <td>
            Wyświetlone<br /><b>{{ getCounter("Wyświetlone") }}</b>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td rowspan="8">
            Odrzucone<br /><b>{{ getRejected() }}</b>
          </td>
          <td>
            Karencja (1 dzień)<br /><b>{{ getCounter("Karencja (1 dzień)") }}</b>
          </td>
          <td>
            Karencja (do 1 miesiąca)<br /><b>{{ getCounter("Karencja (do 1 miesiąca)") }}</b>
          </td>
        </tr>
        <tr>
          <td>
            Karencja (do 6 miesięcy)<br /><b>{{ getCounter("Karencja (do 6 miesięcy)") }}</b>
          </td>
          <td>
            Karencja (powyżej 6 miesięcy)
            <br /><b>{{ getCounter("Karencja (powyżej 6 miesięcy)") }}</b>
          </td>
        </tr>
        <tr>
          <td>
            Limit wyświetleń (pytań profilowych)<br /><b>{{ getCounter("Limit wyświetleń (pytań profilowych)") }}</b>
          </td>
          <td>
            Limit wyświetleń (całkowity)<br /><b>{{ getCounter("Limit wyświetleń (całkowity)") }}</b>
          </td>
        </tr>
        <tr>
          <td>
            Limit wyświetleń (wymuszone - błąd)<br /><b>{{ getCounter("Limit wyświetleń (wymuszone - błąd)") }}</b>
          </td>
          <td>
            Płeć<br /><b>{{ getCounter("Płeć") }}</b>
          </td>
        </tr>
        <tr>
          <td>
            Wiek za mały<br /><b>{{ getCounter("Wiek (za mały)") }}</b>
          </td>
          <td>
            Wiek za duży<br /><b>{{ getCounter("Wiek (za duży)") }}</b>
          </td>
        </tr>
        <tr>
          <td>
            Godzina dnia<br /><b>{{ getCounter("Godzina dnia") }}</b>
          </td>
          <td>
            Dzień tygodnia<br /><b>{{ getCounter("Dzień tygodnia") }}</b>
          </td>
        </tr>
        <tr>
          <td>
            Operator GSM<br /><b>{{ getCounter("Operator GSM") }}</b>
          </td>
          <td>
            Dom jednorodzinny<br /><b>{{ getCounter("Dom jednorodzinny") }}</b>
          </td>
        </tr>
        <tr>
          <td>
            Deduplikacja<br /><b>{{ getCounter("Deduplikacja") }}</b>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["criterias"],
  methods: {
    getCounter(param) {
      if (this.criterias[param]) {
        return this.criterias[param].counter;
      } else {
        return 0;
      }
    },
    getRejected() {
      return this.criterias.Suma - this.criterias.Wyświetlone.counter;
    },
  },
};
</script>
