<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="card demo-icons">
          <div class="card-header">
            <h5 class="card-title">Edycja zakresu użytkownika {{ getUserLoginById }}</h5>
          </div>
          <div class="card-body all-icons">
            <div class="row">
              <div class="col-sm-6">
                <el-table
                  :data="grantedScopes"
                  style="cursor: pointer"
                  :default-sort="{prop: 'user_scope_id', order: 'ascending'}"
                >
                <el-table-column label="Zakresy użytkownika" property="custom_scope_name" align="center" sortable>
                  <template v-slot="props">
                    <div @click="changeGranted(props.row)">
                      {{props.row.custom_scope_name}}
                    </div>
                  </template>
                </el-table-column>
                </el-table>
              </div>
              <div class="col-sm-6">
                <el-table
                  :data="availableScopes"
                  style="cursor: pointer"
                  :default-sort="{prop: 'scope_add_time', order: 'ascending'}"
                >
                  <el-table-column label="Dostępne zakresy" property="custom_scope_name" align="center" sortable>
                  <template v-slot="props">
                    <div @click="changeGranted(props.row)">
                      {{props.row.custom_scope_name}}
                    </div>
                  </template>
                </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="card-footer text-center" align="center">
              <router-link to="/admin/users">
                <p-button type="default" link style="margin-right: 5vw">Anuluj</p-button>
              </router-link>
              <p-button type="success" @click.prevent="saveData">Zapisz</p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

// ui components
import { Table, TableColumn } from "element-ui";

// vuex actions
import { LOAD_SCOPES, SCOPES_INVALID } from "src/store/actions/admin/scopes";
import { LOAD_USER_SCOPES, USER_SCOPES_INVALID, SAVE_USER_SCOPE } from "src/store/actions/admin/user_scopes";
import { LOAD_PARTNERS } from "src/store/actions/admin/partners";

// event bus
import { eventBus } from "src/main";

Vue.use(Table);
Vue.use(TableColumn);

export default {
  data() {
    return {
      allScopes: []
    };
  },
  computed: {
    user_id() {
      return this.$route.params.id;
    },
    getUserLoginById() {
      return this.$store.getters.getUserLoginById(this.user_id);
    },
    users() {
      // table data needs to by an array
      return Object.values(this.$store.getters.getUsers);
    },
    availableScopes() {
      return this.allScopes.filter(row => !row.granted);
    },
    grantedScopes() {
      return this.allScopes.filter(row => row.granted)
    },
    model() {
      return {
        user_id: this.user_id,
        scope_ids: this.grantedScopes.map(row => row.scope_id)
      };
    }
  },
  methods: {
    initScopes() {
      const scopes = Object.values(this.$store.getters.getScopes);
      const user_scopes_ids = Object.values(
        this.$store.getters.getUserScopes
      ).map(row => row.scope_id);
      this.allScopes = scopes.map(row =>
        Object.assign({}, row, {
          granted: user_scopes_ids.includes(row.scope_id)
        })
      );

      if (this.$store.state.partners.loading_status === 'success'){
        let partners = this.$store.getters.getPartners;

        this.allScopes = this.allScopes.map(
          row => Object.assign(
            {}, row, {
              custom_scope_name: partners[row.custom_id].partner_name
            }
          )
        )
      }
    },
    changeGranted(row) {
      row.granted = !(row.granted);
    },
    async loadData(force) {
      const filters = {
        user_id: this.$route.params.id
      };

      if (force) {
        this.$store.commit(SCOPES_INVALID);
        this.$store.commit(USER_SCOPES_INVALID);
        this.$store.commit(USERS_INVALID);
      }

      await this.$store.dispatch(LOAD_USER_SCOPES, filters)
        .catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        })

      await this.$store.dispatch(LOAD_SCOPES, filters)
        .catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        })

      await this.$store.dispatch(LOAD_PARTNERS, filters)
      .catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      })

      this.initScopes();
    },
    async saveData() {
      await this.$store.dispatch(SAVE_USER_SCOPE, this.model).catch(error => {
        console.log(error.response)
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning"
        });
      })

      if (this.$store.getters.getUserScopesModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Rekord został zmodyfikowany",
          type: "success"
        });
      this.$store.commit(USER_SCOPES_INVALID) // oznacz nieaktualność danych
      this.$router.push({ name: "Użytkownicy" }) // przekieruj do listy
      }
    }
  },
  created() {
    this.loadData(false);
  }
};
</script>
<style>
</style>
