import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_DISPLAY_COSTS,
  DISPLAY_COSTS_LOADING,
  DISPLAY_COSTS_LOADING_SUCCESS,
  DISPLAY_COSTS_LOADING_ERROR,
  DISPLAY_COSTS_INVALID,
  SAVE_DISPLAY_COSTS,
  DISPLAY_COSTS_SAVING,
  DISPLAY_COSTS_SAVING_SUCCESS,
  DISPLAY_COSTS_SAVING_ERROR,
  DELETE_DISPLAY_COSTS,
  DISPLAY_COSTS_DELETING,
  DISPLAY_COSTS_DELETING_SUCCESS,
  DISPLAY_COSTS_DELETING_ERROR
} from 'src/store/actions/secure/display_costs'

const storeObjectConfig = {
  objectName: "DisplayCosts",
  getterName: "getDisplayCosts",
  loadActionName: LOAD_DISPLAY_COSTS,
  loadingMutationName: DISPLAY_COSTS_LOADING,
  loadingSuccessMutationName: DISPLAY_COSTS_LOADING_SUCCESS,
  loadingErrorMutationName: DISPLAY_COSTS_LOADING_ERROR,
  invalidMutationName: DISPLAY_COSTS_INVALID,
  saveActionName: SAVE_DISPLAY_COSTS,
  savingMutationName: DISPLAY_COSTS_SAVING,
  savingSuccessMutationName: DISPLAY_COSTS_SAVING_SUCCESS,
  savingErrorMutationName: DISPLAY_COSTS_SAVING_ERROR,
  deleteActionName: DELETE_DISPLAY_COSTS,
  deletingMutationName: DISPLAY_COSTS_DELETING,
  deletingSuccessMutationName: DISPLAY_COSTS_DELETING_SUCCESS,
  deletingErrorMutationName: DISPLAY_COSTS_DELETING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/displayCosts",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/raports/displayCosts",
    method: "put" // {'get','post','put'}
  },
  deleteParams: {
    url: "/raports/displayCosts",
    method: "delete" // {'delete'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
