import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  SAVE_PAUSE_ANSWER,
  PAUSE_ANSWER_SAVING,
  PAUSE_ANSWER_SAVING_SUCCESS,
  PAUSE_ANSWER_SAVING_ERROR,
} from "src/store/actions/questions/pause_answer";

const storeObjectConfig = {
  objectName: "Questions",
  getterName: "getPauseAnswer",
  saveActionName: SAVE_PAUSE_ANSWER,
  savingMutationName: PAUSE_ANSWER_SAVING,
  savingSuccessMutationName: PAUSE_ANSWER_SAVING_SUCCESS,
  savingErrorMutationName: PAUSE_ANSWER_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  saveParams: {
    url: "/questions/pauseAnswer",
    method: "put", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
