import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_MAILING_STATS_EFFICIENCY,
  MAILING_STATS_EFFICIENCY_LOADING,
  MAILING_STATS_EFFICIENCY_LOADING_SUCCESS,
  MAILING_STATS_EFFICIENCY_LOADING_ERROR,
  MAILING_STATS_EFFICIENCY_INVALID,
} from "src/store/actions/stats/mailing_stats_efficiency";

const storeObjectConfig = {
  objectName: "MailingStats",
  getterName: "getMailingStatsEfficiency",
  loadActionName: LOAD_MAILING_STATS_EFFICIENCY,
  loadingMutationName: MAILING_STATS_EFFICIENCY_LOADING,
  loadingSuccessMutationName: MAILING_STATS_EFFICIENCY_LOADING_SUCCESS,
  loadingErrorMutationName: MAILING_STATS_EFFICIENCY_LOADING_ERROR,
  invalidMutationName: MAILING_STATS_EFFICIENCY_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/stats/mailingStats/efficiency",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
