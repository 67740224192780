<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="Podgląd" name="Consents">
            <appConsents></appConsents>
          </el-tab-pane>
          <el-tab-pane v-if="editPermitted" label="Zgody" name="ConsentForm">
            <appConsentsForm></appConsentsForm>
          </el-tab-pane>
          <el-tab-pane v-if="editPermitted" label="Obowiązek" name="DisclosureForm">
            <appDisclosureForm></appDisclosureForm>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import * as priv from 'src/privileges.js';

// event bus
import { eventBus } from "src/main";

// ui components
import { Card } from 'src/components/UIComponents'
import { Tabs, TabPane } from "element-ui";

import Consents from './Consents.vue'
import ConsentsForm from './forms/ConsentsForm.vue'
import DisclosureForm from './forms/DisclosureForm.vue'

export default {
  components: {
    appConsents: Consents,
    appConsentsForm: ConsentsForm,
    appDisclosureForm: DisclosureForm,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    Card
  },
  data() {
    return {
      activeName: 'Consents'
    }
  },
  computed: {
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_CONSENTS)
    },
  },
  methods: {
    handleClick() {
      eventBus.$emit("consentsTabChanged");
    }
  },
  created() {
  }
};
</script>

<style lang="scss">
.el-tabs__active-bar {
  background-color: #3d7de3;
}
.el-tabs__item {
  color: #383428;
}
.el-tabs__item:hover {
  color: #3d7de3;
}
.el-tabs__item.is-active {
  color: #3d7de3;
}
</style>
