<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">{{ macro.name }}</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>

      <appPhoneForm v-if="macro.id === 2" :phone="this.phone" />
      <appEmailForm :mail="this.mail" />

      <br />
      <hr />

      <div class="row">
        <div class="col-sm-12">
          <p class="macro_paragraph"><b>Rekord: </b>{{ choosenRecord }}</p>
          <p class="macro_paragraph"><b>Odbiorca: </b>{{ response_email }}</p>
          <p class="macro_paragraph"><b>Temat: </b>{{ subject }}</p>
          <p class="macro_paragraph"><b>Wiadomość: </b>{{ choosenTemplate }}</p>
        </div>
      </div>

      <div class="row">
        <p-button style="margin-right: 5px;" link @click="$emit('close')">Anuluj</p-button>
        <p-button type="info" @click="executeMacro()">Potwierdź</p-button>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip } from "element-ui";

// vuex actions
// import { SAVE_CATEGORY } from "src/store/actions/questionForm/categorys";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

import { SAVE_MACRO } from "src/store/actions/complaints/macro";
import { SAVE_SET_API_CALL } from "src/store/actions/complaints/actions/set_api_call";

import EmailForm from "src/components/Utils4/complaints/components/EmailForm.vue";
import PhoneForm from "src/components/Utils4/complaints/components/PhoneForm.vue";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    appEmailForm: EmailForm,
    appPhoneForm: PhoneForm,
  },
  props: ["phone", "mail", "mailbox", "company", "complaintID", "complaint_flow_id", "response_email", "subject", "macro"],
  data() {
    return {
      is_test: false,
      model: {
        phone: "",
        mail: "",
        template: null,
      },
      modelValidations: {
        phone: {
          numeric: true,
          min: 11,
          max: 11,
        },
        mail: {
          regex: regexp.EMAIL_REGEXP,
          min: 1,
          max: 128,
        },
        template: {
          numeric: true,
        },
      },
    };
  },
  computed: {
    macro_json() {
      return JSON.parse(this.macro.macro_json);
    },
    responseTemplates() {
      return this.$store.getters.getResponseTemplates;
    },
    choosenRecord() {
      let record = "";
      if (this.model.phone) record += this.model.phone;
      if (this.model.mail) {
        if (this.model.phone) {
          record += " / " + this.model.mail;
        } else {
          record += this.model.mail;
        }
      }
      if (record === "") record = "-";
      return record;
    },
    choosenTemplate() {
      if (!this.model.template) return "-";
      return this.responseTemplates[this.model.template].body;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getMacroTemplateID() {
      const found = this.macro_json.find(x => {
        return x.action === "response";
      });
      if (found) {
        this.model.template = found.template_id;
      }
    },
    async executeMacro() {
      if(this.macro.id == 1 && this.model.mail === "") {
        eventBus.$emit("notify", {
          message: "Brak wybranego emaila",
          type: "warning",
        });
        return;
      }

      if(this.macro.id == 2 && this.model.mail === "" && this.model.phone === "") {
        eventBus.$emit("notify", {
          message: "Brak wybranego emaila/telefonu",
          type: "warning",
        });
        return;
      }

      const model = {
        ...this.model,
        mailbox_id: this.mailbox,
        macro_id: this.macro.id,
        macro_json: JSON.parse(this.macro.macro_json),
        complaint_id: this.complaintID,
        complaint_flow_id: this.complaint_flow_id,
        response_email: this.response_email,
        subject: this.subject,
        message: this.choosenTemplate
      };

      await this.$store.dispatch(SAVE_MACRO, model).catch(error => {
        let message = "";
        console.log(error.response);
        switch (error.response.data.code) {
          case 403:
            message = "Wybrana niepoprawna skrzynka.";
            break;
          case 404:
            message = "Brak rekordów do wykonania akcji.";
            break;
          default:
            message = "Błąd zapisu danych!";
        }
        eventBus.$emit("notify", {
          message: message,
          type: "warning",
        });
      });

      if (this.$store.getters.getMacroSavingStatus === 'saved') {
        eventBus.$emit("notify", {
          message: "Zlecono akcję",
          type: "success",
        });
        eventBus.$emit("macroCompleted");
        this.$emit("close");
      }
    },
  },
  mounted() {
    eventBus.$on("PhoneForm_PhoneChanged", ({ phone }) => {
      this.model.phone = phone;
    });
    eventBus.$on("EmailForm_MailChanged", ({ mail }) => {
      this.model.mail = mail;
    });
    eventBus.$on("TemplateFormChanged", ({ template }) => {
      this.model.template = template;
    });
  },
  async created() {
    this.getMacroTemplateID();
    if (this.phone.length > 0) {
      this.model.phone = this.phone[0];
    }
    if (this.mail.length > 0) {
      this.model.mail = this.mail[0];
    }
  },
  beforeDestroy: function() {
    eventBus.$off("PhoneForm_PhoneChanged");
    eventBus.$off("EmailForm_MailChanged");
    eventBus.$off("TemplateFormChanged");
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}
.macro_paragraph {
  margin-bottom: 0.4em;
}
</style>
