<template>
  <div>
    <div class="row">
      <div class="col-sm-11">
        <h5 class="title">Parametry systemowe</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="tableData">
              <el-table-column label="ID" property="id" width="80"></el-table-column>
              <el-table-column label="Nazwa" property="name" min-width="120"></el-table-column>
              <el-table-column label="Wartość" property="value" min-width="100"></el-table-column>
              <el-table-column label="Opis" property="description" min-width="250"></el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="80">
                <template slot="header">
                  <router-link to="/admin/system_params_form">
                    <p-button type="success" icon round>
                      <i class="fa fa-plus"></i>
                    </p-button>
                  </router-link>
                </template>
                <template v-slot="props">
                  <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                    <i class="fa fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vuex actions
import { LOAD_SYSTEM_PARAMS, SYSTEM_PARAMS_INVALID } from "src/store/actions/admin/system_params";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    tableData() {
      return Object.values(this.$store.getters.getSystemParams);
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN);
    },
  },
  methods: {
    loadData(force) {
      const filters = {};

      if (force) {
        this.$store.commit(SYSTEM_PARAMS_INVALID);
      }

      this.$store.dispatch(LOAD_SYSTEM_PARAMS, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    handleEdit(index, row) {
      this.$router.push("/admin/system_params_form/" + row.id);
    },
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
  },
};
</script>

<style></style>
