<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-10">
        <h5 class="title">{{ templateID ? `Edycja szablonu ${templateID}` : "Nowy szablon" }}</h5>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <label>Szablon</label>
            <appCodeTextarea v-model="model.template" :height="400" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label>Autoresponder</label>
            <appCodeTextarea v-model="model.autoresponder" :height="400" />
          </div>
        </div>
        <div class="row center">
          <p-button style="margin-right: 10px;" link @click="$router.push('/complaints/mailbox_templates')">Anuluj</p-button>
          <p-button type="success" @click="saveData()">Zapisz</p-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Tooltip, Input } from "element-ui";

// vuex actions
import { SAVE_MAILBOX_TEMPLATE, LOAD_MAILBOX_TEMPLATES, MAILBOX_TEMPLATES_INVALID } from "src/store/actions/complaints/mailbox_templates";

// event bus
import { eventBus } from "src/main";

import CodeTextarea from "src/components/GeneralViews/CodeTextarea.vue";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    [Input.name]: Input,
    appCodeTextarea: CodeTextarea,
  },
  data() {
    return {
      loading: false,
      model: {
        id: null,
        template: null,
        autoresponder: null,
      },
      compareModel: {
        id: null,
        template: null,
        autoresponder: null,
      },
    };
  },
  computed: {
    mailboxTemplates() {
      return this.$store.getters.getMailboxTemplates;
    },
    templateID() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData(force) {
      this.loading = true;

      if (force) {
        this.$store.commit(MAILBOX_TEMPLATES_INVALID);
      }

      await this.$store.dispatch(LOAD_MAILBOX_TEMPLATES, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    async saveData() {
      if (JSON.stringify(this.model) !== JSON.stringify(this.compareModel)) {
        this.loading = true;

        await this.$store.dispatch(SAVE_MAILBOX_TEMPLATE, this.model).catch(error => {
          console.log(error.response);
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getMailboxTemplatesModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został zmodyfikowany",
            type: "success",
          });

          this.$store.commit(MAILBOX_TEMPLATES_INVALID); // oznacz nieaktualność danych
          this.$router.push("/complaints/mailbox_templates");
        }

        this.loading = false;
      }
    },
  },
  created() {
    if (this.templateID) {
      this.model.id = this.templateID;
      this.model.template = this.mailboxTemplates[this.templateID].template;
      this.model.autoresponder = this.mailboxTemplates[this.templateID].autoresponder;

      this.compareModel = _.cloneDeep(this.model);
    }
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy() {
    this.$store.commit(MAILBOX_TEMPLATES_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="scss" scoped>
.center {
  justify-content: center;
  align-content: center;
  display: flex;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
