<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Raport efektywności sms</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="-"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd"
          :default-value="defaultDate"
        >
        </el-date-picker>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-5">
        <fg-input
          class="mb-0 mt-auto"
          placeholder="Szukaj"
          v-model="banner"
          addon-right-icon="nc-icon nc-zoom-split"
        ></fg-input>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-3">
        <el-select multiple placeholder="Projekt" v-model="projectsSms">
          <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
            <el-option
              v-for="item in group.options"
              :key="item.project_id"
              :label="item.project_name + ' [' + item.project_id + ']'"
              :value="item.project_id"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <div class="col-6 col-md-4 d-flex">
        <p-button type="success" @click="loadData('show')" class="ml-auto mt-auto mb-0">Pokaż</p-button>
        <p-button type="success" @click="loadData('excel')" class=" mt-auto mb-0">Excel</p-button>
      </div>
    </div>

    <div class="card" style="margin-top: 10px">
      <div class="row text-right">
        <div class="col-sm-12">
          <el-switch
            class="projectSwitch"
            name="switchDays"
            v-model="switchDays"
            active-text="Dni"
            active-color="#fd7e14"
            inactive-text="Projekty"
            inactive-color="#17a2b8"
          ></el-switch>
          <el-switch class="roiSwitch" name="showROI" v-model="showROI" active-text="ROI" active-color="#28a745"
                     inactive-color="#c4c4c4"></el-switch>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <h6>
            <div class="card-header weekHeader" style="display: flex; justify-content: space-between">
              <span>Przedział dat:</span>
              <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
            </div>
          </h6>
        </div>
      </div>
    </div>

    <div class="card" v-if="Object.values(efficiencyRaport).length > 0">
      <div class="card-header">
        <h5>Podsumowanie</h5>
      </div>
      <div class="card-body">
        <appSmsEfficiencyTable
          v-if="Object.values(efficiencyRaport).length > 0"
          :table-data="Object.values(efficiencyRaport.summary)"
          :show-roi="showROI"
          :summary="true"
        ></appSmsEfficiencyTable>
      </div>
    </div>

    <div v-if="!switchDays">
      <div v-for="raport in efficiencyRaport.projects" :key="raport.project_id">
        <div class="card">
          <div class="card-header">
            <h5>
              {{ raport.project_name + " [" + raport.project_id + "]" }}
            </h5>
          </div>
          <appSmsEfficiencyTable
            :table-data="Object.values(raport.banners)"
            :show-roi="showROI"
            :summary="false"
            :date-range="dateRange"
          ></appSmsEfficiencyTable>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="day in efficiencyRaport.date" :key="day.project_id">
        <div class="card">
          <div class="card-header">
            <h5>{{ day.send_date }}</h5>
          </div>
          <appSmsEfficiencyTable
            :table-data="Object.values(day.banners)"
            :show-roi="showROI"
            :summary="false"
            :date-range="dateRange"
          ></appSmsEfficiencyTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

// event bus
import {eventBus} from "src/main";

import {DatePicker, Collapse, CollapseItem, Select, Option, Tooltip, Switch, OptionGroup} from "element-ui";

import {LOAD_SMS_EFFICIENCY, SMS_EFFICIENCY_INVALID} from "src/store/actions/secure/sms_efficiency";

import SmsEfficiencyTable from "src/components/Utils4/sales/views/SmsEfficiencyTable.vue";
import {LOAD_SMS_EFFICIENCY_EXPORT, SMS_EFFICIENCY_EXPORT_INVALID} from "@/store/actions/secure/sms_efficiency_excel";
import {LOAD_PROJECT_DICT, PROJECT_DICT_INVALID} from "@/store/actions/questions/project_dict";
import {PROJECTS_ALL_INVALID} from "@/store/actions/questions/projects_all";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [CollapseItem.name]: CollapseItem,
    [Collapse.name]: Collapse,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [Switch.name]: Switch,
    appSmsEfficiencyTable: SmsEfficiencyTable,
  },
  data() {
    return {
      projectsSms: '',
      banner: '',
      loading: false,
      dateRange: ["", ""],
      switchDays: false,
      showROI: true,
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    async initData(force) {
      if (force) {
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(PROJECTS_ALL_INVALID);
      }
      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    async loadData(mode) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        projects: this.projectsSms,
      };
      this.$store.commit(mode === "excel" ? SMS_EFFICIENCY_EXPORT_INVALID : SMS_EFFICIENCY_INVALID);

      await this.$store.dispatch(mode === 'excel' ? LOAD_SMS_EFFICIENCY_EXPORT : LOAD_SMS_EFFICIENCY, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      if (mode === "excel") {
        const tmpName = this.$store.getters.getSmsEfficiencyExcel.tmpname;
        const fileName = this.$store.getters.getSmsEfficiencyExcel.name;

        //console.log(tmpName,fileName);
        this.downloadFile(tmpName, fileName);
      }

      this.loading = false;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-contract.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  computed: {
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    efficiencyRaport() {
      let data = {};
      if (this.$store.state.sms_efficiency.loading_status === "success") {
        data = JSON.parse(JSON.stringify(this.$store.getters.getSmsEfficiency));
        const projects = data.projects;
        const days = data.date;

        if (!days) return {};
        for (let project in projects) {
          let projectBannersPresent = [], projectBanners = Object.values(projects[project].banners);

          for (let bannerData of projectBanners) {
            let isAddBannerData = true;

            if (this.projectsSms.length > 0 && this.projectsSms.includes(parseInt(project)) === false) {
              isAddBannerData = false
            }

            if (this.banner !== '') {
              let isNameBanner = false;
              if (bannerData.banner_name.toLowerCase().includes(this.banner.toLowerCase())) {
                isNameBanner = true;
              }

              if (bannerData.banner_id.toString().includes(this.banner)) {
                isNameBanner = true;
              }

              if (isNameBanner === false) {
                isAddBannerData = false;
              }
            }
            if (isAddBannerData === true) {
              projectBannersPresent.push(bannerData);
            }
          }

          if (projectBannersPresent.length > 0) {
            projects[project].banners = projectBannersPresent;
            let summary = projects[project].summary;
            summary.banner_name = "Suma";
            summary.banner_id = null;
            projects[project].banners.push(summary);
          } else {
            delete projects[project];
          }
        }

        for (let day in days) {
          let dayBannersPresent = [], dayBanners = Object.values(days[day].banners);

          for (let dayData of dayBanners) {
            let isAddBannerData = true;

            if (this.projectsSms.length > 0 && this.projectsSms.includes(parseInt(dayData.project_id)) === false) {
              isAddBannerData = false
            }

            if (this.banner !== '') {
              let isNameBanner = false;
              if (dayData.banner_name.toLowerCase().includes(this.banner.toLowerCase())) {
                isNameBanner = true;
              }

              if (dayData.banner_id.toString().includes(this.banner)) {
                isNameBanner = true;
              }

              if (isNameBanner === false) {
                isAddBannerData = false;
              }
            }
            if (isAddBannerData === true) {
              dayBannersPresent.push(dayData);
            }
          }

          days[day].banners = dayBannersPresent;
          let summary = days[day].summary;
          summary.banner_name = "Suma";
          summary.banner_id = null;
          days[day].banners.push(summary);
        }

        let daysArray = Object.values(days);
        daysArray.sort((a, b) => (a.send_date > b.send_date ? 1 : -1));

        let projectsArray = Object.values(projects);
        projectsArray.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

        data.date = daysArray;
        data.projects = projectsArray;
      }
      return data;
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
  },
  mounted() {
  },
  created() {
    this.initData(false);
    eventBus.$on("forceReload", () => {
      this.loadData();
      this.initData(true);
    });
  },
  beforeDestroy: function () {
    this.$store.commit(SMS_EFFICIENCY_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.percentageValue {
  color: #383838;
  font-weight: bold;
}

.weekHeader {
  padding: 15px 15px 5px !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.verticalTableHeader {
  font-weight: bold;
  width: 200px;
}

.verticalTableRow {
  width: 200px;
}

.right-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.left-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.tableRow {
  margin: 0;
}

.projectSwitch,
.roiSwitch {
  margin: 20px 10px 10px 10px;
}

.projectSwitch::after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid rgb(179, 177, 177);
  top: 0;
  left: 152px;
}
</style>

<style>
.mailing-efficiency__hide-expand :first-child {
  visibility: hidden !important;
}
</style>
