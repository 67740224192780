import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_POSTCODES_COUNT,
  POSTCODES_COUNT_LOADING,
  POSTCODES_COUNT_LOADING_SUCCESS,
  POSTCODES_COUNT_LOADING_ERROR,
  POSTCODES_COUNT_INVALID,
  SAVE_POSTCODE_COUNT,
  POSTCODE_COUNT_SAVING,
  POSTCODE_COUNT_SAVING_SUCCESS,
  POSTCODE_COUNT_SAVING_ERROR
} from '../../actions/questionForm/postcodes_count'

const storeObjectConfig = {
  objectName: "Postcodes",
  getterName: "getPostcodesCount",
  loadActionName: LOAD_POSTCODES_COUNT,
  loadingMutationName: POSTCODES_COUNT_LOADING,
  loadingSuccessMutationName: POSTCODES_COUNT_LOADING_SUCCESS,
  loadingErrorMutationName: POSTCODES_COUNT_LOADING_ERROR,
  invalidMutationName: POSTCODES_COUNT_INVALID,
  saveActionName: SAVE_POSTCODE_COUNT,
  savingMutationName: POSTCODE_COUNT_SAVING,
  savingSuccessMutationName: POSTCODE_COUNT_SAVING_SUCCESS,
  savingErrorMutationName: POSTCODE_COUNT_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/questionForm/postcodes_count",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
