<template>
  <div>
    <div class="card" style='width: 400px;'>
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">{{ 'Klonuj pytanie ' + question_id }}</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div>
            <el-checkbox :indeterminate="is_indeterminate" v-model="check_all" @change="handleCheckAllChange">Zaznacz wszystkie</el-checkbox>
          </div>

          <br>

          <div>
            <el-checkbox v-model="model.question_group" @input="handleCheck()">Grupa pytań</el-checkbox>
          </div>
          <div>
            <el-checkbox v-model="model.question_criteria" @input="handleCheck()">Kryteria pytania</el-checkbox>
          </div>
          <div>
            <el-checkbox v-model="model.answers" @input="answerChange()">Odpowiedzi</el-checkbox>
          </div>
          <div class="answer_checkbox">
            <el-checkbox :disabled="!answer_check" v-model="model.answer_stats" @input="handleCheck()">Statystyki odpowiedzi</el-checkbox>
          </div>
          <div class="answer_checkbox">
            <el-checkbox :disabled="!answer_check" v-model="model.answer_rules" @input="handleCheck()">Reguły odpowiedzi</el-checkbox>
          </div>
          <div class="answer_checkbox">
            <el-checkbox :disabled="!answer_check" v-model="model.answer_clients" @input="handleCheck()">Klienci</el-checkbox>
          </div>
          <div class="answer_checkbox">
            <el-checkbox :disabled="!answer_check" v-model="model.answer_tags" @input="handleCheck()">Tagi</el-checkbox>
          </div>
          <div class="answer_checkbox">
            <el-checkbox :disabled="!answer_check" v-model="model.answer_categorys">Kategorie</el-checkbox>
          </div>

          <br>

          <p style='font-size: 0.8em;' class="text-muted">Uwaga: podpytania nie zostaną sklonowane!</p>

          <div class="row">
            <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Checkbox } from "element-ui";

// vuex actions
import { SAVE_CLONE_QUESTION } from "src/store/actions/questions/clone_question";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Checkbox.name]: Checkbox,
  },
  props: ['props_question_id'],
  data() {
    return {
      question_id: 996,
      model: {
        question_group: false,
        question_criteria: true,
        answers: true,
        answer_rules: false,
        answer_stats: false,
        answer_clients: true,
        answer_tags: true,
        answer_categorys: true
      },
      answer_check: true,
      check_all: false,
      is_indeterminate: true
    }
  },
  computed: {
  },
  methods: {
    handleCheck() {
      let checkedCount = 0;
      for(let i in this.model) {
        if(this.model[i]) {
          checkedCount += 1;
        }
      }
      this.check_all = checkedCount === 8;
      this.is_indeterminate = checkedCount > 0 && checkedCount < 8;
    },
    handleCheckAllChange(val) {
      if(val) {
        for(let i in this.model) {
          this.model[i] = true;
          this.answer_check = true;
        }
      } else {
        for(let i in this.model) {
          this.model[i] = false;
          this.answer_check = false;
        }
      }
      this.is_indeterminate = false;
    },
    answerChange() {
      if(this.model.answers === false) {
        this.model.answer_stats = false;
        this.model.answer_rules = false;
        this.model.answer_categorys = false;
        this.model.answer_clients = false;
        this.model.answer_tags = false;

        this.answer_check = false;
      } else {
        this.model.answer_categorys = true;
        this.model.answer_clients = true;
        this.model.answer_tags = true;

        this.answer_check = true;
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      let model = Object.assign({}, {question_id: this.question_id}, this.model);
      await this.$store.dispatch(SAVE_CLONE_QUESTION, model).catch(error => {
        console.log(error.response)
        eventBus.$emit("notify", {
          message: 'Błąd zapisu danych!',
          type: "warning"
        });
      })

      if (this.$store.getters.getCloneQuestionModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Rekord został sklonowany",
          type: "success"
        });

        eventBus.$emit('questionCloned');
        this.$emit('close');
      }
    },
  },
  async created() {
    if(this.props_question_id !== null && this.props_question_id !== undefined) {
      this.question_id = parseInt(this.props_question_id);
    }
  },
};
</script>

<style lang="scss" scoped>
  .row {
    margin: 10px;
  }
  .card {
    margin: 0;
    display: flex;
  }
  .row:last-child {
    justify-content: center;
  }
  .center {
    justify-content: center;
    align-content: center;
  }
  .answer_checkbox {
    padding-left: 20px;
  }
  .header_row {
    display: flex;
    justify-content: space-between;
  }
</style>
