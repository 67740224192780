<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="card-body row">
          <div class="col-sm-2">
            <fg-input :error="getError('questionTypeFilter')" data-vv-as="Typ pytania">
              <el-select
                id="questionConfigQuestionTypeSelect"
                v-model="questionTypeFilter"
                v-on:change="changeTypeFilter()"
                name="questionTypeFilter"
                data-vv-as="Typ pytania"
                filterable
                clearable
              >
                <el-option
                  v-for="type in questionTypeDict"
                  :id="'questionConfigQuestionTypeSelectOption_' + type"
                  :label="type"
                  :value="type"
                  :key="type"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-4">
            <fg-input :error="getError('question_id')" data-vv-as="Pytanie">
              <el-select
                id="questionConfigQuestionSelect"
                v-on:visible-change="getUpperSelectWidth()"
                v-model="question_id"
                name="question_id"
                data-vv-as="Pytanie"
                filterable
                clearable
                v-on:input="changeQuestion($event)"
              >
                <el-option
                  :style="upperSelectWidth !== null ? 'width: ' + upperSelectWidth + 'px' : 'width: 300px'"
                  v-for="question in questionList"
                  :id="'questionConfigQuestionSelectOption_' + question.id"
                  :label="'[' + question.id + '] ' + question.question_content"
                  :value="question.id"
                  :key="question.id"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-3" align="center">
            <p-button v-if="editPermitted" :type="checkboxes.edit ? 'warning' : 'success'" size="medium" @click="changeEdit()" style="margin-top: 0;">
              {{ checkboxes.edit ? "Anuluj" : "Edycja" }}
            </p-button>
          </div>
          <div class="col-sm-3">
            <fg-input
              class="input-sm"
              id="questionConfigSearchInput"
              placeholder="Szukaj"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>

          <div v-if="recordObject.insert_project.length > 0 || recordObject.delete_project.length > 0" class="col-sm-12" align="center">
            <p-button type="danger" link id="questionConfigShowAnswersButton" @click="takeToBottom()"
              ><i class="fa fa-arrow-down" aria-hidden="true"></i>Zapisz wprowadzone zmiany<i class="fa fa-arrow-down" aria-hidden="true"></i
            ></p-button>
          </div>
        </div>
        <p class="text-muted" v-if="question_id !== null">
          <b>Treść pytania:</b> {{ questionContent }}
          <p-button id="questionConfigShowAnswersButton" class="pull-right" @click="showAnswers = !showAnswers" link
            >{{ showAnswers ? "Schowaj" : "Pokaż" }} odpowiedzi</p-button
          >
        </p>
        <div class="col-sm-12" v-if="showAnswers">
          <label>Odpowiedzi</label>
          <table class="table table-sm">
            <tbody>
              <tr v-for="(answer, index) in answers[question_id]" :key="answer.id">
                <td>{{ answer.answer_position }}</td>
                <td>[{{ answer.answer_id }}]</td>
                <td :id="'questionConfigAnswerContent' + '_' + index">{{ answer.answer_content }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <el-table :data="queriedData" stripe style="width: 100%" @sort-change="sortChange">
          <el-table-column type="expand">
            <template v-slot="props">
              <div class="row">
                <div class="col-sm-11">
                  <label>Wersje</label>
                  <table class="table table-borderless">
                    <tbody>
                      <tr
                        v-for="(version, index) in props.row.versions"
                        :key="props.row.project_id + '_' + version.module"
                        v-bind:class="{ blue: version.is_lite === 1, pink: version.tags === 'INACTIVE', grey: version.is_active === 0 }"
                      >
                        <td>
                          <p-checkbox
                            :disabled="!checkboxes.edit"
                            v-bind:checked="version.permitted"
                            v-on:input="checkboxChecked($event, props.row, version.module)"
                          ></p-checkbox>
                        </td>
                        <td>{{ version.module }}</td>
                        <td>{{ version.version }}</td>
                        <td :id="'questionConfigProjectVersionDescription' + '_' + index">{{ version.description }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-sm-1" v-if="editPermitted && checkboxes.edit">
                  <p-button style="width: 35px; margin: 20px 0 10px 0;" size="sm" type="default" @click.prevent="changeCheckboxes(props.row, true)"
                    ><i class="fa fa-check-square-o" aria-hidden="true"></i
                  ></p-button>
                  <br />
                  <p-button style="width: 35px;" size="sm" type="default" @click.prevent="changeCheckboxes(props.row, false)"
                    ><i class="fa fa-square-o" aria-hidden="true"></i
                  ></p-button>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="ID" property="project_id" width="80" sortable="custom"></el-table-column>
          <el-table-column label="Nazwa projektu" property="project_name" min-width="300" sortable="custom">
            <template v-slot="props">
              <div :id="'questionConfigProjectNameRow' + '_' + props.$index">
                {{ props.row.project_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Nazwa hosta" property="host_name" min-width="300" sortable="custom"></el-table-column>
          <el-table-column label="Usuń" align="center" v-if="checkboxes.edit && deletePermitted" width="140">
            <template v-slot="props">
              <p-button type="danger" size="sm" icon @click.prevent="handleDelete(props.row)">
                <i class="fa fa-minus-square-o"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body row">
        <div class="pagination-info" v-bind:class="{ 'col-sm-4': this.checkboxes.edit === true, 'col-sm-6': this.checkboxes.edit === false }">
          <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
        </div>
        <div class="col-sm-4 text-center" v-if="this.checkboxes.edit">
          <p-button type="default" link @click.prevent="changeEdit()">
            Anuluj
          </p-button>
          <p-button type="success" style="margin-left: 2vw" @click.prevent="saveData(false, {})">Zapisz</p-button>
        </div>
        <div v-bind:class="{ 'col-sm-4': this.checkboxes.edit === true, 'col-sm-6': this.checkboxes.edit === false }">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"> </p-pagination>
        </div>
      </div>
    </div>

    <div class="card" v-if="editPermitted">
      <div class="card-body">
        <div class="pull-right">
          <el-tooltip class="item" effect="light" placement="top" :open-delay="1000">
            <div slot="content">
              Dodawnie projektów do pytania:<br />
              - pytania typu CO-REGISTRATION - domyślnie aktywne są wszystkie wersje poza LITE, możliwość blokowania poszczególnych wersji lub
              dodawania wersji LITE<br />
              - pytania typu PROFILE i DOUBLEOPT-IN - domyślnie aktywne są wszystkie wersje, możliwość blokowania poszczególnych wersji<br />
              - pytanie typu QUIZ - domyślnie blokowane są wszystkie wersje, trzeba wybrać wersje do wyświetlania
            </div>
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </el-tooltip>
        </div>
        <div class="card-body" v-if="question_id && questionList[question_id].question_type === 'QUIZ'">
          <!-- przypadek dla pytań typu QUIZ -->
          <div class="row">
            <div v-bind:class="{ 'col-sm-8': Number.isInteger(selectedProjectID), 'col-sm-12': !Number.isInteger(selectedProjectID) }">
              <label>Dodaj projekt do pytania</label>
              <fg-input :error="getError('selectedProjectID')" data-vv-as="Dodaj projekt">
                <el-select
                  id="questionConfigProjectSelect"
                  v-on:visible-change="getBottomSelectWidth()"
                  v-on:handleOptionClick="clearVersions()"
                  v-on:clear="clearVersions()"
                  v-model="selectedProjectID"
                  name="selectedProjectID"
                  data-vv-as="Dodaj projekt"
                  filterable
                  clearable
                >
                  <el-option-group v-for="group in distinctProjects" :key="group.label" :label="group.label">
                    <el-option
                      :style="bottomSelectWidth !== null ? 'width: ' + bottomSelectWidth + 'px' : 'width: 300px'"
                      :id="'questionConfigProjectSelectOption_' + item.project_id"
                      v-for="item in group.options"
                      :key="item.project_id"
                      :label="item.project_name + ' [' + item.project_id + ']'"
                      :value="item.project_id"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </fg-input>
            </div>
            <div class="col-sm-4 text-center" v-if="Number.isInteger(selectedProjectID)">
              <p-button id="questionConfigAddProjectButton" type="success" @click.prevent="addProject">Dodaj</p-button>
            </div>
          </div>
          <div class="row" v-if="Number.isInteger(selectedProjectID)">
            <div class="col-sm-12">
              <label>Wybierz wersje</label>
              <fg-input :error="getError('selectedVersions')" data-vv-as="Dodaj wersje">
                <el-select
                  id="bottomRightQuestionProjectsSelect"
                  v-on:visible-change="getBottomRightSelectWidth()"
                  v-model="selectedVersions"
                  name="selectedVersions"
                  data-vv-as="Dodaj wersje"
                  filterable
                  multiple
                  clearable
                >
                  <el-option
                    :style="bottomRightSelectWidth !== null ? 'width: ' + bottomRightSelectWidth + 'px' : 'width: 300px'"
                    v-for="version in activeModules[selectedProjectID]"
                    :label="'[' + version.version + '] ' + version.module"
                    :value="version.module"
                    :key="version.module"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>
          </div>
        </div>
        <div class="card-body row" v-else>
          <div v-bind:class="{ 'col-sm-8': this.selectedProjectsIDs.length !== 0, 'col-sm-12': this.selectedProjectsIDs.length === 0 }">
            <label>Dodaj projekt(y) do pytania</label>
            <fg-input :error="getError('selectedProjectsIDs')" data-vv-as="Dodaj projekt">
              <el-select
                id="questionConfigProjectSelect"
                v-on:visible-change="getBottomSelectWidth()"
                v-model="selectedProjectsIDs"
                name="selectedProjectsIDs"
                data-vv-as="Dodaj projekt"
                filterable
                multiple
                clearable
              >
                <el-option-group v-for="group in distinctProjects" :key="group.label" :label="group.label">
                  <el-option
                    :style="bottomSelectWidth !== null ? 'width: ' + bottomSelectWidth + 'px' : 'width: 300px'"
                    :id="'questionConfigProjectSelectOption_' + item.project_id"
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-4 text-center" v-if="this.selectedProjectsIDs.length !== 0">
            <p-button id="questionConfigAddProjectButton" type="success" @click.prevent="addProject">Dodaj</p-button>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
import Vue from "vue";

import Swal from "sweetalert2";
import BackToTop from "vue-backtotop";

// ui components
import { Table, TableColumn, Select, Option, OptionGroup, Switch, Tag, Tooltip } from "element-ui";
import { Tabs, TabPane, Card } from "src/components/UIComponents";
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions
import {
  LOAD_QUESTION_CONFIGS,
  QUESTION_CONFIGS_INVALID,
  SAVE_QUESTION_CONFIG,
  DELETE_QUESTION_CONFIG,
} from "src/store/actions/questions/question_configs";
import { LOAD_QUESTION_LIST, QUESTION_LIST_INVALID } from "src/store/actions/questions/question_list";
import { LOAD_QUESTION_PROJECTS, QUESTION_PROJECTS_INVALID } from "src/store/actions/questions/question_projects";
import { LOAD_ANSWERS, ANSWERS_INVALID } from "src/store/actions/questions/answers";
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";

Vue.use(Table);
Vue.use(TableColumn);

export default {
  components: {
    [Tag.name]: Tag,
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Switch.name]: Switch,
    PPagination,
    TabPane,
    Tabs,
    Card,
    BackToTop,
  },
  data() {
    return {
      question_id: null,
      showAnswers: false,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["project_id", "project_name", "host_name"],
      checkboxes: {
        edit: false,
      },
      status: "",
      loading: false,
      sort: {
        prop: "project_id",
        order: "ascending",
      },
      newQuestionConfig: {},
      selectedProjectID: null,
      selectedProjectsIDs: [],
      selectedVersions: [],
      modules: {},
      recordObject: {},
      bottomSelectWidth: null,
      bottomRightSelectWidth: null,
      upperSelectWidth: null,
      questionTypeFilter: "",
      questionTypeDict: ["CO-REG", "OPT-IN", "PROFILE", "QUIZ"],
    };
  },
  computed: {
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN_QUESTIONS_CONFIG);
    },
    deletePermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_DELETE_ADMIN_QUESTIONS_CONFIG);
    },
    questionList() {
      let getter = this.$store.getters.getQuestionList;
      let type_map = { "CO-REG": "CO-REGISTRATION", "OPT-IN": "DOUBLEOPT-IN" }; // mapa krótszych nazw typów
      let result = {};
      if (this.questionTypeFilter === "") {
        return getter;
      } else {
        for (let i in getter) {
          if (getter[i].question_type === type_map[this.questionTypeFilter] || getter[i].question_type === this.questionTypeFilter) {
            result[i] = getter[i];
          }
        }
        return result;
      }
      return;
    },
    answers() {
      return JSON.parse(JSON.stringify(this.$store.getters.getAnswers));
    },
    projects() {
      // lista projektów przypisanych i nie przypisanych
      return JSON.parse(JSON.stringify(this.$store.getters.getQuestionProjects));
    },
    activeModules() {
      let data = this.$store.getters.getProjectsAll;
      let result = {};
      for (let i in data) {
        let project_id = data[i].project_id;
        let version = data[i].module;
        if (result[project_id] === undefined) {
          result[project_id] = {};
        }
        result[project_id][version] = data[i];
      }
      return result;
    },
    distinctProjects() {
      // lista projektów nie przypisanych do listy dodawania projektów
      let getter = this.projects;

      if (this.question_id === null) {
        // zwrócenie pustej tablicy w przypadku braku wybranego pytania
        return [];
      }

      if (this.$store.state.question_projects.loading_status === "success") {
        // filtracja projektów nie przypisanych
        let filteredData = {};
        for (let i in getter) {
          let project_id = getter[i].project_id;
          if (filteredData[project_id] === undefined) {
            filteredData[project_id] = Object.assign({}, getter[i]);
          }
          if (getter[i].is_assigned === 1) {
            filteredData[project_id].is_assigned = 1;
          }
        }
        filteredData = Object.values(filteredData).filter(row => row.is_assigned === 0);
        let result = {};
        for (let i in filteredData) {
          result[filteredData[i].project_id] = filteredData[i];
        }
        result = Object.values(result);
        // result.sort((a,b) => (b.project_id - a.project_id));

        let projectObj = {
          options: [
            {
              label: "MM",
              options: [],
            },
            {
              label: "4M",
              options: [],
            },
          ],
        };
        result.map(x => {
          if (x.owner === "MM") {
            projectObj.options[0].options.push(x);
          } else {
            projectObj.options[1].options.push(x);
          }
        });

        projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
        projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

        return projectObj.options;
        // return result
      }
      return;
    },
    questionContent() {
      if (this.question_id !== null && this.question_id !== "" && this.$store.state.question_list.loading_status === "success") {
        return this.questionList[this.question_id].question_content
          .replace(/<br>?/gm, "\n")
          .replace(/<[^>]*>?/gm, " ")
          .replace(/[ ]{2,}?/gm, " ");
      } else return;
    },
    activeProjectModules() {
      // wszystkie moduły aktywnych projektów
      let projects = this.$store.getters.getProjectsAll;
      let questionProjects = this.questionProjects;
      let filteredData = {};
      let temp = {};
      const sortedData = {};
      for (let i in questionProjects) {
        for (let [key, project] of Object.entries(projects)) {
          // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
          if (project.project_id === questionProjects[i].project_id) {
            temp[project.module] = Object.assign({}, project, { permitted: false });
            filteredData[project.project_id] = Object.assign({}, temp);
          }
        }
        temp = {};
      }

      this.modules = filteredData;
      let sourceData = [];

      for (let i in filteredData) {
        // sortowanie danych
        let tempData = filteredData[i];
        let sourceData = {};
        for (let ver of Object.keys(tempData)) {
          // iteracja po nazwach modułów danego projektu
          let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie pierwszej liczby
          filteredData[i][ver].number = res;
        }
      }

      let resultModules = {};

      for (let project_id in filteredData) {
        let projectModules = filteredData[project_id];
        sourceData = Object.values(projectModules);
        sourceData.sort((a, b) => a.number - b.number);
        let result = {};
        for (let i in sourceData) {
          result[sourceData[i].module] = sourceData[i];
        }
        resultModules[project_id] = result;
      }

      return resultModules;
    },
    questionProjects() {
      // projekty dla danego pytania
      let getter = this.projects;

      if (this.$store.state.question_projects.loading_status === "success") {
        let filteredData = getter.filter(
          // filtracja danych oraz przypisaniu
          row => this.activeFilter === "" || row.is_assigned, // no filter
        );
        return filteredData;
      }
      return;
    },
    tableData() {
      // table data needs to by an array
      if (this.question_id === null) {
        // zwrócenie pustej tablicy w przypadku braku wybranego pytania
        return [];
      }
      const projects = this.questionProjects;

      let data = {};
      let versions = {};

      for (let row in projects) {
        if (data[projects[row].project_id] === undefined) {
          data[projects[row].project_id] = projects[row];
        }
        if (data[projects[row].project_id].versions === undefined) {
          if (Object.keys(this.activeProjectModules).length > 0 && this.activeProjectModules[projects[row].project_id]) {
            data[projects[row].project_id].versions = JSON.parse(JSON.stringify(this.activeProjectModules[projects[row].project_id])); // przypisanie wszystkich modułów projektu
          } else {
            data[projects[row].project_id].versions = {};
          }
        }
        for (let i in data[projects[row].project_id].versions) {
          data[projects[row].project_id].versions[i].permitted = false;
        }
        if (
          Object.keys(this.modules).length > 0 &&
          projects[row].module !== null &&
          this.modules[projects[row].project_id] &&
          !Object.keys(this.modules[projects[row].project_id]).includes(projects[row].module) &&
          projects[row].module !== "ALL_VERSION"
        ) {
          data[projects[row].project_id].versions[projects[row].module] = {
            // wyświetlanie nieaktywnych modułów dla danego pytania oraz odrzucanie modułów ALL_VERSION
            description: "Wersja nieaktywna",
            module: projects[row].module,
            version: "inactive",
            tags: "INACTIVE",
            project: projects[row].project_id,
            permitted: true,
          };
        }
      }

      let newQuestionConfig = Object.values(this.newQuestionConfig);

      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        for (let conf of newQuestionConfig) {
          // przypisywanie odpowiednich oznaczeń do modułów
          if (typeof data[conf.project_id] !== "undefined" && typeof data[conf.project_id].versions[conf.module] !== "undefined") {
            data[conf.project_id].versions[conf.module].permitted = true;
          } else {
            //TODO: wpis dla nieaktywnych modułów
          }
        }
      }

      data = Object.values(data).map(row => Object.assign({}, row));

      return data;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      // paginacja danych
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.pagedData;
      }
      let result = this.tableData.filter(row => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  methods: {
    async loadData(force, force_all) {
      this.loading = true;

      const filters = {
        project_status: this.status ? 0 : 1,
        question_id: this.question_id,
      };

      if (force) {
        this.$store.commit(QUESTION_CONFIGS_INVALID);
        this.$store.commit(QUESTION_PROJECTS_INVALID);
      }

      if (force_all) {
        this.$store.commit(QUESTION_CONFIGS_INVALID);
        this.$store.commit(QUESTION_PROJECTS_INVALID);
        this.$store.commit(QUESTION_LIST_INVALID);
        this.$store.commit(ANSWERS_INVALID);
        this.$store.commit(PROJECTS_ALL_INVALID);
      }

      const p1 = this.$store.dispatch(LOAD_QUESTION_LIST, { subquestion: true }).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const p2 = this.$store.dispatch(LOAD_ANSWERS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const p3 = this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const promiseArray = [p1, p2, p3];

      if (this.question_id !== null) {
        const p4 = this.$store.dispatch(LOAD_QUESTION_CONFIGS, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        const p5 = this.$store.dispatch(LOAD_QUESTION_PROJECTS, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        promiseArray.push(p4, p5);
      }

      const end = await Promise.all(promiseArray);
      this.initConfig();
      this.record();

      this.loading = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    sortChange(obj) {
      this.sort = {
        prop: obj.prop,
        order: obj.order,
      };
      if (obj.order === "descending") {
        if (obj.prop === "project_name" || obj.prop === "host_name") {
          this.queriedData.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.queriedData.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (obj.prop === "project_name" || obj.prop === "host_name") {
          this.queriedData.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.queriedData.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        this.queriedData.sort((a, b) => a.project_id - b.project_id);
      }
      this.searchQuery += " ";
      this.searchQuery = this.searchQuery.slice(0, -1);
    },
    changeQuestion(payload) {
      // funkcja obsługująca zmianę pytania i wymuszająca przeładowanie danych
      this.question_id = payload;
      this.selectedProjectID = null;
      this.selectedProjectsIDs = [];
      if (payload != "") {
        this.loadData(true, false);
      } else {
        this.question_id = null;
      }
    },
    changeTypeFilter() {
      if (this.question_id !== null) {
        if (this.questionList[this.question_id] === undefined) {
          this.question_id = null;
          this.selectedProjectID = null;
          this.selectedProjectsIDs = [];
        }
      }
    },
    initConfig() {
      // początkowa konfiguracja modułów
      this.newQuestionConfig = {};
      let questionConfigs = this.$store.getters.getQuestionConfigs;
      for (let i in questionConfigs) {
        if (questionConfigs[i].question_id === this.question_id) {
          this.newQuestionConfig[questionConfigs[i].project_id + "_" + questionConfigs[i].module] = {
            module: questionConfigs[i].module,
            project_id: questionConfigs[i].project_id,
            previous_assign: true,
            current_assign: true,
            question_type: questionConfigs[i].question_type,
            question_tag:
              this.activeProjectModules[questionConfigs[i].project_id] &&
              this.activeProjectModules[questionConfigs[i].project_id][questionConfigs[i].module] !== undefined
                ? this.activeProjectModules[questionConfigs[i].project_id][questionConfigs[i].module].tags
                : "inactive",
          };
        }
      }
    },
    checkboxChecked(checkedBool, row, checkedModule) {
      // funkcja obsługująca zmianę przypisania checkboxa danej wersji pytania
      let checkedConfig = row.project_id + "_" + checkedModule;
      let projectConfig = this.newQuestionConfig;

      if (Object.keys(projectConfig).includes(checkedConfig)) {
        projectConfig[checkedConfig].current_assign = checkedBool;
      } else {
        projectConfig[checkedConfig] = {
          module: checkedModule,
          project_id: row.project_id,
          previous_assign: false,
          current_assign: checkedBool,
          question_type: this.questionList[this.question_id].question_type,
          question_tag: this.activeProjectModules[row.project_id][checkedModule].tags,
        };
      }
      this.record();
    },
    changeCheckboxes(row, direction) {
      if (this.editPermitted) {
        let versions = row.versions;
        for (let i in versions) {
          this.checkboxChecked(direction, row, versions[i].module);
          versions[i].permitted = direction;
        }
      }
    },
    record() {
      let newQuestionConfig = Object.values(this.newQuestionConfig);
      let insert_project = {};
      let delete_project = {};
      for (let el in newQuestionConfig) {
        if (newQuestionConfig[el].current_assign !== newQuestionConfig[el].previous_assign) {
          // sprawdzenie czy poprzednie przypisanie różni się od aktualnego
          if (newQuestionConfig[el].previous_assign === false) {
            // sprawdzenie czy moduł został zaznaczony czy odznaczony
            insert_project[el] = newQuestionConfig[el];
          } else {
            delete_project[el] = newQuestionConfig[el];
          }
        }
      }

      insert_project = Object.values(insert_project);
      delete_project = Object.values(delete_project);
      let record = {
        // stworzenie obiektu
        question_id: this.question_id,
        insert_project: insert_project,
        delete_project: delete_project,
      };

      this.recordObject = record;
    },
    changeEdit() {
      this.record();

      if (this.checkboxes.edit === true) {
        let record = this.recordObject;
        let varThis = this;

        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
            cancelButton: "btn btn-light btn-fill",
          },
          buttonsStyling: false,
        });

        if (record.delete_project.length > 0 || record.insert_project.length > 0) {
          swalWithBootstrapButtons
            .fire({
              title: "Czy chcesz wyjść bez zapisywania?",
              text: `Zmiany zostaną odrzucone`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Akceptuj",
              cancelButtonText: "Anuluj",
              reverseButtons: true,
            })
            .then(res => {
              if (res.isConfirmed) {
                varThis.initConfig();
                varThis.checkboxes.edit = false;
                varThis.record();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          this.checkboxes.edit = false;
        }
      } else {
        this.checkboxes.edit = true;
      }
    },
    async saveData(addProject, projectRecord) {
      // tworzenie tablicy zmian - odznaczenia modułów i przypisania nowych, a następnie zapisania danych do bazy
      let record = {};

      if (addProject) {
        record = {
          question_id: this.question_id,
          insert_project: projectRecord,
          delete_project: [],
        };
      } else {
        this.record();
        record = this.recordObject;
      }

      if (record.insert_project.length > 0 || record.delete_project.length > 0) {
        await this.$store.dispatch(SAVE_QUESTION_CONFIG, record).catch(error => {
          // zapisanie danych do bazy
          console.log(error.response);
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getQuestionConfigsModifiedRecords === true) {
          // wyświetlenie informacji o zapisaniu
          eventBus.$emit("notify", {
            message: "Rekord został zmodyfikowany",
            type: "success",
          });

          this.checkboxes.edit = false;
          this.loadData(true, false);
        } else {
          eventBus.$emit("notify", {
            message: "Coś poszło nie tak..",
            type: "warning",
          });
        }
      }
    },
    async deleteData(record) {
      await this.$store.dispatch(DELETE_QUESTION_CONFIG, record).catch(error => {
        // zapisanie danych do bazy
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getQuestionConfigsDeletingStatus === "deleted") {
        // wyświetlenie informacji o zapisaniu
        eventBus.$emit("notify", {
          message: "Rekord został usunięty",
          type: "success",
        });

        this.checkboxes.edit = false;
        this.loadData(true, false);
        this.searchQuery = " ";
        this.searchQuery = "";
      }
    },
    addProject() {
      // funkcja obsługująca dodanie projektu oraz zmianę licznika wymuszającego przeliczenie computed
      var varThis = this;
      let question = this.selectedProjectsIDs.length > 1 ? "wybrane projekty" : "wybrany projekt";
      let text = this.selectedProjectsIDs.length > 1 ? "Projekty zostaną zapisane" : "Projekt zostanie zapisany";
      let question_type = this.questionList[this.question_id].question_type;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      if (question_type === "QUIZ" && this.selectedVersions.length === 0) {
        eventBus.$emit("notify", {
          message: `Należy wybrać wersję pytania typu ${question_type}!`,
          type: "warning",
        });
        return;
      }
      swalWithBootstrapButtons
        .fire({
          title: `Czy chcesz dodać ${question} do pytania?`,
          text: `${text}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then(res => {
          if (res.isConfirmed) {
            if (question_type !== "QUIZ") {
              let selectedProjectsIDs = varThis.selectedProjectsIDs;
              let projects = {};
              for (let i in selectedProjectsIDs) {
                projects[i] = {
                  current_assign: true,
                  module: null,
                  previous_assign: false,
                  project_id: selectedProjectsIDs[i],
                  question_type: varThis.questionList[varThis.question_id].question_type,
                };
              }
              projects = Object.values(projects);
              varThis.saveData(true, projects);
              varThis.selectedProjectsIDs = [];
            } else {
              let selectedVersions = varThis.selectedVersions;
              let projects = [];
              for (let i in selectedVersions) {
                let project = {
                  current_assign: true,
                  module: selectedVersions[i],
                  previous_assign: false,
                  project_id: varThis.selectedProjectID,
                  question_type: varThis.questionList[varThis.question_id].question_type,
                };
                projects.push(project);
              }
              varThis.saveData(true, projects);
              varThis.selectedProjectID = "";
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    handleDelete(row) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      var varThis = this;
      swalWithBootstrapButtons
        .fire({
          title: "Czy chcesz usunąć to pytanie z projektu?",
          text: `Pytanie zostanie usunięte`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then(res => {
          if (res.isConfirmed) {
            let model = {
              question_id: varThis.question_id,
              project_id: row.project_id,
            };
            varThis.deleteData(model);
          }
        })
        .catch(() => {
          return;
        });
    },
    getBottomSelectWidth() {
      this.bottomSelectWidth = document.getElementById("questionConfigProjectSelect").offsetWidth;
    },
    getBottomRightSelectWidth() {
      this.bottomRightSelectWidth = document.getElementById("bottomRightQuestionProjectsSelect").offsetWidth;
    },
    getUpperSelectWidth() {
      this.upperSelectWidth = document.getElementById("questionConfigQuestionSelect").offsetWidth;
    },
    takeToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    clearVersions() {
      this.selectedVersions = [];
    },
  },
  created() {
    this.loadData(false, false);
    this.record();
    eventBus.$on("forceReload", () => {
      if (this.question_id !== null) this.question_id = null;
      this.loadData(false, true);
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="scss" scoped>
.has-gutter:hover {
  cursor: pointer;
}

.el-table,
.el-table tr,
.el-table thead th {
  background-color: #fff !important;
}
.blue > td {
  /* color: blue; */
  background-color: #edf9ff;
}
.red {
  color: red;
}
.pink {
  color: pink;
}
.grey > td {
  /* background-color: #ededed; */
  color: silver;
}
a,
a:hover {
  text-decoration: none;
  color: red;
}
</style>
