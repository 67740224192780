<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h5 class="title">Szablony odpowiedzi {{ company[company_id].name }}</h5>
      </div>
      <div class="col-sm-12 col-md-4 text-md-right">
        <el-select v-model="company_id" size="small" filterable placeholder="Filtruj">
          <el-option v-for="cmp in company" :key="cmp.id" :label="cmp.name" :value="cmp.id"></el-option>
        </el-select>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <el-table :data="responseTemplates" style="width: 100%" :default-sort="{ prop: 'id', order: 'ascending' }">
            <el-table-column type="expand">
              <template v-slot="props">
                <p><b>Opis: </b>{{ props.row.description }}</p>
                <p><b>Treść: </b><pre>{{ props.row.body }}</pre></p>
              </template>
            </el-table-column>
            <el-table-column label="ID" property="id" width="80" sortable></el-table-column>
            <el-table-column label="Typ" property="response_type_id" min-width="100" sortable>
              <template v-slot="props">
                {{ `[${props.row.response_type_id}] ${props.row.name}` }}
              </template>
            </el-table-column>
            <el-table-column label="Szablon" property="template" align="left" min-width="200">
              <template v-slot="props">
                <span class="overflow">{{ props.row.body }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" width="80">
              <template slot="header">
                    <p-button type="success" icon round @click="handleNew()">
                      <i class="fa fa-plus"></i>
                    </p-button>
                </template>
              <template v-slot="props">
                <p-button type="success" :disabled="!props.row.is_editable" size="sm" icon :id="'response_template_form_edit_button_' + props.$index" @click="handleEdit(props.$index, props.row, 'response')">
                  <i class="fa fa-edit"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-10">
        <h5 class="title" style="cursor: pointer;" @click="showMailboxes = !showMailboxes">Szablony skrzynek <i v-if="!showMailboxes" class="fa fa-caret-square-o-down" aria-hidden="true"></i></h5>
      </div>
    </div>
    <div class="card" v-show="showMailboxes">
      <div class="card-body">
        <div class="row">
          <el-table :data="mailboxTemplates" style="width: 100%" :default-sort="{ prop: 'id', order: 'ascending' }">
            <el-table-column type="expand">
              <template v-slot="props">
                <p><b>Szablon: </b><pre>{{ props.row.template }}</pre></p>
                <p><b>Autoresponder: </b><pre>{{ props.row.autoresponder }}</pre></p>
              </template>
            </el-table-column>
            <el-table-column label="ID" property="id" width="80" sortable></el-table-column>
            <el-table-column label="Skrzynka" property="login" min-width="140" sortable></el-table-column>
            <el-table-column label="Administrator" property="company_id" width="180" sortable>
              <template v-slot="props">
                {{ `[${props.row.company_id}] ${props.row.company_name}` }}
              </template>
            </el-table-column>
            <el-table-column label="Szablon" property="template" align="left" min-width="120">
              <template v-slot="props">
                <span class="overflow">{{ props.row.template }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Autoresponder" property="autoresponder" align="left" min-width="120">
              <template v-slot="props">
                <span class="overflow">{{ props.row.autoresponder }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Aktywny" property="is_active" align="left" width="110">
              <template v-slot="props">
                <el-switch
                  :value="props.row.is_active"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#6bd098"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column align="center" width="80">
              <template v-slot="props">
                <p-button type="success" size="sm" icon :id="'mailbox_template_form_edit_button_' + props.$index" @click="handleEdit(props.$index, props.row, 'mailbox')">
                  <i class="fa fa-edit"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Tooltip, Switch, Select, Option } from "element-ui";

// vuex actions
import { LOAD_MAILBOX_TEMPLATES, MAILBOX_TEMPLATES_INVALID } from "src/store/actions/complaints/mailbox_templates";
import { LOAD_RESPONSE_TEMPLATES, RESPONSE_TEMPLATES_INVALID } from "src/store/actions/complaints/response_templates";
import { LOAD_COMPANY, COMPANY_INVALID } from "src/store/actions/complaints/company";
import { LOAD_RESPONSE_TYPES, RESPONSE_TYPES_INVALID } from "src/store/actions/complaints/response_types";

// event bus
import { eventBus } from "src/main";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    [Switch.name]: Switch,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      company_id: 1,
      loading: false,
      showMailboxes: false
    };
  },
  computed: {
    mailboxTemplates() {
      return Object.values(this.$store.getters.getMailboxTemplates);
    },
    responseTemplates() {
      return Object.values(this.$store.getters.getResponseTemplates).filter(x => x.company_id === this.company_id)
      // .filter(x => x.is_editable);
    },
    company() {
      return this.$store.getters.getCompany;
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData(force) {
      this.loading = true;

      if (force) {
        this.$store.commit(MAILBOX_TEMPLATES_INVALID);
        this.$store.commit(RESPONSE_TEMPLATES_INVALID);
        this.$store.commit(RESPONSE_TYPES_INVALID);
        this.$store.commit(COMPANY_INVALID);
      }

      this.$store.dispatch(LOAD_COMPANY, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_RESPONSE_TEMPLATES, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_RESPONSE_TYPES, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_MAILBOX_TEMPLATES, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    handleEdit(index, row, mode) {
      this.$router.push(`/complaints/${mode}_template_form/${row.id}`);
    },
    handleNew() {
      this.$router.push(`/complaints/response_template_form/?company=${this.company_id}`);
    },
  },
  created() {
    this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy() {
    this.$store.commit(MAILBOX_TEMPLATES_INVALID);
    this.$store.commit(RESPONSE_TEMPLATES_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="scss" scoped>
.center {
  justify-content: center;
  align-content: center;
  display: flex;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
