<template>
  <div class="modal-wrapper">
    <div class="row header-row">
      <div class="col-sm-8">
        <h5 class="title">Statystyki mailingów</h5>
      </div>
      <div class="col-sm-3">
        <fg-input>
          <el-select
            v-model="choosedDomains"
            placeholder="Filtruj domeny"
            name="domains"
            filterable
            clearable
            multiple
            @input="counter += 1"
          >
            <el-option
              v-for="domain in domainArray"
              :label="domain"
              :value="domain"
              :key="domain"
            ></el-option>
          </el-select>
        </fg-input>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
    </div>

    <div class="card" v-for="banner in tableData" :key="banner.banner_id">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-11">
            <h5>{{ '[' + banner.banner_id + '] ' + banner.banner_name }}</h5>
          </div>
        </div>
      <div class="card-body row">
        <el-table :data="banner.domains" style="width:100%;" :summary-method="getSummaries" show-summary>
          <el-table-column label="Domena" property="domain" min-width="120" align="left"></el-table-column>
          <el-table-column label="Wysłane" property="sent" min-width="100" align="right">
            <template v-slot="props">
              {{ props.row.sent | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="%" property="sent_percent" min-width="100" align="right">
            <template v-slot="props">
              {{ getPercentage(props.row.sent, banner.sent) }}
            </template>
          </el-table-column>
          <el-table-column label="Otwarte" property="displayed" min-width="100" align="right">
            <template v-slot="props">
              {{ props.row.displayed | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="%" property="displayed_percent" min-width="100" align="right">
            <template v-slot="props">
              {{ getPercentage(props.row.displayed, banner.displayed) }}
            </template>
          </el-table-column>
          <el-table-column label="OR" property="open_rate" min-width="100" align="right">
            <template v-slot="props">
              {{ getRate(props.row.displayed, props.row.sent) }}
            </template>
          </el-table-column>
          <el-table-column label="Kliknięte" property="clicked" min-width="100" align="right">
            <template v-slot="props">
              {{ props.row.clicked | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column label="%" property="displayed_percent" min-width="100" align="right">
            <template v-slot="props">
              {{ getPercentage(props.row.clicked, banner.clicked) }}
            </template>
          </el-table-column>
          <el-table-column label="CTR" property="open_rate" min-width="100" align="right">
            <template v-slot="props">
              {{ getRate(props.row.clicked, props.row.sent) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library
import { Select, Option } from "element-ui";

// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

import * as priv from 'src/privileges.js';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      loading: false,
      domainArray: ['WP', 'ONET', 'O2', 'INTERIA', 'GMAIL', 'INNA'],
      choosedDomains: [],
      counter: 0,
      referenceCounter: 0,
    }
  },
  props: ['mailingStats'],
  computed: {
    tableData() {
      let counter = this.counter;
      let referenceCounter = this.referenceCounter;
      let check = this.checkAll;
      let mailingStats = this.mailingStats;
      let tableData = [];
      if(counter !== referenceCounter) { // licznik wymuszający przeliczenie tabeli
        for(let bid in mailingStats) { // iteracja po bannerach
          let record = mailingStats[bid];
          let tableObject = {
            project_id: record.project_id,
            project_name: record.project_name,
            banner_id: record.bannerid,
            banner_name: record.banner_name,
            domains: {
              WP : {
                domain: 'WP',
                sent: 0,
                displayed: 0,
                clicked: 0
              },
              ONET : {
                domain: 'ONET',
                sent: 0,
                displayed: 0,
                clicked: 0
              },
              O2 : {
                domain: 'O2',
                sent: 0,
                displayed: 0,
                clicked: 0
              },
              INTERIA : {
                domain: 'INTERIA',
                sent: 0,
                displayed: 0,
                clicked: 0
              },
              GMAIL : {
                domain: 'GMAIL',
                sent: 0,
                displayed: 0,
                clicked: 0
              },
              INNA : {
                domain: 'INNA',
                sent: 0,
                displayed: 0,
                clicked: 0
              },
            }
          }

          if(record['O2']) {
            tableObject.domains['O2'].sent = record['O2'].sent ? record['O2'].sent : 0;
            tableObject.domains['O2'].displayed = record['O2'].displayed ? record['O2'].displayed : 0;
            tableObject.domains['O2'].clicked = record['O2'].clicked ? record['O2'].clicked : 0;
            tableObject.domains['O2'].domain = record['O2'].domain ? record['O2'].domain : 'O2';
          }
          if(record['GMAIL']) {
            tableObject.domains['GMAIL'].sent = record['GMAIL'].sent ? record['GMAIL'].sent : 0;
            tableObject.domains['GMAIL'].displayed = record['GMAIL'].displayed ? record['GMAIL'].displayed : 0;
            tableObject.domains['GMAIL'].clicked = record['GMAIL'].clicked ? record['GMAIL'].clicked : 0;
            tableObject.domains['GMAIL'].domain = record['GMAIL'].domain ? record['GMAIL'].domain : 'GMAIL';
          }
          if(record['INNA']) {
            tableObject.domains['INNA'].sent = record['INNA'].sent ? record['INNA'].sent : 0;
            tableObject.domains['INNA'].displayed = record['INNA'].displayed ? record['INNA'].displayed : 0;
            tableObject.domains['INNA'].clicked = record['INNA'].clicked ? record['INNA'].clicked : 0;
            tableObject.domains['INNA'].domain = record['INNA'].domain ? record['INNA'].domain : 'INNA';
          }
          if(record['INTERIA']) {
            tableObject.domains['INTERIA'].sent = record['INTERIA'].sent ? record['INTERIA'].sent : 0;
            tableObject.domains['INTERIA'].displayed = record['INTERIA'].displayed ? record['INTERIA'].displayed : 0;
            tableObject.domains['INTERIA'].clicked = record['INTERIA'].clicked ? record['INTERIA'].clicked : 0;
            tableObject.domains['INTERIA'].domain = record['INTERIA'].domain ? record['INTERIA'].domain : 'INTERIA';
          }
          if(record['ONET']) {
            tableObject.domains['ONET'].sent = record['ONET'].sent ? record['ONET'].sent : 0;
            tableObject.domains['ONET'].displayed = record['ONET'].displayed ? record['ONET'].displayed : 0;
            tableObject.domains['ONET'].clicked = record['ONET'].clicked ? record['ONET'].clicked : 0;
            tableObject.domains['ONET'].domain = record['ONET'].domain ? record['ONET'].domain : 'ONET';
          }
          if(record['WP']) {
            tableObject.domains['WP'].sent = record['WP'].sent ? record['WP'].sent : 0;
            tableObject.domains['WP'].displayed = record['WP'].displayed ? record['WP'].displayed : 0;
            tableObject.domains['WP'].clicked = record['WP'].clicked ? record['WP'].clicked : 0;
            tableObject.domains['WP'].domain = record['WP'].domain ? record['WP'].domain : 'WP';
          }
          tableObject.sent = tableObject.domains['O2'].sent + tableObject.domains['GMAIL'].sent + tableObject.domains['INNA'].sent + tableObject.domains['INTERIA'].sent + tableObject.domains['ONET'].sent + tableObject.domains['WP'].sent;
          tableObject.displayed = tableObject.domains['O2'].displayed + tableObject.domains['GMAIL'].displayed + tableObject.domains['INNA'].displayed + tableObject.domains['INTERIA'].displayed + tableObject.domains['ONET'].displayed + tableObject.domains['WP'].displayed;
          tableObject.clicked = tableObject.domains['O2'].clicked + tableObject.domains['GMAIL'].clicked + tableObject.domains['INNA'].clicked + tableObject.domains['INTERIA'].clicked + tableObject.domains['ONET'].clicked + tableObject.domains['WP'].clicked;
          let tableResult = utils.deepCopy(tableObject);
          tableResult.domains = [];
          if(this.choosedDomains.length === 0) {
            tableResult.domains = Object.values(tableObject.domains);;
          } else {
            this.choosedDomains.map(x => {
              tableResult.domains.push(tableObject.domains[x]);
            })
          }
          tableData.push(tableResult);
        }
      referenceCounter++;
      }
      return tableData;
    },
  },
  methods: {
    getPercentage(a, b) {
      if(b === 0) {
        return '-'
      }
      return Math.round(a/b * 100 ) + '%';
    },
    getRate(a, b) {
      if(b === 0) {
        return '-'
      }
      return (Math.round(a/b * 100 * 10) / 10) + '%';
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      console.log(param)
      columns.forEach((column, index) => {
        if(index === 0) {
          sums[index] = 'Suma';
          return;
        }
        if(index === 1 || index === 3 || index === 6) {
          const values = data.map(item => Number(item[column.property]));
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = utils.formatNumber(sums[index]);
        } else {
          sums[index] = ' - ';
        }
      });
      return sums;
    },
  },
  created() {
    this.counter += 1;
  },
};
</script>

<style lang="css" scoped>
  .center {
    display: flex;
    margin: auto;
    justify-content: center;
    align-content: center;
  }
  .card {
    margin: 0;
    display: flex;
  }
  .header-row {
    padding-top: 15px;
    padding-left: 15px;
  }
  .modal-wrapper {
    position: relative;
  }
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
  }
</style>
