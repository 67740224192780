<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 v-if="!editMode" class="card-title">Nowa nagroda</h4>
        <h4 v-if="editMode" class="card-title">Edycja nagrody {{ model.id }}</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Nazwa</label>
          <fg-input
            type="text"
            name="name"
            data-vv-as="Nazwa"
            v-validate="modelValidations.name"
            :error="getError('name')"
            v-model="model.name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Pełna nazwa</label>
          <fg-input :error="getError('full_name')">
            <el-input
              v-validate="modelValidations.full_name"
              data-vv-as="Pełna nazwa"
              type="textarea"
              name="full_name"
              :rows="5"
              :autosize="{ minRows: 1, maxRows: 8 }"
              v-model="model.full_name"
            >
            </el-input>
          </fg-input>
        </div>

        <div class="form-group">
          <label>Nazwa skrócona</label>
          <fg-input
            type="text"
            name="short_name"
            data-vv-as="Nazwa skrócona"
            v-validate="modelValidations.short_name"
            :error="getError('short_name')"
            v-model="model.short_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Opis</label>
          <fg-input
            type="text"
            name="description"
            data-vv-as="Opis"
            v-validate="modelValidations.description"
            :error="getError('description')"
            v-model="model.description"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Cena</label>
          <fg-input
            type="number"
            name="price"
            data-vv-as="Cena"
            v-validate="modelValidations.price"
            :error="getError('price')"
            v-model="model.price"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Cena 'tekst'</label>
          <fg-input
            type="string"
            name="price_str"
            data-vv-as="Cena tekst"
            v-validate="modelValidations.price_str"
            :error="getError('price_str')"
            v-model="model.price_str"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Obrazek</label>
          <fg-input
            type="string"
            name="img_name"
            data-vv-as="Obrazek"
            v-validate="modelValidations.img_name"
            :error="getError('img_name')"
            v-model="model.img_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Opis losowania</label>
          <fg-input
            type="string"
            name="description_losowania"
            data-vv-as="Opis losowania"
            v-validate="modelValidations.description_losowania"
            :error="getError('description_losowania')"
            v-model="model.description_losowania"
          ></fg-input>
        </div>
      </div>
      <div class="card-footer text-center">
        <router-link to="/questions/prizes">
          <p-button type="default" link style="margin-right: 5vw">
            Anuluj
          </p-button>
        </router-link>
        <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Select, Option, Switch, Input } from "element-ui";

import { SAVE_PRIZE, PRIZES_INVALID } from "src/store/actions/questions/prizes";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [Input.name]: Input,
  },
  data() {
    return {
      model: {
        id: null,
        name: "",
        full_name: "",
        short_name: "",
        description: "",
        price: 0.0,
        price_str: "",
        img_name: "",
        description_losowania: "",
      },
      modelValidations: {
        id: {
          numeric: true,
          min: 1,
        },
        name: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 2,
          max: 15,
        },
        full_name: {
          min: 2,
          max: 200,
        },
        short_name: {
          regex: regexp.TEXT_REGEXP,
          min: 2,
          max: 50,
        },
        description: {
          regex: regexp.TEXT_REGEXP,
          max: 128,
        },
        price: {
          decimal: 2,
          // numeric: true,
        },
        price_str: {
          max: 6,
        },
        img_name: {
          regex: regexp.TEXT_REGEXP,
          max: 64,
        },
        description_losowania: {
          regex: regexp.TEXT_REGEXP,
          max: 30,
        },
      },
      editMode: true,
    };
  },
  computed: {
    prize_id() {
      return this.$route.params.id;
    },
  },
  methods: {
    loadData() {},
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();

      if (isValid) {
        await this.$store.dispatch(SAVE_PRIZE, this.model).catch(error => {
          let message = "";
          console.log(error.response);
          switch (error.response.data.code) {
            case 409:
              message = "Nazwy muszą być unikalne!";
              break;
            default:
              message = "Błąd zapisu danych!";
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning",
          });
        });

        if (this.$store.getters.getPrizesModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
            type: "success",
          });

          this.$store.commit(PRIZES_INVALID); // oznacz nieaktualność danych
          this.$router.push({ name: "Nagrody" }); // przekieruj do listy
        }
      }
    },
  },
  created() {
    this.loadData();
    if (!!this.prize_id) {
      let prize = this.$store.getters.getPrizes.prize[this.prize_id];
      this.model = Object.assign(this.model, prize);
      // this.model.price = parseFloat(this.model.price).toFixed(2);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  },
};
</script>
<style></style>
