import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_SUBQUESTION_PARENTS,
  SUBQUESTION_PARENTS_LOADING,
  SUBQUESTION_PARENTS_LOADING_SUCCESS,
  SUBQUESTION_PARENTS_LOADING_ERROR,
  SUBQUESTION_PARENTS_INVALID,
  SAVE_SUBQUESTION_PARENTS,
  SUBQUESTION_PARENTS_SAVING,
  SUBQUESTION_PARENTS_SAVING_SUCCESS,
  SUBQUESTION_PARENTS_SAVING_ERROR
} from 'src/store/actions/questions/subquestion_parents'

const storeObjectConfig = {
  objectName: "Questions",
  getterName: "getSubquestionParents",
  loadActionName: LOAD_SUBQUESTION_PARENTS,
  loadingMutationName: SUBQUESTION_PARENTS_LOADING,
  loadingSuccessMutationName: SUBQUESTION_PARENTS_LOADING_SUCCESS,
  loadingErrorMutationName: SUBQUESTION_PARENTS_LOADING_ERROR,
  invalidMutationName: SUBQUESTION_PARENTS_INVALID,
  saveActionName: SAVE_SUBQUESTION_PARENTS,
  savingMutationName: SUBQUESTION_PARENTS_SAVING,
  savingSuccessMutationName: SUBQUESTION_PARENTS_SAVING_SUCCESS,
  savingErrorMutationName: SUBQUESTION_PARENTS_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/questions/questions/subquestionParents",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
