<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 v-if="!editMode" class="card-title">Nowe uprawnienie</h4>
        <h4 v-if="editMode" class="card-title">Edycja uprawnienia</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Nazwa systemu</label>
          <fg-input :error="getError('priv_system_id')" data-vv-as="Nazwa systemu">
            <el-select
              v-model="model.priv_system_id"
              name="system_name"
              data-vv-as="Nazwa systemu"
              v-validate="modelValidations.priv_system_id"
              filterable
            >
              <el-option
                v-for="system in systems"
                :label="system.system_name"
                :value="system.system_id"
                :key="system.system_id"
              ></el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="form-group">
          <label>PrivType</label>
          <fg-input :error="getError('priv_priv_type_id')" data-vv-as="PrivType">
            <el-select
              v-model="model.priv_priv_type_id"
              name="priv_type_name"
              data-vv-as="PrivType"
              v-validate="modelValidations.priv_priv_type_id"
              filterable
            >
              <el-option
                v-for="priv_type in priv_types"
                :label="priv_type.priv_type_name"
                :value="priv_type.priv_type_id"
                :key="priv_type.priv_type_id"
              ></el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="form-group">
          <label>Tryb</label>
          <fg-input :error="getError('priv_mode')" data-vv-as="Tryb">
            <el-select v-model="model.priv_mode" name="priv_mode" data-vv-as="Tryb" filterable>
              <el-option v-for="priv in this.privDict" :label="priv" :value="priv" :key="priv"></el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="form-group">
          <label>Nazwa endpointu</label>
          <fg-input :error="getError('priv_custom_id')" data-vv-as="Nazwa endpointu">
            <el-select
              v-model="model.priv_custom_id"
              name="system_name"
              data-vv-as="Nazwa endpointu"
              v-validate="modelValidations.priv_custom_id"
              filterable
            >
              <el-option
                v-for="endpoint in endpoints"
                :label="`[${endpoint.endpoint_id}] ${endpoint.endpoint_name}`"
                :value="endpoint.endpoint_id"
                :key="endpoint.endpoint_id"
              >
                <te
              </el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="form-group">
          <label>Nazwa</label>
          <fg-input
            type="text"
            name="priv_name"
            data-vv-as="Nazwa"
            v-validate="modelValidations.priv_name"
            :error="getError('priv_name')"
            v-model="model.priv_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Opis</label>
          <fg-input
            type="text"
            name="priv_description"
            data-vv-as="Opis"
            v-validate="modelValidations.priv_description"
            :error="getError('priv_description')"
            v-model="model.priv_description"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Aktywny</label>
          <fg-input>
            <el-switch v-model="model.priv_is_active" active-value="true" inactive-value="false"></el-switch>
          </fg-input>
        </div>
      </div>
      <div class="card-footer text-center">
        <router-link to="/admin/permissions">
          <p-button type="default" link style="margin-right: 5vw">Anuluj</p-button>
        </router-link>
        <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Select, Option, Switch } from "element-ui";

import {
  SAVE_PERMISSION,
  PERMISSIONS_INVALID
} from "src/store/actions/admin/permissions";
import { LOAD_SYSTEMS, SYSTEMS_INVALID } from "src/store/actions/admin/systems";
import {
  LOAD_ENDPOINTS,
  ENDPOINTS_INVALID
} from "src/store/actions/admin/endpoints";
import {
  LOAD_PRIV_TYPES,
  PRIV_TYPES_INVALID
} from "src/store/actions/admin/priv_types";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  data() {
    return {
      model: {
        priv_id: null,
        priv_system_id: null,
        priv_priv_type_id: null,
        priv_mode: "",
        priv_custom_id: null,
        priv_name: "",
        priv_description: "",
        priv_is_active: "true"
      },
      modelValidations: {
        priv_id: {
          numeric: true,
          min: 1
        },
        priv_system_id: {
          numeric: true,
          required: true,
          min: 1
        },
        priv_priv_type_id: {
          numeric: true,
          required: true,
          min: 1
        },
        priv_mode: {
          required: true,
          // regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .-]+)$",
          regex: regexp.NAME_REGEXP,
          min: 1,
          max: 32
        },
        priv_custom_id: {
          numeric: true,
          required: true,
          min: 1
        },
        priv_name: {
          required: true,
          // regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .-]+)$",
          regex: regexp.URL_REGEXP,
          min: 3,
          max: 32
        },
        priv_description: {
          // regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .-]+)$",
          regex: regexp.TEXT_REGEXP,
          max: 128
        }
      },
      editMode: true,
      privDict: ["READ", "EDIT", "DELETE"]
    };
  },
  computed: {
    priv_id() {
      return this.$route.params.id;
    },
    // permissions() {
    //   // table data needs to by an array
    //   const permissions = Object.values(this.$store.getters.getPermissions);
    //   return [...new Set(permissions.map(item => item.priv_mode))]
    // },
    systems() {
      // table data needs to by an array
      return Object.values(this.$store.getters.getSystems);
    },
    endpoints() {
      // table data needs to by an array
      return Object.values(this.$store.getters.getEndpoints);
    },
    priv_types() {
      // table data needs to by an array
      return Object.values(this.$store.getters.getPrivTypes);
    }
  },
  methods: {
    loadData(force) {
      if (force) {
        this.$store.commit(SYSTEMS_INVALID);
        this.$store.commit(ENDPOINTS_INVALID);
        this.$store.commit(PRIV_TYPES_INVALID);
      }

      this.$store.dispatch(LOAD_SYSTEMS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
      this.$store.dispatch(LOAD_ENDPOINTS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
      this.$store.dispatch(LOAD_PRIV_TYPES, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();

      if (isValid) {
        await this.$store.dispatch(SAVE_PERMISSION, this.model).catch(error => {
          let message = "";
          console.log(error.response);
          switch (error.response.data.code) {
            case 409:
              message = "Nazwy muszą być unikalne!";
              break;
            default:
              message = "Błąd zapisu danych!";
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning"
          });
        });

        if (this.$store.getters.getPermissionsModifiedRecords === true) {
          eventBus.$emit("notify", {
            message:
              "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
            type: "success"
          });
          this.$store.commit(PERMISSIONS_INVALID); // oznacz nieaktualność danych
          this.$router.push({ name: "Uprawnienia" }); // przekieruj do listy
        }
      }
    }
  },
  created() {
    this.loadData();
    if (!!this.priv_id) {
      let permission = this.$store.getters.getPermissions[this.priv_id];
      this.model = Object.assign(this.model, permission);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  }
};
</script>
<style>
</style>


