<template>
  <div>
    <el-table :data="tableData" style="width: 100%" size="mini" :row-style="rowStyle" :cell-class-name="cellClass" :header-cell-style="headerStyle">
      <el-table-column type="expand">
        <template v-slot="props">
          <div class="row tableRow">
            <div style="width: 400px">
              <table class="table">
                <tbody>
                  <tr>
                    <td class="verticalTableHeader">Data wysyłki</td>
                    <td v-if="summary">-</td>
                    <td v-else>{{ getDetailedDate(props.row.send_date) }}</td>
                  </tr>
                  <tr>
                    <td class="verticalTableHeader">Kliki</td>
                    <td>{{ props.row.click }}</td>
                  </tr>
                  <tr>
                    <td class="verticalTableHeader">Main</td>
                    <td>{{ props.row.main }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="width: 350px; margin-left: 20px">
              <table class="table">
                <thead>
                <tr>
                  <td>Rekordy</td>
                  <td>Zebrane</td>
                  <td>Sprzedane</td>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="verticalTableHeader">Lead</td>
                    <td>{{ props.row.Lead | formatNumber }}</td>
                    <el-tooltip class="item" effect="light" :content="props.row.LeadSold | formatNumber" placement="top" :open-delay="300">
                      <td>{{ getRatio(props.row.LeadSold, props.row.Lead) }}</td>
                    </el-tooltip>
                  </tr>
                  <tr>
                    <td class="verticalTableHeader">Lead F1</td>
                    <td>{{ props.row.LeadF1 | formatNumber }}</td>
                    <el-tooltip class="item" effect="light" :content="props.row.LeadF1Sold | formatNumber" placement="top" :open-delay="300">
                      <td>{{ getRatio(props.row.LeadF1Sold, props.row.LeadF1) }}</td>
                    </el-tooltip>
                  </tr>
                  <tr>
                    <td class="verticalTableHeader">Full Lead</td>
                    <td>{{ props.row.FullLead | formatNumber }}</td>
                    <el-tooltip class="item" effect="light" :content="props.row.FullLeadSold | formatNumber" placement="top" :open-delay="300">
                      <td>{{ getRatio(props.row.FullLeadSold, props.row.FullLead) }}</td>
                    </el-tooltip>
                  </tr>
                  <tr>
                    <td class="verticalTableHeader">Lead Hot</td>
                    <td>{{ props.row.LeadHot | formatNumber }}</td>
                    <el-tooltip class="item" effect="light" :content="props.row.LeadHotSold | formatNumber" placement="top" :open-delay="300">
                      <td>{{ getRatio(props.row.LeadHotSold, props.row.LeadHot) }}</td>
                    </el-tooltip>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="width: 350px; margin-left: 20px">
              <table class="table">
                <thead>
                <tr>
                  <td>Rekordy</td>
                  <td>Zebrane</td>
                  <td>Sprzedane</td>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="verticalTableHeader">New Lead</td>
                    <td>{{ props.row.NewLead | formatNumber }}</td>
                    <el-tooltip class="item" effect="light" :content="props.row.NewLeadSold | formatNumber" placement="top" :open-delay="300">
                      <td>{{ getRatio(props.row.NewLeadSold, props.row.NewLead) }}</td>
                    </el-tooltip>
                  </tr>
                  <tr>
                    <td class="verticalTableHeader">New Lead F1</td>
                    <td>{{ props.row.NewLeadF1 | formatNumber }}</td>
                    <el-tooltip class="item" effect="light" :content="props.row.NewLeadF1Sold | formatNumber" placement="top" :open-delay="300">
                      <td>{{ getRatio(props.row.NewLeadF1Sold, props.row.NewLeadF1) }}</td>
                    </el-tooltip>
                  </tr>
                  <tr>
                    <td class="verticalTableHeader">New Full Lead</td>
                    <td>{{ props.row.NewFullLead | formatNumber }}</td>
                    <el-tooltip class="item" effect="light" :content="props.row.NewFullLeadSold | formatNumber" placement="top" :open-delay="300">
                      <td>{{ getRatio(props.row.NewFullLeadSold, props.row.NewFullLead) }}</td>
                    </el-tooltip>
                  </tr>
                  <tr>
                    <td class="verticalTableHeader">New Person</td>
                    <td>{{ props.row.NewPerson | formatNumber }}</td>
                    <el-tooltip class="item" effect="light" :content="props.row.NewPersonSold | formatNumber" placement="top" :open-delay="300">
                      <td>{{ getRatio(props.row.NewPersonSold, props.row.NewPerson) }}</td>
                    </el-tooltip>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col"></div>
            <div style="width: 400px">
              <table class="table">
                <tbody>
                  <tr>
                    <td class="verticalTableHeader">Lead</td>
                    <td>{{ props.row.lead | formatNumber }}</td>
                  </tr>
                  <tr>
                    <td class="verticalTableHeader">Przychód Hot</td>
                    <td>{{ props.row.income_hot | formatCurrency }}</td>
                  </tr>
                  <tr>
                    <td class="verticalTableHeader">Przychód Warm</td>
                    <td>{{ props.row.income_warm | formatCurrency }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="summary" label min-width="183" property></el-table-column>
      <el-table-column v-else label="Banner" min-width="200" property="banner_id">
        <template v-slot="props">
          <el-tooltip class="item" effect="light" :content="props.row.banner_name" placement="top" :open-delay="200">
            <span class="truncate" :style="props.row.banner_id ? 'cursor: pointer;' : ''" @click="bannerClick(props.row)">{{
              props.row.banner_id !== null ? "[" + props.row.banner_id + "] " + props.row.banner_name : props.row.banner_name
            }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Wysłane" min-width="100" property="sent" align="right">
        <template v-slot="props">
          {{ props.row.sent | formatNumber }}
        </template>
      </el-table-column>
      <el-table-column label="Klik [%]" min-width="100" property="click" align="right">
        <template v-slot="props">
          {{ getRatio(props.row.click, props.row.sent) }}
        </template>
      </el-table-column>
      <el-table-column label="Main [%]" min-width="100" property="main" align="right">
        <template v-slot="props">
          {{ getRatio(props.row.main, props.row.sent) }}
        </template>
      </el-table-column>
      <el-table-column label="LeadF1" min-width="100" property="LeadF1" align="right">
        <template v-slot="props">
          {{ props.row.LeadF1 | formatNumber }}
        </template>
      </el-table-column>
      <el-table-column label="Reko" min-width="100" property="reko_lead" align="right">
        <template v-slot="props">
          {{ props.row.reko_lead | formatNumber }}
        </template>
      </el-table-column>
      <el-table-column label="Sprzedaże" min-width="120" property="lead" align="right">
        <template v-slot="header">
          <el-tooltip class="item" effect="light" content="Sprzedaże / SprzedaneUU" placement="top" :open-delay="200">
            <span>Sprzedaże</span>
          </el-tooltip>
        </template>
        <template v-slot="props">
          <el-tooltip class="item" effect="light" content="Sprzedaże / SprzedaneUU" placement="top" :open-delay="200">
            <span>{{ formatNumber(props.row.leads) + "/" + formatNumber(props.row.leads_uu) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="CTR" min-width="80" property="leads_uu" align="right">
        <template v-slot="props">
          {{ getRatio(props.row.click, props.row.sent) }}
        </template>
      </el-table-column>
      <el-table-column label="Lead/Klik" min-width="100" property="leads_uu" align="right">
        <template v-slot="props">
          {{ getRatio(props.row.lead, props.row.click) }}
        </template>
      </el-table-column>
      <el-table-column label="Sprz/Lead" min-width="110" property="leads_uu" align="right">
        <template v-slot="header">
          <el-tooltip class="item" effect="light" content="Sprzedaże/Lead / SprzedaneUU/Lead" placement="top" :open-delay="200">
            <span>Sprz/Lead</span>
          </el-tooltip>
        </template>
        <template v-slot="props">
          <el-tooltip
            class="item"
            effect="light"
            :content="props.row.leads + '/' + props.row.click + '//' + props.row.leads_uu + '/' + props.row.click"
            placement="top"
            :open-delay="200"
          >
            <span>{{ getRatio(props.row.leads, props.row.click) + "/" + getRatio(props.row.leads_uu, props.row.click) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Sprz/Lreko" min-width="120" property="uu_reko_ratio" align="right">
        <template v-slot="header">
          <el-tooltip class="item" effect="light" content="Sprzedane/Lead reko//SprzedaneUU/Lead reko" placement="top" :open-delay="200">
            <span>Sprz/Lreko</span>
          </el-tooltip>
        </template>
        <template v-slot="props">
          <el-tooltip
            class="item"
            effect="light"
            :content="props.row.leads + '/' + props.row.reko_lead + '//' + props.row.leads_uu + '/' + props.row.reko_lead"
            placement="top"
            :open-delay="200"
          >
            <span>{{ getRatio(props.row.leads, props.row.reko_lead) + "/" + getRatio(props.row.leads_uu, props.row.reko_lead) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- Część finansowa -->
      <el-table-column v-if="showRoi" fixed="right" label="Przychód" min-width="110" property="income" align="right">
        <template v-slot="props">
          <span>{{ props.row.income | formatCurrency }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="showRoi" fixed="right" label="Koszt" min-width="100" property="cost" align="right">
        <template v-slot="props">
          {{ props.row.cost | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column v-if="showRoi" fixed="right" label="Zysk/Strata" min-width="120" property="revenue" align="right">
        <template v-slot="props">
          <b>{{ getRevenue(props.row.income, props.row.cost) | formatCurrency }}</b>
        </template>
      </el-table-column>
      <el-table-column v-if="showRoi" fixed="right" label="ROI Hot%" min-width="130" property="date_from">
        <template v-slot="props">
          <div class="progress" style="height: 15px">
            <div
              :class="
                getRoundPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) < 0
                  ? 'progress-bar bg-danger'
                  : 'progress-bar bg-success'
              "
              :style="'width: ' + getAbsolutPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + '%;'"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span class="percentageValue">{{ getRoundPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + "%" }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="showRoi" fixed="right" label="ROI%" min-width="130" property="date_from">
        <template v-slot="props">
          <div class="progress" style="height: 15px">
            <div
              :class="
                getRoundPercentage(getRevenue(props.row.income, props.row.cost), props.row.cost) < 0
                  ? 'progress-bar bg-danger'
                  : 'progress-bar bg-success'
              "
              :style="'width: ' + getAbsolutPercentage(getRevenue(props.row.income, props.row.cost), props.row.cost) + '%;'"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span class="percentageValue">{{ getRoundPercentage(getRevenue(props.row.income, props.row.cost), props.row.cost) + "%" }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
//import utils from "src/utils";

// event bus
//import { eventBus } from "src/main";

import { Tooltip } from "element-ui";

import BannerSalesModal from "src/components/Utils4/sales/views/BannerSalesModal.vue";

export default {
  components: {
    [Tooltip.name]: Tooltip,
  },
  props: ["tableData", "showRoi", "summary", "dateRange"],
  methods: {
    getRoundPercentage(a, b) {
      if (b === 0) {
        return 0;
      }
      return Math.round((a / b) * 100);
    },
    getAbsolutPercentage(a, b) {
      return Math.abs(this.getRoundPercentage(a, b));
    },
    getRevenue(a, b) {
      return a - b;
    },
    getRatio(numerator, denominator) {
      if (denominator > 0) {
        return Math.round((numerator / denominator) * 100) + "%";
      } else {
        return "-";
      }
    },
    getDetailedDate(date) {
      moment.locale("pl");
      const dateObj = Date.parse(date);
      return moment(dateObj).format("dddd, Do MMMM YYYY");
    },
    rowStyle({ row }) {
      if (row.banner_name === "Suma") {
        return "background-color: #f2fcff; font-weight: bold;";
      }
      return "";
    },
    cellClass({ column, row }) {
      if (row.banner_id === null && column.property === undefined) {
        return "mailing-efficiency__hide-expand";
      }
      return "";
    },
    headerStyle() {
      return "font-size: 12px;";
    },
    formatNumber(number, decimals = 0) {
      let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number).toFixed(decimals).split(".");
      n[0] = n[0]
        .split("")
        .reverse()
        .map((c, i, a) => (i > 0 && i < a.length && i % 3 === 0 ? c + thousand : c))
        .reverse()
        .join("");
      let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

      return final;
    },
    bannerClick(row) {
      if (!this.summary) {
        this.$modal.show(
          BannerSalesModal,
          {
            dateRange: this.dateRange,
            bannerID: row.banner_id,
            bannerName: row.banner_name,
            buttons: [
              {
                title: "Zamknij",
                handler: () => {
                  this.$modal.hide(BannerSalesModal);
                },
              },
            ],
          },
          {
            name: "BannerSalesModal",
            draggable: false,
            scrollable: true,
            width: "600px",
            height: "auto",
            maxHeight: 800,
            pivotX: 0.5,
            pivotY: 0.6,
            adaptive: true,
          },
        );
      }
    },
  },
};
</script>
<style scoped>
.percentageValue {
  color: #383838;
  font-weight: bold;
}

.verticalTableHeader {
  font-weight: bold;
  width: 200px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableRow {
  margin: 0;
}
</style>

<style>
.mailing-efficiency__hide-expand :first-child {
  visibility: hidden !important;
}
</style>
