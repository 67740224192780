import storeGen from '../../../store/storeGenerator'

import {
    AUTH_LOGOUT
} from '../../../store/actions/auth'

import {
    BASE_API
} from '../../config'

import { 
    GET_USERS_FROM_COMPLAINTS,
    LOAD_USERS_FROM_COMPLAINTS,
    SAVE_USERS_FROM_COMPLAINTS,
    SUCCESS_USERS_FROM_COMPLAINTS,
    ERROR_USERS_FROM_COMPLAINTS,
    INVALID_USERS_FROM_COMPLAINTS,
    SAVE_FROM_COMPLAINTS,
    SAVING_USERS_FROM_COMPLAINTS,
    SAVING_SUCCESS_USERS_FROM_COMPLAINTS,
    SAVING_ERROR_USERS_FROM_COMPLAINTS,
    } from '../../actions/sales/user_from_complaints';

const storeObjectConfig = {
    objectName: "UserFromComplaints",
    getterName: GET_USERS_FROM_COMPLAINTS,
    loadActionName: LOAD_USERS_FROM_COMPLAINTS,
    loadingMutationName: SAVE_USERS_FROM_COMPLAINTS,
    loadingSuccessMutationName: SUCCESS_USERS_FROM_COMPLAINTS,
    loadingErrorMutationName: ERROR_USERS_FROM_COMPLAINTS,
    invalidMutationName: INVALID_USERS_FROM_COMPLAINTS,
    saveActionName: SAVE_FROM_COMPLAINTS,
    savingMutationName: SAVING_USERS_FROM_COMPLAINTS,
    savingSuccessMutationName: SAVING_SUCCESS_USERS_FROM_COMPLAINTS,
    savingErrorMutationName: SAVING_ERROR_USERS_FROM_COMPLAINTS,
    logoutMutationName: AUTH_LOGOUT,
    apiUrl: BASE_API,
    validPeriod: 10, // minutes
    recordType: 'json',
    loadParams: {
        url: "/sales/userFromComplaints",
        method: "post" // {'get','post','put'}
    },
    saveParams: {
        url: "/sales/userFromComplaints",
        method: "post" // {'get','post','put'}
    }
}

const state = storeGen.stateGenerator(storeObjectConfig);// stan stora
const getters = storeGen.gettersGenerator(storeObjectConfig);//pobiera dane ze stora
const actions = storeGen.actionsGenerator(storeObjectConfig);//poberanie dany do stora async
const mutations = storeGen.mutationsGenerator(storeObjectConfig);//zmieniają stan stora  

export default {
    state,
    getters,
    actions,
    mutations
}