<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Raport efektywności mailing</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="-"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd"
          :default-value="defaultDate"
        ></el-date-picker>
      </div>
    </div>

    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <label for="project">Projekt</label>
            <el-select
              id="mailingEfficiencyProjectSelect"
              v-on:visible-change="getUpperLeftSelectWidth()"
              v-model="projectsArray"
              name="project"
              data-vv-as="Projekt"
              style="width: 100%"
              multiple
              filterable
              clearable
            >
              <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                <el-option
                  :style="upperLeftSelectWidth !== null ? 'width: ' + upperLeftSelectWidth + 'px' : 'width: 300px'"
                  :id="'mailingEfficiencyProjectSelectOption_' + item.project_id"
                  v-for="item in group.options"
                  :key="item.project_id"
                  :label="item.project_name + ' [' + item.project_id + ']'"
                  :value="item.project_id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </div>
          <div class="col-sm-1">
            <label for="mailingOwner">Właściciel</label>
            <el-checkbox-group name="mailingOwner" v-model="mailingOwner">
              <el-checkbox-button v-for="owner in mailingOwners" :label="owner" :key="owner">{{ owner }}</el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div class="col-sm-5">
            <label for="mailingTypeGroup">Rodzaj wysyłki</label>
            <el-checkbox-group name="mailingTypeGroup" v-model="mailingType">
              <el-checkbox-button v-for="type in mailingTypes" :label="type" :key="type">{{ type }}</el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div class="col-sm-3 right-center">
            <el-switch
              class="leftSwitch"
              name="showAll"
              v-model="showAll"
              active-text="Dni"
              active-color="#fd7e14"
              inactive-text="Projekty"
              inactive-color="#17a2b8"
            ></el-switch>
            <el-switch name="showROI" v-model="showROI" active-text="ROI" active-color="#28a745" inactive-color="#c4c4c4"></el-switch>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <label>Filtr banerów</label>
            <fg-input
              class="MailingEfficiency__bannerFilter"
              placeholder="Filtruj po id / nazwie"
              name="bannerFilter"
              data-vv-as="Filtr banerów"
              v-model="bannerFilter"
              v-validate="bannerFilterValidation"
              :error="getError('bannerFilter')"
            >
            </fg-input>
          </div>
          <div class="col-sm-3">
            <label for="mailingList">Lista mailingowa</label>
            <el-select
              id="mailingList"
              v-model="mailingLists"
              name="mailingList"
              style="width: 100%"
              multiple
              filterable
              clearable
            >
              <el-option v-for="list in mailingsListDict" :key="list.id" :value="list.id" :label="list.name+' ['+list.id+']'">


              </el-option>
            </el-select>

          </div>
          <div class="col"></div>
          <div class="col-sm-3 right-bottom">
            <p-button type="success" @click="loadData('show')">Pokaż</p-button>
            <p-button type="success" @click="loadData('excel')">Excel</p-button>
          </div>
        </div>
      </div>
      <hr />
      <h6>
        <div class="card-footer" style="display: flex; justify-content: space-between">
          <span>Przedział dat:</span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </div>
      </h6>
    </div>

    <div class="card" v-if="Object.values(efficiencyRaport).length > 0">
      <div class="card-header">
        <h5>Podsumowanie</h5>
      </div>
      <div class="card-body">
        <appMailingEfficiencyTable
          v-if="Object.values(efficiencyRaport).length > 0"
          :table-data="Object.values(efficiencyRaport.summary)"
          :show-roi="showROI"
          :summary="true"
        ></appMailingEfficiencyTable>
      </div>
    </div>

    <div v-if="!showAll">
      <div v-for="raport in efficiencyRaport.projects" :key="raport.project_id">
        <div class="card">
          <div class="card-header">
            <h5>{{ raport.project_name + " [" + raport.project_id + "]" }}</h5>
          </div>
          <appMailingEfficiencyTable
            :table-data="raport.banners"
            :show-roi="showROI"
            :summary="false"
            :date-range="dateRange"
          ></appMailingEfficiencyTable>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="day in efficiencyRaport.date" :key="day.project_id">
        <div class="card">
          <div class="card-header">
            <h5>{{ day.send_date }}</h5>
          </div>
          <appMailingEfficiencyTable
            :table-data="day.banners"
            :show-roi="showROI"
            :summary="false"
            :date-range="dateRange"
          ></appMailingEfficiencyTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";

import { DatePicker, Select, Option, OptionGroup, Switch, CheckboxGroup, CheckboxButton } from "element-ui";

import { LOAD_MAILING_EFFICIENCY, MAILING_EFFICIENCY_INVALID } from "src/store/actions/secure/mailing_efficiency";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";

import MailingEfficiencyTable from "src/components/Utils4/sales/views/MailingEfficiencyTable.vue";
import {
  LOAD_MAILING_EFFICIENCY_EXCEL,
  MAILING_EFFICIENCY_EXCEL_INVALID
} from "@/store/actions/secure/mailing_efficiency_excel";
import {MAILER_LISTS_GETTER_NAME, MAILER_LISTS_INVALID, MAILER_LISTS_LOAD} from "@/store/actions/mailings/mailer_list";


export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Switch.name]: Switch,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
    appMailingEfficiencyTable: MailingEfficiencyTable,
  },
  data() {
    return {
      loading: false,
      showROI: true,
      showAll: false,
      mailingOwner: ["MM"],
      mailingOwners: ["MM"],
      mailingTypes: ["Zewnętrzne", "Wewnętrzne"],
      mailingType: ["Zewnętrzne", "Wewnętrzne"],
      projectsArray: [],
      mailingLists:[],
      bannerFilter: "",
      dateRange: ["", ""],
      upperLeftSelectWidth: null,
      bannerFilterValidation: {
        regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ._]+)$",
        max: 32,
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    async initLoad(force) {
      this.loading = true;

      if (force) {
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(MAILER_LISTS_INVALID);
      }

      await this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(MAILER_LISTS_LOAD, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    async loadData(mode) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        mailing_type: this.mailingType,
        project_list: this.projectsArray,
        mailing_owner: this.mailingOwner,
        banner_filter: this.bannerFilter,
        mailing_lists: this.mailingLists
      };

      this.$store.commit(mode === 'excel'? MAILING_EFFICIENCY_EXCEL_INVALID : MAILING_EFFICIENCY_INVALID);

      await this.$store.dispatch(mode === 'excel'?LOAD_MAILING_EFFICIENCY_EXCEL:LOAD_MAILING_EFFICIENCY, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      if (mode === "excel") {
        const tmpName = this.$store.getters.getMailingEfficiencyExcel.tmpname;
        const fileName = this.$store.getters.getMailingEfficiencyExcel.name;

        //console.log(tmpName,fileName);
        this.downloadFile(tmpName, fileName);
      }
      this.loading = false;
    },
    listChanged(){
      console.log(this.mailingLists);
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-contract.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getUpperLeftSelectWidth() {
      this.upperLeftSelectWidth = document.getElementById("mailingEfficiencyProjectSelect").offsetWidth;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
  },
  computed: {
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    efficiencyRaport() {
      let data = {};
      if (this.$store.state.mailing_efficiency.loading_status === "success") {
        data = JSON.parse(JSON.stringify(this.$store.getters.getMailingEfficiency));
        const projects = data.projects;
        const days = data.date;

        if (!days) return {};

        for (let project in projects) {
          projects[project].banners = Object.values(projects[project].banners);
          let summary = projects[project].summary;
          summary.banner_name = "Suma";
          summary.banner_id = null;
          projects[project].banners.push(summary);
        }
        for (let day in days) {
          days[day].banners = Object.values(days[day].banners);
          let summary = days[day].summary;
          summary.banner_name = "Suma";
          summary.banner_id = null;
          days[day].banners.push(summary);
        }

        let daysArray = Object.values(days);
        daysArray.sort((a, b) => (a.send_date > b.send_date ? 1 : -1));

        let projectsArray = Object.values(projects);
        projectsArray.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

        data.date = daysArray;
        data.projects = projectsArray;
      }
      return data;
    },
    mailingsListDict(){
      let mailingsListDict = [];
      if (this.$store.state.mailer_lists.loading_status === "success") {

        mailingsListDict = this.$store.getters[MAILER_LISTS_GETTER_NAME];

      }
      return mailingsListDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
  },
  mounted() {},
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
      this.initLoad(true);
    });
    this.initLoad(false);
  },
  beforeDestroy: function () {
    this.$store.commit(MAILING_EFFICIENCY_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.right-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.leftSwitch {
  margin-right: 20px;
}

.leftSwitch::after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid rgb(179, 177, 177);
  top: 0;
  left: 152px;
}
</style>

<style>
.mailing-efficiency__hide-expand :first-child {
  visibility: hidden !important;
}
</style>
