export const GET_KO_QUESTIONS_OBJECT = 'KO_QUESTIONS'
export const GET_KO_QUESTIONS = 'GET_KO_QUESTIONS'
export const LOAD_KO_QUESTIONS = 'LOAD_KO_QUESTIONS'
export const LOADING_KO_QUESTIONS = 'LOADING_KO_QUESTIONS'
export const INVALID_KO_QUESTIONS = 'INVALID_KO_QUESTIONS'
export const SAVE_KO_QUESTIONS = 'SAVE_KO_QUESTIONS'
export const SAVING_KO_QUESTIONS = 'SAVING_KO_QUESTIONS'
export const SAVING_SUCCESS_KO_QUESTIONS = 'SAVING_SUCCESS_KO_QUESTIONS'
export const SAVING_ERROR_KO_QUESTIONS = 'SAVING_ERROR_KO_QUESTIONS'
export const SUCCESS_KO_QUESTIONS = 'SUCCESS_KO_QUESTIONS'
export const ERROR_KO_QUESTIONS = 'ERROR_KO_QUESTIONS'
