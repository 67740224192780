import { render, staticRenderFns } from "./EfficiencyStats.vue?vue&type=template&id=28ff4f26&scoped=true&"
import script from "./EfficiencyStats.vue?vue&type=script&lang=js&"
export * from "./EfficiencyStats.vue?vue&type=script&lang=js&"
import style0 from "./EfficiencyStats.vue?vue&type=style&index=0&id=28ff4f26&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ff4f26",
  null
  
)

export default component.exports