<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="title">Statystyki leadów</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          align="center"
          unlink-panels
          range-separator="-"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd HH:mm"
        ></el-date-picker>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <label>Projekt</label>
            <fg-input>
              <el-select
                v-on:clear="clearVersions()"
                v-on:remove-tag="removeVersion($event)"
                v-model="filter.projects"
                name="projects"
                filterable
                clearable
                multiple
              >
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Wersja</label>
            <fg-input>
              <el-select v-model="filter.versions" name="versions" filterable clearable multiple>
                <el-option-group v-for="group in projectModules" :key="group.label" :label="group.label">
                  <el-option
                    v-for="version in group.options"
                    :label="version.module + ' [' + version.tags + ']'"
                    :value="version.site_id"
                    :key="version.module"
                  ></el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Wydawca</label>
            <fg-input>
              <el-select v-model="filter.media_house" name="media_house" filterable clearable multiple>
                <el-option v-for="item in mediaHouse" :label="'[' + item.ID + '] ' + item.Name" :value="item.ID" :key="item.ID"></el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="card-footer text-center">
          <p-button type="success" @click.prevent="loadStats(true)">Wczytaj</p-button>
        </div>
      </div>
    </div>

    <div class="card" v-if="Object.values(leadsRaportSum).length > 0">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-12 col-md-8">
            <h5>Podsumowanie</h5>
          </div>
          <div class="col-sm-12 col-md-4 text-md-right">
            <el-select v-model="leadType" size="small" filterable placeholder="Typ leada">
              <el-option v-for="type in leadTypes" :key="type" :label="type" :value="type"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="card-body">
        <el-table :data="Object.values(leadsRaportSum.Projects)" :cell-style="colStyle" style="width: 100%" row-key="id">
          <el-table-column fixed label="Projekt" min-width="300" property="ProjectID">
            <template v-slot="props">
              <span v-if="props.row.ProjectID">{{ "[" + props.row.ProjectID + "] " + props.row.Project }}</span>
              <span v-else>{{ props.row.Project }}</span>
            </template>
          </el-table-column>
          <el-table-column v-for="date in dateArray" :key="date" min-width="120" align="center" row-key="id">
            <template slot="header">
              {{ showArrayObj[date] }}
            </template>
            <template v-slot="props">
              <span>{{
                showArrayObj[date] === "Wszystkie" ? formatNumber(props.row.Wszystkie[leadType]) : formatNumber(props.row.Days[date][leadType])
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div v-for="raport in leadsRaport" :key="raport.ProjectID">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-12 col-md-8">
              <h5>
                {{ "[" + raport.ProjectID + "] " + raport.Project }}
              </h5>
            </div>
            <div class="col-sm-12 col-md-4 text-md-right">
              <el-select v-model="leadType" size="small" filterable placeholder="Typ leada">
                <el-option v-for="type in leadTypes" :key="type" :label="type" :value="type"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="card-body">
          <el-table :data="Object.values(raport.Modules)" :cell-style="colStyle" style="width: 100%" row-key="id">
            <el-table-column fixed label="Wersja" min-width="300" property="Module"> </el-table-column>
            <el-table-column v-for="date in dateArray" :key="date" min-width="120" align="center" row-key="id">
              <template slot="header">
                {{ showArrayObj[date] }}
              </template>
              <template v-slot="props">
                <span>{{
                  showArrayObj[date] === "Wszystkie" ? formatNumber(props.row.Wszystkie[leadType]) : formatNumber(props.row.Days[date][leadType])
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

import { Table, TableColumn, DatePicker, Select, Option, OptionGroup, Collapse, CollapseItem, Tooltip } from "element-ui";

// vuex actions
import { LOAD_LEAD_STATS, LEAD_STATS_INVALID } from "src/store/actions/stats/lead_stats";
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_MEDIA_HOUSE } from "src/store/actions/stats/media_house";

// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [CollapseItem.name]: CollapseItem,
    [Collapse.name]: Collapse,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      loading: false,
      filter: {
        projects: [],
        versions: [],
        media_house: [],
      },
      leadType: "Lead",
      leadTypes: ["Lead", "LeadF1", "LeadHot", "FullLead", "NewLead", "NewLeadF1", "NewFullLead", "NewPerson","Leadreko"],
      dateRange: [moment(utils.today()).format("YYYY-MM-DD 00:00:00"), moment(utils.today()).format("YYYY-MM-DD 23:59:59")],
      dateArray: [],
      versionProjectObject: {},
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    lead_stats() {
      return this.$store.getters.getLeadStats;
    },
    leadsRaport() {
      let data = {};
      if (this.$store.state.lead_stats.loading_status === "success") {
        data = JSON.parse(JSON.stringify(this.$store.getters.getLeadStats));
        delete data.Wszystkie;
        for (const project in data) {
          for (const module in data[project].Modules) {
            const moduleObj = data[project].Modules[module];
            if (moduleObj.children) {
              moduleObj.children = Object.values(moduleObj.children);
            }
            this.dateArray.map(x => {
              if (!Object.keys(moduleObj.Days).includes(x)) {
                moduleObj.Days[x] = {
                  AddTime: x,
                  Lead: 0,
                  LeadF1: 0,
                  LeadHot: 0,
                  FullLead: 0,
                  NewLead: 0,
                  NewLeadF1: 0,
                  NewFullLead: 0,
                  NewPerson: 0,
                };
              }
            });
            for (let i in moduleObj.children) {
              const childrenObj = data[project].Modules[module].children[i];
              this.dateArray.map(x => {
                if (!Object.keys(childrenObj.Days).includes(x)) {
                  childrenObj.Days[x] = {
                    AddTime: x,
                    Lead: 0,
                    LeadF1: 0,
                    LeadHot: 0,
                    FullLead: 0,
                    NewLead: 0,
                    NewLeadF1: 0,
                    NewFullLead: 0,
                    NewPerson: 0,
                  };
                }
              });
            }
          }
          const projectArray = [];
          let tempModule = data[project].Modules;
          let projectSummary = tempModule.Wszystkie;
          delete tempModule.Wszystkie;
          let tempArray = Object.values(tempModule);
          tempArray.sort((a, b) => (a.Module.toLowerCase() > b.Module.toLowerCase() ? 1 : -1));
          tempArray.map(x => projectArray.push(x));
          tempArray.push(projectSummary);
          data[project].Modules = tempArray;
        }
      }
      return data;
    },
    leadsRaportSum() {
      let data = {};
      if (this.$store.state.lead_stats.loading_status === "success") {
        data = JSON.parse(JSON.stringify(this.$store.getters.getLeadStats)).Wszystkie;
        for (const project in data.Projects) {
          if (data.Projects[project].children) {
            data.Projects[project].children = Object.values(data.Projects[project].children);
          }
          this.dateArray.map(x => {
            if (!Object.keys(data.Projects[project].Days).includes(x)) {
              data.Projects[project].Days[x] = {
                AddTime: x,
                Lead: 0,
                LeadF1: 0,
                LeadHot: 0,
                FullLead: 0,
                NewLead: 0,
                NewLeadF1: 0,
                NewFullLead: 0,
                NewPerson: 0,
              };
            }
          });
          for (let i in data.Projects[project].children) {
            const childrenObj = data.Projects[project].children[i];
            this.dateArray.map(x => {
              if (!Object.keys(childrenObj.Days).includes(x)) {
                childrenObj.Days[x] = {
                  AddTime: x,
                  Lead: 0,
                  LeadF1: 0,
                  LeadHot: 0,
                  FullLead: 0,
                  NewLead: 0,
                  NewLeadF1: 0,
                  NewFullLead: 0,
                  NewPerson: 0,
                };
              }
            });
          }
        }
        const projectArray = [];
        let tempProject = data.Projects;
        let projectSummary = tempProject.Wszystkie;
        delete tempProject.Wszystkie;
        let tempArray = Object.values(tempProject);
        tempArray.sort((a, b) => (a.Project.toLowerCase() > b.Project.toLowerCase() ? 1 : -1));
        tempArray.map(x => projectArray.push(x));
        tempArray.push(projectSummary);
        data.Projects = tempArray;
      }
      return data;
    },
    projects() {
      return this.$store.getters.getProjectDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map(x => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    projectModules() {
      // wszystkie moduły projektu
      if (Array.isArray(this.filter.projects) && this.filter.projects.length > 0) {
        let projects = this.$store.getters.getProjectsAll;
        let activeProjects = this.filter.projects;
        let filteredData = {};
        let temp = {};
        const sortedData = {};
        for (let i in activeProjects) {
          for (let [key, project] of Object.entries(projects)) {
            // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
            if (project.project_id === activeProjects[i]) {
              temp[project.module] = Object.assign({}, project);
              filteredData[project.project_id] = Object.assign({}, temp);
            }
          }
          temp = {};
        }

        let sourceData = [];

        for (let i in filteredData) {
          // sortowanie danych
          let tempData = filteredData[i];
          let sourceData = {};
          for (let ver of Object.keys(tempData)) {
            // iteracja po nazwach modułów danego projektu
            let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie pierwszej liczby
            filteredData[i][ver].number = res;
          }
        }

        let resultModules = [];

        for (let project_id in filteredData) {
          let projectModules = filteredData[project_id];
          sourceData = Object.values(projectModules);
          sourceData.sort((a, b) => a.number - b.number);
          let result = {};
          for (let i in sourceData) {
            this.versionProjectObject[sourceData[i].module] = parseInt(project_id);
            result[sourceData[i].module] = sourceData[i];
          }
          resultModules.push({
            project_id,
            project_id,
            label: this.projects[project_id].project_name,
            options: result,
          });
        }

        return resultModules;
      }
      return [];
    },
    mediaHouse() {
      let data = Object.values(this.$store.getters.getMediaHouse);
      data.sort((a, b) => b.ID - a.ID);
      return data;
    },
    showArrayObj() {
      const dateArray = this.dateArray;
      const objArray = {};
      dateArray.map(x => {
        if (x !== "Wszystkie") {
          objArray[x] = moment(x).format("MM-DD");
        } else {
          objArray[x] = x;
        }
      });
      return objArray;
    },
  },
  methods: {
    async loadData(force) {
      this.loading = true;

      if (force) {
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(PROJECTS_ALL_INVALID);
      }

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_MEDIA_HOUSE, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    async loadStats(force) {
      if (this.dateRange === null) {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      this.createDateArray();
      if (force) {
        this.$store.commit(LEAD_STATS_INVALID);
      }

      if (force && this.dateRange !== null) {
        let date = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
        };

        const filters = Object.assign({}, date, this.filter);

        await this.$store.dispatch(LOAD_LEAD_STATS, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        this.loading = false;
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    clearVersions() {
      this.filter.versions = [];
    },
    removeVersion(project_id) {
      let versions = this.filter.versions;
      let filteredVersions = [];
      for (let i in versions) {
        if (this.versionProjectObject[versions[i]] !== project_id) {
          filteredVersions.push(versions[i]);
        }
      }
      this.filter.versions = filteredVersions;
    },
    colStyle({ row, columnIndex }) {
      const columnID = this.dateArray.length;
      if (row.Client === "Wszystkie" || columnIndex === columnID) {
        return "background-color: #f2fcff; font-weight: bold;";
      }
    },
    formatNumber(number, decimals = 0) {
      if (number === null) {
        return 0;
      }
      let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number)
        .toFixed(decimals)
        .split(".");
      n[0] = n[0]
        .split("")
        .reverse()
        .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
        .reverse()
        .join("");
      let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

      return final;
    },
    createDateArray() {
      const firstDay = new Date(this.dateRange[0]);
      const lastDay = new Date(this.dateRange[1]);
      let nextDay = new Date(moment(firstDay, "YYYY-MM-DD").add(1, "days"));
      const dateArr = [];
      dateArr.push(moment(firstDay).format("YYYY-MM-DD"));
      while (nextDay.getTime() <= lastDay.getTime()) {
        dateArr.push(moment(nextDay).format("YYYY-MM-DD"));
        nextDay = new Date(moment(nextDay, "YYYY-MM-DD").add(1, "days"));
      }
      dateArr.push("Wszystkie");
      this.dateArray = dateArr;
    },
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadData(true);
      this.loadStats(true);
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped></style>
