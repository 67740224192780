<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="title">Konwersja</h5>
      </div>
      <div class="col-md-6 ml-auto">
        <el-menu default-active="simple" class="el-menu-demo" background-color="#F4F3EF" mode="horizontal"
                 @select="menuSelect">
          <el-menu-item index="simple">Uproszczony</el-menu-item>
          <el-menu-item index="detailed">Szczegółowy</el-menu-item>
          <el-menu-item index="table">Tabelaryczny</el-menu-item>
          <el-menu-item index="steps">Step Counter</el-menu-item>
        </el-menu>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 text-sm-left col-md-4">
            <label>Data</label>
            <fg-input>
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                align="center"
                unlink-panels
                range-separator="-"
                start-placeholder="Data początkowa"
                end-placeholder="Data końcowa"
                :picker-options="pickerOptions"
                :clearable="false"
                format="yyyy-MM-dd"
              ></el-date-picker>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Projekt</label>
            <fg-input>
              <el-select
                v-on:clear="clearVersions()"
                v-on:remove-tag="removeVersion($event)"
                v-model="filter.project"
                name="projects"
                filterable
                clearable
              >
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Wersja</label>
            <fg-input>
              <el-select v-model="filter.versions" name="versions" filterable clearable multiple>
                <el-option-group v-for="group in projectModules" :key="group.label" :label="group.label">
                  <el-option
                    v-for="version in group.options"
                    :label="version.module + ' [' + version.version + ']'"
                    :value="version.module"
                    :key="version.module"
                  ></el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Banner</label>
            <fg-input>
              <el-select
                v-model="filter.banners"
                name="banners"
                filterable
                remote
                reserve-keyword
                clearable
                multiple
                :remote-method="remoteSearch"
                :loading="loading"
              >
                <el-option
                  v-for="banner in bannerConfig"
                  :label="'[' + banner.BannerID + '] ' + banner.BannerName"
                  :value="banner.BannerID"
                  :key="banner.BannerID"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Wydawca</label>
            <fg-input>
              <el-select v-model="filter.media_house" name="media_house" filterable clearable multiple>
                <el-option v-for="item in mediaHouse" :label="'[' + item.ID + '] ' + item.Name" :value="item.ID"
                           :key="item.ID"></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-sm-4 filter-button">
            <el-tooltip
              class="item"
              effect="light"
              content="Tylko aktywne banery utworzone w zadanym przedziale czasowym"
              placement="top"
              :open-delay="500"
            >
              <el-checkbox v-model="filter.active_banners">
                Tylko aktywne banery
              </el-checkbox>
            </el-tooltip>
            <el-button type="success" @click="loadData">
              Wczytaj
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedMenu === 'detailed'">
      <div v-for="(ver, index) in conversionData.main" :key="'ver_' + index">
        <div class="card bg-light border border-secondary conv-card">
          <div class="corner">
            <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
              <div slot="content">
                <b style="color: #1b69bd;">Kolor niebieski</b> - wartości liczone jako unikalne dla stepu, procenty
                konwersji liczone względem wejść
                na pierwszy step wersji serwisu.
              </div>
              <div slot="content">
                <b>Kolor czarny</b> - wartości liczone jako unikalne dla przejść pomiędzy dwoma stepami, procenty
                konwersji względem wejść na
                poprzedni step w sekwencji.
              </div>
              <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
            </el-tooltip>
          </div>
          <div class="card-header">
            <h6 class="card-title">
              {{ `${index} [${modules[index].version}]` }}
            </h6>
          </div>
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-4 main-counter" v-if="Object.values(ver)[0].entrance">
              <div>Wejścia</div>
              <div class="unique-color">
                <b>{{ Object.values(ver)[0].entrance.count | formatNumber }}</b>
              </div>
              <div class="conv-arrow"></div>
            </div>
            <div class="col-sm-4"></div>
          </div>
          <div v-for="(row, index) in ver" :key="'conv_step_' + index" class="row">
            <div class="col-sm-4">
              <app-conversion-outer v-if="row.inputs" :row_data="row.inputs"
                                    :direction="'right'"></app-conversion-outer>
            </div>
            <div class="col-sm-4">
              <app-conversion-inner
                v-if="row.entrance || row.step_udid"
                :name="row.name"
                :details="details"
                :description="row.description"
                :entrance="row.entrance"
                :step_udid="row.step_udid"
                :next="row.next"
                :input_percentage="row.input_percentage"
                :summary="row.summary"
              ></app-conversion-inner>
            </div>
            <div class="col-sm-4">
              <app-conversion-outer
                v-if="row.outputs || row.exit"
                :row_data="row.outputs"
                :exit="row.exit"
                :exit_percentage="row.exit_percentage"
                :direction="'left'"
              ></app-conversion-outer>
            </div>
          </div>
          <div class="row" v-if="conversionData.other && conversionData.other[index]">
            <div class="col-sm-4"></div>
            <div class="col-sm-4">
              <appConversionTableComponent :tableData="conversionData.other[index]"/>
            </div>
            <div class="col-sm-4"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="conv_column_wrapper" v-else-if="selectedMenu === 'simple'">
      <div class="conv_column" v-for="(ver, index_ver) in conversionData.main" :key="'ver_' + index_ver">
        <div class="card bg-light border border-secondary conv-card">
          <div class="card-header">
            <h6 class="card-title">
              {{
                `${index_ver} [${modules[index_ver].tags ? modules[index_ver].tags : modules[index_ver].version.toUpperCase()}]`
              }}
            </h6>
          </div>
          <div v-for="(row, index) in ver" :key="'conv_step_' + index" class="row">
            <div class="col-sm-12">
              <app-conversion-inner
                v-if="row.step_udid"
                :name="row.name"
                :details="details"
                :description="row.description"
                :entrance="row.entrance"
                :step_udid="row.step_udid"
                :next="row.next"
                :input_percentage="row.input_percentage"
                :summary="row.summary"
              ></app-conversion-inner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="selectedMenu === 'table'">
      <div class="table-container">
        <app-conversion-table
          v-for="(ver, mod) in conversionData.main"
          :key="mod"
          :main="ver"
          :other="conversionData.other ? conversionData.other[mod] : null"
          :name="mod"
        />
      </div>
    </div>
    <div v-else-if="selectedMenu === 'steps'">
      <div class="row">
        <div class="col-12">
          <el-table :data="stepsData">
            <el-table-column label="Step">
              <template v-slot="props">
                {{ props.row.stepDescryption }} ({{ props.row.stepName }})
              </template>
            </el-table-column>
            <el-table-column prop="count" label="Suma"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

import {Button, DatePicker, Select, Option, OptionGroup, Checkbox, Tooltip, Switch, Menu, MenuItem} from "element-ui";

import {LOAD_CONVERSION_STATS, CONVERSION_STATS_INVALID} from "src/store/actions/stats/conversion_stats";
import {LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID} from "src/store/actions/questions/projects_all";
import {LOAD_PROJECT_DICT, PROJECT_DICT_INVALID} from "src/store/actions/questions/project_dict";
import {LOAD_MEDIA_HOUSE} from "src/store/actions/stats/media_house";
import {LOAD_BANNER_CONFIG, BANNER_CONFIG_INVALID} from "src/store/actions/stats/banner_config";

import ConversionOuter from "src/components/Utils4/stats/conversion/ConversionOuter.vue";
import ConversionInner from "src/components/Utils4/stats/conversion/ConversionInner.vue";
import ConversionTable from "src/components/Utils4/stats/conversion/ConversionTable.vue";
import ConversionTableComponent from "src/components/Utils4/stats/conversion/ConversionTableComponent.vue";

// event bus
import {eventBus} from "src/main";
import utils from "src/utils";

export default {
  components: {
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Checkbox.name]: Checkbox,
    [Tooltip.name]: Tooltip,
    [Switch.name]: Switch,
    [Menu.name]: Menu,
    [MenuItem.name]: MenuItem,
    appConversionOuter: ConversionOuter,
    appConversionInner: ConversionInner,
    appConversionTable: ConversionTable,
    appConversionTableComponent: ConversionTableComponent,
  },
  data() {
    return {
      loading: false,
      selectedMenu: "simple",
      dateRange: null,
      details: true,
      filter: {
        project: "",
        versions: [],
        banners: [],
        media_house: [],
        active_banners: false,
      },
      versionProjectObject: {},
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    stepsData() {
      let steps = [], step = null;
      for (let version in this.conversionData.main) {

        for (let sd in this.conversionData.main[version]) {
          if (this.conversionData.main[version][sd].hasOwnProperty('name')) {
            step = this.conversionData.main[version][sd].name;

            const searchIndex = steps.findIndex((stepData) => stepData.stepName === step);
            if (searchIndex < 0) {
              steps.push({
                'stepName': step,
                'stepDescryption': this.conversionData.main[version][sd].description,
                'count': this.conversionData.main[version][sd].step_udid.display_page_unique_udid
              });
            } else {
              steps[searchIndex].count += this.conversionData.main[version][sd].step_udid.display_page_unique_udid;
            }
          }
        }
      }
      return steps;
    },

    conversionData() {
      if (this.$store.state.conversion_stats.loading_status === "success") {
        return JSON.parse(JSON.stringify(this.$store.getters.getConversionStats));
      } else {
        return [];
      }
    },
    projects() {
      return this.$store.getters.getProjectDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map(x => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    modules() {
      const data = this.$store.getters.getProjectsAll;
      let result = {};
      data.forEach(x => {
        result[x.module] = x;
      });

      return result;
    },
    projectModules() {
      // wszystkie moduły projektu
      if (this.filter.project !== "") {
        let projects = this.$store.getters.getProjectsAll;
        let filteredData = {};
        let temp = {};
        const sortedData = {};
        for (let [key, project] of Object.entries(projects)) {
          // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
          if (project.project_id === this.filter.project) {
            temp[project.module] = Object.assign({}, project);
            filteredData[project.project_id] = Object.assign({}, temp);
          }
        }
        temp = {};

        let sourceData = [];

        for (let i in filteredData) {
          // sortowanie danych
          let tempData = filteredData[i];
          let sourceData = {};
          for (let ver of Object.keys(tempData)) {
            // iteracja po nazwach modułów danego projektu
            let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie pierwszej liczby
            filteredData[i][ver].number = res;
          }
        }

        let resultModules = [];

        for (let project_id in filteredData) {
          let projectModules = filteredData[project_id];
          sourceData = Object.values(projectModules);
          sourceData.sort((a, b) => a.number - b.number);
          let result = {};
          for (let i in sourceData) {
            this.versionProjectObject[sourceData[i].module] = parseInt(project_id);
            result[sourceData[i].module] = sourceData[i];
          }
          resultModules.push({
            project_id,
            project_id,
            label: this.projects[project_id].project_name,
            options: result,
          });
        }

        return resultModules;
      }
      return [];
    },
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
    mediaHouse() {
      let data = Object.values(this.$store.getters.getMediaHouse);
      data.sort((a, b) => b.ID - a.ID);
      return data;
    },
  },
  methods: {
    async loadDicts() {
      this.loading = true;

      this.$store.commit(PROJECT_DICT_INVALID);
      this.$store.commit(PROJECTS_ALL_INVALID);

      this.$store.dispatch(LOAD_MEDIA_HOUSE, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    async loadData(force) {
      if (!this.dateRange) {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        return;
      }

      if (this.filter.project === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać projekt!",
          type: "warning",
        });
        return;
      }

      let date = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
      };

      let filters = {
        ...this.filter,
        ...date,
      };

      this.$store.commit(CONVERSION_STATS_INVALID);

      this.loading = true;

      await this.$store.dispatch(LOAD_CONVERSION_STATS, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, {searchQuery}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    clearVersions() {
      this.filter.versions = [];
    },
    removeVersion(project_id) {
      let versions = this.filter.versions;
      let filteredVersions = [];
      for (let i in versions) {
        if (this.versionProjectObject[versions[i]] !== project_id) {
          filteredVersions.push(versions[i]);
        }
      }
      this.filter.versions = filteredVersions;
    },
    menuSelect(index, indexPath) {
      this.selectedMenu = index;
    },
    formatNumber(num) {
      return utils.formatNumber(num);
    },
  },
  mounted() {
  },
  created() {
    this.loadDicts();
    eventBus.$on("forceReload", () => {
      this.loadDicts();
      this.loadData(true);
    });
  },
  beforeDestroy: function () {
    this.$store.commit(CONVERSION_STATS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped>
.filter-button {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 10px;
}

.conv_column_wrapper {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}

.conv_column {
  min-width: 420px;
  width: 420px;
  padding: 15px;
}

.conv-card {
  margin-bottom: 50px;
  padding: 10px;
}

.conv-arrow {
  margin-left: 8px;
  width: 0;
  height: 0;
  border-top: 12px solid #9ce08f;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}

.main-counter {
  font-size: 1.3em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unique-color {
  color: #1b69bd;
}

.corner {
  position: absolute;
  top: 15px;
  right: 15px;
}

.el-menu {
  float: right;
  margin-right: 10px;
}

.table-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
