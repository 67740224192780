import NProgress from 'nprogress';

const progressShowDelay = 100;
let routeResolved = false;
/**
 * Initializes NProgress after a specified delay only if the route was not resolved yet.
 */
function tryInitProgress() {
  routeResolved = false;
  setTimeout(()=> {
    if (!routeResolved) {
      NProgress.start();
    }
  }, progressShowDelay);
}
export default function initProgress(router) {
  router.beforeEach((to, from, next) => {

    if (process.env.NODE_ENV !== 'production') {
      console.log(`Routing: ${from.name} (${from.path}) => ${to.name} (${to.path})`)
    }

    tryInitProgress();
    return next();
  });

  router.afterEach((to, from) => {
    // console.log(`After: ${from.name} (${from.path}) => ${to.name} (${to.path})`)
    // Complete the animation of the route progress bar.
    routeResolved = true;
    NProgress.done();
  });
}
