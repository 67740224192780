<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-xl-3 text-sm-left text-md-left d-flex align-items-end">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="-"
              start-placeholder="Data początkowa"
              end-placeholder="Data końcowa"
              :picker-options="pickerOptions"
              :clearable="false"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
            >
            </el-date-picker>
          </div>
          <div class="col-12 col-sm-4 col-md-3 col-xl-2 d-flex align-items-end" style="margin-top: 10px;">
            <el-radio-group v-model="owner" size="medium">
              <el-radio-button v-for="owner in owners" :key="owner" :label="owner"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="col-12 col-sm-7 col-md-5 col-xl-4">
            <div>
              <label>Rodzaj wysyłki</label>
            </div>
            <el-radio-group v-model="mailingType" size="medium">
              <el-radio-button label="Wewnętrzne"></el-radio-button>
              <el-radio-button label="Zewnętrzne"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="col-sm-5 col-xl-3 right-bottom">
            <p-button class="MailingStats__buttonsMargin" type="success" @click="loadStats">Statystyki</p-button>
            <p-button class="MailingStats__buttonsMargin" type="success" @click="downloadStats">Excel</p-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label
            >Filtr <span @click="filter.filter_mode = 'banner'"
                         :class="getFilterModeClass('banner')">banerów</span>/<span
              @click="filter.filter_mode = 'list'"
              :class="getFilterModeClass('list')"
            >list</span
            ></label
            >
            <fg-input
              class="MailingStats__banner_filter"
              placeholder="Filtruj po nazwie"
              name="banner_filter"
              data-vv-as="Filtr banerów"
              v-model="filter.banner_filter"
              v-validate="filterValidation.banner_filter"
              :error="getError('banner_filter')"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Baner</label>
            <fg-input>
              <el-select
                v-model="filter.banners"
                name="banners"
                filterable
                remote
                reserve-keyword
                clearable
                multiple
                :remote-method="remoteSearch"
                :loading="loading"
              >
                <el-option
                  v-for="banner in bannerConfig"
                  :label="'[' + banner.BannerID + '] ' + banner.BannerName"
                  :value="banner.BannerID"
                  :key="banner.BannerID"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Projekt</label>
            <fg-input>
              <el-select v-model="filter.projects" name="projects" filterable clearable multiple>
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>
      <hr/>

      <div class="card-footer" style="display: flex; justify-content: end; text-align: right;">
        <h6>
          <span>Przedział dat:</span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </h6>
      </div>
      <div class="card-footer count-footer" style="display: flex; justify-content: end">
        <h6>
          <span>Wysłane / display / click: </span>
          <span>{{ formatNumber(efficiencySum.sent) }} / {{
              formatNumber(efficiencySum.display)
            }} / {{ formatNumber(efficiencySum.click) }}</span>
        </h6>
      </div>
    </div>

    <div class="card">
      <div class="card-body row">
        <el-table
          :data="queriedData"
          @sort-change="sortChange"
          :cell-style="colStyle"
          :header-cell-style="headerStyle"
          style="width: 100%"
          :default-sort="{ prop: 'banner_id', order: 'descending' }"
        >
          <el-table-column label="Projekt" property="project_id" min-width="100" align="left">
            <template v-slot="props">
              {{ "[" + props.row.project_id + "] " + props.row.project_name }}
            </template>
          </el-table-column>
          <el-table-column label="Banner" property="banner_id" min-width="200" align="left">
            <template v-slot="props">
              <span>{{ "[" + props.row.banner_id + "] " + props.row.banner_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Lista" property="list_name" min-width="100" align="left"></el-table-column>
          <el-table-column label="Start" property="time_send" width="140" align="left" sortable="custom">
            <template v-slot="props">
              {{ props.row.time_send }}
            </template>
          </el-table-column>
          <el-table-column label="Wysłano" property="sent" width="130" align="right">
            <template v-slot="props">
              <div>{{ formatNumber(props.row.prepared) }} / {{ formatNumber(props.row.sent) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Status" property="status" align="right">
            <template v-slot="props">
              <el-popover trigger="click" :width="popoverWidth" placement="left">
                <template v-for="(status, index) in  props.row.status">
                  <div>
                    <b>Provider [{{ index }}] Banner [{{ props.row.banner_id }}] {{ status.HowMany }}</b>
                    <el-table :data="status">
                      <el-table-column prop="Description" width="300" label="Status"></el-table-column>
                      <el-table-column prop="HowMany" width="70" label="Ile"></el-table-column>
                      <el-table-column width="70" label="%">
                        <template v-slot="scope">
                          {{ getPercentage(scope.row.HowMany, props.row.sent) }}
                        </template>

                      </el-table-column>
                    </el-table>
                  </div>
                </template>
                <template v-slot:reference>
                  <el-button type="success" class="fa fa-clone"></el-button>
                </template>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="Display" property="display" width="80" align="right">
            <template v-slot="props">
              <el-tooltip
                class="item"
                effect="light"
                :content="props.row.display_nonuq ? props.row.display_nonuq.toString() : '0'"
                placement="top"
                :open-delay="200"
              >
                <span>{{ formatNumber(props.row.display) }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="%" property="display_percent" width="70" align="right">
            <template v-slot="props">
              <el-tooltip
                class="item"
                effect="light"
                :content="props.row.display_nonuq ? getPercentage(props.row.display_nonuq, props.row.sent) : '0'"
                placement="top"
                :open-delay="200"
              >
                <span>{{ getPercentage(props.row.display, props.row.sent) }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Click" property="click" width="80" align="right">
            <template v-slot="props">
              <el-tooltip
                class="item"
                effect="light"
                :content="props.row.click_nonuq ? props.row.click_nonuq.toString() : '0'"
                placement="top"
                :open-delay="200"
              >
                <span>{{ formatNumber(props.row.click) }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="%" property="click_percent" width="70" align="right">
            <template v-slot="props">
              <el-tooltip
                class="item"
                effect="light"
                :content="props.row.click_nonuq ? getPercentage(props.row.click_nonuq, props.row.sent) : '0'"
                placement="top"
                :open-delay="200"
              >
                <span>{{ getPercentage(props.row.click, props.row.sent) }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Wypisani" property="unsubscribed" width="90" align="right">
            <template v-slot="props">
              <el-tooltip
                class="item"
                effect="light"
                :content="props.row.unsubscribed ? props.row.unsubscribed.toString() : '0'"
                placement="top"
                :open-delay="200"
              >
                <span>{{ formatNumber(props.row.unsubscribed) }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body row">
        <div class="col-sm-6 pagination-info">
          <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
                        :total="pagination.total"></p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

import {DatePicker, Select, Option, OptionGroup, Tooltip, RadioButton, RadioGroup, Button, Popover} from "element-ui";

// ui components
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions
import {
  LOAD_MAILING_STATS_EFFICIENCY,
  MAILING_STATS_EFFICIENCY_INVALID
} from "src/store/actions/stats/mailing_stats_efficiency";
import {LOAD_EXPORT_MAILING_STATS, EXPORT_MAILING_STATS_INVALID} from "src/store/actions/stats/export_mailing_stats";
import {LOAD_BANNER_CONFIG, BANNER_CONFIG_INVALID} from "src/store/actions/stats/banner_config";
import {LOAD_PROJECT_DICT, PROJECT_DICT_INVALID} from "src/store/actions/questions/project_dict";

// event bus
import {eventBus} from "src/main";
import utils from "src/utils";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    [Button.name]: Button,
    [Popover.name]: Popover,
    PPagination,
  },
  data: () => {
    return {
      popoverWidth: '300px',
      loading: false,
      filter: {
        banners: [],
        banner_filter: "",
        filter_mode: "banner",
        projects: [],
      },
      filterValidation: {
        banner_filter: {
          regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ._-]+)$",
          max: 32,
        },
      },
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["banner_id", "banner_name"],
      mailingType: "Wewnętrzne",
      dateRange: ["", ""],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      questionSelectWidth: null,
      owners: ['MM', '4M'],
      owner: 'MM',
    };
  },
  computed: {
    efficiencyStats() {
      return this.$store.getters.getMailingStatsEfficiency;
    },
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    tableData() {
      if (this.$store.state.mailing_stats_efficiency.loading_status !== "success") return [];

      let tableData = Object.values(this.efficiencyStats);

      tableData.sort((a, b) => b.banner_id - a.banner_id);
      return tableData;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
    efficiencySum() {
      let sumObj = {
        sent: 0,
        display: 0,
        click: 0,
      };

      if (this.tableData.length < 1) return sumObj;

      if (this.efficiencyStats && Object.values(this.efficiencyStats).length > 0) {
        Object.values(this.efficiencyStats).forEach((row) => {
          sumObj.sent += row.sent ? row.sent : 0;
          sumObj.display += row.display ? row.display : 0;
          sumObj.click += row.click ? row.click : 0;
        });
      }

      return sumObj;
    },
  },
  methods: {
    sortChange(obj) {
      if (obj.order === "ascending") {
        this.tableData.sort(function (a, b) {
          return new Date(a.time_send) - new Date(b.time_send)
        })
      } else {
        this.tableData.sort(function (a, b) {
          return new Date(b.time_send) - new Date(a.time_send)
        })

      }
      this.searchQuery += " ";
      this.searchQuery = this.searchQuery.slice(0, -1);
    },
    updatePopoverWidth() {
      const windowWidth = window.innerWidth;

      // Adjust popover width based on screen size
      if (windowWidth < 470) {
        this.popoverWidth = '300';
      } else {
        this.popoverWidth = '470';
      }
    },
    formatNumber(num, dec) {
      return utils.formatNumber(num, dec);
    },
    getPercentage(a, b) {
      if (typeof a !== "number" || typeof b !== "number") return "0%";
      if (b === 0) {
        return "-";
      }
      return this.formatNumber(Math.round((a / b) * 100 * 100) / 100, 2) + "%";
    },
    async loadData(force) {
      this.loading = true;
      if (force) {
        // this.$store.commit(SETTLEMENTS_INVALID);
      }

      await this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    async loadStats(force) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        return;
      }

      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      if (force) {
        this.$store.commit(MAILING_STATS_EFFICIENCY_INVALID);
      }
      this.loading = true;

      if (force && this.dateRange[0] !== "") {
        let date = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        };

        const filters = Object.assign({}, date, this.filter);
        filters.mailingType = this.mailingType;
        filters.owner = this.owner;

        await this.$store.dispatch(LOAD_MAILING_STATS_EFFICIENCY, filters).catch(() => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
        this.loading = false;
      }
    },
    async downloadStats() {
      this.loading = true;
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        this.loading = false;
        return;
      }

      this.$store.commit(EXPORT_MAILING_STATS_INVALID);

      let date = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
      };

      const filters = Object.assign({}, date, this.filter);
      filters.mailingType = this.mailingType;
      filters.owner = this.owner;

      await this.$store.dispatch(LOAD_EXPORT_MAILING_STATS, filters).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      const tmpName = this.$store.getters.getExportMailingStats.tmpname;
      const fileName = this.$store.getters.getExportMailingStats.name;
      this.downloadFile(tmpName, fileName);

      this.loading = false;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-utils4.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = `${basePath}?token=${userToken}&tmpname=${tmpName}&name=${fileName}`;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, {searchQuery}).catch(() => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    colStyle() {
      return "font-size: 12px;";
    },
    headerStyle() {
      return "font-size: 12px;";
    },
    getFilterModeClass(type) {
      if (type === this.filter.filter_mode) return "filter-checked";
      return "filter-unchecked";
    },
  },
  mounted() {
    this.updatePopoverWidth();
    window.addEventListener('resize', this.updatePopoverWidth);
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadStats(true);
    });
  },
  beforeDestroy: function () {
    this.$store.commit(MAILING_STATS_EFFICIENCY_INVALID);
    window.removeEventListener('resize', this.updatePopoverWidth);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped>
.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.MailingStats__dateRange {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 10px 0;
}

.MailingStats__buttonsMargin {
  margin-left: 10px;
  margin-right: 10px;
}

.count-footer {
  font-size: 12px;
  padding-top: 3px;
}

.filter-checked {
  cursor: pointer;
  text-decoration: underline;
}

.filter-unchecked {
  cursor: pointer;
}
</style>
