<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Raport efektywności display</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 mt-1">
            <div class="row">
              <div class="col-6"><br>
                <el-select v-if="week !== ''" v-model="incomeType" name="incomeType" data-vv-as="Typ przychodu"
                           style="width: 100%">
                  <el-option v-for="type in incomeOptions" :label="type.label" :value="type.name"
                             :key="type.name"></el-option>
                </el-select>
              </div>
              <div class="col-6"><b>Tydzień</b>
                <flat-pickr class="flatPickr" ref="weekPickr" name="week" v-model="pickrWeek" :config="config"
                            placeholder="Wybierz tydzień"></flat-pickr>
              </div>
            </div>

          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-5 mt-1">
            <div class="row">

              <div class="col-12 col-md-6"><b>Od Tydzień</b><br>
                <flat-pickr class="flatPickr" ref="weekPickrRangeOd" name="weekRangeOd" v-model="pickrWeekRangeOd"
                            :config="configWeekRange"
                            placeholder="Wybierz Tydzień Od"></flat-pickr>
              </div>
              <div class="col-12 col-md-6"><b>Do Tydzień</b><br>
                <flat-pickr class="flatPickr" ref="weekPickrRangeDo" name="weekRangeDo" v-model="pickrWeekRangeDo"
                            :config="configWeekRange"
                            placeholder="Wybierz Tydzień Do"></flat-pickr>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-3">
            <div class="float-right">
            <br>
            <p-button type="success" @click="loadData('show')" class="my-0">Pokaż</p-button>
            <p-button type="success" @click="loadData('excel')" class="my-0">Excel</p-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" style="margin-top: 10px">

      <div class="card-header weekHeader" style="display: flex; justify-content: space-between">
        <h6>
          <span>Przedział dat:</span> <span>{{
            week === null ? "Wybierz tydzień" : dateFromTitle + " - " + dateToTitle
          }}</span>
        </h6>
      </div>

    </div>

    <div class="card" v-if="Object.values(efficiencyRaport).length > 0">
      <div class="card-header">
        <h5>Podsumowanie</h5>
      </div>
      <div class="card-body">
        <el-table
          v-if="Object.values(efficiencyRaport).length > 0"
          :data="Object.values(efficiencyRaport.summary)"
          style="width: 100%"
          :header-cell-style="headerStyle"
          size="mini"
        >
          <el-table-column type="expand">
            <template v-slot="props">
              <div class="row">
                <div class="col-sm-12">
                  <div style="width: 400px">
                    <table class="table displayTable">
                      <th>Rekordy</th>
                      <th>Zebrane</th>
                      <th>Sprzedane</th>
                      <tbody>
                      <tr>
                        <td class="verticalTableHeader">Lead</td>
                        <td>{{ props.row.Lead | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.LeadSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.LeadSold, props.row.Lead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">Lead F1</td>
                        <td>{{ props.row.LeadF1 | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.LeadF1Sold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.LeadF1Sold, props.row.LeadF1) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">Full Lead</td>
                        <td>{{ props.row.FullLead | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.FullLeadSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.FullLeadSold, props.row.FullLead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">Lead Hot</td>
                        <td>{{ props.row.LeadHot | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.LeadHotSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.LeadHotSold, props.row.LeadHot) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Lead</td>
                        <td>{{ props.row.NewLead | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.NewLeadSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.NewLeadSold, props.row.NewLead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Lead F1</td>
                        <td>{{ props.row.NewLeadF1 | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.NewLeadF1Sold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.NewLeadF1Sold, props.row.NewLeadF1) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Full Lead</td>
                        <td>{{ props.row.NewFullLead | formatNumber }}</td>
                        <el-tooltip
                          class="item"
                          effect="light"
                          :content="props.row.NewFullLeadSold | formatNumber"
                          placement="top"
                          :open-delay="300"
                        >
                          <td>{{ getRatio(props.row.NewFullLeadSold, props.row.NewFullLead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Person</td>
                        <td>{{ props.row.NewPerson | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.NewPersonSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.NewPersonSold, props.row.NewPerson) }}</td>
                        </el-tooltip>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Lead" min-width="80" property="Lead" align="right">
            <template v-slot="props">{{ props.row.Lead | formatNumber }}</template>
          </el-table-column>
          <el-table-column label="LeadF1" min-width="80" property="LeadF1" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="Lead F1" placement="top" :open-delay="200">
                <span>LF1</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ props.row.LeadF1 | formatNumber }}</template>
          </el-table-column>
          <el-table-column label="NewFullLead" min-width="80" property="NewFullLead" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="Nowy pełny lead" placement="top" :open-delay="200">
                <span>NPL</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ props.row.NewFullLead | formatNumber }}</template>
          </el-table-column>
          <el-table-column label="NL/L" min-width="110" property="deduplication" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="Nowy lead/Lead" placement="top" :open-delay="200">
                <span>NL/L</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ formatNumber(props.row.NewLead) }} / {{
                formatNumber(props.row.Lead)
              }}
            </template>
          </el-table-column>
          <el-table-column label="CPL" min-width="80" property="cpl" align="right">
            <template v-v-slot:header>
              <el-tooltip class="item" effect="light" content="CPL" placement="top" :open-delay="200">
                <span>CPL</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getCPL(props.row.cost, props.row.Lead) }}</template>
          </el-table-column>
          <el-table-column label="CPLF1" min-width="100" property="cplf1" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="CPLF1" placement="top" :open-delay="200">
                <span>CPLF1</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getCPL(props.row.cost, props.row.LeadF1) }}</template>
          </el-table-column>
          <el-table-column label="CPNPL" min-width="100" property="cplf1" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="CPNPL" placement="top" :open-delay="200">
                <span>CPNPL</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getCPL(props.row.cost, props.row.NewFullLead) }}</template>
          </el-table-column>
          <el-table-column label="Przychód" min-width="120" property="income" align="right">
            <template v-slot:header> Przychód</template>
            <template v-slot="props">
              <span>{{ props.row[incomeType] | formatCurrency }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Koszt" min-width="100" property="cost" align="right">
            <template v-slot="props">
              {{ props.row.cost | formatCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Zysk/Strata" min-width="130" property="revenue" align="right">
            <template v-slot="props">
              <b>{{ getRevenue(props.row[incomeType], props.row.cost) | formatCurrency }}</b>
            </template>
          </el-table-column
          >
          <el-table-column label="ROI Hot%" min-width="120" property="date_from">
            <template v-slot="props">
              <div class="progress" style="height: 15px">
                <div
                  :class="
                    getPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) < 0
                      ? 'progress-bar bg-danger'
                      : 'progress-bar bg-success'
                  "
                  :style="'width: ' + getAbsolutPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + '%;'"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span class="percentageValue">{{
                      getPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + "%"
                    }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="ROI%" min-width="120" property="date_from">
            <template v-slot="props">
              <div class="progress" style="height: 15px">
                <div
                  :class="
                    getPercentage(getRevenue(props.row[incomeType], props.row.cost), props.row.cost) < 0
                      ? 'progress-bar bg-danger'
                      : 'progress-bar bg-success'
                  "
                  :style="'width: ' + getAbsolutPercentage(getRevenue(props.row[incomeType], props.row.cost), props.row.cost) + '%;'"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span class="percentageValue">{{
                      getPercentage(getRevenue(props.row[incomeType], props.row.cost), props.row.cost) + "%"
                    }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div v-if="efficiencyMode === 'week'" v-for="raport in efficiencyRaport.projects" :key="raport.project_id">
      <div class="card">
        <div class="card-header">
          <h5>
            {{ raport.project + " [" + raport.project_id + "]" }}
          </h5>
        </div>
        <el-table :data="Object.values(raport.banners)" style="width: 100%" :row-style="rowStyle"
                  :header-cell-style="headerStyle" size="mini">
          <el-table-column type="expand">
            <template v-slot="props">
              <div class="row">
                <div class="col-sm-12">
                  <div style="width: 400px">
                    <table class="table displayTable">

                      <th>Rekordy</th>
                      <th>Zebrane</th>
                      <th>Sprzedane</th>

                      <tbody>
                      <tr>
                        <td class="verticalTableHeader">Lead</td>
                        <td>{{ props.row.Lead | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.LeadSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.LeadSold, props.row.Lead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">Lead F1</td>
                        <td>{{ props.row.LeadF1 | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.LeadF1Sold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.LeadF1Sold, props.row.LeadF1) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">Full Lead</td>
                        <td>{{ props.row.FullLead | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.FullLeadSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.FullLeadSold, props.row.FullLead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">Lead Hot</td>
                        <td>{{ props.row.LeadHot | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.LeadHotSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.LeadHotSold, props.row.LeadHot) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Lead</td>
                        <td>{{ props.row.NewLead | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.NewLeadSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.NewLeadSold, props.row.NewLead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Lead F1</td>
                        <td>{{ props.row.NewLeadF1 | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.NewLeadF1Sold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.NewLeadF1Sold, props.row.NewLeadF1) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Full Lead</td>
                        <td>{{ props.row.NewFullLead | formatNumber }}</td>
                        <el-tooltip
                          class="item"
                          effect="light"
                          :content="props.row.NewFullLeadSold | formatNumber"
                          placement="top"
                          :open-delay="300"
                        >
                          <td>{{ getRatio(props.row.NewFullLeadSold, props.row.NewFullLead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Person</td>
                        <td>{{ props.row.NewPerson | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.NewPersonSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.NewPersonSold, props.row.NewPerson) }}</td>
                        </el-tooltip>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Banner" min-width="200" property="banner_id">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="checkCostTooltip(props.row)" placement="top"
                          :open-delay="500">
                <el-tag v-if="checkCost(props.row)" type="danger" effect="dark" size="small">!</el-tag>
              </el-tooltip>
              <span style="padding-left: 10px" :style="props.row.banner_id ? 'cursor: pointer;' : ''"
                    @click="bannerClick(props.row)">{{
                  props.row.banner_id !== null ? props.row.banner_name + " [" + props.row.banner_id + "]" : props.row.banner_name
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Sprz/F1" min-width="100" property="uu_f1_ratio" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="Sprzedane UU/Lead F1" placement="top" :open-delay="200">
                <span>Sprz/F1</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getRatio(props.row.LeadSold, props.row.LeadF1) }}</template>
          </el-table-column>
          <el-table-column label="NL/L" min-width="80" property="deduplication" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="New Lead/Lead" placement="top" :open-delay="200">
                <span>NL/L</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getRatio(props.row.NewLead, props.row.Lead) }}</template>
          </el-table-column>
          <el-table-column label="CPL" min-width="100" property="cpl" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="CPL" placement="top" :open-delay="200">
                <span>CPL</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getCPL(props.row.cost, props.row.Lead) }}</template>
          </el-table-column>
          <el-table-column label="CPLF1" min-width="100" property="cplf1" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="CPLF1" placement="top" :open-delay="200">
                <span>CPLF1</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getCPL(props.row.cost, props.row.LeadF1) }}</template>
          </el-table-column>
          <el-table-column label="CPNPL" min-width="100" property="cplf1" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="CPNPL" placement="top" :open-delay="200">
                <span>CPNPL</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getCPL(props.row.cost, props.row.NewFullLead) }}</template>
          </el-table-column>
          <el-table-column label="Przychód" min-width="120" property="income" align="right">
            <template v-slot:header>
              <span v-if="incomeType === 'income'">Przychód</span>
              <span v-else-if="incomeType === 'income_hot'">Przychód Hot</span>
              <span v-else-if="incomeType === 'income_warm'">Przychód Warm</span>
            </template>
            <template v-slot="props">
              <span>{{ props.row[incomeType] | formatCurrency }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Koszt" min-width="100" property="cost" align="right">
            <template v-slot="props">
              {{ props.row.cost | formatCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Zysk/Strata" min-width="130" property="revenue" align="right">
            <template v-slot="props">
              <b>{{ getRevenue(props.row[incomeType], props.row.cost) | formatCurrency }}</b>
            </template>
          </el-table-column>
          <el-table-column label="ROI Hot%" min-width="120" property="date_from">
            <template v-slot="props">
              <div class="progress" style="height: 15px">
                <div
                  :class="
                    getPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) < 0
                      ? 'progress-bar bg-danger'
                      : 'progress-bar bg-success'
                  "
                  :style="'width: ' + getAbsolutPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + '%;'"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span class="percentageValue">{{
                      getPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + "%"
                    }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="ROI%" min-width="120" property="date_from">
            <template v-slot="props">
              <div class="progress" style="height: 15px">
                <div
                  :class="
                    getPercentage(getRevenue(props.row[incomeType], props.row.cost), props.row.cost) < 0
                      ? 'progress-bar bg-danger'
                      : 'progress-bar bg-success'
                  "
                  :style="'width: ' + getAbsolutPercentage(getRevenue(props.row[incomeType], props.row.cost), props.row.cost) + '%;'"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span class="percentageValue">{{
                      getPercentage(getRevenue(props.row[incomeType], props.row.cost), props.row.cost) + "%"
                    }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div v-if="efficiencyMode === 'manyWeeks'" v-for="week in efficiencyRaport.projectsWeeks" :key="week.weekNumber">
      <div class="card">
        <div class="card-header">
          <h5>
            {{ week.weekNumber }}
          </h5>
        </div>
      </div>

      <div class="card" v-for="raport in week.projects" :key="raport.project_id">
        <div class="card-header">
          <h5>
            {{ raport.project + " [" + raport.project_id + "]" }}
          </h5>
        </div>
        <el-table :data="Object.values(raport.banners)" style="width: 100%" :row-style="rowStyle"
                  :header-cell-style="headerStyle" size="mini">
          <el-table-column type="expand">
            <template v-slot="props">
              <div class="row">
                <div class="col-sm-12">
                  <div style="width: 400px">
                    <table class="table displayTable">
                      <th>Rekordy</th>
                      <th>Zebrane</th>
                      <th>Sprzedane</th>
                      <tbody>
                      <tr>
                        <td class="verticalTableHeader">Lead</td>
                        <td>{{ props.row.Lead | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.LeadSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.LeadSold, props.row.Lead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">Lead F1</td>
                        <td>{{ props.row.LeadF1 | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.LeadF1Sold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.LeadF1Sold, props.row.LeadF1) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">Full Lead</td>
                        <td>{{ props.row.FullLead | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.FullLeadSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.FullLeadSold, props.row.FullLead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">Lead Hot</td>
                        <td>{{ props.row.LeadHot | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.LeadHotSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.LeadHotSold, props.row.LeadHot) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Lead</td>
                        <td>{{ props.row.NewLead | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.NewLeadSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.NewLeadSold, props.row.NewLead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Lead F1</td>
                        <td>{{ props.row.NewLeadF1 | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.NewLeadF1Sold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.NewLeadF1Sold, props.row.NewLeadF1) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Full Lead</td>
                        <td>{{ props.row.NewFullLead | formatNumber }}</td>
                        <el-tooltip
                          class="item"
                          effect="light"
                          :content="props.row.NewFullLeadSold | formatNumber"
                          placement="top"
                          :open-delay="300"
                        >
                          <td>{{ getRatio(props.row.NewFullLeadSold, props.row.NewFullLead) }}</td>
                        </el-tooltip>
                      </tr>
                      <tr>
                        <td class="verticalTableHeader">New Person</td>
                        <td>{{ props.row.NewPerson | formatNumber }}</td>
                        <el-tooltip class="item" effect="light" :content="props.row.NewPersonSold | formatNumber"
                                    placement="top" :open-delay="300">
                          <td>{{ getRatio(props.row.NewPersonSold, props.row.NewPerson) }}</td>
                        </el-tooltip>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Banner" min-width="200" property="banner_id">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="checkCostTooltip(props.row)" placement="top"
                          :open-delay="500">
                <el-tag v-if="checkCost(props.row)" type="danger" effect="dark" size="small">!</el-tag>
              </el-tooltip>
              <span style="padding-left: 10px" :style="props.row.banner_id ? 'cursor: pointer;' : ''"
                    @click="bannerClick(props.row)">{{
                  props.row.banner_id !== null ? props.row.banner_name + " [" + props.row.banner_id + "]" : props.row.banner_name
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Sprz/F1" min-width="100" property="uu_f1_ratio" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="Sprzedane UU/Lead F1" placement="top" :open-delay="200">
                <span>Sprz/F1</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getRatio(props.row.LeadSold, props.row.LeadF1) }}</template>
          </el-table-column>
          <el-table-column label="NL/L" min-width="80" property="deduplication" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="New Lead/Lead" placement="top" :open-delay="200">
                <span>NL/L</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getRatio(props.row.NewLead, props.row.Lead) }}</template>
          </el-table-column>
          <el-table-column label="CPL" min-width="100" property="cpl" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="CPL" placement="top" :open-delay="200">
                <span>CPL</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getCPL(props.row.cost, props.row.Lead) }}</template>
          </el-table-column>
          <el-table-column label="CPLF1" min-width="100" property="cplf1" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="CPLF1" placement="top" :open-delay="200">
                <span>CPLF1</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getCPL(props.row.cost, props.row.LeadF1) }}</template>
          </el-table-column>
          <el-table-column label="CPNPL" min-width="100" property="cplf1" align="right">
            <template v-slot:header>
              <el-tooltip class="item" effect="light" content="CPNPL" placement="top" :open-delay="200">
                <span>CPNPL</span>
              </el-tooltip>
            </template>
            <template v-slot="props">{{ getCPL(props.row.cost, props.row.NewFullLead) }}</template>
          </el-table-column>
          <el-table-column label="Przychód" min-width="120" property="income" align="right">
            <template v-slot:header>
              <span v-if="incomeType === 'income'">Przychód</span>
              <span v-else-if="incomeType === 'income_hot'">Przychód Hot</span>
              <span v-else-if="incomeType === 'income_warm'">Przychód Warm</span>
            </template>
            <template v-slot="props">
              <span>{{ props.row[incomeType] | formatCurrency }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Koszt" min-width="100" property="cost" align="right">
            <template v-slot="props">
              {{ props.row.cost | formatCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Zysk/Strata" min-width="130" property="revenue" align="right">
            <template v-slot="props">
              <b>{{ getRevenue(props.row[incomeType], props.row.cost) | formatCurrency }}</b>
            </template>
          </el-table-column>
          <el-table-column label="ROI Hot%" min-width="120" property="date_from">
            <template v-slot="props">
              <div class="progress" style="height: 15px">
                <div
                  :class="
                    getPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) < 0
                      ? 'progress-bar bg-danger'
                      : 'progress-bar bg-success'
                  "
                  :style="'width: ' + getAbsolutPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + '%;'"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span class="percentageValue">{{
                      getPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + "%"
                    }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="ROI%" min-width="120" property="date_from">
            <template v-slot="props">
              <div class="progress" style="height: 15px">
                <div
                  :class="
                    getPercentage(getRevenue(props.row[incomeType], props.row.cost), props.row.cost) < 0
                      ? 'progress-bar bg-danger'
                      : 'progress-bar bg-success'
                  "
                  :style="'width: ' + getAbsolutPercentage(getRevenue(props.row[incomeType], props.row.cost), props.row.cost) + '%;'"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span class="percentageValue">{{
                      getPercentage(getRevenue(props.row[incomeType], props.row.cost), props.row.cost) + "%"
                    }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import "moment/locale/pl";

// event bus
import {eventBus} from "src/main";
import {FIRST_DAY_OF_WEEK} from "src/config";

import {Select, Option, Tooltip, Tag} from "element-ui";

import {LOAD_DISPLAY_EFFICIENCY, DISPLAY_EFFICIENCY_INVALID} from "src/store/actions/secure/display_efficiency";
import {
  LOAD_SALES_ERAPORT_EXCEL_DISPLAY,
  SALES_ERAPORT_EXCEL_DISPLAY_INVALID
} from "src/store/actions/secure/sales_eraport_display";

import BannerSalesModal from "src/components/Utils4/sales/views/BannerSalesModal.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/plugins/monthSelect/style.css"
import {Polish} from "flatpickr/dist/l10n/pl.js";
import weekSelect from "flatpickr/dist/plugins/weekSelect/weekSelect";
import weekSelectRange from "flatpickr/dist/plugins/rangePlugin";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Tag.name]: Tag,
    flatPickr,
  },
  data() {
    return {
      loading: false,
      incomeType: "income",
      incomeOptions: [
        {name: "income", label: "Wszystkie"},
        {name: "income_hot", label: "Hot"},
        {name: "income_warm", label: "Warm"},
      ],
      pickrWeek: null,
      pickrWeekRangeOd: null,
      pickrWeekRangeDo: null,
      config: {
        locale: {
          ...Polish,
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
        onOpen: this.pickrWeekOpen,
        plugins: [new weekSelect({})],
        altInput: true,
        altInputClass: "flatPickr",
        altFormat: "T\\y\\dz\\ień W",
      },
      configWeekRange: {
        locale: {
          ...Polish,
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
        onOpen: this.pickrWeekRangeOpen,
        plugins: [
          new weekSelect()
        ],
        altInput: true,
        altInputClass: "flatPickr",
        altFormat: "T\\y\\dz\\ień W",
      },
    };
  },
  methods: {
    getDateSell(sellDay) {
      let resultDate = '';
      if (sellDay) {
        let resultDate = new Date(sellDay);
        const selectedDay = resultDate.getDay();

        // 0: -4, 1: -5, 2: +1, 3: 0, 4: -1, 5: -2, 6: -3
        if (selectedDay >= 2) {
          resultDate.setDate(resultDate.getDate() + (3 - selectedDay));
        } else {
          resultDate.setDate(resultDate.getDate() + (-4 - selectedDay));
        }

        return resultDate;
      }
    },
    getWeekSellOd(weekDay) {
      let firstDay = null;
      weekDay = this.getDateSell(weekDay);
      if (weekDay !== '') {
        firstDay = new Date(weekDay.getTime());
        firstDay.setDate(firstDay.getDate() - 1);
      }
      return firstDay;
    },
    getWeekSellDo(weekDay) {
      let lastDay = null;
      weekDay = this.getDateSell(weekDay);
      if (weekDay !== '') {
        lastDay = new Date(weekDay.getTime());
        lastDay.setDate(lastDay.getDate() + 5);
      }

      return lastDay;
    },

    pickrWeekOpen() {
      this.pickrWeekRangeOd = null;
      this.pickrWeekRangeDo = null;
      const weekOd = this.$refs.weekPickrRangeOd.fp;
      const weekDo = this.$refs.weekPickrRangeDo.fp;

      weekOd.clear();
      weekOd.close();
      weekDo.clear();
      weekDo.close();
    },
    pickrWeekRangeOpen() {
      this.pickrWeek = null;
      const week = this.$refs.weekPickr.fp;
      week.selectedDateElem = null;
      week.weekEndDay = null;
      week.weekStartDay = null;
      week.clear();
      week.close();
    },
    async loadData(mode) {
      let sD = null, eD = null;
      if (this.pickrWeekRangeOd !== null || this.pickrWeekRangeDo !== null) {

        sD = this.getWeekSellOd(this.pickrWeekRangeOd);
        eD = this.getWeekSellDo(this.pickrWeekRangeDo);
        if (eD.getFullYear() < sD.getFullYear() || eD.getFullYear() === sD.getFullYear() && eD.getMonth() < sD.getMonth()) {
          eventBus.$emit("notify", {
            message: "Błąd zakresu Od > Do",
            type: "warning",
          });
          return;
        }


        const monthDiff = eD.getMonth() - sD.getMonth() + (12 * (eD.getFullYear() - sD.getFullYear()));

        if (monthDiff > 6) {
          eventBus.$emit("notify", {
            message: "Maksymalnie 6 mcy",
            type: "warning",
          });
          return;
        }
      }
      if (this.dateFrom === null && eD === null && sD === null) {
        eventBus.$emit("notify", {
          message: "Należy wybrać tydzień",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      let filters = {
        date_from: moment(this.dateFrom).format("YYYY-MM-DD"),
        date_to: moment(this.dateTo).format("YYYY-MM-DD"),
        mode: 'week'
      };
      if (this.dateFrom === null) {
        filters.date_from = moment(sD).format("YYYY-MM-DD");
        filters.date_to = moment(eD).format("YYYY-MM-DD");
        filters.mode = 'weekRange';
      }

      this.$store.commit(mode === "excel" ? SALES_ERAPORT_EXCEL_DISPLAY_INVALID : DISPLAY_EFFICIENCY_INVALID);

      await this.$store.dispatch(mode === "excel" ? LOAD_SALES_ERAPORT_EXCEL_DISPLAY : LOAD_DISPLAY_EFFICIENCY, filters).catch((error) => {
        a
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      if (mode === "excel") {
        const tmpName = this.$store.getters.getDisplayEfficiencyExel.tmpname;
        const fileName = this.$store.getters.getDisplayEfficiencyExel.name;

        //console.log(tmpName,fileName);
        this.downloadFile(tmpName, fileName);
      }

      this.loading = false;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-contract.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getPercentage(a, b) {
      if (b === 0) {
        return 100;
      }
      return Math.round((a / b) * 100);
    },
    getAbsolutPercentage(a, b) {
      return Math.abs(this.getPercentage(a, b));
    },
    getRevenue(a, b) {
      return a - b;
    },
    rowStyle(obj) {
      if (obj.row.banner_name === "Suma") {
        return "background-color: #f2fcff; font-weight: bold;";
      }
      return "";
    },
    headerStyle() {
      return "font-size: 12px;";
    },
    getRatio(a, b) {
      if (b === 0 || b === null) {
        return "-";
      }
      return Math.round((a / b) * 100) + "%";
    },
    getCPL(cost, lead) {
      if (lead > 0) {
        return this.formatNumber(cost / lead, 2) + " zł";
      } else {
        return "-";
      }
    },
    bannerClick(row) {
      if (row.banner_id) {
        this.$modal.show(
          BannerSalesModal,
          {
            dateRange: [this.dateFrom, this.dateTo],
            bannerID: row.banner_id,
            bannerName: row.banner_name,
            buttons: [
              {
                title: "Zamknij",
                handler: () => {
                  this.$modal.hide(BannerSalesModal);
                },
              },
            ],
          },
          {
            name: "BannerSalesModal",
            draggable: false,
            scrollable: true,
            width: "600px",
            height: "auto",
            maxHeight: 800,
            pivotX: 0.5,
            pivotY: 0.6,
            adaptive: true,
          },
        );
      }
    },
    checkCost(row) {
      if (row.banner_id) {
        if (row.IsFullWeek === 0) return true;
        if (row.IsFullWeek === null && moment(this.dateTo).add(1, "days").isBefore(moment(new Date()))) return true;
        return false;
      }
    },
    checkCostTooltip(row) {
      if (row.IsFullWeek == 0) {
        return "Przedział dodanego kosztu dla banera nie obejmuje całego tygodnia";
      } else if (row.IsFullWeek == null) {
        if (moment(this.dateTo).add(1, "days").isBefore(moment(new Date()))) {
          return "Brak dodanego kosztu dla banera";
        }
      } else {
        return "";
      }
    },
    formatNumber(number, decimals = 0) {
      let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number).toFixed(decimals).split(".");
      n[0] = n[0]
        .split("")
        .reverse()
        .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
        .reverse()
        .join("");
      let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

      return final;
    },
  },
  computed: {
    week() {
      let resultDate = '';
      if (this.pickrWeek) {
        let resultDate = new Date(this.pickrWeek);
        const selectedDay = resultDate.getDay();

        // 0: -4, 1: -5, 2: +1, 3: 0, 4: -1, 5: -2, 6: -3
        if (selectedDay >= 2) {
          resultDate.setDate(resultDate.getDate() + (3 - selectedDay));
        } else {
          resultDate.setDate(resultDate.getDate() + (-4 - selectedDay));
        }

        return resultDate;
      }

      return resultDate;
    },
    dateFrom() {
      let firstDay = null;
      if (this.week !== '') {
        firstDay = new Date(this.week.getTime());
        firstDay.setDate(firstDay.getDate() - 1);
      }
      return firstDay;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateFrom).format("dddd, Do MMMM YYYY");
    },
    dateTo() {
      let lastDay = null;
      if (this.week !== '') {
        lastDay = new Date(this.week.getTime());
        lastDay.setDate(lastDay.getDate() + 5);
      }
      return lastDay;
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateTo).format("dddd, Do MMMM YYYY");
    },
    efficiencyMode() {
      let mode = '';
      if (this.$store.state.display_efficiency.loading_status === "success") {
        const data = JSON.parse(JSON.stringify(this.$store.getters.getDisplayEfficiency));
        mode = data.mode;
      }
      return mode;
    },
    efficiencyRaport() {
      let data = {}, mode = '';
      if (this.$store.state.display_efficiency.loading_status === "success") {
        data = JSON.parse(JSON.stringify(this.$store.getters.getDisplayEfficiency));
        const projects = data.projects;
        mode = data.mode;
        if (projects && mode === 'week') {
          for (let project in projects) {
            projects[project].banners = Object.values(projects[project].banners);
            let summary = projects[project].summary;
            summary.banner_name = "Suma";
            summary.banner_id = null;
            projects[project].banners.push(summary);
          }

          let projectsArray = Object.values(projects);
          projectsArray.sort((a, b) => (a.project.toLowerCase() > b.project.toLowerCase() ? 1 : -1));

          data.projects = projectsArray;
        } else if (projects && mode === 'manyWeeks') {
          data.projectsWeeks = [];
          let dataWeek = [];
          for (let weekNumber in projects) {
            let projectWeek = {'weekNumber': weekNumber};
            for (let project in projects[weekNumber]) {
              projects[weekNumber][project].banners = Object.values(projects[weekNumber][project].banners);
              let summary = projects[weekNumber][project].summary;

              summary.banner_name = "Suma";
              summary.banner_id = null;
              projects[weekNumber][project].banners.push(summary);
            }
            let projectsArray = Object.values(projects[weekNumber]);
            projectsArray.sort((a, b) => (a.project.toLowerCase() > b.project.toLowerCase() ? 1 : -1));

            projectWeek.projects = projectsArray;
            data.projectsWeeks.push(projectWeek);
          }
        }
      }
      return data;
    },
  },
  mounted() {
  },
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    this.$store.commit(DISPLAY_EFFICIENCY_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.percentageValue {
  color: #383838;
  font-weight: bold;
}

.weekHeader {
  padding: 15px 15px 5px !important;
}

.displayTable {
  font-size: 12px;
}

</style>
<style>
.flatPickr {
  width: 100%;
}
</style>
