<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Stawki leadów</h5>
      </div>
      <div class="col-md-6 ml-auto">
        <el-menu default-active="active" class="el-menu-demo" background-color="#F4F3EF" mode="horizontal" @select="menuSelect">
          <el-menu-item index="active">Aktualne stawki</el-menu-item>
          <el-menu-item index="historic">Historia</el-menu-item>
        </el-menu>
      </div>
    </div>

    <div v-if="selectedMenu === 'active'">
      <appClientRatesActive></appClientRatesActive>
    </div>
    <div v-else>
      <appClientRatesHistoric></appClientRatesHistoric>
    </div>

  </div>
</template>
<script>
  import Vue from "vue";
  // event bus
  import { eventBus } from "src/main";

  import { Menu, MenuItem } from "element-ui";

  import ClientRatesActive from "src/components/Utils4/sales/views/ClientRatesActive.vue";
  import ClientRatesHistoric from "src/components/Utils4/sales/views/ClientRatesHistoric.vue";

  export default {
    components: {
      [Menu.name]: Menu,
      [MenuItem.name]: MenuItem,
      appClientRatesActive: ClientRatesActive,
      appClientRatesHistoric: ClientRatesHistoric
    },
    data() {
      return {
        selectedMenu: 'active',
      }
    },
    methods: {
      menuSelect(index, indexPath) {
        this.selectedMenu = index;
      }
    },
  }
</script>
<style scoped>
.el-menu {
  float: right;
  margin-right: 10px;
}
</style>
