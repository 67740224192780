<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <h5 class="title">Aktywne zgody i obowiązki informacyjne</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8 col-md-4">
        <label>Projekt</label>
        <fg-input :error="getError('project_id')" data-vv-as="Projekt">
          <el-select
            id="ConsentsProjectSelect"
            v-on:visible-change="getUpperLeftSelectWidth()"
            v-model="project_id"
            name="project_id"
            data-vv-as="Projekt"
            filterable
            v-on:input="loadData()"
          >
            <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
              <el-option
                :style="upperLeftSelectWidth !== null ? 'width: ' + upperLeftSelectWidth + 'px' : 'width: 300px'"
                :id="'ConsentsProjectSelectOption_' + item.project_id"
                v-for="item in group.options"
                :key="item.project_id"
                :label="item.project_name + ' [' + item.project_id + ']'"
                :value="item.project_id"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </fg-input>
      </div>
      <div class="col-sm-4 col-md-2">
        <label>Wersja</label>
        <fg-input :error="getError('version')" data-vv-as="Wersja">
          <el-select
            id="ConsentsVersionSelect"
            v-on:visible-change="getUpperRightSelectWidth()"
            v-model="version"
            name="version"
            data-vv-as="Wersja"
            filterable
            clearable
          >
            <el-option
              :style="upperRightSelectWidth !== null ? 'width: ' + upperRightSelectWidth + 'px' : 'width: 300px'"
              :id="'ConsentsVersionSelectOption_' + version.module"
              v-for="version in activeProjectModules"
              :label="version.module"
              :value="version.module"
              :key="version.module"
            ></el-option>
          </el-select>
        </fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="queriedData" style="width: 100%; font-size: 12px;" :row-style="rowStyle">
              <el-table-column label="ID" width="80" property="zgody_slownik_id"></el-table-column>
              <el-table-column label="Wersja" width="100" property="site_id">
                <template v-slot="props">
                  <div v-if="props.row.site_id === null">domyślna</div>
                  <div v-else>{{ props.row.short_name }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Typ" min-width="80" align="center" property="typ_zgody">
                <template v-slot="props">
                  <div v-if="props.row.kind === 1">{{ props.row.typ_zgody }}</div>
                  <div v-else>ob</div>
                </template>
              </el-table-column>
              <el-table-column label="Admin" width="120" align="left" property="owner_name">
                <template v-slot="props">
                  {{ getOwners(props.row.owners) }}
                </template>
              </el-table-column>
              <el-table-column label="Treść" min-width="300" align="center" property="tresc_zgody">
                <template v-slot="props">
                  <div style="word-break: keep-all; padding-top: 3px;">
                    {{ props.row.tresc_zgody }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Wymagane" width="130" align="center" property="requaier">
                <template v-slot="props">
                  <div v-if="props.row.requaier">TAK</div>
                  <div v-else>NIE</div>
                </template>
              </el-table-column>
              <el-table-column label="Pozycja" width="110" align="center" property="position">
                <template v-slot="props">
                  <div>{{ props.row.position }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Dodał" min-width="100" align="center" property="user_id">
                <template v-slot="props">
                  <div style="word-break: keep-all;">{{ getUserName(props.row.user_id) }}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-body row">
            <div class="col-sm-6 pagination-info">
              <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
// ui components
import { Select, Option, OptionGroup, Tooltip } from "element-ui";
import BackToTop from "vue-backtotop";
import PPagination from "src/components/UIComponents/Pagination.vue";
import swal from "sweetalert2";

// vuex actions
import { LOAD_USERS_LIST, USERS_LIST_INVALID } from "src/store/actions/admin/users_list";
import { LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID } from "src/store/actions/questions/projects_all";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_CONSENTS, CONSENTS_INVALID } from "src/store/actions/consents/consents";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    PPagination,
    BackToTop,
  },
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      project_id: null,
      version: "",
      upperLeftSelectWidth: null,
      upperRightSelectWidth: null,
    };
  },
  computed: {
    usersList() {
      return this.$store.getters.getUsersList;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map(x => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    activeProjectModules() {
      // wszystkie moduły aktywnego projektu
      let projects = this.$store.getters.getProjectsAll;
      let filteredData = {};

      for (let [key, project] of Object.entries(projects)) {
        // wybranie modułów danego projektu oraz przypisanie im wartości permitted: false
        if (project.project_id === this.project_id) {
          filteredData[project.module] = Object.assign({}, project);
        }
      }

      let sourceData = [];

      for (let ver of Object.keys(filteredData)) {
        // iteracja po nazwach modułów danego projektu
        let res = ver.match("[0-9]+.[0-9]+")[0]; // odnajdywanie liczby
        filteredData[ver].number = res;
      }

      sourceData = Object.values(filteredData);
      sourceData.sort((a, b) => a.number - b.number);
      let result = {};
      for (let i in sourceData) {
        result[sourceData[i].module] = sourceData[i];
      }

      return result;
    },
    consents() {
      return this.$store.getters.getConsents;
    },
    tableData() {
      if (this.project_id === null) return [];
      let consents = Object.values(JSON.parse(JSON.stringify(this.consents)));
      let filteredConsents = consents.filter(consent => this.version === "" || (this.version !== null && this.version === consent.short_name));
      filteredConsents.sort((a, b) => a.site_id - b.site_id || a.typ_zgody - b.typ_zgody);
      return filteredConsents;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData;

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  methods: {
    initLoad() {
      this.$store.dispatch(LOAD_USERS_LIST, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    async loadData(force) {
      if (force) {
        this.$store.commit(PROJECTS_ALL_INVALID);
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(USERS_LIST_INVALID);
      }
      this.$store.commit(CONSENTS_INVALID);

      this.$store.dispatch(LOAD_USERS_LIST, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_CONSENTS, { project_id: this.project_id }).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    rowStyle({ row }) {
      if (row.site_id === null) {
        return "background-color: #f2fcff; font-weight: bold;";
      }
      return "";
    },
    getUserName(user_id) {
      if (this.usersList[user_id]) {
        return this.usersList[user_id].user_name;
      } else {
        return user_id;
      }
    },
    getUpperLeftSelectWidth() {
      this.upperLeftSelectWidth = document.getElementById("ConsentsProjectSelect").offsetWidth;
    },
    getUpperRightSelectWidth() {
      this.upperRightSelectWidth = document.getElementById("ConsentsVersionSelect").offsetWidth;
    },
    getOwners(owners) {
      return owners.map(x => x.name).join(", ");
    },
  },
  async created() {
    this.initLoad();
    // eventBus.$on("forceReload", () => {
    //   this.loadData(true);
    // });
  },
  mounted() {
    eventBus.$on("consentsTabChanged", () => {
      this.project_id = null;
    });
  },
  // beforeDestroy: function () {
  //   eventBus.$off("forceReload");
  // },
};
</script>

<style lang="scss" scoped>
.highlight {
  font-size: 1.2em;
  font-weight: bold;
}
.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
