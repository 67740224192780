<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar"></navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="#">Panel Utils 4</a>
      <!-- <div>
        <el-select v-model="activePartnerId" @change="activePartnerChanged">
          <el-option
            v-for="partner in partners"
            :label="partner.partner_name"
            :value="partner.partner_id"
            :key="partner.partner_id"
          ></el-option>
        </el-select>
      </div> -->
    </div>
    <!-- <div>
      <el-date-picker
        v-model="dateRange"
        type="daterange"
        align="center"
        unlink-panels
        range-separator="-"
        start-placeholder="Data początkowa"
        end-placeholder="Data końcowa"
        :picker-options="pickerOptions"
        @input="dateRangeChanged"
      ></el-date-picker>
    </div> -->
    <div class="btn-rotate">
      <a class="nav-link" href="#" @click="forceReload">
        <i class="nc-icon nc-refresh-69"></i>
        <!-- <p>
          <span class="d-lg-none d-md-block">Stats</span>
        </p> -->
      </a>
    </div>
    <!-- <div style="width: 100%; text-align: end;">
      <div style="width: 300px;">
        <div class="row">
          <div class="col-sm-11">
            <span style="margin-right: 10px;">{{ 'Pozostały czas sesji: ' + sessionRemainingTime }}</span>
          </div>
          <div class="col-sm-1">
            <span class="btn-rotate" style="cursor: pointer;" @click="refreshSession"><i class="nc-icon nc-refresh-69"></i></span>
          </div>
        </div>
      </div>
    </div> -->
    <template slot="navbar-menu">
      <ul class="navbar-nav">
        <li class="nav-item">
          <span style="margin-right: 10px; color: #66615;">Pozostały czas sesji: </span>
          <p style="width: 50px;">{{ sessionRemainingTime }}</p>
        </li>
        <li class="nav-item btn-rotate" style="cursor: pointer; margin: auto;" @click="refreshSession">
            <i class="nc-icon nc-refresh-69"></i>
        </li>
        <!-- <drop-down
          icon="nc-icon nc-bell-55"
          tag="li"
          position="right"
          direction="none"
          class="nav-item btn-rotate dropdown"
        >
          <a
            slot="title"
            v-slot="{isOpen}"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="isOpen"
          >
            <i class="nc-icon nc-bell-55"></i>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </drop-down>-->
        <!-- <drop-down
          icon="nc-icon nc-settings-gear-65"
          tag="li"
          position="right"
          direction="none"
          class="nav-item btn-rotate dropdown"
        >
          <a
            slot="title"
            v-slot="{isOpen}"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            :aria-expanded="isOpen"
          >
            <i class="nc-icon nc-settings-gear-65"></i>
            <p>
              <span class="d-lg-none d-md-block">Ustawienia</span>
            </p>
          </a>
          <a class="dropdown-item" href="#">Ustawienia</a>
        </drop-down>-->
      </ul>
    </template>
  </navbar>
</template>

<script>
// ui components
import { Navbar, NavbarToggleButton } from "src/components/UIComponents";
import { DatePicker, Select, Option } from "element-ui";

import echo from 'src/echo';

// vuex actions
import { AUTH_LOGOUT } from "src/store/actions/auth";
// import { DATE_RANGE } from "src/store/actions/common";
// import { LOAD_PARTNERS } from "src/store/actions/admin/partners";
// import { ACTIVE_PARTNER } from "src/store/actions/common";

// utility functions
import utils from "src/utils";

// event bus
import { eventBus } from "src/main";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Navbar,
    NavbarToggleButton
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      sessionTime: 3599,
      // pickerOptions: {
      //   shortcuts: [
      //     {
      //       text: "Dzisiaj",
      //       onClick(picker) {
      //         const end = utils.today();
      //         const start = utils.today();
      //         picker.$emit("pick", [start, end]);
      //       }
      //     },
      //     {
      //       text: "Ostatni tydzień",
      //       onClick(picker) {
      //         const end = utils.today();
      //         const start = utils.today();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      //         picker.$emit("pick", [start, end]);
      //       }
      //     },
      //     {
      //       text: "Ostatni miesiąc",
      //       onClick(picker) {
      //         const end = utils.today();
      //         const start = utils.today();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      //         picker.$emit("pick", [start, end]);
      //       }
      //     },
      //     {
      //       text: "Ostatnie 3 miesiące",
      //       onClick(picker) {
      //         const end = utils.today();
      //         const start = utils.today();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      //         picker.$emit("pick", [start, end]);
      //       }
      //     }
      //   ]
      // }
    };
  },
  methods: {
    // dateRangeChanged() {
    //   // notifies when date is changed or reload button pressed
    //   eventBus.$emit("dateRangeChanged");
    // },
    // activePartnerChanged() {
    //   // notifies when date is changed or reload button pressed
    //   eventBus.$emit("activePartnerChanged");
    // },
    forceReload() {
      eventBus.$emit("forceReload");
    },
    // capitalizeFirstLetter(string) {
    //   return string.charAt(0).toUpperCase() + string.slice(1);
    // },
    // toggleNotificationDropDown() {
    //   this.activeNotifications = !this.activeNotifications;
    // },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    // hideSidebar() {
    //   this.$sidebar.displaySidebar(false);
    // },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    loadData() {
      // this.$store.dispatch(LOAD_PARTNERS, {}).catch(error => {
      //   eventBus.$emit("notify", {
      //     message: "Błąd wczytywania danych!",
      //     type: "warning"
      //   });
      // });
    },
    changeSessionTime() {
      const time = setInterval( () => {
        if(this.sessionTime <= 0) {
          clearInterval(time);
          eventBus.$emit('logout');
        }
        this.sessionTime -= 1;
      }, 1000);
    },
    refreshSession() {
      echo();
    }
  },
  computed: {
      sessionRemainingTime() {
        const pad = (num, size) => ('000' + num).slice(size * -1);

        const time = this.sessionTime;
        const minutes = Math.floor(time / 60) % 60;
        const seconds = Math.floor(time - minutes * 60);

        return pad(minutes, 2) + ':' + pad(seconds,2);
      }
    // dateRange: {
    //   get() {
    //     return this.$store.getters.getDateRange;
    //   },
    //   set(value) {
    //     this.$store.dispatch(DATE_RANGE, value);
    //   }
    // },
    // partners() {
    //   return this.$store.getters.getPartners;
    // },
    // activePartnerId: {
    //   get() {
    //     return this.$store.getters.getActivePartnerId;
    //   },
    //   set(value) {
    //     this.$store.dispatch(ACTIVE_PARTNER, value);
    //   }
    // }
  },
  created() {
    this.loadData();
    this.changeSessionTime();
    eventBus.$on("restartSessionTime", () => {
      this.sessionTime = 3599;
    });
  }
};
</script>

<style>
</style>
