<template>
  <div>
    <h4 class="title">Zmień hasło</h4>
    <div class="row">
      <div class="col-lg-4 col-md-4">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="form-group">
                <label>Stare hasło</label>
                <fg-input  type="password"
                          name="Stare hasło"
                          v-validate="modelValidations.password_old"
                          v-model="model.password_old"
                          :error="getError('Stare hasło')">
                </fg-input>
              </div>
              <div class="form-group">
                <label>Nowe hasło</label>
                <fg-input  type="password"
                          name="Nowe hasło"
                          v-validate="modelValidations.password_new"
                          v-model="model.password_new"
                          :error="getError('Nowe hasło')">
                </fg-input>
              </div>
              <div class="form-group">
                <label>Powtórz nowe hasło</label>
                <fg-input  type="password"
                          name="Powtórz nowe hasło"
                          v-validate="modelValidations.confirmPassword"
                          v-model="model.confirmPassword"
                          :error="getError('Powtórz nowe hasło')">
                </fg-input>
              </div>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-info btn-fill btn-wd"
                  @click.prevent="saveData"
                >Zmień hasło</button>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

import { SAVE_USER_PASSWORD } from "src/store/actions/admin/user_password";
import 'vue-notifyjs/themes/default.css'

export default {
  data() {
    return {
      model: {
        password_old: "",
        password_new: "",
        confirmPassword: "",
        user_id: null,
        user_pass: ""
      },
      modelValidations: {
        password_old: {
          required: true,
          min: 5
        },
        password_new: {
          required: true,
          min: 5
        },
        confirmPassword: {
          required: true,
        },
        user_pass: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 3,
          max: 32
        }
      }
    };
  },
  computed: {
    response() {
      return JSON.parse(this.$store.state.user_password.saving_result);
    },
    getUserLoginById() {
      return this.$store.getters.getUserLoginById(this.user_id);
    },
    modelPassword() {
      return {
        user_pass: this.model.confirmPassword,
        user_old_pass: this.model.password_old,
      }
    }
  },
  methods: {
    getError (fieldName) {
      return this.errors.first(fieldName)
    },
    saveData() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          if (this.model.password_new === this.model.confirmPassword)
          {
            this.dispatch();
          }
          else
          {
            eventBus.$emit("notify", {
              message: "Hasła nie są zgodne!",
              type: "warning"
            });
          }
        }
      });
    },
    async dispatch() {
      await this.$store.dispatch(SAVE_USER_PASSWORD, this.modelPassword) // zapisz zmiany do bazy
        // TODO: obsluga bledow
      if (this.response.code === 201)
        {
          eventBus.$emit("notify", {
            message: "Hasło zostało zmienione!",
            type: "success"
          });
          this.$router.push({ name: "Overview" }) // przekieruj do listy
        }
      else if (this.response.code === 441)
        {
          eventBus.$emit("notify", {
            message: "Stare hasło nie zgadza się, hasło nie zostało zmienione!",
            type: "danger"
          });
        }
      else if (this.response.code === 440)
      {
        eventBus.$emit("notify", {
          message: "Hasło za krótkie!",
          type: "danger"
        });
      }
    }
  }
};
</script>

<style>
</style>
