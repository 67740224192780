import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_SALES_ERAPORT_EXCEL_DISPLAY,
  SALES_ERAPORT_EXCEL_DISPLAY_INVALID,
  SALES_ERAPORT_EXCEL_DISPLAY_LOADING,
  SALES_ERAPORT_EXCEL_DISPLAY_LOADING_ERROR,
  SALES_ERAPORT_EXCEL_DISPLAY_LOADING_SUCCESS
} from 'src/store/actions/secure/sales_eraport_display'

const storeObjectConfig = {
  objectName: "DisplayEfficiencyExel",
  getterName: "getDisplayEfficiencyExel",
  loadActionName: LOAD_SALES_ERAPORT_EXCEL_DISPLAY,
  loadingMutationName: SALES_ERAPORT_EXCEL_DISPLAY_LOADING,
  loadingSuccessMutationName: SALES_ERAPORT_EXCEL_DISPLAY_LOADING_SUCCESS,
  loadingErrorMutationName: SALES_ERAPORT_EXCEL_DISPLAY_LOADING_ERROR,
  invalidMutationName: SALES_ERAPORT_EXCEL_DISPLAY_INVALID,
  // saveActionName: SAVE_DISPLAY_EFFICIENCY,
  // savingMutationName: DISPLAY_EFFICIENCY_SAVING,
  // savingSuccessMutationName: DISPLAY_EFFICIENCY_SAVING_SUCCESS,
  // savingErrorMutationName: DISPLAY_EFFICIENCY_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/displayEfficiency/excel",
    method: "post" // {'get','post','put'}
  }
  // saveParams: {
  //   url: "/raports/displayCosts",
  //   method: "put" // {'get','post','put'}
  // }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
