<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h4 class="card-title">Deduplikacja</h4>
          </div>
          <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
            <router-link to="/sales/deduplication">
              <p-button type="default" link>Wróć do listy</p-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body center">
        <div class="container">
          <div class="deduplication_row">
            <h5>
              Deduplikacja
              <el-button :disabled="editMode !== false" type="success" size="small" @click="handleAdd('deduplication')" class="edit_button">
                Dodaj
              </el-button>
              <el-button v-if="editMode === 'deduplication'" type="success" size="small" @click="handleEditSave('deduplication')" class="edit_button">
                Zapisz
              </el-button>
              <el-button
                :disabled="editMode === 'in_deduplication'"
                :type="editMode === 'deduplication' ? 'warning' : 'success'"
                size="small"
                @click="handleEdit('deduplication')"
                class="edit_button"
              >
                {{ editMode === "deduplication" ? "Anuluj" : "Edytuj" }}
              </el-button>
            </h5>

            <el-table :data="formObject.deduplication_table" :show-header="false" style="width: 100%" size="mini" :cell-style="cellStyle">
              <el-table-column width="40" v-if="editMode === 'deduplication'">
                <template v-slot="props">
                  <input style="accent-color: #dc3545" type="checkbox" v-if="!refreshTable" v-model="deleteObject[props.row.client_id]" />
                </template>
              </el-table-column>
              <el-table-column label="ID" width="60" property="client_id">
                <template v-slot="props"> [{{ props.row.client_id }}] </template>
              </el-table-column>
              <el-table-column label="Klient" min-width="350" property="client_name">
                <template v-slot="props"> {{ deduplicationClients[props.row.client_id].client_name }} </template>
              </el-table-column>
              <el-table-column label="Grupa" width="180" property="group_name">
                <template v-slot="props"> [{{ deduplicationClients[props.row.client_id].group_name }}] </template>
              </el-table-column>
              <el-table-column label="Dni" width="100" property="days" align="right">
                <template v-slot="props">
                  <el-input-number
                    :controls="false"
                    style="width: 80px"
                    v-if="editMode === 'deduplication'"
                    v-model="editObject[props.row.client_id]"
                    size="small"
                    controls-position="right"
                    :min="1"
                  ></el-input-number>
                  <span v-else
                    ><b class="deduplication_days">{{ props.row.days }}</b> dni</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="check_row" v-if="editMode === 'deduplication'">
              <span class="check_button" @click="checkAll(true)"> Zaznacz </span>
              <span class="check_button" @click="checkAll(false)"> Odznacz </span>
            </div>
          </div>

          <div class="deduplication_row">
            <h4 class="client_header">[{{ formObject.client_id }}] {{ formObject.client_name }}</h4>
          </div>

          <div class="deduplication_row">
            <h5>
              W deduplikacji
              <el-button :disabled="editMode !== false" type="success" size="small" @click="handleAdd('in_deduplication')" class="edit_button">
                Dodaj
              </el-button>
              <el-button
                v-if="editMode === 'in_deduplication'"
                type="success"
                size="small"
                @click="handleEditSave('in_deduplication')"
                class="edit_button"
              >
                Zapisz
              </el-button>
              <el-button
                :disabled="editMode === 'deduplication'"
                :type="editMode === 'in_deduplication' ? 'warning' : 'success'"
                size="small"
                @click="handleEdit('in_deduplication')"
                class="edit_button"
              >
                {{ editMode === "in_deduplication" ? "Anuluj" : "Edytuj" }}
              </el-button>
            </h5>
            <el-table :data="formObject.in_deduplication_table" :show-header="false" style="width: 100%" size="mini" :cell-style="cellStyle">
              <el-table-column width="55" v-if="editMode === 'in_deduplication'">
                <template v-slot="props">
                  <input style="accent-color: #dc3545" type="checkbox" v-if="!refreshTable" v-model="deleteObject[props.row.client_id]" />
                </template>
              </el-table-column>
              <el-table-column label="ID" width="60" property="client_id">
                <template v-slot="props"> [{{ props.row.client_id }}] </template>
              </el-table-column>
              <el-table-column label="Klient" min-width="350" property="client_name">
                <template v-slot="props"> {{ deduplicationClients[props.row.client_id].client_name }} </template>
              </el-table-column>
              <el-table-column label="Grupa" width="180" property="group_name">
                <template v-slot="props"> [{{ deduplicationClients[props.row.client_id].group_name }}] </template>
              </el-table-column>
              <el-table-column label="Dni" width="100" property="days" align="right">
                <template v-slot="props">
                  <el-input-number
                    :controls="false"
                    style="width: 80px"
                    v-if="editMode === 'in_deduplication'"
                    v-model="editObject[props.row.client_id]"
                    size="small"
                    controls-position="right"
                    :min="1"
                  ></el-input-number>
                  <span v-else
                    ><b class="deduplication_days">{{ props.row.days }}</b> dni</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="check_row" v-if="editMode === 'in_deduplication'">
              <span class="check_button" @click="checkAll(true)"> Zaznacz </span>
              <span class="check_button" @click="checkAll(false)"> Odznacz </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option, Button, InputNumber } from "element-ui";

import { SAVE_DEDUPLICATION, LOAD_DEDUPLICATION, DEDUPLICATION_INVALID } from "src/store/actions/sales/deduplication";

import DeduplicationModal from "src/components/Utils4/sales/deduplication/DeduplicationModal.vue";

import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [InputNumber.name]: InputNumber,
  },
  data() {
    return {
      loading: false,
      editMode: false,
      deleteObject: {},
      editObject: {},
      refreshTable: false,
    };
  },
  computed: {
    client_id() {
      return this.$route.params.id;
    },
    deduplication() {
      return this.$store.getters.getDeduplication;
    },
    deduplicationActiveClients() {
      return this.$store.getters.getDeduplicationActiveClients;
    },
    deduplicationClients() {
      return this.$store.getters.getDeduplicationClients;
    },
    formObject() {
      if (this.refreshTable) {
        this.refreshTable = false;
      }

      let result = JSON.parse(JSON.stringify(this.deduplicationClients[this.client_id]));

      if (this.$store.state.deduplication.loading_status === "success") {
        if (this.deduplication.deduplication[this.client_id]) {
          result.deduplication = this.deduplication.deduplication[this.client_id];
          result.deduplication_count = Object.keys(this.deduplication.deduplication[this.client_id]).length;
        } else {
          result.deduplication = {};
          result.deduplication_count = 0;
        }
        if (this.deduplication.in_deduplication[this.client_id]) {
          result.in_deduplication = this.deduplication.in_deduplication[this.client_id];
          result.in_deduplication_count = Object.keys(this.deduplication.in_deduplication[this.client_id]).length;
        } else {
          result.in_deduplication = {};
          result.in_deduplication_count = 0;
        }

        result.deduplication_table = [];
        result.in_deduplication_table = [];

        for (let i in result.deduplication) {
          result.deduplication_table.push({ client_id: i, days: result.deduplication[i] });
        }
        for (let i in result.in_deduplication) {
          result.in_deduplication_table.push({ client_id: i, days: result.in_deduplication[i] });
        }
      }

      return result;
    },
  },
  methods: {
    async loadData() {
      this.loading = true;

      this.$store.commit(DEDUPLICATION_INVALID);

      await this.$store.dispatch(LOAD_DEDUPLICATION, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    cellStyle({ row }) {
      if (row.client_id === this.client_id) return "background-color: #ffc10751;";
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    handleEdit(mode) {
      if (this.editMode === mode) {
        this.deleteObject = {};
        this.editObject = {};
        this.editMode = false;
      } else {
        for (let i in this.formObject[mode]) {
          this.deleteObject[i] = false;
          this.editObject[i] = this.formObject[mode][i];
        }
        this.editMode = mode;
      }
    },
    checkAll(bool) {
      for (let i in this.deleteObject) {
        this.deleteObject[i] = bool;
      }
      this.refreshTable = true;
    },
    async handleEditSave(mode) {
      const deleteArray = [];
      let editObject = {};

      let checkCounter = 0;

      for (let i in this.deleteObject) {
        if (this.deleteObject[i]) {
          deleteArray.push(i);
          checkCounter++;
        }
      }

      for (let i in this.editObject) {
        if (this.editObject[i] != this.formObject[mode][i]) {
          editObject[i] = this.editObject[i];
          checkCounter++;
        }
      }

      if (checkCounter < 1) {
        return eventBus.$emit("notify", {
          message: "Brak zmian do zapisu",
          type: "warning",
        });
      }

      const model = {
        mode: mode,
        client_id: this.client_id,
        delete_array: deleteArray,
        edit_object: editObject,
      };

      await this.$store.dispatch(SAVE_DEDUPLICATION, model).catch((error) => {
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getDeduplicationModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Rekord został dodany",
          type: "success",
        });
        this.loadData();
      }

      this.deleteObject = {};
      this.editObject = {};
      this.editMode = false;
    },
    handleAdd(mode, row) {
      this.$modal.show(
        DeduplicationModal,
        {
          mode: mode,
          row: this.formObject,
          isNew: false,
          parent: "DeduplicationForm",
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(DeduplicationModal);
              },
            },
          ],
        },
        {
          draggable: false,
          scrollable: false,
          width: "800px",
          height: "600px",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: false,
        },
      );
    },
  },
  created() {
    eventBus.$on("deduplicationSaved", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    eventBus.$off("deduplicationSaved");
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.deduplication_row {
  padding: 10px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h5 {
    margin: 10px 0;
  }
}

.client_header {
  text-decoration: underline;
  /* background-color: #ffc10751; */
}

.deduplication_days {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3 ease-in-out;
}

.deduplication_days:hover {
  opacity: 0.7;
}

.edit_button {
  margin-left: 10px;
}

.check_row {
  width: 100%;
  margin-top: 10px;
}

.check_button {
  font-size: 12px;
  margin-right: 10px;
}

.check_button:hover {
  opacity: 0.8;
  cursor: pointer;
}
</style>
