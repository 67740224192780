import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_FILTER_CONSISTENCY_RAPORT,
  FILTER_CONSISTENCY_RAPORT_LOADING,
  FILTER_CONSISTENCY_RAPORT_LOADING_SUCCESS,
  FILTER_CONSISTENCY_RAPORT_LOADING_ERROR,
  FILTER_CONSISTENCY_RAPORT_INVALID,
} from "src/store/actions/filter/filter_consistency_raport";

const storeObjectConfig = {
  objectName: "filterAnalyser",
  getterName: "getFilterConsistencyRaport",
  loadActionName: LOAD_FILTER_CONSISTENCY_RAPORT,
  loadingMutationName: FILTER_CONSISTENCY_RAPORT_LOADING,
  loadingSuccessMutationName: FILTER_CONSISTENCY_RAPORT_LOADING_SUCCESS,
  loadingErrorMutationName: FILTER_CONSISTENCY_RAPORT_LOADING_ERROR,
  invalidMutationName: FILTER_CONSISTENCY_RAPORT_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/filter/filterAnalyser/consistency",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
