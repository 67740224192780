// special characters: [\^$.|?*+(){}

export const NAME_REGEXP = "^[-0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ _\\.+]+$";
export const TEXT_REGEXP = "^[-0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ :=,&_!@()/\\.+]+$";
export const URL_REGEXP = "^[-0-9a-zA-Z:=,&_!@/\\.\\?\\$\\*+]+$";
export const SMS_REGEXP = "^[0-9a-zA-Z *.%(),:;\\-!?]+$";
export const LABEL_REGEXP = "^[0-9a-zA-Z]+$";
export const EMAIL_REGEXP = "^[-0-9a-zA-Z_\\.]+@[-0-9a-zA-Z_\\.]+$";
export const QUESTION_REGEXP = "^[-0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ :=,&_!@/\\.+?%]+$";
// export const URL_REGEXP = '^[-0-9a-zA-Z_!/:@=&$.?*+]+$'
// export const JSON_REGEXP = "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .+\\[\\\\\^\\$\\.\\|\\?\\*\\+\\(\\)]+)$"
