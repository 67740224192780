<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 v-if="!editMode" class="card-title">Nowy zakres</h4>
        <h4 v-if="editMode" class="card-title">Edycja zakresu</h4>
      </div>
      <div class="card-body">

        <div class="form-group">
          <label>Nazwa systemu</label>
          <fg-input :error="getError('system_id')" data-vv-as="Nazwa systemu">
            <el-select
              v-model="model.system_id"
              name="system_name"
              data-vv-as="Nazwa systemu"
              v-validate="modelValidations.system_id"
              filterable
            >
              <el-option
                v-for="system in systems"
                :label="system.system_name"
                :value="system.system_id"
                :key="system.system_id"
              ></el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="form-group">
          <label>Typ zakresu</label>
          <fg-input :error="getError('scope_type_id')" data-vv-as="Typ zakresu">
            <el-select
              v-model="model.scope_type_id"
              name="scope_type_id"
              data-vv-as="Typ zakresu"
              v-validate="modelValidations.scope_type_id"
              filterable
            >
              <el-option
                v-for="scope_type in scope_types"
                :label="scope_type.scope_type_name"
                :value="scope_type.scope_type_id"
                :key="scope_type.scope_type_id"
              ></el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="form-group">
          <label>Nazwa partnera</label>
          <fg-input :error="getError('custom_id')" data-vv-as="Nazwa partnera">
            <el-select
              v-model="model.custom_id"
              name="custom_id"
              data-vv-as="Nazwa partnera"
              v-validate="modelValidations.custom_id"
              filterable
            >
              <el-option
                v-for="partner in partners"
                :label="partner.partner_name"
                :value="partner.partner_id"
                :key="partner.partner_id"
              ></el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="form-group">
          <label>Opis</label>
          <fg-input
            type="text"
            name="scope_description"
            data-vv-as="Opis"
            v-validate="modelValidations.scope_description"
            :error="getError('scope_description')"
            v-model="model.scope_description"
          ></fg-input>
        </div>


        <div class="form-group">
          <label>Aktywny</label>
          <fg-input>
            <el-switch v-model="model.scope_is_active" active-value="true" inactive-value="false"></el-switch>
          </fg-input>
        </div>

      </div>
      <div class="card-footer text-center">
        <router-link to="/admin/scopes">
          <p-button type="default" link style="margin-right: 5vw">
            Anuluj
          </p-button>
        </router-link>
        <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Select, Option, Switch } from "element-ui";

import { SAVE_SCOPE, LOAD_SCOPES, SCOPES_INVALID } from "src/store/actions/admin/scopes";
import { LOAD_SYSTEMS } from "src/store/actions/admin/systems";
import { LOAD_SCOPE_TYPES } from "src/store/actions/admin/scope_types";
import { LOAD_PARTNERS, PARTNERS_INVALID } from "src/store/actions/admin/partners";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  data() {
    return {
      model: {
        scope_id: null,
        system_id: null,
        scope_type_id: null,
        custom_id: null,
        scope_name: "",
        scope_description: "",
        scope_is_active: "true"
      },
      modelValidations: {
        scope_id: {
          numeric: true,
          min: 1
        },
        system_id: {
          numeric: true,
          min: 1
        },
        scope_type_id: {
          numeric: true,
          min: 1
        },
        custom_id: {
          numeric: true,
          min: 1
        },
        scope_name: {
          required: true,
          // regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .-]+)$",
          regex: regexp.NAME_REGEXP,
          min: 3,
          max: 32
        },
        scope_description: {
          // regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ .-]+)$",
          regex: regexp.TEXT_REGEXP,
          max: 128
        },
      },
      editMode: true
    };
  },
  computed: {
    scope_id() {
      return this.$route.params.id;
    },
    systems() {
      // table data needs to by an array
      return Object.values(this.$store.getters.getSystems);
    },
    partners() {
      // table data needs to by an array
      return Object.values(this.$store.getters.getPartners);
    },
    scope_types() {
      // table data needs to by an array
      return Object.values(this.$store.getters.getScopeTypes);
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch(LOAD_SYSTEMS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      this.$store.dispatch(LOAD_SCOPE_TYPES, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      this.$store.dispatch(LOAD_PARTNERS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll()

      if (isValid) {
        await this.$store.dispatch(SAVE_SCOPE, this.model).catch(error => {
          let message = ''
          console.log(error.response)
          switch(error.response.data.code) {
            case 409:
              message = 'Nazwy muszą być unikalne!'
              break;
            default:
              message = "Błąd zapisu danych!"
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning"
          });
        })

        if (this.$store.getters.getScopesModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
            type: "success"
          });
        this.$store.commit(SCOPES_INVALID) // oznacz nieaktualność danych
        this.$router.push({ name: "Zakresy" }) // przekieruj do listy
        }
      }
    }
  },
  created() {
    this.loadData();
    if (!!this.scope_id) {
      let scope = this.$store.getters.getScopes[this.scope_id];
      this.model = Object.assign(this.model, scope);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  }
};
</script>
<style>
</style>


