<template>
  <div>
    <div class="row">
      <div class="col-12">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="Dodaj do serwis" name="ConsentPartnersService">
            <appConsentPartnersService :activeName="activeName"></appConsentPartnersService>
          </el-tab-pane>
          <el-tab-pane label="Zmień na wszystkich" name="AddToAllPartnersService">
            <appAddToAllPartnersService :activeName="activeName"></appAddToAllPartnersService>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>

import ConsentPartnersService from "@/components/Utils4/consents/forms/ConsentPartnersService.vue";
import AddToAllPartnersService from "@/components/Utils4/consents/forms/AddToAllPartnersService.vue";
import {Tabs,TabPane} from "element-ui";
import {eventBus} from "@/main";

export default {
  components: {
    appConsentPartnersService: ConsentPartnersService,
    appAddToAllPartnersService:AddToAllPartnersService,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
  data() {
    return {
      activeName: 'ConsentPartnersService'
    }
  },
  methods: {
    handleClick(tab, event) {
      eventBus.$emit('reload', true);
    }
  },
  async created() {
  }
}
</script>
