<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Koszty display</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <flat-pickr class="flatPickr" v-model="pickrWeek" :config="config" placeholder="Wybierz tydzień" v-on:on-change="loadData"></flat-pickr>
      </div>
    </div>

    <div class="card" style="margin-top: 10px">
      <h6>
        <div class="card-header weekHeader" style="display: flex; justify-content: space-between">
          <span>Przedział dat:</span> <span>{{ week === null ? "Wybierz tydzień" : dateFromTitle + " - " + dateToTitle }}</span>
        </div>
      </h6>
    </div>

    <div class="card">
      <div class="card-body">
        <el-table :data="queriedData" @sort-change="sortChange" style="width: 100%">
          <el-table-column label="Banner ID" min-width="100" property="banner_id" sortable="custom"></el-table-column>
          <el-table-column label="Nazwa" min-width="200" property="banner_name" sortable="custom"></el-table-column>
          <el-table-column label="Od" min-width="100" property="date_from" sortable="custom"></el-table-column>
          <el-table-column label="Do" min-width="100" property="date_to" sortable="custom"></el-table-column>
          <el-table-column label="Koszt" min-width="100" property="cost" sortable="custom" align="right">
            <template v-slot="props">
              {{ props.row.cost | formatCurrency }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="140" v-if="editPermitted && loadedWeek">
            <template slot="header">
              <el-tooltip class="item" effect="light" content="Dodaj koszt" placement="top" :open-delay="500">
                <p-button type="success" @click="showModal('add', {})" icon round style="margin: 0 5px 0 5px">
                  <i class="fa fa-plus"></i>
                </p-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="Usuń koszty dla tego tygodnia" placement="top" :open-delay="500">
                <p-button type="danger" @click="handleDelete('week', {})" icon round style="margin: 0 5px 0 5px">
                  <i class="fa fa-trash"></i>
                </p-button>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" content="Edytuj koszt" placement="top" :open-delay="500">
                <p-button type="success" size="sm" icon style="margin: 0 5px 0 5px" @click="showModal('edit', props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="Usuń koszt" placement="top" :open-delay="500">
                <p-button type="danger" size="sm" icon style="margin: 0 5px 0 5px" v-if="deletePermitted" @click="handleDelete('banner', props.row)">
                  <i class="fa fa-trash"></i>
                </p-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div class="card-body row">
          <div class="pagination-info col-sm-6">
            <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>

      <div class="card-body" v-if="editPermitted">
        <div class="row">
          <div class="col-sm-12">
            <span class="btn btn-default btn-file"
              >Wybierz plik
              <input type="file" class="inputfile" id="file" ref="file" v-on:change="handleFileUpload" />
            </span>
            <button v-if="file_name != ''" class="btn btn-primary" v-on:click="submitFile()">Wyślij</button>
            <span v-if="file_name != ''"> Plik: {{ file_name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import Swal from "sweetalert2";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";
import { FIRST_DAY_OF_WEEK } from "src/config";

import { Tooltip } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";

import { DISPLAY_COSTS_INVALID, LOAD_DISPLAY_COSTS, DELETE_DISPLAY_COSTS } from "src/store/actions/secure/display_costs";
import { SAVE_DISPLAY_COSTS_UPLOAD, DISPLAY_COSTS_UPLOAD_INVALID } from "src/store/actions/secure/display_costs_upload";

import AmountModal from "src/components/Utils4/sales/views/AmountModal.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Polish } from "flatpickr/dist/l10n/pl.js";
import weekSelect from "flatpickr/dist/plugins/weekSelect/weekSelect.js";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    PPagination,
    flatPickr,
  },
  data() {
    return {
      loading: false,
      loadedWeek: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      file: "",
      file_name: "",
      searchQuery: "",
      pickrWeek: null,
      config: {
        locale: {
          ...Polish,
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
        plugins: [new weekSelect({})],
        altInput: true,
        altInputClass: "flatPickr",
        altFormat: "T\\y\\dz\\ień W",
      },
    };
  },
  methods: {
    handleFileUpload(e) {
      // Handles a change on the file upload
      var files = e.target.files;
      if (!files.length) return;
      this.file = files[0];
      this.file_name = this.file.name;
    },
    async submitFile() {
      if (!this.file) {
        eventBus.$emit("notify", {
          message: "Nie wybrano pliku",
          type: "warning",
        });
        return;
      }

      if (this.file.size > 1024 * 1024) {
        eventBus.$emit("notify", {
          message: "Plik jest za duży (> 1MB)",
          type: "warning",
        });
        return;
      }

      let allowed_extensions = new Array("csv");
      let file_extension = this.file.name.split(".").pop().toLowerCase();

      if (!allowed_extensions.includes(file_extension)) {
        eventBus.$emit("notify", {
          message: "Niedozwolone rozszerzenie",
          type: "warning",
        });
        return; // invalid file extension
      }

      // Initialize the form data
      let formData = new FormData();
      // Add the form data we need to submit
      formData.append("file", this.file);

      await this.$store.dispatch(SAVE_DISPLAY_COSTS_UPLOAD, formData).catch((error) => {
        // wyślij pliki na serwer
        let message = "";
        console.log(error.response);
        switch (error.response.data.code) {
          case 406:
            message = "Nieprawidłowa struktura pliku!";
            break;
          case 460:
            message = "Nieprawidłowy zakres czasowy!";
            break;
          default:
            message = "Błąd zapisu danych!";
        }
        eventBus.$emit("notify", {
          message: message,
          type: "warning",
        });
      });

      if (this.$store.getters.getDisplayCostsUploadModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Dane zostały poprawnie zapisane",
          type: "success",
        });

        this.file = "";
        this.file_name = "";
      }

      if (this.week !== null) {
        this.loadData();
      }
    },
    showModal(mode, row) {
      // wyświetlenie modala
      this.$modal.show(
        AmountModal,
        {
          mode: mode,
          row: row,
          tableWeek: this.week,
          parent: "displayCost",
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(AmountModal);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "400px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    async loadData() {
      if (this.week === null) {
        eventBus.$emit("notify", {
          message: "Należy wybrać tydzień!",
          type: "warning",
        });
        return;
      }
      this.loadedWeek = true;
      this.loading = true;

      const filters = {
        date_from: moment(this.dateFrom).format("YYYY-MM-DD"),
        date_to: moment(this.dateTo).format("YYYY-MM-DD"),
      };

      this.$store.commit(DISPLAY_COSTS_INVALID);

      await this.$store.dispatch(LOAD_DISPLAY_COSTS, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    handleDelete(mode, row) {
      var varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: mode === "week" ? "Czy chcesz usunąć rekordy dla tego tygodnia?" : "Czy chcesz usunąć ten koszt?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            let model = {};

            if (mode === "week") {
              model = {
                mode: mode,
                id: null,
                banner_id: null,
                date_from: moment(varThis.dateFrom).format("YYYY-MM-DD"),
                date_to: moment(varThis.dateTo).format("YYYY-MM-DD"),
                cost: null,
              };
            } else {
              model = {
                mode: mode,
                id: row.id,
                banner_id: row.banner_id,
                date_from: row.date_from,
                date_to: row.date_to,
                cost: row.cost,
              };
            }
            varThis.deleteData(model);
          }
        })
        .catch(() => {
          return;
        });
    },
    async deleteData(model) {
      await this.$store.dispatch(DELETE_DISPLAY_COSTS, model).catch((error) => {
        // usunięcie kosztu
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getDisplayCostsDeletingStatus === "deleted") {
        // wyświetlenie informacji o zapisaniu
        eventBus.$emit("notify", {
          message: "Rekord został usunięty",
          type: "success",
        });

        this.loadData();
      }
    },
    sortChange(obj) {
      if (obj.order === "descending") {
        if (obj.prop === "banner_name" || obj.prop === "date_from" || obj.prop === "date_to") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (obj.prop === "banner_name" || obj.prop === "date_from" || obj.prop === "date_to") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        this.tableData.sort((a, b) => b.id - a.id);
      }
      this.searchQuery += " ";
      this.searchQuery = this.searchQuery.slice(0, -1);
    },
  },
  computed: {
    tableData() {
      if (this.$store.state.display_costs.loading_status === "success") {
        const getter = this.$store.getters.getDisplayCosts;
        let tableData = Object.values(getter);
        return tableData;
      } else {
        return [];
      }
    },
    week() {
      let resultDate = null;
      if (this.pickrWeek) {
        let resultDate = new Date(this.pickrWeek);
        const selectedDay = resultDate.getDay();

        // 0: -4, 1: -5, 2: +1, 3: 0, 4: -1, 5: -2, 6: -3
        if (selectedDay >= 2) {
          resultDate.setDate(resultDate.getDate() + (3 - selectedDay));
        } else {
          resultDate.setDate(resultDate.getDate() + (-4 - selectedDay));
        }

        return resultDate;
      }

      return resultDate;
    },
    dateFrom() {
      let firstDay = null;
      if (this.week !== null) {
        firstDay = new Date(this.week.getTime());
        firstDay.setDate(firstDay.getDate() - 1);
      }
      return firstDay;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateFrom).format("dddd, Do MMMM YYYY");
    },
    dateTo() {
      let lastDay = null;
      if (this.week !== null) {
        lastDay = new Date(this.week.getTime());
        lastDay.setDate(lastDay.getDate() + 5);
      }
      return lastDay;
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateTo).format("dddd, Do MMMM YYYY");
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      // paginacja danych
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    extensionsCheck() {
      if (this.file) {
        let allowed_extensions = new Array("csv");
        let file_extension = this.file.name.split(".").pop().toLowerCase();

        if (!allowed_extensions.includes(file_extension)) {
          return false; // invalid file extension
        } else {
          return true; // proper file extension
        }
      } else {
        return false;
      }
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_DISPLAY_COSTS);
    },
    deletePermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_DELETE_DISPLAY_COSTS);
    },
  },
  mounted() {
    eventBus.$on("displayCostsSaved", (week) => {
      // zapis kosztów
      this.week = week;
      this.loadData();
    });
  },
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    this.$store.commit(DISPLAY_COSTS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
/* stylowanie przycisku do wczytania pliku */
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.weekHeader {
  padding: 15px 15px 5px !important;
}
</style>
