import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_PBR,
  PBR_LOADING,
  PBR_LOADING_SUCCESS,
  PBR_LOADING_ERROR,
  PBR_INVALID,
  SAVE_PBR,
  PBR_SAVING,
  PBR_SAVING_SUCCESS,
  PBR_SAVING_ERROR,
  DELETE_PBR,
  PBR_DELETING,
  PBR_DELETING_SUCCESS,
  PBR_DELETING_ERROR,
} from "src/store/actions/mailings/pbr";

const storeObjectConfig = {
  objectName: "Pbr",
  getterName: "getPbr",
  loadActionName: LOAD_PBR,
  loadingMutationName: PBR_LOADING,
  loadingSuccessMutationName: PBR_LOADING_SUCCESS,
  loadingErrorMutationName: PBR_LOADING_ERROR,
  invalidMutationName: PBR_INVALID,
  saveActionName: SAVE_PBR,
  savingMutationName: PBR_SAVING,
  savingSuccessMutationName: PBR_SAVING_SUCCESS,
  savingErrorMutationName: PBR_SAVING_ERROR,
  deleteActionName: DELETE_PBR,
  deletingMutationName: PBR_DELETING,
  deletingSuccessMutationName: PBR_DELETING_SUCCESS,
  deletingErrorMutationName: PBR_DELETING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/mailings/pbr",
    method: "post", // {'get','post','put'}
  },
  saveParams: {
    url: "/mailings/pbr",
    method: "put", // {'get','post','put'}
  },
  deleteParams: {
    url: "/mailings/pbr",
    method: "delete", // {'delete'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
