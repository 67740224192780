import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_DEMOGRAPHY_STATS,
  DEMOGRAPHY_STATS_LOADING,
  DEMOGRAPHY_STATS_LOADING_SUCCESS,
  DEMOGRAPHY_STATS_LOADING_ERROR,
  DEMOGRAPHY_STATS_INVALID,
} from "src/store/actions/stats/demography_stats";

const storeObjectConfig = {
  objectName: "DemographyStats",
  getterName: "getDemographyStats",
  loadActionName: LOAD_DEMOGRAPHY_STATS,
  loadingMutationName: DEMOGRAPHY_STATS_LOADING,
  loadingSuccessMutationName: DEMOGRAPHY_STATS_LOADING_SUCCESS,
  loadingErrorMutationName: DEMOGRAPHY_STATS_LOADING_ERROR,
  invalidMutationName: DEMOGRAPHY_STATS_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/stats/demographyStats",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
