import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_DISPLAY_COSTS_UPLOAD,
  DISPLAY_COSTS_UPLOAD_LOADING,
  DISPLAY_COSTS_UPLOAD_LOADING_SUCCESS,
  DISPLAY_COSTS_UPLOAD_LOADING_ERROR,
  DISPLAY_COSTS_UPLOAD_INVALID,
  SAVE_DISPLAY_COSTS_UPLOAD,
  DISPLAY_COSTS_UPLOAD_SAVING,
  DISPLAY_COSTS_UPLOAD_SAVING_SUCCESS,
  DISPLAY_COSTS_UPLOAD_SAVING_ERROR
} from 'src/store/actions/secure/display_costs_upload'

const storeObjectConfig = {
  objectName: "DisplayCosts",
  getterName: "getDisplayCostsUpload",
  loadActionName: LOAD_DISPLAY_COSTS_UPLOAD,
  loadingMutationName: DISPLAY_COSTS_UPLOAD_LOADING,
  loadingSuccessMutationName: DISPLAY_COSTS_UPLOAD_LOADING_SUCCESS,
  loadingErrorMutationName: DISPLAY_COSTS_UPLOAD_LOADING_ERROR,
  invalidMutationName: DISPLAY_COSTS_UPLOAD_INVALID,
  saveActionName: SAVE_DISPLAY_COSTS_UPLOAD,
  savingMutationName: DISPLAY_COSTS_UPLOAD_SAVING,
  savingSuccessMutationName: DISPLAY_COSTS_UPLOAD_SAVING_SUCCESS,
  savingErrorMutationName: DISPLAY_COSTS_UPLOAD_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: 'binary',
  loadParams: {
    url: "",
    method: "" // {'get','post','put'}
  },
  saveParams: {
    url: "/raports/displayCosts/csv_data",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
