<template>
  <div class="card">
    <form>
      <div class="card-header">
        <h4 v-if="!editMode" class="card-title">Nowa baza danych</h4>
        <h4 v-if="editMode" class="card-title">Edycja bazy danych</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Nazwa partnera</label>
          <fg-input :error="getError('partner_name')" data-vv-as="Nazwa partnera">
            <el-select
              v-model="model.partner_name"
              name="Nazwa partnera"
              data-vv-as="Nazwa partnera"
              v-validate="modelValidations.partner_name"
              filterable
              :disabled="editMode"
            >
              <el-option
                v-for="partner in partners"
                :label="partner.partner_name"
                :value="partner.partner_id"
                :key="partner.partner_id"
              ></el-option>
            </el-select>
          </fg-input>
        </div>

        <div class="form-group">
          <label>Nazwa bazy danych</label>
          <fg-input
            type="text"
            name="database_name"
            data-vv-as="Nazwa bazy danych"
            v-validate="modelValidations.database_name"
            :error="getError('database_name')"
            v-model="model.database_name"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Opis bazy danych</label>
          <fg-input
            type="text"
            name="description"
            data-vv-as="Opis bazy danych"
            v-validate="modelValidations.description"
            :error="getError('description')"
            v-model="model.description"
          ></fg-input>
        </div>

        <div class="form-group">
          <label>Aktywny</label>
          <fg-input>
            <el-switch v-model="model.active" active-value="true" inactive-value="false"></el-switch>
          </fg-input>
        </div>

      </div>
      <div class="card-footer text-center">
        <router-link to="/admin/databases">
          <p-button type="default" link style="margin-right: 5vw">
            Anuluj
          </p-button>
        </router-link>
        <p-button v-if="!editMode" type="success" @click.prevent="saveData">Utwórz</p-button>
        <p-button v-if="editMode" type="success" @click.prevent="saveData">Aktualizuj</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import { Select, Option, Switch } from "element-ui";

import { LOAD_PARTNERS } from "src/store/actions/admin/partners";
import { SAVE_DATABASE, DATABASES_INVALID } from "src/store/actions/admin/databases";
import * as regexp from "src/regexp.js";
import { eventBus } from "src/main";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch
  },
  data() {
    return {
      model: {
        database_id: null,
        partner_id: null,
        database_name: "",
        description: "",
        active: 'true'
      },
      modelValidations: {
        database_id: {
          numeric: true,
          min: 1
        },
        partner_id: {
          numeric: true,
          min: 1
        },
        database_name: {
          required: true,
          regex: regexp.NAME_REGEXP,
          min: 3,
          max: 32
        },
        description: {
          regex: regexp.TEXT_REGEXP,
          max: 128
        },
        active: {
          required: true,
          included: ['true', 'false']
        }
      },
      editMode: true
    };
  },
  computed: {
    database_id() {
      return this.$route.params.id;
    },
    partners() {
      return Object.values(this.$store.getters.getPartners);
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch(LOAD_PARTNERS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll()

      if (isValid) {
        await this.$store.dispatch(SAVE_DATABASE, this.model).catch(error => {
          let message = ''
          console.log(error.response)
          switch(error.response.data.code) {
            case 409:
              message = 'Nazwy muszą być unikalne!'
              break;
            default:
              message = "Błąd zapisu danych!"
          }
          eventBus.$emit("notify", {
            message: message,
            type: "warning"
          });
        })

        if (this.$store.getters.getDatabasesModifiedRecords.affected_rows > 0) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.editMode ? "zmodyfikowany" : "dodany"),
            type: "success"
          });

          this.$store.commit(DATABASES_INVALID) // oznacz nieaktualność danych
          this.$router.push({ name: "Bazy danych" }) // przekieruj do listy
        }
      }
    }
  },
  created() {
    this.loadData();
    if (!!this.database_id) {
      let database = this.$store.getters.getDatabases[this.database_id];
      this.model = Object.assign(this.model, database);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  }
};
</script>
<style>
</style>


