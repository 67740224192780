<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <h5 id="filterAnalyserTitle" class="title">Walidator sprzedażowy</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <fg-input
                  class="input-sm"
                  id="filterAnalyserSearchInput"
                  placeholder="Szukaj"
                  v-model="searchQuery"
                  addon-right-icon="nc-icon nc-zoom-split"
                >
                </fg-input>
              </div>
            </div>
            <el-table :data="queriedData" style="width: 100%">
              <el-table-column label="Paczka" width="100" property="id">
                <template v-slot="props">
                  <span>{{ props.row.id }}</span>
                  <el-tooltip class="item" effect="light" content="Sprawdź spójność paczki" placement="top" :open-delay="500">
                    <el-badge
                      v-if="props.row.consistency_status"
                      :style="props.row.consistency_status === 'ERROR' ? 'color: #dc3545;' : 'color: #ffc107;'"
                      class="item consistency_badge"
                      type="warning"
                      @click="showConsistencyModal(props.row)"
                    >
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    </el-badge>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="Klient" min-width="150" align="left">
                <template v-slot="props">
                  {{ `[${props.row.client_id}] ${props.row.client}` }}
                </template>
              </el-table-column>
              <el-table-column label="Rekordy" width="120" align="right">
                <template v-slot="props">
                  <div v-if="props.row.package_limit == null">{{ props.row.records | formatNumber }}</div>
                  <div v-else-if="props.row.package_limit != props.row.records">
                    <el-tooltip class="item" effect="light" :content="'Limit: ' + props.row.package_limit" placement="top" :open-delay="500">
                      <span>{{ props.row.records | formatNumber }}</span>
                    </el-tooltip>
                  </div>
                  <div v-else>
                    <el-tooltip class="item" effect="light" :content="'Limit: ' + props.row.package_limit" placement="top" :open-delay="500">
                      <span>{{ "[" + formatNumber(props.row.records) + "]" }}</span>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Walidator" width="130" align="right">
                <template v-slot="props">
                  <div style="padding: 12px 0px">
                    <span>{{ props.row.records_valid !== null ? formatNumber(props.row.records_valid) : "-" }}</span>
                    <el-badge value="!" class="item" type="warning" v-if="badgeAlert(props.row)"></el-badge>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Sprzedaże" width="130" align="right">
                <template v-slot="props">
                  {{ formatNumber(props.row.sold ? props.row.sold : 0) }}
                </template>
              </el-table-column>
              <el-table-column label="Odrzucone" width="140" align="right">
                <template v-slot="props">
                  {{ formatNumber(props.row.rejected ? props.row.rejected : 0) }}
                </template>
              </el-table-column>
              <el-table-column label="Data" width="180">
                <template v-slot="props">
                  {{ props.row.fp_date }}
                </template>
              </el-table-column>
              <el-table-column label="Autor" width="180">
                <template v-slot="props">
                  {{ getUserName(props.row.system_user_id) }}
                </template>
              </el-table-column>
              <el-table-column align="right" width="140">
                <template v-slot="props">
                  <el-tooltip class="item" effect="light" content="Pokaż szczegóły" placement="top" :open-delay="500">
                    <p-button
                      style="margin: 0 2px;"
                      type="success"
                      size="sm"
                      icon
                      @click="showDetailsModal(props.row.id, props.row.client_id, props.row.client)"
                    >
                      <i class="fa fa-info" aria-hidden="true"></i>
                    </p-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="light" :content="getAnalyseButtonTooltip(props.row)" placement="top" :open-delay="500">
                    <p-button style="margin: 0 2px;" :type="getAnalyseButtonColor(props.row)" size="sm" icon @click="handleAnalyseButton(props.row)">
                      <i :class="getAnalyseButtonClass(props.row)" aria-hidden="true"></i>
                    </p-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="light" content="Raport spójności" placement="top" :open-delay="500">
                    <p-button style="margin: 0 2px;" type="success" size="sm" icon @click="showConsistencyModal(props.row)">
                      <i class="fa fa-cogs" aria-hidden="true"></i>
                    </p-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-body row">
            <div class="col-sm-6 pagination-info">
              <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
// ui components
import { Tag, Tooltip, Badge } from "element-ui";
import BackToTop from "vue-backtotop";
import PPagination from "src/components/UIComponents/Pagination.vue";

import FilterAnalyserModal from "src/components/Utils4/filter/modals/FilterAnalyserModal.vue";
import FilterDetailsModal from "src/components/Utils4/filter/modals/FilterDetailsModal.vue";
import FilterConsistencyModal from "src/components/Utils4/filter/modals/FilterConsistencyModal.vue";

// vuex actions
import { LOAD_FILTER_ANALYSER, FILTER_ANALYSER_INVALID } from "src/store/actions/filter/filter_analyser";
import { LOAD_FILTER_PANEL_VALIDATOR, FILTER_PANEL_VALIDATOR_INVALID } from "src/store/actions/filter/filter_panel_validator";
import { LOAD_USERS_LIST, USERS_LIST_INVALID } from "src/store/actions/admin/users_list";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Tag.name]: Tag,
    [Tooltip.name]: Tooltip,
    [Badge.name]: Badge,
    PPagination,
    BackToTop,
  },
  data() {
    return {
      loading: false,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["id", "client"],
    };
  },
  computed: {
    usersList() {
      return this.$store.getters.getUsersList;
    },
    filterAnalyserData() {
      return this.$store.getters.getFilterAnalyser;
    },
    tableData() {
      return Object.values(this.filterAnalyserData).sort((a, b) => b.id - a.id);
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData.filter(row => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key]) {
            let rowValue = row[key].toString();
            let rowValueLowerCase = row[key].toString().toLowerCase();
            if (
              (rowValue.includes && rowValue.includes(this.searchQuery)) ||
              (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
            ) {
              isIncluded = true;
            }
          }
        }

        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(USERS_LIST_INVALID);
      }

      this.loading = true;

      this.$store.commit(FILTER_ANALYSER_INVALID);

      this.$store.dispatch(LOAD_USERS_LIST, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_FILTER_ANALYSER, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {},
    showDetailsModal(package_id, client_id, client) {
      // wyświetlenie modala szczegółów
      this.$modal.show(
        FilterDetailsModal,
        {
          package_id: package_id,
          client_id: client_id,
          client: client,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(FilterDetailsModal);
              },
            },
          ],
        },
        {
          draggable: false,
          name: "FilterDetailsModal",
          scrollable: true,
          width: "50%",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    showChartModal(row) {
      // wyświetlenie modala analizy
      this.$modal.show(
        FilterAnalyserModal,
        {
          row: row,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(FilterAnalyserModal);
              },
            },
          ],
        },
        {
          draggable: false,
          name: "FilterAnalyserModal",
          scrollable: true,
          width: "80%",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    showConsistencyModal({ id, client_id, client, consistency_start_time }) {
      // wyświetlenie modala spójności
      this.$modal.show(
        FilterConsistencyModal,
        {
          package_id: id,
          client_id: client_id,
          client: client,
          consistency_start_time: consistency_start_time,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(FilterConsistencyModal);
              },
            },
          ],
        },
        {
          draggable: false,
          name: "FilterConsistencyModal",
          scrollable: true,
          width: "80%",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    formatNumber(number, decimals = 0) {
      let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number)
        .toFixed(decimals)
        .split(".");
      n[0] = n[0]
        .split("")
        .reverse()
        .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
        .reverse()
        .join("");
      let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

      return final;
    },
    getUserName(user_id) {
      if (this.usersList[user_id]) {
        return this.usersList[user_id].user_name;
      } else {
        return user_id;
      }
    },
    getAnalyseButtonClass(row) {
      if (row.fa_running || row.sa_running || row.dd_running) {
        return "fa fa-cogs";
      }
      if (row.fa_done && row.sa_done && row.dd_done) {
        return "fa fa-bar-chart";
      }
      return "fa fa-play";
    },
    getAnalyseButtonTooltip(row) {
      if (row.fa_running || row.sa_running || row.dd_running) {
        return "Analiza w trakcie wykonywania";
      }
      if (row.fa_done && row.sa_done && row.dd_done) {
        return "Analiza gotowa do wyświetlenia";
      }
      return "Zleć analizę do wykonania";
    },
    getAnalyseButtonColor(row) {
      if (row.fa_running || row.sa_running || row.dd_running) {
        return "warning";
      }
      if (row.fa_done && row.sa_done && row.dd_done) {
        return "success";
      }
      return "primary";
    },
    handleAnalyseButton(row) {
      if (row.fa_running || row.sa_running || row.dd_running) {
        this.loadData();
      } else if (row.fa_done && row.sa_done && row.dd_done) {
        this.showChartModal(row);
      } else {
        this.createJobForAnalyse(row, false);
      }
    },
    forceJob({ package_id }) {
      const row = this.filterAnalyserData[package_id];
      if (row.fa_done && row.sa_done && row.dd_done) {
        this.createJobForAnalyse(row, true);
      }
    },
    forceConsistencyJob({ package_id }) {
      const row = this.filterAnalyserData[package_id];
      if (row.consistency_done) {
        const filters = {
          filter_pack_id: package_id,
          action: "consistency",
        };

        this.$store.commit(FILTER_PANEL_VALIDATOR_INVALID);

        this.$store.dispatch(LOAD_FILTER_PANEL_VALIDATOR, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }

      this.loadData();
    },
    createJobForAnalyse(row, force) {
      if (force || !row.fa_done) {
        const filters = {
          filter_pack_id: row.id,
          action: "full-analyse",
        };

        this.$store.commit(FILTER_PANEL_VALIDATOR_INVALID);

        this.$store.dispatch(LOAD_FILTER_PANEL_VALIDATOR, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }

      if (force || !row.sa_done) {
        const filters = {
          filter_pack_id: row.id,
          action: "simple-analyse",
        };

        this.$store.commit(FILTER_PANEL_VALIDATOR_INVALID);

        this.$store.dispatch(LOAD_FILTER_PANEL_VALIDATOR, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }

      if (force || !row.dd_done) {
        const filters = {
          filter_pack_id: row.id,
          action: "dd-analyse",
        };

        this.$store.commit(FILTER_PANEL_VALIDATOR_INVALID);

        this.$store.dispatch(LOAD_FILTER_PANEL_VALIDATOR, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }

      if (force || !row.consistency_done) {
        const filters = {
          filter_pack_id: row.id,
          action: "consistency",
        };

        this.$store.commit(FILTER_PANEL_VALIDATOR_INVALID);

        this.$store.dispatch(LOAD_FILTER_PANEL_VALIDATOR, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }

      this.loadData();
    },
    badgeAlert(row) {
      if (row.records_valid !== null) {
        if (row.package_limit === null || (row.package_limit !== null && row.package_limit !== row.records)) {
          if (Math.abs(row.records - row.records_valid) > 30) {
            return true;
          }
        }
      }
      return false;
    },
  },
  mounted() {
    eventBus.$on("categorySaved", () => {
      // zapis do obiektu zmian kategorii
      this.loadData();
    });
    eventBus.$on("forceFilterJob", package_id => {
      this.forceJob(package_id);
    });
    eventBus.$on("forceConsistencyJob", package_id => {
      this.forceConsistencyJob(package_id);
    });
  },
  async created() {
    await this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy: function() {
    eventBus.$off("forceReload");
    eventBus.$off("forceFilterJob");
    eventBus.$off("forceConsistencyJob");
  },
};
</script>

<style lang="scss" scoped>
.parentCat {
  font-weight: bold;
  font-size: 1.2em;
}
.subcat {
  padding-left: 30px;
  font-style: italic;
}
.consistency_badge {
  padding-left: 5px;
  margin-bottom: 4px;
  cursor: pointer;
}
</style>
