<template>
  <div class="row">
    <div class="col-xl-12 col-sm-12">
      <div class="card">
        <div class="card-body action-item-list">
          <el-dropdown size="small" trigger="click" @command="emitHandleMacroAction" v-if="isMM">
            <el-button :class="'complaints-action-button ' + getActionClass()" size="small" type="plain"
              ><i class="fa fa-bolt complaints-action-icon" aria-hidden="true"></i
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="action in macro" :key="action.id" :command="action">{{ action.name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-tooltip class="item" effect="light" :content="searchDataTooltip" placement="top" :open-delay="500">
            <div :class="this.userData ? 'action-icon action-active' : 'action-icon'" @click="emitHandleUserData('search')">
              <i class="fa fa-search fa-lg" aria-hidden="true"></i>
              <div v-if="isMM" class="user_data_counter">{{ userDataCounter[this.activeComplaintID] }}</div>
            </div>
          </el-tooltip>

          <el-tooltip class="item" effect="light" :content="deleteDataTooltip" placement="top" :open-delay="500">
            <div :class="getActionClass('delete')" @click="emitHandleUserData('delete', 'phone')">
              <i :class="isMM ? 'fa fa-ban fa-lg' : 'fa fa-phone fa-lg'" aria-hidden="true"></i>
            </div>
          </el-tooltip>

          <el-tooltip v-if="!isMM" class="item" effect="light" content="Blacklista email" placement="top" :open-delay="500">
            <div :class="getActionClass('delete')" @click="emitHandleUserData('delete', 'email')">
              <i class="fa fa-at fa-lg" aria-hidden="true"></i>
            </div>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="light"
            :content="showResponseBool ? 'Anuluj udzielanie odpowiedzi' : 'Udziel odpowiedzi'"
            placement="top"
            :open-delay="500"
          >
            <div :class="getResponseActionClass()" @click="emitHandleResponse()">
              <i class="fa fa-envelope-o fa-lg fa-fw" aria-hidden="true"></i>
            </div>
          </el-tooltip>

          <el-tooltip class="item" effect="light" content="Przekaż do" placement="top" :open-delay="500">
            <div class="action-icon" @click="emitForwardComplaint()">
              <i class="fa fa-user-o fa-lg" aria-hidden="true"></i>
            </div>
          </el-tooltip>

          <el-tooltip class="item" effect="light" content="Dodaj komentarz" placement="top" :open-delay="500">
            <div class="action-icon" @click="emitHandleStatusChange('comment')">
              <i class="fa fa-comment-o fa-lg" aria-hidden="true"></i>
            </div>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="light"
            :content="statusID == 4 ? 'Przywróć reklamację' : 'Zawieś reklamację'"
            placement="top"
            :open-delay="500"
          >
            <div :class="getActionClass()" @click="emitHandleStatusChange('pause')">
              <i class="fa fa-clock-o fa-lg" :style="statusID == 4 ? 'color: green;' : ''" aria-hidden="true"></i>
            </div>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="light"
            :content="statusID == 6 ? 'Przywróć reklamację' : 'Zamknij reklamację'"
            placement="top"
            :open-delay="500"
          >
            <div :class="getActionClass('border')" @click="emitCloseComplaint()">
              <i v-if="statusID == 6" class="fa fa-undo fa-lg" aria-hidden="true"></i>
              <i v-else class="fa fa-times fa-lg" aria-hidden="true"></i>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// event bus
import { eventBus } from "src/main";

import { Tooltip, Dropdown, DropdownItem, DropdownMenu, Button } from "element-ui";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button,
  },
  props: [
    "editPermitted",
    "companyID",
    "macro",
    "userData",
    "userDataCounter",
    "activeComplaintID",
    "showResponseBool",
    "isManualComplaints",
    "statusID",
  ],
  methods: {
    emitHandleMacroAction(action) {
      eventBus.$emit("emitHandleMacroAction", action);
    },
    emitHandleUserData(mode, blacklistMode) {
      eventBus.$emit("emitHandleUserData", {
        mode,
        isMM: this.isMM,
        blacklistMode,
      });
    },
    emitHandleResponse() {
      eventBus.$emit("emitHandleResponse");
    },
    emitForwardComplaint() {
      eventBus.$emit("emitForwardComplaint");
    },
    emitCloseComplaint() {
      eventBus.$emit("emitCloseComplaint");
    },
    emitHandleStatusChange(mode) {
      eventBus.$emit("emitHandleStatusChange", {
        mode,
      });
    },
    getActionClass(mode) {
      let result;
      if (!this.editPermitted) {
        result = "action-icon action-disabled";
      } else {
        result = "action-icon";
      }
      if (mode === "border") result += " action-border";
      if (mode === "delete" && this.editPermitted) result += " action-delete";
      return result;
    },
    getResponseActionClass() {
      if (!this.editPermitted || this.isManualComplaints) {
        return "action-icon action-disabled";
      } else if (this.showResponseBool) {
        return "action-icon action-gray";
      } else {
        return "action-icon";
      }
    },
  },
  computed: {
    isMM() {
      if (this.companyID === 1) return true;
      return false;
    },
    searchDataTooltip() {
      if (this.isMM) return "Wyszukaj dane (rekordy)";
      return "Historia blacklisty SMS";
    },
    deleteDataTooltip() {
      if (this.isMM) return "Usuń dane / Wypisz";
      return "Blacklista SMS";
    },
  },
};
</script>

<style scoped>
.action-select {
  display: inline-block;
}

.action-item-list {
  display: flex;
  justify-content: space-between;
}

.action-icon {
  display: inline-block;
  justify-content: center;
  padding: 7px 15px;
  margin: 0 20px;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  position: relative;
}

.action-icon:hover {
  transition: background-color 200ms ease-in-out;
  background-color: #f7f7f7;
}

.action-active {
  color: #32cd32;
}

.action-gray {
  color: #b5b5b5;
}

.action-gray:hover {
  background-color: #b5b5b5;
  cursor: pointer;
}

.action-disabled {
  color: #b5b5b5 !important;
}

.action-disabled:hover {
  background-color: #fff !important;
  cursor: not-allowed;
}

.action-disabled:focus {
  background-color: #fff !important;
}

.action-delete {
  color: #c75656;
}

.action-delete:hover {
  transition: background-color 200ms ease-in-out;
  background-color: #ff6e6e;
}

.user_data_counter {
  position: absolute;
  top: 0;
  right: 0;

  font-size: 1em;
  font-weight: bold;
  color: #007bff;
}

.complaints-action-button {
  height: 100%;
  border: none;
  color: black;
}

.complaints-action-button:hover {
  height: 100%;
  border: none;
  color: black;
}

.complaints-action-button:focus {
  color: black;
  background-color: #f7f7f7;
}

.complaints-action-icon {
  font-size: 1.5em;
}
</style>
