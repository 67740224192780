<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 v-if="mode === 'add'" class="title">Dodaj nowy {{ parentTitle }}</h5>
          <h5 v-if="mode !== 'add'" class="title">Edytuj {{ parentTitle }}</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer; opacity: 0.5" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <el-form label-position="left" label-width="100px">
            <el-form-item label="Banner">
              <fg-input :error="getError('banner_id')" data-vv-as="BannerID">
                <el-select
                  v-model="model.banner_id"
                  v-validate="modelValidations.banner_id"
                  name="banner_id"
                  filterable
                  remote
                  reserve-keyword
                  clearable
                  :remote-method="remoteSearch"
                  :loading="loading"
                  :disabled="mode === 'edit'"
                  placeholder="Wyszukaj baner"
                >
                  <el-option
                    v-for="banner in bannerConfig"
                    :label="'[' + banner.BannerID + '] ' + banner.BannerName"
                    :value="banner.BannerID"
                    :key="banner.BannerID"
                  ></el-option>
                </el-select>
              </fg-input>
            </el-form-item>

            <el-form-item label="Tydzień">
              <flat-pickr class="flatPickr" v-model="pickrWeek" :config="config" placeholder="Wybierz tydzień"></flat-pickr>
              <p class="dateSubtitle">{{ dateFromTitle }} - {{ dateToTitle }}</p>
            </el-form-item>

            <el-form-item :label="parentTitleUppercase">
              <el-input-number
                name="amount"
                v-model="model.amount"
                v-validate="modelValidations.amount"
                :error="getError('amount')"
                :precision="2"
                :step="0.1"
                controls-position="right"
              ></el-input-number>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="row">
        <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library
// ui components
import { Input, Select, Option, Tooltip, InputNumber, DatePicker, Form, FormItem } from "element-ui";

// vuex actions
import { LOAD_BANNER_CONFIG, BANNER_CONFIG_INVALID } from "src/store/actions/stats/banner_config";
import { SAVE_DISPLAY_COSTS, DISPLAY_COSTS_INVALID } from "src/store/actions/secure/display_costs";
import { SAVE_MAILING_COST, MAILING_COSTS_INVALID } from "src/store/actions/secure/mailing_costs";

// event bus
import { eventBus } from "src/main";
import { FIRST_DAY_OF_WEEK } from "src/config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Polish } from "flatpickr/dist/l10n/pl.js";
import weekSelect from "flatpickr/dist/plugins/weekSelect/weekSelect.js";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [InputNumber.name]: InputNumber,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    flatPickr,
  },
  props: ["parent", "mode", "row", "tableWeek"],
  data() {
    return {
      loading: false,
      model: {
        id: null,
        banner_id: null,
        date_from: "",
        date_to: "",
        amount: 0,
      },
      modelValidations: {
        id: {
          numeric: true,
          min: 1,
        },
        banner_id: {
          required: true,
          numeric: true,
          min: 1,
        },
        amount: {
          required: true,
        },
      },
      pickrWeek: null,
      config: {
        locale: {
          ...Polish,
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
        plugins: [new weekSelect({})],
        altInput: true,
        altInputClass: "flatPickr",
        altFormat: "T\\y\\dz\\ień W",
      },
    };
  },
  computed: {
    week() {
      let resultDate = null;
      if (this.pickrWeek) {
        let resultDate = new Date(this.pickrWeek);
        const selectedDay = resultDate.getDay();

        // 0: -4, 1: -5, 2: +1, 3: 0, 4: -1, 5: -2, 6: -3
        if (selectedDay >= 2) {
          resultDate.setDate(resultDate.getDate() + (3 - selectedDay));
        } else {
          resultDate.setDate(resultDate.getDate() + (-4 - selectedDay));
        }

        return resultDate;
      }

      return resultDate;
    },
    dateFrom() {
      let firstDay = null;
      if (this.week !== null) {
        firstDay = new Date(this.week.getTime());
        firstDay.setDate(firstDay.getDate() - 1);
      }
      return firstDay;
    },
    dateFromTitle() {
      return moment(this.dateFrom).format("DD");
    },
    dateTo() {
      let lastDay = null;
      if (this.week !== null) {
        lastDay = new Date(this.week.getTime());
        lastDay.setDate(lastDay.getDate() + 5);
      }
      return lastDay;
    },
    dateToTitle() {
      return moment(this.dateTo).format("DD.MM.YYYY");
    },
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
    parentTitle() {
      if (this.parent === "displayCost") {
        return "koszt";
      } else if (this.parent === "mailingCosts") {
        return "koszt";
      }
    },
    parentTitleUppercase() {
      return this.parentTitle[0].toUpperCase() + this.parentTitle.substring(1);
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, { searchQuery }).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        if (this.week === null) {
          eventBus.$emit("notify", {
            message: "Należy wybrać tydzień!",
            type: "warning",
          });
          return;
        }
        let model = this.model;
        model.date_from = moment(this.dateFrom).format("YYYY-MM-DD");
        model.date_to = moment(this.dateTo).format("YYYY-MM-DD");

        let saveAction, saveResult, invalidAction, busAction;

        if (this.parent === "displayCost") {
          model.cost = model.amount;
          saveAction = SAVE_DISPLAY_COSTS;
          invalidAction = DISPLAY_COSTS_INVALID;
          busAction = "displayCostsSaved";
        } else if (this.parent === "mailingCosts") {
          model.cost = model.amount;
          saveAction = SAVE_MAILING_COST;
          invalidAction = MAILING_COSTS_INVALID;
          busAction = "mailingCostSaved";
        }

        await this.$store.dispatch(saveAction, model).catch((error) => {
          console.log(error.response);
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.parent === "displayCost") {
          saveResult = this.$store.getters.getDisplayCostsModifiedRecords;
        } else if (this.parent === "mailingCosts") {
          saveResult = this.$store.getters.getMailingCostsModifiedRecords;
        }

        if (saveResult === true) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.mode === "edit" ? "zmodyfikowany" : "dodany"),
            type: "success",
          });

          this.$store.commit(invalidAction); // oznacz nieaktualność danych
          eventBus.$emit(busAction, this.week);
          this.$emit("close");
        }
      }
    },
  },
  async created() {
    if (this.tableWeek) {
      this.pickrWeek = this.tableWeek;
    }
    if (this.mode === "edit") {
      this.model = Object.assign({}, this.row);
      if (this.parent === "displayCost") {
        this.model.amount = this.model.cost;
      } else if (this.parent === "mailingCosts") {
        this.model.amount = this.model.cost;
      }
      await this.searchBanners(this.model.banner_id);
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}
.dateSubtitle {
  margin: 5px 0;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
}
</style>
