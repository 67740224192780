import storeGen from '../../../store/storeGeneratorSymfony'

import {
    AUTH_LOGOUT
} from '../../../store/actions/auth'

import {
    BASE_API_MAILINGS
} from '../../config'

import { 
    LOAD_MAILINGS,
    GET_WORKING,
    MAILINGS_INVALID,
    MALINGS_WORK_LOADING_SUCCESS,
    MALINGS_WORK_LOADING_ERROR,
    SAVE_MALING,
    MALING_SAVING,
    MALING_SAVING_SUCCESS,
    MALING_SAVING_ERROR
    } from '../../actions/mailings/mailings-list';

const storeObjectConfig = {
    objectName: "Mailings",
    getterName: "getWorking",
    loadActionName: GET_WORKING,
    loadingMutationName: LOAD_MAILINGS,
    loadingSuccessMutationName: MALINGS_WORK_LOADING_SUCCESS,
    loadingErrorMutationName: MALINGS_WORK_LOADING_ERROR,
    invalidMutationName: MAILINGS_INVALID,
    saveActionName: SAVE_MALING,
    savingMutationName: MALING_SAVING,
    savingSuccessMutationName: MALING_SAVING_SUCCESS,
    savingErrorMutationName: MALING_SAVING_ERROR,
    logoutMutationName: AUTH_LOGOUT,
    apiUrl: BASE_API_MAILINGS,
    validPeriod: 10, // minutes
    recordType: 'json',
    loadParams: {
        url: "/campains",
        method: "get" // {'get','post','put'}
    },
    saveParams: {
        url: "/campains",
        method: "post" // {'get','post','put'}
    }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);

export default {
    state,
    getters,
    actions,
    mutations
}