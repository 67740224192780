<template>
  <div class="modal-wrapper" :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row header-row">
      <div class="col-sm-11">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="title">Wykresy dla kanału {{ channel + ' [' + channel_id +']' }}</h5>
          </div>
          <div class="col-sm-4 text-sm-right">
            <el-date-picker
              v-model="dateRange"
              v-on:input="loadData()"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="-"
              start-placeholder="Data początkowa"
              end-placeholder="Data końcowa"
              :picker-options="pickerOptions"
              :clearable="false"
              format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;  opacity: 0.5;" aria-hidden="true"></i>
    </div>

    <div class="row" v-if="loading">
      <div class="col-sm-12">
        <div class="center" style="font-size: 1.3em; padding: 30px;">
          Wczytywanie danych
        </div>
      </div>
    </div>
    <div v-if="ready">
      <div class="row" v-for="question in viewsChart" :key="question.question_id">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <p class="resolutionTitle">{{ resolutionTitle + ' ' + chartData.from + ' - ' + chartData.to }}</p>
            </template>
            <bar-chart :labels="question.labels"
                      :height="100"
                      :extra-options="question.options"
                      :datasets="question.datasets">
            </bar-chart>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from 'src/components/UIComponents'
import moment from "moment"; // time manipulation library
import BarChart from 'src/components/UIComponents/Charts/BarChart'
import { DatePicker, Select, Option } from "element-ui";

import { LOAD_STATUS_RAPORT_CHART_DATA, STATUS_RAPORT_CHART_DATA_INVALID } from "src/store/actions/secure/statusRaport/status_raport_chart_data";
// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

import * as priv from 'src/privileges.js';

const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
  };

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Card,
    BarChart,
  },
  data() {
    return {
      loading: false,
      yTicks: {
        min: 0,
        max: 2
      },
      resolution: '',
      dateRange: ['', ''],
    }
  },
  props: ['channel', 'channel_id', 'project_id', 'date'],
  computed: {
    chartData() {
      let chartData = this.$store.getters.getStatusRaportChartData;
      this.yTicks.max = 2;
      if(typeof chartData[this.channel_id] === 'object' && chartData[this.channel_id] !== null) {
        let data = chartData[this.channel_id];
        let leadsData = chartData[this.channel_id].Leads;
        let leadsChartData = [];
        let labels = [];
        for(let day in leadsData) {

          let dayData = leadsData[day];

          leadsChartData.push(dayData);
          labels.push(day);

          if(dayData > this.yTicks.max) { // określenie najwyższej wartości dla osi Y wykresu
            let divider = 10;
            if(dayData < 20) {
              divider = 2;
            } else if(dayData >= 20 && dayData < 50) {
              divider = 10;
            } else if(dayData >= 50 && dayData < 100) {
              divider = 20;
            } else divider = 50;
            this.yTicks.max = Math.ceil(dayData / divider) * divider;
          }
        }
        data.data = leadsChartData;
        data.labels = labels;
      }

      return chartData;
    },
    viewsChart() {
      let chartData = this.chartData;
      const channel_id = this.channel_id;
      let leads = {};

      if(typeof chartData[this.channel_id] === 'object' && chartData[this.channel_id] !== null) {
        let data = {};
        data.channel_id = this.channel_id;
        data.labels = [];
        data.datasets = [];
        data.labels = chartData[channel_id].labels;
        data.datasets = [
          {
            label: "Leady",
            borderColor: '#17a2b8',
            fill: true,
            backgroundColor: '#17a2b8',
            hoverBorderColor: '#17a2b8',
            borderWidth: 5,
            data: chartData[channel_id].data,
          },
        ];
        data.options = {
          tooltips: tooltipOptions,
          dataset: {
            maxBarThickness: 100,
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: true
              },
            }],
            yAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                min: this.yTicks.min,
                max: this.yTicks.max,
              }
            }]
          }
        }
        leads[channel_id] = data;
      }

      return leads
    },
    ready() {
      if(this.$store.state.status_raport_chart_data.loading_status === 'success') {
        return true
      }
      return false
    },
    resolutionTitle() {
      let resolution = this.resolution;
      if(resolution === 'DAY') {
        return 'Zakres dzienny';
      } else if(resolution === 'WEEK') {
        return 'Zakres tygodniowy';
      } else if(resolution === 'MONTH') {
        return 'Zakres miesięczny';
      } else if(resolution === 'YEAR') {
        return 'Zakres roczny';
      } else return '';
    },
    pickerOptions() {
      const today = utils.today();
      const firstDayOfWeek = this.getfirstDayOfWeek(today, 2);

      const shortcuts = [
          {
            text: "Poprzedni tydzień",
            onClick(picker) {
              const end = new Date(today);
              const start = new Date(firstDayOfWeek);
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 6);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Poprzednie 4 tygodnie",
            onClick(picker) {
              const end = new Date(firstDayOfWeek);
              const start = new Date(firstDayOfWeek);
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7 * 4);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Poprzednie 8 tygodni",
            onClick(picker) {
              const end = new Date(firstDayOfWeek);
              const start = new Date(firstDayOfWeek);
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7 * 8);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
        ]
      return {firstDayOfWeek : 2, shortcuts};
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData() {
      this.loading = true;
      this.$store.commit(STATUS_RAPORT_CHART_DATA_INVALID);
      let date = this.date;

      let date_from = new Date(this.dateRange[0]);
      let date_to = new Date(this.dateRange[1]);
      let diff = Math.abs(date_to - date_from)/1000/60/60/24;

      let resolution = '';

      if(diff < 7) {
        resolution = 'DAY';
      } else if(diff >= 7 && diff < 30 * 6) {
        resolution = 'WEEK';
      } else if(diff >= 30 * 6 && diff < 30 * 12) {
        resolution = 'MONTH';
      } else {
        resolution = 'YEAR';
      }

      this.resolution = resolution;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        resolution,
        channel_id: this.channel_id,
        project_id: this.project_id
      }

      await this.$store.dispatch(LOAD_STATUS_RAPORT_CHART_DATA, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
      this.loading = false;
    },
    getfirstDayOfWeek(dateObject, firstDayOfWeekIndex) {
      console.log(dateObject, firstDayOfWeekIndex);
      const dayOfWeek = dateObject.getDay(),
          firstDayOfWeek = new Date(dateObject),
          diff = dayOfWeek >= firstDayOfWeekIndex ?
              dayOfWeek - firstDayOfWeekIndex :
              6 - dayOfWeek

      firstDayOfWeek.setDate(dateObject.getDate() - diff)
      firstDayOfWeek.setHours(0,0,0,0)

      return firstDayOfWeek
    }
  },
  created() {
    this.dateRange[0] = this.date.date_from;
    this.dateRange[1] = this.date.date_to;

    this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  }
};
</script>

<style lang="css" scoped>
.resolutionTitle {
  font-size: 1.1em;
}
.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}
.card {
  margin: 0;
  display: flex;
}
.header-row {
  padding-top: 15px;
  padding-left: 15px;
}
.modal-wrapper {
  position: relative;
}
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
