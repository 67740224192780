import { render, staticRenderFns } from "./Consents.vue?vue&type=template&id=76f5e15b&scoped=true&"
import script from "./Consents.vue?vue&type=script&lang=js&"
export * from "./Consents.vue?vue&type=script&lang=js&"
import style0 from "./Consents.vue?vue&type=style&index=0&id=76f5e15b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76f5e15b",
  null
  
)

export default component.exports