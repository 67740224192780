export const LOAD_PROJECT_LIMITS = 'LOAD_PROJECT_LIMITS'
export const PROJECT_LIMITS_LOADING = 'PROJECT_LIMITS_LOADING'
export const PROJECT_LIMITS_LOADING_SUCCESS = 'PROJECT_LIMITS_LOADING_SUCCESS'
export const PROJECT_LIMITS_LOADING_ERROR = 'PROJECT_LIMITS_LOADING_ERROR'
export const PROJECT_LIMITS_INVALID = 'PROJECT_LIMITS_INVALID'

export const SAVE_PROJECT_LIMITS = 'SAVE_PROJECT_LIMITS'
export const PROJECT_LIMITS_SAVING = 'PROJECT_LIMITS_SAVING'
export const PROJECT_LIMITS_SAVING_SUCCESS = 'PROJECT_LIMITS_SAVING_SUCCESS'
export const PROJECT_LIMITS_SAVING_ERROR = 'PROJECT_LIMITS_SAVING_ERROR'

export const DELETE_PROJECT_LIMITS = 'DELETE_PROJECT_LIMITS'
export const PROJECT_LIMITS_DELETING = 'PROJECT_LIMITS_DELETING'
export const PROJECT_LIMITS_DELETING_SUCCESS = 'PROJECT_LIMITS_DELETING_SUCCESS'
export const PROJECT_LIMITS_DELETING_ERROR = 'PROJECT_LIMITS_DELETING_ERROR'