<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="card-body row">
          <div class="date_col">
            <el-radio v-model="filter" label="date"><el-date-picker
              @change="loadData"
              v-model="day"
              type="date"
              align="center"
              :clearable="false"
              :disabled="filter !== 'date'"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker></el-radio>
          </div>
          <div class="col-sm-12 col-md-4">
            <el-radio v-model="filter" label="client">
              <el-select
                v-model="client_id"
                name="client_id"
                data-vv-as="Klienci"
                :disabled="filter !== 'client'"
                filterable
                @change="loadData"
              >
              <el-option
                v-for="client in clients"
                :label="'[' + client.client_id + '] ' + client.client_name"
                :value="client.client_id"
                :key="client.client_id"
              ></el-option>
              </el-select>
            </el-radio>
          </div>
          <div class="col-sm-12 col-md-4 ml-auto">
            <fg-input class="input-sm"
                      id="sales_limit_search_input"
                      placeholder="Szukaj"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <el-table :data="queriedData" @sort-change="sortChange" style="width: 100%">
          <el-table-column label="ID Klienta" min-width="100" property="client_id" sortable="custom"></el-table-column>
          <el-table-column label="Nazwa" min-width="200" property="client_name" sortable="custom"></el-table-column>
          <el-table-column label="Od" min-width="100" property="date_from" sortable="custom"></el-table-column>
          <el-table-column label="Do" min-width="100" property="date_to" sortable="custom">
            <template v-slot="props">
              {{ props.row.date_to === null ? '-' : props.row.date_to }}
            </template>
          </el-table-column>
          <el-table-column label="Limit" min-width="100" align="right" property="sale_limit" sortable="custom"></el-table-column>
          <el-table-column label="Twardy" min-width="100" align="right" property="is_hard" sortable="custom"></el-table-column>
        </el-table>
        <div class="card-body row">
          <div class="pagination-info col-sm-6">
            <p class="category">Pokazuje od {{from + 1}} do {{to}} z {{total}} pozycji</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from "vue";
  import moment from "moment"; // time manipulation library
  import utils from "src/utils";

  import * as priv from 'src/privileges.js';

  // event bus
  import { eventBus } from "src/main";

  import { DatePicker, Radio, Select, Option } from "element-ui";
  import PPagination from 'src/components/UIComponents/Pagination.vue';

  import { SALES_LIMITS_INVALID, LOAD_SALES_LIMITS } from "src/store/actions/sales/sales_limits";

  export default {
    components: {
      [DatePicker.name]: DatePicker,
      [Radio.name]: Radio,
      [Select.name]: Select,
      [Option.name]: Option,
      PPagination,
    },
    data() {
      return {
        loading: false,
        filter: 'date',
        client_id: null,
        pickerOptions: {
          firstDayOfWeek: 1,
        },
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        day: moment(utils.today()).format("YYYY-MM-DD"),
        searchQuery: '',
        propsToSearch: ['client_id', 'client_name']
      }
    },
    methods: {
      async loadData() {
        this.loading = true;

        const filters = {
          day: moment(this.day).format("YYYY-MM-DD"),
          client_id: this.client_id,
          filter: this.filter,
          mode: 'historic'
        };

        this.$store.commit(SALES_LIMITS_INVALID);

        await this.$store.dispatch(LOAD_SALES_LIMITS, filters).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });

        this.loading = false;
      },
      sortChange(obj) {
        if(obj.order === 'descending') {
          if(obj.prop === 'client_name' || obj.prop === 'date_from' || obj.prop === 'date_to') {
            this.tableData.sort((a,b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase()) ? 1 : -1);
          } else {
            this.tableData.sort((a,b) => b[obj.prop] - a[obj.prop])
          }
        } else if(obj.order === 'ascending') {
          if(obj.prop === 'client_name' || obj.prop === 'date_from' || obj.prop === 'date_to') {
            this.tableData.sort((a,b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase()) ? 1 : -1);
          } else {
            this.tableData.sort((a,b) => a[obj.prop] - b[obj.prop])
          }
        } else {
          this.tableData.sort((a,b) => b.id - a.id);
        }
        this.searchQuery += ' ';
        this.searchQuery = this.searchQuery.slice(0, -1);
      },
    },
    computed: {
      tableData() {
        const getter = this.$store.getters.getSalesLimits;
        let tableData = Object.values(getter);
        return tableData
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      queriedData () { // paginacja danych
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.tableData.slice(this.from, this.to)
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString();
              let rowValueLowerCase = row[key].toString().toLowerCase();
              if ((rowValue.includes && rowValue.includes(this.searchQuery)) || rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })

        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      clients() {
        return this.$store.getters.getClients;
      },
    },
    created() {
      this.loadData();
    },
  }
</script>
<style scoped>

.date_col {
  position: relative;
  width: 260px;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
</style>
