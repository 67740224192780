import storeGen from "@/store/storeGenerator";

import {AUTH_LOGOUT} from "@/store/actions/auth";
import {SECURE_API} from "@/store/config";


import {
  MAILING_EFFINCIENCY_CONST_EXCEL_OB_NAME,
  MAILING_EFFINCIENCY_CONST_EXCEL_GETTER_NAME,
  MAILING_EFFINCIENCY_CONST_EXCEL_LOAD,
  MAILING_EFFICIENCY_CONST_EXCEL_LOADING_SUCCESS,
  MAILING_EFFICIENCY_CONST_EXCEL_LOADING_ERROR,
  MAILING_EFFICIENCY_CONST_EXCEL_INVALID,
  MAILING_EFFICIENCY_CONST_EXCEL_LOADING
} from "@/store/actions/secure/mailing_efficiency_const_excel";


const storeObjectConfig = {
  objectName: MAILING_EFFINCIENCY_CONST_EXCEL_OB_NAME,
  getterName: MAILING_EFFINCIENCY_CONST_EXCEL_GETTER_NAME,
  loadActionName: MAILING_EFFINCIENCY_CONST_EXCEL_LOAD,
  loadingMutationName: MAILING_EFFICIENCY_CONST_EXCEL_LOADING,
  loadingSuccessMutationName: MAILING_EFFICIENCY_CONST_EXCEL_LOADING_SUCCESS,
  loadingErrorMutationName: MAILING_EFFICIENCY_CONST_EXCEL_LOADING_ERROR,
  invalidMutationName: MAILING_EFFICIENCY_CONST_EXCEL_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/raports/mailingEfficiencyConst/excel",
    method: "get", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
