<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 text-sm-left col-md-4 text-md-left MailingStats__dateRange">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="-"
              start-placeholder="Data początkowa"
              end-placeholder="Data końcowa"
              :picker-options="pickerOptions"
              :clearable="false"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
            >
            </el-date-picker>
          </div>
          <div class="col-sm-4">
            <div>
              <label>Rodzaj wysyłki</label>
            </div>
            <el-radio-group v-model="mailingType" size="medium">
              <el-radio-button label="Wewnętrzne"></el-radio-button>
              <el-radio-button label="Zewnętrzne"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="col-sm-4 right-bottom">
            <p-button class="MailingStats__buttonsMargin" type="success" @click="loadStats">Statystyki</p-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label
              >Filtr <span @click="filter.filter_mode = 'banner'" :class="getFilterModeClass('banner')">banerów</span>/<span
                @click="filter.filter_mode = 'list'"
                :class="getFilterModeClass('list')"
                >list</span
              ></label
            >
            <fg-input
              class="MailingStats__banner_filter"
              placeholder="Filtruj po nazwie"
              name="banner_filter"
              data-vv-as="Filtr banerów"
              v-model="filter.banner_filter"
              v-validate="filterValidation.banner_filter"
              :error="getError('banner_filter')"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Baner</label>
            <fg-input>
              <el-select
                v-model="filter.banners"
                name="banners"
                filterable
                remote
                reserve-keyword
                clearable
                multiple
                :remote-method="remoteSearch"
                :loading="loading"
              >
                <el-option
                  v-for="banner in bannerConfig"
                  :label="'[' + banner.BannerID + '] ' + banner.BannerName"
                  :value="banner.BannerID"
                  :key="banner.BannerID"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Projekt</label>
            <fg-input>
              <el-select v-model="filter.projects" name="projects" filterable clearable multiple>
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>
      <hr />
      <h6>
        <div class="card-footer" style="display: flex; justify-content: space-between">
          <span>Przedział dat:</span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </div>
        <div class="card-footer count-footer" style="display: flex; justify-content: space-between">
          <span>Suma wysłanych:</span>
          <span>{{ sumSent | formatNumber }}</span>
        </div>
      </h6>
    </div>

    <div class="card">
      <div class="card-body row">
        <el-table
          :span-method="objectSpanMethod"
          :data="queriedData"
          style="width: 100%"
          :default-sort="{ prop: 'banner_id', order: 'descending' }"
          :cell-style="colStyle"
          :header-cell-style="headerStyle"
        >
          <el-table-column label="Projekt" property="project_id" min-width="150" align="left">
            <template v-slot="props">
              {{ props.row.project_name }}
            </template>
          </el-table-column>
          <el-table-column label="Banner" property="banner_id" min-width="200" align="left">
            <template v-slot="props">
              <span>{{ "[" + props.row.banner_id + "] " + props.row.banner_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Lista" property="list_name" min-width="100" align="left"> </el-table-column>
          <el-table-column label="Wysłano" property="sent" min-width="120" align="left">
            <template v-slot="props">
              <div>
                {{ formatNumber(props.row.sent) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Płeć" property="sex" min-width="150" align="left">
            <template v-slot="props">
              <div class="sexColumn">
                <span
                  ><b>{{ props.row.sex }}</b></span
                >
                <span>{{ formatNumber(props.row.sumDisplay) + "/" + formatNumber(props.row.sumClick) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="18-24" property="p1" width="100" align="right">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="'display/click ' + props.row.p1_percent" placement="top" :open-delay="300">
                <div>
                  {{ formatNumber(props.row.p1_displayed) + "/" + formatNumber(props.row.p1_clicked) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="25-34" property="p2" width="110" align="right">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="'display/click ' + props.row.p2_percent" placement="top" :open-delay="300">
                <div>
                  {{ formatNumber(props.row.p2_displayed) + "/" + formatNumber(props.row.p2_clicked) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="35-44" property="p3" width="120" align="right">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="'display/click ' + props.row.p3_percent" placement="top" :open-delay="300">
                <div>
                  {{ formatNumber(props.row.p3_displayed) + "/" + formatNumber(props.row.p3_clicked) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="45-55" property="p4" width="120" align="right">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="'display/click ' + props.row.p4_percent" placement="top" :open-delay="300">
                <div>
                  {{ formatNumber(props.row.p4_displayed) + "/" + formatNumber(props.row.p4_clicked) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="55+" property="p5" width="120" align="right">
            <template v-slot="props">
              <el-tooltip class="item" effect="light" :content="'display/click ' + props.row.p5_percent" placement="top" :open-delay="300">
                <div>
                  {{ formatNumber(props.row.p5_displayed) + "/" + formatNumber(props.row.p5_clicked) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body row">
        <div class="col-sm-6 pagination-info">
          <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"> </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

import { DatePicker, Select, Option, OptionGroup, Tooltip, RadioButton, RadioGroup } from "element-ui";

// ui components
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions
import { LOAD_MAILING_STATS_DEMOGRAPHY, MAILING_STATS_DEMOGRAPHY_INVALID } from "src/store/actions/stats/mailing_stats_demography";
import { LOAD_BANNER_CONFIG, BANNER_CONFIG_INVALID } from "src/store/actions/stats/banner_config";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";

// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    PPagination,
  },
  data() {
    return {
      loading: false,
      filter: {
        banners: [],
        banner_filter: "",
        filter_mode: "banner",
        projects: [],
      },
      filterValidation: {
        banner_filter: {
          regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ._-]+)$",
          max: 32,
        },
      },
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["banner_id", "banner_name"],
      mailingType: "Wewnętrzne",
      dateRange: ["", ""],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      questionSelectWidth: null,
    };
  },
  computed: {
    demographyStats() {
      return this.$store.getters.getMailingStatsDemography;
    },
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    tableData() {
      if (this.$store.state.mailing_stats_demography.loading_status !== "success") return [];

      let check = this.checkAll;
      let demographyStats = this.demographyStats;
      let tableData = [];

      for (let bid in demographyStats) {
        // iteracja po bannerach
        let record = demographyStats[bid];
        let tableObject = {
          project_id: record.project_id,
          project_name: record.project_name,
          banner_id: bid,
          banner_name: record.banner_name,
          list_name: record.list_name,
          sent: record.sent,
          sumDisplay: 0,
          sumClick: 0,
          p1_displayed: 0,
          p1_clicked: 0,
          p1_percent: 0,
          p2_displayed: 0,
          p2_clicked: 0,
          p2_percent: 0,
          p3_displayed: 0,
          p3_clicked: 0,
          p3_percent: 0,
          p4_displayed: 0,
          p4_clicked: 0,
          p4_percent: 0,
          p5_displayed: 0,
          p5_clicked: 0,
          p5_percent: 0,
        };
        let tableObjectM = { ...tableObject, sex: "M" };
        let tableObjectK = { ...tableObject, sex: "K" };

        this.getTableObject(tableObjectM, "p1", "M", record, "18-24");
        this.getTableObject(tableObjectM, "p2", "M", record, "25-34");
        this.getTableObject(tableObjectM, "p3", "M", record, "35-44");
        this.getTableObject(tableObjectM, "p4", "M", record, "45-55");
        this.getTableObject(tableObjectM, "p5", "M", record, "55+");

        this.getTableObject(tableObjectK, "p1", "K", record, "18-24");
        this.getTableObject(tableObjectK, "p2", "K", record, "25-34");
        this.getTableObject(tableObjectK, "p3", "K", record, "35-44");
        this.getTableObject(tableObjectK, "p4", "K", record, "45-55");
        this.getTableObject(tableObjectK, "p5", "K", record, "55+");

        tableObjectM.sumDisplay =
          tableObjectM.p1_displayed + tableObjectM.p2_displayed + tableObjectM.p3_displayed + tableObjectM.p4_displayed + tableObjectM.p5_displayed;
        tableObjectK.sumDisplay =
          tableObjectK.p1_displayed + tableObjectK.p2_displayed + tableObjectK.p3_displayed + tableObjectK.p4_displayed + tableObjectK.p5_displayed;
        tableObjectM.sumClick =
          tableObjectM.p1_clicked + tableObjectM.p2_clicked + tableObjectM.p3_clicked + tableObjectM.p4_clicked + tableObjectM.p5_clicked;
        tableObjectK.sumClick =
          tableObjectK.p1_clicked + tableObjectK.p2_clicked + tableObjectK.p3_clicked + tableObjectK.p4_clicked + tableObjectK.p5_clicked;

        tableData.push(tableObjectM);
        tableData.push(tableObjectK);
      }
      tableData.sort((a, b) => b.banner_id - a.banner_id);
      return tableData;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
    sumSent() {
      let cnt = 0;

      if (this.demographyStats && Object.values(this.demographyStats).length > 0) {
        Object.values(this.demographyStats).forEach((row) => {
          cnt += row.sent;
        });
      }

      return cnt;
    },
  },
  methods: {
    formatNumber(num) {
      return utils.formatNumber(num);
    },
    getPercentage(a, b) {
      if (b === 0) {
        return "-";
      }
      return Math.round((a / b) * 100 * 10) / 10 + "%";
    },
    async loadData(force) {
      this.loading = true;
      if (force) {
        // this.$store.commit(SETTLEMENTS_INVALID);
      }

      await this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    async loadStats(force) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        return;
      }

      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      if (force) {
        this.$store.commit(MAILING_STATS_DEMOGRAPHY_INVALID);
      }
      this.loading = true;

      if (force && this.dateRange[0] !== "") {
        let date = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        };

        const filters = Object.assign({}, date, this.filter);
        filters.mailingType = this.mailingType;

        await this.$store.dispatch(LOAD_MAILING_STATS_DEMOGRAPHY, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
        this.loading = false;
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, { searchQuery }).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    getTableObject(tableObject, objectName, sex, record, recordName) {
      if (record[sex] && record[sex][recordName]) {
        tableObject[`${objectName}_displayed`] = record[sex][recordName].displayed ? record[sex][recordName].displayed : 0;
        tableObject[`${objectName}_clicked`] = record[sex][recordName].clicked ? record[sex][recordName].clicked : 0;
        tableObject[`${objectName}_percent`] = record[sex][recordName].percent ? Math.round(record[sex][recordName].percent * 100) / 100 + "%" : 0;
      }

      return tableObject;
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex < 3) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    colStyle() {
      return "font-size: 12px;";
    },
    headerStyle() {
      return "font-size: 12px;";
    },
    getFilterModeClass(type) {
      if (type === this.filter.filter_mode) return "filter-checked";
      return "filter-unchecked";
    },
  },
  mounted() {},
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadStats(true);
    });
  },
  beforeDestroy: function () {
    this.$store.commit(MAILING_STATS_DEMOGRAPHY_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped>
.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.MailingStats__dateRange {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 10px 0;
}

.MailingStats__buttonsMargin {
  margin-left: 10px;
  margin-right: 10px;
}

.count-footer {
  font-size: 12px;
  padding-top: 3px;
}

.sexColumn {
  display: flex;
  justify-content: space-between;
}

.filter-checked {
  cursor: pointer;
  text-decoration: underline;
}

.filter-unchecked {
  cursor: pointer;
}
</style>
