import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_COMPLAINTS_USER_DASHBOARD,
  COMPLAINTS_USER_DASHBOARD_LOADING,
  COMPLAINTS_USER_DASHBOARD_LOADING_SUCCESS,
  COMPLAINTS_USER_DASHBOARD_LOADING_ERROR,
  COMPLAINTS_USER_DASHBOARD_INVALID,
} from '../../actions/complaints/complaints_user_dashboard'

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getComplaintsUserDashboard",
  loadActionName: LOAD_COMPLAINTS_USER_DASHBOARD,
  loadingMutationName: COMPLAINTS_USER_DASHBOARD_LOADING,
  loadingSuccessMutationName: COMPLAINTS_USER_DASHBOARD_LOADING_SUCCESS,
  loadingErrorMutationName: COMPLAINTS_USER_DASHBOARD_LOADING_ERROR,
  invalidMutationName: COMPLAINTS_USER_DASHBOARD_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/complaints/dashboard/user",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
