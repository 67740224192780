<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-10">
        <h5 class="title">Mailingi użytkownika</h5>
      </div>
      <div class="col-sm-2">
        <router-link
          :to="{
            name: 'Reklamacje',
            params: {
              mailbox: $route.params.mailbox,
              complaintID: $route.params.complaintID,
            },
          }"
        >
          <p-button type="default" link v-if="complaintCheck">
            Powrót do reklamacji
          </p-button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <label>Wpisz ID użytkownika</label>
              <fg-input :error="getError('ID użytkownika')" data-vv-as="ID użytkownika">
                <el-input
                  :disabled="selectedFilter == 'teledata'"
                  type="text"
                  inputmode="numeric"
                  name="ID użytkownika"
                  clearable
                  placeholder="ID użytkownika"
                  v-model="model.user_id"
                  :error="getError('ID użytkownika')"
                >
                </el-input>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label>Wpisz adres email</label>
              <fg-input :error="getError('Adres email')" data-vv-as="Adres email">
                <el-input
                  :disabled="selectedFilter == 'user_id'"
                  type="email"
                  name="Adres email"
                  clearable
                  placeholder="Adres email"
                  v-model="model.mail"
                  v-validate="modelValidations.mail"
                  :error="getError('Adres email')"
                >
                </el-input>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                align="center"
                unlink-panels
                range-separator="-"
                start-placeholder="Data początkowa"
                end-placeholder="Data końcowa"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <p-button type="success" size="sm" @click="loadData">Sprawdź</p-button>
            </div>
            <div class="col-sm-7" v-if="this.$store.state.user_data.loading_status === 'success' && Object.keys(userData.userQuiz).length > 0">
              <p-button type="info" size="sm" @click="showQuizData = !showQuizData">{{
                showQuizData ? "Schowaj konkurs" : "Pokaż konkurs"
              }}</p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="this.$store.state.user_mailings.loading_status === 'success'">
      <div class="card-body">
        <div class="row">
          <el-table :data="userMailings" style="width: 100%" :default-sort="{ prop: 'time_send', order: 'descending' }">
            <el-table-column type="expand">
              <template v-slot="props">
                <p><b>Mail: </b>{{ props.row.email }}</p>
                <p><b>UserDataID: </b>{{ props.row.UserDataID }}</p>
                <p><b>Nazwa nadacy: </b>{{ getParam(props.row.from_name) }}</p>
                <p><b>ID kampanii: </b>{{ props.row.campaign_id }}</p>
                <p><b>ID listy: </b>{{ props.row.sended_list_id }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Wysłano" property="time_send" width="180" sortable></el-table-column>
            <el-table-column label="Nadawca" property="from_email" min-width="100" sortable>
              <template v-slot="props">
                {{ getParam(props.row.from_email) }}
              </template>
            </el-table-column>
            <el-table-column label="Temat" property="subject" min-width="120" sortable></el-table-column>
            <el-table-column label="Kampania" property="campaign_name" align="left" min-width="120" sortable></el-table-column>
            <el-table-column label="Typ" property="mailing_type" align="left" width="100" sortable></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Select, Option, Input, Tooltip, DatePicker } from "element-ui";
import moment from "moment"; // time manipulation library

// vuex actions
import { LOAD_USER_MAILINGS, USER_MAILINGS_INVALID } from "src/store/actions/complaints/user_mailings";

// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Tooltip.name]: Tooltip,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      loading: false,
      model: {
        phone: "",
        mail: "",
        user_id: "",
      },
      modelValidations: {
        phone: {
          regex: "^(48)?[0-9]{9}$",
          max: 11,
        },
        mail: {
          regex: "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}",
        },
      },
      dateRange: [utils.today(), utils.today()],
      pickerOptions: {
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      complaintCheck: false,
    };
  },
  computed: {
    userMailings() {
      return this.$store.getters.getUserMailings;
    },
    selectedFilter() {
      if (this.model.user_id.length > 0) {
        return "user_id";
      }
      if (this.model.mail.length > 0 || this.model.phone.length > 0) {
        return "teledata";
      }
      return null;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData(force) {
      if (this.model.mail.length < 1 && this.model.user_id.length < 1) return;
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      this.loading = true;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        mail: this.model.mail,
        user_id: this.model.user_id,
      };

      if (force) {
        this.$store.commit(USER_MAILINGS_INVALID);
      }

      await this.$store.dispatch(LOAD_USER_MAILINGS, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    getParam(param) {
      if (param === null || param === "" || param === 0) {
        return "brak";
      } else {
        return param;
      }
    },
  },
  created() {
    let params = this.$route.params;
    if (params.mail) {
      this.model.mail = params.mail;
      this.complaintCheck = true;
    }
    if (this.complaintCheck) {
      this.loadData(true);
    }
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
  },
  beforeDestroy() {
    this.$store.commit(USER_MAILINGS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="scss" scoped>
.center {
  justify-content: center;
  align-content: center;
  display: flex;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
