import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_MAILBOXES,
  MAILBOXES_LOADING,
  MAILBOXES_LOADING_SUCCESS,
  MAILBOXES_LOADING_ERROR,
  MAILBOXES_INVALID
} from '../../actions/complaints/mailboxes'

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getMailboxes",
  loadActionName: LOAD_MAILBOXES,
  loadingMutationName: MAILBOXES_LOADING,
  loadingSuccessMutationName: MAILBOXES_LOADING_SUCCESS,
  loadingErrorMutationName: MAILBOXES_LOADING_ERROR,
  invalidMutationName: MAILBOXES_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/complaints/mailboxes",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
