<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">Dane użytkownika</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <label>Email</label>
          <fg-input :error="getError('email')" data-vv-as="Email">
            <el-input
              type="text"
              name="email"
              data-vv-as="Email"
              v-validate="modelValidations.email"
              v-validate.immediate
              :error="getError('email')"
              v-model="model.email"
            >
              <template slot="append">@fajnapoczta.pl</template>
            </el-input>
          </fg-input>
        </div>
        <div class="col-sm-2 helper">
          <p-button type="success" size="sm" icon @click="copyElement(model.email)">
            <i class="fa fa-clone"></i>
          </p-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <label>Hasło</label>
          <fg-input
            type="text"
            name="password"
            data-vv-as="Hasło"
            v-validate="modelValidations.password"
            v-validate.immediate
            :error="getError('password')"
            v-model="model.password"
          ></fg-input>
        </div>

        <div class="col-sm-2 helper">
          <p-button type="success" size="sm" icon @click="copyElement(model.password)">
            <i class="fa fa-clone"></i>
          </p-button>
        </div>
      </div>
      <div class="row">
        <p-button style="margin-right: 5px" link @click="$emit('close')">Anuluj</p-button>
        <p-button type="success" @click="handleSave()">Zapisz</p-button>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip } from "element-ui";

import * as regexp from "src/regexp.js";

// event bus
import { eventBus } from "src/main";

import { SAVE_PBR } from "src/store/actions/mailings/pbr";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },
  props: ["email", "password", "phone", "id"],
  data() {
    return {
      is_test: false,
      model: {
        email: "",
        password: "",
      },
      modelValidations: {
        email: {
          regex: /^[0-9a-z.-]+$/,
          min: 1,
          max: 32,
        },
        password: {
          regex: /^[0-9a-zA-Z!@]+$/,
          min: 1,
          max: 12,
        },
      },
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    copyElement(element) {
      navigator.clipboard.writeText(element).then(
        function () {
          eventBus.$emit("notify", {
            message: "Skopiowano dane do schowka",
            type: "success",
          });
        },
        function (err) {
          eventBus.$emit("notify", {
            message: "Błąd kopiowania",
            type: "warning",
          });
        },
      );
    },
    async handleSave() {
      const isValidated = await this.$validator.validateAll();
      if (!isValidated) {
        return eventBus.$emit("notify", {
          message: "Dane nieprawidłowe!",
          type: "warning",
        });
      }

      const record = {
        id: this.id,
        phone: this.phone,
        email: this.model.email,
        password: this.model.password,
      };

      await this.$store.dispatch(SAVE_PBR, record).catch((error) => {
        // zapisanie danych do bazy
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getPbrModifiedRecords === true) {
        // wyświetlenie informacji o zapisaniu
        eventBus.$emit("notify", {
          message: "Rekord został zmodyfikowany",
          type: "success",
        });

        eventBus.$emit("pbrSuccess");
        this.$emit("close");
      }
    },
  },
  async created() {
    this.model.email = this.email.toString().toLowerCase();
    this.model.password = this.password;
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}

.helper {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}
</style>
