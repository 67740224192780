import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_SCOPES,
  SCOPES_LOADING,
  SCOPES_LOADING_SUCCESS,
  SCOPES_LOADING_ERROR,
  SCOPES_INVALID,
  SAVE_SCOPE,
  SCOPE_SAVING,
  SCOPE_SAVING_SUCCESS,
  SCOPE_SAVING_ERROR
} from 'src/store/actions/admin/scopes'

const storeObjectConfig = {
  objectName: "Scopes",
  getterName: "getScopes",
  loadActionName: LOAD_SCOPES,
  loadingMutationName: SCOPES_LOADING,
  loadingSuccessMutationName: SCOPES_LOADING_SUCCESS,
  loadingErrorMutationName: SCOPES_LOADING_ERROR,
  invalidMutationName: SCOPES_INVALID,
  saveActionName: SAVE_SCOPE,
  savingMutationName: SCOPE_SAVING,
  savingSuccessMutationName: SCOPE_SAVING_SUCCESS,
  savingErrorMutationName: SCOPE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/scopes",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/scopes",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
