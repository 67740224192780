<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Przychód ruchu spadowego</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <flat-pickr class="flatPickr" v-model="pickrWeek" :config="config" placeholder="Wybierz tydzień" v-on:on-change="loadData"></flat-pickr>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <el-table :data="queriedData" @sort-change="sortChange" style="width: 100%">
          <el-table-column label="Projekt" min-width="100" property="project_name" sortable="custom"></el-table-column>
          <el-table-column label="Od" min-width="100" property="date_from" sortable="custom"></el-table-column>
          <el-table-column label="Do" min-width="100" property="date_to" sortable="custom"></el-table-column>
          <el-table-column label="Przychód" min-width="100" property="income" sortable="custom" align="right">
            <template v-slot="props">
              {{ props.row.income | formatCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Wyświetlenia" min-width="100" property="displays" sortable="custom" align="right">
            <template v-slot="props">
              {{ props.row.displays | formatNumber }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="140" v-if="editPermitted">
            <template slot="header">
              <el-tooltip class="item" effect="light" content="Dodaj przychód" placement="top" :open-delay="500">
                <p-button type="success" @click="showModal('add', {})" icon round style="margin: 0 5px 0 5px">
                  <i class="fa fa-plus"></i>
                </p-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="Stwórz szablon na podstawie aktualnego tygodnia" placement="top" :open-delay="500">
                <p-button type="success" @click="showModal('copy', {})" icon round style="margin: 0 5px 0 5px">
                  <i class="fa fa-clone"></i>
                </p-button>
              </el-tooltip>
            </template>
            <template v-slot="props">
              <el-tooltip class="item" effect="light" content="Edytuj przychód" placement="top" :open-delay="500">
                <p-button type="success" size="sm" icon style="margin: 0 5px 0 5px" @click="showModal('edit', props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
              </el-tooltip>
              <el-tooltip class="item" effect="light" content="Usuń przychód" placement="top" :open-delay="500">
                <p-button type="danger" size="sm" icon style="margin: 0 5px 0 5px" v-if="deletePermitted" @click="handleDelete(props.row)">
                  <i class="fa fa-trash"></i>
                </p-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div class="card-body row">
          <div class="pagination-info col-sm-6">
            <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="weekFormat">
      <div class="card-body">
        <b>Tydzień:</b> {{ weekFormat }}, <span class="summaryTitle">Przychód:</span> {{ summary.income | formatCurrency }},
        <span class="summaryTitle">Wyświetlenia:</span> {{ summary.display | formatNumber }}
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment"; // time manipulation library
import utils from "src/utils";
import Swal from "sweetalert2";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";
import { FIRST_DAY_OF_WEEK } from "src/config";

import { Tooltip, DatePicker } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";

import { UPSELLING_INCOME_INVALID, LOAD_UPSELLING_INCOME, DELETE_UPSELLING_INCOME } from "src/store/actions/secure/upselling_income";
import { LOAD_PROJECTS, PROJECTS_INVALID } from "src/store/actions/questions/projects";

import UpsellingIncomeModal from "src/components/Utils4/sales/views/UpsellingIncomeModal.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Polish } from "flatpickr/dist/l10n/pl.js";
import weekSelect from "flatpickr/dist/plugins/weekSelect/weekSelect.js";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    [DatePicker.name]: DatePicker,
    PPagination,
    flatPickr,
  },
  data() {
    return {
      loading: false,
      pagination: {
        perPage: 15,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      pickrWeek: null,
      config: {
        locale: {
          ...Polish,
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
        plugins: [new weekSelect({})],
        altInput: true,
        altInputClass: "flatPickr",
        altFormat: "T\\y\\dz\\ień W",
      },
    };
  },
  methods: {
    initLoad() {
      this.$store.dispatch(LOAD_PROJECTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    showModal(mode, row) {
      // wyświetlenie modala
      const copyData = [];
      if (mode === "copy") {
        this.tableData.map((x) => {
          copyData.push({ project_id: x.project_id, income: 0, displays: 0 });
        });
      }
      this.$modal.show(
        UpsellingIncomeModal,
        {
          mode: mode,
          row: row,
          copyData: copyData,
          parentWeek: this.week,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(UpsellingIncomeModal);
              },
            },
          ],
        },
        {
          name: "UpsellingIncomeModal",
          draggable: false,
          scrollable: true,
          width: "800px",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    async loadData() {
      if (this.week === null) {
        eventBus.$emit("notify", {
          message: "Należy wybrać tydzień!",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const filters = {
        date_from: moment(this.dateFrom).format("YYYY-MM-DD"),
        date_to: moment(this.dateTo).format("YYYY-MM-DD"),
      };

      this.$store.commit(UPSELLING_INCOME_INVALID);

      this.$store.dispatch(LOAD_PROJECTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_UPSELLING_INCOME, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    handleDelete(row) {
      var varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Czy chcesz usunąć ten przychód?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            let model = {};
            model = {
              id: row.id,
              income: row.income,
              date_from: row.date_from,
              date_to: row.date_to,
              display: row.display,
            };
            varThis.deleteData(model);
          }
        })
        .catch(() => {
          return;
        });
    },
    async deleteData(model) {
      await this.$store.dispatch(DELETE_UPSELLING_INCOME, model).catch((error) => {
        // usunięcie przychodu
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getUpsellingIncomeDeletingStatus === "deleted") {
        // wyświetlenie informacji o zapisaniu
        eventBus.$emit("notify", {
          message: "Rekord został usunięty",
          type: "success",
        });

        this.loadData();
      }
    },
    sortChange(obj) {
      if (obj.order === "descending") {
        if (obj.prop === "date_from" || obj.prop === "date_to" || obj.prop === "project_name") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (obj.prop === "date_from" || obj.prop === "date_to" || obj.prop === "project_name") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        this.tableData.sort((a, b) => b.id - a.id);
      }
      this.searchQuery += " ";
      this.searchQuery = this.searchQuery.slice(0, -1);
    },
  },
  computed: {
    week() {
      let resultDate = null;
      if (this.pickrWeek) {
        let resultDate = new Date(this.pickrWeek);
        const selectedDay = resultDate.getDay();

        // 0: -4, 1: -5, 2: +1, 3: 0, 4: -1, 5: -2, 6: -3
        if (selectedDay >= 2) {
          resultDate.setDate(resultDate.getDate() + (3 - selectedDay));
        } else {
          resultDate.setDate(resultDate.getDate() + (-4 - selectedDay));
        }

        return resultDate;
      }

      return resultDate;
    },
    dateFrom() {
      let firstDay = null;
      if (this.week !== null) {
        firstDay = new Date(this.week.getTime());
        firstDay.setDate(firstDay.getDate() - 1);
      }
      return firstDay;
    },
    dateTo() {
      let lastDay = null;
      if (this.week !== null) {
        lastDay = new Date(this.week.getTime());
        lastDay.setDate(lastDay.getDate() + 5);
      }
      return lastDay;
    },
    weekFormat() {
      let result = null;
      if (this.week !== null) {
        result = moment(this.dateFrom).format("DD") + "-" + moment(this.dateTo).format("DD.MM.YYYY");
      }
      return result;
    },
    tableData() {
      if (this.$store.state.upselling_income.loading_status === "success") {
        const getter = this.$store.getters.getUpsellingIncome;
        let tableData = Object.values(getter);
        return tableData;
      } else {
        return [];
      }
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      // paginacja danych
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_UPSELLING_INCOME);
    },
    deletePermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_DELETE_UPSELLING_INCOME);
    },
    summary() {
      let result = { display: 0, income: 0 };
      for (let i in this.tableData) {
        result.income += this.tableData[i].income;
        result.display += this.tableData[i].displays;
      }
      return result;
    },
  },
  mounted() {
    eventBus.$on("upsellingIncomeSaved", () => {
      // zapis przychodu
      if (this.week !== null) {
        this.loadData();
      }
    });
  },
  created() {
    this.initLoad();
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    this.$store.commit(UPSELLING_INCOME_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
/* stylowanie przycisku do wczytania pliku */
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.el-radio-group {
  padding: 5px 5px 0 5px;
}

.summaryTitle {
  font-weight: bold;
  margin-left: 10px;
}
</style>
