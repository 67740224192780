import storeGen from '../storeGenerator'

import {
  AUTH_LOGOUT
} from '../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_ECHO,
  ECHO_LOADING,
  ECHO_LOADING_SUCCESS,
  ECHO_LOADING_ERROR,
  ECHO_INVALID,
  SAVE_ECHO,
  ECHO_SAVING,
  ECHO_SAVING_SUCCESS,
  ECHO_SAVING_ERROR
} from '../actions/echo'

const storeObjectConfig = {
  objectName: "Echo",
  getterName: "getEcho",
  loadActionName: LOAD_ECHO,
  loadingMutationName: ECHO_LOADING,
  loadingSuccessMutationName: ECHO_LOADING_SUCCESS,
  loadingErrorMutationName: ECHO_LOADING_ERROR,
  invalidMutationName: ECHO_INVALID,
  saveActionName: SAVE_ECHO,
  savingMutationName: ECHO_SAVING,
  savingSuccessMutationName: ECHO_SAVING_SUCCESS,
  savingErrorMutationName: ECHO_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/echo",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
