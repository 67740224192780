<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">{{ header }}</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer" aria-hidden="true"></i>
        </div>
      </div>
      <div class="modalRow row">
        <div :class="showCriteria ? 'col-sm-6' : 'col-sm-12'">
          <div class="row" v-if="mode === 'search' || blacklistMode === 'phone'">
            <div class="col-sm-11">
              <div class="form-group">
                <label>Telefon</label>
                <fg-input :error="getError('phone')" data-vv-as="Telefon">
                  <el-select
                    v-model="model.phone"
                    @input="blacklistCheck"
                    name="phone"
                    data-vv-as="Telefon"
                    v-validate="modelValidations.phone"
                    filterable
                    allow-create
                    clearable
                  >
                    <el-option v-for="record in phone" :label="record" :value="record" :key="record"></el-option>
                  </el-select>
                </fg-input>
              </div>
            </div>
            <div class="col-sm-1 helper">
              <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                <div slot="content">Aby dodać ręcznie numer należy wpisać go w danym polu i wybrać kliknięciem</div>
                <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
              </el-tooltip>
            </div>
          </div>
          <div class="row" v-if="blacklistMode === 'email'">
            <div class="col-md-11">
              <div class="form-group">
                <label>Mail</label>
                <fg-input :error="getError('mail')" data-vv-as="Mail">
                  <el-select v-model="model.mail" name="mail" data-vv-as="Mail" v-validate="modelValidations.mail" filterable allow-create clearable>
                    <el-option v-for="record in mail" :label="record" :value="record" :key="record"></el-option>
                  </el-select>
                </fg-input>
              </div>
            </div>

            <div class="col-sm-1 helper">
              <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                <div slot="content">Aby dodać ręcznie email należy wpisać go w danym polu i wybrać kliknięciem</div>
                <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
              </el-tooltip>
            </div>
          </div>
          <div class="row" v-if="checkedPhone">
            <hr />
            <div class="col-sm-12">
              <p class="blacklist-paragraph"><b>Blacklista:</b> {{ checkedPhone[model.phone].status ? "tak" : "nie" }}</p>
              <p class="blacklist-paragraph" v-if="checkedPhone[model.phone].add_time"><b>Od:</b> {{ checkedPhone[model.phone].add_time }}</p>
              <p class="blacklist-paragraph" v-if="checkedPhone[model.phone].expire_time"><b>Do:</b> {{ checkedPhone[model.phone].expire_time }}</p>
            </div>
          </div>
          <div class="row" v-if="showCriteria">
            <div class="col-sm-11">
              <div class="form-group">
                <label>Spółka</label>
                <el-select v-model="criteriaTempModel.owner" @input="criteriaInput($event, 'owner')" name="owner" data-vv-as="Spółka" filterable>
                  <el-option
                    v-for="record in blacklistCriteria.owners"
                    :label="'[' + record.owner_id + '] ' + record.owner_name"
                    :value="record.owner_id"
                    :key="record.owner_id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="col-sm-1 helper">
              <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                <div slot="content">Wybrane spółki pokażą się na liście po prawej stronie</div>
                <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
              </el-tooltip>
            </div>
          </div>
          <div class="row" v-if="showCriteria">
            <div class="col-sm-11">
              <div class="form-group">
                <label>Brand</label>
                <el-select v-model="criteriaTempModel.brand" @input="criteriaInput($event, 'brand')" name="brand" data-vv-as="Brand" filterable>
                  <el-option
                    v-for="record in blacklistCriteria.brands"
                    :label="record.brand_name"
                    :value="record.brand_id"
                    :key="record.brand_id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="col-sm-1 helper">
              <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                <div slot="content">Wybrane brandy pokażą się na liście po prawej stronie</div>
                <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
              </el-tooltip>
            </div>
          </div>
          <div class="row" v-if="showCriteria">
            <div class="col-sm-11">
              <div class="form-group">
                <label>Projekt</label>
                <el-select
                  v-model="criteriaTempModel.project"
                  @input="criteriaInput($event, 'project')"
                  name="project"
                  data-vv-as="Projekt"
                  filterable
                >
                  <el-option
                    v-for="record in blacklistCriteria.projects"
                    :label="'[' + record.sms_project_id + '] ' + record.sms_project_name"
                    :value="record.sms_project_id"
                    :key="record.sms_project_id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="col-sm-1 helper">
              <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                <div slot="content">Wybrane projekty pokażą się na liście po prawej stronie</div>
                <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
              </el-tooltip>
            </div>
          </div>
          <div class="row" v-if="showCriteria && blacklistMode === 'phone'">
            <div class="col-sm-11">
              <div class="form-group">
                <label>Bramka</label>
                <el-select v-model="criteriaTempModel.gate" @input="criteriaInput($event, 'gate')" name="gate" data-vv-as="Bramka" filterable>
                  <el-option
                    v-for="record in blacklistCriteria.sms_premium_numbers"
                    :label="record.sms_premium_number"
                    :value="record.sms_premium_number_id"
                    :key="record.sms_premium_number_id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="col-sm-1 helper">
              <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                <div slot="content">Wybrane bramki pokażą się na liście po prawej stronie</div>
                <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
              </el-tooltip>
            </div>
          </div>
          <div class="row" v-if="showCriteria">
            <div class="col-sm-11">
              <div class="form-group">
                <label>Data zakończenia</label>
                <el-date-picker v-model="criteriaModel.date" type="date" placeholder="Wybierz dzień"> </el-date-picker>
              </div>
            </div>
            <div class="col-sm-1 helper">
              <el-tooltip class="item" effect="light" placement="top" :open-delay="300">
                <div slot="content">Data zakończenia blacklisty</div>
                <i class="fa fa-lg fa-question-circle-o" aria-hidden="true"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div v-if="showCriteria" class="col-sm-6">
          <div class="center criteriaRow"><b>Wybrane kryteria</b></div>
          <div class="criteriaRow">
            <span
              ><b>{{ blacklistMode === "phone" ? "Telefon" : "Mail" }}: </b></span
            >
            <span class="criteriaItem">{{ blacklistMode === "phone" ? model.phone : model.mail }}</span>
          </div>
          <div class="criteriaRow">
            <span><b>Spółka: </b></span>
            <span
              v-for="(selectedOwner, index) in criteriaModel.owner"
              @click="deleteCriteria(index, 'owner')"
              :key="selectedOwner"
              class="criteriaItem"
              >{{ blacklistCriteria.owners.find((x) => x.owner_id === selectedOwner).owner_name + ", " }}</span
            >
          </div>
          <div class="criteriaRow">
            <span><b>Brand: </b></span>
            <span
              v-for="(selectedBrand, index) in criteriaModel.brand"
              @click="deleteCriteria(index, 'brand')"
              :key="selectedBrand"
              class="criteriaItem"
              >{{ blacklistCriteria.brands.find((x) => x.brand_id === selectedBrand).brand_name + ", " }}</span
            >
          </div>
          <div class="criteriaRow">
            <span><b>Projekt: </b></span>
            <span
              v-for="(selectedProject, index) in criteriaModel.project"
              @click="deleteCriteria(index, 'project')"
              :key="selectedProject"
              class="criteriaItem"
              >{{ blacklistCriteria.projects.find((x) => x.sms_project_id === selectedProject).sms_project_name + ", " }}</span
            >
          </div>
          <div class="criteriaRow" v-if="blacklistMode === 'phone'">
            <span><b>Bramka: </b></span>
            <span
              v-for="(selectedGate, index) in criteriaModel.gate"
              @click="deleteCriteria(index, 'gate')"
              :key="selectedGate"
              class="criteriaItem"
              >{{ blacklistCriteria.sms_premium_numbers.find((x) => x.sms_premium_number_id === selectedGate).sms_premium_number + ", " }}</span
            >
          </div>
          <div class="criteriaRow">
            <span><b>Data zakończenia: </b></span>
            <span class="criteriaItem">{{ criteriaDate }}</span>
          </div>
        </div>
      </div>
      <div class="row center" v-if="mode === 'delete'">
        <span class="criteriaItem" @click="switchCriteriaMode">{{ showCriteria ? "Zamknij" : "Pokaż" }} kryteria</span>
      </div>
      <div class="modalRow center" v-if="mode === 'search'">
        <p-button style="margin-right: 5px" link @click="$emit('close')">Anuluj</p-button>
        <p-button :disabled="!checkData" type="success" style="margin-right: 5px" @click="blacklistHistory()">Sprawdź</p-button>
        <p-button :disabled="!checkData" type="success" style="margin-right: 5px" @click="phoneHistory">Historia</p-button>
      </div>
      <div class="modalRow center" v-else-if="mode === 'delete'">
        <p-button style="margin-right: 5px" link @click="$emit('close')">Anuluj</p-button>
        <p-button :disabled="!checkData" type="danger" @click="blacklistAdd()">Usuń</p-button>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip, DatePicker } from "element-ui";

import moment from "moment";

import * as regexp from "src/regexp.js";

// event bus
import { eventBus } from "src/main";

import BlacklistHistory from "src/components/Utils4/complaints/views/BlacklistHistory.vue";

import { LOAD_BLACKLIST_CHECK, BLACKLIST_CHECK_INVALID } from "src/store/actions/blacklist/blacklist_check";
import { SAVE_BLACKLIST_ADD } from "src/store/actions/blacklist/blacklist_add";
import { SAVE_SET_API_CALL } from "src/store/actions/complaints/actions/set_api_call";

import { LOAD_BLACKLIST_MSISDN_CRITERIA } from "src/store/actions/blacklist/blacklist_msisdn_criteria";
import { LOAD_BLACKLIST_EMAIL_CRITERIA } from "src/store/actions/blacklist/blacklist_email_criteria";
import { SAVE_BLACKLIST_MSISDN } from "src/store/actions/blacklist/blacklist_msisdn";
import { SAVE_BLACKLIST_EMAIL } from "src/store/actions/blacklist/blacklist_email";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [DatePicker.name]: DatePicker,
  },
  props: ["phone", "mail", "mailbox", "company", "complaintID", "mode", "blacklistMode"],
  data() {
    return {
      checkedPhone: null,
      showCriteria: false,
      model: {
        phone: "",
        mail: "",
      },
      modelValidations: {
        phone: {
          numeric: true,
          min: 9,
          max: 11,
        },
        mail: {
          regex: regexp.EMAIL_REGEXP,
          min: 1,
          max: 128,
        },
      },
      criteriaTempModel: {
        owner: null,
        project: null,
        brand: null,
        gate: null,
      },
      criteriaModel: {
        owner: [],
        project: [],
        brand: [],
        gate: [],
        date: null,
      },
    };
  },
  computed: {
    blacklistCriteria() {
      if (this.blacklistMode === "email") {
        if (this.$store.state.blacklist_email_criteria.loading_status === "success") {
          return this.$store.getters.getBlacklistEmailCriteria;
        } else {
          return { brands: [], owners: [], projects: [], sms_premium_numbers: [] };
        }
      } else {
        if (this.$store.state.blacklist_msisdn_criteria.loading_status === "success") {
          return this.$store.getters.getBlacklistMsisdnCriteria;
        } else {
          return { brands: [], owners: [], projects: [], sms_premium_numbers: [] };
        }
      }
    },
    header() {
      if (this.mode === "search") {
        return "Wyszukaj historię blacklisty SMS";
      } else {
        if (this.blacklistMode === "email") {
          return "Blacklista Email";
        } else {
          return "Blacklista SMS";
        }
      }
    },
    checkData() {
      if (this.model.phone === "" && !(this.model.phone.length === 9 || this.model.phone.length === 11)) return false;
      else return true;
    },
    criteriaDate() {
      if (this.criteriaModel.date) {
        return moment(this.criteriaModel.date).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
  },
  methods: {
    switchCriteriaMode() {
      this.showCriteria = !this.showCriteria;
      this.criteriaModel = {
        owner: [],
        project: [],
        brand: [],
        gate: [],
        date: null,
      };
    },
    criteriaInput(e, criteria) {
      if (!this.criteriaModel[criteria].includes(e)) {
        this.criteriaModel[criteria].push(e);
      }
      this.criteriaTempModel[criteria] = null;
    },
    deleteCriteria(index, criteria) {
      this.criteriaModel[criteria].splice(index, 1);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData() {
      this.$store.dispatch(LOAD_BLACKLIST_MSISDN_CRITERIA, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_BLACKLIST_EMAIL_CRITERIA, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    async blacklistCheck() {
      this.checkedPhone = null;

      if (!this.checkData) return;

      this.model.phone = this.model.phone.length === 9 ? "48" + this.model.phone : this.model.phone;

      const model = {
        msisdn: this.model.phone,
        system_user_id: this.$store.state.profile.data.ID,
      };

      this.$store.commit(BLACKLIST_CHECK_INVALID);

      await this.$store.dispatch(LOAD_BLACKLIST_CHECK, model).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.checkedPhone = this.$store.getters.getBlacklistCheck;
    },
    async setAPICall(data) {
      let result = null;

      await this.$store.dispatch(SAVE_SET_API_CALL, data).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getSetAPICallModifiedRecords) {
        result = this.$store.getters.getSetAPICallModifiedRecords;
      }

      return result;
    },
    blacklistHistory() {
      if (!this.checkData) {
        eventBus.$emit("notify", {
          message: "Niepoprawny numer telefonu!",
          type: "warning",
        });
        return;
      }

      const phone = this.model.phone.length == 9 ? "48" + this.model.phone : this.model.phone;

      this.$emit("close");
      console.log(this.model);
      this.$modal.show(
        BlacklistHistory,
        {
          phone: phone,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(BlacklistHistory);
              },
            },
          ],
        },
        {
          draggable: false,
          scrollable: true,
          width: "500px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    phoneHistory() {
      this.$emit("close");
      this.$router.push({
        name: "Historia numeru",
        params: {
          phone: this.model.phone,
          company: this.company,
          mailbox: this.mailbox,
          complaintID: this.complaintID,
        },
      });
    },
    async blacklistAdd() {
      if (!this.checkData) {
        eventBus.$emit("notify", {
          message: "Niepoprawny numer telefonu!",
          type: "warning",
        });
        return;
      }
      const phone = this.model.phone.length == 9 ? "48" + this.model.phone : this.model.phone;
      const mail = this.model.mail;

      let model = {
        company_id: this.company,
        complaint_id: this.complaintID,
        system_user_id: this.$store.state.profile.data.ID,
        token: this.$store.state.auth.token,
      };

      if (this.showCriteria) {
        model = Object.assign(model, {
          owner_ids: this.criteriaModel.owner,
          brand_ids: this.criteriaModel.brand,
          sms_project_ids: this.criteriaModel.project,
          sms_premium_number_ids: this.criteriaModel.gate,
        });
        if (this.criteriaModel.date) {
          model.expiration_date = moment(this.criteriaModel.date).format("YYYY-MM-DD");
        }
      }

      let apiCallData = {
        complaint_id: this.complaintID,
        operation_type: "BLACKLIST", // TODO: add to complaint flow enum
      };

      if (this.blacklistMode === "phone") {
        model.msisdns = [phone];
        apiCallData.user_msisdn = phone;
      } else {
        model.emails = [mail];
        apiCallData.user_email = mail;
      }

      let complaint_flow_id = await this.setAPICall(apiCallData);

      if (!complaint_flow_id) {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
        return;
      }

      model.complaint_flow_id = complaint_flow_id;

      console.log(model);

      const dispatchTarget = this.blacklistMode === "phone" ? SAVE_BLACKLIST_MSISDN : SAVE_BLACKLIST_EMAIL;
      const dispatchGetter = this.blacklistMode === "phone" ? "getBlacklistMsisdnModifiedRecords" : "getBlacklistEmailModifiedRecords";

      await this.$store.dispatch(dispatchTarget, model).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters[dispatchGetter]) {
        eventBus.$emit("notify", {
          message: `Zlecono blacklistę ${this.blacklistMode === "phone" ? "numeru telefonu" : "adresu email"}`,
          type: "success",
        });
        eventBus.$emit("handleLeadCompleted");
        this.$emit("close");
      }
    },
  },
  async created() {
    this.loadData();
    if (this.phone.length > 0) {
      this.model.phone = this.phone[0];
    }
    if (this.mail.length > 0) {
      this.model.mail = this.mail[0];
    }
    if (this.mode === "search") {
      await this.blacklistCheck();
    }
  },
};
</script>

<style lang="scss" scoped>
.modalRow {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
/* .row:last-child {
  justify-content: center;
} */
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.formButton {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.helper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.blacklist-paragraph {
  margin-bottom: 6px;
}

.pointer {
  cursor: pointer;
}

.criteriaRow {
  margin-bottom: 10px;
}

.criteriaItem {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.criteriaItem:hover {
  opacity: 0.6;
}
</style>
