<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Raport zdarzeń</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="-"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label>Typ zmiany</label>
            <fg-input>
              <el-select v-model="filter.type" name="type" filterable clearable>
                <el-option v-for="type in typesDict" :key="type.value" :label="type.label" :value="type.value"> </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-6">
            <label>Obiekt zmiany</label>
            <fg-input>
              <el-select v-model="filter.changeObject" name="object" filterable clearable>
                <el-option v-for="object in objectsDict" :key="object.value" :label="object.label" :value="object.value"> </el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label>Krytyczność zmiany</label>
            <fg-input>
              <el-select v-model="filter.criticality" name="criticality" filterable clearable>
                <el-option
                  v-for="criticality in [
                    { label: 'Krytyczne', value: 'critical' },
                    { label: 'Niekrytyczne', value: 'noncritical' },
                  ]"
                  :key="criticality.value"
                  :label="criticality.label"
                  :value="criticality.value"
                >
                </el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-6">
            <label>Autor zmiany</label>
            <fg-input>
              <el-select v-model="filter.author" name="author" filterable clearable>
                <el-option
                  v-for="author in users"
                  :key="author.user_id"
                  :label="author.user_last_name + ' ' + author.user_first_name"
                  :value="author.user_id"
                >
                </el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="card-footer text-center">
          <p-button type="success" @click.prevent="loadStats()">Wczytaj</p-button>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <el-table :data="events" style="width: 100%" :default-sort="{ prop: 'ID', order: 'descending' }">
          <el-table-column label="ID" property="ID" width="100" align="center" sortable></el-table-column>
          <el-table-column label="Akcja" property="ActionName" width="150" align="center" sortable></el-table-column>
          <el-table-column label="Treść" property="Message" min-width="120" align="left" sortable>
            <template v-slot="props">
              <div style="word-break: keep-all">{{ props.row.Message }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Data" property="AddDate" width="180" align="center" sortable></el-table-column>
          <el-table-column label="Użytkownik" property="UserName" width="200" align="left" sortable></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="block center">
      <el-pagination
        v-on:current-change="loadStats($event)"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="maxPage * pageLimit"
        :page-size="pageLimit"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import utils from "src/utils";

// event bus
import { eventBus } from "src/main";

import { Select, Option, DatePicker, Pagination } from "element-ui";

import { LOAD_EVENTS_RAPORT, EVENTS_RAPORT_INVALID } from "src/store/actions/raports/events_raport";
import { LOAD_USERS, USERS_INVALID } from "src/store/actions/admin/users";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      loading: false,
      page: 1,
      maxPage: 1,
      pageLimit: 20,
      filter: {
        type: "",
        changeObject: "",
        criticality: "",
        author: "",
      },
      typesDict: [
        { label: "Pytania", value: "questions" },
        { label: "Odpowiedzi", value: "answers" },
        { label: "Konfiguracja", value: "config" },
        { label: "Kolejność", value: "order" },
        { label: "Limity", value: "limits" },
        { label: "Nagrody", value: "prizes" },
      ],
      objectsDict: [
        { label: "Pytania", value: "questions" },
        { label: "Projekty", value: "projects" },
      ],
      dateRange: [utils.today(), utils.today()],
      pickerOptions: {
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    async initLoad() {
      this.loading = true;

      await this.$store.dispatch(LOAD_USERS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    async loadStats(currentPage) {
      this.loading = true;

      if (currentPage) {
        this.page = currentPage;
      } else {
        this.page = 1;
      }

      const filters = {
        type: this.filter.type,
        changeObject: this.filter.changeObject,
        criticality: this.filter.criticality,
        author: this.filter.author,
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        page: this.page,
      };

      this.$store.commit(EVENTS_RAPORT_INVALID);

      await this.$store.dispatch(LOAD_EVENTS_RAPORT, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
  },
  computed: {
    events() {
      let data = [];
      if (this.$store.state.events_raport.loading_status === "success") {
        data = this.$store.getters.getEventsRaport["events"];
        this.maxPage = Math.ceil(this.$store.getters.getEventsRaport.counter / this.pageLimit);
      }
      return data;
    },
    users() {
      return Object.values(this.$store.getters.getUsers)
        .filter((x) => x.user_partner_name === "Mobime")
        .sort((a, b) => (a.user_last_name.toLowerCase() > b.user_last_name.toLowerCase() ? 1 : -1));
    },
  },
  created() {
    this.initLoad();
    if (this.$route.params.param) {
      this.filter.type = this.$route.params.param;
      this.loadStats();
    }
  },
};
</script>
<style scoped>
.center {
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
}
</style>
