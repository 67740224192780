<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Wyniki testów szczegółowe</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="-"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd"
        ></el-date-picker>
      </div>
    </div>

    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <label for="project">Projekt</label>
            <el-select
              id="testResultsDetailedProjectSelect"
              v-on:visible-change="getUpperLeftSelectWidth()"
              v-model="projectsArray"
              name="project"
              data-vv-as="Projekt"
              style="width: 100%"
              multiple
              filterable
              clearable
            >
              <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                <el-option
                  :style="upperLeftSelectWidth !== null ? 'width: ' + upperLeftSelectWidth + 'px' : 'width: 300px'"
                  :id="'testResultsProjectSelectDetailedOption_' + item.project_id"
                  v-for="item in group.options"
                  :key="item.project_id"
                  :label="item.project_name + ' [' + item.project_id + ']'"
                  :value="item.project_id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </div>
          <div class="col-sm-2">
            <label for="project">Scenariusz</label>
            <el-select
              id="testResultsDetailedScenarioTypeSelect"
              v-model="scenarioTypeFilter"
              name="scenariusz"
              data-vv-as="Scenariusz"
              style="width: 100%"
              filterable
              clearable
            >
              <el-option v-for="scenario in scenarioTypes" :key="scenario.value" :label="scenario.text" :value="scenario.value"> </el-option>
            </el-select>
          </div>
          <div class="col right-bottom">
            <el-switch
              class="leftSwitch"
              name="showLastTestResultsDetailed"
              style="margin: 0 20px 20px 0"
              v-model="showLast"
              inactive-text="Ostatni przebieg"
              active-color="#28a745"
              inactive-color="#dc3545"
            ></el-switch>
            <el-switch
              name="isAutoTestsResultsDetailed"
              style="margin: 0 20px 20px 0"
              v-model="isAuto"
              active-color="#17a2b8"
              active-text="Automatyczne"
              inactive-color="gray"
              inactive-text="Ręczne"
            ></el-switch>
            <p-button type="success" @click="loadData">Pokaż</p-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body row">
        <el-table :data="tableData" style="width: 100%" :header-cell-style="headerStyle" @cell-click="cellClick">
          <el-table-column label="ID" property="project_id" align="right" width="70">
            <template v-slot="props">
              <div v-if="props.row.mode === 'project'">
                {{ props.row.project_id }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Nazwa" property="content" align="left" min-width="300">
            <template v-slot="props">
              <div v-if="props.row.mode === 'project'" style="cursor: pointer; word-break: keep-all">
                {{ projects[props.row.project_id].project_name }}
              </div>
              <div v-else-if="props.row.mode === 'version'" style="padding-left: 50px; cursor: pointer; word-break: keep-all">
                {{ props.row.version }}
              </div>
              <div v-else-if="props.row.mode === 'module'" style="padding-left: 100px; cursor: pointer; word-break: keep-all">
                {{ props.row.start_module }}
              </div>
              <div v-else-if="props.row.mode === 'test'" style="text-align: right">
                {{ props.row.scenario_type }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Test" property="test_name" align="right" min-width="300"></el-table-column>
          <el-table-column label="Data" property="finish_time" align="right" width="180"></el-table-column>
          <el-table-column label="Status" property="status" align="right" width="110">
            <template v-slot="props">
              <el-tag
                v-if="props.row.status === 'SUCCESS' || props.row.status === 'FAILED'"
                class="test-results__status-tag"
                :type="props.row.status === 'SUCCESS' ? 'success' : 'danger'"
                size="small"
                >{{ props.row.status === "SUCCESS" ? "SUKCES" : "BŁĄD" }}</el-tag
              >
              <span v-else :class="checkStatus(props.row) ? 'test-results__bad-status' : ''">{{ props.row.status }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" width="80">
            <template v-slot="props">
              <p-button v-if="props.row.mode === 'test'" type="info" size="sm" icon @click="handleDetails(props.row)">
                <i class="fa fa-info" aria-hidden="true"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";

import { DatePicker, Select, Option, OptionGroup, Tag, Switch } from "element-ui";

import { LOAD_TESTS_RESULTS, TESTS_RESULTS_INVALID } from "src/store/actions/tests/tests_results";
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_TESTS_PROJECTS, TESTS_PROJECTS_INVALID } from "../../../store/actions/tests/tests_projects";

import TestsResultsModal from "src/components/Utils4/tests/TestsResultsModal.vue";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tag.name]: Tag,
    [Switch.name]: Switch,
  },
  data() {
    return {
      loading: false,
      isAuto: true,
      showLast: false,
      projectsArray: [],
      scenarioTypes: [
        { text: "POZYTYWNY", value: "POSITIVE" },
        { text: "NEGATYWNY", value: "NEGATIVE" },
      ],
      scenarioTypeFilter: "",
      dateRange: ["", ""],
      upperLeftSelectWidth: null,
      tableBoolObject: {},
      counter: 0,
      referenceCounter: 0,
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    async initLoad(force) {
      this.loading = true;

      if (force) {
        this.$store.commit(TESTS_PROJECTS_INVALID);
        this.$store.commit(PROJECT_DICT_INVALID);
      }

      this.$store.dispatch(LOAD_TESTS_PROJECTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.loadData();

      this.loading = false;
    },
    async loadData() {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        project_list: this.projectsArray,
        detailed: true,
        isAuto: this.isAuto,
        showLast: this.showLast,
        scenarioType: this.scenarioTypeFilter,
      };

      this.$store.commit(TESTS_RESULTS_INVALID);

      await this.$store.dispatch(LOAD_TESTS_RESULTS, filters).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
      this.createBoolObjects();
    },
    createBoolObjects() {
      let data = this.testsResults;
      for (let pid in data) {
        this.tableBoolObject[pid + "_ALL_VERSIONS_ALL_MODULES"] = false;
        for (let ver in data[pid].versions) {
          this.tableBoolObject[pid + "_" + ver + "_ALL_MODULES"] = false;
          for (let mod in data[pid]["versions"][ver]["modules"]) {
            this.tableBoolObject[pid + "_" + ver + "_" + mod] = false;
          }
        }
      }
      this.counter++;
    },
    cellClick(row, column) {
      let columnName = column.property;
      let pid = row.project_id;
      let ver = row.version;
      let mod = row.start_module;
      let mode = row.mode;
      if (columnName === "content") {
        if (mode === "project") {
          if (this.tableBoolObject[pid + "_ALL_VERSIONS_ALL_MODULES"]) {
            let prefix = pid + "_";
            let regex = new RegExp(prefix + ".+", "g");
            for (let key in this.tableBoolObject) {
              if (key.search(regex) !== -1) {
                this.tableBoolObject[key] = false;
              }
            }
          } else {
            this.tableBoolObject[pid + "_ALL_VERSIONS_ALL_MODULES"] = !this.tableBoolObject[pid + "_ALL_VERSIONS_ALL_MODULES"];
          }
          this.counter++;
        } else if (mode === "version") {
          if (this.tableBoolObject[pid + "_" + ver + "_ALL_MODULES"]) {
            let prefix = pid + "_" + ver + "_";
            let regex = new RegExp(prefix + ".+", "g");
            for (let key in this.tableBoolObject) {
              if (key.search(regex) !== -1) {
                this.tableBoolObject[key] = false;
              }
            }
          } else {
            this.tableBoolObject[pid + "_" + ver + "_ALL_MODULES"] = !this.tableBoolObject[pid + "_" + ver + "_ALL_MODULES"];
          }
          this.counter++;
        } else if (mode === "module") {
          this.tableBoolObject[pid + "_" + ver + "_" + mod] = !this.tableBoolObject[pid + "_" + ver + "_" + mod];
          this.counter++;
        }
      }
    },
    getUpperLeftSelectWidth() {
      this.upperLeftSelectWidth = document.getElementById("testResultsDetailedProjectSelect").offsetWidth;
    },
    headerStyle(obj) {
      return "font-size: 12px;";
    },
    checkStatus({ status }) {
      if (status === "SUCCESS" || status === "FAILED") {
        return false;
      } else {
        const testSuccess = status.split("/")[0];
        const testAll = status.split("/")[1];
        if (testSuccess === testAll) {
          return false;
        } else {
          return true;
        }
      }
    },
    handleDetails(row) {
      // wyświetlenie modala szczegółów
      this.$modal.show(
        TestsResultsModal,
        {
          row: row,
          projects: this.projects,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(TestsResultsModal);
              },
            },
          ],
        },
        {
          name: "TestsResultsModal",
          draggable: false,
          scrollable: true,
          width: "60%",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
  },
  computed: {
    testsProjects() {
      return this.$store.getters.getTestsProjects;
    },
    projects() {
      return this.$store.getters.getProjectDict;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      const projects = Object.values(this.$store.getters.getProjectDict);
      let testProjects = this.testsProjects;

      testProjects = _.isArray(testProjects) ? testProjects : [];

      let projects_filtered = projects.filter((x) => testProjects.includes(x.project_id));
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects_filtered.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    testsResults() {
      return this.$store.getters.getTestsResults;
    },
    tableData() {
      let testsResults = this.testsResults;
      const testsResults_array = Object.values(testsResults);
      testsResults_array.sort((a, b) =>
        this.projects[a.project_id].project_name.toLowerCase() > this.projects[b.project_id].project_name.toLowerCase() ? 1 : -1,
      );

      let tableData = [];
      let tableBoolObject = this.tableBoolObject;
      let counter = this.counter;
      let referenceCounter = this.referenceCounter;

      if (counter !== referenceCounter) {
        // licznik wymuszający przeliczenie tabeli
        for (let i in testsResults_array) {
          // iteracja wyświetleń  na poziomie pytania
          let pid = testsResults_array[i].project_id;
          tableData.push({
            // dodanie poziomu projektu
            project_id: pid,
            project_name: this.projects[pid].project_name,
            version: "-",
            start_module: "-",
            module: "-",
            finish_time: "-",
            test_name: "-",
            status: testsResults_array[i].success_tests_no + "/" + testsResults_array[i].tests_no,
            mode: "project",
          });

          for (let ver in testsResults[pid].versions) {
            // iteracja wyświetleń po poziomie wersji
            if (this.tableBoolObject[pid + "_ALL_VERSIONS_ALL_MODULES"]) {
              // sprawdzenie czy są wyświetlanie wersje
              tableData.push({
                // dodanie poziomu projektu
                project_id: pid,
                project_name: this.projects[pid].project_name,
                version: ver,
                start_module: "-",
                module: "-",
                finish_time: "-",
                test_name: "-",
                status: testsResults[pid].versions[ver].success_tests_no + "/" + testsResults[pid].versions[ver].tests_no,
                mode: "version",
              });
            }

            for (let mod in testsResults[pid].versions[ver].modules) {
              // iteracja wyświetleń po poziomie modułów
              if (this.tableBoolObject[pid + "_" + ver + "_ALL_MODULES"]) {
                // sprawdzenie czy są wyświetlane moduły
                const modObj = testsResults[pid].versions[ver].modules;

                tableData.push({
                  // dodanie poziomu modułu
                  project_id: pid,
                  project_name: this.projects[pid].project_name,
                  version: ver,
                  start_module: modObj[mod].start_module,
                  module: modObj[mod].module,
                  finish_time: "-",
                  test_name: "-",
                  status: modObj[mod].success_tests_no + "/" + modObj[mod].tests_no,
                  mode: "module",
                });
              }

              for (let test in testsResults[pid].versions[ver].modules[mod].tests) {
                // iteracja wyświetleń po poziomie testów
                if (this.tableBoolObject[pid + "_" + ver + "_" + mod]) {
                  // sprawdzenie czy są wyświetlane testy
                  let testObject = testsResults[pid].versions[ver].modules[mod].tests[test];
                  testObject.project_name = this.projects[pid].project_name;
                  testObject.mode = "test";
                  testObject.test_name = JSON.parse(testObject.test_config).test_name + " [" + testObject.module + "]";

                  tableData.push(testObject); // dodanie poziomu testu
                }
              }
            }
          }
        }
        referenceCounter++;
      }
      return tableData;
    },
  },
  mounted() {},
  created() {
    let params = this.$route.params;
    if (params.start_time) {
      this.dateRange[0] = params.start_time;
      this.dateRange[1] = params.start_time;
    } else {
      const today = moment(utils.today()).format("YYYY-MM-DD");
      this.dateRange[0] = today;
      this.dateRange[1] = today;
    }
    if (params.project_id) {
      this.projectsArray = [params.project_id];
    }
    if (params.show_last) {
      this.showLast = params.show_last;
    }
    if (params.is_auto) {
      this.isAuto = params.is_auto;
    }
    if (params.scenario_type) {
      this.scenarioTypeFilter = params.scenario_type;
    }
    eventBus.$on("forceReload", () => {
      this.loadData();
      this.initLoad(true);
    });
    this.initLoad(false);
  },
  beforeDestroy: function () {
    this.$store.commit(TESTS_RESULTS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.right-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.leftSwitch {
  margin-right: 20px;
}

.leftSwitch::after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid rgb(179, 177, 177);
  top: 0;
  left: 175px;
}
</style>

<style>
.mailing-efficiency__hide-expand :first-child {
  visibility: hidden !important;
}

.test-results__status-tag {
  color: white !important;
}

.test-results__bad-status {
  background-color: #ffd396;
  padding: 3px;
  border-radius: 10px;
}
</style>
