import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  SAVE_CLONE_QUESTION,
  CLONE_QUESTION_SAVING,
  CLONE_QUESTION_SAVING_SUCCESS,
  CLONE_QUESTION_SAVING_ERROR
} from 'src/store/actions/questions/clone_question'

const storeObjectConfig = {
  objectName: "CloneQuestionForm",
  getterName: "getCloneQuestion",
  saveActionName: SAVE_CLONE_QUESTION,
  savingMutationName: CLONE_QUESTION_SAVING,
  savingSuccessMutationName: CLONE_QUESTION_SAVING_SUCCESS,
  savingErrorMutationName: CLONE_QUESTION_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  saveParams: {
    url: "/questions/questions/clone_question",
    method: "put" // {'get','post','put'}
  },
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
