import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_MAILING_EFFICIENCY,
  MAILING_EFFICIENCY_LOADING,
  MAILING_EFFICIENCY_LOADING_SUCCESS,
  MAILING_EFFICIENCY_LOADING_ERROR,
  MAILING_EFFICIENCY_INVALID
} from 'src/store/actions/secure/mailing_efficiency'

const storeObjectConfig = {
  objectName: "MailingEfficiency",
  getterName: "getMailingEfficiency",
  loadActionName: LOAD_MAILING_EFFICIENCY,
  loadingMutationName: MAILING_EFFICIENCY_LOADING,
  loadingSuccessMutationName: MAILING_EFFICIENCY_LOADING_SUCCESS,
  loadingErrorMutationName: MAILING_EFFICIENCY_LOADING_ERROR,
  invalidMutationName: MAILING_EFFICIENCY_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/mailingEfficiency",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
