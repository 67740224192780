<template>
  <div class="card">
    <div class="row header_row">
      <div class="col-sm-10" v-if="!isNew">
        <h5 v-if="mode === 'in_deduplication'" class="title">W deduplikacji {{ row.client_name }}</h5>
        <h5 v-else class="title">Deduplikacja {{ row.client_name }}</h5>
      </div>
      <div class="col-sm-10" v-else>
        <h5 class="title">Nowa deduplikacja</h5>
      </div>
      <div>
        <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer; opacity: 0.5" aria-hidden="true"></i>
      </div>
    </div>

    <div class="row selectRow" v-if="isNew">
      <div class="col-sm-6">
        <span class="selectLabel">Klient:</span>
        <el-select v-model="client_id" filterable name="Klient" data-vv-as="Klient">
          <el-option v-for="client in availableClients" :label="client.client_name" :value="client.client_id" :key="client.client_id"></el-option>
        </el-select>
      </div>

      <div class="col-sm-6">
        <span class="selectLabel">Rodzaj:</span>
        <el-select v-model="deduplication_mode" name="Rodzaj" data-vv-as="Rodzaj">
          <el-option
            v-for="selectedMode in [
              { label: 'Deduplikacja', value: 'deduplication' },
              { label: 'W deduplikacji', value: 'in_deduplication' },
            ]"
            :label="selectedMode.label"
            :value="selectedMode.value"
            :key="selectedMode.value"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-1 fieldColumn"><div class="fieldLabel">Filtry</div></div>
      <div class="col-sm-3 fieldColumn">
        <fg-input class="input-sm deduplicationModal__fieldInput" placeholder="Sprzedaż" v-model="filterObject.client_name"> </fg-input>
      </div>
      <div class="col-sm-3 fieldColumn">
        <fg-input class="input-sm deduplicationModal__fieldInput" placeholder="Partner" v-model="filterObject.partner_name"> </fg-input>
      </div>
      <div class="col-sm-3 fieldColumn">
        <fg-input class="input-sm deduplicationModal__fieldInput" placeholder="Grupa" v-model="filterObject.group_name"> </fg-input>
      </div>
      <div class="col-sm-2 fieldColumn">
        <el-button class="fieldButton" @click="clearFields">Wyczyść</el-button>
      </div>
    </div>
    <div class="row tableRow">
      <el-table ref="multipleTable" :data="tableData" style="width: 100%" size="mini" height="300px">
        <el-table-column width="55">
          <template v-slot="props">
            <input
              v-if="!refreshTable"
              type="checkbox"
              :id="'deduplication_checkbox_' + props.row.client_id"
              v-model="selectedClients[props.row.client_id]"
            />
          </template>
        </el-table-column>
        <el-table-column label="ID" width="60" property="client_id"> </el-table-column>
        <el-table-column property="client_name" label="Nazwa" min-width="140" show-overflow-tooltip> </el-table-column>
        <el-table-column property="partner_name" label="Partner" min-width="140" show-overflow-tooltip> </el-table-column>
        <el-table-column property="group_name" label="Branża" width="160" show-overflow-tooltip> </el-table-column>
      </el-table>
      <div style="margin-top: 20px" class="bottomRow">
        <div>
          <el-button @click="toggleSelection(true)">Zaznacz widoczne</el-button>
          <el-button @click="toggleSelection(false)">Odznacz widoczne</el-button>
        </div>
        <div class="daysColumn">
          <span>Dni</span>
          <fg-input type="number" min="0" v-model="days"></fg-input>
        </div>
      </div>
    </div>

    <div class="row">
      <p-button type="warning" @click="$emit('close')">Anuluj</p-button>
      <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip, InputNumber, Button, Checkbox } from "element-ui";

import { SAVE_DEDUPLICATION, DEDUPLICATION_INVALID } from "src/store/actions/sales/deduplication";

// event bus
import { eventBus } from "src/main";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [InputNumber.name]: InputNumber,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  props: ["mode", "row", "isNew"],
  data() {
    return {
      client_id: this.row ? this.row.client_id : null,
      deduplication_mode: this.mode,
      propsToSearch: ["client_name", "partner_name", "group_name"],
      filterObject: {
        client_name: "",
        partner_name: "",
        group_name: "",
      },
      selectedClients: {},
      days: 1,
      refreshTable: false,
    };
  },
  computed: {
    deduplication() {
      return this.$store.getters.getDeduplication;
    },
    availableClients() {
      let getter = JSON.parse(JSON.stringify(this.$store.getters.getDeduplicationActiveClients));
      if (this.isNew) {
        for (let i in this.deduplication[this.deduplication_mode]) {
          if (getter[i]) delete getter[i];
        }
      }

      return getter;
    },
    deduplicationActiveClients() {
      let getter = JSON.parse(JSON.stringify(this.$store.getters.getDeduplicationActiveClients));
      if (!this.isNew) {
        for (let i in this.row[this.deduplication_mode]) {
          if (getter[i]) delete getter[i];
        }
      }

      return getter;
    },
    deduplicationClients() {
      return this.$store.getters.getDeduplicationClients;
    },
    tableData() {
      if (this.refreshTable) {
        this.refreshTable = false;
      }
      let result = Object.values(this.deduplicationActiveClients).sort((a, b) =>
        a.client_name.toLowerCase() > b.client_name.toLowerCase() ? 1 : -1,
      );

      result = result.filter((row) => {
        let resultArray = [];
        for (let key of this.propsToSearch) {
          let isIncluded = false;
          let rowValue;
          if (row[key]) {
            rowValue = row[key].toString().toLowerCase();
          }
          if (!rowValue || rowValue.includes(this.filterObject[key].toLowerCase())) {
            isIncluded = true;
          }
          if (!row[key] && this.filterObject[key] !== "") isIncluded = false;

          resultArray.push(isIncluded);
        }

        if (resultArray.includes(false)) return false;
        return true;
      });

      return result;
    },
  },
  methods: {
    toggleSelection(bool) {
      this.tableData.forEach((row) => (this.selectedClients[row.client_id] = bool));
      this.refreshTable = true;
    },
    clearFields() {
      this.filterObject = {
        client_name: "",
        partner_name: "",
        group_name: "",
      };
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const selectedClients = [];
      for (let i in this.selectedClients) {
        if (this.selectedClients[i]) selectedClients.push(i);
      }

      if (!this.client_id) {
        return eventBus.$emit("notify", {
          message: "Brak wybranego klienta!",
          type: "warning",
        });
      }

      if (selectedClients.length < 1) {
        return eventBus.$emit("notify", {
          message: "Nie zaznaczono żadnych klientów!",
          type: "warning",
        });
      }

      const model = {
        mode: this.deduplication_mode,
        client_id: this.client_id,
        add_array: selectedClients,
        days: this.days,
      };

      await this.$store.dispatch(SAVE_DEDUPLICATION, model).catch((error) => {
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getDeduplicationModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Rekord został dodany",
          type: "success",
        });
        eventBus.$emit("deduplicationSaved");
        this.$emit("close");
      }
    },
  },
  async created() {
    for (let i in this.deduplicationActiveClients) {
      this.selectedClients[i] = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}

.tableRow {
  display: block;
}

.fieldLabel {
  color: #66615b;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  background-color: #e9ecef;
  border: 1px solid #dddddd;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fieldColumn {
  padding: 0;
}

.fieldButton {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0.5px;
  height: 39px;
}

.bottomRow {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.daysColumn {
  display: flex;
  flex-direction: row;

  span {
    margin-right: 10px;
    margin-top: 8px;
  }
}

.selectRow {
  display: flex;
  align-content: center;
  text-align: center;
}

.selectLabel {
  margin-right: 15px;
}
</style>

<style>
.deduplicationModal__fieldInput input {
  border-radius: 0;
  border-left: 0.5px;
}
</style>
