<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title" v-if="mode === 'pause'">{{ status_id == 4 ? "Zawieś" : "Przywróć" }} reklamacje</h5>
          <h5 class="title" v-else>Dodaj komentarz do reklamacji</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p v-if="mode === 'pause'">Status reklamacji zostanie zmieniony na '{{ status_id == 4 ? "Zawieszony" : "Otwarty" }}'</p>

          <div class="attachment-icon">
            <el-tooltip class="item" effect="light" content="Dodaj załącznik" placement="top" :open-delay="500">
              <div class="action-icon files-icon">
                <i class="fa fa-lg fa-paperclip" aria-hidden="true"></i>
                <input type="file" class="inputfile" id="commentAttachment" ref="file" multiple v-on:change="handleFileUpload" />
              </div>
            </el-tooltip>
          </div>
          <div class="form-group">
            <label>Komentarz</label>
            <el-input
              type="textarea"
              name="komentarz"
              :class="{ textarea: true, 'is-danger': errors.has('form.komentarz') }"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="Treść komentarza"
              v-model="model.message"
              v-validate="modelValidations.message"
            >
            </el-input>
            <span v-show="errors.has('komentarz')" class="help is-danger text-danger">{{ errors.first("komentarz") }}</span>
          </div>

          <div v-if="fileNames.length > 0">
            <hr />
            <div>Dodane załączniki:</div>
            <div v-for="(file, index) in fileNames" :key="index">
              <span class="response_files" @click="deleteSubmitedFile(index)">
                <i :class="getFileClass(files[index].type)" aria-hidden="true"></i>
                <span style="padding-left: 10px;">{{ file }}</span>
              </span>
            </div>
          </div>

          <div class="row">
            <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Tooltip } from "element-ui";

import { SAVE_UPLOAD_COMPLAINT_FLOW_FILES } from "src/store/actions/complaints/actions/upload_complaint_flow_files";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input,
    [Tooltip.name]: Tooltip,
  },
  props: ["mode", "complaintID", "status_id"],
  data() {
    return {
      model: {
        complaint_id: this.complaintID,
        mode: this.mode,
        message: "",
      },
      modelValidations: {
        complaint_id: {
          required: true,
          numeric: true,
          min: 1,
        },
        mode: {
          required: true,
        },
        message: {
          required: true,
          // regex: regexp.TEXT_REGEXP,
          min: 1,
          max: 256,
        },
      },

      files: [],
      fileNames: [],
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      if ((this.mode == "pause" && this.status_id != 4) || isValid) {
        let files = [];

        if (this.files.length > 0) {
          // send response files to server
          let submitResult = await this.submitResponseFile();
          console.log(submitResult);
          files = submitResult;

          if (!submitResult) {
            return;
          }
        }

        eventBus.$emit("saveStatusChange", {
          statusModel: { ...this.model, files: files },
        });
        this.$emit("close");
      }
    },
    async submitResponseFile() {
      const FILES_SIZE_LIMIT = 1024 * 1024 * 25; // 25Mb
      let files_size = 0;
      let result = true;

      this.files.forEach(file => {
        files_size += file.size;
      });

      if (files_size > FILES_SIZE_LIMIT) {
        eventBus.$emit("notify", {
          message: "Rozmiar załączników przekracza dozwoloną wartość (> 25MB)",
          type: "warning",
        });
        return;
      }

      // Initialize the form data
      let formData = new FormData();
      // Add the form data we need to submit
      this.files.forEach((file, index) => {
        formData.append("file" + "_" + index, file);
      });

      await this.$store.dispatch(SAVE_UPLOAD_COMPLAINT_FLOW_FILES, formData).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu załącznika!",
          type: "warning",
        });
        result = false;
      });

      result = this.$store.getters.getUploadComplaintFlowFilesModifiedRecords;

      if (result) {
        this.files = [];
        this.fileNames = [];
      }

      return result;
    },
    handleFileUpload(e) {
      var files = e.target.files;
      if (!files.length) return;
      Array.from(files).forEach(file => {
        this.files.push(file);
        this.fileNames.push(file.name);
        console.log(file.type);
      });
      document.getElementById("commentAttachment").value = null;
    },
    deleteSubmitedFile(index) {
      this.files.splice(index, 1);
      this.fileNames.splice(index, 1);
    },
    getFileClass(type) {
      switch (type) {
        case "application/pdf":
          return "fa fa-file-pdf-o";
        case "text/plain":
          return "fa fa-file-text-o";
        case "image/gif":
          return "fa fa-file-image-o";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "fa fa-file-excel-o";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "fa fa-file-word-o";
        default:
          return "fa fa-file-o";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}

.attachment-icon {
  margin-top: 5px;
  right: 20px;
  top: -10px;
  position: absolute;

  z-index: 1000;
}

.files-icon {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.files-icon input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.response_files {
  font-weight: bold;
}

.response_files:hover {
  text-decoration: line-through 2px #dc3545;
  cursor: pointer;
}
</style>
