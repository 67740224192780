export default {
  state : {
      notifications: {},
      MESSAGE_INTERVAL: 5000 // minimal interval between 2 same messages in milliseconds
  },
  mutations : {
    updateNotificationTimestamp(state, message) {
      state.notifications[message] = (new Date).getTime()
    }
  }
}
