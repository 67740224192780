<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h5 class="title">Limity</h5>
      </div>
      <div class="col-sm-12 col-md-4 text-md-right">
        <el-select if="limitsConfigurationFilterSelect" v-model="active_project_id" size="small" filterable clearable placeholder="Filtruj">
          <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
            <el-option
              :id="'limitsConfigurationFilterSelectOption_' + item.project_id"
              v-for="item in group.options"
              :key="item.project_id"
              :label="item.project_name + ' [' + item.project_id + ']'"
              :value="item.project_id"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <el-table :data="queriedData" style="width: 100%" @sort-change="sortChange">
              <el-table-column type="expand">
                <template v-slot="props">
                  <el-table v-if="props.row.children.length > 0" :data="props.row.children">

                    <el-table-column label="Moduł" min-width="120" property="module">
                      <template v-slot="props">
                        <div :id="'limitsConfigurationVersionRow_' + props.row.module">
                          {{ props.row.module }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="Co-reg" min-width="120" align="center">
                      <template v-slot="props">
                        <div v-if="props.row['MAX_CO-REGISTRATION_QUESTION_PER_SSESSION'] === undefined">
                          {{
                            limitsObject[props.row.project_id]["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"]
                              ? limitsObject[props.row.project_id]["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"]
                              : tableData[0]["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"]
                          }}
                        </div>
                        <div v-else class="highlight">{{ props.row["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"] }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="Profile" min-width="120" align="center">
                      <template v-slot="props">
                        <div v-if="props.row['MAX_PROFILE_QUESTION_PER_SSESSION'] === undefined">
                          {{
                            limitsObject[props.row.project_id]["MAX_PROFILE_QUESTION_PER_SSESSION"]
                              ? limitsObject[props.row.project_id]["MAX_PROFILE_QUESTION_PER_SSESSION"]
                              : tableData[0]["MAX_PROFILE_QUESTION_PER_SSESSION"]
                          }}
                        </div>
                        <div v-else class="highlight">{{ props.row["MAX_PROFILE_QUESTION_PER_SSESSION"] }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="Łącznie" min-width="120" align="center">
                      <template v-slot="props">
                        <div v-if="props.row['MAX_QUESTION_PER_SSESSION'] === undefined">
                          {{
                            limitsObject[props.row.project_id]["MAX_QUESTION_PER_SSESSION"]
                              ? limitsObject[props.row.project_id]["MAX_QUESTION_PER_SSESSION"]
                              : tableData[0]["MAX_QUESTION_PER_SSESSION"]
                          }}
                        </div>
                        <div v-else class="highlight">{{ props.row["MAX_QUESTION_PER_SSESSION"] }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" v-if="editPermitted" width="120">
                      <template v-slot="props">
                        <p-button
                          :id="'limitsConfigurationEditButton_' + props.row.module"
                          style="margin-right: 2px"
                          type="success"
                          size="sm"
                          icon
                          @click="showModal('edit', props.row)"
                        >
                          <i class="fa fa-edit"></i>
                        </p-button>
                        <p-button
                          :id="'limitsConfigurationDeleteButton_' + props.row.module"
                          style="margin-left: 2px"
                          type="danger"
                          size="sm"
                          icon
                          @click="handleDelete(props.row)"
                        >
                          <i class="fa fa-trash"></i>
                        </p-button>
                      </template>
                    </el-table-column>

                  </el-table>
                </template>
              </el-table-column>

              <el-table-column label="ID" width="100" property="project_id" sortable="custom">
                <template v-slot="props">
                  <div :id="'limitsConfigurationIDRow_' + props.row.project_id">
                    {{ props.row.project_id }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Nazwa" min-width="160" sortable="custom" property="project_name">
                <template v-slot="props">
                  <div v-if="props.row.project_id === 0">
                    <b>{{ props.row.project_name }}</b>
                  </div>
                  <div v-else style="word-break: keep-all; padding-top: 3px">
                    {{ props.row.project_name }}
                    <el-tooltip class="item" effect="light" content="Ilość wersji z dodanymi limitami" placement="top" :open-delay="500">
                      <el-badge :value="props.row.children.length" class="item" type="primary" v-if="props.row.children.length > 0"></el-badge>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Co-reg" min-width="70" align="center">
                <template v-slot="props">
                  <div v-if="props.row.project_id === 0 || props.row['MAX_CO-REGISTRATION_QUESTION_PER_SSESSION'] === undefined">
                    {{ tableData[0]["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"] }}
                  </div>
                  <div v-else class="highlight">{{ props.row["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"] }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Profile" min-width="70" align="center">
                <template v-slot="props">
                  <div v-if="props.row.project_id === 0 || props.row['MAX_PROFILE_QUESTION_PER_SSESSION'] === undefined">
                    {{ tableData[0]["MAX_PROFILE_QUESTION_PER_SSESSION"] }}
                  </div>
                  <div v-else class="highlight">{{ props.row["MAX_PROFILE_QUESTION_PER_SSESSION"] }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Łącznie" min-width="70" align="center">
                <template v-slot="props">
                  <div v-if="props.row.project_id === 0 || props.row['MAX_QUESTION_PER_SSESSION'] === undefined">
                    {{ tableData[0]["MAX_QUESTION_PER_SSESSION"] }}
                  </div>
                  <div v-else class="highlight">{{ props.row["MAX_QUESTION_PER_SSESSION"] }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="120">
                <template v-slot:header>
                  <p-button id="limitsConfigurationAddNewButton" type="success" icon round @click="showModal('add', null)">
                    <i class="fa fa-plus"></i>
                  </p-button>
                </template>
                <template v-slot="props">
                  <p-button v-if="props.row.project_id !== 0"
                    :id="'limitsConfigurationEditButton_' + props.row.project_id"
                    style="margin-right: 2px"
                    type="success"
                    size="sm"
                    icon
                    @click="showModal('edit', props.row)"
                  >
                    <i class="fa fa-edit"></i>
                  </p-button>
                  <p-button
                    :id="'limitsConfigurationDeleteButton_' + props.row.project_id"
                    style="margin-left: 2px"
                    type="danger"
                    size="sm"
                    icon
                    @click="handleDelete(props.row)"
                    :disabled="!checkProjectLimits(props.row)"
                  >
                    <i class="fa fa-trash"></i>

                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-body row">
            <div class="col-sm-6 pagination-info">
              <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
// ui components
import { Select, Option, OptionGroup, Input, Tag, Tooltip, Badge } from "element-ui";
import BackToTop from "vue-backtotop";
import PPagination from "src/components/UIComponents/Pagination.vue";
import Swal from "sweetalert2";

import LimitsConfigurationForm from "src/components/Utils4/questions/forms/LimitsConfigurationForm.vue";

// vuex actions
import { LOAD_PROJECT_DICT, PROJECT_DICT_INVALID } from "src/store/actions/questions/project_dict";
import { LOAD_PROJECT_LIMITS, PROJECT_LIMITS_INVALID, DELETE_PROJECT_LIMITS } from "src/store/actions/questions/project_limits";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Input.name]: Input,
    [Tag.name]: Tag,
    [Tooltip.name]: Tooltip,
    [Badge.name]: Badge,
    PPagination,
    BackToTop,
  },
  data() {
    return {
      loading: false,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      active_project_id: "",
      limitsObject: {},
      sort: {
        prop: "question_id",
        order: "ascending",
      },
      searchQuery: "",
      propsToSearch: ["project_name"],
    };
  },
  computed: {
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    limits() {
      return this.$store.getters.getProjectLimits;
    },
    tableData() {
      let data = Object.values(this.limits);
      let result = {};
      data.map((el) => {
        if (result[el.project_id] === undefined) {
          result[el.project_id] = {};
          result[el.project_id].children = {};
        }
        if (el.module === "ALL_VERSION") {
          result[el.project_id].project_id = el.project_id;
          result[el.project_id].project_name = el.project_name;
          result[el.project_id].module = el.module;
          result[el.project_id][el.limit_name] = el.limit_value;
        } else {
          if (result[el.project_id].project_id === undefined) {
            result[el.project_id].project_id = el.project_id;
            result[el.project_id].project_name = el.project_name;
            result[el.project_id].module = "ALL_VERSION";
          }
          if (result[el.project_id].children[el.module] === undefined) {
            result[el.project_id].children[el.module] = {};
          }
          result[el.project_id].children[el.module].module = el.module;
          result[el.project_id].children[el.module].project_id = el.project_id;
          result[el.project_id].children[el.module].project_name = el.project_name;
          result[el.project_id].children[el.module][el.limit_name] = el.limit_value;
        }
      });
      let allData = {};
      for (let i in result) {
        allData[i] = Object.assign({}, result[i]);
        allData[i].children = [];
        if (Object.keys(result[i].children).length > 0) {
          for (let j in result[i].children) {
            allData[i].children.push(result[i].children[j]);
          }
        }
      }

      this.limitsObject = JSON.parse(JSON.stringify(allData));

      let filteredData = Object.values(allData).filter(
        (row) => this.active_project_id === "" || row.project_id === this.active_project_id || row.project_id === 0,
      );
      return filteredData;

      // return Object.values(new_object);
      // return data;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData;

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN_QUESTIONS);
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(PROJECT_LIMITS_INVALID);
      }

      this.loading = true;

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_PROJECT_LIMITS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
    },
    checkProjectLimits(row) {
      return !!(row["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"] || row["MAX_PROFILE_QUESTION_PER_SSESSION"] || row["MAX_QUESTION_PER_SSESSION"]);
    },
    showModal(mode, row) {
      // wyświetlenie modala zmiany limitu
      let projectLimit = {};
      let level = "";
      if (row === undefined || row === null || row.module === "ALL_VERSION") {
        level = "project";
      } else {
        level = "version";
        projectLimit = {
          CR: this.limitsObject[row.project_id]["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"]
            ? this.limitsObject[row.project_id]["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"]
            : null,
          PR: this.limitsObject[row.project_id]["MAX_PROFILE_QUESTION_PER_SSESSION"]
            ? this.limitsObject[row.project_id]["MAX_PROFILE_QUESTION_PER_SSESSION"]
            : null,
          ALL: this.limitsObject[row.project_id]["MAX_QUESTION_PER_SSESSION"] ? this.limitsObject[row.project_id]["MAX_QUESTION_PER_SSESSION"] : null,
        };
      }
      this.$modal.show(
        LimitsConfigurationForm,
        {
          level: level,
          projectLimit: projectLimit,
          mode: mode,
          row: row,
          defaultLimit: {
            CR: this.tableData[0]["MAX_CO-REGISTRATION_QUESTION_PER_SSESSION"],
            PR: this.tableData[0]["MAX_PROFILE_QUESTION_PER_SSESSION"],
            ALL: this.tableData[0]["MAX_QUESTION_PER_SSESSION"],
          },
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(LimitsConfigurationForm);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "50%",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    handleDelete(row) {
      let varThis = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      let text = row.module === "ALL_VERSION" ? "dla poziomu projektu" : 'dla poziomu wersji "' + row.module + '"';
      swalWithBootstrapButtons
        .fire({
          title: `Czy chcesz usunąć limity dla projektu "${row.project_name}"?`,
          text: `Zostaną usunięte limity ${text}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Akceptuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            varThis.deleteData(row);
          }
        })
        .catch(() => {
          return;
        });
    },
    async deleteData(row) {
      let deleteModel = {project_id: row.project_id, module: row.module};

      await this.$store.dispatch(DELETE_PROJECT_LIMITS, deleteModel).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu!",
          type: "warning",
        });
      });

      if (this.$store.state.project_limits.deleting_result === true) {
        eventBus.$emit("notify", {
          message: "Rekord został usunięty",
          type: "success",
        });

        this.$store.commit(PROJECT_LIMITS_INVALID); // oznacz nieaktualność danych
        this.loadData();
      }
    },
    sortChange(obj) {
      this.sort = {
        prop: obj.prop,
        order: obj.order,
      };
      if (obj.order === "descending") {
        if (obj.prop === "project_name") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (obj.prop === "project_name") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        this.tableData.sort((a, b) => b.priority - a.priority);
      }
      this.searchQuery += " ";
      this.searchQuery = this.searchQuery.slice(0, -1);
    },
  },
  async created() {
    await this.loadData();
    eventBus.$on("forceReload", () => {
      this.loadData(true);
    });
    eventBus.$on("limitsSaved", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
    eventBus.$off("limitsSaved");
  },
};
</script>

<style lang="scss" scoped>
.highlight {
  font-size: 1.2em;
  font-weight: bold;
}
</style>
