<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h5 class="title">Partnerzy zgód dodaj do wszystkich serwisów</h5>
        </div>

      </div>

      <div class="row">
        <div class="col-12">
          <div class="row align-items-center">
            <div class="col align-self-star al">
              <label class="mb-0">Projekt</label> <i class="el-icon-view"
                                                     @click="showAllProjectMm = !showAllProjectMm"></i>
            </div>
            <div class="col align-self-end">
              <p-button class="float-right" type="success" @click="saveDataAll()">Zapisz</p-button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <fg-input :error="getError('project_id')" data-vv-as="Projekt" v-show="showAllProjectMm">
            <el-select
              id="ConsentsPartnersSelectAll"
              v-on:visible-change="getUpperLeftSelectWidth()"
              v-model="project_id"
              multiple
              name="project_id"
              data-vv-as="Projekt"
              filterable
              v-on:input=""
            >
              <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                <el-option
                  :style="upperLeftSelectWidth !== null ? 'width: ' + upperLeftSelectWidth + 'px' : 'width: 300px'"
                  :id="'ConsentsPartnersSelectOptionAll_' + item.project_id"
                  v-for="item in group.options"
                  :key="item.project_id"
                  selected="selected"
                  :label="item.project_name + ' [' + item.project_id + ']'"
                  :value="item.project_id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <label>Lista pól</label>

              <div class="row" v-if="project_id !== null && editPermitted">
                <div class="col-sm-7">
                  <table class="table">
                    <thead>
                    <tr>
                      <th style="width: 150px;" scope="col">Kolejność</th>
                      <th scope="col">Nazwa</th>
                    </tr>
                    </thead>
                    <draggable v-model="grantedFields" tag="tbody" @change="moved" handle=".handle">
                      <tr v-for="(item, index) in grantedFields" :key="index + '_' + item.partner_id">
                        <slot :row="item">
                          <td style="cursor: pointer; width: 150px;"><i class="fa fa-bars handle"
                                                                        aria-hidden="true"></i>
                          </td>
                          <td @click="changeGranted(item)" style="cursor: pointer">
                            <el-tooltip class="item" effect="light" :content="item.content" placement="top"
                                        :open-delay="500">
                              <span>{{ item.partner_name }}</span>
                            </el-tooltip>
                          </td>
                        </slot>
                      </tr>
                    </draggable>
                  </table>
                </div>
                <div class="col-sm-1">
                  <div class="row">
                    <div class="col-sm-12">
                      <el-button style="margin-top: 20px;" plain @click.prevent="moveFields('left')"
                      ><i class="fa fa-angle-double-left" aria-hidden="true"></i
                      ></el-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <el-button style="margin-top: 10px;" plain @click.prevent="moveFields('right')"
                      ><i class="fa fa-angle-double-right" aria-hidden="true"></i
                      ></el-button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th scope="col">Dostępne pola</th>
                    </tr>
                    </thead>
                    <tr v-for="item in availableFields" :key="item.partner_id">
                      <slot :row="item">
                        <td @click="changeGranted(item)" style="cursor: pointer">
                          <el-tooltip class="item" effect="light" :content="item.content" placement="top"
                                      :open-delay="500">
                            <span>{{ item.partner_name }}</span>
                          </el-tooltip>
                        </td>
                      </slot>
                    </tr>
                  </table>
                </div>
              </div>
              <div v-if="project_id !== null && !editPermitted">
                <ul class="consents_partners__read_only_granted_fields">
                  <li v-for="(field, index) in grantedFields" :key="index">{{ field.partner_name }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {Select, Option, OptionGroup, Button, Tooltip} from "element-ui";
import draggable from "vuedraggable";
import * as priv from "@/privileges";
import {eventBus} from "@/main";
import {LOAD_PROJECTS_ALL, PROJECTS_ALL_INVALID} from "@/store/actions/questions/projects_all";
import {LOAD_PROJECT_DICT, PROJECT_DICT_INVALID} from "@/store/actions/questions/project_dict";
import {
  CONSENTS_PARTNERS_DICT_INVALID,
  LOAD_CONSENTS_PARTNERS_DICT
} from "@/store/actions/consents/consents_partners_dict";
import {CONSENTS_PARTNERS_INVALID, SAVE_CONSENTS_PARTNERS} from "@/store/actions/consents/consents_partners";

export default {
  props: ['active-name'],
  components: {
    draggable,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [OptionGroup.name]: OptionGroup
  },
  data() {
    return {
      project_id: [],
      setAllProjectMm: true,
      showAllProjectMm: false,
      upperLeftSelectWidth: null,
      grantedFields: [],
      availableFields: []
    };
  },
  computed: {
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjectDict);
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map(x => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
          if (this.setAllProjectMm === true) {
            this.project_id.push(x.project_id);
          }
        } else {
          projectObj.options[1].options.push(x);
        }
      });
      if (this.setAllProjectMm === true) {
        this.setAllProjectMm = false;
      }
      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    }
  },
  methods: {
    cancel() {
      this.grantedFields = [];
      this.version = "";
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getUpperLeftSelectWidth() {
      this.upperLeftSelectWidth = document.getElementById("ConsentsPartnersSelectAll").offsetWidth;
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_CONSENTS_PARTNERS);
    },
    moved(event) {
      // indeksowanie tablic przy każdej zmianie kolejności
      this.grantedFields.forEach((el, index) => (el.position = index + 1));
    },
    async loadData(force) {
      if (force) {
        this.$store.commit(PROJECTS_ALL_INVALID);
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(CONSENTS_PARTNERS_DICT_INVALID);
      }
      this.$store.commit(CONSENTS_PARTNERS_INVALID);

      this.$store.dispatch(LOAD_PROJECTS_ALL, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych1!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych2!",
          type: "warning",
        });
      });
      this.$store.dispatch(LOAD_CONSENTS_PARTNERS_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych3!",
          type: "warning",
        });
      }).then(()=>{this.initFields();});

    },
    initFields() {
      this.allFields = [];
      this.availableFields = [];
      this.grantedFields = [];

      const dict = Object.values(JSON.parse(JSON.stringify(this.$store.getters.getConsentsPartnersDict)));

      const consentsPartners = Object.values(JSON.parse(JSON.stringify(this.$store.getters.getConsentsPartners)));
      let filteredConsentsPartners = consentsPartners.filter(
        consent => (this.version === "" && consent.site_id === null) || (this.version !== null && this.version === consent.short_name),
      );
      let data = {};
      filteredConsentsPartners.forEach(x => {
        data[x.partner_id] = x;
      });
      const data_ids = Object.values(data).map(row => row.partner_id);
      for (let i in dict) {
        let row = dict[i];
        this.allFields.push({
          partner_id: row.id,
          partner_name: row.name,
          granted: data_ids.includes(row.id),
          position: data[row.id] !== undefined ? data[row.id].position : null,
          content: row.content,
        });
      }
      this.setFields();
      this.grantedFields = this.grantedFields.sort((one, two) => {
        return one.position - two.position;
      });
    },
    moveFields(direction) {
      let set = direction === "left" ? this.availableFields : this.grantedFields;
      set.forEach(x => {
        x.granted = !x.granted;
      });
      this.setFields();
      this.moved();
    },
    changeGranted(row) {
      row.granted = !row.granted;
      this.setFields();
      this.moved();
    },
    setFields() {
      this.grantedFields = this.allFields.filter(row => row.granted);
      this.availableFields = this.allFields.filter(row => !row.granted);
    },
    async saveDataAll() {
      let allGrantedFields = this.grantedFields;
      allGrantedFields.forEach((el, index) => (el.position = index + 1));
      let grantedFields = allGrantedFields.map(({partner_id, position}) => {
        return {partner_id: partner_id, position: position};
      });
      let model = Object.assign({}, {
        project_id: this.project_id
      }, {grantedFields: grantedFields});

      await this.$store.dispatch(SAVE_CONSENTS_PARTNERS, model).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getConsentsPartnersModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Rekord został zmodyfikowany",
          type: "success",
        });

        this.$store.commit(CONSENTS_PARTNERS_INVALID); // oznacz nieaktualność danych
        this.loadData();
      }

    }
  },
   async created() {
    eventBus.$on('reload',this.cancel);
    eventBus.$on("forceReload", () => {
      if (this.activeName === 'AddToAllPartnersService') {
        this.loadData(true);
      }
    });
     await this.loadData(true);
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
}
</script>
