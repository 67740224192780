export const LOAD_RESPONSE_TEMPLATES = "LOAD_RESPONSE_TEMPLATES";
export const RESPONSE_TEMPLATES_LOADING = "RESPONSE_TEMPLATES_LOADING";
export const RESPONSE_TEMPLATES_LOADING_SUCCESS = "RESPONSE_TEMPLATES_LOADING_SUCCESS";
export const RESPONSE_TEMPLATES_LOADING_ERROR = "RESPONSE_TEMPLATES_LOADING_ERROR";
export const RESPONSE_TEMPLATES_INVALID = "RESPONSE_TEMPLATES_INVALID";

export const SAVE_RESPONSE_TEMPLATE = "SAVE_RESPONSE_TEMPLATE";
export const RESPONSE_TEMPLATE_SAVING = "RESPONSE_TEMPLATE_SAVING";
export const RESPONSE_TEMPLATE_SAVING_SUCCESS = "RESPONSE_TEMPLATE_SAVING_SUCCESS";
export const RESPONSE_TEMPLATE_SAVING_ERROR = "RESPONSE_TEMPLATE_SAVING_ERROR";
