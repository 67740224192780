<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Konfiguracja Hotjar</h5>
      </div>
    </div>

    <div class="card" style="margin-top: 10px;">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Projekt</th>
                  <th scope="col">Wersja</th>
                  <th scope="col">Użytkownik</th>
                  <th scope="col" class="text-center">
                    <p-button type="success" icon round @click="handleConfig('add', model)">
                      <i class="fa fa-plus"></i>
                    </p-button>
                  </th>
                </tr>
              </thead>
              <tr v-for="(row, index) in hotjarConfig" :key="index">
                <td scope="row">{{ "[" + row.project_id + "] " + row.project_name }}</td>
                <td scope="row">{{ row.module }}</td>
                <td scope="row">{{ row.system_user_id }}</td>
                <td style="width: 120px" class="text-center">
                  <p-button style="margin-right: 2px;" type="success" size="sm" icon @click="handleConfig('edit', row)">
                    <i class="fa fa-edit" aria-hidden="true"></i>
                  </p-button>
                  <p-button style="margin-left: 2px;" type="danger" size="sm" icon @click="deleteData(row)">
                    <i class="fa fa-trash"></i>
                  </p-button>
                </td>
              </tr>
            </table>

            <div v-if="hotjarConfig.length == 0" class="center" style="margin: 10px; font-size: 1.1em;">
              Brak danych
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// event bus
import { eventBus } from "src/main";

import { Select, Option, OptionGroup, Tag } from "element-ui";

import { LOAD_HOTJAR_CONFIG, HOTJAR_CONFIG_INVALID, SAVE_HOTJAR_CONFIG } from "src/store/actions/tests/hotjar_config";

import HotjarConfigModal from "src/components/Utils4/tests/HotjarConfigModal.vue";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tag.name]: Tag,
  },
  data() {
    return {
      loading: false,
      model: {
        project_id: "",
        module: "",
      },
      versionProjectObject: {},
      upperLeftSelectWidth: null,
    };
  },
  methods: {
    async loadData() {
      this.loading = true;

      this.$store.commit(HOTJAR_CONFIG_INVALID);

      await this.$store.dispatch(LOAD_HOTJAR_CONFIG, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    async deleteData(row) {
      let record = {
        insert: null,
        delete: _.cloneDeep(row),
      };

      record.delete.module = record.delete.module === "Wszystkie" ? null : record.delete.module;

      await this.$store.dispatch(SAVE_HOTJAR_CONFIG, record).catch(error => {
        // zapisanie danych do bazy
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getHotjarConfigModifiedRecords === true) {
        // wyświetlenie informacji o zapisaniu
        eventBus.$emit("notify", {
          message: "Rekord został usunięty",
          type: "success",
        });

        this.loadData();
      }
    },
    handleConfig(mode, row) {
      this.$modal.show(
        HotjarConfigModal,
        {
          mode: mode,
          row: row,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(HotjarConfigModal);
              },
            },
          ],
        },
        {
          draggable: false,
          width: "400px",
          height: "auto",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
  },
  computed: {
    hotjarConfig() {
      return this.$store.getters.getHotjarConfig;
    },
  },
  mounted() {},
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
    eventBus.$on("hotjarConfigSaved", () => {
      this.loadData();
    });
    this.loadData();
  },
  beforeDestroy: function() {
    this.$store.commit(HOTJAR_CONFIG_INVALID);
    eventBus.$off("forceReload");
    eventBus.$off("hotjarConfigSaved");
  },
};
</script>
<style scoped>
.right-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
