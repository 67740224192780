import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_DEDUPLICATION_CLIENTS,
  DEDUPLICATION_CLIENTS_LOADING,
  DEDUPLICATION_CLIENTS_LOADING_SUCCESS,
  DEDUPLICATION_CLIENTS_LOADING_ERROR,
  DEDUPLICATION_CLIENTS_INVALID,
} from "src/store/actions/sales/deduplication_clients";

const storeObjectConfig = {
  objectName: "Deduplication",
  getterName: "getDeduplicationClients",
  loadActionName: LOAD_DEDUPLICATION_CLIENTS,
  loadingMutationName: DEDUPLICATION_CLIENTS_LOADING,
  loadingSuccessMutationName: DEDUPLICATION_CLIENTS_LOADING_SUCCESS,
  loadingErrorMutationName: DEDUPLICATION_CLIENTS_LOADING_ERROR,
  invalidMutationName: DEDUPLICATION_CLIENTS_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/sales/deduplication/clients",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
