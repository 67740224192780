import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API
} from 'src/store/config'
import {
  CUSTOMER_EFFICIENCY_DISPLAY_INVALID,
  CUSTOMER_EFFICIENCY_DISPLAY_LOADING_ERROR,
  CUSTOMER_EFFICIENCY_DISPLAY_LOADING_SUCCESS,
  CUSTOMER_EFFICIENCY_EXCEL_DISPLAY_LOADING,
  LOAD_CUSTOMER_EFFICIENCY_EXCEL_DISPLAY
} from "@/store/actions/sales/customer_efficiency_excel";



const storeObjectConfig = {
  objectName: "DisplayEfficiencyExel",
  getterName: "getCustomerEfficiencyExel",
  loadActionName: LOAD_CUSTOMER_EFFICIENCY_EXCEL_DISPLAY,
  loadingMutationName: CUSTOMER_EFFICIENCY_EXCEL_DISPLAY_LOADING,
  loadingSuccessMutationName: CUSTOMER_EFFICIENCY_DISPLAY_LOADING_SUCCESS,
  loadingErrorMutationName: CUSTOMER_EFFICIENCY_DISPLAY_LOADING_ERROR,
  invalidMutationName: CUSTOMER_EFFICIENCY_DISPLAY_INVALID,
  // saveActionName: SAVE_DISPLAY_EFFICIENCY,
  // savingMutationName: DISPLAY_EFFICIENCY_SAVING,
  // savingSuccessMutationName: DISPLAY_EFFICIENCY_SAVING_SUCCESS,
  // savingErrorMutationName: DISPLAY_EFFICIENCY_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: SECURE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/raports/customerEfficiency/excel",
    method: "post" // {'get','post','put'}
  }
  // saveParams: {
  //   url: "/raports/displayCosts",
  //   method: "put" // {'get','post','put'}
  // }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
