import storeGen from 'src/store/storeGenerator'

import {
  AUTH_LOGOUT
} from 'src/store/actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_USER_ROLES,
  USER_ROLES_LOADING,
  USER_ROLES_LOADING_SUCCESS,
  USER_ROLES_LOADING_ERROR,
  USER_ROLES_INVALID,
  SAVE_USER_ROLE,
  USER_ROLE_SAVING,
  USER_ROLE_SAVING_SUCCESS,
  USER_ROLE_SAVING_ERROR
} from 'src/store/actions/admin/user_roles'

const storeObjectConfig = {
  objectName: "UserRoles",
  getterName: "getUserRoles",
  loadActionName: LOAD_USER_ROLES,
  loadingMutationName: USER_ROLES_LOADING,
  loadingSuccessMutationName: USER_ROLES_LOADING_SUCCESS,
  loadingErrorMutationName: USER_ROLES_LOADING_ERROR,
  invalidMutationName: USER_ROLES_INVALID,
  saveActionName: SAVE_USER_ROLE,
  savingMutationName: USER_ROLE_SAVING,
  savingSuccessMutationName: USER_ROLE_SAVING_SUCCESS,
  savingErrorMutationName: USER_ROLE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/admin/userRoles",
    method: "post" // {'get','post','put'}
  },
  saveParams: {
    url: "/admin/userRoles",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
