import DashboardLayout from "../components/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "../components/GeneralViews/NotFoundPage.vue";
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ "src/components/Dashboard/Views/Dashboard/Overview.vue");

// Pages
import User from "src/components/Dashboard/Views/Pages/UserProfile.vue";
import ChangePassword from "src/components/Dashboard/Views/Pages/ChangePassword.vue";
import Login from "src/components/Dashboard/Views/Pages/Login.vue";
import Logout from "src/components/Dashboard/Views/Pages/Logout.vue";
import ProfilePreferences from "src/components/Dashboard/Views/Pages/ProfilePreferences.vue";

// Admin
import Partners from "src/components/Utils4/admin/Partners.vue";
import PartnerForm from "src/components/Utils4/admin/PartnerForm.vue";
import Users from "src/components/Utils4/admin/Users.vue";
import UserImport from "src/components/Utils4/admin/UserImport.vue";
import UserForm from "src/components/Utils4/admin/UserForm.vue";
import UserPassword from "src/components/Utils4/admin/UserPassword.vue";
import UserAddRole from "src/components/Utils4/admin/UserAddRole.vue";
import UserAddScope from "src/components/Utils4/admin/UserAddScope.vue";
import Systems from "src/components/Utils4/admin/Systems.vue";
import SystemForm from "src/components/Utils4/admin/SystemForm.vue";
import Roles from "src/components/Utils4/admin/Roles.vue";
import RoleForm from "src/components/Utils4/admin/RoleForm.vue";
import RoleAddPriv from "src/components/Utils4/admin/RoleAddPriv.vue";
import Permissions from "src/components/Utils4/admin/Permissions.vue";
import PermissionForm from "src/components/Utils4/admin/PermissionForm.vue";
import Endpoints from "src/components/Utils4/admin/Endpoints.vue";
import EndpointForm from "src/components/Utils4/admin/EndpointForm.vue";
import Scopes from "src/components/Utils4/admin/Scopes.vue";
import ScopeForm from "src/components/Utils4/admin/ScopeForm.vue";
import Databases from "src/components/Utils4/admin/Databases.vue";
import DatabaseForm from "src/components/Utils4/admin/DatabaseForm.vue";
import ComplaintsGroups from "src/components/Utils4/admin/ComplaintsGroups.vue";
import SystemParams from "src/components/Utils4/admin/SystemParams.vue";
import SystemParamsForm from "src/components/Utils4/admin/SystemParamsForm.vue";

// Questions
import Questions from "src/components/Utils4/questions/Questions.vue";
import QuestionForm from "src/components/Utils4/questions/QuestionForm.vue";
import QuestionOrder from "src/components/Utils4/questions/QuestionOrder.vue";
import Configuration from "src/components/Utils4/questions/Configuration.vue";
import AnswerForm from "src/components/Utils4/questions/AnswerForm.vue";
import Prizes from "src/components/Utils4/questions/Prizes.vue";
import PrizesForm from "src/components/Utils4/questions/PrizesForm.vue";
import QuestionGroup from "src/components/Utils4/questions/QuestionGroup.vue";

// Dictionaries
import Categorys from "src/components/Utils4/dictionaries/Categorys.vue";
import Postcodes from "src/components/Utils4/dictionaries/Postcodes.vue";
import ConsentsPartnersDict from "src/components/Utils4/dictionaries/ConsentsPartnersDict.vue";

// Consents
import ConsentsMenu from "src/components/Utils4/consents/ConsentsMenu.vue";
import ConsentsDict from "src/components/Utils4/consents/ConsentsDict.vue";
import ConsentsPartners from "src/components/Utils4/consents/ConsentsPartners.vue";

// Filter
import FilterAnalyser from "src/components/Utils4/filter/FilterAnalyser.vue";
// import FilterPanel from 'src/components/Utils4/filter/FilterPanel.vue'

// Raports
import HLR from "src/components/Utils4/raports/HLR.vue";
import Settlements from "src/components/Utils4/raports/Settlements.vue";
import Quiz from "src/components/Utils4/raports/Quiz.vue";
import EventsRaport from "src/components/Utils4/raports/EventsRaport.vue";

// Sales
import DisplayCosts from "src/components/Utils4/sales/DisplayCosts.vue";
import ClientRatesMain from "src/components/Utils4/sales/ClientRatesMain.vue";
import DisplayEfficiency from "src/components/Utils4/sales/DisplayEfficiency.vue";
import MailingEfficiency from "src/components/Utils4/sales/MailingEfficiency.vue";
import MailingEfficiencyConst from "@/components/Utils4/sales/MailingEfficiencyConst.vue";
import SmsEfficiency from "src/components/Utils4/sales/SmsEfficiency.vue";
import StatusRaport from "src/components/Utils4/sales/StatusRaport.vue";
import UpsellingIncome from "src/components/Utils4/sales/UpsellingIncome.vue";
import SalesRaport from "src/components/Utils4/sales/SalesRaport.vue";
import CreationsEfficiency from "src/components/Utils4/sales/CreationsEfficiency.vue";
import QuestionsEfficiency from "src/components/Utils4/sales/QuestionsEfficiency.vue";
import MailingCosts from "src/components/Utils4/sales/MailingCosts.vue";
import CustomerEfficiency from "src/components/Utils4/sales/CustomerEfficiency.vue";

import SalesLimitsMain from "src/components/Utils4/sales/SalesLimitsMain.vue";
import Deduplication from "src/components/Utils4/sales/deduplication/Deduplication.vue";
import DeduplicationForm from "src/components/Utils4/sales/deduplication/DeduplicationForm.vue";

import UserFromComplaints from "src/components/Utils4/sales/UserFromComplaints.vue";

import BanersEfficiency from "@/components/Utils4/sales/BanersEfficiency.vue";

// Complaints
import ComplaintsDashboard from "src/components/Utils4/complaints/ComplaintsDashboard.vue";
import Complaints from "src/components/Utils4/complaints/Complaints.vue";
import UserData from "src/components/Utils4/complaints/UserData.vue";
import UserHistory from "src/components/Utils4/complaints/UserHistory.vue";
import UserMailings from "src/components/Utils4/complaints/UserMailings.vue";
import UserBlacklist from "src/components/Utils4/complaints/UserBlacklist.vue";
import MailboxTemplates from "src/components/Utils4/complaints/MailboxTemplates.vue";
import MailboxTemplateForm from "src/components/Utils4/complaints/MailboxTemplateForm.vue";
import ResponseTemplateForm from "src/components/Utils4/complaints/ResponseTemplateForm.vue";
import PhoneHistory from "src/components/Utils4/complaints/PhoneHistory.vue";

// Stats
import DisplayStats from "src/components/Utils4/stats/DisplayStats.vue";
import MailingStats from "src/components/Utils4/stats/MailingStats.vue";
import ConversionStats from "src/components/Utils4/stats/ConversionStats.vue";
import QuestionApiStats from "src/components/Utils4/stats/QuestionApiStats.vue";
import LeadStats from "src/components/Utils4/stats/LeadStats.vue";
import QuestionActivity from "src/components/Utils4/stats/QuestionActivity.vue";
import DemographyStats from "src/components/Utils4/stats/DemographyStats.vue";

// Tests
import TestsResults from "src/components/Utils4/tests/TestsResults.vue";
import TestsResultsDetailed from "src/components/Utils4/tests/TestsResultsDetailed.vue";
import TestsRequests from "src/components/Utils4/tests/TestsRequests.vue";
import HotjarConfig from "src/components/Utils4/tests/HotjarConfig.vue";

// Mailings
import Pbr from "src/components/Utils4/mailings/PBR.vue";
import mMain from "src/components/Utils4/mailings/mMain.vue";
import ListsMails from "src/components/Utils4/mailings/ListsMails.vue";
import WorkCampaigns from "src/components/Utils4/mailings/WorkCampaigns.vue";
import WaitCampaigns from "src/components/Utils4/mailings/WaitCampaigns.vue";
import SendedCampaigns from "src/components/Utils4/mailings/SendedCampaigns.vue";
import AddCampaign from "src/components/Utils4/mailings/AddCampaign.vue";

//PidCfg
import PidVersion from "@/components/Utils4/pidcfg/PidVersion.vue";

import store from "src/store";

import * as priv from "src/privileges.js";
import BanersStats from "@/components/Utils4/stats/BanersStats";
import {PRIV_READ_MAILING_EFFINCIENCY_CONST} from "src/privileges.js";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next({
    name: "Overview",
  });
};

const ifAuthenticated = (to, from, next) => {
  let userPrivileges = store.getters.getProfile.privileges || [];

  if (store.getters.isAuthenticated) {
    if (typeof to.meta === "undefined" || typeof to.meta.privilege === "undefined") {
      next();
      return;
    } else if (userPrivileges.includes(to.meta.privilege)) {
      next();
      return;
    } else {
      next({
        path: "/",
      });
    }
  } else {
    next({
      name: "Login",
    });
  }
};

let userMenu = {
  path: "/user",
  component: DashboardLayout,
  redirect: "/user/profile",
  children: [
    {
      path: "profile",
      name: "User Profile",
      component: User,
    },
    {
      path: "profile_prefs",
      name: "Ustawienia użytkownika",
      component: ProfilePreferences,
    },
    {
      path: "password",
      name: "Change Password",
      component: ChangePassword,
    },
  ],
};

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login,
  beforeEnter: ifNotAuthenticated,
};

let logoutPage = {
  path: "/logout",
  name: "Logout",
  component: Logout,
};

let adminMenu = {
  path: "/admin",
  component: DashboardLayout,
  redirect: "/admin/users",
  children: [
    {
      path: "users",
      name: "Użytkownicy",
      component: Users,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN,
      },
    },
    {
      path: "user_form",
      name: "Tworzenie użytkownika",
      component: UserForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "user_form/:id",
      name: "Edycja użytkownika",
      component: UserForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "user_import",
      name: "Import użytkownika",
      component: UserImport,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "user_password/:id",
      name: "Zmiana hasła",
      component: UserPassword,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "user_add_role/:id",
      name: "Dodanie roli",
      component: UserAddRole,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "user_add_scope/:id",
      name: "Dodanie zakresu",
      component: UserAddScope,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "systems",
      name: "Systemy",
      component: Systems,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN,
      },
    },
    {
      path: "system_form",
      name: "Tworzenie systemu",
      component: SystemForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "system_form/:id",
      name: "Edycja systemu",
      component: SystemForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "permissions",
      name: "Uprawnienia",
      component: Permissions,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN,
      },
    },
    {
      path: "permission_form",
      name: "Tworzenie uprawnienia",
      component: PermissionForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "permission_form/:id",
      name: "Edycja uprawnienia",
      component: PermissionForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "roles",
      name: "Role",
      component: Roles,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN,
      },
    },
    {
      path: "role_form",
      name: "Tworzenie roli",
      component: RoleForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "role_form/:id",
      name: "Edycja roli",
      component: RoleForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "role_add_priv/:id",
      name: "Dodanie uprawnienia",
      component: RoleAddPriv,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "endpoints",
      name: "Endpoint",
      component: Endpoints,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN,
      },
    },
    {
      path: "endpoint_form",
      name: "Tworzenie endpointu",
      component: EndpointForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "endpoint_form/:id",
      name: "Edycja endpointu",
      component: EndpointForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "scopes",
      name: "Zakresy",
      component: Scopes,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN,
      },
    },
    {
      path: "scope_form/:id",
      name: "Edycja zakresu",
      component: ScopeForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "scope_form",
      name: "Tworzenie zakresu",
      component: ScopeForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "partners",
      name: "Partnerzy",
      component: Partners,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN,
      },
    },
    {
      path: "partner_form",
      name: "Tworzenie partnera",
      component: PartnerForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "partner_form/:id",
      name: "Edycja partnera",
      component: PartnerForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "databases",
      name: "Bazy danych",
      component: Databases,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN,
      },
    },
    {
      path: "database_form",
      name: "Tworzenie bazy danych",
      component: DatabaseForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "database_form/:id",
      name: "Edycja bazy danych",
      component: DatabaseForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "complaints_groups",
      name: "Grupy reklamacyjne",
      component: ComplaintsGroups,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "system_params",
      name: "Parametry systemowe",
      component: SystemParams,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN,
      },
    },
    {
      path: "system_params_form",
      name: "Dodawanie parametrów systemowych",
      component: SystemParamsForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
    {
      path: "system_params_form/:id",
      name: "Edycja bazy danych",
      component: SystemParamsForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN,
      },
    },
  ],
};

let questionsMenu = {
  path: "/questions",
  component: DashboardLayout,
  redirect: "/questions/questions",
  children: [
    {
      path: "questions",
      name: "Pytania",
      component: Questions,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN_QUESTIONS,
      },
    },
    {
      path: "question_form",
      name: "Tworzenie pytania",
      component: QuestionForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN_QUESTIONS,
      },
    },
    {
      path: "question_form/:id",
      name: "Edycja pytania",
      component: QuestionForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN_QUESTIONS,
      },
    },
    {
      path: "order",
      name: "Kolejność",
      component: QuestionOrder,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN_QUESTIONS,
      },
    },
    {
      path: "configuration",
      name: "Konfiguracja",
      component: Configuration,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN_QUESTIONS_CONFIG,
      },
    },
    {
      path: "answer_form",
      name: "Tworzenie odpowiedzi",
      component: AnswerForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN_QUESTIONS,
      },
    },
    {
      path: "answer_form/:id",
      name: "Edycja odpowiedzi",
      component: AnswerForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_ADMIN_QUESTIONS,
      },
    },
    {
      path: "prizes",
      name: "Nagrody",
      component: Prizes,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_PRIZES,
      },
    },
    {
      path: "prize_form",
      name: "Tworzenie nagrody",
      component: PrizesForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_PRIZES,
      },
    },
    {
      path: "prize_form/:id",
      name: "Edycja nagrody",
      component: PrizesForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_PRIZES,
      },
    },
    {
      path: "question_group",
      name: "Grupy pytań",
      component: QuestionGroup,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_QUESTION_GROUP,
      },
    },
  ],
};

let dictionariesMenu = {
  path: "/dictionaries",
  component: DashboardLayout,
  redirect: "/dictionaries/categorys",
  children: [
    {
      path: "categorys",
      name: "Kategorie",
      component: Categorys,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_CATEGORY_DICT,
      },
    },
    {
      path: "postcodes",
      name: "Kody pocztowe",
      component: Postcodes,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_POSTCODES_DICT,
      },
    },
    {
      path: "consents_partners_dict",
      name: "Partnerzy zgód słownik",
      component: ConsentsPartnersDict,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_CONSENTS_PARTNERS_DICT,
      },
    },
  ],
};

let consentsMenu = {
  path: "/consents",
  component: DashboardLayout,
  redirect: "/consents/consents",
  children: [
    {
      path: "consents",
      name: "Zgody",
      component: ConsentsMenu,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_CONSENTS,
      },
    },
    {
      path: "consents_dict",
      name: "Słownik zgód",
      component: ConsentsDict,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_CONSENTS_DICT,
      },
    },
    {
      path: "consents_partners",
      name: "Partnerzy zgód",
      component: ConsentsPartners,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_CONSENTS_PARTNERS,
      },
    },
  ],
};

let filterMenu = {
  path: "/filter",
  component: DashboardLayout,
  redirect: "/filter/filter_analyser",
  children: [
    {
      path: "filter_analyser",
      name: "Analiza filtracji",
      component: FilterAnalyser,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_FILTER,
      },
    },
    // {
    //   path: 'filter_panel',
    //   name: 'Panel filtrowania',
    //   component: FilterPanel,
    //   beforeEnter: ifAuthenticated,
    //   meta: {
    //     privilege: priv.PRIV_READ_FILTER
    //   }
    // }
  ],
};

let raportsMenu = {
  path: "/raports",
  component: DashboardLayout,
  redirect: "/raports/hlr",
  children: [
    {
      path: "hlr",
      name: "Raport HLR",
      component: HLR,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_HLR,
      },
    },
    {
      path: "settlements",
      name: "Rozliczenia",
      component: Settlements,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_SETTLEMENTS,
      },
    },
    {
      path: "quiz",
      name: "Konkursy",
      component: Quiz,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_QUIZ,
      },
    },
    {
      path: "events_raport",
      name: "Raport zdarzeń",
      component: EventsRaport,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN_STATS,
      },
    },
  ],
};

let salesMenu = {
  path: "/sales",
  component: DashboardLayout,
  redirect: "/sales/display_costs",
  children: [
    {
      path: "deduplication",
      name: "Deduplikacja",
      component: Deduplication,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_DEDUPLICATION,
      },
    },
    {
      path: "deduplication_form",
      name: "Dodawanie deduplikacji",
      component: DeduplicationForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_DEDUPLICATION,
      },
    },
    {
      path: "deduplication_form/:id",
      name: "Edycja deduplikacji",
      component: DeduplicationForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_DEDUPLICATION,
      },
    },
    {
      path: "display_costs",
      name: "Koszty wyświetleń",
      component: DisplayCosts,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_DISPLAY_COSTS,
      },
    },
    {
      path: "upselling_income",
      name: "Przychody ruchu spadowego",
      component: UpsellingIncome,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_UPSELLING_INCOME,
      },
    },
    {
      path: "client_rates",
      name: "Stawki klientów",
      component: ClientRatesMain,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_CLIENT_RATES,
      },
    },
    {
      path: "sales_limits",
      name: "Limity sprzedażowe",
      component: SalesLimitsMain,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_SALES_LIMITS,
      },
    },
    {
      path: "questions_efficiency",
      name: "Rentowność pytań",
      component: QuestionsEfficiency,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_QUESTIONS_EFFICIENCY,
      },
    },
    {
      path: "display_efficiency",
      name: "Efektywność display",
      component: DisplayEfficiency,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_DISPLAY_EFFICIENCY,
      },
    },
    {
      path: "mailing_efficiency",
      name: "Efektywność mailing",
      component: MailingEfficiency,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_MAILING_EFFICIENCY,
      },
    },
    {
      path: "mailing_efficiency_const",
      name: "Efektywność mailing stałych",
      component: MailingEfficiencyConst,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_MAILING_EFFINCIENCY_CONST,
      },
    },
    {
      path: "baners_efficiency",
      name: "Efektywność Banerów",
      component: BanersEfficiency,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_BANERS_EFFICIENCY,
      },
    },
    {
      path: "sms_efficiency",
      name: "Efektywność sms",
      component: SmsEfficiency,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_SMS_EFFICIENCY,
      },
    },
    {
      path: "customer_efficiency",
      name: "Efektywność Klientów",
      component: CustomerEfficiency,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_CUSTOMER_EFFICIENCY,
      },
    },
    {
      path: "status_raport",
      name: "Raport statusowy",
      component: StatusRaport,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_STATUS_RAPORT,
      },
    },
    {
      path: "sales_raport",
      name: "Raport sprzedażowy",
      component: SalesRaport,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_SALES_RAPORT,
      },
    },
    {
      path: "creations_efficiency",
      name: "Efektywność kreacji",
      component: CreationsEfficiency,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_CREATIONS_EFFICIENCY,
      },
    },
    {
      path: "mailing_costs",
      name: "Koszty mailing",
      component: MailingCosts,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_MAILING_COSTS,
      },
    },
    {
      path: "user_from_complaints",
      name: "Użytkownicy z reklamacji",
      component: UserFromComplaints,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_SALES_RAPORT,
      },
    },
  ],
};

let complaintsMenu = {
  path: "/complaints",
  component: DashboardLayout,
  redirect: "/complaints/dashboard",
  children: [
    {
      path: "dashboard",
      name: "Pulpit",
      component: ComplaintsDashboard,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_COMPLAINTS,
      },
    },
    {
      path: "complaints",
      name: "Reklamacje",
      component: Complaints,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_COMPLAINTS,
      },
    },
    {
      path: "user_data",
      name: "Dane użytkownika",
      component: UserData,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_USER_DATA,
      },
    },
    {
      path: "user_history",
      name: "Historia użytkownika",
      component: UserHistory,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_USER_HISTORY,
      },
    },
    {
      path: "user_mailings",
      name: "Mailingi użytkownika",
      component: UserMailings,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_USER_DATA,
      },
    },
    {
      path: "phone_history",
      name: "Historia numeru",
      component: PhoneHistory,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_PHONE_HISTORY,
      },
    },
    {
      path: "user_blacklist",
      name: "Usuń użytkownika",
      component: UserBlacklist,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_USER_BLACKLIST,
      },
    },
    {
      path: "mailbox_templates",
      name: "Szablony odpowiedzi",
      component: MailboxTemplates,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_COMPLAINTS,
      },
    },
    {
      path: "mailbox_template_form",
      name: "Tworzenie szablonu skrzynki",
      component: MailboxTemplateForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_COMPLAINTS,
      },
    },
    {
      path: "mailbox_template_form/:id",
      name: "Edycja szablonu skrzynki",
      component: MailboxTemplateForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_COMPLAINTS,
      },
    },
    {
      path: "response_template_form",
      name: "Tworzenie szablonu odpowiedzi",
      component: ResponseTemplateForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_COMPLAINTS,
      },
    },
    {
      path: "response_template_form/:id",
      name: "Edycja szablonu odpowiedzi",
      component: ResponseTemplateForm,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_COMPLAINTS,
      },
    },
  ],
};

let statsMenu = {
  path: "/stats",
  component: DashboardLayout,
  redirect: "/stats/display_stats",
  children: [
    {
      path: "display_stats",
      name: "Wyświetlenia pytań",
      component: DisplayStats,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN_STATS,
      },
    },
    {
      path: "mailing_stats",
      name: "Statystki mailingów",
      component: MailingStats,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_MAILING_STATS,
      },
    },
    {
      path: "baners_stats",
      name: "Statystki banerów",
      component: BanersStats,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_BANERS_STATS,
      },
    },
    {
      path: "conversion_stats",
      name: "Konwersja",
      component: ConversionStats,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_CONVERSION_STATS,
      },
    },
    {
      path: "question_api_stats",
      name: "Statystyka API pytań",
      component: QuestionApiStats,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_QUESTION_API_STATS,
      },
    },
    {
      path: "lead_stats",
      name: "Statystyka Leadów",
      component: LeadStats,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN_STATS,
      },
    },
    {
      path: "question_activity",
      name: "Aktywność Pytań",
      component: QuestionActivity,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN_STATS,
      },
    },
    {
      path: "demography",
      name: "Demografia",
      component: DemographyStats,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_ADMIN_STATS,
      },
    },
  ],
};

let testsMenu = {
  path: "/tests",
  component: DashboardLayout,
  redirect: "/tests/tests_results",
  children: [
    {
      path: "tests_results",
      name: "Wyniki testów",
      component: TestsResults,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_TESTS_RESULTS,
      },
    },
    {
      path: "tests_results_detailed",
      name: "Wyniki szczegółowe",
      component: TestsResultsDetailed,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_TESTS_RESULTS,
      },
    },
    {
      path: "tests_requests",
      name: "Zlecenia testów",
      component: TestsRequests,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_TESTS_RESULTS,
      },
    },
    {
      path: "hotjar_config",
      name: "Konfiguracja Hotjar",
      component: HotjarConfig,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_HOTJAR_CONFIG,
      },
    },
  ],
};

let dashboardMenu = {
  path: "/",
  component: DashboardLayout,
  redirect: "/dashboard",
  children: [
    {
      path: "dashboard",
      name: "Overview",
      component: Overview,
      beforeEnter: ifAuthenticated,
    },
  ],
};

let mailingsMenu = {
  path: "/mailings",
  component: DashboardLayout,
  redirect: "/mailings/mMain",
  children: [
    {
      path: "pbr",
      name: "PBR",
      component: Pbr,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_PBR,
      },
    },
    {
      path: "mMain",
      name: "Mailing main",
      component: mMain,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_MAILINGS_SEND,
      },
    },
    {
      path: "lists_mails",
      name: "Listy mailingowe",
      component: ListsMails,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_MAILINGS_SEND,
      },
    },
    {
      path: "work_campaigns",
      name: "Kampanie robocze",
      component: WorkCampaigns,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_MAILINGS_SEND,
      },
    },
    {
      path: "wait_campaigns",
      name: "Kampanie oczekujące",
      component: WaitCampaigns,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_MAILINGS_SEND,
      },
    },
    {
      path: "sended_campaigns",
      name: "Kampanie wysłane",
      component: SendedCampaigns,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_MAILINGS_SEND,
      },
    },
    {
      path: "add-campaign",
      name: "Tworzenie Kampanii",
      component: AddCampaign,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_MAILINGS_SEND,
      },
    },
    {
      path: "add-campaign/:id",
      name: "Edycja Kampanii",
      component: AddCampaign,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_EDIT_MAILINGS_SEND,
      },
    },
  ],
};

let projectConfig = {
  path: "/pidcfg",
  component: DashboardLayout,
  redirect: "/pidcfg/pid_version",
  children: [
    {
      path: "pid_version",
      name: "Projekt Wersje",
      component: PidVersion,
      beforeEnter: ifAuthenticated,
      meta: {
        privilege: priv.PRIV_READ_PID_CONFIG,
      },
    },
  ],
};

const routes = [
  dashboardMenu,
  adminMenu,
  questionsMenu,
  dictionariesMenu,
  consentsMenu,
  filterMenu,
  raportsMenu,
  salesMenu,
  complaintsMenu,
  statsMenu,
  testsMenu,
  mailingsMenu,
  userMenu,
  projectConfig,
  loginPage,
  logoutPage,
  {
    path: "*",
    component: NotFound,
  },
];

export default routes;
