import storeGen from '../../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  SAVE_CREATE_COMPLAINT,
  CREATE_COMPLAINT_SAVING,
  CREATE_COMPLAINT_SAVING_SUCCESS,
  CREATE_COMPLAINT_SAVING_ERROR
} from '../../../actions/complaints/actions/create_complaint'

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getCreateComplaint",
  saveActionName: SAVE_CREATE_COMPLAINT,
  savingMutationName: CREATE_COMPLAINT_SAVING,
  savingSuccessMutationName: CREATE_COMPLAINT_SAVING_SUCCESS,
  savingErrorMutationName: CREATE_COMPLAINT_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'plain',
  saveParams: {
    url: "/complaints/create",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
