<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">
            {{ title }}
            <el-radio-group v-model="kind" v-if="mode === 'add'" style="margin-left: 20px;">
              <el-radio-button :label="1">Zgoda</el-radio-button>
              <el-radio-button :label="2">Obowiązek</el-radio-button>
            </el-radio-group>
          </h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div :class="kind === null || kind === 1 ? 'col-md-8' : 'col-md-10'">
          <div class="form-group">
            <label>Treść</label>
            <fg-input :error="getError('tresc_zgody')" data-vv-as="Treść">
              <el-input type="textarea" name="Treść" :rows="20" resize="none" placeholder="Treść zgody" v-model="model.tresc_zgody"> </el-input>
            </fg-input>
          </div>
        </div>

        <div :class="kind === null || kind === 1 ? 'col-md-4' : 'col-md-2'">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Administrator</label>
                <div v-for="owner in consentsOwners" :key="owner.id">
                  <el-checkbox v-model="checkedOwners[owner.id]" @change="actionCounter += 1">{{ owner.name }}</el-checkbox>
                </div>
              </div>
            </div>

            <div class="col" v-if="kind === null || kind === 1">
              <div class="form-group">
                <label>Typ zgody</label>
                <div>
                  <el-checkbox v-model="model.typ_zgody['1']">Typ 1</el-checkbox>
                </div>
                <div>
                  <el-checkbox v-model="model.typ_zgody['2']">Typ 2</el-checkbox>
                </div>
                <div>
                  <el-checkbox v-model="model.typ_zgody['3']">Typ 3</el-checkbox>
                </div>
                <div>
                  <el-checkbox v-model="model.typ_zgody['4']">Typ 4</el-checkbox>
                </div>
                <div>
                  <el-checkbox v-model="model.typ_zgody['5']">Typ 5</el-checkbox>
                </div>
                <div>
                  <el-checkbox v-model="model.typ_zgody['6']">Typ 6</el-checkbox>
                </div>
                <div>
                  <el-checkbox v-model="model.typ_zgody['7']">Typ 7</el-checkbox>
                </div>
                <div>
                  <el-checkbox v-model="model.typ_zgody['8']">Typ 8</el-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="countConsentTypes > 1">
            <div class="col-sm-12 warning-row">
              <i class="fa fa-exclamation-triangle" style="align-self: center; color: #ffc107;" aria-hidden="true"></i>
              <span style="color: grey; padding-left: 10px;"> Zaznaczono więcej niż jeden typ zgody!</span>
            </div>
          </div>
          <div class="row" v-if="countOwners > 1">
            <div class="col-sm-12 warning-row">
              <i class="fa fa-exclamation-triangle" style="align-self: center; color: #ffc107;" aria-hidden="true"></i>
              <span style="color: grey; padding-left: 10px;"> Zaznaczono więcej niż jednego administratora!</span>
            </div>
          </div>
          <!-- <div class="row" v-if="countConsentTypes === 0">
            <i class="fa fa-exclamation-triangle" style="align-self: center; color: #ffc107;" aria-hidden="true"></i> <span style="color: grey; padding-left: 10px;"> Nie zaznaczono żadnego typu zgody!</span>
          </div> -->
        </div>
        <div style="visibility: hidden;">{{ actionCounter }}</div>

        <div class="row">
          <p-button style="margin-right: 5px;" type="warning" @click.prevent="$emit('close')">Anuluj</p-button>
          <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input, Select, Option, Tooltip, Checkbox, Radio, RadioGroup, RadioButton } from "element-ui";

// vuex actions
import { SAVE_CONSENT_DICT, CONSENTS_DICT_INVALID } from "src/store/actions/consents/consents_dict";

// event bus
import { eventBus } from "src/main";

import * as priv from "src/privileges.js";
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Checkbox.name]: Checkbox,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
  },
  props: ["mode", "row"],
  data() {
    return {
      model: {
        id: null,
        tresc_zgody: null,
        typ_zgody: {
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": false,
        },
        owners: [],
      },
      modelValidations: {
        id: {
          numeric: true,
          min: 1,
        },
        tresc_zgody: {
          required: true,
          regex: regexp.TEXT_REGEXP,
          min: 1,
          max: 1024,
        },
      },
      compareModel: {},
      kind: 1,
      checkedOwners: {},
      actionCounter: 0,
    };
  },
  computed: {
    consentsOwners() {
      return this.$store.getters.getConsentsOwners;
    },
    title() {
      let result = "Zgody";
      if (this.mode === "edit") {
        if (this.kind === 1) {
          result = "Edytuj zgodę";
        } else {
          result = "Edytuj obowiązek";
        }
      } else {
        result = "Dodaj: ";
      }
      return result;
    },
    countConsentTypes() {
      const types = this.model.typ_zgody;
      let counter = 0;
      for (let i in types) {
        if (types[i]) {
          counter += 1;
        }
      }
      return counter;
    },
    countOwners() {
      const owners = this.checkedOwners;
      const actionCounter = this.actionCounter;
      let counter = 0;
      for (let i in owners) {
        if (owners[i] && actionCounter >= 0) {
          counter += 1;
        }
      }
      return counter;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      // const isValid = await this.$validator.validateAll();
      const checkedOwnersArray = [];
      let ownersCount = 0;
      for (let i in this.checkedOwners) {
        if (this.checkedOwners[i]) {
          checkedOwnersArray.push(i);
          ownersCount += 1;
        }
      }
      const modelOwnersArray = this.model.owners.map(x => x.id);
      if (this.model.tresc_zgody === null || this.model.tresc_zgody === "" || ownersCount < 1 || (this.kind === 1 && this.countConsentTypes === 0)) {
        if (this.model.tresc_zgody === null || this.model.tresc_zgody === "") {
          eventBus.$emit("notify", {
            message: "Treść zgody nie może być pusta!",
            type: "warning",
          });
        }
        if (ownersCount < 1) {
          eventBus.$emit("notify", {
            message: "Administrator musi zostać wybrany!",
            type: "warning",
          });
        }
        if (this.kind === 1 && this.countConsentTypes === 0) {
          eventBus.$emit("notify", {
            message: "Typ zgody musi zostać wybrany!",
            type: "warning",
          });
        }
        return;
      }
      if (JSON.stringify(this.model) !== JSON.stringify(this.compareModel) || !_.isEqual(modelOwnersArray, checkedOwnersArray)) {
        let model = JSON.parse(JSON.stringify(this.model));
        let typ_zgody = "";
        for (let i in this.model.typ_zgody) {
          if (this.model.typ_zgody[i]) {
            typ_zgody += `${i},`;
          }
        }
        typ_zgody = typ_zgody.slice(0, -1);
        model.typ_zgody = typ_zgody;
        model.kind = this.kind;
        if (model.kind === 2) {
          model.typ_zgody = null;
        }
        let owners = [];
        for (let i in this.checkedOwners) {
          if (this.checkedOwners[i]) {
            owners.push(i);
          }
        }
        model.owners = owners;

        console.log(model);
        await this.$store.dispatch(SAVE_CONSENT_DICT, model).catch(error => {
          console.log(error.response);
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning",
          });
        });

        if (this.$store.getters.getConsentsDictModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.mode === "edit" ? "zmodyfikowany" : "dodany"),
            type: "success",
          });

          this.$store.commit(CONSENTS_DICT_INVALID); // oznacz nieaktualność danych
          eventBus.$emit("consentsDictSaved");
          this.$emit("close");
        }
      } else {
        this.$emit("close");
      }
    },
    initData() {
      for (let i in this.consentsOwners) {
        let owner_id = this.consentsOwners[i].id;
        this.checkedOwners[owner_id] = false;
      }

      if (this.mode === "edit") {
        this.model = Object.assign({}, this.row);
        this.kind = this.model.kind;
        this.model.typ_zgody = {
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
          "6": false,
          "7": false,
          "8": false,
        };
        const consentsArray = this.row.typ_zgody.toString().split(",");
        consentsArray.forEach(element => {
          this.model.typ_zgody[element] = true;
        });
        this.row.owners.forEach(owner => {
          this.checkedOwners[owner.id] = true;
        });
      }
      this.compareModel = JSON.parse(JSON.stringify(this.model));
    },
  },
  async created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  justify-content: center;
  align-content: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}
.warning-row {
  display: flex;
  flex-direction: column;
}
</style>
