export const LOAD_MAILBOX_TEMPLATES = "LOAD_MAILBOX_TEMPLATES";
export const MAILBOX_TEMPLATES_LOADING = "MAILBOX_TEMPLATES_LOADING";
export const MAILBOX_TEMPLATES_LOADING_SUCCESS = "MAILBOX_TEMPLATES_LOADING_SUCCESS";
export const MAILBOX_TEMPLATES_LOADING_ERROR = "MAILBOX_TEMPLATES_LOADING_ERROR";
export const MAILBOX_TEMPLATES_INVALID = "MAILBOX_TEMPLATES_INVALID";

export const SAVE_MAILBOX_TEMPLATE = "SAVE_MAILBOX_TEMPLATE";
export const MAILBOX_TEMPLATE_SAVING = "MAILBOX_TEMPLATE_SAVING";
export const MAILBOX_TEMPLATE_SAVING_SUCCESS = "MAILBOX_TEMPLATE_SAVING_SUCCESS";
export const MAILBOX_TEMPLATE_SAVING_ERROR = "MAILBOX_TEMPLATE_SAVING_ERROR";
