import storeGen from "../../storeGenerator";

import { AUTH_LOGOUT } from "../../actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_RESPONSE_TEMPLATES,
  RESPONSE_TEMPLATES_LOADING,
  RESPONSE_TEMPLATES_LOADING_SUCCESS,
  RESPONSE_TEMPLATES_LOADING_ERROR,
  RESPONSE_TEMPLATES_INVALID,
  SAVE_RESPONSE_TEMPLATE,
  RESPONSE_TEMPLATE_SAVING,
  RESPONSE_TEMPLATE_SAVING_SUCCESS,
  RESPONSE_TEMPLATE_SAVING_ERROR,
} from "../../actions/complaints/response_templates";

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getResponseTemplates",
  loadActionName: LOAD_RESPONSE_TEMPLATES,
  loadingMutationName: RESPONSE_TEMPLATES_LOADING,
  loadingSuccessMutationName: RESPONSE_TEMPLATES_LOADING_SUCCESS,
  loadingErrorMutationName: RESPONSE_TEMPLATES_LOADING_ERROR,
  invalidMutationName: RESPONSE_TEMPLATES_INVALID,
  saveActionName: SAVE_RESPONSE_TEMPLATE,
  savingMutationName: RESPONSE_TEMPLATE_SAVING,
  savingSuccessMutationName: RESPONSE_TEMPLATE_SAVING_SUCCESS,
  savingErrorMutationName: RESPONSE_TEMPLATE_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/complaints/responseTemplates",
    method: "post", // {'get','post','put'}
  },
  saveParams: {
    url: "/complaints/responseTemplates",
    method: "put", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
