<template>
  <div>
    <el-table :data="tableData" size="mini" style="width: 100%" :header-cell-style="headerStyle">
      <el-table-column type="expand">
        <template v-slot="props">
          <div class="row tableRow">
            <div style="width: 400px">
              <table class="table">
                <tbody>
                <tr>
                  <td class="verticalTableHeader">Data wysyłki</td>
                  <td v-if="summary">-</td>
                  <td v-else>{{
                      props.row.send_hour ? props.row.send_hour + ":00, " + getDetailedDate(props.row.send_date) : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Lista mailingowa</td>
                  <td v-if="summary">-</td>
                  <td v-else>{{
                      props.row.send_list_id ? "[" + props.row.send_list_id + "] " + props.row.list_name : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Media House</td>
                  <td v-if="summary">-</td>
                  <td v-else>{{
                      props.row.media_house_id ? "[" + props.row.media_house_id + "] " + props.row.media_house_name : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Temat</td>
                  <td v-if="summary">-</td>
                  <td v-else>{{ props.row.subject ? props.row.subject : "-" }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Nadawca</td>
                  <td v-if="summary">-</td>
                  <td v-else>{{
                      props.row.from_name ? props.row.from_name + " [" + props.row.from_email + "]" : "-"
                    }}
                  </td>
                </tr>
                <tr style="background-color: #fafafa">
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Otwarcia</td>
                  <td>{{ props.row.display | formatNumber }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Kliknięcia</td>
                  <td>{{ props.row.click | formatNumber }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">
                    <el-tooltip class="item" effect="light" content="Kliki/Otwarcia" placement="top" :open-delay="200">
                      <span>Kliknięcia 2 [%]</span>
                    </el-tooltip>
                  </td>
                  <td>{{ props.row.display !== 0 ? getPercentage(props.row.click, props.row.display) + "%" : "" }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Main</td>
                  <td>{{ props.row.main | formatNumber }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Full Lead</td>
                  <td>{{ props.row.full_lead | formatNumber }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div style="width: 400px; margin-left: 50px">
              <table class="table">
                <thead>
                <td>Rekordy</td>
                <td>Zebrane</td>
                <td>Sprzedane</td>
                </thead>
                <tbody>
                <tr>
                  <td class="verticalTableHeader">Lead</td>
                  <td>{{ props.row.Lead | formatNumber }}</td>
                  <el-tooltip class="item" effect="light" :content="props.row.LeadSold | formatNumber" placement="top"
                              :open-delay="300">
                    <td>{{ getRatio(props.row.LeadSold, props.row.Lead) }}</td>
                  </el-tooltip>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Lead F1</td>
                  <td>{{ props.row.LeadF1 | formatNumber }}</td>
                  <el-tooltip class="item" effect="light" :content="props.row.LeadF1Sold | formatNumber" placement="top"
                              :open-delay="300">
                    <td>{{ getRatio(props.row.LeadF1Sold, props.row.LeadF1) }}</td>
                  </el-tooltip>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Full Lead</td>
                  <td>{{ props.row.FullLead | formatNumber }}</td>
                  <el-tooltip class="item" effect="light" :content="props.row.FullLeadSold | formatNumber"
                              placement="top" :open-delay="300">
                    <td>{{ getRatio(props.row.FullLeadSold, props.row.FullLead) }}</td>
                  </el-tooltip>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Lead Hot</td>
                  <td>{{ props.row.LeadHot | formatNumber }}</td>
                  <el-tooltip class="item" effect="light" :content="props.row.LeadHotSold | formatNumber"
                              placement="top" :open-delay="300">
                    <td>{{ getRatio(props.row.LeadHotSold, props.row.LeadHot) }}</td>
                  </el-tooltip>
                </tr>
                <tr>
                  <td class="verticalTableHeader">New Lead</td>
                  <td>{{ props.row.NewLead | formatNumber }}</td>
                  <el-tooltip class="item" effect="light" :content="props.row.NewLeadSold | formatNumber"
                              placement="top" :open-delay="300">
                    <td>{{ getRatio(props.row.NewLeadSold, props.row.NewLead) }}</td>
                  </el-tooltip>
                </tr>
                <tr>
                  <td class="verticalTableHeader">New Lead F1</td>
                  <td>{{ props.row.NewLeadF1 | formatNumber }}</td>
                  <el-tooltip class="item" effect="light" :content="props.row.NewLeadF1Sold | formatNumber"
                              placement="top" :open-delay="300">
                    <td>{{ getRatio(props.row.NewLeadF1Sold, props.row.NewLeadF1) }}</td>
                  </el-tooltip>
                </tr>
                <tr>
                  <td class="verticalTableHeader">New Full Lead</td>
                  <td>{{ props.row.NewFullLead | formatNumber }}</td>
                  <el-tooltip class="item" effect="light" :content="props.row.NewFullLeadSold | formatNumber"
                              placement="top" :open-delay="300">
                    <td>{{ getRatio(props.row.NewFullLeadSold, props.row.NewFullLead) }}</td>
                  </el-tooltip>
                </tr>
                <tr>
                  <td class="verticalTableHeader">New Person</td>
                  <td>{{ props.row.NewPerson | formatNumber }}</td>
                  <el-tooltip class="item" effect="light" :content="props.row.NewPersonSold | formatNumber"
                              placement="top" :open-delay="300">
                    <td>{{ getRatio(props.row.NewPersonSold, props.row.NewPerson) }}</td>
                  </el-tooltip>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col"></div>
            <div style="width: 400px">
              <table class="table">
                <tbody>
                <tr>
                  <td class="verticalTableHeader">CPL F1</td>
                  <td>{{ getCPL(props.row.cost, props.row.f1_lead) }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">CPL Full Lead</td>
                  <td>{{ getCPL(props.row.cost, props.row.full_lead) }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Sprzedane UU</td>
                  <td>{{ props.row.leads_uu }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Sprzedane UU/Reko</td>
                  <td>{{ getRatio(props.row.leads_uu, props.row.reko_lead) }}</td>
                </tr>
                <tr style="background-color: #fafafa">
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Przychód CPM</td>
                  <td>{{ getCPMIncome(props.row.income, props.row.mail_sent) }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Przychód Hot</td>
                  <td>{{ props.row.income_hot | formatCurrency }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Przychód Warm</td>
                  <td>{{ props.row.income_warm | formatCurrency }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Zysk CPM</td>
                  <td>{{ getCPMRevenue(getRevenue(props.row.income, props.row.cost), props.row.mail_sent) }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">Zysk/Strata Hot</td>
                  <td>{{ getRevenue(props.row.income_hot, props.row.cost) | formatCurrency }}</td>
                </tr>
                <tr>
                  <td class="verticalTableHeader">ROI Hot</td>
                  <td>{{
                      getRoundPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + "%"
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="summary" label min-width="183" property></el-table-column>
      <el-table-column v-else label="Banner" min-width="200" property="banner_id">
        <template v-slot="props">
          <el-tooltip class="item" effect="light" :content="props.row.banner_name" placement="top" :open-delay="200">
            <span class="truncate" :style="!summary ? 'cursor: pointer;' : ''" @click="bannerClick(props.row)">{{
                props.row.banner_id !== null ? "[" + props.row.banner_id + "] " + props.row.banner_name : props.row.banner_name
              }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Wysłane" min-width="100" property="mail_sent" align="right">
        <template v-slot="props">{{ props.row.mail_sent | formatNumber }}</template>
      </el-table-column>
      <el-table-column label="Otwarcia [%]" min-width="125" property="display" align="right">
        <template v-slot="props"
        >
          <el-tooltip class="item" effect="light" :content="props.row.display.toString()" placement="top"
                      :open-delay="200"
          ><span>{{ getPercentage(props.row.display, props.row.mail_sent) + "%" }}</span></el-tooltip
          >
        </template
        >
      </el-table-column>
      <el-table-column label="Kliki [%]" min-width="90" property="click" align="right">
        <template v-slot="props"
        >
          <el-tooltip class="item" effect="light" :content="props.row.click.toString()" placement="top"
                      :open-delay="200"
          ><span>{{ getPercentage(props.row.click, props.row.mail_sent) + "%" }}</span></el-tooltip
          >
        </template
        >
      </el-table-column>
      <el-table-column label="Lead F1" min-width="95" property="f1_lead" align="right">
        <template v-slot="props">{{ props.row.f1_lead | formatNumber }}</template>
      </el-table-column>
      <el-table-column label="Lead Reko" min-width="110" property="reko_lead" align="right">
        <template v-slot="props">{{ props.row.reko_lead | formatNumber }}</template>
      </el-table-column>
      <el-table-column label="CPL Reko" min-width="100" property="cpl_lead_reko" align="right">
        <template v-slot="props">{{ getCPL(props.row.cost, props.row.reko_lead) }}</template>
      </el-table-column>
      <el-table-column label="Sprz/F1" min-width="100" property="uu_f1_ratio" align="right">
        <template slot="header">
          <el-tooltip class="item" effect="light" content="Sprzedane UU/Lead F1" placement="top" :open-delay="200">
            <span>Sprz/F1</span>
          </el-tooltip>
        </template>
        <template v-slot="props">{{ getRatio(props.row.leads_uu, props.row.f1_lead) }}</template>
      </el-table-column>
      <el-table-column label="Sprz/Lreko" min-width="120" property="uu_reko_ratio" align="right">
        <template slot="header">
          <el-tooltip class="item" effect="light" content="Sprzedane/Lead reko//SprzedaneUU/Lead reko" placement="top"
                      :open-delay="200">
            <span>Sprz/Lreko</span>
          </el-tooltip>
        </template>
        <template v-slot="props">
          <el-tooltip
            class="item"
            effect="light"
            :content="props.row.leads + '/' + props.row.reko_lead + '//' + props.row.leads_uu + '/' + props.row.reko_lead"
            placement="top"
            :open-delay="200"
          >
            <span>{{
                getRatio(props.row.leads, props.row.reko_lead) + "/" + getRatio(props.row.leads_uu, props.row.reko_lead)
              }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- Część finansowa -->
      <el-table-column fixed="right" v-if="showRoi" label="Przychód" min-width="110" property="income" align="right">
        <template slot="header">
          <span>Przychód</span>
        </template>
        <template v-slot="props">
          <span>{{ props.row.income | formatCurrency }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" v-if="showRoi" label="Koszt" min-width="110" property="cost" align="right">
        <template v-slot="props">{{ props.row.cost | formatCurrency }}</template>
      </el-table-column>
      <el-table-column fixed="right" v-if="showRoi" label="Zysk/Strata" min-width="120" property="revenue"
                       align="right">
        <template v-slot="props">
          <b>{{ getRevenue(props.row.income, props.row.cost) | formatCurrency }}</b>
        </template>
      </el-table-column>
      <el-table-column fixed="right" v-if="showRoi" label="ROI Hot%" min-width="130" property="date_from">
        <template v-slot="props">
          <div class="progress" style="height: 15px">
            <div
              :class="
                getRoundPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) < 0
                  ? 'progress-bar bg-danger'
                  : 'progress-bar bg-success'
              "
              :style="'width: ' + getAbsolutPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + '%;'"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span class="percentageValue">{{
                  getRoundPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + "%"
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" v-if="showRoi" label="ROI%" min-width="130" property="date_from">
        <template v-slot="props">
          <div class="progress" style="height: 15px">
            <div
              :class="
                getRoundPercentage(getRevenue(props.row.income, props.row.cost), props.row.cost) < 0
                  ? 'progress-bar bg-danger'
                  : 'progress-bar bg-success'
              "
              :style="'width: ' + getAbsolutPercentage(getRevenue(props.row.income, props.row.cost), props.row.cost) + '%;'"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span class="percentageValue">{{
                  getRoundPercentage(getRevenue(props.row.income, props.row.cost), props.row.cost) + "%"
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

// event bus
import {eventBus} from "src/main";

import {Tooltip} from "element-ui";

import BannerSalesModal from "src/components/Utils4/sales/views/BannerSalesModal.vue";

export default {
  components: {
    [Tooltip.name]: Tooltip,
  },
  props: ["tableData", "showRoi", "summary", "dateRange"],
  methods: {
    getPercentage(a, b) {
      if (b === 0) {
        return 0;
      }
      return Math.round((a / b) * 100 * 100) / 100;
    },
    getRoundPercentage(a, b) {
      if (b === 0) {
        return 0;
      }
      return Math.round((a / b) * 100);
    },
    getAbsolutPercentage(a, b) {
      return Math.abs(this.getRoundPercentage(a, b));
    },
    getRevenue(a, b) {
      return a - b;
    },
    getCPMIncome(income, sent) {
      if (sent === 0) {
        return "-";
      }
      return utils.formatNumber((income / sent) * 1000, 2) + " zł";
    },
    getCPMRevenue(revenue, sent) {
      if (sent === 0) {
        return "-";
      }
      return utils.formatNumber((revenue / sent) * 1000, 2) + " zł";
    },
    getCPL(cost, lead) {
      if (lead > 0) {
        return this.formatNumber(cost / lead, 2) + " zł";
      } else {
        return "-";
      }
    },
    getRatio(numerator, denominator) {
      if (denominator > 0) {
        return Math.round((numerator / denominator) * 100) + "%";
      } else {
        return "-";
      }
    },
    getDetailedDate(date) {
      moment.locale("pl");
      const dateObj = Date.parse(date);
      return moment(dateObj).format("dddd, Do MMMM YYYY");
    },
    rowStyle({row}) {
      if (row.banner_name === "Suma") {
        return "background-color: #f2fcff; font-weight: bold;";
      }
      return "";
    },
    cellClass({column, row}) {
      if (row.banner_id === null && column.property === undefined) {
        return "mailing-efficiency__hide-expand";
      }
      return "";
    },
    headerStyle(obj) {
      return "font-size: 12px;";
    },
    formatNumber(number, decimals = 0) {
      let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number).toFixed(decimals).split(".");
      n[0] = n[0]
        .split("")
        .reverse()
        .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
        .reverse()
        .join("");
      let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

      return final;
    },
    bannerClick(row) {
      if (!this.summary) {
        this.$modal.show(
          BannerSalesModal,
          {
            dateRange: this.dateRange,
            bannerID: row.banner_id,
            bannerName: row.banner_name,
            buttons: [
              {
                title: "Zamknij",
                handler: () => {
                  this.$modal.hide(BannerSalesModal);
                },
              },
            ],
          },
          {
            name: "BannerSalesModal",
            draggable: false,
            scrollable: true,
            width: "600px",
            height: "auto",
            maxHeight: 800,
            pivotX: 0.5,
            pivotY: 0.6,
            adaptive: true,
          },
        );
      }
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
  },
};
</script>
<style scoped>
.percentageValue {
  color: #383838;
  font-weight: bold;
}

.verticalTableHeader {
  font-weight: bold;
  width: 200px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

@supports (selector(:nth-child(1 of x)

)) or

(-webkit-touch-callout: none

) {

  .truncate {
    white-space: normal;
  }
}


.tableRow {
  margin: 0;
}
</style>

<style>
.mailing-efficiency__hide-expand :first-child {
  visibility: hidden !important;
}
</style>
