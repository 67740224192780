<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Raport efektywności kreacji mailingowych</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="-"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd"
          :default-value="defaultDate"
        ></el-date-picker>
      </div>
    </div>

    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <label for="project">Projekt</label>
            <el-select v-model="projectsArray" name="project" data-vv-as="Projekt" style="width: 100%" multiple filterable clearable>
              <el-option
                v-for="project in projects"
                :label="'[' + project.project_id + '] ' + project.project_name"
                :value="project.project_id"
                :key="project.project_id"
              ></el-option>
            </el-select>
          </div>
          <div class="col-sm-3">
            <label>Filtr list</label>
            <fg-input
              placeholder="Filtruj po liście mailingowej"
              name="mailingListFilter"
              data-vv-as="Filtr list mailingowych"
              v-model="mailingListFilter"
              v-validate="mailingListFilterValidation"
              :error="getError('mailingListFilter')"
            >
            </fg-input>
          </div>
          <div class="col-sm-3">
            <label for="mailingList">Typ bannera</label>
            <el-select
              id="bannerTypes"
              v-model="bannerTypes"
              name="bannerTypes"
              style="width: 100%"
              multiple
              filterable
              clearable
            >
              <el-option v-for="bannertType in bannerTypesDict" :key="bannertType" :value="bannertType"
                         :label="bannertType">


              </el-option>
            </el-select>
          </div>
          <div class="col-sm-2">
            <label for="mailingOwner">Właściciel</label>
            <el-checkbox-group name="mailingOwner" v-model="mailingOwner">
              <el-checkbox-button v-for="owner in mailingOwners" :label="owner" :key="owner">{{ owner }}</el-checkbox-button>
            </el-checkbox-group>
          </div>

        </div>
        <div class="row">
          <div class="col-sm-2 left-center">
            <el-switch name="showROI" v-model="showROI" active-text="ROI" active-color="#28a745" inactive-color="#c4c4c4"></el-switch>
          </div>
          <div class="col-sm-6 text-left">
            <label for="mailingTypeGroup">Rodzaj wysyłki</label>
            <el-checkbox-group name="mailingTypeGroup" v-model="mailingType">
              <el-checkbox-button v-for="type in mailingTypes" :label="type" :key="type">{{ type }}</el-checkbox-button>
            </el-checkbox-group>
          </div>

          <div class="col-sm-4 right-bottom">
            <p-button type="success" @click="loadData(false)">Pokaż</p-button>
            <p-button type="success" @click="loadData(true)">Eksport</p-button>
          </div>
        </div>
      </div>
      <hr />
      <h6>
        <div class="card-footer" style="display: flex; justify-content: space-between">
          <span>Przedział dat:</span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </div>
      </h6>
    </div>

    <div class="card" v-if="this.$store.state.creations_efficiency.loading_status === 'success'">
      <el-table
        :data="Object.values(efficiencyRaport)"
        style="width: 100%"
        size="mini"
        :row-style="rowStyle"
        :cell-class-name="cellClass"
        :header-cell-style="headerStyle"
        :default-sort="{ prop: 'roi', order: 'descending' }"
      >
        <el-table-column type="expand">
          <template v-slot="props">
            <div class="row tableRow">
              <div style="width: 400px">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="verticalTableHeader">Data wysyłki</td>
                      <td>
                        {{ props.row.send_hour + ":00, " + getDetailedDate(props.row.send_date) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Lista mailingowa</td>
                      <td>
                        {{ "[" + props.row.send_list_id + "] " + props.row.list_name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Media House</td>
                      <td>{{ props.row.mediahouse }}</td>
                    </tr>
                    <tr style="background-color: #fafafa">
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Otwarcia</td>
                      <td>{{ props.row.display | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Kliknięcia</td>
                      <td>{{ props.row.click | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">
                        <el-tooltip class="item" effect="light" content="Kliki/Otwarcia" placement="top" :open-delay="200">
                          <span>Kliknięcia 2 [%]</span>
                        </el-tooltip>
                      </td>
                      <td>
                        {{ props.row.display !== 0 ? getPercentage(props.row.click, props.row.display) + "%" : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Main</td>
                      <td>{{ props.row.main | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Lead Reko</td>
                      <td>{{ props.row.reko_lead | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Full Lead</td>
                      <td>{{ props.row.full_lead | formatNumber }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style="width: 400px; margin-left: 50px">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="verticalTableHeader">Od nazwa</td>
                      <td>{{ props.row.from_name }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Od email</td>
                      <td>{{ props.row.from_email }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Temat</td>
                      <td>{{ props.row.subject }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Odpowiedz nazwa</td>
                      <td>{{ props.row.reply_name }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Odpowiedz email</td>
                      <td>{{ props.row.reply_email }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Kreacja</td>
                      <td class="campaignLink" @click="openCreation(props.row.campaign_id)">
                        {{ props.row.campaign_id }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col"></div>
              <div style="width: 400px" v-if="showROI">
                <table class="table">
                  <tbody>
                    <tr>
                      <td class="verticalTableHeader">CPL Full Lead</td>
                      <td>{{ getCPL(props.row.cost, props.row.full_lead) }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Sprzedane UU</td>
                      <td>{{ props.row.leads_uu }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Sprzedane UU/Reko</td>
                      <td>
                        {{ getRatio(props.row.leads_uu, props.row.reko_lead) }}
                      </td>
                    </tr>
                    <tr style="background-color: #fafafa">
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Przychód CPM</td>
                      <td>
                        {{ getCPMIncome(props.row.income, props.row.mail_sent) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Przychód Hot</td>
                      <td>{{ props.row.income_hot | formatCurrency }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Przychód Warm</td>
                      <td>{{ props.row.income_warm | formatCurrency }}</td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Zysk CPM</td>
                      <td>
                        {{ getCPMRevenue(getRevenue(props.row.income, props.row.cost), props.row.mail_sent) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">Zysk/Strata Hot</td>
                      <td>
                        {{ getRevenue(props.row.income_hot, props.row.cost) | formatCurrency }}
                      </td>
                    </tr>
                    <tr>
                      <td class="verticalTableHeader">ROI Hot</td>
                      <td>
                        {{ getRoundPercentage(getRevenue(props.row.income_hot, props.row.cost), props.row.cost) + "%" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Projekt" width="95" property="project_id">
          <template v-slot="props">
            <el-tooltip class="item" effect="light" :content="props.row.project" placement="top" :open-delay="200">
              <span class="truncate">{{ "[" + props.row.project_id + "]" }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Baner" min-width="280" property="banner" sortable>
          <!-- <template v-slot="props">
            <el-tooltip
              class="item"
              effect="light"
              :content="props.row.banner"
              placement="top"
              :open-delay="200"
            >
              <span class="truncate">{{ props.row.banner }}</span>
            </el-tooltip>
          </template> -->
        </el-table-column>
        <el-table-column label="Wysłane" width="120" property="mail_sent" align="right" sortable>
          <template v-slot="props">{{ props.row.mail_sent | formatNumber }}</template>
        </el-table-column>
        <el-table-column label="Otwarcia [%]" width="150" property="display_percentage" align="right" sortable>
          <template v-slot="props">
            <el-tooltip class="item" effect="light" :content="String(props.row.display)" placement="top" :open-delay="200">
              <span class="truncate">{{ getPercentage(props.row.display, props.row.mail_sent) + "%" }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Kliki [%]" width="115" property="click_percentage" align="right" sortable>
          <template v-slot="props">
            <el-tooltip class="item" effect="light" :content="String(props.row.click)" placement="top" :open-delay="200">
              <span class="truncate">{{ getPercentage(props.row.click, props.row.mail_sent) + "%" }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Lead F1" width="115" property="f1_lead" align="right" sortable>
          <template v-slot="props">{{ props.row.f1_lead | formatNumber }}</template>
        </el-table-column>
        <el-table-column label="CPL F1" width="100" property="cpl_f1" align="right" sortable>
          <template v-slot="props">{{ getCPL(props.row.cost, props.row.f1_lead) }}</template>
        </el-table-column>
        <el-table-column label="CPL Reko" width="125" property="cpl_reko" align="right" sortable>
          <template v-slot="props">{{ getCPL(props.row.cost, props.row.reko_lead) }}</template>
        </el-table-column>
        <!-- Część finansowa -->
        <el-table-column fixed="right" v-if="showROI" label="Przychód" width="130" property="income" align="right" sortable>
          <template slot="header">
            <span>Przychód</span>
          </template>
          <template v-slot="props">
            <span>{{ props.row.income | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" v-if="showROI" label="Koszt" width="100" property="cost" align="right" sortable>
          <template v-slot="props">{{ props.row.cost | formatCurrency }}</template>
        </el-table-column>
        <el-table-column fixed="right" v-if="showROI" label="Zysk/Strata" width="140" property="revenue" align="right" sortable>
          <template v-slot="props">
            <b>{{ getRevenue(props.row.income, props.row.cost) | formatCurrency }}</b>
          </template>
        </el-table-column>
        <el-table-column fixed="right" v-if="showROI" label="ROI%" width="130" property="roi" sortable>
          <template v-slot="props">
            <div class="progress" style="height: 15px">
              <div
                :class="
                  getRoundPercentage(getRevenue(props.row.income, props.row.cost), props.row.cost) < 0
                    ? 'progress-bar bg-danger'
                    : 'progress-bar bg-success'
                "
                :style="'width: ' + getAbsolutPercentage(getRevenue(props.row.income, props.row.cost), props.row.cost) + '%;'"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span class="percentageValue">{{ getRoundPercentage(getRevenue(props.row.income, props.row.cost), props.row.cost) + "%" }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";

import { DatePicker, Collapse, CollapseItem, Select, Option, Tooltip, Switch, CheckboxGroup, CheckboxButton } from "element-ui";

import { LOAD_CREATIONS_EFFICIENCY, CREATIONS_EFFICIENCY_INVALID } from "src/store/actions/secure/creations_efficiency";
import { LOAD_CREATIONS_EFFICIENCY_EXPORT, CREATIONS_EFFICIENCY_EXPORT_INVALID } from "src/store/actions/secure/creations_efficiency_export";
import { LOAD_PROJECTS } from "src/store/actions/questions/projects";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [CollapseItem.name]: CollapseItem,
    [Collapse.name]: Collapse,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Switch.name]: Switch,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
  },
  data() {
    return {
      loading: false,
      showROI: true,
      mailingOwner: ["MM"],
      mailingOwners: ["MM"],
      mailingTypes: ["Zewnętrzne", "Wewnętrzne"],
      mailingType: ["Zewnętrzne", "Wewnętrzne"],
      projectsArray: [],
      mailingListFilter: "",
      mailingListFilterValidation: {
        regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ._]+)$",
        max: 32,
      },
      bannerTypesDict: ['DOUBLEOPTIN','MAILING','POSTPROCESSING'],
      bannerTypes: [],      dateRange: ["", ""],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    async initLoad(force) {
      this.loading = true;

      if (force) this.$store.commit(PROJECTS_INVALID);

      await this.$store.dispatch(LOAD_PROJECTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    async loadData(excel) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }

      this.loading = true;

      const filters = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        mailing_type: this.mailingType,
        project_list: this.projectsArray,
        mailing_list_filter: this.mailingListFilter,
        mailing_owner: this.mailingOwner,
        bannerTypes:this.bannerTypes
      };

      if (excel) {
        this.$store.commit(CREATIONS_EFFICIENCY_EXPORT_INVALID);

        await this.$store.dispatch(LOAD_CREATIONS_EFFICIENCY_EXPORT, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });

        const tmpName = this.$store.getters.getCreationsEfficiencyExport.tmpname;
        const fileName = this.$store.getters.getCreationsEfficiencyExport.name;
        this.downloadFile(tmpName, fileName);
      } else {
        this.$store.commit(CREATIONS_EFFICIENCY_INVALID);

        await this.$store.dispatch(LOAD_CREATIONS_EFFICIENCY, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
      }

      this.loading = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getPercentage(a, b) {
      if (b === 0) {
        return 0;
      }
      return Math.round((a / b) * 100 * 100) / 100;
    },
    getRoundPercentage(a, b) {
      if (b === 0) {
        return 0;
      }
      return Math.round((a / b) * 100);
    },
    getAbsolutPercentage(a, b) {
      return Math.abs(this.getRoundPercentage(a, b));
    },
    getRevenue(a, b) {
      return a - b;
    },
    getCPMIncome(income, sent) {
      if (sent === 0) {
        return "-";
      }
      return utils.formatNumber((income / sent) * 1000, 2) + " zł";
    },
    getCPMRevenue(revenue, sent) {
      if (sent === 0) {
        return "-";
      }
      return utils.formatNumber((revenue / sent) * 1000, 2) + " zł";
    },
    getCPL(cost, lead) {
      if (lead > 0) {
        return this.formatNumber(cost / lead, 2) + " zł";
      } else {
        return "-";
      }
    },
    getRatio(numerator, denominator) {
      if (denominator > 0) {
        return Math.round((numerator / denominator) * 100) + "%";
      } else {
        return "-";
      }
    },
    rowStyle({ row }) {
      if (row.banner_name === "Suma") {
        return "background-color: #f2fcff; font-weight: bold;";
      }
      return "";
    },
    cellClass({ column, row }) {
      if (row.banner_id === null && column.property === undefined) {
        return "mailing-efficiency__hide-expand";
      }
      return "";
    },
    headerStyle(obj) {
      return "font-size: 12px;";
    },
    getDetailedDate(date) {
      moment.locale("pl");
      const dateObj = Date.parse(date);
      return moment(dateObj).format("dddd, Do MMMM YYYY");
    },
    formatNumber(number, decimals = 0) {
      let decpoint = ","; // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = " "; // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number).toFixed(decimals).split(".");
      n[0] = n[0]
        .split("")
        .reverse()
        .map((c, i, a) => (i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c))
        .reverse()
        .join("");
      let final = (Math.sign(number) < 0 ? "-" : "") + n.join(decpoint);

      return final;
    },
    openCreation(campaign_id) {
      window.open(`https://utils2.mobileformats.pl/mailing/campainsworkingSee/id=${campaign_id}`, "_blank");
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-contract.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  computed: {
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    efficiencyRaport() {
      return this.$store.getters.getCreationsEfficiency;
    },
    projects() {
      // lista projektów do wybrania z listy
      let projects = Object.values(this.$store.getters.getProjects);
      let uniqueProjects = {};

      for (let i in projects) {
        // wybranie unikalnych projektów
        uniqueProjects[projects[i].project_id] = {
          project_id: projects[i].project_id,
          project_name: projects[i].project_name,
        };
      }

      let result = Object.values(uniqueProjects).sort((a, b) => (a.project_id > b.project_id ? 1 : -1)); // sortowanie projektów po nazwie

      return result;
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
  },
  mounted() {},
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData(false);
      this.initLoad(true);
    });
    this.initLoad(false);
  },
  beforeDestroy: function () {
    this.$store.commit(CREATIONS_EFFICIENCY_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.percentageValue {
  color: #383838;
  font-weight: bold;
}

.weekHeader {
  padding: 15px 15px 5px !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.verticalTableHeader {
  font-weight: bold;
  width: 200px;
}

.left-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.right-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.campaignLink:hover {
  cursor: pointer;
  text-decoration: underline;
}

.tableRow {
  margin: 0;
}
</style>

<style>
.mailing-efficiency__hide-expand :first-child {
  visibility: hidden !important;
}
</style>
