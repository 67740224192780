import storeGen from '../../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  SAVE_UPLOAD_COMPLAINT_FLOW_FILES,
  UPLOAD_COMPLAINT_FLOW_FILES_SAVING,
  UPLOAD_COMPLAINT_FLOW_FILES_SAVING_SUCCESS,
  UPLOAD_COMPLAINT_FLOW_FILES_SAVING_ERROR
} from '../../../actions/complaints/actions/upload_complaint_flow_files'

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getUploadComplaintFlowFiles",
  saveActionName: SAVE_UPLOAD_COMPLAINT_FLOW_FILES,
  savingMutationName: UPLOAD_COMPLAINT_FLOW_FILES_SAVING,
  savingSuccessMutationName: UPLOAD_COMPLAINT_FLOW_FILES_SAVING_SUCCESS,
  savingErrorMutationName: UPLOAD_COMPLAINT_FLOW_FILES_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'binary',
  saveParams: {
    url: "/complaints/action/complaint_flow_file",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
