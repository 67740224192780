import storeGen from "src/store/storeGenerator";

import { AUTH_LOGOUT } from "src/store/actions/auth";

import { SECURE_API, BASE_API } from "src/store/config";

import {
  LOAD_DEDUPLICATION,
  DEDUPLICATION_LOADING,
  DEDUPLICATION_LOADING_SUCCESS,
  DEDUPLICATION_LOADING_ERROR,
  DEDUPLICATION_INVALID,
  SAVE_DEDUPLICATION,
  DEDUPLICATION_SAVING,
  DEDUPLICATION_SAVING_SUCCESS,
  DEDUPLICATION_SAVING_ERROR,
  DELETE_DEDUPLICATION,
  DEDUPLICATION_DELETING,
  DEDUPLICATION_DELETING_SUCCESS,
  DEDUPLICATION_DELETING_ERROR,
} from "src/store/actions/sales/deduplication";

const storeObjectConfig = {
  objectName: "Deduplication",
  getterName: "getDeduplication",
  loadActionName: LOAD_DEDUPLICATION,
  loadingMutationName: DEDUPLICATION_LOADING,
  loadingSuccessMutationName: DEDUPLICATION_LOADING_SUCCESS,
  loadingErrorMutationName: DEDUPLICATION_LOADING_ERROR,
  invalidMutationName: DEDUPLICATION_INVALID,
  saveActionName: SAVE_DEDUPLICATION,
  savingMutationName: DEDUPLICATION_SAVING,
  savingSuccessMutationName: DEDUPLICATION_SAVING_SUCCESS,
  savingErrorMutationName: DEDUPLICATION_SAVING_ERROR,
  deleteActionName: DELETE_DEDUPLICATION,
  deletingMutationName: DEDUPLICATION_DELETING,
  deletingSuccessMutationName: DEDUPLICATION_DELETING_SUCCESS,
  deletingErrorMutationName: DEDUPLICATION_DELETING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/sales/deduplication",
    method: "post", // {'get','post','put'}
  },
  saveParams: {
    url: "/sales/deduplication",
    method: "put", // {'get','post','put'}
  },
  deleteParams: {
    url: "/sales/deduplication",
    method: "delete", // {'delete'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
