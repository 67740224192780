<template>
  <div class="modal-wrapper" :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row header-row">
      <div class="col-sm-11">
        <div class="row">
          <div class="col-sm-12">
            <h5 class="title">Analiza paczki {{ row.id }} dla klienta '{{ row.client }}'</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
    </div>

    <div class="row" v-if="loading">
      <div class="col-sm-12">
        <div class="center" style="font-size: 1.3em;">
          Wczytywanie danych
        </div>
      </div>
    </div>
    <div v-if="ready">
      <div class="card">
        <div class="card-body">
          <div class="row center">
            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-6">
                  <h4 class="card-title center">Rekordy UU</h4>
                  <h5 class="center">{{ row.records_valid }}</h5>
                </div>
                <div class="col-sm-6">
                  <h4 class="card-title center">Filtracja</h4>
                  <el-tooltip
                      class="item"
                      effect="light"
                      :content="(filterAnalyseData.full.steps[0].records).toString()"
                      placement="top"
                      :open-delay="500"
                    >
                    <h5 class="center">{{ getFiltrationPercentage() }}</h5>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-title">
          <h4>Analiza przyrostowa</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <doughnut-chart :labels="simpleChart.labels"
                      :height="300"
                      :extra-options="simpleChart.extraOptions"
                      :datasets="simpleChart.datasets">
              </doughnut-chart>
            </div>
            <div class="col-sm-6">
              <el-table :data="simpleTableData" style="width: 100%">
                <el-table-column label="Kryterium" min-width="150" align="center">
                  <template v-slot="props">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="props.row.filter_name"
                      placement="top"
                      :open-delay="500"
                    >
                      <span :style="props.row.step_no == 0 ? 'font-weight: bold;' : ''">{{ props.row.step_name }}</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column label="Rekordy" width="120" align="right">
                  <template v-slot="props">
                    {{ props.row.records | formatNumber }}
                  </template>
                </el-table-column>
                <el-table-column label="Różnica" width="120" align="right">
                  <template v-slot="props">
                    {{ props.row.difference | formatNumber }}
                  </template>
                </el-table-column>
                <el-table-column label="Odpada %" width="120" align="right">
                  <template v-slot="props">
                    {{ formatNumber(props.row.percentage, 1) + '%' }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-title">
          <h4>Analiza niezależna</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <bar-chart :labels="viewsChart.labels"
                      :height="400"
                      :extra-options="viewsChart.options"
                      :datasets="viewsChart.datasets">
              </bar-chart>
            </div>
            <div class="col-sm-6">
              <el-table :data="fullTableData" style="width: 100%">
                <el-table-column label="Kryterium" min-width="150" align="center">
                  <template v-slot="props">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="props.row.filter_name"
                      placement="top"
                      :open-delay="500"
                    >
                      <span :style="props.row.step_no == 0 ? 'font-weight: bold;' : ''">{{ props.row.step_name }}</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column label="Rekordy" width="120" align="right">
                  <template v-slot="props">
                    {{ props.row.records | formatNumber }}
                  </template>
                </el-table-column>
                <el-table-column label="Różnica" width="120" align="right">
                  <template v-slot="props">
                    {{ props.row.difference | formatNumber }}
                  </template>
                </el-table-column>
                <el-table-column label="Odpada %" width="120" align="right">
                  <template v-slot="props">
                    {{ formatNumber(props.row.percentage, 1) + '%' }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-title">
          <h4>Analiza deduplikacyjna</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <doughnut-chart :labels="deduplicationChart.labels"
                      :height="300"
                      :extra-options="deduplicationChart.extraOptions"
                      :datasets="deduplicationChart.datasets">
              </doughnut-chart>
            </div>
            <div class="col-sm-6">
              <el-table :data="deduplicationTableData" style="width: 100%">
                <el-table-column label="Kryterium" min-width="150" align="center">
                  <template v-slot="props">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="props.row.filter_name"
                      placement="top"
                      :open-delay="500"
                    >
                      <span :style="props.row.step_no == 0 ? 'font-weight: bold;' : ''">{{ props.row.step_name }}</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column label="Rekordy" width="120" align="right">
                  <template v-slot="props">
                    {{ props.row.records | formatNumber }}
                  </template>
                </el-table-column>
                <el-table-column label="Różnica" width="120" align="right">
                  <template v-slot="props">
                    {{ props.row.difference | formatNumber }}
                  </template>
                </el-table-column>
                <el-table-column label="Odpada %" width="120" align="right">
                  <template v-slot="props">
                    {{ formatNumber(props.row.percentage, 1) + '%' }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from 'src/components/UIComponents'
import { Select, Option, Tooltip } from "element-ui";
import moment from "moment"; // time manipulation library
import chroma from "chroma-js"; // color manipulation library
import BarChart from 'src/components/UIComponents/Charts/BarChart'
import DoughnutChart from 'src/components/UIComponents/Charts/DoughnutChart'
import ChartCard from 'src/components/UIComponents/Cards/ChartCard'

// import {  } from "element-ui";
import { LOAD_FILTER_ANALYSE_DATA, FILTER_ANALYSE_DATA_INVALID } from "src/store/actions/filter/filter_analyse_data";
import { LOAD_CLIENTS } from "src/store/actions/questionForm/clients";
// event bus
import { eventBus } from "src/main";
import utils from "src/utils";

import * as priv from 'src/privileges.js';

const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
  };

export default {
  components: {
      Card,
      ChartCard,
      BarChart,
      DoughnutChart,
      [Select.name]: Select,
      [Option.name]: Option,
      [Tooltip.name]: Tooltip,
    },
  data() {
    return {
      loading: false,
      counter: 0,
      referenceCounter: 0,
    }
  },
  props: ['row'],
  computed: {
    filterAnalyseData() {
      return this.$store.getters.getFilterAnalyseData;
    },
    clients() {
      return this.$store.getters.getClients;
    },
    simpleTableData() {
      const simpleData = this.filterAnalyseData.simple;
      if(!simpleData.steps) return [];

      const steps = simpleData.steps;

      let result = [];
      let stepName = '';

      for(let no in steps) {
        let step = steps[no];
        let stepObj = {};
        let diff = 0;
        let diff_percent = 0;
        if(no == 0) {
          stepName = step.description;
        } else {
          stepName = stepName + ', ' + step.description;
          diff = steps[no - 1].records - step.records;
          diff_percent = diff/steps[0].records*100;
        }


        stepObj = {
          step_no: no,
          step_name: step.description,
          filter_name: stepName,
          records: step.records,
          difference: diff,
          percentage: diff_percent
        }

        result.push(stepObj);
      }

      return result
    },
    fullTableData() {
      const fullData = this.filterAnalyseData.full;
      if(!fullData.steps) return [];

      const steps = fullData.steps;

      let result = [];
      let stepName = '';

      for(let no in steps) {
        let step = steps[no];
        let stepObj = {};
        let diff = 0;
        let diff_percent = 0;
        if(no == 0) {
        } else {
          diff = steps[0].records - step.records;
          diff_percent = diff / steps[0].records * 100;
        }


        stepObj = {
          step_no: no,
          step_name: step.description,
          filter_name: steps[0].description + ', ' + step.description,
          records: step.records,
          difference: diff,
          percentage: diff_percent
        }

        result.push(stepObj);
      }

      return result
    },
    deduplicationTableData() {
      const deduplicationData = this.filterAnalyseData.dd;
      if(!deduplicationData.steps) return [];
      if(Object.keys(this.clients) == 0) return [];

      const steps = Object.values(deduplicationData.steps);

      let result = [];
      let stepName = '';
      let filterName = '';

      for(let no in steps) {
        let step = steps[no];
        let stepObj = {};
        let diff = 0;
        let diff_percent = 0;
        let clientID = null;
        let clientName = null;
        let dateFrom = null;
        let dateTo = null;

        if(steps[no].filter_param) {
          const clientJSON = JSON.parse(steps[no].filter_param);
          clientID = clientJSON.params['client_id_list'][0];
          clientName = this.clients[clientID].client_name;
          dateFrom = clientJSON.params.datetime_from;
          dateTo = clientJSON.params.datetime_to;
        }

        if(no == 0) {
          stepName = 'Inicjalne'; // table description
          filterName = step.description// tooltip description
        } else {
          stepName = clientName;
          filterName = `Od: ${dateFrom}, Do: ${dateTo}`;
          diff = steps[no - 1].records - step.records;
          diff_percent = diff/steps[0].records*100;
        }


        stepObj = {
          step_no: no,
          step_name: stepName,
          filter_name: filterName,
          records: step.records,
          difference: diff,
          percentage: diff_percent
        }

        result.push(stepObj);
      }

      return result
    },
    pieChartData() {
      const source = [...this.simpleTableData];

      let result = {labels: [], backgroundColor: [], data: []};

      let data = source.filter(x => x.percentage > 0);
      let reduceResult = data.reduce((acc, el) => acc += parseFloat(el.percentage), 0);
      let counter = parseFloat(this.formatNumber(reduceResult, 2).replace(',', '.'));

      counter = 100 - counter;

      if(data.length > 0) {
        const colorTable = chroma
        .scale('Spectral')
        .mode("lch")
        .colors(data.length);

        for(let i in data) {
          let value = parseFloat(this.formatNumber(data[i].percentage, 2).replace(',', '.'));
          result.labels.push(data[i].step_name);
          result.data.push(value);
          result.backgroundColor.push(colorTable[i]);
        }

        result.labels.push('Rekordy nUU');
        result.data.push(counter);
        result.backgroundColor.push('green');

      }

      return result;
    },
    simpleChart() {
        const obj = {
          labels: [],
          datasets: [{
            label: "Filtracja",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: [],
            borderWidth: 0,
            data: []
          }],
          extraOptions: {
            tooltips: tooltipOptions,
            responsive: true,
            cutoutPercentage: 50,
            legend: {
              display: true
            }
          }
        };

        obj.extraOptions.tooltips = {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
              var dataLabel = data.labels[tooltipItem.index];
              var value = ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString();

              if (Chart.helpers.isArray(dataLabel)) {
                dataLabel = dataLabel.slice();
                dataLabel[0] += value;
              } else {
                dataLabel += value;
              }

              return dataLabel + '%';
            }
          }
        }

        obj.datasets[0].data = this.pieChartData.data;
        obj.datasets[0].backgroundColor = this.pieChartData.backgroundColor;
        obj.labels = this.pieChartData.labels;
        obj.data = this.pieChartData.data;

        return obj;
      },
    chartData() {
      let chartData = this.fullTableData;
      if(chartData.length > 0) {
        let result = {};
        let labels = [];
        let data = [];
        let percentageData = [];

        let reference = chartData[0].records;

        for(let i in chartData) {
          let row = chartData[i];

          if(row.difference !== 0) {
            labels.push(row.step_name);
            data.push(row.records);
            percentageData.push(100 - parseFloat(this.formatNumber(row.records / reference * 100, 2)))
          }
        }
        result = {data: data, percentageData: percentageData, labels: labels};

        return result;
      } else {
        return [];
      }
    },
    viewsChart() {
      let counter = this.counter;
      let referenceCounter = this.referenceCounter;
      let chartData = this.chartData;
      let data = {};
      if(counter !== referenceCounter) { // licznik wymuszający przeliczenie tabeli
        data.labels = [];
        data.datasets = [];
        data.labels = chartData.labels;
        data.data = chartData.data;
        data.percentageData = chartData.percentageData;

        data.datasets.push({
          label: "Rekordy",
          borderColor: "rgba(54, 162, 235, 0.7)",
          fill: true,
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          hoverBorderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
          data: data.percentageData,
        });

        data.options = {
          tooltips: tooltipOptions,
          dataset: {
            maxBarThickness: 100,
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                autoSkip: false
              }
            }],
            yAxes: [{
              gridLines: {
                display: true
              },
              ticks: {
                min: 0,
                max: 100,
                callback: function (value) {
                  return value + '%';
                },
              }
            }]
          }
        }
        data.options.tooltips.callbacks = {
          label: function(tooltipItem) {
            let recordLabel = "Rekordy: " + data.data[tooltipItem.index];
            let percentageLabel = "Odrzucone: " + data.percentageData[tooltipItem.index] + "%";
            let multistringText = '';
            multistringText = [...[recordLabel], ...[percentageLabel]];
            return percentageLabel;
          },
        };
        referenceCounter++;
      }

      return data
    },
    deduplicationChartData() {
      const source = [...this.deduplicationTableData];

      let result = {labels: [], backgroundColor: [], data: []};

      let data = source.filter(x => x.percentage > 0);

      if(data.length > 0) {
        const colorTable = chroma
        .scale('Spectral')
        .mode("lch")
        .colors(data.length);

        for(let i in data) {
          let value = parseFloat(this.formatNumber(data[i].percentage, 2).replace(',', '.'));
          result.labels.push(data[i].step_name);
          result.data.push(value);
          result.backgroundColor.push(colorTable[i]);
        }

      }

      return result;
    },
    deduplicationChart() {
        const obj = {
          labels: [],
          datasets: [{
            label: "Filtracja",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: [],
            borderWidth: 0,
            data: []
          }],
          extraOptions: {
            tooltips: tooltipOptions,
            responsive: true,
            cutoutPercentage: 50,
          }
        };

        obj.extraOptions.tooltips = {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
              var dataLabel = data.labels[tooltipItem.index];
              var value = ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString();

              if (Chart.helpers.isArray(dataLabel)) {
                dataLabel = dataLabel.slice();
                dataLabel[0] += value;
              } else {
                dataLabel += value;
              }

              return dataLabel + '%';
            }
          }
        }

        obj.datasets[0].data = this.deduplicationChartData.data;
        obj.datasets[0].backgroundColor = this.deduplicationChartData.backgroundColor;
        obj.labels = this.deduplicationChartData.labels;
        obj.data = this.deduplicationChartData.data;

        return obj;
      },
    ready() {
      if(this.$store.state.filter_analyse_data.loading_status === 'success') {
        return true
      }
      return false
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData() {
      this.loading = true;
      this.$store.commit(FILTER_ANALYSE_DATA_INVALID);

      const filters = {id: this.row.id};

      this.$store.dispatch(LOAD_CLIENTS, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });

      await this.$store.dispatch(LOAD_FILTER_ANALYSE_DATA, filters).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning"
        });
      });
      this.loading = false;
    },
    formatNumber(number, decimals = 0) {
      let decpoint = ',' // Or Number(0.1).toLocaleString().substring(1, 2)
      let thousand = ' ' // Or Number(10000).toLocaleString().substring(2, 3)

      let n = Math.abs(number).toFixed(decimals).split('.')
      n[0] = n[0].split('').reverse().map((c, i, a) =>
        i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
      ).reverse().join('')
      let final = (Math.sign(number) < 0 ? '-' : '') + n.join(decpoint)

      return final
    },
    getFiltrationPercentage() {
      if(this.filterAnalyseData.full) {
        return this.formatNumber(this.row.records / this.filterAnalyseData.full.steps[0].records * 100, 1) + '%';
      } else {
        return '-';
      }
    }
  },
  created() {
    this.counter += 1;
    this.loadData();
  }
};
</script>

<style lang="css" scoped>
.card-title {
  padding: 0 40px;
}

.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}
.header-row {
  padding-top: 15px;
  padding-left: 15px;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}

</style>
