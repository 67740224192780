<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <el-input
            v-on:input="searchTag()"
            placeholder="Wyszukaj"
            prefix-icon="el-icon-search"
            v-model="searchBar"
            >
          </el-input>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div v-for="(arr, index) in showTags" v-bind:key="index" class='col'>
          <div class="wrapper">
            <div v-for="(tag, index) in arr" v-bind:key="index">
              <ul>
                <li v-for="subtag in tag" v-on:click="tagChecked(subtag)" v-bind:key="subtag.id" v-bind:class="{'checked': subtag.checked === true}">
                  {{ subtag.tag_name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <p-button type="success" @click.prevent="closeModal()">Zapisz</p-button>
      </div>
    </div>
  </div>
</template>

<script>
// ui components
import { Input } from "element-ui";

// import VModal from 'vue-js-modal'

// vuex actions
import { LOAD_TAGS, TAGS_INVALID } from "src/store/actions/questionForm/tags";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
    [Input.name]: Input
  },
  data() {
    return {
      tags: {},
      showTags: {},
      checkedIDs: [],
      tag_changes: {},
      searchBar: '',
      newTags: {}
    }
  },
  computed: {
  },
  props: ['checked_ids_props'],
  methods: {
    async loadData(force) {
      let filters = {
        question_id: parseInt(this.question_id)
      };

      await this.$store.dispatch(LOAD_TAGS, {}).catch(error => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning"
          });
        });

      if (force) {
        this.$store.commit(ANSWERS_INVALID);
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    tagChecked(item) {
      let id = item.id;
      let first_letter = item.tag_name.charAt(0).toLowerCase();
      let new_check = null;
      this.tags[first_letter].map(el => {
        if(el.id === id) {
          el.checked = !el.checked;
          new_check = el.checked;
        }
      })
      if(this.tag_changes[id] === undefined) {
        this.tag_changes[id] = new_check;
      } else {
        delete this.tag_changes[id];
      }
    },
    async saveData() {
    },
    initData() {
      // this.checkedIDs = this.checked_ids_props;
      this.checkedIDs = [];
      let data = Object.values(JSON.parse(JSON.stringify(this.$store.getters.getTags)));
      data.sort((a,b) => (a.tag_name.toLowerCase() > b.tag_name.toLowerCase()) ? 1 : -1);
      data.map(item => {
        if(this.checkedIDs.some(element => element === item.id)) {
          item.checked = true;
        } else item.checked = false;
      });
      let result = {};
      for(let i in data) {
        let id = data[i].id;
        let first_letter = data[i].tag_name.charAt(0).toLowerCase();
        if(result[first_letter] === undefined) {
          result[first_letter] = [];
        }
        result[first_letter].push(data[i]);
      }
      for(let i in result) {
        result[i].sort((a,b) => (a.tag_name.toLowerCase() > b.tag_name.toLowerCase()) ? 1 : -1);
      };
      this.tags = result;
    },
    searchTag() {
      let data = {};
      let result = {};
      for(let i in this.newTags) {
        let el = this.newTags[i];
        for(let j in el) {
          result = el[j]
            .filter((row) => {
              let isIncluded = false
              let rowValue = row.tag_name.toString();
              let rowValueLowerCase = row.tag_name.toString().toLowerCase();
              if ((rowValue.includes && rowValue.includes(this.searchBar)) || rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchBar)) {
                isIncluded = true
              }
              return isIncluded
            })
          if(result.length > 0) {
            if(data[i] === undefined) {
              data[i] = [];
            }
            data[i].push(result);
          }
        }
      }
      this.showTags = data;

    },
    closeModal() {
      eventBus.$emit('answerTagsChanged', this.tag_changes);
      this.$emit('close');
    },
    handleResize() {
      let width = window.innerWidth;
      let tags = this.tags;

      let all_tags_number = 0;
      for(let i in tags) {
        all_tags_number += tags[i].length;
      }

      let cnt = 0;
      let border = 0;
      this.newTags = {};

      if(width <= 670) {
        border = 1;
      } else if(width > 670 && width <= 990) {
        border = 2;
      } else if(width > 990 && width <= 1140) {
        border = 3;
      } else if(width > 1140 && width <= 1460) {
        border = 4;
      } else if(width > 1460){
        border = 5;
      }

      let row_limit = Math.ceil(all_tags_number/border);
      let row_cnt = 0;

      for(let step = 0; step < border; step++) {
        this.newTags[step] = [];
      }
      for(let i in tags) {
        this.newTags[cnt].push(tags[i]);
        row_cnt += tags[i].length;
        if(row_cnt >= row_limit) {
          cnt++;
          row_cnt = 0;
        }
      }
      this.showTags = this.newTags;
    }
  },
  async created() {
    await this.loadData();
    this.initData();
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-height: 70vh;
  width: auto;
  height: auto;
  display: grid;
  grid-gap: 5px;
  grid-auto-rows: min-content;
  grid-template-columns: 1;
  grid-auto-columns: auto;
  background-color: #F4F3EF;
  padding: 5px;
}
.wrapper > div {
  border: #e8e8e6 1px solid;
  border-radius: 5px;
  background-color: white;
}
.card {
  margin-bottom: 0;
  background-color: #F4F3EF;
}
ul {
  padding: 2px;
}
li {
  cursor: pointer;
  list-style: none;
}
li {
  padding-left: 10px;
}
.checked {
  background-color: #E8FFF0;
}
.checked:before {
  content: " \2713"
}
button {
  width: 30%;
  margin: 2px;
}
.row > div {
  margin-top: 2px;
}
.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row:first-child {
  padding-bottom: 10px;
}

.row:nth-of-type(2) {
  overflow-y: scroll;
  margin-left: 2px;
  margin-right: 2px;
}

.row:last-child {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.col {
  padding: 0;
}

.card {
  padding: 15px;
}
.header_row {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
</style>
