<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 v-if="mode === 'add'" class="title">Dodaj nowy limit</h5>
          <h5 v-if="mode === 'edit'" class="title">Edytuj limit</h5>
          <h5 v-if="mode === 'date'" class="title">Następny limit</h5>
          <h5 v-if="mode === 'delete'" class="title">Usuń limit</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer; opacity: 0.5;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row" v-if="mode === 'delete'">
        <div class="col-md-12">
          <el-form label-position="left" label-width="150px">
            <el-form-item label="Obowiązuje od">
              <el-date-picker
                v-model="model.day"
                :default-value="pickerDefault"
                type="date"
                align="center"
                :clearable="false"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>

            <p class="text-danger text-md-right" v-if="dateCheck"><small>Data usunięcia musi być późniejsza od istniejącej</small><p/>
          </el-form>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-12">
          <el-form label-position="left" label-width="150px">
            <el-form-item label="Klient">
              <fg-input :error="getError('Klient')" data-vv-as="Klient">
              <el-select
                v-model="model.client_id"
                v-validate="modelValidations.client_id"
                name="Klient"
                filterable
                :disabled="mode !== 'add'"
              >
                <el-option
                  v-for="client in clients"
                  :label="'[' + client.client_id + '] ' + client.client_name"
                  :value="client.client_id"
                  :key="client.client_id"
                ></el-option>
              </el-select>
              </fg-input>
            </el-form-item>

            <el-form-item label="Obowiązuje od">
              <el-date-picker
                v-model="model.day"
                :default-value="pickerDefault"
                type="date"
                align="center"
                :clearable="false"
                format="yyyy-MM-dd"
                :disabled="mode === 'edit'"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>

            <p class="text-danger text-md-right" v-if="mode === 'date' && dateCheck"><small>Nowa data musi być późniejsza od istniejącej</small><p/>
            <p class="text-danger text-md-right" v-if="readdCheck"><small>Nowa data musi być późniejsza od {{ clients[model.client_id].max_date }}</small><p/>

            <el-form-item label="Twardy">
              <fg-input :error="getError('Twardy')" data-vv-as="Twardy">
                <el-select
                  v-model="model.is_hard"
                  v-validate="modelValidations.is_hard"
                  name="Twardy"
                >
                  <el-option
                    v-for="opt in isHardSelect"
                    :label="opt.label"
                    :value="opt.value"
                    :key="opt.value"
                  ></el-option>
                </el-select>
              </fg-input>
            </el-form-item>

            <el-form-item label="Limit">
              <el-input-number
                name="sale_limit"
                v-model="model.sale_limit"
                v-validate="modelValidations.sale_limit"
                :error="getError('sale_limit')"
                controls-position="right"
              ></el-input-number>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="row">
        <p-button type="success" @click.prevent="saveData">Zapisz</p-button>
      </div>

    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library
import utils from "src/utils";
// ui components
import { Input, Select, Option, Tooltip, InputNumber, DatePicker, Form, FormItem } from "element-ui";

// vuex actions
import { SAVE_SALES_LIMIT, SALES_LIMITS_INVALID } from "src/store/actions/sales/sales_limits";
import { LIMITS_UNIQUE_CLIENTS_INVALID } from "src/store/actions/sales/limits_unique_clients";

// event bus
import { eventBus } from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [InputNumber.name]: InputNumber,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  props: ['mode', 'row'],
  data() {
    return {
      loading: false,
      date_from: '',
      pickerDefault: '',
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      model: {
        id: null,
        client_id: null,
        client_name: null,
        day: moment(utils.today()).format("YYYY-MM-DD"),
        is_hard: 1,
        sale_limit: 0,
      },
      modelValidations: {
        id: {
          numeric: true,
          min: 1
        },
        client_id: {
          required: true,
          numeric: true,
          min: 1
        },
        is_hard: {
          required: true
        },
        sale_limit: {
          required: true
        },
      },
      isHardSelect: [
        {label: 'TAK', value: 1},
        {label: 'NIE', value: 0}
      ]
    }
  },
  computed: {
    uniqueClients() {
      return this.$store.getters.getLimitsUniqueClients;
    },
    allClients() {
      return this.$store.getters.getClients;
    },
    clients() {
      if(this.mode !== 'add') {
        return this.allClients;
      } else {
        return this.uniqueClients;
      }
    },
    dateCheck() {
      if((this.mode === 'date' || this.mode === 'delete') && this.date_from !== '') {
        let existingDate = new Date(this.date_from);
        let selectedDate = new Date(this.model.day);

        if(moment(existingDate).isAfter(selectedDate, 'day')) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    readdCheck() {
      let result = false;

      if(this.mode === 'add') {
        if(this.model.client_id && this.clients[this.model.client_id].max_date) {
          let lastDate = new Date(this.clients[this.model.client_id].max_date);
          let selectedDate = new Date(this.model.day);

          if(moment(lastDate).isAfter(selectedDate, 'day')) {
            result = true;
          } else {
            result = false;
          }
        }
      }

      return result;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      if(isValid) {
        if(this.dateCheck || this.readdCheck) {
          eventBus.$emit("notify", {
            message: "Nowa data musi być późniejsza od istniejącej!",
            type: "warning"
          });
          return;
        }
        let model = this.model;
        model.day = moment(this.model.day).format("YYYY-MM-DD");
        model.mode = this.mode;

        await this.$store.dispatch(SAVE_SALES_LIMIT, model).catch(error => {
          console.log(error.response)
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning"
          });
        })

        if (this.$store.getters.getSalesLimitsModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.mode === 'edit' ? "zmodyfikowany" : "dodany"),
            type: "success"
          });

          this.$store.commit(SALES_LIMITS_INVALID) // oznacz nieaktualność danych
          this.$store.commit(LIMITS_UNIQUE_CLIENTS_INVALID);
          eventBus.$emit('salesLimitSaved', this.day);
          this.$emit('close');
        }
      }
    },
  },
  async created() {
    if(this.mode !== 'add') {
      this.model.id = this.row.id;
      this.model.client_id = this.row.client_id;
      this.model.client_name = this.row.client_name;
      this.model.sale_limit = this.row.sale_limit;
      this.model.is_hard = this.row.is_hard;

      if(this.mode === 'edit') {
        this.model.day = this.row.date_from;
      } else {
        if(moment(utils.today()).isAfter(this.row.date_from, 'day')) {
          this.model.day = moment(utils.today()).format("YYYY-MM-DD");
        } else {
          this.model.day = this.row.date_from;
        }
      }
      this.date_from = this.row.date_from;
    }
  },
};
</script>

<style lang="scss" scoped>
  .row {
    margin: 10px;
  }
  .card {
    margin: 0;
    display: flex;
  }
  .row:last-child {
    justify-content: center;
  }
  .center {
    justify-content: center;
    align-content: center;
  }
  .header_row {
    display: flex;
    justify-content: space-between;
  }
</style>
