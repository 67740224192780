<template>
  <div class="modal-wrapper" :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row header-row">
      <div class="col-sm-11">
        <div class="row">
          <div class="col-sm-12">
            <h5 class="title">Raport spójności paczki {{ package_id }} dla klienta '{{ client }}' [{{ client_id }}]</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
    </div>

    <div class="row warning-row" v-if="!isRaportUpToDate">
      <div class="col-sm-12">
        <div class="center" style="font-size: 1.3em;">
          Raport może być nieaktualny - zleć ponownie!
        </div>
        <p-button type="default" link @click="forceConsistencyJob()">
          Zleć ponownie
        </p-button>
      </div>
    </div>

    <div class="row" v-if="loading">
      <div class="col-sm-12">
        <div class="center" style="font-size: 1.3em;">
          Wczytywanie danych
        </div>
      </div>
    </div>
    <h6>Rozbieżności</h6>
    <div class="row" v-if="!loading && raports.discrepancies.length == 0">
      <div class="col-sm-12">
        <div class="center" style="font-size: 1.3em;">
          Brak danych
        </div>
      </div>
    </div>
    <card v-for="(report, index) in raports.discrepancies" :key="'disc_' + index">
      <appFilterConsistencyRaport
        :report="report"
        :checkedArray="checkedDiscrepancies"
        :index="index"
        type="discrepancy"
        :disableButtons="!isRaportUpToDate"
      />
    </card>
    <br />
    <h6>Wyjątki</h6>
    <div class="row" v-if="!loading && raports.exceptions.length == 0">
      <div class="col-sm-12">
        <div class="center" style="font-size: 1.3em;">
          Brak danych
        </div>
      </div>
    </div>
    <card v-for="(exception, index) in raports.exceptions" :key="'exc_' + index">
      <appFilterConsistencyRaport
        :report="exception"
        :checkedArray="checkedExceptions"
        :index="index"
        type="exception"
        :disableButtons="!isRaportUpToDate"
      />
    </card>
    <br />
    <br />
    <div class="row">
      <div class="col-sm-12 center">
        <p-button style="margin-right: 5px;" link @click="$emit('close')">Anuluj</p-button>
        <p-button :disabled="saveButtonCheck" type="success" @click="handleSave()">Zapisz</p-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from "src/components/UIComponents";
import { Select, Option, Tooltip } from "element-ui";

// import {  } from "element-ui";
import { LOAD_FILTER_CONSISTENCY_RAPORT, FILTER_CONSISTENCY_RAPORT_INVALID } from "src/store/actions/filter/filter_consistency_raport";
import {
  LOAD_FILTER_EXCEPTION,
  FILTER_EXCEPTION_INVALID,
  SAVE_FILTER_EXCEPTION,
  DELETE_FILTER_EXCEPTION,
} from "src/store/actions/filter/filter_exception";
// event bus
import { eventBus } from "src/main";
import moment from "moment";

import FilterConsistencyRaport from "src/components/Utils4/filter/views/FilterConsistencyRaport.vue";

export default {
  components: {
    Card,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    appFilterConsistencyRaport: FilterConsistencyRaport,
  },
  data() {
    return {
      loading: false,
      columndWidth: null,
      columnCount: 0,
      checkedDiscrepancies: [],
      checkedExceptions: [],
    };
  },
  props: ["package_id", "client_id", "client", "consistency_start_time"],
  computed: {
    filterConsistencyRaport() {
      let data = {};
      if (this.$store.state.filter_consistency_raport.loading_status === "success") {
        data = _.cloneDeep(this.$store.getters.getFilterConsistencyRaport);
        data.consistency?.forEach(x => (x.report = JSON.parse(x.report)));
      }
      return data;
    },
    filterException() {
      return this.$store.getters.getFilterException;
    },
    lastFilterExceptionDate() {
      let lastDate;
      for (let i in this.filterException) {
        let exception = this.filterException[i];
        let exceptionTime = exception.delete_time ? exception.delete_time : exception.insert_time;
        if (!lastDate) lastDate = moment(exceptionTime);
        if (moment(exceptionTime) > lastDate) {
          lastDate = moment(exceptionTime);
        }
      }
      if (lastDate) lastDate = lastDate.format("YYYY-MM-DD HH:mm:ss");
      return lastDate;
    },
    isRaportUpToDate() {
      if (
        this.filterConsistencyRaport.consistency &&
        this.filterConsistencyRaport.consistency.report &&
        this.filterConsistencyRaport.consistency[0]?.start_time
      ) {
        if (moment(this.lastFilterExceptionDate) > moment(this.filterConsistencyRaport.consistency[0].start_time)) return false;
      }
      return true;
    },
    raports() {
      let result = [];
      let raports = { discrepancies: [], exceptions: [] };
      this.filterConsistencyRaport.consistency?.forEach(cons => {
        cons.report?.forEach(rep =>
          result.push(Object.assign({}, rep, { result_pl: cons.result == "ERROR" ? "BŁĄD" : "OSTRZEŻENIE", result: cons.result })),
        );
      });

      result.forEach(res => {
        let type = res.report_type === "exception" ? "exceptions" : "discrepancies";
        if (res.error_code === 1) {
          for (let q in res.questions) {
            raports[type].push({
              result: res.result_pl,
              question_id: q,
              config: JSON.stringify(res),
              type: res.type,
              error_code: res.error_code,
              value: `W pytaniu ${q} odpowiedzi ${res.questions[q].answers.join(", ")} nie przypisane do klienta`,
              tip: "Dodaj klienta do odpowiedzi w konfiguracji pytań",
            });
          }
        }
        if (res.error_code === 2) {
          for (let q in res.questions) {
            res.questions[q].answers.forEach(answer => {
              raports[type].push({
                result: res.result_pl,
                question_id: q,
                config: JSON.stringify(res),
                type: res.type,
                error_code: res.error_code,
                value: `Odpowiedź ${answer} (pytanie ${q}) przypisana do klienta, ale nie wykorzystana w paczce`,
                tip: "Dodaj odpowiedź do kryteriów paczki",
              });
            });
          }
        }
        if (res.error_code === 3) {
          for (let project in res.projects) {
            raports[type].push({
              result: res.result_pl,
              question_id: null,
              exception_id: type === "exceptions" ? res.exception_id : null,
              config: JSON.stringify(res),
              type: res.type,
              error_code: res.error_code,
              value: `Projekt ${project} z odpowiedziami sprzedażowymi dla klienta nie wykorzystany w paczce`,
              tip: "Dodaj projekt do kryteriów paczki",
            });
          }
        }
        if (res.error_code === 4 || res.error_code === 5) {
          let filter = JSON.stringify(res.filter);
          let criteria = JSON.stringify(res.criteria);
          let code_value = res.error_code === 4 ? "płci/wieku" : "operatora";
          raports[type].push({
            result: res.result_pl,
            question_id: res.question_id,
            exception_id: type === "exceptions" ? res.exception_id : null,
            config: JSON.stringify({ criteria: res.criteria, filter: res.filter }),
            type: res.type,
            error_code: res.error_code,
            filter: filter,
            criteria: criteria,
            value: `Kryteria ${code_value} dla pytania ${res.question_id} inne niż kryteria paczki`,
            tip: `Uzgodnij kryteria ${code_value}`,
          });
        }
      });

      return raports;
    },
    saveButtonCheck() {
      return this.checkedDiscrepancies.length == 0 && this.checkedExceptions.length == 0;
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadData(id) {
      this.loading = true;

      this.$store.commit(FILTER_CONSISTENCY_RAPORT_INVALID);
      this.$store.commit(FILTER_EXCEPTION_INVALID);

      this.$store.dispatch(LOAD_FILTER_EXCEPTION, { client_id: this.client_id }).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_FILTER_CONSISTENCY_RAPORT, { package_id: id }).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    async addException({ question_id, type, config }) {
      const result = {
        question_id: question_id,
        client_id: this.client_id,
        type: type,
        config: config,
      };

      await this.$store.dispatch(SAVE_FILTER_EXCEPTION, result).catch(error => {
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getFilterExceptionModifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Wyjątek został dodany",
          type: "success",
        });
      }
    },
    async deleteException(id) {
      await this.$store.dispatch(DELETE_FILTER_EXCEPTION, { id: id }).catch(error => {
        console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (this.$store.getters.getFilterExceptionDeletingStatus === "deleted") {
        eventBus.$emit("notify", {
          message: "Wyjątek został usunięty",
          type: "success",
        });
      }
    },
    async handleSave() {
      for (let i in this.checkedDiscrepancies) {
        await this.addException(this.raports.discrepancies[this.checkedDiscrepancies[i]]);
      }
      for (let i in this.checkedExceptions) {
        await this.deleteException(this.raports.exceptions[this.checkedExceptions[i]].exception_id);
      }
      this.forceConsistencyJob();
    },
    handleResize() {
      const screenWidth = window.innerWidth;
      const modalWidth = screenWidth / 2;
      const paddingWidth = 140;
      const columnArea = modalWidth - paddingWidth;

      const columnSize = Math.floor(columnArea / this.columnCount);

      this.columndWidth = columnSize;
    },
    forceJob() {
      this.$emit("close");
      eventBus.$emit("forceFilterJob", { package_id: this.package_id });
    },
    forceConsistencyJob() {
      this.$emit("close");
      eventBus.$emit("forceConsistencyJob", { package_id: this.package_id });
    },
  },
  mounted() {
    eventBus.$on("handleConsistencyException", ({ report, index, type, mode }) => {
      if (type !== "exception") {
        if (mode) {
          const indexInArray = this.checkedDiscrepancies.indexOf(index);
          if (indexInArray > -1) {
            this.checkedDiscrepancies.splice(indexInArray, 1);
          }
        } else {
          this.checkedDiscrepancies.push(index);
        }
        // this.addException(obj.report);
      } else {
        // this.deleteException(obj.report.exception_id);
        if (mode) {
          this.checkedExceptions.push(index);
        } else {
          const indexInArray = this.checkedExceptions.indexOf(index);
          if (indexInArray > -1) {
            this.checkedExceptions.splice(indexInArray, 1);
          }
        }
      }
    });
  },
  async created() {
    await this.loadData(this.package_id);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeDestroy: function() {
    eventBus.$off("handleConsistencyException");
  },
};
</script>

<style lang="css" scoped>
.card {
  margin-bottom: 5px;
}

.card-title {
  margin: 0 40px;
}

.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}
.header-row {
  padding-top: 15px;
  padding-left: 15px;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-wrapper {
  position: relative;
  padding: 0 20px;
}

.tip {
  font-size: 0.8em;
  color: grey;
}

.warning-row {
  padding: 20px;
  margin: 20px -30px;
  background-color: #fffac2;
  border: 1px solid #f5f5f5;
}
</style>
