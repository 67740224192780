import storeGen from '../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  LOAD_USER_HISTORY,
  USER_HISTORY_LOADING,
  USER_HISTORY_LOADING_SUCCESS,
  USER_HISTORY_LOADING_ERROR,
  USER_HISTORY_INVALID,
} from '../../actions/complaints/user_history'

const storeObjectConfig = {
  objectName: "UserHistory",
  getterName: "getUserHistory",
  loadActionName: LOAD_USER_HISTORY,
  loadingMutationName: USER_HISTORY_LOADING,
  loadingSuccessMutationName: USER_HISTORY_LOADING_SUCCESS,
  loadingErrorMutationName: USER_HISTORY_LOADING_ERROR,
  invalidMutationName: USER_HISTORY_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  loadParams: {
    url: "/complaints/userData/user_history",
    method: "post" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
