export const LOAD_UPSELLING_INCOME = 'LOAD_UPSELLING_INCOME'
export const UPSELLING_INCOME_LOADING = 'UPSELLING_INCOME_LOADING'
export const UPSELLING_INCOME_LOADING_SUCCESS = 'UPSELLING_INCOME_LOADING_SUCCESS'
export const UPSELLING_INCOME_LOADING_ERROR = 'UPSELLING_INCOME_LOADING_ERROR'
export const UPSELLING_INCOME_INVALID = 'UPSELLING_INCOME_INVALID'

export const SAVE_UPSELLING_INCOME = 'SAVE_UPSELLING_INCOME'
export const UPSELLING_INCOME_SAVING = 'UPSELLING_INCOME_SAVING'
export const UPSELLING_INCOME_SAVING_SUCCESS = 'UPSELLING_INCOME_SAVING_SUCCESS'
export const UPSELLING_INCOME_SAVING_ERROR = 'UPSELLING_INCOME_SAVING_ERROR'

export const DELETE_UPSELLING_INCOME = 'DELETE_UPSELLING_INCOME'
export const UPSELLING_INCOME_DELETING = 'UPSELLING_INCOME_DELETING'
export const UPSELLING_INCOME_DELETING_SUCCESS = 'UPSELLING_INCOME_DELETING_SUCCESS'
export const UPSELLING_INCOME_DELETING_ERROR = 'UPSELLING_INCOME_DELETING_ERROR'