<template>
  <div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 class="title">{{ title }}</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <el-switch v-model="allCompanys" active-color="#13ce66" inactive-color="gray"> </el-switch>
        <span style="margin-left: 10px; color: grey;">Pokaż wszystkie firmy</span>
      </div>
      <hr />
      <div v-if="availableConsents.length > 0">
        <div
          class="row consentRow"
          v-for="consent in availableConsents"
          :key="consent.id"
          @click="setConsent(consent.id, consent.tresc_zgody, consent.owners)"
        >
          <div class="col-sm-11">
            {{ `[${consent.id}][${getOwners(consent.owners)}] ${consent.tresc_zgody}` }}
          </div>
          <div class="col-sm-1 center">
            <div v-if="consent.id === choosedID">
              <i class="fa fa-check fa-2x" style="color: #28a745" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="center">
        Brak zgód do wyboru dla danego typu
      </div>

      <div class="row">
        <p-button style="margin-right: 5px;" type="warning" @click.prevent="$emit('close')">Anuluj</p-button>
        <p-button type="success" @click.prevent="saveData()">Zapisz</p-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Switch } from "element-ui";

// vuex actions
import { LOAD_CONSENTS_DICT, CONSENTS_DICT_INVALID } from "src/store/actions/consents/consents_dict";

// event bus
import { eventBus } from "src/main";

export default {
  components: {
    [Switch.name]: Switch,
  },
  props: ["mode", "row", "project_name", "project_owner", "version"],
  data() {
    return {
      choosedID: null,
      consent_content: "",
      owners: [],
      allCompanys: false,
    };
  },
  computed: {
    consentsDict() {
      return this.$store.getters.getConsentsDict;
    },
    title() {
      return `Zgody dla '${this.project_name}' ${this.version}`;
    },
    availableConsents() {
      const dict = Object.values(this.consentsDict);
      let result = dict.filter(x => x.kind === this.row.kind && x.typ_zgody === this.row.typ_zgody);
      if (!this.allCompanys) {
        result = result.filter(x => x.owners.some(owner => owner.group === this.project_owner));
      }
      return result;
    },
  },
  methods: {
    async loadData(force) {
      if (force) {
        this.$store.commit(CONSENTS_DICT_INVALID);
      }

      await this.$store.dispatch(LOAD_CONSENTS_DICT, {}).catch(error => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    setConsent(consent_id, consent_content, owners) {
      this.choosedID = consent_id;
      this.consent_content = consent_content;
      this.owners = owners;
    },
    async saveData() {
      eventBus.$emit("setConsentID", {
        row: this.row,
        consent_id: this.choosedID,
        tresc_zgody: this.consent_content,
        owners: this.owners,
        changed: this.choosedID === this.row.zgody_slownik_id ? false : true,
      });
      this.$emit("close");
    },
    initData() {
      if (this.mode === "edit") {
        this.choosedID = this.row.zgody_slownik_id;
      }
    },
    getOwners(owners) {
      return owners.map(x => x.name).join(", ");
    },
  },
  async created() {
    await this.loadData();
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}
.card {
  margin: 0;
  display: flex;
}
.row:last-child {
  justify-content: center;
}
.center {
  display: flex;
  justify-content: center;
  align-self: center;
}
.header_row {
  display: flex;
  justify-content: space-between;
}
.consentRow {
  border: 1px solid gray;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
</style>
