import storeGen from '../../../storeGenerator'

import {
  AUTH_LOGOUT
} from '../../../actions/auth'

import {
  SECURE_API,
  BASE_API
} from 'src/store/config'

import {
  SAVE_SET_FORWARD,
  SET_FORWARD_SAVING,
  SET_FORWARD_SAVING_SUCCESS,
  SET_FORWARD_SAVING_ERROR
} from '../../../actions/complaints/actions/set_forward'

const storeObjectConfig = {
  objectName: "Complaints",
  getterName: "getSetForward",
  saveActionName: SAVE_SET_FORWARD,
  savingMutationName: SET_FORWARD_SAVING,
  savingSuccessMutationName: SET_FORWARD_SAVING_SUCCESS,
  savingErrorMutationName: SET_FORWARD_SAVING_ERROR,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: 'json',
  saveParams: {
    url: "/complaints/action/forward",
    method: "put" // {'get','post','put'}
  }
}

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations
}
