export const LOAD_PROJECT_CONFIGS = 'LOAD_PROJECT_CONFIGS'
export const PROJECT_CONFIGS_LOADING = 'PROJECT_CONFIGS_LOADING'
export const PROJECT_CONFIGS_LOADING_SUCCESS = 'PROJECT_CONFIGS_LOADING_SUCCESS'
export const PROJECT_CONFIGS_LOADING_ERROR = 'PROJECT_CONFIGS_LOADING_ERROR'
export const PROJECT_CONFIGS_INVALID = 'PROJECT_CONFIGS_INVALID'

export const SAVE_PROJECT_CONFIG = 'SAVE_PROJECT_CONFIG'
export const PROJECT_CONFIG_SAVING = 'PROJECT_CONFIG_SAVING'
export const PROJECT_CONFIG_SAVING_SUCCESS = 'PROJECT_CONFIG_SAVING_SUCCESS'
export const PROJECT_CONFIG_SAVING_ERROR = 'PROJECT_CONFIG_SAVING_ERROR'

export const DELETE_PROJECT_CONFIG = 'DELETE_PROJECT_CONFIG'
export const PROJECT_CONFIG_DELETING = 'PROJECT_CONFIG_DELETING'
export const PROJECT_CONFIG_DELETING_SUCCESS = 'PROJECT_CONFIG_DELETING_SUCCESS'
export const PROJECT_CONFIG_DELETING_ERROR = 'PROJECT_CONFIG_DELETING_ERROR'