export const LOAD_QUESTION_CONFIGS = 'LOAD_QUESTION_CONFIGS'
export const QUESTION_CONFIGS_LOADING = 'QUESTION_CONFIGS_LOADING'
export const QUESTION_CONFIGS_LOADING_SUCCESS = 'QUESTION_CONFIGS_LOADING_SUCCESS'
export const QUESTION_CONFIGS_LOADING_ERROR = 'QUESTION_CONFIGS_LOADING_ERROR'
export const QUESTION_CONFIGS_INVALID = 'QUESTION_CONFIGS_INVALID'

export const SAVE_QUESTION_CONFIG = 'SAVE_QUESTION_CONFIG'
export const QUESTION_CONFIG_SAVING = 'QUESTION_CONFIG_SAVING'
export const QUESTION_CONFIG_SAVING_SUCCESS = 'QUESTION_CONFIG_SAVING_SUCCESS'
export const QUESTION_CONFIG_SAVING_ERROR = 'QUESTION_CONFIG_SAVING_ERROR'

export const DELETE_QUESTION_CONFIG = 'DELETE_QUESTION_CONFIG'
export const QUESTION_CONFIG_DELETING = 'QUESTION_CONFIG_DELETING'
export const QUESTION_CONFIG_DELETING_SUCCESS = 'QUESTION_CONFIG_DELETING_SUCCESS'
export const QUESTION_CONFIG_DELETING_ERROR = 'QUESTION_CONFIG_DELETING_ERROR'